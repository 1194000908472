// Imports
import { useState } from 'react';
import { Button, Tag } from 'antd';
import { RightCircleOutlined } from '@ant-design/icons';

// App Imports
import RevealInfoModal from '../../components/modal/ReveaInfoModal';
import RevealSplash from '../../images/reveal_dashboard.png';

const Reveal = () => {
  const [showRevealInfoModal, setShowRevealInfoModal] = useState(false);

  return (
    <div
      style={{
        borderBottom: '1px solid #9b45d866',
        borderRadius: 5,
        overflow: 'hidden',
      }}
    >
      <div
        style={{
          background:
            'linear-gradient(150deg, #9b45d899 15%, #5c48c199 70%, #5533ff99 94%)',
          height: '250px',
        }}
      >
        <div style={{ float: 'right', padding: '20px 25px' }}>
          <Button
            icon={<RightCircleOutlined />}
            onClick={_ => setShowRevealInfoModal(true)}
            style={{ borderRadius: '5px', width: '120px', zIndex: 1000 }}
            size="large"
          >
            Start
          </Button>
        </div>
        <div
          style={{
            position: 'absolute',
            right: '10%',
            width: '50%',
            height: '250px',
            overflow: 'hidden',
          }}
        >
          <img
            src={RevealSplash}
            alt="Reveal Dashboard"
            style={{
              width: '100%',
              maxWidth: 500,
              marginTop: '100px',
            }}
          />
        </div>
        <div
          style={{
            padding: '25px 30px',
            lineHeight: '10px',
            textShadow: '0 1px 3px rgba(0,0,0,.4)',
          }}
        >
          <h2 style={{ fontSize: '32px', color: '#ffffff' }}>Reveal</h2>
          <h4
            style={{
              fontSize: '18px',
              color: '#ffffff99',
              fontWeight: '200',
            }}
          >
            Visual Data Exploration
          </h4>
          <div style={{ width: '40%', marginTop: 15 }}>
            <Tag color="#9b45d899" style={{ margin: '0px 10px 10px 0px' }}>
              Drag and Drop Analytics
            </Tag>
            <Tag color="#9b45d899" style={{ margin: '0px 10px 10px 0px' }}>
              Rich Data Visualizations
            </Tag>
            <Tag color="#9b45d899" style={{ margin: '0px 10px 10px 0px' }}>
              Advanced Mapping Tools
            </Tag>
            <Tag color="#9b45d899" style={{ margin: '0px 10px 10px 0px' }}>
              Refined Access Control
            </Tag>
          </div>
        </div>
      </div>
      {showRevealInfoModal && (
        <RevealInfoModal
          visible={showRevealInfoModal}
          close={_ => {
            setShowRevealInfoModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Reveal;
