// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTERUPGRADES = gql`
  query K8sKineticaClusterUpgrades {
    k8s_kineticaclusterupgrades {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICACLUSTERUPGRADES_BY_NAME = gql`
  query GetK8sKineticaClusterUpgradeByName($name: String!) {
    k8s_kineticaclusterupgrade(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICACLUSTERUPGRADE = gql`
  mutation CreateK8sKineticaClusterUpgrade($name: String!, $body: JSON!) {
    kineticaClusterUpgradeCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusterUpgrades = () => {
  return useQuery(GET_K8S_KINETICACLUSTERUPGRADES, {
    fetchPolicy: 'no-cache',
  });
};

export const useGetK8sKineticaClusterUpgradeByName = options => {
  return useQuery(GET_K8S_KINETICACLUSTERUPGRADES_BY_NAME, options);
};

export const useCreateK8SKineticaClusterUpgrade = () => {
  return useMutation(CREATE_K8S_KINETICACLUSTERUPGRADE);
};
