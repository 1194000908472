// Imports
import React, {
  useState,
  useMemo,
  useContext,
  useCallback,
  useEffect,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Tooltip,
  Button,
  Spin,
  Row,
  Col,
  Card,
  Badge,
  Popconfirm,
  Divider,
  Tag,
  notification,
} from 'antd';
import {
  PlusOutlined,
  RedoOutlined,
  ImportOutlined,
  ExportOutlined,
  SyncOutlined,
  AlertOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import axios from 'axios';

// App Imports
import Spinner from '../../components/common/Spinner';
import GraphQLServices from '../../graphql/services';
import WorkbookCreateModal from '../../components/modal/WorkbookCreateModal';
import WorkbookImportModal from '../../components/modal/WorkbookImportModal';
import {
  NAV_ROUTE_DATAEXPLORE_WORKBOOK,
  WORKBOOKS_CATALOG_URL,
} from '../../constants';
import { formatTimestampDistance } from '../../formatter';
import WorkbookSplash from '../../images/workbook_splash.png';
import { useExportAllJsonWorkbooks } from './utils';
import useAnalytics from '../../hooks/useAnalytics';
import { DEPLOYMENT_TYPE, FREE_SAAS } from '../../setup/config';
import { GET_K8S_KINETICACLUSTERS } from '../../graphql/schema/k8s_kineticaclusters';
import { GET_WORKSHEET_BY_ID } from '../../graphql/schema/worksheets';
import { UserContext } from '../../context';

const WorkbookCatalog = ({ workbooks, loading, refetch }) => {
  const userMe = useContext(UserContext);

  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);
  const [showWorkbookImportModal, setShowWorkbookImportModal] = useState(false);
  const [exampleUpdateAvailable, setExampleUpdateAvailable] = useState(false);

  const [updateExamples, { loading: updatingExamples }] =
    GraphQLServices.Workbooks.useUpdateExamples();
  const exportAllWorkbooks = useExportAllJsonWorkbooks();

  const history = useHistory();
  const graphqlClient = useApolloClient();
  const analytics = useAnalytics();

  const getExamplesTypeVersion = useCallback(
    async _ => {
      let type = 'default';
      if (FREE_SAAS) {
        type = 'free_saas';
      }

      const clusterResp = await graphqlClient.query({
        query: GET_K8S_KINETICACLUSTERS,
        variables: {
          deployment_type: DEPLOYMENT_TYPE,
        },
      });

      const cluster =
        clusterResp?.data?.k8s_kineticaclusters &&
        clusterResp?.data?.k8s_kineticaclusters.length > 0
          ? clusterResp?.data?.k8s_kineticaclusters[0]
          : null;
      const versionRegex = /(\d+\.\d+\.\d+)\.\d+/gi;
      const versionRegexMatches = versionRegex.exec(
        cluster?.status?.hmStatus?.version
      );
      const version =
        versionRegexMatches && versionRegexMatches.length === 2
          ? versionRegexMatches[1]
          : cluster?.status?.hmStatus?.version ?? '';

      return { type, version };
    },
    [graphqlClient]
  );

  useEffect(
    _ => {
      const fetchCatalog = async _ => {
        try {
          const {
            data: { catalog },
          } = await axios.get(WORKBOOKS_CATALOG_URL);
          const { type, version } = await getExamplesTypeVersion();

          const examples = catalog.examples[version][type];
          const existing = workbooks.filter(workbook => workbook.is_example);

          const hasNewExample = examples.some(example => {
            return !existing.some(exist => {
              return exist?.metadata?.example_id === example.example_id;
            });
          });

          const hasNewUpdate = existing.some(exist => {
            return examples.some(example => {
              return (
                exist?.metadata?.example_id === example.example_id &&
                (!exist?.metadata?.revision ||
                  exist?.metadata?.revision < example.revision)
              );
            });
          });

          setExampleUpdateAvailable(hasNewExample || hasNewUpdate || false);
        } catch (error) {
          console.error(error);
          setExampleUpdateAvailable(false);
        }
      };
      if (workbooks.length > 0) {
        setTimeout(_ => {
          fetchCatalog();
        }, 2000);
      } else {
        setExampleUpdateAvailable(false);
      }
    },
    [getExamplesTypeVersion, workbooks]
  );

  const handleAddWorkbook = e => {
    setShowWorkbookCreateModal(true);
  };

  const handleWorkbookSelect = workbook => e => {
    if (workbook.is_example) {
      analytics.track(analytics.EVENT_TYPES.OPENED_EXAMPLE_WORKBOOK)({
        title: workbook.name,
      });
      analytics.track(
        `${analytics.EVENT_TYPES.TRACK_EXAMPLE_WORKBOOK} ${workbook.name}`
      )({});
    }
    history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${workbook.id}`);
  };

  const handleWorkbookCreateCallback = async (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;

      const worksheetResp = await graphqlClient.query({
        query: GET_WORKSHEET_BY_ID,
        variables: {
          id: worksheet_id,
        },
      });

      refetch().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(
          workbook => workbook.id === worksheetResp?.data?.worksheet.workbook_id
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const handleUpdateExamples = async _ => {
    const { type, version } = await getExamplesTypeVersion();

    const { data } = await updateExamples({
      variables: { type, version },
    });

    analytics.track(analytics.EVENT_TYPES.REFRESHED_EXAMPLE_WORKBOOKS)({});

    refetch();
    notification.success({
      message: 'Examples Refresh',
      description: (
        <>
          Successfully refreshed example workbooks:
          <ul style={{ margin: 5, padding: '5px 5px 5px 10px' }}>
            {data?.workbookUpdateExamples.workbooks.map(update => (
              <li key={update.id}>
                {update.name}{' '}
                {data?.workbookUpdateExamples.source && (
                  <Tag
                    style={{
                      padding: '2px 5px',
                      lineHeight: '12px',
                      fontSize: '11px',
                    }}
                  >
                    {data?.workbookUpdateExamples.source}
                  </Tag>
                )}
              </li>
            ))}
          </ul>
        </>
      ),
    });
  };

  const handleImportWorkbook = _ => {
    setShowWorkbookImportModal(true);
  };

  const handleWorkbookImportCallback = (err, resp) => {
    if (resp) {
      refetch().then(_ => {
        setShowWorkbookImportModal(false);
      });
    } else {
      console.error(err);
    }
  };

  const featureStyle = {
    fontSize: '15px',
    padding: '8px 20px',
    margin: '10px 0px 10px 0px',
    backgroundColor: '#f6f6f6',
    borderRadius: '5px',
    borderLeft: '5px solid #dddddd',
  };

  const fillers = useMemo(
    _ => {
      if (workbooks.length % 3 > 0) {
        return Array.from(Array(3 - (workbooks.length % 3)).keys());
      }
      return [];
    },
    [workbooks]
  );

  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children;

  return (
    <div className="workbook_catalog">
      <div style={{ float: 'right' }}>
        <Space>
          <Tooltip title="Refresh to latest versions">
            <Button
              type={exampleUpdateAvailable ? 'primary' : 'default'}
              icon={
                exampleUpdateAvailable ? <AlertOutlined /> : <SyncOutlined />
              }
              onClick={handleUpdateExamples}
              loading={updatingExamples}
            >
              {exampleUpdateAvailable
                ? 'Updates Available'
                : 'Refresh Examples'}
            </Button>
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Import Workbook (JSON/SQL)">
            <Button icon={<ImportOutlined />} onClick={handleImportWorkbook}>
              Import
            </Button>
          </Tooltip>
          <Tooltip title="Export All Workbooks JSON">
            <Popconfirm
              title="Are you sure you want to export all workbooks?"
              onConfirm={exportAllWorkbooks}
            >
              <Button icon={<ExportOutlined />}>Export All</Button>
            </Popconfirm>
          </Tooltip>
          <Tooltip title="Refresh">
            <Button
              icon={<RedoOutlined spin={loading} />}
              onClick={() => refetch()}
            />
          </Tooltip>
          <Divider type="vertical" />
          <Tooltip title="Create a new workbook">
            <Button type="primary" onClick={handleAddWorkbook}>
              <PlusOutlined /> Create
            </Button>
          </Tooltip>
        </Space>
      </div>
      <h2>Workbooks</h2>
      <Spin indicator={<Spinner />} spinning={loading}>
        {workbooks.length <= 12 && (
          <div
            style={{
              backgroundColor: '#ffffff',
              padding: '20px 70px',
              marginBottom: '20px',
              width: '100%',
            }}
          >
            <Row gutter={40}>
              <Col span={12}>
                <h1
                  style={{
                    fontSize: '30px',
                    color: '#3700b399',
                    marginTop: '10px',
                  }}
                >
                  Key Features
                </h1>
                <ul
                  style={{
                    listStyle: 'none',
                    margin: '0px',
                    padding: '0px',
                  }}
                >
                  <li style={featureStyle}>
                    Keep all your analytics work organized
                  </li>
                  <li style={featureStyle}>
                    Visualize results using charts and maps
                  </li>
                  <li style={featureStyle}>
                    Add insight by annotating your findings
                  </li>
                  <li>
                    <Button
                      type="primary"
                      icon={<PlusOutlined />}
                      onClick={handleAddWorkbook}
                      size="large"
                      style={{ marginTop: '10px' }}
                      block
                    >
                      Create New Workbook
                    </Button>
                  </li>
                </ul>
              </Col>
              <Col span={12} style={{ textAlign: 'center' }}>
                <img
                  src={WorkbookSplash}
                  style={{ height: '250px', marginTop: '30px' }}
                  alt="Workbook Splash"
                />
              </Col>
            </Row>
          </div>
        )}
        <Row gutter={16}>
          {[...workbooks]
            .sort((wb1, wb2) => {
              if (wb1.updated_at < wb2.updated_at) return 1;
              if (wb1.updated_at > wb2.updated_at) return -1;
              return 0;
            })
            .map(workbook => {
              return (
                <Col
                  key={workbook.id}
                  span={8}
                  xxl={6}
                  style={{ marginBottom: '16px' }}
                >
                  <ConditionalWrapper
                    condition={
                      workbook.is_shared && workbook?.user?.id !== userMe?.id
                    }
                    wrapper={children => (
                      <Badge.Ribbon text="Shared" color="blue">
                        {children}
                      </Badge.Ribbon>
                    )}
                  >
                    <ConditionalWrapper
                      condition={
                        workbook.is_example &&
                        (!workbook.is_shared ||
                          workbook?.user?.id === userMe?.id)
                      }
                      wrapper={children => (
                        <Badge.Ribbon text="Example" color="magenta">
                          {children}
                        </Badge.Ribbon>
                      )}
                    >
                      <Card
                        title={workbook.name}
                        onClick={handleWorkbookSelect(workbook)}
                        bordered={false}
                        style={{ cursor: 'pointer' }}
                        bodyStyle={{ padding: '18px 24px' }}
                      >
                        <div
                          style={{
                            height: '64px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            lineHeight: '20px',
                            WebkitLineClamp: '3',
                            WebkitBoxOrient: 'vertical',
                            display: '-webkit-box',
                          }}
                        >
                          {workbook.description}
                        </div>
                        <div style={{ fontSize: '12px', color: '#cccccc' }}>
                          Updated:{' '}
                          {`${formatTimestampDistance(
                            workbook.updated_at
                          )} ago`}
                        </div>
                      </Card>
                    </ConditionalWrapper>
                  </ConditionalWrapper>
                </Col>
              );
            })}
          {fillers.map((filler, idx) => {
            return (
              <Col
                key={`filler_${idx}`}
                span={8}
                xxl={6}
                style={{ marginBottom: '16px' }}
              >
                <div
                  style={{
                    height: '178px',
                    border: '2px dashed #e6e6e6',
                    backgroundColor: '#ffffff33',
                  }}
                ></div>
              </Col>
            );
          })}
        </Row>
      </Spin>
      {showWorkbookCreateModal && (
        <WorkbookCreateModal
          visible={showWorkbookCreateModal}
          close={_ => {
            setShowWorkbookCreateModal(false);
          }}
          callback={handleWorkbookCreateCallback}
        />
      )}
      {showWorkbookImportModal && (
        <WorkbookImportModal
          visible={showWorkbookImportModal}
          close={_ => {
            setShowWorkbookImportModal(false);
          }}
          callback={handleWorkbookImportCallback}
        />
      )}
    </div>
  );
};

export default WorkbookCatalog;
