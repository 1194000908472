// Imports
import React, { useState, useMemo } from 'react';
import { Modal, Form, Input, Button, Select } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const { Option } = Select;

const TableMoveModal = ({ table, visible, close, callback }) => {
  const [moveTable] = GraphQLServices.Tables.useTableMoveTable();
  const {
    data: { schemas = [] } = {},
  } = GraphQLServices.Schemas.useGetSchemas();

  const [isMoving, setIsMoving] = useState(false);

  const [form] = Form.useForm();

  const schemaOptions = useMemo(
    _ => {
      return schemas.map(schema => {
        return (
          <Option key={schema.name} value={schema.name}>
            {schema.name}
          </Option>
        );
      });
    },
    [schemas]
  );

  const onFinish = values => {
    const { newSchema } = values;
    const { schema, name } = table;
    setIsMoving(true);
    moveTable({
      variables: {
        schema,
        name,
        newSchema,
      },
    })
      .then(resp => {
        setIsMoving(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsMoving(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const move = _ => {
    form.submit();
  };

  return (
    <Modal
      title={`Table Move: ${table.full}`}
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button key="move" type="primary" onClick={move} loading={isMoving}>
          Move
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="table"
        layout="vertical"
        initialValues={table}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item label="Current Schema" name="schema">
          <Input disabled />
        </Form.Item>
        <Form.Item
          label="New Schema"
          name="newSchema"
          rules={[
            {
              required: true,
              message: 'Please input new schema name!',
            },
          ]}
        >
          <Select
            allowClear
            showSearch
            style={{ width: '100%' }}
            placeholder="Select a schema"
          >
            {schemaOptions}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default TableMoveModal;
