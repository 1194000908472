// Imports

// App Imports
import { BLOCKS_UPDATE_RESULT } from './actions';

// Initial State
export const initialState = {
  blockResults: {},
};

// State
export const worksheet = (state = initialState, action) => {
  switch (action.type) {
    case BLOCKS_UPDATE_RESULT:
      return {
        ...state,
        blockResults: {
          ...state.blockResults,
          [action.blockId]: action.results,
        },
      };

    default:
      return state;
  }
};
