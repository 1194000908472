// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';

const FuncEnvironmentCreateModal = ({ visible, close, callback }) => {
  const [createFuncEnvironment] =
    GraphQLServices.FuncEnvironments.useCreateFuncEnvironment();

  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { name: func_environment_name } = values;
    setIsCreating(true);
    createFuncEnvironment({
      variables: {
        func_environment_name,
      },
    })
      .then(resp => {
        setIsCreating(false);
        displaySuccess(
          `Function Environment ${func_environment_name} created.`
        );
        if (resp?.data?.funcEnvironmentCreate && callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsCreating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const create = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Create Function Environment"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="func_environment"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input function environment name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default FuncEnvironmentCreateModal;
