// Imports
import { useLazyQuery, gql } from '@apollo/client';

export const GET_WMS = gql`
  query GetWms($params: JSON!) {
    wms(params: $params) {
      params
      image_data
    }
  }
`;

export const useGetWms = () => {
  return useLazyQuery(GET_WMS);
};
