// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button, Radio, Row, Col } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const GraphDeleteModal = ({ graph, visible, close, callback }) => {
  const [deleteGraphByName] = GraphQLServices.Graphs.useDeleteGraphByName();

  const [isRemoving, setIsRemoving] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { delete_persist, server_id } = values;
    setIsRemoving(true);
    deleteGraphByName({
      variables: {
        graph_name: graph.graph_name,
        options: {
          delete_persist,
          server_id,
        },
      },
    })
      .then(resp => {
        setIsRemoving(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsRemoving(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const remove = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Delete Graph"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="remove"
          type="primary"
          onClick={remove}
          loading={isRemoving}
        >
          Delete
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="graph"
        layout="vertical"
        initialValues={{
          graph_name: graph.graph_name,
          delete_persist: true,
          server_id: graph.delete_server_ids.join(','),
        }}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item label="Graph Name" name="graph_name">
          <Input type="text" disabled={true} />
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item label="Delete Persist" name="delete_persist">
              <Radio.Group buttonStyle="solid">
                <Radio.Button value={true}>True</Radio.Button>
                <Radio.Button value={false}>False</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={16}>
            <Form.Item label="Server ID(s)" name="server_id">
              <Input type="text" placeholder="Empty defaults to all servers" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default GraphDeleteModal;
