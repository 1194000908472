import { gql, useQuery } from '@apollo/client';

export const FETCH_URL = gql`
  query FetchURL($url: String!) {
    fetch_url(url: $url) {
      data
    }
  }
`;

export const useFetchURL = options => useQuery(FETCH_URL, options);
