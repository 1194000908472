// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_GRAPHS = gql`
  query Graphs {
    graphs {
      graph_name
      directed
      num_nodes
      num_edges
      num_bytes
      is_persisted
      is_partitioned
      is_sync_db
      has_insert_table_monitor
      graph_server_id
      original_request
    }
  }
`;

export const GET_GRAPH_BY_NAME = gql`
  query GetGraphByName($graph_name: String!) {
    graph(graph_name: $graph_name) {
      graph_name
      directed
      num_nodes
      num_edges
      num_bytes
      is_persisted
      is_partitioned
      is_sync_db
      has_insert_table_monitor
      graph_server_id
      original_request
    }
  }
`;

export const CREATE_GRAPH = gql`
  mutation CreateGraph(
    $graph_name: String!
    $directed_graph: Boolean!
    $nodes: [String]!
    $edges: [String]!
    $weights: [String]!
    $restrictions: [String]!
    $options: JSON!
  ) {
    graphCreate(
      graph_name: $graph_name
      directed_graph: $directed_graph
      nodes: $nodes
      edges: $edges
      weights: $weights
      restrictions: $restrictions
      options: $options
    ) {
      graph_name
      directed
      num_nodes
      num_edges
      num_bytes
      is_persisted
      is_partitioned
      is_sync_db
      has_insert_table_monitor
      graph_server_id
      original_request
    }
  }
`;

export const SOLVE_GRAPH = gql`
  mutation SolveGraph(
    $graph_name: String!
    $solver_type: String!
    $solution_table: String!
    $weights_on_edges: [String]!
    $restrictions: [String]!
    $source_nodes: [String]!
    $destination_nodes: [String]!
    $options: JSON!
  ) {
    graphSolve(
      graph_name: $graph_name
      solver_type: $solver_type
      solution_table: $solution_table
      weights_on_edges: $weights_on_edges
      restrictions: $restrictions
      source_nodes: $source_nodes
      destination_nodes: $destination_nodes
      options: $options
    ) {
      result
      result_per_destination_node
      info
    }
  }
`;

export const DELETE_GRAPH_BY_NAME = gql`
  mutation DeleteGraphByName($graph_name: String!) {
    graphDeleteByName(graph_name: $graph_name) {
      graph_name
    }
  }
`;

export const useGetGraphs = () => {
  return useQuery(GET_GRAPHS);
};

export const useGetGraphByName = options => {
  return useQuery(GET_GRAPH_BY_NAME, options);
};

export const useCreateGraph = () => {
  return useMutation(CREATE_GRAPH);
};

export const useSolveGraph = () => {
  return useMutation(SOLVE_GRAPH);
};

export const useDeleteGraphByName = () => {
  return useMutation(DELETE_GRAPH_BY_NAME);
};
