// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Space, Input, Table, Spin, Alert } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';

const { Column } = Table;
const { Search } = Input;

const DatasourceTablesModal = ({
  datasource,
  visible,
  setVisible,
  handleDatasourceImport,
  actionLabel = 'Import',
  width,
  height,
  pageSize,
}) => {
  const { datasource_name } = datasource;

  const { data, loading } = GraphQLServices.Datasources.useGetDatasourceByName({
    variables: {
      datasource_name,
    },
  });

  const [filter, setFilter] = useState('');

  const tables = useMemo(
    _ => {
      if (data && data.datasource) {
        return data.datasource.tables
          .filter(
            table =>
              filter.trim() === '' ||
              table.toLowerCase().includes(filter.trim().toLowerCase())
          )
          .map(table => {
            return {
              name: table,
            };
          });
      }
      return [];
    },
    [data, filter]
  );

  const onFilterChange = evt => {
    setFilter(evt.target.value);
  };

  const handleTableImport = table => e => {
    const { name } = table;
    handleDatasourceImport(datasource, { table: name });
    setVisible(false);
  };

  return (
    visible && (
      <Modal
        title={`Data Source Tables: ${datasource_name}`}
        open={visible}
        width={width}
        onCancel={_ => setVisible(false)}
        footer={[
          <Button key="submit" onClick={_ => setVisible(false)}>
            Close
          </Button>,
        ]}
        destroyOnClose
        centered
      >
        <Spin indicator={<Spinner />} spinning={loading}>
          <div
            style={{
              height,
              overflow: 'auto',
            }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Alert message="Limited to first 1,000" type="warning" showIcon />
              <Search
                placeholder="Filter tables"
                allowClear
                onChange={onFilterChange}
              />
              {data && (
                <Table
                  rowKey="name"
                  dataSource={tables}
                  pagination={{
                    pageSize: pageSize - 1,
                    pageSizeOptions: [pageSize - 1],
                    showSizeChanger: false,
                  }}
                  scroll={{
                    x: 'max-content',
                  }}
                  size="small"
                >
                  <Column key="name" title="Schema/Table" dataIndex="name" />
                  <Column
                    title=""
                    key="name"
                    width="1%"
                    render={(table, record) => (
                      <Button
                        type="primary"
                        onClick={handleTableImport(record)}
                        size="small"
                        ghost
                      >
                        {actionLabel}
                      </Button>
                    )}
                  />
                </Table>
              )}
            </Space>
          </div>
        </Spin>
      </Modal>
    )
  );
};

export default DatasourceTablesModal;
