// Imports
import React, { useState, useMemo, useCallback } from 'react';
import { Space, Button, Popconfirm, Empty, Tooltip } from 'antd';
import {
  SettingOutlined,
  CloseOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import shortid from 'shortid';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import useInterval from '../../hooks/useInterval';
import { useIsReadOnly } from './utils';
import { downloadPng } from '../../helper';

const VizBigNumber = ({
  viz,
  data,
  run,
  columns,
  handleUpdate,
  handleRemove,
  minHeight = 180,
}) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const [intervalCount, setIntervalCount] = useState(0);
  useInterval(() => {
    const intervalLimit = parseInt(config.interval_limit);
    if (
      config.auto_refresh_interval > 0 &&
      (intervalLimit === -1 ||
        (intervalLimit > 0 && intervalLimit >= intervalCount))
    ) {
      setIntervalCount(intervalCount + 1);
      run();
    }
  }, config.auto_refresh_interval * 1000);

  const imageId = useMemo(_ => {
    return `image_${shortid.generate()}`;
  }, []);

  const handleDownloadPng = useCallback(
    _ => {
      downloadPng(imageId);
    },
    [imageId]
  );

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            {config.auto_refresh_interval > 0 &&
              intervalCount > 0 &&
              (parseInt(config.interval_limit) === -1 ||
                (parseInt(config.interval_limit) > 0 &&
                  parseInt(config.interval_limit) >= intervalCount)) && (
                <Tooltip
                  title={`Refreshing for ${config.interval_limit} times every ${config.auto_refresh_interval} seconds`}
                >
                  <Button
                    icon={<SettingOutlined />}
                    loading={true}
                    type="text"
                    size="small"
                    style={{ color: '#999999' }}
                  >
                    {intervalCount}
                  </Button>
                </Tooltip>
              )}
            <Button
              icon={<FileImageOutlined />}
              onClick={handleDownloadPng}
              size="small"
            >
              Save PNG
            </Button>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this visualization?"
              onConfirm={handleRemove}
            >
              <Button icon={<CloseOutlined />} size="small"></Button>
            </Popconfirm>
          </Space>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <VizTitleBar config={config} />
        <div
          id={imageId}
          className="big-number"
          style={{ height: minHeight - 20 }}
        >
          {config?.value_column ? (
            <>
              <div
                style={{
                  fontSize: 60,
                  lineHeight: '60px',
                  textAlign: 'center',
                  color: `#${config.color}`,
                  marginTop: minHeight - 110,
                }}
              >
                {data[
                  `column_${
                    data.column_headers.findIndex(
                      header => header === config.value_column
                    ) + 1
                  }`
                ] &&
                  data[
                    `column_${
                      data.column_headers.findIndex(
                        header => header === config.value_column
                      ) + 1
                    }`
                  ].toLocaleString('en-US')}
              </div>
              <div
                style={{
                  fontSize: 25,
                  fontWeight: 200,
                  textAlign: 'center',
                  color: '#999999',
                }}
              >
                {config?.value_label}
              </div>
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure Chart"
            />
          )}
          {!readOnly && (
            <VizConfigDrawer
              title="Big Number Configuration"
              fields={viz?.visualization_type?.params?.fields}
              viz={viz}
              config={viz?.config}
              options={{
                columns,
              }}
              isOpen={isVizConfigOpen}
              handleClose={handleCloseVizConfig}
              handleUpdate={handleUpdateVizConfig}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VizBigNumber;
