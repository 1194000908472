// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_DATASOURCE_PERMISSION = gql`
  mutation GrantDatasourcePermission(
    $name: String!
    $permission: String!
    $datasource_name: String!
  ) {
    datasourcePermissionGrant(
      name: $name
      permission: $permission
      datasource_name: $datasource_name
    ) {
      name
      permission
      datasource_name
    }
  }
`;

export const REVOKE_DATASOURCE_PERMISSION = gql`
  mutation RevokeDatasourcePermission(
    $name: String!
    $permission: String!
    $datasource_name: String!
  ) {
    datasourcePermissionRevoke(
      name: $name
      permission: $permission
      datasource_name: $datasource_name
    ) {
      name
      permission
      datasource_name
    }
  }
`;

export const useGrantDatasourcePermission = () => {
  return useMutation(GRANT_DATASOURCE_PERMISSION);
};

export const useRevokeDatasourcePermission = () => {
  return useMutation(REVOKE_DATASOURCE_PERMISSION);
};
