// Imports
import { useMutation, useQuery, gql } from '@apollo/client';

export const GET_SETTINGS = gql`
  query Settings {
    settings {
      id
      name
      description
      key
      value
      created_at
      updated_at
    }
  }
`;

export const GET_SETTING_BY_ID = gql`
  query SettingById($id: ID!) {
    setting(id: $id) {
      id
      name
      description
      key
      value
      created_at
      updated_at
    }
  }
`;

export const UPDATE_SETTING_BY_ID = gql`
  mutation UpdateSettingById($id: ID!, $value: String!) {
    settingUpdateById(id: $id, value: $value) {
      id
      name
      description
      key
      value
      created_at
      updated_at
    }
  }
`;

export const useGetSettings = () => {
  return useQuery(GET_SETTINGS);
};

export const useGetSettingById = options => {
  return useQuery(GET_SETTING_BY_ID, options);
};

export const useUpdateSettingById = () => {
  return useMutation(UPDATE_SETTING_BY_ID);
};
