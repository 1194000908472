// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_SQL_PROC_PERMISSION = gql`
  mutation GrantSqlProcPermission($name: String!, $sql_proc_name: String!) {
    sqlProcPermissionGrant(name: $name, sql_proc_name: $sql_proc_name) {
      name
      sql_proc_name
      statement
    }
  }
`;

export const REVOKE_SQL_PROC_PERMISSION = gql`
  mutation RevokeSqlProcPermission($name: String!, $sql_proc_name: String!) {
    sqlProcPermissionRevoke(name: $name, sql_proc_name: $sql_proc_name) {
      name
      sql_proc_name
      statement
    }
  }
`;

export const useGrantSqlProcPermission = () => {
  return useMutation(GRANT_SQL_PROC_PERMISSION);
};

export const useRevokeSqlProcPermission = () => {
  return useMutation(REVOKE_SQL_PROC_PERMISSION);
};
