import React from 'react';
import { Radio, DatePicker } from 'antd';
import parseISO from 'date-fns/parseISO';
import { useHistory, useLocation } from 'react-router-dom';
import store from 'store2';

const LOCALSTORAGE_NAMESPACE = 'workbench';

const { RangePicker } = DatePicker;
const TIME_RANGE_QUERY_KEY = 'r';
const DATE_RANGE_START_QUERY_KEY = 'st';
const DATE_RANGE_END_QUERY_KEY = 'et';
const TIME_RANGE_STORE_KEY = 'time_range';
const kagentStore = store.namespace(LOCALSTORAGE_NAMESPACE);

export function toAbsolute(relative) {
  const startTime = Date.now();
  return {
    start: new Date(startTime - relative * 1000),
    end: new Date(startTime),
  };
}

export function useTimeRange() {
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let stored = kagentStore.get(TIME_RANGE_STORE_KEY, {});
  if (typeof stored !== 'object') {
    stored = {};
  }
  let relative =
    stored.relative || Number(queryParams.get(TIME_RANGE_QUERY_KEY)) || null;
  let absolute = null;
  if (stored.absolute && stored.absolute.start && stored.absolute.end) {
    absolute = {
      start: parseISO(stored.absolute.start),
      end: parseISO(stored.absolute.end),
    };
  } else if (
    queryParams.has(DATE_RANGE_START_QUERY_KEY) &&
    queryParams.has(DATE_RANGE_END_QUERY_KEY)
  ) {
    absolute = {
      start: parseISO(queryParams.get(DATE_RANGE_START_QUERY_KEY)),
      end: parseISO(queryParams.get(DATE_RANGE_END_QUERY_KEY)),
    };
  }

  // Default to latest minute if neither absolute nor relative time ranges
  // have been selected.
  if (!absolute && !relative) {
    relative = 60;
  }

  function setRelative(relative) {
    queryParams.set(TIME_RANGE_QUERY_KEY, relative);
    queryParams.delete(DATE_RANGE_START_QUERY_KEY);
    queryParams.delete(DATE_RANGE_END_QUERY_KEY);
    kagentStore.set(TIME_RANGE_STORE_KEY, { relative });
    history.push({
      search: queryParams.toString(),
    });
  }

  // NOTE: We do not store absolute ranges in local storage because this would
  // make the user go back to a historical view of the data.
  function setAbsolute(start, end) {
    if (start && end) {
      // We save the absolute time range as ISO 8601 date strings.
      queryParams.set(DATE_RANGE_START_QUERY_KEY, start.format());
      queryParams.set(DATE_RANGE_END_QUERY_KEY, end.format());
      kagentStore.set(TIME_RANGE_STORE_KEY, {
        absolute: {
          start: start.format(),
          end: end.format(),
        },
      });
    } else {
      queryParams.delete(DATE_RANGE_START_QUERY_KEY);
      queryParams.delete(DATE_RANGE_END_QUERY_KEY);
      kagentStore.remove(TIME_RANGE_STORE_KEY);
    }
    queryParams.delete(TIME_RANGE_QUERY_KEY);
    history.push({
      search: queryParams.toString(),
    });
  }

  function getDateRange() {
    const startTime = Date.now();
    const startDate = absolute
      ? absolute.start.toDate()
      : new Date(startTime - relative * 1000);
    const endDate = absolute ? absolute.end.toDate() : new Date(startTime);

    return {
      startDate,
      endDate,
    };
  }

  return {
    relative,
    absolute,
    setRelative,
    setAbsolute,
    getDateRange,
  };
}

const TimeRangeSelector = props => {
  const { relative, absolute, setRelative, setAbsolute } = useTimeRange();

  function onTimeRangeChange(evt) {
    setRelative(Number(evt.target.value));
  }

  function onDateRangeChange(value) {
    if (value) {
      setAbsolute(value[0], value[1]);
    } else {
      setAbsolute(null, null);
    }
  }

  return (
    <span>
      <Radio.Group
        value={String(relative)}
        onChange={onTimeRangeChange}
        size="small"
        style={{ marginRight: '5px' }}
      >
        <Radio.Button value="60">1m</Radio.Button>
        <Radio.Button value="300">5m</Radio.Button>
        <Radio.Button value="1800">30m</Radio.Button>
        <Radio.Button value="3600">1h</Radio.Button>
        <Radio.Button value="21600">6h</Radio.Button>
        <Radio.Button value="86400">1d</Radio.Button>
      </Radio.Group>
      <RangePicker
        size="small"
        showTime={true}
        onChange={onDateRangeChange}
        format="YYYY-MM-DD HH:mm:ss"
        value={absolute ? [absolute.start, absolute.end] : null}
      ></RangePicker>
    </span>
  );
};

export default TimeRangeSelector;
