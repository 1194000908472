// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect, useLocation } from 'react-router-dom';

// App Imports
import { routes } from '../../setup/routes';

const ProtectedRoute = ({ children, ...rest }) => {
  const { user } = useSelector(state => state.auth);
  const location = useLocation();

  return (
    <Route
      {...rest}
      render={props =>
        user ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: routes.login,
              state: {
                redirect: location?.pathname,
              },
            }}
          />
        )
      }
    />
  );
};

export default ProtectedRoute;
