// Imports
import React, { useCallback, useContext } from 'react';

// App Imports
import GraphQLServices from '../../graphql/services';
import { BLOCK_TYPES } from '../../constants';

const comment = (str, block = false) => {
  return block ? `/*\n${str.trim()}\n*/` : `/* ${str} */`;
};

const emptyLine = _ => '';

const checkSemi = str => {
  return str.trim()
    ? str.trim().endsWith(';')
      ? str.trim()
      : `${str.trim()};`
    : '';
};

const rightPad = (str, length) => {
  const padLength = length - str.length;
  const pad = ' '.repeat(padLength);
  return `${str}${pad}`;
};

const getText = arr => {
  const process = obj => {
    if (typeof obj !== 'string' && typeof obj !== 'boolean') {
      if (Array.isArray(obj)) {
        return obj.map(cur2 => {
          return process(cur2);
        });
      } else {
        return Object.keys(obj).reduce((acc1, cur1) => {
          if (cur1 === 'text') {
            acc1.push(obj[cur1].trim().replace(/\/\*|\*\//g, ''));
          } else {
            acc1 = acc1.concat(process(obj[cur1]));
          }
          return acc1;
        }, []);
      }
    }
    return [];
  };
  return arr.reduce((acc, cur) => {
    acc = acc.concat(process(cur));
    return acc;
  }, []);
};

export function validateProcessedBlocks(originalBlocks, processedBlocks) {
  const missingBlocks = originalBlocks.filter(
    block => !processedBlocks.find(b => b.id === block.id)
  );

  if (missingBlocks.length > 0) {
    console.warn(
      `The following block ids were not processed (${
        missingBlocks.length
      }):\n${missingBlocks.map(block => block.id).join('\n')}`
    );
  }
}

// Basic validation of content, not really checking deeply into what
// properties might be expected for each.
export function validateBlockContent(type, content) {
  switch (type) {
    case BLOCK_TYPES.SQL:
      return typeof content === 'string';
    case BLOCK_TYPES.TEXT:
      return typeof content === 'object';
    case BLOCK_TYPES.IMAGE:
      return typeof content === 'object';
    case BLOCK_TYPES.HTML:
      return typeof content === 'object';
    case BLOCK_TYPES.MAP:
      return typeof content === 'object';
    case BLOCK_TYPES.GRAPH:
      return typeof content === 'object';
    default:
      return false;
  }
}

export function sortWorksheets(worksheets) {
  const processedWorksheets = [];

  // Start with first worksheet
  let curWorksheet = worksheets.find(
    worksheet => worksheet.previous_worksheet_id === null
  );

  // Keep track of visited worksheets so there is no possibility of infinite loops.
  const visitedWorksheets = [];

  // Visit worksheets by following next_worksheet_id.
  while (curWorksheet && !visitedWorksheets.includes(curWorksheet.id)) {
    processedWorksheets.push(curWorksheet);
    visitedWorksheets.push(curWorksheet.id);
    const nextWorksheetId = curWorksheet.next_worksheet_id;
    curWorksheet = worksheets.find(
      worksheet => worksheet.id === nextWorksheetId
    );
  }

  return processedWorksheets;
}

export function sortBlocks(blocks) {
  const processedBlocks = [];

  // Start with first block
  let curBlock = blocks.find(b => b.previous_block_id === null);

  // Keep track of visited blocks so there is no possibility of infinite loops.
  const visitedBlocks = [];

  // Visit blocks by following next_block_id and creating Slate objects.
  while (curBlock && !visitedBlocks.includes(curBlock.id)) {
    const newBlock = {
      ...curBlock,
    };

    // Include what is stored in database for block so it can be accessed by error block.
    if (newBlock.type === null) {
      newBlock.storedBlock = { ...curBlock };
    }

    processedBlocks.push(newBlock);
    visitedBlocks.push(curBlock.id);
    const nextBlockId = curBlock.next_block_id;
    curBlock = blocks.find(b => b.id === nextBlockId);
  }

  validateProcessedBlocks(blocks, processedBlocks);

  return processedBlocks;
}

// Based on https://stackoverflow.com/a/45831280 CC BY-SA 3.0
export function download(filename, text, mimeType = 'text/plain') {
  var element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${mimeType};charset=utf-8,` + encodeURIComponent(text)
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

export function useExportJsonWorkbook() {
  const [exportWorkbook] = GraphQLServices.Workbooks.useExportWorkbook();

  return useCallback(
    workbook => {
      exportWorkbook({
        variables: {
          id: workbook.id,
        },
      })
        .then(resp => {
          if (resp?.data?.workbookExport) {
            download(
              `${workbook.name}.json`,
              JSON.stringify(resp.data.workbookExport, null, 2),
              'application/json'
            );
          } else {
            console.error('workbookExport missing from response', resp);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [exportWorkbook]
  );
}

export function useExportAllJsonWorkbooks() {
  const [exportAllWorkbooks] =
    GraphQLServices.Workbooks.useExportAllWorkbooks();

  return useCallback(
    _ => {
      exportAllWorkbooks()
        .then(resp => {
          if (resp?.data?.workbookExportAll) {
            download(
              'all_workbooks.json',
              JSON.stringify(resp.data.workbookExportAll, null, 2),
              'application/json'
            );
          } else {
            console.error('workbookExportAll missing from response', resp);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [exportAllWorkbooks]
  );
}

export function useExportPerfReportWorkbook() {
  const [exportWorkbook] = GraphQLServices.Workbooks.useExportWorkbook();

  return useCallback(
    (workbookId, worksheetId, report) => {
      exportWorkbook({
        variables: {
          id: workbookId,
        },
      })
        .then(resp => {
          if (resp?.data?.workbookExport) {
            const { workbook, worksheets, blockMap } = resp.data.workbookExport;
            let sql = [];

            // Heading
            const workbookLabel = `Workbook: ${workbook.name}`;
            const workbookDesc = workbook.description;
            const maxLength = Math.max(
              workbookLabel.length,
              workbookDesc.length
            );
            sql.push(comment('-'.repeat(maxLength)));
            sql.push(comment(rightPad(workbookLabel, maxLength)));
            sql.push(comment(rightPad(workbookDesc, maxLength)));
            sql.push(comment('-'.repeat(maxLength)));

            let total = 0;

            // Per Worksheet
            sortWorksheets(worksheets)
              .filter(worksheet => worksheet.id === worksheetId)
              .forEach(worksheet => {
                const worksheetLabel = `Worksheet: ${worksheet.name}`;
                sql.push(emptyLine());
                sql.push(comment('-'.repeat(worksheetLabel.length)));
                sql.push(comment(worksheetLabel));
                sql.push(comment('-'.repeat(worksheetLabel.length)));
                sql.push(emptyLine());

                // Per Block
                sortBlocks(blockMap[worksheet.id])
                  .filter(
                    block =>
                      block.block_type_id === BLOCK_TYPES.SQL &&
                      report[block.id]?.queryResponse?.responses
                  )
                  .forEach(block => {
                    const { responses } = report[block.id]?.queryResponse;

                    sql.push(emptyLine());
                    sql.push(checkSemi(JSON.parse(block.content)));
                    sql.push(emptyLine());

                    let maxLength = 0;
                    const timesStr = [];
                    responses.forEach(response => {
                      total += parseFloat(
                        parseFloat(response.request_time_secs).toFixed(4)
                      );
                      const time = `Completed in ${parseFloat(
                        response.request_time_secs
                      ).toFixed(4)} (s)`;

                      if (time.length > maxLength) {
                        maxLength = time.length;
                      }

                      timesStr.push(comment(time));
                    });

                    sql.push(comment('-'.repeat(maxLength)));
                    sql = sql.concat(timesStr);
                    sql.push(comment('-'.repeat(maxLength)));
                    sql.push(emptyLine());
                    sql.push(emptyLine());
                  });
              });

            const totalStr = `All completed in: ${parseFloat(total).toFixed(
              4
            )} (s)`;
            sql.push(emptyLine());
            sql.push(comment('-'.repeat(totalStr.length)));
            sql.push(comment(totalStr));
            sql.push(comment('-'.repeat(totalStr.length)));

            download(
              `${workbook.name} Perf Report.txt`,
              sql.join('\n'),
              'text/plain'
            );
          } else {
            console.error('workbookExport missing from response', resp);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [exportWorkbook]
  );
}

export function useExportSqlWorkbook() {
  const [exportWorkbook] = GraphQLServices.Workbooks.useExportWorkbook();

  return useCallback(
    workbook => {
      exportWorkbook({
        variables: {
          id: workbook.id,
        },
      })
        .then(resp => {
          if (resp?.data?.workbookExport) {
            const { workbook, worksheets, blockMap } = resp.data.workbookExport;
            let sql = [];

            // Heading
            const workbookLabel = `Workbook: ${workbook.name}`;
            const workbookDesc = `Workbook Description: ${workbook.description}`;

            sql.push(comment(workbookLabel));
            sql.push(comment(workbookDesc));
            sql.push(emptyLine());

            // Per Worksheet
            sortWorksheets(worksheets)
              .filter(worksheet => worksheet.workbook_id === workbook.id)
              .forEach((worksheet, idx1) => {
                const worksheetLabel = `Worksheet: ${worksheet.name}`;
                const worksheetDesc = `Worksheet Description: ${worksheet.description}`;

                sql.push(emptyLine());
                sql.push(comment(worksheetLabel));
                sql.push(comment(worksheetDesc));
                sql.push(emptyLine());

                // Per Block
                sortBlocks(blockMap[worksheet.id])
                  .filter(
                    block =>
                      block.worksheet_id === worksheet.id &&
                      (block.block_type_id === BLOCK_TYPES.SQL ||
                        block.block_type_id === BLOCK_TYPES.TEXT)
                  )
                  .forEach((block, idx2) => {
                    if (block.block_type_id === BLOCK_TYPES.TEXT) {
                      sql.push(emptyLine());
                      const textArr = getText(JSON.parse(block.content)).map(
                        text => {
                          if (Array.isArray(text)) {
                            return text.join('\n');
                          }
                          return text;
                        }
                      );
                      sql.push(comment(`TEXT Block Start`));
                      sql = sql.concat(comment(textArr.join('\n'), true));
                      sql.push(comment(`TEXT Block End`));
                      sql.push(emptyLine());
                    } else if (block.content) {
                      try {
                        if (checkSemi(JSON.parse(block.content))) {
                          sql.push(emptyLine());
                          sql.push(comment(`SQL Block Start`));
                          sql.push(checkSemi(JSON.parse(block.content)));
                          sql.push(comment(`SQL Block End`));
                          sql.push(emptyLine());
                        }
                      } catch (error) {
                        // Just skip if we can't parse
                        sql.push(emptyLine());
                        sql.push('/* Count not parse content */');
                        sql.push(emptyLine());
                      }
                    }
                  });
              });

            download(`${workbook.name}.sql`, sql.join('\n'), 'application/sql');
          } else {
            console.error('workbookExport missing from response', resp);
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    [exportWorkbook]
  );
}

export const ReadOnlyContext = React.createContext(false);

export function useIsReadOnly() {
  return useContext(ReadOnlyContext);
}

export const PrintOnlyContext = React.createContext(false);

export function useIsPrintOnly() {
  return useContext(PrintOnlyContext);
}
