// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Row, Col, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { CREDENTIAL_TYPE_DOCKER } from '../../constants';
import { getTimestampKey } from '../../helper';

const ContainerRegistryCreateModal = ({
  visible,
  close,
  width = 750,
  callback,
}) => {
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [createContainerRegistry] =
    GraphQLServices.ContainerRegistries.useCreateContainerRegistry();

  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = async values => {
    const {
      name: registry_name,
      uri: registry_uri,
      email: useremail,
      password: secret,
    } = values;

    const variables = {
      registry_name,
      registry_uri,
      replace: false,
    };

    setIsCreating(true);

    const name = `${registry_name}_credential_autogen_${getTimestampKey()}`;
    const variables2 = {
      name,
      type: CREDENTIAL_TYPE_DOCKER,
      identity: 'userid',
      secret,
      useremail,
    };
    const createCredResp = await createCredential({
      variables: variables2,
    });
    if (createCredResp?.errors) {
      setIsCreating(false);
      return;
    }

    createContainerRegistry({
      variables: {
        ...variables,
        credential_name: name,
      },
    })
      .then(resp => {
        setIsCreating(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsCreating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const create = _ => {
    form.submit();
  };

  const formLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 16 },
  };

  const doubleInputLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };

  return (
    <Modal
      title="Create Container Registry"
      open={visible}
      width={width}
      bodyStyle={{ minHeight: '300px' }}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        {...formLayout}
        form={form}
        name="container_registry"
        layout="horizontal"
        initialValues={{}}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input container registry name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label="URI"
              name="uri"
              rules={[
                {
                  required: true,
                  message: 'Please input container registry URI!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={12}>
            <Form.Item
              {...doubleInputLayout}
              label="E-Mail"
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input credential e-mail!',
                  whitespace: true,
                },
              ]}
            >
              <Input placeholder="<registry user e-mail>" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              {...doubleInputLayout}
              label="Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input credential password!',
                  whitespace: true,
                },
              ]}
            >
              <Input type="password" placeholder="<password>" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ContainerRegistryCreateModal;
