// Imports
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import * as monaco from 'monaco-editor';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import WorkbookEditor from './WorkbookEditor';
import WorkbookCatalog from './WorkbookCatalog';

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

// Component
const DataExplore = () => {
  const { workbookId } = useParams();

  const { data: { tableTokens } = [] } =
    GraphQLServices.Tables.useGetTableTokens();

  const {
    loading: worksbooksLoading,
    data: { workbooks = undefined } = {},
    refetch: refetchWorkbooks,
  } = GraphQLServices.Workbooks.useGetWorkbooksOnly();

  useEffect(
    _ => {
      let disposable;
      if (tableTokens) {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }

        disposable = monaco.languages.registerCompletionItemProvider(
          'kinetica-sql',
          {
            provideCompletionItems() {
              const dynamicSuggestions = [];

              tableTokens.tables.forEach(table => {
                // Add fully qualified name
                dynamicSuggestions.push({
                  label: table ?? '',
                  kind: monaco.languages.CompletionItemKind.Value,
                  insertText: table,
                });
              });

              tableTokens.columns.forEach(column => {
                dynamicSuggestions.push({
                  label: column ?? '',
                  kind: monaco.languages.CompletionItemKind.Value,
                  insertText: column,
                });
              });

              return {
                suggestions: [...defaultSuggestions, ...dynamicSuggestions],
              };
            },
          }
        );
      }

      return _ => {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }
      };
    },
    [tableTokens]
  );

  return (
    <>
      <Breadcrumb style={{ margin: '9px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Explore</Breadcrumb.Item>
      </Breadcrumb>
      {workbookId && (
        <WorkbookEditor
          workbookId={workbookId}
          refetchWorkbooks={refetchWorkbooks}
        />
      )}
      {workbooks && !workbookId && (
        <WorkbookCatalog
          workbooks={workbooks}
          loading={worksbooksLoading}
          refetch={refetchWorkbooks}
        />
      )}
    </>
  );
};

export default DataExplore;
