// Imports
import React, { useMemo, useState } from 'react';
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  Divider,
  Space,
  Input,
  AutoComplete,
} from 'antd';
import {
  PlusOutlined,
  DeleteOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import MapPickerModal from '../../components/modal/MapPickerModal';
import { GET_GRAPH_BY_NAME } from '../../graphql/schema/graphs';
import { GET_TABLE_BY_NAME } from '../../graphql/schema/tables';
import {
  getGraphComponent as getComponent,
  isGraphComponent as isComponent,
  getGraphField as getField,
  getFieldInfo,
  formatGraphIdentifiers as formatIdentifiers,
} from '../../helper';
import {
  MIN_WMS_PREVIEW_MODAL_WIDTH,
  MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO,
} from '../../constants';
import { capitalizeSnakecase } from '../../formatter';

const { Option } = Select;

const SOLVE_GRAPH_MAPPINGS = {
  weights_on_edges: 'WEIGHTS',
  source_nodes: 'SOLVETARGETS',
  destination_nodes: 'SOLVETARGETS',
};

const GRAPH_TABLE_REGEX = /(?:graph_table|'graph_table')\s*=\s*'([^']+)'/;

const handleComponentValueFilter = (inputValue, option) => {
  return option.value.includes(inputValue);
};

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

const SOLVE_GRAPH_MAPPING = {
  weights_on_edges: 'WEIGHTS',
  source_nodes: 'SOLVETARGETS',
  destination_nodes: 'SOLVETARGETS',
};

const SolveGraphConfigureForm = ({
  form,
  fields,
  grammar,
  componentAdditions,
  setComponentAdditions,
  componentSelections,
  setComponentSelections,
  docs,
}) => {
  const { data: { tableColumnNames = [] } = {} } =
    GraphQLServices.Tables.useGetTableColumnNames();

  const graphqlClient = useApolloClient();

  const [wmsTable, setWmsTable] = useState(undefined);
  const [wmsTableDefaults, setWmsTableDefaults] = useState(undefined);
  const [showTableWms, setShowTableWms] = useState(false);

  const [currentMapField, setCurrentMapField] = useState(undefined);

  const handleComponentChange = field => idx => {
    const { configurations } = getComponent(
      field,
      grammar,
      SOLVE_GRAPH_MAPPINGS
    );
    setComponentSelections({
      ...componentSelections,
      [field.name]: configurations[idx]?.identifiers,
    });
  };

  const handleComponentAdd = field => idx => {
    const selection = componentSelections[field.name] ?? [];
    if (selection.length > 0) {
      const optionalsName = SOLVE_GRAPH_MAPPING[field.name] || field.name;
      const component = grammar?.components.find(comp => {
        return comp.name.toLowerCase() === optionalsName.toLowerCase();
      });
      const optionals = component ? component.addons : [];
      setComponentAdditions({
        ...componentAdditions,
        [field.name]: [
          ...(componentAdditions[field.name] ?? []),
          {
            set: selection
              .map(item => {
                return {
                  name: item,
                  value: '',
                  optional: false,
                };
              })
              .concat(
                optionals.map(item => {
                  return {
                    name: item,
                    value: '',
                    optional: true,
                  };
                })
              ),
          },
        ],
      });

      const selectionCopy = { ...componentSelections };
      delete selectionCopy[field.name];
      setComponentSelections(selectionCopy);
    }
  };

  const handleComponentDelete = (field, idx) => e => {
    const additionCopy = { ...componentAdditions };
    additionCopy[field.name].splice(idx, 1);
    setComponentAdditions({
      ...componentAdditions,
      [field.name]: [...additionCopy[field.name]],
    });
  };

  const handleComponentValueChange = (field, idx1, idx2, item) => value => {
    const updatedFieldAdditions = [...componentAdditions[field.name]];
    updatedFieldAdditions[idx1].set[idx2].value = value;
    setComponentAdditions({
      ...componentAdditions,
      [field.name]: updatedFieldAdditions,
    });
  };

  const columns = useMemo(
    _ => {
      return tableColumnNames.reduce((acc, cur) => {
        const { schema, name: table, columns } = cur;
        const update = columns.map(column => {
          return {
            value: [schema, table, column].join('.'),
          };
        });
        return [...acc, ...update];
      }, []);
    },
    [tableColumnNames]
  );

  const handleGeoPicker = (field, idx, item) => async e => {
    const graph_name = form.getFieldValue('graph_name');

    if (graph_name) {
      const graphResp = await graphqlClient.query({
        query: GET_GRAPH_BY_NAME,
        variables: {
          graph_name,
        },
      });

      if (!graphResp?.data?.graph) {
        return;
      }

      const { is_partitioned, graph_server_id, original_request } =
        graphResp?.data?.graph;

      let graphTable = null;

      if (original_request?.statement) {
        const graphTableMatch = GRAPH_TABLE_REGEX.exec(
          original_request?.statement
        );
        graphTable =
          graphTableMatch && graphTableMatch.length > 1 && graphTableMatch[1];
      } else {
        graphTable = original_request?.options?.graph_table;
      }

      if (graphTable && graphTable !== '') {
        const split = graphTable.split('.');
        const serverIds = graph_server_id.split(',');
        const table =
          is_partitioned && serverIds.length > 0
            ? {
                full: `${graphTable}_${serverIds[0]}`,
                schema: split.length > 1 ? split[0] : '',
                name:
                  split.length > 1
                    ? `${split[1]}_${serverIds[0]}`
                    : `${split[0]}_${serverIds[0]}`,
              }
            : {
                full: graphTable,
                schema: split.length > 1 ? split[0] : '',
                name: split.length > 1 ? split[1] : split[0],
              };

        const tableResp = await graphqlClient.query({
          query: GET_TABLE_BY_NAME,
          variables: {
            ...table,
          },
        });

        const { schema, name } = tableResp?.data?.table;
        setWmsTable({
          full: `${schema}.${name}`,
          schema,
          name,
        });
        setWmsTableDefaults({});
        setShowTableWms(true);
        setCurrentMapField({
          field,
          idx,
          item,
        });
      }
    } else {
      setWmsTable({
        full: '',
        schema: '',
        name: '',
      });
      setWmsTableDefaults({});
      setShowTableWms(true);
      setCurrentMapField({
        field,
        idx,
        item,
      });
    }
  };

  const selectionToValue = selection => {
    const values = selection.map(
      coords =>
        `POINT(${coords
          .map(coord => Number.parseFloat(coord).toPrecision(8))
          .join(' ')})`
    );
    return `{${values.map(value => `'${value}'`).join(', ')}}`;
  };

  const handleGeoSelection = currentField => selection => {
    if (selection.length === 0) {
      return;
    }

    const { field, idx, item } = currentField;
    const updatedFieldAdditions = [...componentAdditions[field.name]];
    if (item.name === 'NODE_WKTPOINT') {
      updatedFieldAdditions[idx].set[0].value = selectionToValue(selection);
    } else if (item.name === 'NODE_X' || item.name === 'NODE_Y') {
      const coords = selection[0];
      updatedFieldAdditions[idx].set[0].value = `{${Number.parseFloat(
        coords[0]
      ).toPrecision(8)}}`;
      updatedFieldAdditions[idx].set[1].value = `{${Number.parseFloat(
        coords[1]
      ).toPrecision(8)}}`;
    } else {
      return;
    }

    setComponentAdditions({
      ...componentAdditions,
      [field.name]: updatedFieldAdditions,
    });
  };

  return (
    <div>
      {fields
        .filter(
          field =>
            field.name !== 'options' &&
            !isComponent(field, grammar, SOLVE_GRAPH_MAPPINGS)
        )
        .map(field => {
          return (
            <Form.Item
              key={field.name}
              label={
                <>
                  {capitalizeSnakecase(field.name)}
                  {getFieldInfo(docs[field.name])}
                </>
              }
              name={field.name}
              rules={
                field.name === 'graph_name' && [
                  {
                    required: true,
                    message: 'Please input graph name!',
                    whitespace: true,
                  },
                ]
              }
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              {getField(field)}
            </Form.Item>
          );
        })}
      {fields
        .filter(
          field =>
            field.name !== 'options' &&
            isComponent(field, grammar, SOLVE_GRAPH_MAPPINGS)
        )
        .map(field => {
          return (
            <Form.Item
              key={field.name}
              label={
                <>
                  {capitalizeSnakecase(field.name)}
                  {getFieldInfo(docs[field.name])}
                </>
              }
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Row gutter={10}>
                <Col span={20}>
                  <Select
                    value={
                      componentSelections[field.name]
                        ? formatIdentifiers(componentSelections[field.name])
                        : ''
                    }
                    onChange={handleComponentChange(field)}
                    placeholder={`Select ${capitalizeSnakecase(
                      field.name
                    )} to add`.toUpperCase()}
                    style={{ fontSize: '13px' }}
                    dropdownStyle={{ fontSize: '12px' }}
                  >
                    {getComponent(
                      field,
                      grammar,
                      SOLVE_GRAPH_MAPPINGS
                    )?.configurations.map((configuration, index) => {
                      return (
                        <Option key={index} value={index}>
                          {formatIdentifiers(configuration?.identifiers)}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    onClick={handleComponentAdd(field)}
                    icon={<PlusOutlined />}
                    disabled={!componentSelections[field.name]}
                    block
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              {componentAdditions[field.name]?.map((addition, idx) => {
                const matches = ['NODE_WKTPOINT', 'NODE_X', 'NODE_Y'];
                const canSelect = addition.set.some(item =>
                  matches.includes(item.name)
                );
                return (
                  <div key={idx}>
                    <Divider style={{ margin: '12px 0' }} dashed />
                    <Row key={idx} gutter={10}>
                      <Col span={20} style={{ display: 'flex' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          {addition?.set.map((item, idx2) => {
                            return (
                              <Row key={idx2} gutter={10}>
                                <Col span={12}>
                                  {canSelect ? (
                                    <Input.Group compact>
                                      <AutoComplete
                                        value={item.value}
                                        options={columns}
                                        filterOption={
                                          handleComponentValueFilter
                                        }
                                        onChange={handleComponentValueChange(
                                          field,
                                          idx,
                                          idx2,
                                          item
                                        )}
                                        allowClear={true}
                                        style={{ width: 'calc(100% - 32px)' }}
                                        placeholder={
                                          item.optional && 'Optional'
                                        }
                                      />
                                      <Button
                                        icon={<GlobalOutlined />}
                                        onClick={handleGeoPicker(
                                          field,
                                          idx,
                                          item
                                        )}
                                      />
                                    </Input.Group>
                                  ) : (
                                    <AutoComplete
                                      value={item.value}
                                      options={columns}
                                      filterOption={handleComponentValueFilter}
                                      onChange={handleComponentValueChange(
                                        field,
                                        idx,
                                        idx2,
                                        item
                                      )}
                                      allowClear={true}
                                      placeholder={item.optional && 'Optional'}
                                    />
                                  )}
                                </Col>
                                <Col
                                  span={2}
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    lineHeight: '28px',
                                    color: '#999999',
                                  }}
                                >
                                  as
                                </Col>
                                <Col
                                  span={10}
                                  style={{
                                    fontSize: '12px',
                                    lineHeight: '28px',
                                  }}
                                >
                                  {item.name}
                                </Col>
                              </Row>
                            );
                          })}
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={handleComponentDelete(field, idx)}
                          icon={<DeleteOutlined />}
                          block
                        ></Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Form.Item>
          );
        })}
      {wmsTable && (
        <MapPickerModal
          table={wmsTable}
          defaults={wmsTableDefaults}
          visible={showTableWms}
          setVisible={visible => {
            setShowTableWms(visible);
            if (!visible) {
              setWmsTable(undefined);
              setWmsTableDefaults(undefined);
            }
          }}
          width={Math.max(
            Math.min(
              window.innerWidth - 300,
              MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO * (window.innerHeight - 200)
            ),
            MIN_WMS_PREVIEW_MODAL_WIDTH
          )}
          height={window.innerHeight - 200}
          initWithConfig={wmsTable && wmsTable.full !== '' ? true : false}
          handleGeoPicker={handleGeoSelection(currentMapField)}
        />
      )}
    </div>
  );
};

export default SolveGraphConfigureForm;
