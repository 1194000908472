// Imports
import React, { useState } from 'react';
import { Space, Button, Empty, Alert } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';

const VizImage = ({ viz, handleUpdate, minHeight = 180 }) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const isValidURL = url => {
    try {
      // eslint-disable-next-line no-unused-vars
      const test = new URL(url);
      return true;
    } catch (err) {
      return false;
    }
  };

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
          </Space>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <VizTitleBar config={config} />
        <div className="image" style={{ minHeight: minHeight - 20 }}>
          {config?.image_url ? (
            <div style={{ textAlign: config?.alignment || 'center' }}>
              {isValidURL(config?.image_url) ? (
                <img
                  src={config?.image_url}
                  style={{
                    height: config?.height
                      ? isNaN(config?.height)
                        ? config?.height
                        : `${config?.height}px`
                      : undefined,
                    width: config?.width
                      ? isNaN(config?.width)
                        ? config?.width
                        : `${config?.width}px`
                      : undefined,
                    maxWidth: '100%',
                  }}
                  alt="Block"
                />
              ) : (
                <Alert type="error" message="Invalid Image URL" banner />
              )}
            </div>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure Image"
            />
          )}
          {!readOnly && (
            <VizConfigDrawer
              title="Image Configuration"
              fields={viz?.visualization_type?.params?.fields}
              viz={viz}
              config={viz?.config}
              options={{}}
              isOpen={isVizConfigOpen}
              handleClose={handleCloseVizConfig}
              handleUpdate={handleUpdateVizConfig}
              columns={4}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VizImage;
