// Imports
import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { Modal, Form, Button, AutoComplete, Alert, Input } from 'antd';
import { FilePond } from 'react-filepond';
import { useApolloClient } from '@apollo/client';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import 'filepond/dist/filepond.min.css';

// App Imports
import GPUdb from '../../lib/GPUdb';
import { displayError, humanFileSize } from '../../helper';
import GraphQLServices from '../../graphql/services';
import { GET_FOLDER_BY_NAME } from '../../graphql/schema/files';

import {
  FILE_STATUSES,
  FILES_PERMISSION_WRITE,
  KIFS_UPLOAD_CHUNK_SIZE,
} from '../../constants';
import {
  getFileFormat,
  getTableFromFile,
} from '../../containers/importexport/utils';
import { APP_URL_API, FREE_SAAS } from '../../setup/config';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';

const { confirm } = Modal;

const FileUploadModal = ({ defaultFolder, visible, close, callback }) => {
  const { data: { folders = [] } = {}, refetch: refetchFolders } =
    GraphQLServices.Files.useGetFolders();
  const [createFolder] = GraphQLServices.Files.useCreateFolder();

  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const [folder, setFolder] = useState(defaultFolder);

  const graphqlClient = useApolloClient();
  const history = useHistory();

  const { emit } = useEvent(EVENT_TYPES.FILES_UPDATE);

  const [form] = Form.useForm();
  const uploaderRef = useRef(null);

  useEffect(() => {
    if (FREE_SAAS && !folder && folders.length > 0) {
      setFolder(folders[0].id);
      form.setFieldsValue({ folder: folders[0].id });
    }
  }, [folders, folder, form]);

  const freeData = useMemo(() => {
    if (folder && folders.length > 0) {
      const match = folders.find(item => {
        return item.name === folder;
      });
      if (match && match.data_limit && parseInt(match.data_limit) > -1) {
        return parseInt(match.data_limit) - parseInt(match.data_usage);
      }
    }
    return -1;
  }, [folders, folder]);

  const folderOptions = useMemo(
    _ => {
      return folders
        ? folders
            .filter(
              folder => folder.permission === FILES_PERMISSION_WRITE.value
            )
            .map(folder => {
              return {
                value: folder.name,
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  const onFinish = async values => {
    const { folder } = values;
    try {
      setIsUploading(true);
      setFolder(folder);
      const processedFiles = await uploaderRef.current.processFiles();
      setFiles(processedFiles);
    } catch (err) {
      displayError(err?.error?.body);
    } finally {
      setIsUploading(false);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setFolder(allValues.folder);
  };

  const handleUpload = useCallback(
    async _ => {
      const folder = form.getFieldValue('folder');

      // Can only upload to folder that starts with ~
      // if the folder already exists (system-generated)
      if (folder && folder.split('/')[0].startsWith('~')) {
        const resp = await graphqlClient.query({
          query: GET_FOLDER_BY_NAME,
          variables: {
            name: folder.split('/')[0],
          },
        });
        if (!resp?.data?.folder) {
          form.setFields([
            {
              name: 'folder',
              errors: ['Folder name cannot start with ~'],
            },
          ]);
          return;
        }
      }

      const filenames = files.map(
        (file, idx) => form.getFieldValue(`file_${idx}`) || file.filename
      );
      const existing = files
        .map((file, idx) => {
          return {
            ...file,
            uploadName: form.getFieldValue(`file_${idx}`) || file.filename,
          };
        })
        .filter(file => {
          const currentFolder = folders.find(item => item.name === folder);
          if (currentFolder) {
            return currentFolder.files.find(file =>
              filenames.includes(file.name)
            );
          }
          return false;
        });
      if (existing.length > 0) {
        confirm({
          title: 'Duplicate Filename(s) Detected',
          icon: <ExclamationCircleOutlined />,
          content: `The following filename(s) in '${folder}' are already in use: ${existing
            .map(file => `'${file.uploadName}'`)
            .join(
              ', '
            )}. If you continue with the upload, those files will be overwritten and the previous data will be lost. Do you want to continue?`,
          okText: 'Yes',
          onOk() {
            form.submit();
          },
          cancelText: 'No',
          centered: true,
        });
      } else {
        form.submit();
      }
    },
    [form, files, graphqlClient, folders]
  );

  const handleInit = e => {
    uploaderRef.current.browse();
  };

  const handleFilesUpdate = fileItems => {
    setFiles(fileItems);
  };

  const handleProcess = useCallback(
    async (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort,
      transfer,
      options
    ) => {
      const spaceRequired = files.reduce((acc, cur) => {
        return acc + cur.fileSize;
      }, 0);
      console.debug('Free', freeData, 'Requires', spaceRequired);

      if (freeData > -1 && spaceRequired > freeData) {
        error(
          `Upload requires ${humanFileSize(
            spaceRequired,
            true
          )} of free space. Only ${humanFileSize(
            freeData,
            true
          )} available in folder.`
        );
      } else {
        // Create a FileHandler
        const apiUrl = `${APP_URL_API}/proxy/dbapi`;
        const gpudb = new GPUdb(apiUrl, {
          timeout: 0,
        });
        const fileHandler = new GPUdb.FileHandler(gpudb);
        fileHandler.chunkSize = KIFS_UPLOAD_CHUNK_SIZE;

        const filename = files.map(
          (file, idx) => form.getFieldValue(`file_${idx}`) || file.filename
        )[files.findIndex(thisFile => thisFile.filename === file.name)];

        if (!folder.split('/')[0].startsWith('~')) {
          await createFolder({
            variables: {
              name: folder.split('/')[0],
              no_error_if_exists: true,
            },
          });
        }

        Object.defineProperty(file, 'name', {
          writable: true,
          value: filename,
        });
        Object.defineProperty(file, 'folder', {
          writable: true,
          value: folder,
        });

        fileHandler.upload(
          file,
          folder,
          { file_encoding: 'base64' },
          status => {
            progress(true, status || 0, 100);
          },
          (err3, resp3) => {
            if (err3) {
              error(err3.message);
            } else {
              load(resp3);
              refetchFolders();
              emit();
            }
          }
        );
      }

      return {
        abort: () => {
          abort();
        },
      };
    },
    [createFolder, emit, files, folder, form, freeData, refetchFolders]
  );

  const handleImport = _ => {
    if (files.length === 1) {
      const { folder, name } = files[0].file;
      history.push('/importexport/kifs', {
        datasource: { datasource_name: '' },
        filepath: `kifs://${folder}/${name}`,
        fileformat: getFileFormat(name),
        tableName: getTableFromFile(name),
      });
      close();
    }
  };

  const hasIncomplete = useMemo(
    _ => {
      return files.some(file => {
        return file.status !== FILE_STATUSES.PROCESSING_COMPLETE;
      });
    },
    [files]
  );

  const hasSingleFile = useMemo(
    _ => {
      return files.length === 1;
    },
    [files]
  );

  return (
    <Modal
      title="Upload File"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Close
        </Button>,
        hasSingleFile && !hasIncomplete && (
          <Button key="import" type="primary" onClick={handleImport} ghost>
            Import
          </Button>
        ),
        hasIncomplete && (
          <Button
            key="upload"
            type="primary"
            onClick={handleUpload}
            loading={isUploading}
          >
            Upload
          </Button>
        ),
      ]}
      onCancel={close}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="file"
        layout="vertical"
        initialValues={{ folder }}
        onFinish={onFinish}
        onValuesChange={onValuesChange}
        colon={false}
        preserve={false}
      >
        <Form.Item
          label="Folder"
          name="folder"
          rules={[
            {
              required: true,
              message: 'Please select a folder!',
            },
          ]}
        >
          <AutoComplete
            options={folderOptions}
            placeholder="New or existing folder name"
            notFoundContent="No folders found"
            disabled={FREE_SAAS}
            filterOption
          />
        </Form.Item>
        {freeData > -1 && (
          <Alert
            message={
              <>
                Available space:{' '}
                <strong>{humanFileSize(freeData, true)}</strong>
              </>
            }
            type="warning"
            showIcon={false}
            style={{ fontSize: '13px', marginBottom: 20 }}
            banner
          />
        )}
        <FilePond
          ref={uploaderRef}
          files={files}
          instantUpload={false}
          oninit={handleInit}
          onupdatefiles={handleFilesUpdate}
          allowMultiple={true}
          allowProcess={false}
          allowRemove={true}
          allowRevert={false}
          itemInsertLocation={'after'}
          chunkForce={true}
          chunkUploads={true}
          chunkSize={5000000}
          name="files"
          labelIdle='Drag & Drop your files or <span class="filepond--label-action">Browse</span>'
          credits={false}
          // server="/api/upload"
          server={{
            process: handleProcess,
          }}
        />
        {files.length > 0 &&
          files.some(
            file => file.status !== FILE_STATUSES.PROCESSING_COMPLETE
          ) && (
            <div style={{ padding: '0px 20px' }}>
              {files.map((file, idx) => {
                return (
                  <Form.Item
                    key={idx}
                    label={file.filename}
                    name={`file_${idx}`}
                    noStyle={file.status === FILE_STATUSES.PROCESSING_COMPLETE}
                  >
                    <Input
                      title={file.status}
                      placeholder="File name override"
                      type={
                        file.status !== FILE_STATUSES.PROCESSING_COMPLETE
                          ? 'text'
                          : 'hidden'
                      }
                    />
                  </Form.Item>
                );
              })}
            </div>
          )}
      </Form>
    </Modal>
  );
};

export default FileUploadModal;
