import { gql, useQuery } from '@apollo/client';

export const GET_CDATA_DRIVERS_SUMMARY = gql`
  query CDataDrivers(
    $driver_name: String
    $with_props: Boolean
    $visible_props_only: Boolean
    $filter: [String]
  ) {
    cdata_drivers(
      driver_name: $driver_name
      with_props: $with_props
      visible_props_only: $visible_props_only
      filter: $filter
    ) {
      route
      title
      type
      img {
        src
        alt
      }
      categories
    }
  }
`;

export const GET_CDATA_DRIVERS = gql`
  query CDataDrivers(
    $driver_name: String
    $with_props: Boolean
    $visible_props_only: Boolean
  ) {
    cdata_drivers(
      driver_name: $driver_name
      with_props: $with_props
      visible_props_only: $visible_props_only
    ) {
      route
      title
      description
      class_name
      url_prefix
      type
      img {
        src
        alt
      }
      categories
      docs
      properties {
        CatOrdinal
        Category
        Default
        ETC
        Hierarchy
        IsSessionProperty
        Name
        Ordinal
        PropertyName
        Required
        Sensitivity
        ShortDescription
        Type
        Value
        Values
        Visible
        alias
      }
    }
  }
`;

export const useGetCDataDriversSummary = options =>
  useQuery(GET_CDATA_DRIVERS_SUMMARY, options);

export const useGetCDataDrivers = options =>
  useQuery(GET_CDATA_DRIVERS, options);
