// Imports
import React, { useMemo, useState, useCallback, useContext } from 'react';
import { Table, Space, Button, Tag, Tooltip } from 'antd';
import {
  ClearOutlined,
  ExportOutlined,
  ConsoleSqlOutlined,
} from '@ant-design/icons';
import Papa from 'papaparse';

// App Imports
import { download } from './utils';
import {
  processExecuteSqlResults,
  isNumericTimestampColumnType,
} from '../../helper';
import { WorksheetContext } from '../../context';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import { formatFriendlyNumberWhole, truncateLong } from '../../formatter';

const truncate = (text, truncateLength) => {
  const regex = /\[(\d* characters truncated)\]/gi;
  const match = regex.exec(text);
  if (match) {
    return (
      <>
        {text.split(match[0])[0]}
        <Tag color="#f6f6f6" style={{ color: '#cccccc', marginLeft: '5px' }}>
          {match[1]}
        </Tag>
      </>
    );
  } else if (truncateLength > -1 && text.length > truncateLength) {
    const truncated = text.substring(0, truncateLength);
    const truncatedSize = text.length - truncated.length;
    return truncate(`${truncated}... [${truncatedSize} characters truncated]`);
  }
  return text;
};

const ResultTable = ({
  blockId,
  clearResult,
  queryResponse,
  defaultPageSize = 5,
  pageSizeOptions = ['1', '5', '10', '25', '50', '100'],
  showSizeChanger = true,
  showQuickJumper = true,
  clearLabel = 'Clear',
  clearIcon = <ClearOutlined />,
  truncateLength = -1,
  onChange = _ => {},
}) => {
  const { addSQLBlock = null } = useContext(WorksheetContext) ?? {};

  const [pageSize, setPageSize] = useState(defaultPageSize);

  const handleResultTableChange = pagination => {
    setPageSize(pagination.pageSize);
    onChange(pagination);
  };

  const renderColumn = useCallback(
    type => text => {
      return text !== null ? (
        !isNumericTimestampColumnType(type) ? (
          <div
            style={{
              textAlign: text.toString().length === 1 ? 'center' : 'left',
            }}
          >
            {truncate(text, truncateLength)}
          </div>
        ) : type === 'timestamp' ? (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {new Date(text).toISOString().replace(/T|Z/g, ' ').trim()}
          </div>
        ) : type === 'long' ? (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {truncateLong(text)}
          </div>
        ) : (
          <div
            style={{
              textAlign: 'right',
            }}
          >
            {text}
          </div>
        )
      ) : (
        <Tag color="#f6f6f6" style={{ color: '#cccccc' }}>
          null
        </Tag>
      );
    },
    [truncateLength]
  );

  const columns = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        return queryResponse.response.data.column_headers.map((column, idx) => {
          const type = queryResponse.response.data.column_datatypes[idx];
          return {
            key: column,
            title: column,
            dataIndex: column,
            type,
            numeric: isNumericTimestampColumnType(type),
            render: renderColumn(type),
          };
        });
      }
      return [];
    },
    [queryResponse, renderColumn]
  );

  const dataSource = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        return processExecuteSqlResults(queryResponse.response.data);
      }
      return [];
    },
    [queryResponse]
  );

  const pagination = useMemo(
    _ => {
      if (dataSource?.length > pageSize) {
        return {
          pageSize,
          pageSizeOptions,
          showSizeChanger,
          showQuickJumper,
        };
      } else {
        return false;
      }
    },
    [dataSource, pageSize, pageSizeOptions, showSizeChanger, showQuickJumper]
  );

  const scroll = useMemo(_ => {
    return {
      x: 'max-content',
    };
  }, []);

  const totalRecords = useMemo(
    _ => {
      if (queryResponse?.response?.total_number_of_records) {
        return queryResponse?.response?.total_number_of_records;
      }
      return null;
    },
    [queryResponse]
  );

  const replaceNulls = content => {
    return content
      .split('\n')
      .map(line => {
        let update = line
          .replaceAll(/([,]{2,})/g, key => {
            const delims = key.split(',');
            if (delims.length > 2) {
              delims.pop();
            }
            return delims.map(_ => ',').join('\\N');
          })
          .trim();
        if (update.slice(-1) === ',') {
          update = update + '\\N';
        }
        if (update.slice(0, 1) === ',') {
          update = '\\N' + update;
        }
        return update;
      })
      .join('\n');
  };

  const handleGenerateSQLBlock = useCallback(
    _ => {
      if (
        queryResponse?.response?.info?.last_endpoint ===
          '/generate/sql/internal' &&
        addSQLBlock
      ) {
        addSQLBlock(queryResponse?.response?.data?.column_1[0], blockId);
      }
    },
    [addSQLBlock, queryResponse, blockId]
  );

  const handleCSVDownload = useCallback(
    _ => {
      if (
        totalRecords > EXECUTE_SQL_LIMIT &&
        totalRecords >= 0 &&
        !window.confirm(
          `Only ${formatFriendlyNumberWhole(
            EXECUTE_SQL_LIMIT
          )} of ${formatFriendlyNumberWhole(
            totalRecords
          )} records will be exported due to system query limit. Would you like to continue?`
        )
      ) {
        return false;
      }

      const header = columns
        .map(column => column.key)
        .map(column => `"${column}"`)
        .join(',');
      const csv = Papa.unparse(
        dataSource.map(({ __key, ...rest }) => ({ ...rest })),
        {
          header: false,
          columns: columns.map(column => column.key),
          quotes: columns.map(column => !column.numeric),
        }
      );

      download('results.csv', `${header}\n${replaceNulls(csv)}`, 'text/csv');
    },
    [totalRecords, dataSource, columns]
  );

  return (
    <div>
      <div style={{ height: 30 }}>
        <Space style={{ float: 'right' }}>
          {queryResponse?.response?.info?.last_endpoint ===
            '/generate/sql/internal' && (
            <Button
              type="primary"
              onClick={handleGenerateSQLBlock}
              icon={<ConsoleSqlOutlined />}
              size="small"
              ghost
            >
              Generate SQL Block
            </Button>
          )}
          <Button
            onClick={handleCSVDownload}
            icon={<ExportOutlined />}
            size="small"
          >
            Export CSV
          </Button>
          <Button icon={clearIcon} size="small" onClick={clearResult}>
            {clearLabel}
          </Button>
        </Space>
        {totalRecords > EXECUTE_SQL_LIMIT && totalRecords >= 0 && (
          <Tooltip title="Due to system query limit">
            <label style={{ fontSize: '12px', color: '#3700b3' }}>
              Only{' '}
              <strong>{formatFriendlyNumberWhole(EXECUTE_SQL_LIMIT)}</strong> of{' '}
              <strong>{formatFriendlyNumberWhole(totalRecords)}</strong> records
              shown
            </label>
          </Tooltip>
        )}
      </div>
      <div>
        <Table
          className="result-table-fit"
          rowKey="__key"
          childrenColumnName="__kinetica_children__"
          columns={columns}
          dataSource={dataSource}
          onChange={handleResultTableChange}
          pagination={pagination}
          scroll={scroll}
          size="small"
        />
      </div>
    </div>
  );
};

export default ResultTable;
