// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_K8S_STATEFULSETS = gql`
  query K8sStatefulSets {
    k8s_statefulsets {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_STATEFULSET_BY_NAME = gql`
  query GetK8sStatefulSetByName($name: String!) {
    k8s_statefulset(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sStatefulSets = (options = {}) => {
  return useQuery(GET_K8S_STATEFULSETS, options);
};

export const useGetK8sStatefulSetByName = () => {
  return useQuery(GET_K8S_STATEFULSET_BY_NAME);
};
