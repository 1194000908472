// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_FUNC_ENVIRONMENTS = gql`
  query FuncEnvironments {
    func_environments {
      func_environment_name
      packages {
        package_name
        version
      }
    }
  }
`;

export const GET_FUNC_ENVIRONMENT_BY_NAME = gql`
  query FuncEnvironment($func_environment_name: String!) {
    func_environment(func_environment_name: $func_environment_name) {
      func_environment_name
      packages {
        package_name
        version
      }
    }
  }
`;

export const CREATE_FUNC_ENVIRONMENT = gql`
  mutation CreateFuncEnvironment($func_environment_name: String!) {
    funcEnvironmentCreate(func_environment_name: $func_environment_name) {
      func_environment_name
      packages {
        package_name
        version
      }
    }
  }
`;

export const INSTALL_FUNC_ENVIRONMENT_PACKAGES_BY_NAME = gql`
  mutation InstallFuncEnvironmentPackagesByName(
    $func_environment_name: String!
    $packages: [String]!
  ) {
    funcEnvironmentInstallPackagesByName(
      func_environment_name: $func_environment_name
      packages: $packages
    ) {
      func_environment_name
      packages {
        package_name
        version
      }
    }
  }
`;

export const UNINSTALL_FUNC_ENVIRONMENT_PACKAGES_BY_NAME = gql`
  mutation UninstallFuncEnvironmentPackagesByName(
    $func_environment_name: String!
    $packages: [String]!
  ) {
    funcEnvironmentUninstallPackagesByName(
      func_environment_name: $func_environment_name
      packages: $packages
    ) {
      func_environment_name
      packages {
        package_name
        version
      }
    }
  }
`;

export const DELETE_FUNC_ENVIRONMENT_BY_NAME = gql`
  mutation DeleteFuncEnvironmentByName($func_environment_name: String!) {
    funcEnvironmentDeleteByName(func_environment_name: $func_environment_name) {
      func_environment_name
    }
  }
`;

export const useGetFuncEnvironments = () => {
  return useQuery(GET_FUNC_ENVIRONMENTS);
};

export const useGetFuncEnvironmentByName = options => {
  return useQuery(GET_FUNC_ENVIRONMENT_BY_NAME, options);
};

export const useCreateFuncEnvironment = () => {
  return useMutation(CREATE_FUNC_ENVIRONMENT);
};

export const useInstallFuncEnvironmentPackagesByName = () => {
  return useMutation(INSTALL_FUNC_ENVIRONMENT_PACKAGES_BY_NAME);
};

export const useUninstallFuncEnvironmentPackagesByName = () => {
  return useMutation(UNINSTALL_FUNC_ENVIRONMENT_PACKAGES_BY_NAME);
};

export const useDeleteFuncEnvironmentByName = () => {
  return useMutation(DELETE_FUNC_ENVIRONMENT_BY_NAME);
};
