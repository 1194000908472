// Imports
import React from 'react';
import { Space } from 'antd';
import {
  DatabaseOutlined,
  BookOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';

// App Imports
import { setExplorerCurrentTab } from '../../store/app/actions';

// Component
const Explorer = ({ setCollapsed }) => {
  const dispatch = useDispatch();

  const handleDataClicked = _ => {
    dispatch(setExplorerCurrentTab('data'));
    setCollapsed(false);
  };

  const handleWorkbooksClicked = _ => {
    dispatch(setExplorerCurrentTab('workbooks'));
    setCollapsed(false);
  };

  const handleFilesClicked = _ => {
    dispatch(setExplorerCurrentTab('files'));
    setCollapsed(false);
  };

  return (
    <Space
      direction="vertical"
      size="large"
      style={{ margin: '20px 0px', width: '100%' }}
    >
      <div
        onClick={handleDataClicked}
        style={{
          textAlign: 'center',
          fontSize: '11px',
          color: '#3700b3',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <DatabaseOutlined style={{ fontSize: '25px' }} />
        <br />
        Data
      </div>
      <div
        onClick={handleWorkbooksClicked}
        style={{
          textAlign: 'center',
          fontSize: '11px',
          color: '#3700b3',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <BookOutlined style={{ fontSize: '25px' }} />
        <br />
        Workbooks
      </div>
      <div
        onClick={handleFilesClicked}
        style={{
          textAlign: 'center',
          fontSize: '11px',
          color: '#3700b3',
          width: '100%',
          cursor: 'pointer',
        }}
      >
        <CopyOutlined style={{ fontSize: '25px' }} />
        <br />
        Files
      </div>
    </Space>
  );
};

export default Explorer;
