// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Space, Row, Col, Card } from 'antd';
import {
  AreaChartOutlined,
  ArrowLeftOutlined,
  FileSearchOutlined,
  PlayCircleOutlined,
} from '@ant-design/icons';
import ReactPlayer from 'react-player';

// App Imports
import GraphQLServices from '../../graphql/services';
import RevealSplash from '../../images/reveal_dashboard.png';
import { DEPLOYMENT_TYPE } from '../../setup/config';

const RevealInfoModal = ({ visible, close }) => {
  const { data: { k8s_kineticaclusters: clusters } = {} } =
    GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });

  const [showVideo, setShowVideo] = useState(false);

  const cluster = useMemo(
    _ => {
      if (clusters && clusters.length > 0) {
        return clusters[0];
      }
      return null;
    },
    [clusters]
  );

  return (
    <Modal
      title=""
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Close
        </Button>,
      ]}
      width={940}
      onCancel={close}
      destroyOnClose
      closable={false}
      centered
    >
      <div style={{ height: '600px' }}>
        {showVideo ? (
          <div style={{ overflow: 'hidden', paddingBottom: '10px' }}>
            <div
              style={{
                background:
                  'linear-gradient(150deg, #9b45d8 15%, #5c48c1 70%, #5533ff 94%)',
                height: '280px',
                transform: 'skewY(-8deg)',
                transformOrigin: '0',
              }}
            ></div>
            <Button
              icon={<ArrowLeftOutlined />}
              onClick={_ => setShowVideo(false)}
              style={{
                position: 'absolute',
                top: '100px',
                right: '70px',
                fontSize: '22px',
                color: '#ffffff',
                border: 'none',
                padding: '5px',
              }}
              ghost
            >
              Back
            </Button>
            <div
              style={{
                position: 'relative',
                marginTop: '-220px',
                marginBottom: '120px',
                width: '35%',
                left: '45px',
                textShadow: '0 1px 3px rgba(0,0,0,.4)',
                lineHeight: '20px',
              }}
            >
              <h2 style={{ fontSize: '46px', color: '#ffffff' }}>Reveal</h2>
              <h4
                style={{
                  fontSize: '26px',
                  color: '#ffffff99',
                  fontWeight: '200',
                  marginBottom: '25px',
                }}
              >
                Visual Data Exploration
              </h4>
              <ReactPlayer
                url="https://www.youtube.com/watch?v=AaoSXIrlILQ"
                controls={true}
                width={800}
                height={450}
              />
            </div>
          </div>
        ) : (
          <>
            <div style={{ overflow: 'hidden', paddingBottom: '10px' }}>
              <div
                style={{
                  background:
                    'linear-gradient(150deg, #9b45d8 15%, #5c48c1 70%, #5533ff 94%)',
                  height: '280px',
                  transform: 'skewY(-8deg)',
                  transformOrigin: '0',
                }}
              ></div>
              <div
                style={{
                  position: 'absolute',
                  backgroundColor: '#ffffff66',
                  borderRadius: '35px',
                  float: 'right',
                  top: '160px',
                  right: '200px',
                  zIndex: '1',
                }}
              >
                <PlayCircleOutlined
                  onClick={_ => setShowVideo(true)}
                  style={{
                    fontSize: '70px',
                    color: '#9b45d8',
                  }}
                />
              </div>
              <img
                src={RevealSplash}
                style={{
                  position: 'absolute',
                  marginTop: '-240px',
                  width: '340px',
                  float: 'right',
                  right: '70px',
                  boxShadow:
                    '0 4px 6px rgba(50, 50, 93, .11), 0 1px 3px rgba(0, 0, 0, .08)',
                }}
                alt="Reveal Dashboard"
              />
              <div
                style={{
                  position: 'relative',
                  marginTop: '-220px',
                  marginBottom: '120px',
                  width: '35%',
                  left: '45px',
                  textShadow: '0 1px 3px rgba(0,0,0,.4)',
                  lineHeight: '20px',
                }}
              >
                <h2 style={{ fontSize: '46px', color: '#ffffff' }}>Reveal</h2>
                <h4
                  style={{
                    fontSize: '26px',
                    color: '#ffffff99',
                    fontWeight: '200',
                  }}
                >
                  Visual Data Exploration
                </h4>
                <Space style={{ marginTop: '20px' }}>
                  {cluster?.status?.ingressUrls?.reveal && (
                    <Button
                      href={cluster?.status?.ingressUrls?.reveal}
                      target="_blank"
                      icon={<AreaChartOutlined />}
                      size="large"
                      style={{ textShadow: 'none' }}
                    >
                      Launch
                    </Button>
                  )}
                  <Button
                    icon={<PlayCircleOutlined />}
                    onClick={_ => setShowVideo(true)}
                    size="large"
                  >
                    Video Tutorial
                  </Button>
                  <Button
                    href="https://docs.kinetica.com/7.1/analytics/reveal/"
                    target="_blank"
                    icon={<FileSearchOutlined />}
                    size="large"
                    style={{ textShadow: 'none' }}
                  >
                    Docs
                  </Button>
                </Space>
              </div>
            </div>
            <Row gutter={20}>
              <Col span={6}>
                <Card
                  title={
                    <label style={{ color: '#5c48c1' }}>
                      Drag and Drop Analytics
                    </label>
                  }
                  bordered={false}
                  size="small"
                >
                  <p>
                    Simply drag and drop data tables to slice and dice data and
                    start creating on-the-fly analytics dashboards. A SQL query
                    editor enables specific and custom queries too.
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title={
                    <label style={{ color: '#5c48c1' }}>
                      Rich Data Visualizations
                    </label>
                  }
                  bordered={false}
                  size="small"
                >
                  <p>
                    Reveal comes with dozens of visualizations for analyzing
                    data, as well as a flexible means to extend it's
                    capabilities yourself. See your data in tables, treemaps,
                    directed-force diagrams, line-graphs, and more.
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title={
                    <label style={{ color: '#5c48c1' }}>
                      Advanced Mapping Tools
                    </label>
                  }
                  bordered={false}
                  size="small"
                >
                  <p>
                    Reveal takes full advantage of Kinetica's geospatial
                    visualization pipeline for rendering unlimited data points
                    in map overlay layers. Overlays are rendered on the server,
                    with fast point-and-click interaction available through
                    Reveal.
                  </p>
                </Card>
              </Col>
              <Col span={6}>
                <Card
                  title={
                    <label style={{ color: '#5c48c1' }}>
                      Refined Access Control
                    </label>
                  }
                  bordered={false}
                  size="small"
                >
                  <p>
                    Enhanced security with fine-grained multilevel access
                    control for permission-based widgets, views and dashboards.
                  </p>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    </Modal>
  );
};

export default RevealInfoModal;
