// Imports
import React, { useMemo, useState, useEffect, useCallback } from 'react';
import {
  Button,
  Form,
  Select,
  Input,
  Slider,
  Row,
  Col,
  Space,
  Tooltip,
  AutoComplete,
  Collapse,
  Radio,
  Checkbox,
  Spin,
  Divider,
  Table,
  ConfigProvider,
} from 'antd';
import {
  InfoCircleOutlined,
  ReloadOutlined,
  DeleteOutlined,
  PlusOutlined,
  RightOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { ChromePicker } from 'react-color';
import rgbHex from 'rgb-hex';
import { transform, transformExtent } from 'ol/proj';
import OlMap from 'ol/Map';
import { useApolloClient } from '@apollo/client';
import * as ColorHelper from 'colormap';
import debounce from 'debounce';
import RCSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  DEFAULT_FILL_COLOR,
  DEFAULT_BORDER_COLOR,
  NIVO_COLOR_SCHEMES,
  MAP_RENDER_TYPES,
  MAP_RENDER_TYPE_HEATMAP,
  MAP_RENDER_TYPE_RASTER,
  MAP_RENDER_TYPE_CLASSBREAK,
  CB_TYPES,
  CB_NUM_CLASSES,
  CB_STANDARD_DEVIATIONS,
  CB_ROUND_CLASSES,
  CB_COLORMAPS,
  CB_TYPE_UNIQUE_VALUES,
  CB_TYPE_EQUAL_INTERVAL,
  GEOMETRY_TYPE_LONLAT,
  GEOMETRY_TYPE_WKT,
  MAP_COLORMAPS,
  NUMERIC_TYPES,
  CB_TYPE_DEFINED_INTERVAL,
  CB_TYPE_QUANTILE,
  CB_TYPE_STANDARD_DEVIATION,
  CB_TYPE_MANUAL_INTERVAL,
  WMS_PARAMS,
  SHAPES,
  MAP_RENDER_TYPE_TRACK,
  MAP_RENDER_TYPE_CONTOUR,
  MAX_AUTO_CLASS_BREAK_LEVELS,
  DEFAULT_AUTO_CLASS_BREAK_COLORMAP,
} from '../../constants';
import { invertColor, sleep } from '../../helper';
import Spinner from '../../components/common/Spinner';

const { Panel } = Collapse;
const { Option, OptGroup } = Select;
const { Column } = Table;

const createSliderWithTooltip = RCSlider.createSliderWithTooltip;
const Range = createSliderWithTooltip(RCSlider.Range);

const FIELD_DEFAULT_COLORS = {
  color: DEFAULT_BORDER_COLOR,
  bgColor: DEFAULT_FILL_COLOR,
  textColor: DEFAULT_BORDER_COLOR,
  fillColor: DEFAULT_FILL_COLOR,
  borderColor: DEFAULT_BORDER_COLOR,
  trackHeadColor: DEFAULT_FILL_COLOR,
  trackLineColor: DEFAULT_BORDER_COLOR,
  trackMarkerColor: DEFAULT_FILL_COLOR,
};

const DEBOUNCE_DURATION = 500;

const DEFAULT_RANGES = [0, 20, 40, 60, 80, 100];

const MapConfigDrawer = ({
  layerIdx,
  vizConfig,
  config,
  fields,
  tables,
  options,
  handleUpdate,
  handleOnChange,
  columns = 3,
  map,
  disabledFields = [],
  commitUpdate = false,
  setCommitUpdate = _ => {},
  moveLayerDown = _ => {},
  moveLayerUp = _ => {},
  multiLayer = true,
}) => {
  const [tableCollapse, setTableCollapse] = useState(['1']);
  const [symbologyCollapse, setSymbologyCollapse] = useState(['1']);
  const [viewportCollapse, setViewportCollapse] = useState(['1']);
  const [advancedCollapse, setAdvancedCollapse] = useState(['1']);

  const initialValues = fields.reduce((acc, cur) => {
    if (config[cur.name] !== undefined && config[cur.name] !== null) {
      acc[cur.name] =
        !Array.isArray(config[cur.name]) &&
        !isNaN(config[cur.name]) &&
        config[cur.name] !== '' &&
        cur.type !== 'color' // Need to treat all hex color codes as string
          ? Number(config[cur.name])
          : config[cur.name];
    } else {
      acc[cur.name] = cur.defaultValue || '';
    }
    return acc;
  }, {});

  const [configSchema, setConfigSchema] = useState('');

  const [geometryType, setGeometryType] = useState(
    config?.geometry_type || initialValues?.geometry_type
  );
  const [renderType, setRenderType] = useState(
    config?.renderType || initialValues?.renderType
  );

  const [cbAttr, setCbAttr] = useState(config?.cbAttr || initialValues?.cbAttr);
  const [cbType, setCbType] = useState(
    config?.cb_type || initialValues?.cb_type
  );
  const [cbColormap, setCbColormap] = useState(
    config?.cb_colormap || initialValues?.cb_colormap
  );
  const [cbIncludeOther, setCbIncludeOther] = useState(
    config?.cb_include_other || initialValues?.cb_include_other || false
  );

  const [cbVals, setCbVals] = useState(
    config?.cbVals || initialValues?.cbVals || []
  );
  const [cbColors, setCbColors] = useState(
    config?.cbColors || initialValues?.cbColors || []
  );
  const [cbShapes, setCbShapes] = useState(
    config?.cbShapes || initialValues?.cbShapes || []
  );
  const [cbColorsOpen, setCbColorsOpen] = useState(
    config?.cbColors
      ? config?.cbColors.map(color => false)
      : initialValues?.cbShapes
      ? initialValues?.cbColors.map(color => false)
      : []
  );
  const [isUpdatingVals, setIsUpdatingVals] = useState(false);
  const [uniqueCounts, setUniqueCounts] = useState({});

  const [overrides, setOverrides] = useState(
    config?.overrides || initialValues?.overrides || []
  );

  const [colorPicker, setColorPicker] = useState(
    fields
      .filter(field => field.type === 'color')
      .reduce((acc, cur) => {
        acc[cur.name] = {
          color:
            config[cur.name] ||
            cur.defaultValue ||
            FIELD_DEFAULT_COLORS[cur.name],
          open: false,
        };
        return acc;
      }, {})
  );

  const graphqlClient = useApolloClient();

  const [form] = Form.useForm();

  const [dynamicDisabledFields, setDynamicDisabledFields] = useState(
    fields.reduce((acc, cur) => {
      if (cur.disable) {
        acc[cur.name] =
          disabledFields.includes(cur.name) ||
          Object.keys(cur.disable).every(
            key => initialValues[key] === cur.disable[key]
          );
      }
      return acc;
    }, {})
  );

  const onFinish = values => {
    handleUpdate(values);
  };

  const update = useCallback(
    _ => {
      form.submit();
    },
    [form]
  );

  useEffect(
    _ => {
      setTimeout(_ => {
        if (commitUpdate) {
          update();
          setCommitUpdate(false);
        }
      }, layerIdx * 200);
    },
    [commitUpdate, setCommitUpdate, update, layerIdx]
  );

  const buildColormapOptions = field => {
    return {
      ...options,
      colorSchemes: NIVO_COLOR_SCHEMES,
      renderTypes: MAP_RENDER_TYPES,
      cbTypes: CB_TYPES,
      cbNumClasses: CB_NUM_CLASSES,
      cbStandardDeviations: CB_STANDARD_DEVIATIONS,
      cbRoundClasses: CB_ROUND_CLASSES,
      cbColormaps: CB_COLORMAPS,
      colormaps: MAP_COLORMAPS,
      shapes: SHAPES,
      shapesNoOriented: SHAPES.filter(
        shape => shape.indexOf('oriented_') === -1
      ),
    }[field.source].map(option => {
      const optionLabel = typeof option === 'object' ? option?.label : option;
      const optionValue =
        typeof option === 'object'
          ? option?.value.toLowerCase()
          : option.toLowerCase();
      const colors = ColorHelper({
        colormap: optionValue,
        nshades: 16,
        format: 'hex',
        alpha: 1,
      });
      const labelStyle = {
        position: 'absolute',
        textAlign: 'center',
        width: '90%',
        color: '#ffffff',
        fontSize: '11px',
      };
      const colorStyle = {
        display: 'inline-block',
        width: `${100 / 16}%`,
        height: '16px',
        margin: '3px 0px',
      };

      return (
        <Option key={optionValue} label={optionLabel} value={optionValue}>
          <div>
            <span style={labelStyle}>{optionLabel}</span>
            {colors.map((color, idx) => (
              <span
                key={idx}
                style={{ ...colorStyle, backgroundColor: color }}
              ></span>
            ))}
          </div>
        </Option>
      );
    });
  };

  const buildOptions = field => {
    const { columns, ...rest } = options;
    return {
      ...rest,
      columns: columns.map(column => column.name),
      colorSchemes: NIVO_COLOR_SCHEMES,
      renderTypes: MAP_RENDER_TYPES,
      cbTypes: CB_TYPES,
      cbNumClasses: CB_NUM_CLASSES,
      cbStandardDeviations: CB_STANDARD_DEVIATIONS,
      cbRoundClasses: CB_ROUND_CLASSES,
      cbColormaps: CB_COLORMAPS,
      colormaps: MAP_COLORMAPS,
      shapes: SHAPES,
      shapesNoOriented: SHAPES.filter(
        shape => shape.indexOf('oriented_') === -1
      ),
    }[field.source].map(option => {
      const optionLabel = typeof option === 'object' ? option?.label : option;
      const optionValue = typeof option === 'object' ? option?.value : option;
      return (
        <Option key={optionValue} label={optionLabel} value={optionValue}>
          {optionLabel}
        </Option>
      );
    });
  };

  const handleToggleColorPicker = colorField => _ => {
    setColorPicker({
      ...colorPicker,
      [colorField]: {
        ...colorPicker[colorField],
        open: !colorPicker[colorField].open,
      },
    });
  };

  const handleSelectColor = colorField => (color, e) => {
    const { r = 0, g = 0, b = 0, a = 1 } = color?.rgb;
    const hexColor = `${rgbHex(r, g, b, a)}`;
    form.setFieldsValue({ [colorField]: hexColor });
    setColorPicker({
      ...colorPicker,
      [colorField]: {
        ...colorPicker[colorField],
        color: hexColor,
      },
    });
  };

  const suggestions = useMemo(
    _ => {
      return {
        table_schema: tables
          ? Array.from(new Set(tables.map(table => table.schema)))
              .map(schema => ({
                value: schema,
              }))
              .sort((a, b) =>
                a.value.toLowerCase().localeCompare(b.value.toLowerCase())
              )
          : [],
        table_name: tables
          ? Array.from(
              new Set(
                tables
                  .filter(table => {
                    return configSchema !== ''
                      ? table.schema === configSchema
                      : true;
                  })
                  .map(table => table.name)
              )
            )
              .map(name => ({
                value: name,
              }))
              .sort((a, b) =>
                a.value.toLowerCase().localeCompare(b.value.toLowerCase())
              )
          : [],
        cbAttr: options.columns
          ? options.columns
              .map(column => ({
                value: column.name,
              }))
              .sort((a, b) =>
                a.value.toLowerCase().localeCompare(b.value.toLowerCase())
              )
          : [],
      };
    },
    [tables, options, configSchema]
  );

  const renderInput = (field, options = {}) => {
    switch (field.type) {
      case 'colormap':
        return (
          <Select
            showSearch
            placeholder="Select one"
            optionFilterProp="children"
            filterOption={true}
            disabled={disabledFields.includes(field.name)}
          >
            <Option value=""></Option>
            {buildColormapOptions(field)}
          </Select>
        );
      case 'select':
        return (
          <Select
            showSearch
            placeholder="Select one"
            optionFilterProp="children"
            filterOption={true}
            disabled={disabledFields.includes(field.name)}
          >
            <Option value=""></Option>
            {buildOptions(field)}
          </Select>
        );
      case 'radio':
        return (
          <Radio.Group options={field.options} style={{ margin: '0px 6px' }} />
        );
      case 'checkbox':
        return (
          <Checkbox
            id={`checkbox_${field.name}`}
            style={{ margin: '33px 8px 0px' }}
          >
            {getFieldLabel(field)}
          </Checkbox>
        );
      case 'range':
        const dynamicDisable = field.disable
          ? Object.keys(field.disable).every(fieldName => {
              if (activeParams[fieldName]) {
                return activeParams[fieldName] === field.disable[fieldName];
              }
              return false;
            })
          : dynamicDisabledFields[field.name];
        return (
          <Slider
            min={field.start}
            max={field.end}
            marks={{
              [field.start]: {
                label: field.start,
                style: { fontSize: '11px ' },
              },
              [field.end]: {
                label: field.end,
                style: { fontSize: '11px' },
              },
            }}
            step={field.step || 1}
            style={{ margin: '0px 5px' }}
            trackStyle={[
              {
                backgroundColor: '#3700B333',
                height: 12,
                marginTop: '-4px',
              },
            ]}
            handleStyle={[
              {
                backgroundColor: '#ffffff',
                borderColor: '#dddddd',
                borderWidth: '1px',
                height: 16,
                width: 10,
                borderRadius: '2px',
                marginTop: '-6px',
              },
            ]}
            railStyle={{ height: 12, marginTop: '-4px' }}
            dotStyle={{ display: 'none' }}
            disabled={disabledFields.includes(field.name) || dynamicDisable}
          />
        );
      case 'color':
        return (
          <>
            <Button
              onClick={handleToggleColorPicker(field.name)}
              style={{
                fontSize: '11px',
                backgroundColor: `#${colorPicker[field.name].color}`,
                borderColor: `#${colorPicker[field.name].color}`,
                color: `#${invertColor(
                  colorPicker[field.name].color.substring(0, 6),
                  true
                )}`,
              }}
              disabled={disabledFields.includes(field.name)}
              block
            >
              {`#${colorPicker[field.name].color}`}
            </Button>
            {colorPicker[field.name].open && (
              <div style={popover}>
                <div
                  style={cover}
                  onClick={handleToggleColorPicker(field.name)}
                />
                <ChromePicker
                  color={`#${colorPicker[field.name].color}`}
                  onChangeComplete={handleSelectColor(field.name)}
                  width={168}
                />
              </div>
            )}
          </>
        );
      case 'ranges':
        const cbMinValue = parseFloat(
          form.getFieldValue('cb_min_value') || cbVals[0] || 0
        );
        const cbMaxValue = parseFloat(
          form.getFieldValue('cb_max_value') || cbVals[cbVals.length - 1] || 1
        );
        return (
          !isNaN(cbMinValue) &&
          !isNaN(cbMaxValue) && (
            <div
              style={{
                padding: '0px 6px',
                marginTop: cbVals.length <= 17 ? '0px' : '-15px',
              }}
            >
              <Range
                min={cbMinValue}
                max={cbMaxValue}
                step={0.01}
                defaultValue={cbVals}
                onChange={handleCbValsChange}
                marks={cbVals.reduce((acc, cur) => {
                  acc[cur] = {
                    label: `${cur}`,
                    style: {
                      fontSize: cbVals.length <= 17 ? '11px' : '10px',
                      transform:
                        cbVals.length <= 17
                          ? 'translateX(-50%)'
                          : 'translateX(-50%) translateY(25%) rotate(-90deg)',
                    },
                  };
                  return acc;
                }, {})}
                trackStyle={ColorHelper({
                  colormap: cbColormap,
                  format: 'hex',
                  alpha: 1,
                })
                  .reduce((acc, cur, idx, arr) => {
                    if (arr.length / (cbVals.length - 1) >= 2) {
                      const mod = Math.floor(arr.length / (cbVals.length - 1));
                      if ((idx + Math.floor(mod / 2)) % mod === 0) {
                        acc.push(cur);
                      }
                    } else {
                      acc.push(cur);
                    }
                    return acc;
                  }, [])
                  .map(color => {
                    return {
                      backgroundColor: cbAttr ? color : '#f9f9f9',
                      height: 12,
                      marginTop: '-4px',
                    };
                  })}
                handleStyle={[
                  {
                    backgroundColor: cbAttr ? '#ffffff' : '#fcfcfc',
                    borderColor: cbAttr ? '#dddddd' : '#eeeeee',
                    borderWidth: '1px',
                    height: 16,
                    width: 10,
                    borderRadius: '2px',
                    marginTop: '-6px',
                  },
                ]}
                railStyle={{ height: 12, marginTop: '-4px' }}
                allowCross={false}
                pushable={false}
                draggableTrack={false}
                disabled={cbAttr === '' || cbType !== CB_TYPE_MANUAL_INTERVAL}
              />
            </div>
          )
        );
      default:
        if (field.name === 'table_schema') {
          return (
            <AutoComplete
              options={suggestions[field.name]}
              placeholder={
                field.placeholder != null
                  ? form.getFieldValue(field.placeholder)
                  : ''
              }
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              disabled={disabledFields.includes(field.name)}
              onChange={value => {
                setConfigSchema(value.trim());
              }}
              dropdownMatchSelectWidth={false}
            />
          );
        } else if (field.name === 'table_name') {
          return (
            <AutoComplete
              options={suggestions[field.name]}
              placeholder={
                field.placeholder != null
                  ? form.getFieldValue(field.placeholder)
                  : ''
              }
              filterOption={(inputValue, option) =>
                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
              disabled={disabledFields.includes(field.name)}
              dropdownMatchSelectWidth={false}
            />
          );
        } else if (field.name === 'cbAttr') {
          return (
            <AutoComplete
              options={suggestions[field.name]}
              placeholder={
                field.placeholder != null
                  ? form.getFieldValue(field.placeholder)
                  : ''
              }
              disabled={disabledFields.includes(field.name)}
              dropdownMatchSelectWidth={false}
            />
          );
        } else if (field.name === 'cb_min_value') {
          return (
            <Input
              addonAfter={
                <Tooltip title="Reset Minimum to Data">
                  <ReloadOutlined onClick={handleResetCbMinValue} />
                </Tooltip>
              }
              placeholder={
                field.placeholder != null
                  ? form.getFieldValue(field.placeholder)
                  : ''
              }
              disabled={disabledFields.includes(field.name)}
            />
          );
        } else if (field.name === 'cb_max_value') {
          return (
            <Input
              addonAfter={
                <Tooltip title="Reset Maximum to Data">
                  <ReloadOutlined onClick={handleResetCbMaxValue} />
                </Tooltip>
              }
              placeholder={
                field.placeholder != null
                  ? form.getFieldValue(field.placeholder)
                  : ''
              }
              disabled={disabledFields.includes(field.name)}
            />
          );
        }
        return (
          <Input
            placeholder={
              field.placeholder != null
                ? form.getFieldValue(field.placeholder)
                : ''
            }
            disabled={disabledFields.includes(field.name)}
          />
        );
    }
  };

  const handleUseCurrentViewport = useCallback(
    _ => {
      if (map) {
        const center = map.getView().getCenter();
        const zoom = map.getView().getZoom();
        const coords = transform(center, 'EPSG:3857', 'EPSG:4326');
        const lon = coords[0].toFixed(4);
        const lat = coords[1].toFixed(4);
        form.setFieldsValue({
          center_longitude: lon,
          center_latitude: lat,
          zoom_level: zoom,
        });
      }
    },
    [map, form]
  );

  const handleFitViewportData = useCallback(
    async _ => {
      const schema = form.getFieldValue('table_schema');
      const name = form.getFieldValue('table_name');
      const longitude = form.getFieldValue('longitude');
      const latitude = form.getFieldValue('latitude');
      const wkt = form.getFieldValue('wkt');
      if (map && name && ((longitude && latitude) || wkt)) {
        const tableResp = await graphqlClient.query({
          query: GraphQLServices.Tables.GET_TABLE_BY_NAME,
          variables: {
            schema,
            name,
          },
        });
        if (!tableResp?.data?.table) {
          return;
        }
        const extentResp = await graphqlClient.query({
          query: GraphQLServices.Tables.GET_TABLE_GEO_EXTENT,
          variables: {
            schema,
            name,
            longitude,
            latitude,
            wkt,
          },
        });
        const extent = extentResp?.data?.tableGeoExtent?.extent;
        if (extent) {
          const testMap = new OlMap({
            target: undefined,
            pixelRatio: 1.0,
          });
          testMap
            .getView()
            .fit(transformExtent(extent, 'EPSG:4326', 'EPSG:3857'));
          const center = testMap.getView().getCenter();
          const zoom = testMap.getView().getZoom() + 2;
          const coords = transform(center, 'EPSG:3857', 'EPSG:4326');
          const lon = coords[0].toFixed(4);
          const lat = coords[1].toFixed(4);
          form.setFieldsValue({
            center_longitude: lon,
            center_latitude: lat,
            zoom_level: zoom.toFixed(2),
          });
        }
      }
    },
    [map, form, graphqlClient]
  );

  const isColumnReal = useCallback(
    name => {
      const { columns = [] } = options;
      return columns.some(column => column.name === name);
    },
    [options]
  );

  const isColumnNumeric = useCallback(
    name => {
      const { columns = [] } = options;
      return columns.some(
        column => column.name === name && NUMERIC_TYPES.includes(column.type)
      );
    },
    [options]
  );

  const round = (value, precision) => {
    return Number(value).toFixed(precision);
  };

  const getUniqueCounts = useCallback(
    async (schema, table, cbAttr) => {
      const countsResp = await graphqlClient.query({
        query: GraphQLServices.Tables.GET_COLUMN_UNIQUE_COUNTS,
        variables: {
          table_name: `${schema}.${table}`,
          column_name: cbAttr,
          silent: true,
        },
      });
      return countsResp?.data?.column_unique_counts || { counts: {} };
    },
    [graphqlClient]
  );

  const getCustomColumnStats = async (schema, table, cbAttr, statsQuery) => {
    const statsResp = await graphqlClient.query({
      query: GraphQLServices.Tables.GET_CUSTOM_COLUMN_STATS,
      variables: {
        table_name: `${schema}.${table}`,
        column_name: cbAttr,
        stats: statsQuery,
        silent: true,
      },
    });
    return statsResp?.data?.column_custom_stat?.stats || {};
  };

  useEffect(
    _ => {
      const getCounts = async _ => {
        const schema = form.getFieldValue('table_schema');
        const table = form.getFieldValue('table_name');
        const cbAttr = form.getFieldValue('cbAttr');
        if (schema && table && cbAttr) {
          const { counts } = await getUniqueCounts(schema, table, cbAttr);
          setUniqueCounts(counts);
        }
      };
      getCounts();
    },
    [form, getUniqueCounts]
  );

  const getExpressionMinMax = async _ => {
    const schema = form.getFieldValue('table_schema');
    const table = form.getFieldValue('table_name');
    const cbAttr = form.getFieldValue('cbAttr');
    return getCustomColumnStats(schema, table, cbAttr, 'min,max');
  };

  const getStats = async _ => {
    const schema = form.getFieldValue('table_schema');
    const table = form.getFieldValue('table_name');
    const cbAttr = form.getFieldValue('cbAttr');
    const cbType = form.getFieldValue('cb_type');
    const cbNumClasses = parseInt(form.getFieldValue('cb_num_classes'));

    let statsQuery = '';
    if (
      cbType === CB_TYPE_MANUAL_INTERVAL ||
      cbType === CB_TYPE_EQUAL_INTERVAL ||
      cbType === CB_TYPE_DEFINED_INTERVAL
    ) {
      statsQuery = 'min,max';
    } else if (cbType === CB_TYPE_QUANTILE && cbNumClasses > 0) {
      statsQuery = 'min,max';
      let percentage = 0;
      while (percentage + 1 / cbNumClasses < 1) {
        percentage += 1 / cbNumClasses;
        statsQuery =
          statsQuery + `,percentile(${Math.round(percentage * 100)})`;
      }
    } else if (cbType === CB_TYPE_STANDARD_DEVIATION) {
      statsQuery = 'min,max,mean,stdv';
    } else if (cbType === CB_TYPE_UNIQUE_VALUES) {
      statsQuery = 'counts';
    } else {
      return {};
    }

    if (statsQuery === 'counts') {
      return getUniqueCounts(schema, table, cbAttr);
    } else {
      return getCustomColumnStats(schema, table, cbAttr, statsQuery);
    }
  };

  const resetMinMax = async (stats, update = ['min', 'max']) => {
    const cbType = form.getFieldValue('cb_type');
    const cbNumClasses = parseInt(form.getFieldValue('cb_num_classes'));
    const cbStandardDeviation = parseFloat(
      form.getFieldValue('cb_standard_deviation')
    );

    const { min: statsMin, max: statsMax, mean, stdv } = stats;

    if (cbType === CB_TYPE_STANDARD_DEVIATION) {
      const classes = Math.round(8 / cbStandardDeviation);
      const interval = stdv * cbStandardDeviation;
      const values = [];
      let start = mean - (classes / 2) * interval;
      for (let i = 0; i < classes; i++) {
        const end = start + interval;
        values.push(round(start, 2));
        start = end;
      }
      const ranges = Array.from(new Set(values));

      if (update.includes('min')) {
        form.setFieldsValue({
          cb_min_value: round(ranges[0], 2),
        });
      }
      if (update.includes('max')) {
        form.setFieldsValue({
          cb_max_value: round(ranges[ranges.length - 1], 2),
        });
      }
    } else if (cbType === CB_TYPE_QUANTILE) {
      const values = [];
      const percentages = [];
      if (cbNumClasses > 0) {
        let percentage = 0;
        while (percentage + 1 / cbNumClasses < 1) {
          percentage += 1 / cbNumClasses;
          percentages.push(Math.round(percentage * 100));
        }
      }
      values.push(statsMin);
      for (let i = 0; i < percentages.length; i++) {
        const percentage = percentages[i];
        values.push(round(stats[`percentile(${percentage})`], 2));
      }
      values.push(statsMax);
      const ranges = Array.from(new Set(values));

      if (update.includes('min')) {
        form.setFieldsValue({
          cb_min_value: round(ranges[0], 2),
        });
      }
      if (update.includes('max')) {
        form.setFieldsValue({
          cb_max_value: round(ranges[ranges.length - 1], 2),
        });
      }
    } else {
      if (update.includes('min')) {
        form.setFieldsValue({ cb_min_value: round(statsMin, 2) });
      }
      if (update.includes('max')) {
        form.setFieldsValue({ cb_max_value: round(statsMax, 2) });
      }
    }
  };

  const handleResetCbMinValue = async __Directive => {
    const stats = await getStats();
    await resetMinMax(stats, ['min']);
    await updateCbVals(stats);
  };

  const handleResetCbMaxValue = async _ => {
    const stats = await getStats();
    await resetMinMax(stats, ['max']);
    await updateCbVals(stats);
  };

  const updateCbVals = async (stats = {}) => {
    const cbType = form.getFieldValue('cb_type');
    const cbNumClasses = parseInt(form.getFieldValue('cb_num_classes'));
    const cbIntervalSize = parseFloat(form.getFieldValue('cb_interval_size'));
    const cbMaxIntervals = parseInt(form.getFieldValue('cb_max_intervals'));
    const cbStandardDeviation = parseFloat(
      form.getFieldValue('cb_standard_deviation')
    );
    const cbMinValue = parseFloat(form.getFieldValue('cb_min_value'));
    const cbMaxValue = parseFloat(form.getFieldValue('cb_max_value'));

    setIsUpdatingVals(true);
    await sleep(500);

    if (
      cbType === CB_TYPE_EQUAL_INTERVAL ||
      cbType === CB_TYPE_MANUAL_INTERVAL
    ) {
      const interval = round((cbMaxValue - cbMinValue) / cbNumClasses, 2);
      const values = [];
      for (let i = 0; i < cbNumClasses; i++) {
        values.push(round(i * interval + cbMinValue, 2));
      }
      values.push(cbMaxValue);
      const update = Array.from(new Set(values));
      setCbVals(update);
      form.setFieldsValue({ cbVals: update });
    } else if (cbType === CB_TYPE_DEFINED_INTERVAL) {
      let classes = Math.ceil((cbMaxValue - cbMinValue) / cbIntervalSize);
      if (classes > cbMaxIntervals) {
        classes = cbMaxIntervals;
      }
      const values = [];
      let start = cbMinValue;
      for (let i = 0; i <= classes; i++) {
        const end = Math.min(
          start + cbIntervalSize,
          cbMaxValue + cbIntervalSize * 0.01
        );
        values.push(round(start + cbIntervalSize, 2));
        start = end;
      }

      const update = Array.from(new Set(values));
      setCbVals(update);
      form.setFieldsValue({ cbVals: update });
    } else if (cbType === CB_TYPE_QUANTILE) {
      const values = [];
      const percentages = [];
      if (cbNumClasses > 0) {
        let percentage = 0;
        while (percentage + 1 / cbNumClasses < 1) {
          percentage += 1 / cbNumClasses;
          percentages.push(Math.round(percentage * 100));
        }
      }
      values.push(cbMinValue);
      for (let i = 0; i < percentages.length; i++) {
        const percentage = percentages[i];
        values.push(round(stats[`percentile(${percentage})`], 2));
      }
      values.push(cbMaxValue);

      const update = Array.from(new Set(values));
      setCbVals(update);
      form.setFieldsValue({ cbVals: update });
    } else if (cbType === CB_TYPE_STANDARD_DEVIATION) {
      const classes = Math.round(8 / cbStandardDeviation);
      const interval = stats.stdv * cbStandardDeviation;
      const values = [];
      let start = stats.mean - (classes / 2) * interval;
      for (let i = 0; i < classes; i++) {
        const end = start + interval;
        values.push(round(start, 2));
        start = end;
      }

      const update = Array.from(new Set(values));
      setCbVals(update);
      form.setFieldsValue({ cbVals: update });
    }

    setIsUpdatingVals(false);
  };

  const onValuesChange = debounce(async (changedValues, allValues) => {
    fields.forEach(field => {
      if (
        field.dynamicDefaultValue &&
        Object.keys(changedValues).includes(field.dynamicDefaultValue) &&
        form.getFieldValue(field.name) === ''
      ) {
        form.setFieldsValue({
          [field.name]: form.getFieldValue(field.dynamicDefaultValue),
        });
      }
    });

    // Disable fields based on rules
    const dynamicDisabledFieldsUpdate = {};
    fields.forEach(field => {
      if (
        field.disable &&
        Object.keys(field.disable).every(key => {
          return form.getFieldValue(key) === field.disable[key];
        })
      ) {
        dynamicDisabledFieldsUpdate[field.name] = true;
      } else if (field.disable && !disabledFields.includes(field.name)) {
        dynamicDisabledFieldsUpdate[field.name] = false;
      }
    });
    setDynamicDisabledFields({
      ...dynamicDisabledFields,
      ...dynamicDisabledFieldsUpdate,
    });

    if (
      parseInt(allValues?.center_longitude) === 0 &&
      parseInt(allValues?.center_latitude) === 0 &&
      layerIdx === 0
    ) {
      handleFitViewportData();
    }

    if (Object.keys(changedValues).includes('geometry_type')) {
      setGeometryType(changedValues['geometry_type']);
    }

    if (Object.keys(changedValues).includes('renderType')) {
      setRenderType(changedValues['renderType']);
    }

    if (Object.keys(changedValues).includes('cbAttr')) {
      const cbType = form.getFieldValue('cb_type');
      const value = changedValues['cbAttr'];
      setCbAttr(value);

      const isColumn = isColumnReal(value);
      let isNumeric = isColumnNumeric(value);

      if (!isColumn) {
        const expressMinMax = await getExpressionMinMax();
        isNumeric =
          expressMinMax.min !== undefined && expressMinMax.min !== undefined;
      }

      if (!isNumeric) {
        form.setFieldsValue({
          cb_type: CB_TYPE_UNIQUE_VALUES,
          cbVals: [],
          cbColors: [],
          cbShapes: [],
        });
        setCbType(CB_TYPE_UNIQUE_VALUES);
        setCbVals([]);
        setCbColors([]);
        setCbShapes([]);
        setCbColorsOpen([]);
      } else if (
        isNumeric &&
        (cbType === '' || cbType === CB_TYPE_UNIQUE_VALUES)
      ) {
        form.setFieldsValue({
          cb_type: CB_TYPE_EQUAL_INTERVAL,
          cbVals: DEFAULT_RANGES,
          cbColors: [],
          cbShapes: [],
        });
        setCbType(CB_TYPE_EQUAL_INTERVAL);
        setCbVals(DEFAULT_RANGES);
        setCbColors([]);
        setCbShapes([]);
        setCbColorsOpen([false]);
      }

      const stats = await getStats();
      await resetMinMax(stats);
      await updateCbVals(stats);

      if (!isNumeric) {
        if (
          stats.counts &&
          Object.keys(stats.counts).length <= MAX_AUTO_CLASS_BREAK_LEVELS
        ) {
          const colorSamples = ColorHelper({
            colormap: DEFAULT_AUTO_CLASS_BREAK_COLORMAP,
            nshades: Object.keys(stats.counts).length,
            format: 'hex',
            alpha: 1,
          }).map(hex => hex.replace(/#/gi, ''));
          const sortedValues = Object.keys(stats.counts).map((key, idx) => {
            return {
              val: key,
              color: colorSamples[idx],
              shape: 'circle',
              colorOpen: false,
            };
          });

          const vals = sortedValues.map(item => item.val);
          const colors = sortedValues.map(item => item.color);
          const shapes = sortedValues.map(item => item.shape);
          const colorsOpen = sortedValues.map(item => item.colorOpen);

          setCbVals(vals);
          setCbColors(colors);
          setCbShapes(shapes);
          setCbColorsOpen(colorsOpen);

          form.setFieldsValue({
            cbVals: vals,
            cbColors: colors,
            cbShapes: shapes,
          });
        } else {
          setCbVals(['']);
          setCbColors(['cccccc']);
          setCbShapes(['circle']);
          setCbColorsOpen([false]);

          form.setFieldsValue({
            cbVals: [''],
            cbColors: ['cccccc'],
            cbShapes: ['circle'],
          });
        }
      }

      if (stats.counts) {
        setUniqueCounts(stats.counts);
      }
    }

    if (Object.keys(changedValues).includes('cb_type')) {
      const value = changedValues['cb_type'];
      setCbType(value);

      if (value === CB_TYPE_UNIQUE_VALUES) {
        form.setFieldsValue({
          cb_type: CB_TYPE_UNIQUE_VALUES,
          cbVals: [],
          cbColors: [],
          cbShapes: [],
        });
        setCbType(CB_TYPE_UNIQUE_VALUES);
        setCbVals([]);
        setCbColors([]);
        setCbShapes([]);
        setCbColorsOpen([]);

        const stats = await getStats();

        if (
          stats.counts &&
          Object.keys(stats.counts).length <= MAX_AUTO_CLASS_BREAK_LEVELS
        ) {
          const colorSamples = ColorHelper({
            colormap: DEFAULT_AUTO_CLASS_BREAK_COLORMAP,
            nshades: Object.keys(stats.counts).length,
            format: 'hex',
            alpha: 1,
          }).map(hex => hex.replace(/#/gi, ''));
          const sortedValues = Object.keys(stats.counts).map((key, idx) => {
            return {
              val: key,
              color: colorSamples[idx],
              shape: 'circle',
              colorOpen: false,
            };
          });

          const vals = sortedValues.map(item => item.val);
          const colors = sortedValues.map(item => item.color);
          const shapes = sortedValues.map(item => item.shape);
          const colorsOpen = sortedValues.map(item => item.colorOpen);

          setCbVals(vals);
          setCbColors(colors);
          setCbShapes(shapes);
          setCbColorsOpen(colorsOpen);

          form.setFieldsValue({
            cbVals: vals,
            cbColors: colors,
            cbShapes: shapes,
          });
        } else {
          setCbVals(['']);
          setCbColors(['cccccc']);
          setCbShapes(['circle']);
          setCbColorsOpen([false]);

          form.setFieldsValue({
            cbVals: [''],
            cbColors: ['cccccc'],
            cbShapes: ['circle'],
          });
        }

        if (stats.counts) {
          setUniqueCounts(stats.counts);
        }
      } else {
        const stats = await getStats();
        await resetMinMax(stats);
        await updateCbVals(stats);
      }
    }

    if (Object.keys(changedValues).includes('cb_include_other')) {
      setCbIncludeOther(changedValues['cb_include_other']);
    }

    if (
      cbType === CB_TYPE_QUANTILE &&
      (Object.keys(changedValues).includes('cb_num_classes') ||
        Object.keys(changedValues).includes('cb_min_value') ||
        Object.keys(changedValues).includes('cb_max_value') ||
        Object.keys(changedValues).includes('cb_colormap'))
    ) {
      if (Object.keys(changedValues).includes('cb_colormap')) {
        setCbColormap(changedValues['cb_colormap']);
      }
      const stats = await getStats();
      await updateCbVals(stats);
    } else if (
      cbType === CB_TYPE_STANDARD_DEVIATION &&
      (Object.keys(changedValues).includes('cb_standard_deviation') ||
        Object.keys(changedValues).includes('cb_min_value') ||
        Object.keys(changedValues).includes('cb_max_value') ||
        Object.keys(changedValues).includes('cb_colormap'))
    ) {
      if (Object.keys(changedValues).includes('cb_colormap')) {
        setCbColormap(changedValues['cb_colormap']);
      }
      const stats = await getStats();
      await updateCbVals(stats);
    } else if (Object.keys(changedValues).includes('cb_colormap')) {
      setCbColormap(changedValues['cb_colormap']);
      await updateCbVals();
    } else if (
      Object.keys(changedValues).includes('cb_num_classes') ||
      Object.keys(changedValues).includes('cb_min_value') ||
      Object.keys(changedValues).includes('cb_max_value')
    ) {
      await updateCbVals();
    }

    if (
      Object.keys(changedValues).includes('cb_interval_size') ||
      Object.keys(changedValues).includes('cb_max_intervals')
    ) {
      await updateCbVals();
    }

    if (handleOnChange) {
      handleOnChange(changedValues, allValues);
    }
  }, DEBOUNCE_DURATION * 1.5);

  useEffect(
    _ => {
      if (options?.columns) {
        const LON_NAMES = { lon: 'partial', x: 'full' };
        const LAT_NAMES = { lat: 'partial', y: 'full' };
        const WKT_NAMES = [
          'kigeometry',
          'wkt',
          'geo',
          'shape',
          'isochrone',
          'path',
        ];
        if (form.getFieldValue('wkt') === '') {
          const match = WKT_NAMES.reduce((acc, cur) => {
            if (!acc) {
              acc = options?.columns
                .filter(column => column.properties.includes('wkt'))
                .map(column => column.name)
                .find(option => {
                  return option.toLowerCase().includes(cur.toLowerCase());
                });
            }
            return acc;
          }, null);
          if (match) {
            form.setFieldsValue({
              wkt: match,
            });
            if (
              form.getFieldValue('longitude') === '' &&
              form.getFieldValue('latitude') === ''
            ) {
              form.setFieldsValue({
                geometry_type: GEOMETRY_TYPE_WKT,
                renderType: MAP_RENDER_TYPE_RASTER,
              });
              setGeometryType(GEOMETRY_TYPE_WKT);
              setRenderType(MAP_RENDER_TYPE_RASTER);
            }
          }
        }

        if (form.getFieldValue('longitude') === '') {
          const match = Object.keys(LON_NAMES).reduce((acc, cur) => {
            if (!acc) {
              acc = options?.columns
                .map(column => column.name)
                .find(option => {
                  return LON_NAMES[cur] === 'partial'
                    ? option.toLowerCase().includes(cur)
                    : option.toLowerCase() === cur;
                });
            }
            return acc;
          }, null);
          if (match) {
            form.setFieldsValue({
              longitude: match,
            });
            if (form.getFieldValue('wkt') === '') {
              form.setFieldsValue({
                geometry_type: GEOMETRY_TYPE_LONLAT,
                renderType: MAP_RENDER_TYPE_HEATMAP,
              });
              setGeometryType(GEOMETRY_TYPE_LONLAT);
              setRenderType(MAP_RENDER_TYPE_HEATMAP);
            }
          }
        }

        if (form.getFieldValue('latitude') === '') {
          const match = Object.keys(LAT_NAMES).reduce((acc, cur) => {
            if (!acc) {
              acc = options?.columns
                .map(column => column.name)
                .find(option => {
                  return LAT_NAMES[cur] === 'partial'
                    ? option.toLowerCase().includes(cur)
                    : option.toLowerCase() === cur;
                });
            }
            return acc;
          }, null);
          if (match) {
            form.setFieldsValue({
              latitude: match,
            });
            if (form.getFieldValue('wkt') === '') {
              form.setFieldsValue({
                latitude: match,
                geometry_type: GEOMETRY_TYPE_LONLAT,
                renderType: MAP_RENDER_TYPE_HEATMAP,
              });
              setGeometryType(GEOMETRY_TYPE_LONLAT);
              setRenderType(MAP_RENDER_TYPE_HEATMAP);
            }
          }
        }

        if (
          parseInt(form.getFieldValue('center_longitude')) === 0 &&
          parseInt(form.getFieldValue('center_latitude')) === 0 &&
          layerIdx === 0
        ) {
          handleFitViewportData();
        }
      }
    },
    [form, options, handleFitViewportData, layerIdx]
  );

  const handleCollapseChange = type => keys => {
    switch (type) {
      case 'table':
        setTableCollapse(keys);
        break;
      case 'symbology':
        setSymbologyCollapse(keys);
        break;
      case 'viewport':
        setViewportCollapse(keys);
        break;
      case 'advanced':
        setAdvancedCollapse(keys);
        break;
      default:
    }
  };

  const getFieldLabel = field => {
    return field ? (
      field?.description ? (
        <>
          {field.label}
          <Tooltip title={field.description}>
            <InfoCircleOutlined
              style={{
                color: '#bbbbbb',
                marginLeft: '5px',
                marginBottom: '-2px',
              }}
            />
          </Tooltip>
        </>
      ) : (
        field.label
      )
    ) : null;
  };

  const getFieldRequired = field => {
    return field.required && !disabledFields.includes(field.name);
  };

  const renderFormItem = field => {
    return field.type !== 'checkbox' ? (
      <Form.Item
        label={getFieldLabel(field)}
        name={field.name}
        required={getFieldRequired(field)}
        hidden={field.type === 'hidden'}
        shouldUpdate
      >
        {renderInput(field)}
      </Form.Item>
    ) : (
      <Form.Item
        name={field.name}
        required={getFieldRequired(field)}
        valuePropName="checked"
        hidden={field.type === 'hidden'}
        shouldUpdate
      >
        {renderInput(field)}
      </Form.Item>
    );
  };

  const fieldsMap = useMemo(
    _ => {
      return fields
        ? fields.reduce((acc, cur) => {
            acc[cur.name] = cur;
            return acc;
          }, {})
        : {};
    },
    [fields]
  );

  const getFieldColumn = (fieldName, span, override = {}) => {
    const field = {
      ...fieldsMap[fieldName],
      ...override,
    };
    return fieldName ? (
      field.type !== 'hidden' ? (
        <Col key={fieldName} span={span ?? 24 / columns}>
          {renderFormItem(field)}
        </Col>
      ) : (
        renderFormItem(field)
      )
    ) : (
      <Col
        key={`empty_${Math.random() * 999999 + 1000000}`}
        span={24 / columns}
      ></Col>
    );
  };

  const getSymbologyHeader = renderType => {
    return MAP_RENDER_TYPES.find(type => type?.value === renderType)?.label;
  };

  const getPanelHeader = title => {
    return (
      <span
        style={{
          color: '#3700b3',
          fontWeight: 500,
          fontSize: '16px',
        }}
      >
        {title}
      </span>
    );
  };

  const handleCbValsChange = vals => {
    form.setFieldsValue({ cbVals: vals });
    setCbVals(vals);
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  const dividerStyle = { margin: '0px 10px', padding: '0px' };
  const panelStyle = { borderBottom: '0px', padding: '0px' };
  const panelContentStyle = {
    backgroundColor: '#ffffff',
    marginTop: '-15px',
  };

  const handleAddCbUnique = _ => {
    const vals = [...cbVals, ''];
    const colors = [...cbColors, 'cccccc'];
    const shapes = [...cbShapes, 'circle'];
    const colorsOpen = [...cbColorsOpen, false];

    form.setFieldsValue({ cbVals: vals, cbColors: colors, cbShapes: shapes });

    setCbVals(vals);
    setCbColors(colors);
    setCbShapes(shapes);
    setCbColorsOpen(colorsOpen);
  };

  const handleUpdateCbUniqueVal = record => e => {
    const { key } = record;
    const vals = [...cbVals];
    vals.splice(key, 1, e.target.value);
    form.setFieldsValue({ cbVals: vals });
    setCbVals(vals);
  };

  const handleDeleteCbUnique = record => _ => {
    const { key } = record;

    const vals = [...cbVals];
    const colors = [...cbColors];
    const shapes = [...cbShapes];
    const colorsOpen = [...cbColorsOpen];

    vals.splice(key, 1);
    colors.splice(key, 1);
    shapes.splice(key, 1);
    colorsOpen.splice(key, 1);

    form.setFieldsValue({ cbVals: vals, cbColors: colors, cbShapes: shapes });

    setCbVals(vals);
    setCbColors(colors);
    setCbShapes(shapes);
    setCbColorsOpen(colorsOpen);
  };

  const handleToggleCbColorPicker = record => _ => {
    const colorsOpen = [...cbColorsOpen];
    colorsOpen.splice(record.key, 1, !cbColorsOpen[record.key]);
    setCbColorsOpen(colorsOpen);
  };

  const handleSelectCbColor = record => (color, e) => {
    const { key } = record;
    const { r = 0, g = 0, b = 0, a = 1 } = color?.rgb;
    const hexColor = `${rgbHex(r, g, b, a)}`;
    const colors = [...cbColors];
    colors.splice(key, 1, hexColor);
    form.setFieldsValue({ cbColors: colors });
    setCbColors(colors);
  };

  const handleUpdateOverrideName = (record, idx) => name => {
    const copy = [...overrides];
    copy.splice(idx, 1, {
      ...overrides[idx],
      name,
    });
    form.setFieldsValue({ overrides: copy });
    setOverrides(copy);
  };

  const handleUpdateOverrideValue = (record, idx) => e => {
    const copy = [...overrides];
    copy.splice(idx, 1, {
      ...overrides[idx],
      value: e.target.value,
    });
    form.setFieldsValue({ overrides: copy });
    setOverrides(copy);
  };

  const handleAddOverride = () => {
    const copy = [
      ...overrides,
      {
        name: '',
        value: '',
      },
    ];
    form.setFieldsValue({ overrides: copy });
    setOverrides(copy);
  };

  const handleDeleteOverride = (record, idx) => e => {
    const copy = [...overrides];
    copy.splice(idx, 1);
    form.setFieldsValue({ overrides: copy });
    setOverrides(copy);
  };

  const cbDatasource = useMemo(
    _ => {
      return cbVals
        .map((value, idx) => {
          return {
            key: idx,
            value,
            color: cbColors[idx] || 'cccccc',
            shape: cbShapes[idx] || 'circle',
            count: uniqueCounts[value] || 0,
            disabled: false,
          };
        })
        .concat(
          cbIncludeOther
            ? {
                key: cbVals.length,
                value: '<other>',
                color: '666666',
                shape: 'circle',
                count: -1,
                disabled: true,
              }
            : []
        );
    },
    [cbVals, cbColors, cbShapes, cbIncludeOther, uniqueCounts]
  );

  const activeParams = useMemo(
    _ => {
      return {
        renderType,
        geometry_type: geometryType,
      };
    },
    [renderType, geometryType]
  );

  const handleLayerDown = useCallback(
    _ => {
      moveLayerDown(layerIdx);
    },
    [layerIdx, moveLayerDown]
  );

  const handleLayerUp = useCallback(
    _ => {
      moveLayerUp(layerIdx);
    },
    [layerIdx, moveLayerUp]
  );

  return (
    <Form
      form={form}
      name="config"
      layout="vertical"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      colon={false}
      preserve={false}
      size="small"
    >
      {multiLayer && (
        <Space style={{ float: 'right', marginTop: 10 }}>
          <Button
            onClick={handleLayerDown}
            icon={<LeftOutlined />}
            disabled={layerIdx <= 1}
          />
          <Button
            onClick={handleLayerUp}
            icon={<RightOutlined />}
            disabled={layerIdx <= 0 || layerIdx >= vizConfig.length - 1}
          />
        </Space>
      )}
      <Space direction="vertical" style={{ width: '100%' }}>
        <Collapse
          activeKey={tableCollapse}
          onChange={handleCollapseChange('table')}
          expandIconPosition="end"
          ghost={tableCollapse.length > 0}
          bordered={false}
        >
          <Panel
            header={getPanelHeader('Table Options')}
            key="1"
            style={panelStyle}
          >
            <div style={panelContentStyle}>
              <Row gutter={16}>
                {getFieldColumn('table_schema')}
                {getFieldColumn('table_name')}
                {getFieldColumn('geometry_type')}
                {geometryType === GEOMETRY_TYPE_LONLAT && (
                  <>
                    {getFieldColumn('longitude')}
                    {getFieldColumn('latitude')}
                  </>
                )}
                {geometryType === GEOMETRY_TYPE_WKT && (
                  <>{getFieldColumn('wkt')}</>
                )}
                {getFieldColumn('renderType')}
              </Row>
            </div>
          </Panel>
        </Collapse>
        <Divider style={dividerStyle} dashed />
        <Collapse
          activeKey={symbologyCollapse}
          onChange={handleCollapseChange('symbology')}
          expandIconPosition="end"
          ghost={symbologyCollapse.length > 0}
          bordered={false}
        >
          <Panel
            header={getPanelHeader(`${getSymbologyHeader(renderType)} Options`)}
            key="1"
            style={panelStyle}
          >
            <div style={panelContentStyle}>
              <Row gutter={16}>
                {renderType === MAP_RENDER_TYPE_HEATMAP && (
                  <>
                    {getFieldColumn('colormap')}
                    {getFieldColumn('blurRadius')}
                  </>
                )}
                {renderType === MAP_RENDER_TYPE_RASTER &&
                  geometryType === GEOMETRY_TYPE_LONLAT && (
                    <>
                      {getFieldColumn('fillColor', null, {
                        label: 'Point Color',
                      })}
                      {getFieldColumn('pointSize')}
                      {getFieldColumn()}
                    </>
                  )}
                {renderType === MAP_RENDER_TYPE_RASTER &&
                  geometryType === GEOMETRY_TYPE_WKT && (
                    <>
                      {getFieldColumn('fillColor')}
                      {getFieldColumn('borderColor')}
                      {getFieldColumn()}
                      {getFieldColumn('pointSize')}
                      {getFieldColumn('lineWidth')}
                    </>
                  )}
                {renderType === MAP_RENDER_TYPE_CLASSBREAK && (
                  <>
                    {getFieldColumn('cbAttr')}
                    {cbAttr !== '' && (
                      <>
                        {getFieldColumn('cb_type')}
                        {cbType !== '' && cbType === CB_TYPE_UNIQUE_VALUES && (
                          <>
                            {getFieldColumn('cb_include_other')}
                            <Col span={24}>
                              <Form.Item label="Values">
                                <ConfigProvider
                                  renderEmpty={_ => {
                                    return (
                                      <p style={{ margin: '5px 10px' }}>
                                        No Values
                                      </p>
                                    );
                                  }}
                                >
                                  <Table
                                    dataSource={cbDatasource}
                                    pagination={false}
                                    rowKey="key"
                                    size="small"
                                  >
                                    <Column
                                      title="Color"
                                      dataIndex="color"
                                      key="color"
                                      width={120}
                                      render={(text, record) => {
                                        return (
                                          <>
                                            <Button
                                              onClick={handleToggleCbColorPicker(
                                                record
                                              )}
                                              style={{
                                                fontSize: '11px',
                                                backgroundColor: `#${text}`,
                                                borderColor: `#${text}`,
                                                color: `#${invertColor(
                                                  text.substring(0, 6),
                                                  true
                                                )}`,
                                              }}
                                              disabled={record.disabled}
                                              block
                                            >
                                              {`#${text}`}
                                            </Button>
                                            {cbColorsOpen[record.key] && (
                                              <div style={popover}>
                                                <div
                                                  style={cover}
                                                  onClick={handleToggleCbColorPicker(
                                                    record
                                                  )}
                                                />
                                                <ChromePicker
                                                  color={`#${
                                                    cbColors[record.key]
                                                  }`}
                                                  onChangeComplete={handleSelectCbColor(
                                                    record
                                                  )}
                                                  width={168}
                                                />
                                              </div>
                                            )}
                                          </>
                                        );
                                      }}
                                    />
                                    <Column
                                      title="Label"
                                      dataIndex="value"
                                      key="value"
                                      render={(text, record) => {
                                        return (
                                          <Input
                                            value={text}
                                            onChange={handleUpdateCbUniqueVal(
                                              record
                                            )}
                                            placeholder="Enter a unique value"
                                            disabled={record.disabled}
                                          />
                                        );
                                      }}
                                    />
                                    <Column
                                      title="Count"
                                      dataIndex="count"
                                      key="count"
                                      width={120}
                                      render={(_, record) => {
                                        return (
                                          <div style={{ textAlign: 'right' }}>
                                            {record.count > -1
                                              ? record.count
                                              : '-'}
                                          </div>
                                        );
                                      }}
                                    />
                                    <Column
                                      title={
                                        <PlusOutlined
                                          onClick={handleAddCbUnique}
                                          style={{
                                            fontSize: '16px',
                                            margin: '0px 3px',
                                          }}
                                        />
                                      }
                                      dataIndex="key"
                                      key="key"
                                      width={1}
                                      render={(_, record) => {
                                        const disabledStyle = record.disabled
                                          ? { color: '#eeeeee' }
                                          : {};
                                        return (
                                          <DeleteOutlined
                                            onClick={handleDeleteCbUnique(
                                              record
                                            )}
                                            style={{
                                              fontSize: '16px',
                                              margin: '0px 3px',
                                              ...disabledStyle,
                                            }}
                                            disabled={record.disabled}
                                          />
                                        );
                                      }}
                                    />
                                  </Table>
                                </ConfigProvider>
                              </Form.Item>
                            </Col>
                            {getFieldColumn('cbVals', null, {
                              type: 'hidden',
                            })}
                            {getFieldColumn('cbColors')}
                            {getFieldColumn('cbShapes')}
                            {getFieldColumn('pointSize')}
                            {geometryType === GEOMETRY_TYPE_WKT &&
                              getFieldColumn('lineWidth')}
                          </>
                        )}
                        {cbType !== '' && cbType !== CB_TYPE_UNIQUE_VALUES && (
                          <>
                            {(cbType === CB_TYPE_MANUAL_INTERVAL ||
                              cbType === CB_TYPE_EQUAL_INTERVAL ||
                              cbType === CB_TYPE_QUANTILE) &&
                              getFieldColumn('cb_num_classes')}
                            {cbType === CB_TYPE_DEFINED_INTERVAL && (
                              <>
                                {getFieldColumn()}
                                {getFieldColumn('cb_interval_size')}
                                {getFieldColumn('cb_max_intervals')}
                                {getFieldColumn()}
                              </>
                            )}
                            {cbType === CB_TYPE_STANDARD_DEVIATION &&
                              getFieldColumn('cb_standard_deviation')}
                            {getFieldColumn('cb_min_value')}
                            {getFieldColumn('cb_max_value')}
                            {getFieldColumn('cb_colormap')}
                            {isUpdatingVals ? (
                              <Col span={24}>
                                <Spin indicator={<Spinner />} spinning={true}>
                                  <div
                                    style={{ height: '80px', width: '100%' }}
                                  ></div>
                                </Spin>
                              </Col>
                            ) : (
                              getFieldColumn('cbVals', 24)
                            )}
                            {getFieldColumn('pointSize')}
                            {geometryType === GEOMETRY_TYPE_WKT &&
                              getFieldColumn('lineWidth')}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
                {renderType === MAP_RENDER_TYPE_TRACK && (
                  <>
                    {getFieldColumn('trackHeadColor', null, {
                      label: 'Head Color',
                    })}
                    {getFieldColumn('trackHeadShape', null, {
                      label: 'Head Shape',
                    })}
                    {getFieldColumn('trackHeadSize', null, {
                      label: 'Head Size',
                    })}
                    {getFieldColumn('trackMarkerColor', null, {
                      label: 'Marker Color',
                    })}
                    {getFieldColumn('trackMarkerShape', null, {
                      label: 'Marker Shape',
                    })}
                    {getFieldColumn('trackMarkerSize', null, {
                      label: 'Marker Size',
                    })}
                    {getFieldColumn('trackLineColor', null, {
                      label: 'Line Color',
                    })}
                    {getFieldColumn('trackLineWidth', null, {
                      label: 'Line Width',
                    })}
                    {getFieldColumn('symbolRotation')}
                  </>
                )}
                {renderType === MAP_RENDER_TYPE_CONTOUR && (
                  <>
                    {getFieldColumn('valAttr')}
                    {getFieldColumn('renderOutputGrid')}
                    {getFieldColumn()}
                    {getFieldColumn('colormap')}
                    {getFieldColumn('color')}
                    {getFieldColumn('bgColor')}
                    {getFieldColumn('griddingMethod')}
                    {getFieldColumn('minLevel')}
                    {getFieldColumn('maxLevel')}
                    {getFieldColumn('numLevels')}
                    {getFieldColumn('smoothingFactor')}
                    {getFieldColumn('adjustLevel')}
                    {getFieldColumn()}
                    {getFieldColumn('addLabels')}
                    {getFieldColumn('labelsFontSize')}
                    {getFieldColumn('textColor')}
                  </>
                )}
              </Row>
            </div>
          </Panel>
        </Collapse>
        <Divider style={dividerStyle} dashed />
        <Collapse
          activeKey={viewportCollapse}
          onChange={handleCollapseChange('viewport')}
          expandIconPosition="end"
          ghost={viewportCollapse.length > 0}
          bordered={false}
        >
          <Panel
            header={getPanelHeader('Viewport Options')}
            key="1"
            style={panelStyle}
          >
            <div style={panelContentStyle}>
              <Row gutter={16}>
                {layerIdx === 0 && getFieldColumn('center_longitude')}
                {layerIdx === 0 && getFieldColumn('center_latitude')}
                {layerIdx === 0 && (
                  <Col key="use_current_map_viewport" span={24 / columns}>
                    <Button
                      key="use_current"
                      onClick={handleUseCurrentViewport}
                      size="small"
                      style={{ marginTop: '32px' }}
                      block
                    >
                      Set Current Map Viewport
                    </Button>
                  </Col>
                )}
                {layerIdx === 0 && getFieldColumn('zoom_level')}
                {getFieldColumn('opacity')}
                {layerIdx === 0 && (
                  <Col key="use_data_fitted_viewport" span={24 / columns}>
                    <Button
                      key="fit_to_data"
                      onClick={handleFitViewportData}
                      size="small"
                      style={{ marginTop: '32px' }}
                      block
                    >
                      Set Data-Fitted Viewport
                    </Button>
                  </Col>
                )}
              </Row>
            </div>
          </Panel>
        </Collapse>
        <Divider style={dividerStyle} dashed />
        <Collapse
          activeKey={advancedCollapse}
          onChange={handleCollapseChange('advanced')}
          expandIconPosition="end"
          ghost={advancedCollapse.length > 0}
          bordered={false}
        >
          <Panel
            header={getPanelHeader('Advanced Options')}
            key="1"
            style={panelStyle}
          >
            <div style={panelContentStyle}>
              <Row gutter={16}>
                {layerIdx === 0 && getFieldColumn('title', 16)}
                {layerIdx === 0 && getFieldColumn('block_height', 8)}
                {getFieldColumn('auto_refresh_interval')}
                {layerIdx === 0 && getFieldColumn('basemap_style', 16)}
                <Col key="overrides" span={24}>
                  {getFieldColumn('overrides')}
                  <i
                    style={{
                      color: '#cccccc',
                      marginBottom: '5px',
                      display: 'inline-block',
                    }}
                  >
                    Specify custom WMS parameters (will override any configured
                    above)
                  </i>
                  <ConfigProvider
                    renderEmpty={_ => {
                      return (
                        <p style={{ margin: '5px 10px' }}>No Parameters</p>
                      );
                    }}
                  >
                    <Table
                      dataSource={overrides}
                      pagination={false}
                      rowKey="name"
                      size="small"
                    >
                      <Column
                        title="Parameter"
                        dataIndex="name"
                        width={280}
                        render={(text, record, idx) => {
                          return (
                            <Select
                              value={text}
                              showSearch
                              placeholder="Select parameter"
                              optionFilterProp="children"
                              onChange={handleUpdateOverrideName(record, idx)}
                              filterOption={true}
                              style={{ width: '100%' }}
                            >
                              <Option value=""></Option>
                              {WMS_PARAMS.map(group => {
                                return (
                                  <OptGroup key={group.type} label={group.type}>
                                    {group.params.map(param => {
                                      return (
                                        <Option
                                          key={`${group.type}|${param}`}
                                          value={`${group.type}|${param}`}
                                        >
                                          {param}
                                        </Option>
                                      );
                                    })}
                                  </OptGroup>
                                );
                              })}
                            </Select>
                          );
                        }}
                      />
                      <Column
                        title="Value"
                        dataIndex="value"
                        render={(text, record, idx) => {
                          return (
                            <Input
                              value={text}
                              onChange={handleUpdateOverrideValue(record, idx)}
                              placeholder="Enter parameter value"
                            />
                          );
                        }}
                      />
                      <Column
                        title={
                          <PlusOutlined
                            onClick={handleAddOverride}
                            style={{
                              fontSize: '16px',
                              margin: '0px 3px',
                            }}
                          />
                        }
                        dataIndex="name"
                        width={1}
                        render={(_, record, idx) => {
                          return (
                            <DeleteOutlined
                              onClick={handleDeleteOverride(record, idx)}
                              style={{
                                fontSize: '16px',
                                margin: '0px 3px',
                              }}
                            />
                          );
                        }}
                      />
                    </Table>
                    <br />
                  </ConfigProvider>
                </Col>
              </Row>
            </div>
          </Panel>
        </Collapse>
      </Space>
    </Form>
  );
};

export default MapConfigDrawer;
