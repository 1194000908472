// Imports
import { useState } from 'react';
import { AutoComplete, Checkbox, Col, Form, Row, Input } from 'antd';

// App Imports
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import GraphQLServices from '../../graphql/services';
import { FREE_SAAS } from '../../setup/config';

export default function DestinationStep({ form }) {
  const [isAbortOnError, setIsAbortOnError] = useState(true);
  const [isBadRecord, setIsBadRecord] = useState(
    form.getFieldValue('badRecordSchema') ||
      form.getFieldValue('badRecordTable')
  );

  const { loading, error, data } = GraphQLServices.Schemas.useGetSchemas();
  const {
    loading: tableLoading,
    error: tableError,
    data: tableData,
  } = GraphQLServices.Tables.useGetTables();

  const schemas =
    data && data.schemas ? data.schemas.map(s => ({ value: s.name })) : [];
  let schemaNotFoundText = 'No schemas found';
  if (loading) {
    schemaNotFoundText = 'Retrieving...';
  } else if (error) {
    schemaNotFoundText = 'Error while retrieving';
    console.error(error);
  }

  let tableNotFoundText = 'No tables found for selected schema';
  if (tableLoading) {
    tableNotFoundText = 'Retrieving...';
  } else if (tableError) {
    tableNotFoundText = 'Error while retrieving';
    console.error(tableError);
  }

  const handleAbortOnErrorChange = e => {
    setIsAbortOnError(e.target.checked);
  };

  const handleBadRecordChange = e => {
    setIsBadRecord(e);
  };

  const isKafka = form.getFieldValue('type') === 'kafka';

  const subscribe = form.getFieldValue('subscribe');

  return (
    <Form.Provider>
      <Form layout="horizontal" form={form}>
        <Row>
          <Col span={24}>
            <Form.Item
              name="schemaName"
              label="Schema"
              extra="Enter a new or existing schema"
              {...DEFAULT_FORM_ITEM_PROPS}
              rules={[
                {
                  pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                  message:
                    'Schema name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                },
                {
                  min: 1,
                  message: 'Schema must be between 1-200 characters',
                },
                {
                  max: 200,
                  message: 'Schema must be between 1-200 characters',
                },
              ]}
            >
              <AutoComplete
                options={schemas}
                notFoundContent={schemaNotFoundText}
                filterOption={true}
                disabled={FREE_SAAS}
              ></AutoComplete>
            </Form.Item>
            <Form.Item
              shouldUpdate={(prevValues, values) =>
                prevValues.schemaName !== values.schemaName
              }
              style={{ marginBottom: '0px' }}
            >
              {_ => {
                const values = form.getFieldsValue();
                const tables =
                  tableData && tableData.tables
                    ? tableData.tables
                        .filter(t => t.schema === values?.schemaName)
                        .map(t => ({ value: t.name }))
                    : [];

                return (
                  <Form.Item
                    name="tableName"
                    label="Table"
                    {...DEFAULT_FORM_ITEM_PROPS}
                    rules={[
                      {
                        required: true,
                        message: 'Please enter a table name.',
                      },
                      {
                        pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                        message:
                          'Table name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                      },
                      {
                        min: 1,
                        message: 'Table must be between 1-200 characters',
                      },
                      {
                        max: 200,
                        message: 'Table must be between 1-200 characters',
                      },
                    ]}
                    extra="Enter a new or existing table"
                  >
                    <AutoComplete
                      options={tables}
                      notFoundContent={tableNotFoundText}
                    ></AutoComplete>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          {!isKafka && (
            <>
              <Col span={4}></Col>
              <Col span={20}>
                <h3>Options</h3>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="batchSize"
                  label="Batch Size"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValue={'200000'}
                  rules={[
                    {
                      required: true,
                      message: 'Batch Size must be a valid number',
                    },
                  ]}
                >
                  <Input type="number" style={{ width: 270 }}></Input>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </>
          )}
          <Col span={4}></Col>
          <Col span={20}>
            <h3>Error Handling</h3>
          </Col>
          {!subscribe && (
            <>
              <Col span={12}>
                <Form.Item
                  name="abortOnError"
                  label="Abort on Error"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 12 }}
                  valuePropName="checked"
                  colon={false}
                  initialValue={true}
                >
                  <Checkbox
                    onChange={handleAbortOnErrorChange}
                    disabled={isBadRecord}
                  ></Checkbox>
                </Form.Item>
              </Col>
              <Col span={12}></Col>
            </>
          )}
          <Col span={4}></Col>
          <Col span={20}>
            <h4 style={{ marginLeft: '5px', marginBottom: '0px' }}>
              Bad Records Table
            </h4>
            <p
              className="ant-form-item-extra"
              style={{
                padding: '0px 5px',
              }}
            >
              When Abort on Error is checked, bad records table will not be
              populated
            </p>
          </Col>
          <Col span={11}>
            <Form.Item
              name="badRecordSchema"
              label="Schema"
              extra="Enter a new or existing schema"
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 15 }}
              rules={[
                {
                  pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                  message:
                    'Schema name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                },
                {
                  min: 1,
                  message: 'Schema must be between 1-200 characters',
                },
                {
                  max: 200,
                  message: 'Schema must be between 1-200 characters',
                },
              ]}
            >
              <AutoComplete
                disabled={!subscribe && (isAbortOnError || FREE_SAAS)}
                options={schemas}
                notFoundContent={schemaNotFoundText}
                filterOption={true}
                onChange={handleBadRecordChange}
              ></AutoComplete>
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item
              shouldUpdate={(prevValues, values) =>
                prevValues.badRecordSchema !== values.badRecordSchema
              }
              style={{ marginBottom: '0px' }}
            >
              {_ => {
                const values = form.getFieldsValue();
                const tables =
                  tableData && tableData.tables
                    ? tableData.tables
                        .filter(t => t.schema === values?.badRecordSchema)
                        .map(t => ({ value: t.name }))
                    : [];

                return (
                  <Form.Item
                    name="badRecordTable"
                    label="Table"
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 15 }}
                    rules={[
                      {
                        pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                        message:
                          'Table name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                      },
                      {
                        min: 1,
                        message: 'Table must be between 1-200 characters',
                      },
                      {
                        max: 200,
                        message: 'Table must be between 1-200 characters',
                      },
                    ]}
                    extra="Enter a new or existing table"
                  >
                    <AutoComplete
                      disabled={!subscribe && isAbortOnError}
                      options={tables}
                      notFoundContent={tableNotFoundText}
                      onChange={handleBadRecordChange}
                    ></AutoComplete>
                  </Form.Item>
                );
              }}
            </Form.Item>
          </Col>
          <Col span={12}>
            {/* <Form.Item
              name="dimensionTable"
              label="Dimension Table"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 12 }}
              valuePropName="checked"
              colon={false}
              initialValue={false}
            >
              <Checkbox></Checkbox>
            </Form.Item> */}
          </Col>
        </Row>
      </Form>
    </Form.Provider>
  );
}
