// Imports

// App Imports
import {
  APP_SET_TOP_BAR_COLLAPSED,
  APP_SET_EXPLORER_CURRENT_TAB,
  APP_SET_EXPLORER_PANEL_COLLAPSED,
  APP_SET_EXPLORER_PANEL_DATA_EXPANDED_KEYS,
  APP_SET_EXPLORER_PANEL_WORKBOOKS_EXPANDED_KEYS,
  APP_SET_EXPLORER_PANEL_FILES_EXPANDED_KEYS,
} from './actions';

// Initial State
export const initialState = {
  topBarCollapsed: false,
  explorerCurrentTab: 'data',
  explorerPanelCollapsed: false,
  explorerPanelExpandedKeys: {
    data: [],
    workbooks: [],
    files: [],
  },
};

// State
export const app = (state = initialState, action) => {
  switch (action.type) {
    case APP_SET_EXPLORER_CURRENT_TAB:
      return {
        ...state,
        explorerCurrentTab: action.current,
      };

    case APP_SET_EXPLORER_PANEL_COLLAPSED:
      return {
        ...state,
        explorerPanelCollapsed: action.current,
      };

    case APP_SET_EXPLORER_PANEL_DATA_EXPANDED_KEYS:
      return {
        ...state,
        explorerPanelExpandedKeys: {
          ...state.explorerPanelExpandedKeys,
          data: action.current,
        },
      };

    case APP_SET_EXPLORER_PANEL_WORKBOOKS_EXPANDED_KEYS:
      return {
        ...state,
        explorerPanelExpandedKeys: {
          ...state.explorerPanelExpandedKeys,
          workbooks: action.current,
        },
      };

    case APP_SET_EXPLORER_PANEL_FILES_EXPANDED_KEYS:
      return {
        ...state,
        explorerPanelExpandedKeys: {
          ...state.explorerPanelExpandedKeys,
          files: action.current,
        },
      };

    case APP_SET_TOP_BAR_COLLAPSED:
      return {
        ...state,
        topBarCollapsed: action.current,
      };

    default:
      return state;
  }
};
