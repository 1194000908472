// Imports
import React from 'react';
import { Modal, Button, Spin, Typography, message } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// App Imports
import Spinner from '../common/Spinner';

const TableCreateSqlModal = ({
  table,
  data,
  visible,
  setVisible,
  width,
  height,
  loading,
  error,
}) => {
  return (
    <Modal
      title={`Table Create SQL: ${table}`}
      open={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <CopyToClipboard
          key="copytoclipboard"
          text={
            data?.column_1
              ? data?.column_1[0].replace(/(\r\n|\n|\r)/gm, '\n')
              : ''
          }
          onCopy={() => {
            message.success('Copied to clipboard!');
            setVisible(false);
          }}
        >
          <Button key="submit" type="primary">
            Copy To Clipboard
          </Button>
        </CopyToClipboard>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'auto',
          }}
        >
          {data?.column_1 && (
            <Typography.Paragraph>
              <pre style={{ fontSize: '13px', padding: '20px' }}>
                {data?.column_1[0]}
              </pre>
            </Typography.Paragraph>
          )}
          {error && (
            <pre className="console">Query failed: {error.message}</pre>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TableCreateSqlModal;
