// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_K8S_KINETICAUSERS = gql`
  query K8sKineticaUsers {
    k8s_kineticausers {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICAUSER = gql`
  query K8sKineticaUsers($username: String!) {
    k8s_kineticauser(username: $username) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAUSER = gql`
  mutation CreateK8sKineticaUser($name: String!, $body: JSON!) {
    kineticaUserCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaUsers = () => {
  return useQuery(GET_K8S_KINETICAUSERS);
};

export const useGetK8sKineticaUser = options => {
  return useQuery(GET_K8S_KINETICAUSER, options);
};

export const useCreateK8SKineticaUser = () => {
  return useMutation(CREATE_K8S_KINETICAUSER);
};
