// Imports
import { useContext } from 'react';
import debounce from 'debounce';
import axios from 'axios';

// App Imports
import { UserContext } from '../context';
import { TELEMETRY_EVENT_TYPES as EVENT_TYPES } from '../constants';
import {
  SEGMENT_API_KEY,
  CUSTOMER_INFO_JSON,
  APP_URL_GRAPHQL,
  APP_URL_SEGMENT,
} from '../setup/config';

const DEBOUNCE_DURATION = 800;
const trackMap = {};

const page = debounce(window?.analytics?.page, DEBOUNCE_DURATION);
const pageWrapper = (params, options) => {
  return _ => page(params, options);
};

const identify = userId => {
  window?.analytics?.identify(userId);
};

const group = groupId => {
  window?.analytics?.group(groupId);
};

const serverTrack = (name, params) => {
  let url = `${APP_URL_SEGMENT}/track`;
  if (!APP_URL_SEGMENT) {
    url = `${APP_URL_GRAPHQL.replace(/graphql/, 'sm')}/track`;
  }

  axios({
    url,
    method: 'post',
    data: {
      event: name,
      params,
    },
  }).then(resp => {
    // console.log(resp);
  });
};

function useAnalytics() {
  const userMe = useContext(UserContext) ?? {};
  const { username, cloud_group_id } = userMe;

  if (SEGMENT_API_KEY) {
    let customerInfo = {};
    try {
      customerInfo = JSON.parse(CUSTOMER_INFO_JSON);
    } catch (err) {}

    return {
      EVENT_TYPES,
      identify,
      group,
      track: name => {
        if (!trackMap[name]) {
          trackMap[name] = debounce((...args) => {
            const newArgs = args.some(arg => typeof arg === 'object')
              ? args.map(arg => {
                  if (typeof arg === 'object') {
                    return {
                      username,
                      ...customerInfo,
                      ...arg,
                    };
                  }
                  return arg;
                })
              : [
                  {
                    username,
                    ...customerInfo,
                  },
                ].concat(args);
            if (window?.analytics?.timeout) {
              window?.analytics?.track(name, ...newArgs, {
                groupId: cloud_group_id,
              });
            } else {
              serverTrack(name, ...newArgs);
              args.forEach(arg => {
                if (typeof arg === 'function') {
                  arg();
                }
              });
            }
          }, DEBOUNCE_DURATION);
        }
        return trackMap[name];
      },
      page: pageWrapper(
        {
          username,
          ...customerInfo,
        },
        {
          groupId: cloud_group_id,
        }
      ),
    };
  }

  return {
    EVENT_TYPES,
    track: name => {
      return (...args) => {
        args.forEach(arg => {
          if (typeof arg === 'function') {
            arg();
          }
        });
      };
    },
    page: _ => {},
  };
}

export default useAnalytics;
