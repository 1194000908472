// Imports
import React, { useState, useMemo, useEffect } from 'react';
import {
  Form,
  Button,
  Popconfirm,
  Input,
  InputNumber,
  Select,
  AutoComplete,
  Empty,
  Radio,
  DatePicker,
  Row,
  Col,
} from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';

const EVENT_TYPES = [
  {
    label: 'Insert',
    value: 'insert',
  },
  {
    label: 'Update',
    value: 'update',
  },
  {
    label: 'Delete',
    value: 'delete',
  },
];

const REFRESH_METHODS = [
  {
    label: 'On Change',
    value: 'on_change',
  },
  {
    label: 'Periodic',
    value: 'periodic',
  },
];

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const CreateStreamForm = () => {
  const { refetch: refetchStreams } =
    GraphQLServices.TableMonitors.useGetTableMonitors();
  const { data: { datasinks = [] } = {} } =
    GraphQLServices.Datasinks.useGetDatasinks();
  const [createStream] = GraphQLServices.TableMonitors.useCreateTableMonitor();
  const {
    loading: schemaLoading,
    error: schemaError,
    data: schemaData,
  } = GraphQLServices.Schemas.useGetSchemas();
  const {
    loading: tableLoading,
    error: tableError,
    data: tableData,
  } = GraphQLServices.Tables.useGetTables();

  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const { topBarCollapsed } = useSelector(state => state.app);

  const [formValues, setFormValues] = useState({
    event: EVENT_TYPES[0].value,
    refresh_method: REFRESH_METHODS[0].value,
  });

  useEffect(
    _ => {
      if (location?.state?.datasink_name) {
        form.setFieldsValue({
          datasink_name: location.state.datasink_name,
        });
      }
    },
    [location, form]
  );

  const schemas =
    schemaData && schemaData.schemas
      ? schemaData.schemas.map(s => ({ value: s.name }))
      : [];
  let schemaNotFoundText = 'No schemas found';
  if (schemaLoading) {
    schemaNotFoundText = 'Retrieving...';
  } else if (schemaError) {
    schemaNotFoundText = 'Error while retrieving';
    console.error(schemaError);
  }

  let tableNotFoundText = 'No tables found for selected schema';
  if (tableLoading) {
    tableNotFoundText = 'Retrieving...';
  } else if (tableError) {
    tableNotFoundText = 'Error while retrieving';
    console.error(tableError);
  }

  const resetForm = _ => {
    setFormValues({
      event: EVENT_TYPES[0].value,
      refresh_method: REFRESH_METHODS[0].value,
    });
    form.resetFields();
  };

  const onFinish = async values => {
    const {
      monitor_id,
      schema,
      table,
      event,
      increasing_column,
      filter_expression,
      refresh_method,
      refresh_period,
      refresh_start_time,
      datasink_name,
    } = values;

    try {
      setIsCreating(true);
      const createStreamResp = await createStream({
        variables: {
          monitor_id,
          table_name: `${schema}.${table}`,
          event,
          increasing_column,
          filter_expression: event === 'insert' ? filter_expression : undefined,
          refresh_method,
          refresh_period:
            refresh_method === 'periodic' ? refresh_period : undefined,
          refresh_start_time: refresh_start_time
            ? refresh_start_time.format('YYYY-MM-DD HH:mm:ss')
            : undefined,
          datasink_name,
        },
      });

      if (createStreamResp?.errors) {
        throw new Error(createStreamResp?.errors[0]?.message);
      }

      displaySuccess(`Stream ${monitor_id} created.`);
      resetForm();
      refetchStreams();
    } catch (error) {
      // Graphql client should display error
    } finally {
      setIsCreating(false);
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues({
      ...allValues,
      event: allValues.event || EVENT_TYPES[0].value,
      refresh_method: allValues.refresh_method || REFRESH_METHODS[0].value,
    });
  };

  const create = _ => {
    form.submit();
  };

  const handleCancel = e => {
    history.push('/');
  };

  const handleDatasinkChange = datasink_name => {
    form.setFieldsValue({
      datasink_name,
    });
  };

  const datasinkOptions = useMemo(
    _ => {
      return datasinks
        ? datasinks.map(datasink => ({
            label: datasink.datasink_name,
            value: datasink.datasink_name,
          }))
        : [];
    },
    [datasinks]
  );

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={{}}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      colon={false}
    >
      <div
        style={{
          height: topBarCollapsed
            ? 'calc(100vh - 250px)'
            : 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="monitor_id"
          label="Stream Name"
          rules={[
            {
              required: true,
              message: 'Please enter a stream name.',
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          label="Data Sink"
          name="datasink_name"
          extra="New data sink must first be created from the data explorer."
          rules={[
            {
              required: true,
              message: 'Please select a data sink.',
            },
          ]}
        >
          <Select
            onChange={handleDatasinkChange}
            placeholder="Select an existing data sink"
            notFoundContent={
              <Empty
                description="No data sink available"
                image={Empty.PRESENTED_IMAGE_SIMPLE}
              />
            }
          >
            {datasinkOptions.map(option => {
              return (
                <Select.Option key={option.value} value={option.value}>
                  {option.label}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          name="schema"
          label="Schema"
          {...DEFAULT_FORM_ITEM_PROPS}
          rules={[
            {
              required: true,
              message: 'Please select a schema.',
            },
          ]}
        >
          <AutoComplete
            options={schemas}
            notFoundContent={schemaNotFoundText}
            filterOption
          ></AutoComplete>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.schema !== values.schema
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const tables =
              tableData && tableData.tables
                ? tableData.tables
                    .filter(t => t.schema === values?.schema)
                    .map(t => ({ value: t.name }))
                : [];

            return (
              <Form.Item
                name="table"
                label="Table"
                {...DEFAULT_FORM_ITEM_PROPS}
                rules={[
                  {
                    required: true,
                    message: 'Please select a table.',
                  },
                ]}
              >
                <AutoComplete
                  options={tables}
                  notFoundContent={tableNotFoundText}
                  filterOption
                ></AutoComplete>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          label="Event"
          name="event"
          initialValue={EVENT_TYPES[0].value}
        >
          <Radio.Group
            options={EVENT_TYPES}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.table !== values.table
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const columns =
              tableData &&
              tableData.tables &&
              tableData.tables.find(t => t.name === values?.table)
                ? tableData.tables
                    .find(t => t.name === values?.table)
                    .columns.map(c => ({ value: c.name }))
                : [];

            return (
              <Form.Item
                name="increasing_column"
                label="Column"
                extra="Column on the table that will increase for new records."
                {...DEFAULT_FORM_ITEM_PROPS}
              >
                <AutoComplete
                  options={columns}
                  notFoundContent="No column found for selected table"
                  filterOption
                  allowClear
                ></AutoComplete>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="filter_expression"
          label="Filter"
        >
          <Input
            placeholder="Filter expression to limit records"
            disabled={formValues.event !== 'insert'}
          />
        </Form.Item>
        <Row gutter={20}>
          <Col span={10}>
            <Form.Item
              label="Refresh Method"
              name="refresh_method"
              initialValue={REFRESH_METHODS[0].value}
              labelCol={{ span: 10 }}
              wrapperCol={{ span: 14 }}
            >
              <Radio.Group
                options={REFRESH_METHODS}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              name="refresh_period"
              label="Period"
              labelCol={{ span: 8 }}
              wrapperCol={{ span: 16 }}
            >
              <InputNumber
                placeholder="in seconds"
                style={{ width: '100%' }}
                disabled={formValues.refresh_method !== 'periodic'}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="refresh_start_time"
          label="Start Time"
        >
          <DatePicker showTime allowClear />
        </Form.Item>
      </div>
      <div>
        <Button
          type="primary"
          onClick={create}
          style={{ float: 'right' }}
          loading={isCreating}
        >
          Create Stream
        </Button>
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default CreateStreamForm;
