// Imports
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import store from 'store2';

// App Imports
import * as app from '../store/app/reducer';
import * as auth from '../store/auth/reducer';
import * as workbook from '../store/workbook/reducer';
import * as worksheet from '../store/worksheet/reducer';

const enhancers = [];
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

// Root Reducer
const rootReducer = combineReducers({
  ...app,
  ...auth,
  ...workbook,
  ...worksheet,
});

// Initial state
const sessionStore = store.namespace('workbench');
const initialState = sessionStore.session('redux') || {};

// Clear running state of block results that were running before last page refresh.
// This is because when a user navigates away from the page while a block is running
// it will be persisted as running even though when the page is refreshed we do not
// actually run the query again.
if (initialState?.worksheet?.blockResults) {
  Object.values(initialState.worksheet.blockResults).forEach(result => {
    result.queryRunning = false;
  });
}

// Store
const rootStore = createStore(
  rootReducer,
  initialState,
  compose(applyMiddleware(thunk), ...enhancers)
);
rootStore.subscribe(_ => {
  const { auth, ...rest } = rootStore.getState();
  try {
    sessionStore.session('redux', { ...rest });
  } catch (err) {
    console.warn('Could not persist to redux store due to the following:');
    console.error(err);
    console.warn('Deleting old block results to make room.');
    try {
      sessionStore.session('redux', {
        ...rest,
        worksheet: { ...rest?.worksheet, blockResults: {} },
      });
    } catch (err2) {
      console.warn(
        'Could not persist redux store after removing block results due to the following:'
      );
      console.error(err2);
    }
  }
});

export default rootStore;
