// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const SchemaCreateModal = ({ visible, close, callback }) => {
  const [createSchema] = GraphQLServices.Schemas.useCreateSchema();

  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { name } = values;
    setIsCreating(true);
    createSchema({
      variables: {
        name,
      },
    })
      .then(resp => {
        setIsCreating(false);
        if (resp?.data?.schemaCreate && callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsCreating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const create = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Create Schema"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="schema"
        layout="vertical"
        initialValues={{}}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input schema name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default SchemaCreateModal;
