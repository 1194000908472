// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_K8S_KINETICAWORKBENCHUPGRADES = gql`
  query K8sKineticaWorkbenchUpgrades {
    k8s_kineticaworkbenchupgrades {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICAWORKBENCHUPGRADES_BY_NAME = gql`
  query GetK8sKineticaWorkbenchUpgradeByName($name: String!) {
    k8s_kineticaworkbenchupgrade(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAWORKBENCHUPGRADE = gql`
  mutation CreateK8sKineticaWorkbenchUpgrade($name: String!, $body: JSON!) {
    kineticaWorkbenchUpgradeCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaWorkbenchUpgrades = () => {
  return useQuery(GET_K8S_KINETICAWORKBENCHUPGRADES, {
    fetchPolicy: 'no-cache',
  });
};

export const useGetK8sKineticaWorkbenchUpgradeByName = options => {
  return useQuery(GET_K8S_KINETICAWORKBENCHUPGRADES_BY_NAME, options);
};

export const useCreateK8SKineticaWorkbenchUpgrade = () => {
  return useMutation(CREATE_K8S_KINETICAWORKBENCHUPGRADE);
};
