// Imports
import { useQuery, gql } from '@apollo/client';

// App Imports

export const GET_VISUALIZATION_TYPE_BY_ID = gql`
  query GetVisualizationTypeById($id: ID!) {
    visualization_type(id: $id) {
      id
      name
      description
      params
    }
  }
`;

export const useGetVisualizationTypeById = options => {
  return useQuery(GET_VISUALIZATION_TYPE_BY_ID, options);
};
