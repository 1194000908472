export function extractFields(columns) {
  return columns.map(col => ({
    name: col.columnName,
    type: col.nullable ? [col?.type?.[0], 'null'] : col?.type?.[0],
  }));
}

export function extractProperties(columns) {
  const propertyMap = {};
  for (let col of columns) {
    const properties = [];

    if (col.storage) {
      properties.push(col.storage);
    }

    if (col.type && col.type.length > 1 && col.type[1]) {
      properties.push(col.type[1]);
    }

    if (
      col.type &&
      col.type.length > 1 &&
      col.type[0] === 'bytes' &&
      col.type[1] === ''
    ) {
      if (properties.indexOf('data') > -1) {
        properties.splice(properties.indexOf('data'), 1, 'store_only');
      } else {
        properties.push('store_only');
      }
    } else if (properties.indexOf('store_only') > -1) {
      properties.splice(properties.indexOf('store_only'), 1, 'data');
    }

    if (col.properties) {
      for (let prop of col.properties) {
        // Make sure to only add properties when applicable.
        // Check docs for most up to date information about what the
        // restrictions are.
        switch (prop) {
          case 'primary_key':
          case 'shard_key':
            if (col?.storage !== 'store_only') {
              properties.push(prop);
            }
            break;
          case 'disk_optimized':
            if (
              col?.storage === 'data' &&
              col?.type?.[0] === 'string' &&
              col?.type?.[1] === ''
            ) {
              properties.push(prop);
            }
            break;
          case 'dict':
            if (
              ((col?.type?.[0] === 'int' && col?.type?.[1] === '') ||
                col?.type?.[0] === 'long' ||
                col?.type?.[1] === 'date' ||
                (col?.type?.[1] || '').startsWith('char')) &&
              col?.storage !== 'store_only'
            ) {
              properties.push(prop);
            }
            break;
          case 'timestamp':
            if (col.type[0] === 'long') {
              properties.push(prop);
            }
            break;
          case 'init_with_now':
            if (
              col?.type?.[0] === 'string' &&
              (col?.type?.[1] === 'date' ||
                col?.type?.[1] === 'datetime' ||
                col?.type?.[1] === 'time')
            ) {
              properties.push(prop);
            }
            break;
          case 'text_search':
            if (
              col?.type?.[0] === 'string' &&
              (col?.type?.[1] === '' ||
                (col?.type?.[1] || '').startsWith('char') ||
                col?.type?.[1] === 'date' ||
                col?.type?.[1] === 'datetime' ||
                col?.type?.[1] === 'time' ||
                col?.type?.[1] === 'ipv4' ||
                col?.type?.[1] === 'uuid')
            ) {
              properties.push(prop);
            }
            break;
          case 'init_with_uuid':
            if (col.type[1] === 'uuid') {
              properties.push(prop);
            }
            break;
          default:
            properties.push(prop);
            break;
        }
      }
    }

    if (col.nullable) {
      properties.push('nullable');
    } else {
      const index = properties.indexOf('nullable');
      if (index !== -1) {
        properties.splice(index, 1);
      }
    }

    propertyMap[col.columnName] = properties;
  }

  return propertyMap;
}

const ALLOWED_MASKABLE_SUBTYPES = [
  'ipv4',
  'decimal',
  'ulong',
  'date',
  'time',
  'datetime',
];

export function validMaskColumn(type) {
  return (
    Array.isArray(type) &&
    type[0] === 'string' &&
    type[1] &&
    (type[1].startsWith('char') || ALLOWED_MASKABLE_SUBTYPES.includes(type[1]))
  );
}

export const validObfuscateColumn = validMaskColumn;
