// Imports
import React, { useMemo, useState } from 'react';
import { Layout, Tooltip } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { DEPLOYMENT_TYPE } from '../../setup/config';

const { Footer: AntFooter } = Layout;

const Footer = () => {
  const [workbenches, setWorkbenches] = useState([]);

  const graphqlClient = useApolloClient();

  const version = useMemo(
    _ => {
      if (workbenches) {
        if (workbenches[0]?.spec?.image.indexOf('/') > -1) {
          return workbenches[0]?.spec?.image.split('/')[1];
        }
        return workbenches[0]?.spec?.image;
      }
      return '';
    },
    [workbenches]
  );

  const handleMouseOver = _ => {
    const fetch = async () => {
      const { data: { k8s_kineticaworkbenches } = {} } =
        await graphqlClient.query({
          query:
            GraphQLServices.K8sKineticaWorkbenches.GET_K8S_KINETICAWORKBENCHES,
          variables: {
            deployment_type: DEPLOYMENT_TYPE,
          },
        });
      setWorkbenches(k8s_kineticaworkbenches);
    };
    if (!version) {
      fetch();
    }
  };

  return (
    <AntFooter className="footer">
      <Tooltip title={version}>
        <span onMouseOver={handleMouseOver}>
          Workbench ©{new Date().getFullYear()} Created by Kinetica
        </span>
      </Tooltip>
    </AntFooter>
  );
};

export default Footer;
