// Imports
import { useMutation, useQuery, gql } from '@apollo/client';

export const GET_WORKSHEETS = gql`
  query {
    worksheets {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const GET_WORKSHEET_BY_ID = gql`
  query GetWorksheetById($id: ID!) {
    worksheet(id: $id) {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const GET_WORKSHEET_ONLY_BY_ID = gql`
  query GetWorksheetOnlyById($id: ID!) {
    worksheet(id: $id) {
      id
      name
      description
      config
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_WORKSHEET = gql`
  mutation CreateWorksheet(
    $name: String!
    $description: String
    $config: JSON!
    $previous_worksheet_id: ID
    $next_worksheet_id: ID
    $workbook_id: ID!
  ) {
    worksheetCreate(
      name: $name
      description: $description
      config: $config
      previous_worksheet_id: $previous_worksheet_id
      next_worksheet_id: $next_worksheet_id
      workbook_id: $workbook_id
    ) {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_WORKSHEET_SET = gql`
  mutation CreateWorksheetSet(
    $name: String!
    $description: String
    $config: JSON!
    $previous_worksheet_id: ID
    $next_worksheet_id: ID
    $workbook_id: ID!
  ) {
    worksheetSetCreate(
      name: $name
      description: $description
      config: $config
      previous_worksheet_id: $previous_worksheet_id
      next_worksheet_id: $next_worksheet_id
      workbook_id: $workbook_id
    ) {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const UPDATE_WORKSHEET_BY_ID = gql`
  mutation UpdateWorksheetById(
    $id: ID!
    $name: String
    $description: String
    $config: JSON
    $previous_worksheet_id: ID
    $next_worksheet_id: ID
    $workbook_id: ID
  ) {
    worksheetUpdateById(
      id: $id
      name: $name
      description: $description
      config: $config
      previous_worksheet_id: $previous_worksheet_id
      next_worksheet_id: $next_worksheet_id
      workbook_id: $workbook_id
    ) {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const SWAP_WORKSHEETS_BY_ID = gql`
  mutation SwapWorksheetsById($from_id: ID!, $to_id: ID!) {
    worksheetsSwapById(from_id: $from_id, to_id: $to_id) {
      from {
        id
        name
        description
        config
        blocks {
          id
          name
          description
          content
          config
          previous_block_id
          next_block_id
          block_type {
            id
            name
            description
          }
        }
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
      to {
        id
        name
        description
        config
        blocks {
          id
          name
          description
          content
          config
          previous_block_id
          next_block_id
          block_type {
            id
            name
            description
          }
        }
        previous_worksheet_id
        next_worksheet_id
        workbook_id
        created_at
        updated_at
      }
    }
  }
`;

export const REPAIR_WORKSHEET_BY_ID = gql`
  mutation RepairWorksheetById($id: ID!) {
    worksheetRepairById(id: $id) {
      id
      name
      description
      config
      blocks {
        id
        name
        description
        content
        config
        previous_block_id
        next_block_id
        block_type {
          id
          name
          description
        }
      }
      previous_worksheet_id
      next_worksheet_id
      workbook_id
      created_at
      updated_at
    }
  }
`;

export const REMOVE_WORKSHEET_BY_ID = gql`
  mutation RemoveWorksheetById($id: ID!) {
    worksheetRemoveById(id: $id) {
      id
    }
  }
`;

export const REMOVE_WORKSHEET_SET_BY_ID = gql`
  mutation RemoveWorksheetSetById($id: ID!) {
    worksheetSetRemoveById(id: $id) {
      id
    }
  }
`;

export const useGetWorksheets = () => {
  return useQuery(GET_WORKSHEETS);
};

export const useGetWorksheetById = options => {
  return useQuery(GET_WORKSHEET_BY_ID, options);
};

export const useGetWorksheetOnlyById = options => {
  return useQuery(GET_WORKSHEET_ONLY_BY_ID, options);
};

export const useCreateWorksheet = () => {
  return useMutation(CREATE_WORKSHEET);
};

export const useCreateWorksheetSet = () => {
  return useMutation(CREATE_WORKSHEET_SET);
};

export const useUpdateWorksheetById = () => {
  return useMutation(UPDATE_WORKSHEET_BY_ID);
};

export const useSwapWorksheetsById = () => {
  return useMutation(SWAP_WORKSHEETS_BY_ID);
};

export const useRepairWorksheetById = () => {
  return useMutation(REPAIR_WORKSHEET_BY_ID);
};

export const useRemoveWorksheetById = () => {
  return useMutation(REMOVE_WORKSHEET_BY_ID);
};

export const useRemoveWorksheetSetById = () => {
  return useMutation(REMOVE_WORKSHEET_SET_BY_ID);
};
