// Imports
import React, { useMemo, useState, useEffect } from 'react';
import { Form, Steps, Button, Popconfirm, notification } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import SolveGraphConfigureForm from './SolveGraphConfigureForm';
import SolveGraphOptionsForm from './SolveGraphOptionsForm';
import TableWmsModal from '../../components/modal/TableWmsModal';
import { GPUDB_REQUEST_MAP } from '../../endpoints';
import {
  displaySuccess,
  buildGraphComponentParams as buildComponentParams,
  cleanGraphOptions as cleanOptions,
} from '../../helper';
import {
  MIN_WMS_PREVIEW_MODAL_WIDTH,
  MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO,
} from '../../constants';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Configure',
    StepComponent: SolveGraphConfigureForm,
  },
  {
    title: 'Options',
    StepComponent: SolveGraphOptionsForm,
  },
];

const SOLVE_GRAPH_ENDPOINT = '/solve/graph';

const SolveGraphForm = () => {
  const { data: { graph_grammar: grammar = {} } = {} } =
    GraphQLServices.GraphGrammars.useGetGraphGrammarByEndpoint({
      variables: {
        endpoint: SOLVE_GRAPH_ENDPOINT,
      },
    });
  const [solveGraph] = GraphQLServices.Graphs.useSolveGraph();

  const [step, setStep] = useState(0);
  const [isSolving, setIsSolving] = useState(false);
  const [componentAdditions, setComponentAdditions] = useState({});
  const [componentSelections, setComponentSelections] = useState({});

  const [wmsTable, setWmsTable] = useState(undefined);
  const [wmsTableDefaults, setWmsTableDefaults] = useState(undefined);
  const [showTableWms, setShowTableWms] = useState(false);

  const graphqlClient = useApolloClient();

  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const { topBarCollapsed } = useSelector(state => state.app);

  useEffect(
    _ => {
      if (location?.state?.graph_name) {
        form.setFieldsValue({
          graph_name: location.state.graph_name,
        });
      }
    },
    [location, form]
  );

  const resetForm = _ => {
    setStep(0);
    form.resetFields();
    setComponentAdditions({});
    setComponentSelections({});
  };

  const onFinish = async values => {
    const {
      graph_name = '',
      solution_table = '',
      solver_type = '',
      ...options
    } = values;
    const { weights_on_edges, restrictions, source_nodes, destination_nodes } =
      componentAdditions;

    try {
      setWmsTable(undefined);
      setWmsTableDefaults(undefined);
      setShowTableWms(false);
      setIsSolving(true);

      const solveGraphResp = await solveGraph({
        variables: {
          graph_name,
          solver_type,
          solution_table,
          weights_on_edges: buildComponentParams(weights_on_edges),
          restrictions: buildComponentParams(restrictions),
          source_nodes: buildComponentParams(source_nodes),
          destination_nodes: buildComponentParams(destination_nodes),
          options: cleanOptions(options),
        },
      });

      if (solveGraphResp?.errors) {
        throw new Error(solveGraphResp?.errors[0]?.message);
      }

      if (solveGraphResp?.data?.graphSolve?.result) {
        // We have to check the solution table for the
        // field 'wktroute' in order to determine if it
        // is able to be rendered.
        const schema =
          solution_table.indexOf('.') > -1
            ? solution_table.split('.')[0]
            : undefined;
        const name =
          solution_table.indexOf('.') > -1
            ? solution_table.split('.')[1]
            : solution_table;
        const tableResp = await graphqlClient.query({
          query: GraphQLServices.Tables.GET_TABLE_BY_NAME,
          variables: {
            schema,
            name,
          },
        });

        if (tableResp?.errors) {
          throw new Error(tableResp?.errors[0]?.message);
        }

        if (tableResp?.data?.table) {
          const { columns } = tableResp?.data?.table;
          var hasWKTField = columns.find(column => {
            return column.name === 'wktroute' || column.name === 'PATH';
          });

          if (hasWKTField) {
            const { schema, name } = tableResp?.data?.table;
            setWmsTable({
              full: `${schema}.${name}`,
              schema,
              name,
            });
            setWmsTableDefaults({
              borderColor: '304ffe',
              lineWidth: 5,
            });
            setShowTableWms(true);
          } else {
            displaySuccess(`Graph ${graph_name} solved.`);
          }
        }
      }

      resetForm();
    } catch (error) {
      // Graphql client should display error
    } finally {
      setIsSolving(false);
    }
  };

  const solve = _ => {
    const errors = [];
    const graph_name = form.getFieldValue('graph_name') ?? '';
    const solution_table = form.getFieldValue('solution_table') ?? '';

    if (graph_name.trim() === '') {
      errors.push('Graph Name is required.');
    }
    if (solution_table.trim() === '') {
      errors.push('Solution Table is required.');
    }

    if (errors.length > 0) {
      errors.forEach(error => {
        notification.open({
          message: 'Validation Error Occurred',
          description: error,
          type: 'error',
        });
      });
    } else {
      form.submit();
    }
  };

  const { fields } = GPUDB_REQUEST_MAP[SOLVE_GRAPH_ENDPOINT];

  const initialValues = useMemo(
    _ => {
      if (grammar && Object.keys(grammar).length > 0) {
        const configDefaults = fields.reduce((acc, cur) => {
          if (
            typeof cur.type === 'string' &&
            cur.type !== 'options' &&
            cur?.value?.default !== ''
          ) {
            if (cur.type === 'boolean') {
              acc[cur.name] = cur?.value?.default === 'true';
            } else {
              acc[cur.name] = cur?.value?.default;
            }
          }
          return acc;
        }, {});
        const optionDefaults = grammar?.options
          .filter(option => {
            // Don't display internal options
            return (
              !option.internal ||
              (option.internal && option.internal !== 'true')
            );
          })
          .reduce((acc, cur) => {
            if (cur?.default !== '') {
              if (cur.type === 'boolean') {
                acc[cur.name] = cur.default === 'true';
              } else {
                acc[cur.name] = cur.default;
              }
            }
            return acc;
          }, {});

        return {
          ...configDefaults,
          ...optionDefaults,
        };
      }
      return {};
    },
    [grammar, fields]
  );

  const handleCancel = e => {
    history.push('/');
  };

  const handleStepClick = step => {
    setStep(step);
  };

  const docs = useMemo(
    _ => {
      return fields.reduce((acc, cur) => {
        if (cur.name !== 'options') {
          acc[cur.name] = cur.doc.replace(/@{link.*?}/g, '');
        } else {
          Object.keys(cur.value.valid_keys).forEach(option => {
            acc[option] = cur.value.valid_keys[option].doc.replace(
              /@{link.*?}/g,
              ''
            );
          });
        }
        return acc;
      }, {});
    },
    [fields]
  );

  if (!grammar || Object.keys(grammar).length === 0) {
    return <></>;
  }

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={initialValues}
      onFinish={onFinish}
      colon={false}
    >
      <Steps
        current={step}
        onChange={handleStepClick}
        style={{ marginBottom: '20px' }}
      >
        {STEPS.map(({ title }, idx) => (
          <Step key={idx} title={title}></Step>
        ))}
      </Steps>
      <div
        style={{
          height: topBarCollapsed
            ? 'calc(100vh - 340px)'
            : 'calc(100vh - 390px)',
          margin: '30px 0px',
          overflow: 'auto',
        }}
      >
        {STEPS.map(({ StepComponent }, idx) => (
          <div key={idx} style={{ display: step === idx ? 'block' : 'none' }}>
            <StepComponent
              form={form}
              fields={fields}
              grammar={grammar}
              componentAdditions={componentAdditions}
              setComponentAdditions={setComponentAdditions}
              componentSelections={componentSelections}
              setComponentSelections={setComponentSelections}
              docs={docs}
            ></StepComponent>
          </div>
        ))}
      </div>
      <div>
        <Button
          icon={<LeftOutlined />}
          onClick={_ => setStep(Math.max(step - 1, 0))}
          style={{ float: 'left' }}
          disabled={step === 0}
        >
          Back
        </Button>
        {step === STEPS.length - 1 ? (
          <Button
            type="primary"
            onClick={solve}
            style={{ float: 'right' }}
            loading={isSolving}
          >
            Solve Graph
          </Button>
        ) : (
          <Button
            onClick={_ => setStep(Math.min(step + 1, 2))}
            style={{ float: 'right' }}
            disabled={step === STEPS.length - 1}
          >
            Next <RightOutlined />
          </Button>
        )}
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
      {wmsTable && (
        <TableWmsModal
          table={wmsTable}
          defaults={wmsTableDefaults}
          visible={showTableWms}
          setVisible={visible => {
            setShowTableWms(visible);
            if (!visible) {
              setWmsTable(undefined);
              setWmsTableDefaults(undefined);
            }
          }}
          width={Math.max(
            Math.min(
              window.innerWidth - 300,
              MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO * (window.innerHeight - 200)
            ),
            MIN_WMS_PREVIEW_MODAL_WIDTH
          )}
          height={window.innerHeight - 200}
        />
      )}
    </Form>
  );
};

export default SolveGraphForm;
