// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_MODELS = gql`
  query Models {
    models {
      model_name
      registry_name
      path_to_container
      model_run_function
    }
  }
`;

export const GET_MODEL_BY_NAME = gql`
  query Model($model_name: String!) {
    model(model_name: $model_name) {
      model_name
      registry_name
      path_to_container
      model_run_function
    }
  }
`;

export const DESCRIBE_MODEL_BY_NAME = gql`
  query DescribeModel($model_name: String!) {
    modelDescribeByName(model_name: $model_name) {
      model_name
      entity_id
      input_schema
      output_schema
    }
  }
`;

export const IMPORT_MODEL = gql`
  mutation ImportModel(
    $model_name: String!
    $registry_name: String!
    $path_to_container: String!
    $model_run_function: String!
  ) {
    modelImport(
      model_name: $model_name
      registry_name: $registry_name
      path_to_container: $path_to_container
      model_run_function: $model_run_function
    ) {
      model_name
      registry_name
      path_to_container
      model_run_function
    }
  }
`;

export const ALTER_MODEL_BY_NAME = gql`
  mutation AlterModelByName(
    $model_name: String!
    $registry_name: String
    $path_to_container: String
  ) {
    modelAlterByName(
      model_name: $model_name
      registry_name: $registry_name
      path_to_container: $path_to_container
    ) {
      model_name
      registry_name
      path_to_container
    }
  }
`;

export const EVALUATE_MODEL_BY_NAME = gql`
  mutation EvaluateModelByName(
    $model_name: String!
    $method: String!
    $deployment_mode: String!
    $number_of_replications: Int!
    $source_data: String!
    $destination_table_name: String
  ) {
    modelEvaluateByName(
      model_name: $model_name
      method: $method
      deployment_mode: $deployment_mode
      number_of_replications: $number_of_replications
      source_data: $source_data
      destination_table_name: $destination_table_name
    ) {
      model_name
    }
  }
`;

export const DELETE_MODEL_BY_NAME = gql`
  mutation DeleteModelByName($model_name: String!) {
    modelDeleteByName(model_name: $model_name) {
      model_name
    }
  }
`;

export const REFRESH_MODEL_BY_NAME = gql`
  mutation RefreshModelByName($model_name: String!) {
    modelRefreshByName(model_name: $model_name) {
      model_name
    }
  }
`;

export const useGetModels = () => {
  return useQuery(GET_MODELS);
};

export const useGetModelByName = options => {
  return useQuery(GET_MODEL_BY_NAME, options);
};

export const useDescribeModelByName = options => {
  return useQuery(DESCRIBE_MODEL_BY_NAME, options);
};

export const useImportModel = () => {
  return useMutation(IMPORT_MODEL);
};

export const useEvaluateModelByName = () => {
  return useMutation(EVALUATE_MODEL_BY_NAME);
};

export const useAlterModelByName = () => {
  return useMutation(ALTER_MODEL_BY_NAME);
};

export const useDeleteModelByName = () => {
  return useMutation(DELETE_MODEL_BY_NAME);
};

export const useRefreshModelByName = () => {
  return useMutation(REFRESH_MODEL_BY_NAME);
};
