// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_GRAPH_PERMISSION = gql`
  mutation GrantGraphPermission(
    $name: String!
    $permission: String!
    $graph_name: String!
  ) {
    graphPermissionGrant(
      name: $name
      permission: $permission
      graph_name: $graph_name
    ) {
      name
      permission
      graph_name
    }
  }
`;

export const REVOKE_GRAPH_PERMISSION = gql`
  mutation RevokeGraphPermission(
    $name: String!
    $permission: String!
    $graph_name: String!
  ) {
    graphPermissionRevoke(
      name: $name
      permission: $permission
      graph_name: $graph_name
    ) {
      name
      permission
      graph_name
    }
  }
`;

export const useGrantGraphPermission = () => {
  return useMutation(GRANT_GRAPH_PERMISSION);
};

export const useRevokeGraphPermission = () => {
  return useMutation(REVOKE_GRAPH_PERMISSION);
};
