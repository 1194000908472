// Imports
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Space,
  Button,
  Tree,
  Tooltip,
  Input,
  Spin,
  Dropdown,
  Modal,
  Alert,
  Popconfirm,
  notification,
  Collapse,
} from 'antd';
import {
  DownOutlined,
  RedoOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import { useResizeDetector } from 'react-resize-detector';
import { useApolloClient } from '@apollo/client';

// App Imports
import { setExplorerPanelDataExpandedKeys } from '../../store/app/actions';
import GraphQLServices from '../../graphql/services';
import TypeObject from './objects/TypeObject';
import SchemaObject from './objects/SchemaObject';
import TableObject from './objects/TableObject';
import DatasourceObject from './objects/DatasourceObject';
import DatasinkObject from './objects/DatasinkObject';
import StreamObject from './objects/StreamObject';
import GraphObject from './objects/GraphObject';
import ModelObject from './objects/ModelObject';
import ContainerRegistryObject from './objects/ContainerRegistryObject';
import ContextObject from './objects/ContextObject';
import FuncEnvironmentObject from './objects/FuncEnvironmentObject';
import { objHasMatch, getWarehouseStatus } from '../../helper';
import Spinner from '../common/Spinner';
import GraphInfoModal from '../modal/GraphInfoModal';
import GraphDeleteModal from '../modal/GraphDeleteModal';
import TablePreviewModal from '../modal/TablePreviewModal';
import TableInfoModal from '../modal/TableInfoModal';
import TableCreateSqlModal from '../modal/TableCreateSqlModal';
import TableStatsModal from '../modal/TableStatsModal';
import TableWmsModal from '../modal/TableWmsModal';
import TableMoveModal from '../modal/TableMoveModal';
import TableExportModal from '../modal/TableExportModal';
import TableRenameModal from '../modal/TableRenameModal';
import TableAddRecordModal from '../modal/TableAddRecordModal';
import FuncEnvironmentCreateModal from '../modal/FuncEnvironmentCreateModal';
import ContextCreateModal from '../modal/ContextCreateModal';
import SchemaCreateModal from '../modal/SchemaCreateModal';
import SchemaEditModal from '../modal/SchemaEditModal';
import DatasourceCreateModal from '../modal/DatasourceCreateModal';
import DatasourceEditModal from '../modal/DatasourceEditModal';
import DatasourceTablesModal from '../modal/DatasourceTablesModal';
import DatasourceHistoryModal from '../modal/DatasourceHistoryModal';
import DatasinkInfoModal from '../modal/DatasinkInfoModal';
import DatasinkCreateModal from '../modal/DatasinkCreateModal';
import DatasinkEditModal from '../modal/DatasinkEditModal';
import StreamInfoModal from '../modal/StreamInfoModal';
import FuncEnvironmentInfoModal from '../modal/FuncEnvironmentInfoModal';
import ModelInfoModal from '../modal/ModelInfoModal';
import ModelDDLModal from '../modal/ModelDDLModal';
import ContainerRegistryInfoModal from '../modal/ContainerRegistryInfoModal';
import ContainerRegistryCreateModal from '../modal/ContainerRegistryCreateModal';
import MultiSelectContext from './MultiSelectContext';
import { ClusterContext } from '../../context';
import { displayError } from '../../helper';
import useAnalytics from '../../hooks/useAnalytics';
import { UserContext } from '../../context';
import {
  MIN_WMS_PREVIEW_MODAL_WIDTH,
  MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO,
  DATASOURCE_LOCATION_AZURE,
  DATASOURCE_LOCATION_S3,
  DATASOURCE_LOCATION_KAFKA,
  DATASOURCE_LOCATION_HDFS,
  DATASOURCE_LOCATION_JDBC,
  EXPLORER_PANEL_WIDTH,
  DATASOURCE_LOCATION_CONFLUENT,
} from '../../constants';
import { FREE_SAAS } from '../../setup/config';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';

const { Search } = Input;
const { confirm } = Modal;
const { Panel } = Collapse;

const DataExplorer = ({ offset = 0, refetchUsage = () => {} }) => {
  const { isGlobalAdmin = false } = useContext(UserContext) ?? {};

  const {
    loading: objectsLoading,
    data,
    refetch: refetchObjects,
  } = GraphQLServices.DataObjects.useGetDataObjects();
  const [
    executeSql,
    {
      loading: queryLoading,
      error: queryError,
      data: { executeSql: queryResponse } = {},
    },
  ] = GraphQLServices.SqlQueries.useExecuteSql();
  const [removeTableBySchemaAndName, { loading: removingTable }] =
    GraphQLServices.Tables.useDeleteTableBySchemaAndName();
  const [deleteSchemaByName] = GraphQLServices.Schemas.useDeleteSchemaByName();
  const [deleteDatasourceByName, { loading: removingDatasource }] =
    GraphQLServices.Datasources.useDeleteDatasourceByName();
  const [deleteDatasinkByName, { loading: removingDatasink }] =
    GraphQLServices.Datasinks.useDeleteDatasinkByName();
  const [deleteStreamByName, { loading: removingStream }] =
    GraphQLServices.TableMonitors.useDeleteTableMonitorByName();
  const [deleteModelByName, { loading: removingModel }] =
    GraphQLServices.Models.useDeleteModelByName();
  const [refreshModelByName] = GraphQLServices.Models.useRefreshModelByName();
  const [
    deleteContainerRegistryByName,
    { loading: removingContainerRegistry },
  ] = GraphQLServices.ContainerRegistries.useDeleteContainerRegistryByName();
  const [deleteContextByName, { loading: removingContext }] =
    GraphQLServices.Contexts.useDeleteContextByName();
  const [deleteFuncEnvironmentByName, { loading: removingFuncEnvironment }] =
    GraphQLServices.FuncEnvironments.useDeleteFuncEnvironmentByName();
  const [deleteGraphByName, { loading: removingGraph }] =
    GraphQLServices.Graphs.useDeleteGraphByName();
  const { data: { warehouses = [] } = {} } =
    GraphQLServices.Warehouses.useGetWarehouses();

  const [showSchemaCreateModal, setShowSchemaCreateModal] = useState(false);
  const [showSchemaEditModal, setShowSchemaEditModal] = useState(false);
  const [currentEditSchema, setCurrentEditSchema] = useState(undefined);

  const [showDatasourceCreateModal, setShowDatasourceCreateModal] =
    useState(false);
  const [showDatasourceEditModal, setShowDatasourceEditModal] = useState(false);
  const [currentEditDatasource, setCurrentEditDatasource] = useState(undefined);

  const [showDatasourceHistoryModal, setShowDatasourceHistoryModal] =
    useState(false);
  const [currentHistoryDatasource, setCurrentHistoryDatasource] =
    useState(undefined);

  const [showDatasourceTablesModal, setShowDatasourceTablesModal] =
    useState(false);
  const [currentTablesDatasource, setCurrentTablesDatasource] =
    useState(undefined);

  const [showDatasinkCreateModal, setShowDatasinkCreateModal] = useState(false);
  const [showDatasinkEditModal, setShowDatasinkEditModal] = useState(false);
  const [currentEditDatasink, setCurrentEditDatasink] = useState(undefined);

  const [
    showContainerRegistryCreateModal,
    setShowContainerRegistryCreateModal,
  ] = useState(false);

  const [infoGraph, setInfoGraph] = useState(undefined);
  const [showGraphInfo, setShowGraphInfo] = useState(false);

  const [deleteGraph, setDeleteGraph] = useState(undefined);
  const [showGraphDelete, setShowGraphDelete] = useState(false);

  const [infoTable, setInfoTable] = useState(undefined);
  const [showTableInfo, setShowTableInfo] = useState(false);

  const [renameTable, setRenameTable] = useState(undefined);
  const [showTableRename, setShowTableRename] = useState(false);

  const [moveTable, setMoveTable] = useState(undefined);
  const [showTableMove, setShowTableMove] = useState(false);

  const [previewTable, setPreviewTable] = useState(undefined);
  const [showTablePreview, setShowTablePreview] = useState(false);

  const [createSqlTable, setCreateSqlTable] = useState(undefined);
  const [showTableCreateSql, setShowTableCreateSql] = useState(false);

  const [statsTable, setStatsTable] = useState(undefined);
  const [showTableStats, setShowTableStats] = useState(false);

  const [wmsTable, setWmsTable] = useState(undefined);
  const [wmsTableDefaults, setWmsTableDefaults] = useState(undefined);
  const [showTableWms, setShowTableWms] = useState(false);

  const [addRecordTable, setAddRecordTable] = useState(undefined);
  const [showTableAddRecord, setShowTableAddRecord] = useState(false);

  const [exportTable, setExportTable] = useState(undefined);
  const [showTableExport, setShowTableExport] = useState(false);

  const [infoDatasink, setInfoDatasink] = useState(undefined);
  const [showDatasinkInfo, setShowDatasinkInfo] = useState(false);

  const [infoStream, setInfoStream] = useState(undefined);
  const [showStreamInfo, setShowStreamInfo] = useState(false);

  const [infoModel, setInfoModel] = useState(undefined);
  const [showModelInfo, setShowModelInfo] = useState(false);

  const [ddlModel, setDDLModel] = useState(undefined);
  const [showModelDDL, setShowModelDDL] = useState(false);

  const [infoContainerRegistry, setInfoContainerRegistry] = useState(undefined);
  const [showContainerRegistryInfo, setShowContainerRegistryInfo] =
    useState(false);

  const [infoContext, setInfoContext] = useState(undefined);
  const [showContextInfo, setShowContextInfo] = useState(false);
  const [configureContext, setConfigureContext] = useState(undefined);
  const [showContextConfigure, setShowContextConfigure] = useState(false);
  const [showContextCreate, setShowContextCreate] = useState(false);

  const [infoFuncEnvironment, setInfoFuncEnvironment] = useState(undefined);
  const [showFuncEnvironmentInfo, setShowFuncEnvironmentInfo] = useState(false);
  const [showFuncEnvironmentCreate, setShowFuncEnvironmentCreate] =
    useState(false);

  const [entityFilterStr, setEntityFilterStr] = useState('');
  const [tablesData, setTablesData] = useState([]);
  const [datasourcesData, setDatasourcesData] = useState([]);
  const [datasinksData, setDatasinksData] = useState([]);
  const [streamsData, setStreamsData] = useState([]);
  const [graphsData, setGraphsData] = useState([]);
  const [containerRegistriesData, setContainerRegistriesData] = useState([]);
  const [modelsData, setModelsData] = useState([]);
  const [contextsData, setContextsData] = useState([]);
  const [funcEnvironmentsData, setFuncEnvironmentsData] = useState([]);

  const { explorerPanelExpandedKeys } = useSelector(state => state.app);
  const [expandedKeys, setExpandedKeys] = useState([]);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);

  // KUI-2008
  // (inelegeant) safeguard against auto expandig all menus in unwanted situations
  const [freshlyCreated, setFreshlyCreated] = useState(true);

  const dispatch = useDispatch();
  const history = useHistory();
  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const graphqlClient = useApolloClient();

  const { listen, cancel } = useEvent(EVENT_TYPES.TABLES_UPDATE);

  const [dataTablesResp, setDataTablesResp] = useState({
    loading: false,
    data: null,
    errors: null,
  });

  const [slowDataObjectsResp, setSlowDataObjectsResp] = useState({
    loading: false,
    data: null,
    errors: null,
  });

  const refetchTables = useCallback(
    async _ => {
      try {
        const {
          data = null,
          loading = false,
          errors = null,
        } = await graphqlClient.query({
          query: GraphQLServices.DataObjects.GET_DATA_TABLES,
        });
        setDataTablesResp({ data, loading, errors });
      } catch (err) {
        console.error(err);
      }
    },
    [graphqlClient]
  );

  const refetchSlowObjects = useCallback(
    async _ => {
      if (isGlobalAdmin) {
        try {
          const {
            data = null,
            loading = false,
            errors = null,
          } = await graphqlClient.query({
            query: GraphQLServices.DataObjects.GET_SLOW_DATA_OBJECTS,
          });
          setSlowDataObjectsResp({ data, loading, errors });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [graphqlClient, isGlobalAdmin]
  );

  useEffect(
    _ => {
      refetchTables();
      refetchObjects();
      refetchSlowObjects();
      listen('data-explorer', _ => {
        refetchTables();
        refetchObjects();
        refetchSlowObjects();
      });
      return _ => {
        cancel('data-explorer');
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGlobalAdmin, refetchTables, refetchObjects, refetchSlowObjects]
  );

  const {
    loading: tablesLoading,
    data: dataTables,
    errors: tablesErrors,
  } = useMemo(
    _ => {
      return dataTablesResp;
    },
    [dataTablesResp]
  );

  const { loading: slowObjectsLoading, data: slowData } = useMemo(
    _ => {
      return slowDataObjectsResp;
    },
    [slowDataObjectsResp]
  );

  const analytics = useAnalytics();
  const { clusters } = useContext(ClusterContext);

  const warehouse = useMemo(
    _ => {
      if (warehouses && warehouses.length > 0) {
        return warehouses[0];
      }
      return null;
    },
    [warehouses]
  );

  const resetContext = useCallback(() => {
    setCurrentEditSchema(undefined);
    setShowSchemaEditModal(false);

    setInfoTable(undefined);
    setShowTableInfo(false);

    setRenameTable(undefined);
    setShowTableRename(false);

    setMoveTable(undefined);
    setShowTableMove(false);

    setPreviewTable(undefined);
    setShowTablePreview(false);

    setCreateSqlTable(undefined);
    setShowTableCreateSql(false);

    setStatsTable(undefined);
    setShowTableStats(false);

    setWmsTable(undefined);
    setWmsTableDefaults(undefined);
    setShowTableWms(false);

    setAddRecordTable(undefined);
    setShowTableAddRecord(false);

    setExportTable(undefined);
    setShowTableExport(false);

    setInfoGraph(undefined);
    setShowGraphInfo(false);

    setDeleteGraph(undefined);
    setShowGraphDelete(false);

    setInfoContainerRegistry(undefined);
    setShowContainerRegistryInfo(false);

    setCurrentEditDatasource(undefined);
    setShowDatasourceEditModal(false);

    setCurrentTablesDatasource(undefined);
    setShowDatasourceTablesModal(false);

    setCurrentEditDatasink(undefined);
    setShowDatasinkEditModal(false);

    setInfoModel(undefined);
    setShowModelInfo(false);

    setDDLModel(undefined);
    setShowModelDDL(false);

    setInfoDatasink(undefined);
    setShowDatasinkInfo(false);

    setInfoStream(undefined);
    setShowStreamInfo(false);

    setInfoContext(undefined);
    setShowContextInfo(false);

    setConfigureContext(undefined);
    setShowContextConfigure(false);

    setInfoFuncEnvironment(undefined);
    setShowFuncEnvironmentInfo(false);
  }, []);

  const filterTable = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data
      .map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          if (Array.isArray(item[cur])) {
            acc[cur] = item[cur].filter(item2 => {
              return objHasMatch(item2, filterStr);
            });
          } else {
            acc[cur] = item[cur];
          }
          return acc;
        }, {});
      })
      .filter(item => objHasMatch(item, filterStr));
  };

  const filterDatasource = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterDatasink = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterStream = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterContext = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterGraph = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterContainerRegistry = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  const filterModel = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data.filter(item => objHasMatch(item, filterStr));
  };

  useEffect(
    _ => {
      const tablesData =
        dataTables && dataTables.tables
          ? dataTables.tables
              .reduce(
                (acc, cur) => {
                  // Check if schema exists
                  let schema = acc.find(schema => schema.id === cur.schema);
                  if (!schema) {
                    // No schema found so initialize
                    acc.push({
                      id: cur.schema,
                      title: cur.schema || '< root >',
                      key: `schema.${cur.schema}`,
                      children: [],
                      selectable: false,
                    });
                    schema = acc.find(schema => schema.id === cur.schema);
                  }

                  // Add table to schema
                  schema.children.push({
                    id: cur.full,
                    title: cur.full.split('.')[1],
                    key: `table.${cur.full}`,
                    source: cur,
                    selectable: false,
                  });

                  return acc;
                },
                dataTables.schemas.map(schema => {
                  return {
                    id: schema.name,
                    title: schema.name || '< root >',
                    key: `schema.${schema.name}`,
                    source: schema,
                    children: [],
                    selectable: false,
                    checkable: false,
                  };
                })
              )
              .sort((schema1, schema2) => {
                if (schema1.id.toLowerCase() > schema2.id.toLowerCase())
                  return 1;
                if (schema1.id.toLowerCase() < schema2.id.toLowerCase())
                  return -1;
                return 0;
              })
          : [];
      setTablesData(tablesData);

      const datasourcesData =
        data && data.datasources
          ? data.datasources.map(datasource => {
              return {
                id: datasource.datasource_name,
                title: datasource.datasource_name,
                key: `datasource.${datasource.datasource_name}`,
                source: datasource,
                selectable: false,
              };
            })
          : [];
      setDatasourcesData(datasourcesData);

      const datasinksData =
        data && data.datasinks
          ? data.datasinks.map(datasink => {
              return {
                id: datasink.datasink_name,
                title: datasink.datasink_name,
                key: `datasink.${datasink.datasink_name}`,
                source: datasink,
                selectable: false,
              };
            })
          : [];
      setDatasinksData(datasinksData);

      const streamsData =
        data && data.table_monitors
          ? data.table_monitors.map(table_monitor => {
              return {
                id: table_monitor.monitor_id,
                title: table_monitor.monitor_id,
                key: `stream.${table_monitor.monitor_id}`,
                source: table_monitor,
                selectable: false,
              };
            })
          : [];
      setStreamsData(streamsData);

      const graphsData =
        data && data.graphs
          ? data.graphs.reduce((acc, cur) => {
              const idx = acc.findIndex(g => g.id === cur.graph_name);
              if (idx > -1) {
                acc[idx] = {
                  ...acc[idx],
                  children: [
                    ...acc[idx].children,
                    {
                      id: cur.graph_name,
                      title: `${cur.graph_name}`,
                      key: `graph.${cur.graph_name}.${cur.graph_server_id}`,
                      source: cur,
                      selectable: false,
                      checkable: false,
                      parent: true,
                    },
                  ],
                };
              } else {
                acc.push({
                  id: cur.graph_name,
                  title: cur.graph_name,
                  key: `graph.${cur.graph_name}`,
                  source: cur,
                  children:
                    data.graphs.filter(g => g.graph_name === cur.graph_name)
                      .length > 1
                      ? [
                          {
                            id: cur.graph_name,
                            title: `${cur.graph_name}`,
                            key: `graph.${cur.graph_name}.${cur.graph_server_id}`,
                            source: cur,
                            selectable: false,
                            checkable: false,
                            parent: true,
                          },
                        ]
                      : [],
                  selectable: false,
                  checkable: false,
                });
              }
              return acc;
            }, [])
          : [];
      setGraphsData(graphsData);

      const containerRegistriesData =
        data && data.container_registries
          ? data.container_registries.map(container_registry => {
              return {
                id: container_registry.registry_name,
                title: container_registry.registry_name,
                key: `container_registry.${container_registry.registry_name}`,
                source: container_registry,
                selectable: false,
              };
            })
          : [];
      setContainerRegistriesData(containerRegistriesData);

      const modelsData =
        data && data.models
          ? data.models.map(model => {
              return {
                id: model.model_name,
                title: model.model_name,
                key: `model.${model.model_name}`,
                source: model,
                selectable: false,
              };
            })
          : [];
      setModelsData(modelsData);

      const contextsData =
        data && data.contexts
          ? data.contexts.map(context => {
              return {
                id: context.context_name,
                title: context.context_name,
                key: `context.${context.context_name}`,
                source: context,
                selectable: false,
              };
            })
          : [];
      setContextsData(contextsData);

      const funcEnvironmentsData =
        slowData && slowData.func_environments
          ? slowData.func_environments.map(func_environment => {
              return {
                id: func_environment.func_environment_name,
                title: func_environment.func_environment_name,
                key: `func_environment.${func_environment.func_environment_name}`,
                source: func_environment,
                selectable: false,
              };
            })
          : [];
      setFuncEnvironmentsData(funcEnvironmentsData);

      if (freshlyCreated && explorerPanelExpandedKeys.data.length === 0) {
        setExpandedKeys([
          'data.tables',
          'data.datasources',
          'data.datasinks',
          'data.streams',
          'data.graphs',
          'data.container_registries',
          'data.models',
          'data.contexts',
          'data.func_environments',
          // ...tablesData.map(schema => schema.key),
        ]);
      } else {
        setExpandedKeys(explorerPanelExpandedKeys.data);
      }
    },
    [data, slowData, dataTables, explorerPanelExpandedKeys, freshlyCreated]
  );

  const treeData = useMemo(
    _ => {
      const defaultItems = [
        {
          title: 'Tables & Views',
          key: 'data.tables',
          children: filterTable(tablesData, entityFilterStr),
          selectable: false,
          checkable: false,
        },
        {
          title: 'Data Sources',
          key: 'data.datasources',
          children: filterDatasource(datasourcesData, entityFilterStr),
          selectable: false,
          checkable: false,
        },
        {
          title: 'Data Sinks',
          key: 'data.datasinks',
          children: filterDatasink(datasinksData, entityFilterStr),
          selectable: false,
          checkable: false,
        },
        {
          title: 'Streams',
          key: 'data.streams',
          children: filterStream(streamsData, entityFilterStr),
          selectable: false,
          checkable: false,
        },
      ];

      if (warehouse && warehouse.graph_enabled) {
        defaultItems.push({
          title: 'Graphs',
          key: 'data.graphs',
          children: filterGraph(graphsData, entityFilterStr),
          selectable: false,
          checkable: false,
        });
      }

      if (warehouse && warehouse.ml_enabled) {
        defaultItems.push({
          title: 'Models',
          key: 'data.models',
          children: filterModel(modelsData, entityFilterStr),
          selectable: false,
          checkable: false,
        });
        defaultItems.push({
          title: 'Container Registries',
          key: 'data.container_registries',
          children: filterContainerRegistry(
            containerRegistriesData,
            entityFilterStr
          ),
          selectable: false,
          checkable: false,
        });
      }

      defaultItems.push({
        title: 'Contexts',
        key: 'data.contexts',
        children: filterContext(contextsData, entityFilterStr),
        selectable: false,
        checkable: false,
      });

      if (isGlobalAdmin) {
        defaultItems.push({
          title: 'Function Environments',
          key: 'data.func_environments',
          children: filterContext(funcEnvironmentsData, entityFilterStr),
          selectable: false,
          checkable: false,
        });
      }

      return defaultItems;
    },
    [
      isGlobalAdmin,
      warehouse,
      tablesData,
      datasourcesData,
      datasinksData,
      streamsData,
      graphsData,
      modelsData,
      contextsData,
      funcEnvironmentsData,
      containerRegistriesData,
      entityFilterStr,
    ]
  );

  const handleAddMenuClick = useCallback(
    type => {
      switch (type.key) {
        case 'schema': {
          setShowSchemaCreateModal(true);
          return;
        }
        case 'datasource': {
          setShowDatasourceCreateModal(true);
          return;
        }
        case 'datasink': {
          setShowDatasinkCreateModal(true);
          return;
        }
        case 'stream': {
          history.push('/createstream');
          return;
        }
        case 'table': {
          history.push('/createtable', { schema: type?.schema });
          return;
        }
        case 'graph': {
          history.push('/creategraph');
          return;
        }
        case 'model': {
          history.push('/importmodel');
          return;
        }
        case 'container_registry': {
          setShowContainerRegistryCreateModal(true);
          return;
        }
        case 'externaltable': {
          history.push('/createexternaltable');
          return;
        }
        case 'func_environment': {
          setShowFuncEnvironmentCreate(true);
          return;
        }
        case 'context': {
          setShowContextCreate(true);
          return;
        }
        default: {
          return;
        }
      }
    },
    [history]
  );

  const addMenu = useMemo(
    _ => {
      const items = [];

      if (!FREE_SAAS) {
        items.push({
          key: 'schema',
          label: 'Add New Schema',
        });
      }

      const defaults = [
        {
          key: 'table',
          label: 'Add New Table',
        },
        {
          key: 'externaltable',
          label: 'Add New External Table',
        },
        {
          type: 'divider',
        },
        {
          key: 'datasource',
          label: 'Add New Data Source',
        },
        {
          key: 'datasink',
          label: 'Add New Data Sink',
        },
        {
          key: 'stream',
          label: 'Add New Stream',
        },
      ];

      items.push(...defaults);

      if (warehouse && warehouse.graph_enabled) {
        items.push({
          type: 'divider',
        });
        items.push({
          key: 'graph',
          label: 'Add New Graph',
        });
      }

      if (warehouse && warehouse.ml_enabled) {
        items.push({
          type: 'divider',
        });
        items.push({
          key: 'model',
          label: 'Import New Model',
        });
        items.push({
          key: 'container_registry',
          label: 'Add New Container Registry',
        });
      }

      if (isGlobalAdmin) {
        items.push({
          type: 'divider',
        });
        items.push({
          key: 'func_environment',
          label: 'Add New Function Environment',
        });
      }

      return {
        items,
        onClick: handleAddMenuClick,
      };
    },
    [handleAddMenuClick, warehouse, isGlobalAdmin]
  );

  const handleTreeExpand = useCallback(
    (current, { expanded, node }) => {
      // KUI-2008 prevent all keys from expanding when all are collapses
      setFreshlyCreated(false);
      if (expanded) {
        // Add to expandedKeys
        const added = [...expandedKeys, node.key];
        const update = [...new Set(added)];
        setExpandedKeys(update);
        dispatch(setExplorerPanelDataExpandedKeys(update));
      } else {
        // Remove from expandedKeys
        const copy = [...expandedKeys];
        copy.splice(copy.indexOf(node.key), 1);
        const update = [...copy];
        setExpandedKeys(update);
        dispatch(setExplorerPanelDataExpandedKeys(update));
      }
    },
    [expandedKeys, dispatch]
  );

  const handleTreeCheck = (checkedKeys, e) => {
    setCheckedKeys(checkedKeys);
  };

  const handleMultiSelect = useCallback(
    node => {
      setCheckedKeys([node.key]);
      setIsMultiSelect(!isMultiSelect);
    },
    [isMultiSelect]
  );

  const handleCancelMultiSelect = _ => {
    setCheckedKeys([]);
    setIsMultiSelect(false);
  };

  const handleDeleteSelected = _ => {
    Promise.all(
      checkedKeys.map(key => {
        const type = key.split('.')[0];
        if (type === 'table') {
          const schema = key.split('.')[1];
          const name = key.split('.')[2];
          return removeTableBySchemaAndName({
            variables: {
              schema,
              name,
            },
          });
        } else if (type === 'datasource') {
          const datasource_name = key.split('datasource.')[1];
          return deleteDatasourceByName({
            variables: {
              datasource_name,
            },
          });
        } else if (type === 'datasink') {
          const datasink_name = key.split('datasink.')[1];
          return deleteDatasinkByName({
            variables: {
              datasink_name,
            },
          });
        } else if (type === 'stream') {
          const monitor_id = key.split('stream.')[1];
          return deleteStreamByName({
            variables: {
              monitor_id,
            },
          });
        } else if (type === 'model') {
          const model_name = key.split('model.')[1];
          return deleteModelByName({
            variables: {
              model_name,
            },
          });
        } else if (type === 'container_registry') {
          const registry_name = key.split('.')[1];
          return deleteContainerRegistryByName({
            variables: {
              registry_name,
            },
          });
        } else if (type === 'graph') {
          const graph_name = key.split('graph.')[1];
          return deleteGraphByName({
            variables: {
              graph_name,
              options: {
                delete_persist: true,
                server_id: '',
              },
            },
          });
        } else if (type === 'context') {
          const context_name = key.split('context.')[1];
          return deleteContextByName({
            variables: {
              context_name,
            },
          });
        } else if (type === 'func_environment') {
          const func_environment_name = key.split('func_environment.')[1];
          return deleteFuncEnvironmentByName({
            variables: {
              func_environment_name,
            },
          });
        }
        return Promise.resolve();
      })
    )
      .then(responses => {
        notification.success({
          message: 'Success',
          description: `Selected item(s) deleted successfully!`,
        });
        refetchTables();
        refetchObjects();
        refetchSlowObjects();
        handleCancelMultiSelect();
      })
      .catch(errors => {
        console.error(errors);
      });
  };

  const handleGraphInfo = useCallback(
    graph => {
      resetContext();
      setInfoGraph(graph);
      setShowGraphInfo(true);
    },
    [resetContext]
  );

  const handleGraphSolve = useCallback(
    graph => {
      history.push('/solvegraph', {
        graph_name: graph.graph_name,
      });
    },
    [history]
  );

  const handleTableInfo = useCallback(
    table => {
      resetContext();
      setInfoTable(table);
      setShowTableInfo(true);
    },
    [resetContext]
  );

  const handleTableConfigure = useCallback(
    table => {
      history.push(`/edittable/${table.split('.').join('/')}`);
    },
    [history]
  );

  const handleTablePreview = useCallback(
    table => {
      resetContext();
      setPreviewTable(table);
      setShowTablePreview(true);
    },
    [resetContext]
  );

  const handleTableStats = useCallback(
    table => {
      resetContext();
      setStatsTable(table);
      setShowTableStats(true);
    },
    [resetContext]
  );

  const handleTableWms = useCallback(
    (table, defaults = {}) => {
      resetContext();
      setWmsTable(table);
      setWmsTableDefaults(defaults);
      setShowTableWms(true);
    },
    [resetContext]
  );

  const handleTableAddRecord = useCallback(
    table => {
      resetContext();
      setAddRecordTable(table);
      setShowTableAddRecord(true);
    },
    [resetContext]
  );

  const handleTableExport = useCallback(
    table => {
      resetContext();
      setExportTable(table);
      setShowTableExport(true);
    },
    [resetContext]
  );

  const handleTableCreateSql = useCallback(
    table => {
      const quotedTable = table
        .split('.')
        .map(part => `"${part}"`)
        .join('.');
      executeSql({
        variables: {
          statement: `SHOW ${quotedTable};`,
        },
      }).then(resp => {
        resetContext();
        setCreateSqlTable(table);
        setShowTableCreateSql(true);
      });
    },
    [executeSql, resetContext]
  );

  const handleTableRename = useCallback(
    table => {
      resetContext();
      setRenameTable(table);
      setShowTableRename(true);
    },
    [resetContext]
  );

  const handleTableMove = useCallback(
    table => {
      resetContext();
      setMoveTable(table);
      setShowTableMove(true);
    },
    [resetContext]
  );

  const handleTableRefresh = useCallback(
    table => {
      let statement;
      if (table.type === 'materialized_view') {
        statement = `REFRESH MATERIALIZED VIEW ${table.full}`;
      } else if (table.type === 'materialized_external_table') {
        statement = `REFRESH EXTERNAL TABLE ${table.full}`;
      }
      if (statement) {
        confirm({
          title: `Do you want to refresh ${table.full}?`,
          icon: <ExclamationCircleOutlined />,
          async onOk() {
            const resp = await executeSql({
              variables: {
                statement,
              },
            });
            if (resp.errors) {
              notification.open({
                message: 'Refresh',
                description: resp.errors.map(e => e.message).join('\n'),
                type: 'error',
              });
            } else {
              notification.open({
                message: 'Refresh',
                description: 'Refreshed successfully!',
                type: 'success',
              });
            }
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
          centered: true,
        });
      }
    },
    [executeSql]
  );

  const handleTableDelete = useCallback(
    table => {
      confirm({
        title: `Do you want to delete table ${table.full}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          removeTableBySchemaAndName({
            variables: {
              schema: table.schema,
              name: table.name,
            },
          })
            .then(resp => {
              refetchTables();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [removeTableBySchemaAndName, refetchTables]
  );

  const handleSchemaEdit = useCallback(
    schema => {
      resetContext();
      setCurrentEditSchema(schema);
      setShowSchemaEditModal(true);
    },
    [resetContext]
  );

  const handleSchemaDelete = useCallback(
    schema => {
      confirm({
        title: `Do you want to delete schema ${schema.name} and all tables within?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteSchemaByName({
            variables: {
              name: schema.name,
            },
          })
            .then(resp => {
              refetchTables();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [deleteSchemaByName, refetchTables]
  );

  const handleSchemaCreateCallback = (err, resp) => {
    if (resp) {
      refetchTables().then(resp => {
        setShowSchemaCreateModal(false);
      });
      analytics.track(analytics.EVENT_TYPES.CREATED_SCHEMA)({});
    } else {
      console.error(err);
    }
  };

  const handleSchemaEditCallback = (err, resp) => {
    if (resp) {
      refetchTables().then(resp => {
        resetContext();
        setCurrentEditSchema(undefined);
        setShowSchemaEditModal(false);
      });
    } else {
      console.error(err);
    }
  };

  const handleTableExportCallback = (err, resp) => {
    if (resp) {
      resetContext();
      setExportTable(undefined);
      setShowTableExport(false);
    } else {
      console.error(err);
    }
  };

  const handleTableRenameCallback = (err, resp) => {
    if (resp) {
      refetchTables().then(resp => {
        resetContext();
        setRenameTable(undefined);
        setShowTableRename(false);
      });
    } else {
      console.error(err);
    }
  };

  const handleTableMoveCallback = (err, resp) => {
    if (resp) {
      refetchTables().then(resp => {
        resetContext();
        setMoveTable(undefined);
        setShowTableMove(false);
      });
    } else {
      console.error(err);
    }
  };

  const handleDatasourceEdit = useCallback(
    datasource => {
      resetContext();
      setCurrentEditDatasource(datasource);
      setShowDatasourceEditModal(true);
    },
    [resetContext]
  );

  const handleDatasourceHistory = useCallback(
    datasource => {
      resetContext();
      setCurrentHistoryDatasource(datasource);
      setShowDatasourceHistoryModal(true);
    },
    [resetContext]
  );

  const handleDatasourceTables = useCallback(
    datasource => {
      resetContext();
      setCurrentTablesDatasource(datasource);
      setShowDatasourceTablesModal(true);
    },
    [resetContext]
  );

  const handleDatasourceImport = useCallback(
    (datasource, options = {}) => {
      switch (datasource.storage_provider_type.toLowerCase().split(':')[0]) {
        case DATASOURCE_LOCATION_AZURE:
          history.push('/importexport/azure', {
            datasource: datasource,
            ...options,
          });
          break;
        case DATASOURCE_LOCATION_S3:
          history.push('/importexport/s3', {
            datasource: datasource,
            ...options,
          });
          break;
        case DATASOURCE_LOCATION_KAFKA:
          history.push('/importexport/kafka', {
            datasource: datasource,
            fileformat: 'JSON',
            ...options,
          });
          break;
        case DATASOURCE_LOCATION_CONFLUENT:
          history.push('/importexport/confluent', {
            datasource: datasource,
            fileformat: 'JSON',
            ...options,
          });
          break;
        case DATASOURCE_LOCATION_HDFS:
          history.push('/importexport/hdfs', {
            datasource: datasource,
            ...options,
          });
          break;
        case DATASOURCE_LOCATION_JDBC:
          const regx = /jdbc:cdata:(.*):/gi;
          const cdata = regx.exec(
            datasource.storage_provider_type.toLowerCase()
          );

          const regx2 = /jdbc:(.*):/gi;
          const cdata2 = regx2.exec(
            datasource.storage_provider_type.toLowerCase()
          );

          if (cdata && cdata.length === 2) {
            history.push(`/importexport/${cdata[1]}`, {
              datasource: datasource,
              ...options,
            });
          } else if (cdata2 && cdata2.length === 2) {
            history.push(`/importexport/${cdata2[1]}`, {
              datasource: datasource,
              ...options,
            });
          } else {
            history.push('/importexport/jdbc', {
              datasource: datasource,
              ...options,
            });
          }
          break;
        default:
          history.push('/importexport/wizard', {
            datasource: datasource,
            ...options,
          });
      }
      setShowDatasourceTablesModal(false);
    },
    [history]
  );

  const handleDatasourceDelete = useCallback(
    datasource => {
      confirm({
        title: `Do you want to delete data source ${datasource.datasource_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteDatasourceByName({
            variables: {
              datasource_name: datasource.datasource_name,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteDatasourceByName]
  );

  const handleDatasourceCreateCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp2 => {
        setShowDatasourceCreateModal(false);

        // Offer continuation to import flow
        const { datasourceCreate: datasource } = resp?.data;
        confirm({
          title: `Would you like to import from data source ${datasource.datasource_name}?`,
          onOk() {
            handleDatasourceImport(datasource);
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
          centered: true,
        });
      });
    }
  };

  const handleDatasourceEditCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp => {
        resetContext();
        setCurrentEditDatasource(undefined);
        setShowDatasourceEditModal(false);
      });
    }
  };

  const handleDatasinkInfo = useCallback(
    datasink => {
      resetContext();
      setInfoDatasink(datasink);
      setShowDatasinkInfo(true);
    },
    [resetContext]
  );

  const handleDatasinkStream = useCallback(
    datasink => {
      history.push('/createstream', {
        datasink_name: datasink.datasink_name,
      });
    },
    [history]
  );

  const handleDatasinkEdit = useCallback(
    datasink => {
      resetContext();
      setCurrentEditDatasink(datasink);
      setShowDatasinkEditModal(true);
    },
    [resetContext]
  );

  const handleDatasinkDelete = useCallback(
    datasink => {
      confirm({
        title: `Do you want to delete data sink ${datasink.datasink_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteDatasinkByName({
            variables: {
              datasink_name: datasink.datasink_name,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteDatasinkByName]
  );

  const handleDatasinkCreateCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp2 => {
        setShowDatasinkCreateModal(false);
      });
    }
  };

  const handleDatasinkEditCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp => {
        resetContext();
        setCurrentEditDatasink(undefined);
        setShowDatasinkEditModal(false);
      });
    }
  };

  const handleContainerRegistryCreateCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp2 => {
        setShowContainerRegistryCreateModal(false);
      });
    }
  };

  const handleStreamInfo = useCallback(
    stream => {
      resetContext();
      setInfoStream(stream);
      setShowStreamInfo(stream);
    },
    [resetContext]
  );

  const handleStreamDelete = useCallback(
    stream => {
      confirm({
        title: `Do you want to delete stream ${stream.monitor_id}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteStreamByName({
            variables: {
              monitor_id: stream.monitor_id,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteStreamByName]
  );

  const handleContextInfo = useCallback(
    context => {
      resetContext();
      setInfoContext(context);
      setShowContextInfo(true);
    },
    [resetContext]
  );

  const handleContextConfigure = useCallback(
    context => {
      resetContext();
      setConfigureContext(context);
      setShowContextConfigure(true);
    },
    [resetContext]
  );

  const handleContextCreateCallback = (err, resp) => {
    if (resp) {
      setShowContextCreate(false);
      refetchObjects();
      analytics.track(analytics.EVENT_TYPES.CREATED_CONTEXT)({});
      resetContext();
      // const { contextCreate: contexts } = resp?.data;
      // if (contexts.length > 0) {
      //   setInfoContext(contexts[0]);
      //   setShowContextInfo(true);
      // }
    } else {
      console.error(err);
    }
  };

  const handleContextConfigureCallback = (err, resp) => {
    if (resp) {
      setShowContextConfigure(false);
      refetchObjects();
      resetContext();
      // const { contextCreate: contexts } = resp?.data;
      // if (contexts.length > 0) {
      //   setInfoContext(contexts[0]);
      //   setShowContextInfo(true);
      // }
    } else {
      console.error(err);
    }
  };

  const handleContextDelete = useCallback(
    context => {
      confirm({
        title: `Do you want to delete context ${context.context_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteContextByName({
            variables: {
              context_name: context.context_name,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteContextByName]
  );

  const handleFuncEnvironmentCreateCallback = (err, resp) => {
    if (resp) {
      setShowFuncEnvironmentCreate(false);
      refetchSlowObjects();
      analytics.track(analytics.EVENT_TYPES.CREATED_FUNC_ENVIRONMENT)({});

      resetContext();
      const { funcEnvironmentCreate: func_environment } = resp?.data;
      setInfoFuncEnvironment(func_environment);
      setShowFuncEnvironmentInfo(true);
    } else {
      console.error(err);
    }
  };

  const handleFuncEnvironmentInfo = useCallback(
    func_environment => {
      resetContext();
      setInfoFuncEnvironment(func_environment);
      setShowFuncEnvironmentInfo(true);
    },
    [resetContext]
  );

  const handleFuncEnvironmentDelete = useCallback(
    func_environment => {
      confirm({
        title: `Do you want to delete context ${func_environment.func_environment_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteFuncEnvironmentByName({
            variables: {
              func_environment_name: func_environment.func_environment_name,
            },
          })
            .then(resp => {
              refetchSlowObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchSlowObjects, deleteFuncEnvironmentByName]
  );

  const handleModelInfo = useCallback(
    model => {
      resetContext();
      setInfoModel(model);
      setShowModelInfo(true);
    },
    [resetContext]
  );

  const handleModelExecute = useCallback(
    model => {
      history.push(`/executemodel/${model.model_name}`);
    },
    [history]
  );

  const handleModelConfigure = useCallback(
    model => {
      history.push(`/configuremodel/${model.model_name}`);
    },
    [history]
  );

  const handleModelDDL = useCallback(
    model => {
      resetContext();
      setDDLModel(model);
      setShowModelDDL(true);
    },
    [resetContext]
  );

  const handleModelRefresh = useCallback(
    model => {
      confirm({
        title: `Do you want to refresh model ${model.model_name}?`,
        icon: <ExclamationCircleOutlined />,
        okText: 'Refresh',
        onOk() {
          return new Promise((resolve, reject) => {
            refreshModelByName({
              variables: {
                model_name: model.model_name,
              },
            })
              .then(resp => {
                refetchObjects();
                resolve(resp);
              })
              .catch(err => {
                console.error(err);
                reject(err);
              });
          });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refreshModelByName, refetchObjects]
  );

  const handleModelDelete = useCallback(
    model => {
      confirm({
        title: `Do you want to delete model ${model.model_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteModelByName({
            variables: {
              model_name: model.model_name,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteModelByName]
  );

  const handleContainerRegistryInfo = useCallback(
    container_registry => {
      resetContext();
      setInfoContainerRegistry(container_registry);
      setShowContainerRegistryInfo(true);
    },
    [resetContext]
  );

  const handleModelImport = useCallback(
    container_registry => {
      history.push('/importmodel', {
        registry_name: container_registry.registry_name,
      });
    },
    [history]
  );

  const handleContainerRegistryDelete = useCallback(
    container_registry => {
      confirm({
        title: `Do you want to delete container registry ${container_registry.registry_name}?`,
        icon: <ExclamationCircleOutlined />,
        onOk() {
          deleteContainerRegistryByName({
            variables: {
              registry_name: container_registry.registry_name,
            },
          })
            .then(resp => {
              refetchObjects();
            })
            .catch(err => {
              console.error(err);
            });
        },
        onCancel() {
          // Do nothing
        },
        width: 600,
        centered: true,
      });
    },
    [refetchObjects, deleteContainerRegistryByName]
  );

  const handleGraphDeleteCallback = (err, resp) => {
    if (resp) {
      refetchObjects().then(resp => {
        resetContext();
        setDeleteGraph(undefined);
        setShowGraphDelete(false);
      });
    } else {
      displayError(err);
    }
  };

  const handleGraphDelete = useCallback(
    (graph, ids = []) => {
      resetContext();
      setDeleteGraph({
        ...graph,
        delete_server_ids: ids,
      });
      setShowGraphDelete(true);
    },
    [setDeleteGraph, setShowGraphDelete, resetContext]
  );

  const handleTitleRender = useCallback(
    nodeData => {
      if (nodeData.key.startsWith('data.')) {
        return (
          <TypeObject nodeData={nodeData} handleAdd={handleAddMenuClick} />
        );
      } else if (nodeData.key.startsWith('table.')) {
        return (
          <TableObject
            nodeData={nodeData}
            handleTableInfo={handleTableInfo}
            handleTablePreview={handleTablePreview}
            handleTableCreateSql={handleTableCreateSql}
            handleTableRename={handleTableRename}
            handleTableDelete={handleTableDelete}
            handleTableStats={handleTableStats}
            handleTableWms={handleTableWms}
            handleTableAddRecord={handleTableAddRecord}
            handleTableExport={handleTableExport}
            handleTableConfigure={handleTableConfigure}
            handleTableMove={handleTableMove}
            handleTableRefresh={handleTableRefresh}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('graph.')) {
        return (
          <GraphObject
            nodeData={nodeData}
            handleGraphInfo={handleGraphInfo}
            handleGraphWms={handleTableWms}
            handleGraphSolve={handleGraphSolve}
            handleGraphDelete={handleGraphDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('container_registry.')) {
        return (
          <ContainerRegistryObject
            nodeData={nodeData}
            handleContainerRegistryInfo={handleContainerRegistryInfo}
            handleModelImport={handleModelImport}
            handleContainerRegistryDelete={handleContainerRegistryDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('model.')) {
        return (
          <ModelObject
            nodeData={nodeData}
            handleModelInfo={handleModelInfo}
            handleModelExecute={handleModelExecute}
            handleModelConfigure={handleModelConfigure}
            handleModelDelete={handleModelDelete}
            handleModelDDL={handleModelDDL}
            handleModelRefresh={handleModelRefresh}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('datasource.')) {
        return (
          <DatasourceObject
            nodeData={nodeData}
            handleDatasourceEdit={handleDatasourceEdit}
            handleDatasourceDelete={handleDatasourceDelete}
            handleDatasourceImport={handleDatasourceImport}
            handleDatasourceTables={handleDatasourceTables}
            handleDatasourceHistory={handleDatasourceHistory}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('datasink.')) {
        return (
          <DatasinkObject
            nodeData={nodeData}
            handleDatasinkInfo={handleDatasinkInfo}
            handleDatasinkStream={handleDatasinkStream}
            handleDatasinkEdit={handleDatasinkEdit}
            handleDatasinkDelete={handleDatasinkDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('stream.')) {
        return (
          <StreamObject
            nodeData={nodeData}
            handleStreamInfo={handleStreamInfo}
            handleStreamDelete={handleStreamDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('schema.')) {
        return (
          <SchemaObject
            nodeData={nodeData}
            handleSchemaEdit={handleSchemaEdit}
            handleSchemaDelete={handleSchemaDelete}
            handleAddTableClick={handleAddMenuClick}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('context.')) {
        return (
          <ContextObject
            nodeData={nodeData}
            handleContextInfo={handleContextInfo}
            handleContextConfigure={handleContextConfigure}
            handleContextDelete={handleContextDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('func_environment.')) {
        return (
          <FuncEnvironmentObject
            nodeData={nodeData}
            handleFuncEnvironmentInfo={handleFuncEnvironmentInfo}
            handleFuncEnvironmentDelete={handleFuncEnvironmentDelete}
            handleMultiSelect={handleMultiSelect}
          />
        );
      }
    },
    [
      handleAddMenuClick,
      handleTableInfo,
      handleTablePreview,
      handleTableCreateSql,
      handleTableRename,
      handleTableDelete,
      handleTableStats,
      handleTableWms,
      handleTableAddRecord,
      handleTableExport,
      handleTableConfigure,
      handleTableMove,
      handleTableRefresh,
      handleMultiSelect,
      handleGraphInfo,
      handleGraphSolve,
      handleGraphDelete,
      handleContainerRegistryInfo,
      handleModelImport,
      handleContainerRegistryDelete,
      handleModelInfo,
      handleModelExecute,
      handleModelConfigure,
      handleModelDelete,
      handleModelDDL,
      handleModelRefresh,
      handleDatasourceEdit,
      handleDatasourceDelete,
      handleDatasourceImport,
      handleDatasourceTables,
      handleDatasourceHistory,
      handleDatasinkInfo,
      handleDatasinkStream,
      handleDatasinkEdit,
      handleDatasinkDelete,
      handleStreamInfo,
      handleStreamDelete,
      handleSchemaEdit,
      handleSchemaDelete,
      handleContextInfo,
      handleContextConfigure,
      handleContextDelete,
      handleFuncEnvironmentInfo,
      handleFuncEnvironmentDelete,
    ]
  );

  const unableToFetch = useMemo(
    _ => {
      return (
        dataTables &&
        (dataTables?.schemas === null || dataTables?.tables === null)
      );
    },
    [dataTables]
  );

  const unableToFetchMessage = useMemo(
    _ => {
      if (unableToFetch && clusters && clusters.length > 0) {
        const { phase, processing } = getWarehouseStatus(clusters[0]);
        const status = processing
          ? phase.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ')
          : phase;
        if (status === 'Running') {
          return tablesErrors && tablesErrors.length > 0 ? (
            <Collapse bordered={false} ghost>
              <Panel
                header="Unable to load data objects"
                showArrow={false}
                key="1"
              >
                <ul
                  style={{
                    fontSize: '12px',
                    listStyle: 'none',
                    padding: '0px',
                  }}
                >
                  {tablesErrors.map((error, idx) => {
                    return (
                      <li key={idx} style={{ marginBottom: '10px' }}>
                        {error.message}
                      </li>
                    );
                  })}
                </ul>
              </Panel>
            </Collapse>
          ) : (
            'Unable to load data objects'
          );
        }
        return `Database is ${status}`;
      }
      return '';
    },
    [unableToFetch, clusters, tablesErrors]
  );

  useEffect(
    _ => {
      if (dataTables && data) {
        const { schemas, tables } = dataTables;
        const { datasources, datasinks, graphs, models, container_registries } =
          data;

        analytics.track(analytics.EVENT_TYPES.DATA_OBJECT_COUNT)({
          schemas: schemas?.length,
          tables: tables?.length,
          models: models?.length,
          container_registries: container_registries?.length,
          datasources: datasources?.length,
          datasinks: datasinks?.length,
          graphs: graphs?.length,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dataTables, data]
  );

  return (
    <div
      className="data-explorer"
      style={{
        padding: '15px',
        height: `calc(100vh - ${101 + offset}px)`,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
          <Space>
            <Search
              placeholder="Data search"
              value={entityFilterStr}
              onChange={e => setEntityFilterStr(e.target.value)}
              allowClear
              disabled={unableToFetch}
            />
            <Tooltip title="Refresh">
              <Button
                icon={
                  <RedoOutlined
                    spin={objectsLoading || slowObjectsLoading || tablesLoading}
                  />
                }
                onClick={() => {
                  refetchTables();
                  refetchObjects();
                  refetchSlowObjects();
                  refetchUsage();
                }}
              />
            </Tooltip>
            <Dropdown menu={addMenu} disabled={unableToFetch}>
              <Button icon={<PlusOutlined />} />
            </Dropdown>
          </Space>
        </div>
        {isMultiSelect && (
          <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
            <Space>
              <Popconfirm
                title="Are you sure you want to delete selected item(s)?"
                placement="topRight"
                onConfirm={() => handleDeleteSelected()}
                disabled={
                  checkedKeys.length === 0 ||
                  removingTable ||
                  removingDatasource ||
                  removingDatasink ||
                  removingStream ||
                  removingModel ||
                  removingContainerRegistry ||
                  removingGraph ||
                  removingContext ||
                  removingFuncEnvironment
                }
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  style={{ width: `${EXPLORER_PANEL_WIDTH - 160}px` }}
                  loading={
                    removingTable ||
                    removingDatasource ||
                    removingDatasink ||
                    removingStream ||
                    removingModel ||
                    removingContainerRegistry ||
                    removingGraph ||
                    removingContext ||
                    removingFuncEnvironment
                  }
                  disabled={
                    checkedKeys.length === 0 ||
                    removingTable ||
                    removingDatasource ||
                    removingDatasink ||
                    removingStream ||
                    removingModel ||
                    removingContainerRegistry ||
                    removingGraph ||
                    removingContext ||
                    removingFuncEnvironment
                  }
                  danger
                >
                  Delete Selected
                </Button>
              </Popconfirm>
              <Button
                onClick={() => handleCancelMultiSelect()}
                style={{ width: `${EXPLORER_PANEL_WIDTH - 236}px` }}
                disabled={
                  removingTable ||
                  removingDatasource ||
                  removingDatasink ||
                  removingStream ||
                  removingModel ||
                  removingContainerRegistry ||
                  removingGraph ||
                  removingContext ||
                  removingFuncEnvironment
                }
                danger
              >
                Cancel
              </Button>
            </Space>
          </div>
        )}
        <Spin
          indicator={<Spinner />}
          spinning={objectsLoading || tablesLoading}
          style={{ opacity: '20%' }}
        >
          <div
            ref={ref}
            style={{
              height: isMultiSelect
                ? `calc(100vh - ${220 + offset}px)`
                : `calc(100vh - ${180 + offset}px)`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {tablesData.length > 0 &&
            treeData.length > 0 &&
            height !== undefined &&
            height > 0 ? (
              <MultiSelectContext.Provider value={isMultiSelect}>
                <Tree
                  showLine={{ showLeafIcon: false }}
                  switcherIcon={<DownOutlined />}
                  expandedKeys={expandedKeys}
                  onExpand={handleTreeExpand}
                  onCheck={handleTreeCheck}
                  checkedKeys={checkedKeys}
                  treeData={treeData}
                  height={height}
                  blockNode={true}
                  titleRender={handleTitleRender}
                  checkable={isMultiSelect}
                />
              </MultiSelectContext.Provider>
            ) : unableToFetch ? (
              <Alert message={unableToFetchMessage} banner />
            ) : null}
          </div>
        </Spin>
      </Space>
      {showSchemaCreateModal && (
        <SchemaCreateModal
          visible={showSchemaCreateModal}
          close={_ => {
            setShowSchemaCreateModal(false);
          }}
          callback={handleSchemaCreateCallback}
        />
      )}
      {showDatasourceCreateModal && (
        <DatasourceCreateModal
          visible={showDatasourceCreateModal}
          close={_ => {
            setShowDatasourceCreateModal(false);
          }}
          callback={handleDatasourceCreateCallback}
        />
      )}
      {showDatasinkCreateModal && (
        <DatasinkCreateModal
          visible={showDatasinkCreateModal}
          close={_ => {
            setShowDatasinkCreateModal(false);
          }}
          callback={handleDatasinkCreateCallback}
        />
      )}
      {showContainerRegistryCreateModal && (
        <ContainerRegistryCreateModal
          visible={showContainerRegistryCreateModal}
          close={_ => {
            setShowContainerRegistryCreateModal(false);
          }}
          callback={handleContainerRegistryCreateCallback}
        />
      )}
      {infoGraph && (
        <GraphInfoModal
          graph={infoGraph}
          visible={showGraphInfo}
          setVisible={visible => {
            setShowGraphInfo(visible);
            if (!visible) {
              setInfoGraph(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {deleteGraph && (
        <GraphDeleteModal
          graph={deleteGraph}
          visible={showGraphDelete}
          close={_ => {
            setDeleteGraph(undefined);
            setShowGraphDelete(false);
          }}
          callback={handleGraphDeleteCallback}
        />
      )}
      {infoTable && (
        <TableInfoModal
          table={infoTable}
          visible={showTableInfo}
          setVisible={visible => {
            setShowTableInfo(visible);
            if (!visible) {
              setInfoTable(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {previewTable && (
        <TablePreviewModal
          table={previewTable}
          visible={showTablePreview}
          setVisible={visible => {
            setShowTablePreview(visible);
            if (!visible) {
              setPreviewTable(undefined);
            }
          }}
          width={window.innerWidth - 200}
          height={window.innerHeight - 350}
          pageSize={Math.floor((window.innerHeight - 475) / 40)}
        />
      )}
      {createSqlTable && (
        <TableCreateSqlModal
          table={createSqlTable}
          visible={showTableCreateSql}
          setVisible={visible => {
            setShowTableCreateSql(visible);
            if (!visible) {
              setCreateSqlTable(undefined);
            }
          }}
          data={queryResponse?.response?.data}
          loading={queryLoading}
          error={queryError}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {statsTable && (
        <TableStatsModal
          table={statsTable}
          visible={showTableStats}
          setVisible={visible => {
            setShowTableStats(visible);
            if (!visible) {
              setStatsTable(undefined);
            }
          }}
          width={window.innerWidth - 200}
          height={window.innerHeight - 350}
          pageSize={Math.floor((window.innerHeight - 560) / 41)}
        />
      )}
      {wmsTable && (
        <TableWmsModal
          table={wmsTable}
          defaults={wmsTableDefaults}
          visible={showTableWms}
          setVisible={visible => {
            setShowTableWms(visible);
            if (!visible) {
              setWmsTable(undefined);
              setWmsTableDefaults(undefined);
            }
          }}
          width={Math.max(
            Math.min(
              window.innerWidth - 300,
              MAX_WMS_PREVIEW_MODAL_WIDTH_RATIO * (window.innerHeight - 200)
            ),
            MIN_WMS_PREVIEW_MODAL_WIDTH
          )}
          height={window.innerHeight - 200}
        />
      )}
      {addRecordTable && (
        <TableAddRecordModal
          table={addRecordTable}
          visible={showTableAddRecord}
          setVisible={visible => {
            setShowTableAddRecord(visible);
            if (!visible) {
              setAddRecordTable(undefined);
            }
          }}
          width={800}
          height={window.innerHeight - 350}
        />
      )}
      {exportTable && (
        <TableExportModal
          table={exportTable}
          visible={showTableExport}
          close={_ => {
            setShowTableExport(false);
            setExportTable(undefined);
          }}
          callback={handleTableExportCallback}
        />
      )}
      {renameTable && (
        <TableRenameModal
          table={renameTable}
          visible={showTableRename}
          close={_ => {
            setShowTableRename(false);
          }}
          callback={handleTableRenameCallback}
        />
      )}
      {moveTable && (
        <TableMoveModal
          table={moveTable}
          visible={showTableMove}
          close={_ => {
            setShowTableMove(false);
          }}
          callback={handleTableMoveCallback}
        />
      )}
      {currentEditSchema && (
        <SchemaEditModal
          schema={currentEditSchema}
          visible={showSchemaEditModal}
          close={_ => {
            setShowSchemaEditModal(false);
            setCurrentEditSchema(undefined);
          }}
          callback={handleSchemaEditCallback}
        />
      )}
      {currentTablesDatasource && (
        <DatasourceTablesModal
          datasource={currentTablesDatasource}
          handleDatasourceImport={handleDatasourceImport}
          visible={showDatasourceTablesModal}
          setVisible={visible => {
            setShowDatasourceTablesModal(visible);
            if (!visible) {
              setCurrentTablesDatasource(undefined);
            }
          }}
          width={800}
          height={window.innerHeight - 350}
          pageSize={Math.floor((window.innerHeight - 500) / 40)}
        />
      )}
      {currentEditDatasource && (
        <DatasourceEditModal
          datasource={currentEditDatasource}
          visible={showDatasourceEditModal}
          close={_ => {
            setShowDatasourceEditModal(false);
            setCurrentEditDatasource(undefined);
          }}
          callback={handleDatasourceEditCallback}
        />
      )}
      {currentHistoryDatasource && (
        <DatasourceHistoryModal
          datasource={currentHistoryDatasource}
          visible={showDatasourceHistoryModal}
          setVisible={visible => {
            setShowDatasourceHistoryModal(visible);
            if (!visible) {
              setCurrentHistoryDatasource(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
          pageSize={Math.floor((window.innerHeight - 460) / 40)}
        />
      )}
      {infoDatasink && (
        <DatasinkInfoModal
          datasink={infoDatasink}
          visible={showDatasinkInfo}
          setVisible={visible => {
            setShowDatasinkInfo(visible);
            if (!visible) {
              setInfoDatasink(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {infoStream && (
        <StreamInfoModal
          stream={infoStream}
          visible={showStreamInfo}
          setVisible={visible => {
            setShowStreamInfo(visible);
            if (!visible) {
              setInfoStream(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {currentEditDatasink && (
        <DatasinkEditModal
          datasink={currentEditDatasink}
          visible={showDatasinkEditModal}
          close={_ => {
            setShowDatasinkEditModal(false);
            setCurrentEditDatasink(undefined);
          }}
          callback={handleDatasinkEditCallback}
        />
      )}
      {infoModel && (
        <ModelInfoModal
          model={infoModel}
          visible={showModelInfo}
          setVisible={visible => {
            setShowModelInfo(visible);
            if (!visible) {
              setInfoModel(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {ddlModel && (
        <ModelDDLModal
          model={ddlModel}
          visible={showModelDDL}
          setVisible={visible => {
            setShowModelDDL(visible);
            if (!visible) {
              setDDLModel(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {infoContainerRegistry && (
        <ContainerRegistryInfoModal
          container_registry={infoContainerRegistry}
          visible={showContainerRegistryInfo}
          setVisible={visible => {
            setShowContainerRegistryInfo(visible);
            if (!visible) {
              setInfoContainerRegistry(undefined);
            }
          }}
          width={window.innerWidth - 300}
          height={window.innerHeight - 350}
        />
      )}
      {showContextCreate && (
        <ContextCreateModal
          visible={showContextCreate}
          close={_ => {
            setShowContextCreate(false);
          }}
          callback={handleContextCreateCallback}
        />
      )}
      {configureContext && (
        <ContextCreateModal
          context_name={configureContext.context_name}
          visible={showContextConfigure}
          close={_ => {
            setShowContextConfigure(false);
            setConfigureContext(undefined);
          }}
          callback={handleContextConfigureCallback}
        />
      )}
      {infoContext && (
        <ContextCreateModal
          context_name={infoContext.context_name}
          readonly={true}
          visible={showContextInfo}
          close={_ => {
            setShowContextInfo(false);
            setInfoContext(undefined);
          }}
        />
      )}
      {showFuncEnvironmentCreate && (
        <FuncEnvironmentCreateModal
          visible={showFuncEnvironmentCreate}
          close={_ => {
            setShowFuncEnvironmentCreate(false);
          }}
          callback={handleFuncEnvironmentCreateCallback}
        />
      )}
      {infoFuncEnvironment && (
        <FuncEnvironmentInfoModal
          context={infoFuncEnvironment}
          visible={showFuncEnvironmentInfo}
          setVisible={visible => {
            setShowFuncEnvironmentInfo(visible);
            if (!visible) {
              setInfoFuncEnvironment(undefined);
            }
          }}
          width={window.innerWidth - 200}
          height={window.innerHeight - 250}
        />
      )}
    </div>
  );
};

export default DataExplorer;
