// Imports
import axios from 'axios';

// App Imports
import { APP_URL_API } from '../../setup/config';

// Actions Types
export const AUTH_LOGIN_REQUEST = 'AUTH/LOGIN_REQUEST';
export const AUTH_LOGIN_RESPONSE = 'AUTH/LOGIN_RESPONSE';
export const AUTH_USER_REQUEST = 'AUTH/USER_REQUEST';
export const AUTH_USER_RESPONSE = 'AUTH/USER_RESPONSE';
export const AUTH_SET_USER = 'AUTH/SET_USER';
export const AUTH_LOGOUT = 'AUTH/LOGOUT';

// Actions

// Login a user using credentials
export function login({ username, password, free_saas = false }) {
  return async dispatch => {
    dispatch({
      type: AUTH_LOGIN_REQUEST,
    });

    try {
      const { data } = await axios.post(
        `${APP_URL_API}/auth/login`,
        {
          free_saas,
        },
        {
          auth: {
            username,
            password,
          },
        }
      );
      if (data.success) {
        dispatch(setUser(data.user));
        dispatch({
          type: AUTH_LOGIN_RESPONSE,
        });
        return data.user;
      } else {
        dispatch(setUser(null));
        dispatch({
          type: AUTH_LOGIN_RESPONSE,
          error: new Error('Login failed'),
        });
        throw new Error('Login failed');
      }
    } catch (error) {
      dispatch(setUser(null));
      dispatch({
        type: AUTH_LOGIN_RESPONSE,
        error,
      });
      throw error;
    }
  };
}

// Log out user and remove token from local (AsyncStorage)
export function logout() {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${APP_URL_API}/auth/logout`);
      if (data.success) {
        dispatch({
          type: AUTH_LOGOUT,
        });
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
}

export function logoutSilent() {
  return async dispatch => {
    try {
      const { data } = await axios.get(`${APP_URL_API}/auth/logout`);
      if (data.success) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  };
}

// Get a user after login
export function getUser(user) {
  return async dispatch => {
    dispatch({
      type: AUTH_USER_REQUEST,
    });

    try {
      const { data } = await axios.get(`${APP_URL_API}/auth/me`);
      if (data.success) {
        dispatch(setUser(data.user));
        dispatch({
          type: AUTH_USER_RESPONSE,
        });
      } else {
        dispatch(setUser(null));
        dispatch({
          type: AUTH_USER_RESPONSE,
          error: new Error('Get user failed'),
        });
      }
    } catch (error) {
      dispatch(setUser(null));
      dispatch({
        type: AUTH_USER_RESPONSE,
        error,
      });
    }
  };
}

// Set a user after login
export function setUser(user) {
  return {
    type: AUTH_SET_USER,
    user,
  };
}
