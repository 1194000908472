// Imports
import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Button, Table, Spin, Tag } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { formatFriendlyNumberWhole, truncateLong } from '../../formatter';
import Spinner from '../common/Spinner';

const LENGTH_LIMIT = 64;

const truncate = text => {
  const regex = /\[(\d* characters truncated)\]/gi;
  const match = regex.exec(text);
  if (match) {
    return (
      <>
        {text.split(match[0])[0]}
        <Tag color="#f6f6f6" style={{ color: '#cccccc', marginLeft: '5px' }}>
          {match[1]}
        </Tag>
      </>
    );
  }
  return text;
};

const TablePreviewModal = ({
  table,
  visible,
  setVisible,
  width,
  height,
  pageSize,
}) => {
  const { schema, name } = table;

  const [currentPage, setCurrentPage] = useState(1);

  const { data: tableResp } = GraphQLServices.Tables.useGetTableByName({
    variables: {
      schema,
      name,
    },
  });

  const graphqlClient = useApolloClient();

  const [tableDataResp, setTableDataResp] = useState({
    loading: false,
    data: {},
    error: null,
  });

  useEffect(
    _ => {
      const fetch = async (use_execute_sql = false) => {
        try {
          const { data, loading, error } = await graphqlClient.query({
            query: GraphQLServices.Tables.GET_TABLE_RECORDS_BY_NAME,
            variables: {
              schema,
              name,
              offset: (currentPage - 1) * pageSize,
              limit: pageSize,
              max_json_string_length: LENGTH_LIMIT,
              use_execute_sql,
            },
          });
          setTableDataResp({ data, loading, error });
        } catch (err) {
          console.error(err);
        }
      };
      if (tableResp) {
        const {
          table: { type },
        } = tableResp;
        fetch(type === 'logical_view');
      }
    },
    [graphqlClient, name, currentPage, pageSize, schema, tableResp]
  );

  const {
    loading,
    data: { table_records } = {},
    error,
  } = useMemo(
    _ => {
      return tableDataResp;
    },
    [tableDataResp]
  );

  const renderTitle = (name, type, properties) => {
    return (
      <>
        {name}
        <br />
        <span
          style={{
            fontWeight: 300,
            fontSize: '12px',
            color: '#3700b3',
          }}
        >
          {[type]
            .concat(properties.filter(prop => !['data'].includes(prop)))
            .join(', ')}
        </span>
      </>
    );
  };

  const renderColumn = (type, properties) => text => {
    return text !== undefined && text !== null ? (
      type === 'string' ? (
        <div
          style={{
            textAlign: text.toString().length === 1 ? 'center' : 'left',
          }}
        >
          {text !== '' ? (
            truncate(text)
          ) : (
            <Tag color="#f6f6f6" style={{ color: '#cccccc' }}>
              empty
            </Tag>
          )}
        </div>
      ) : type === 'long' && properties.includes('timestamp') ? (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {new Date(text).toISOString().replace(/T|Z/g, ' ').trim()}
        </div>
      ) : type === 'long' && !properties.includes('timestamp') ? (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {truncateLong(text)}
        </div>
      ) : (
        <div
          style={{
            textAlign: 'right',
          }}
        >
          {text}
        </div>
      )
    ) : type === 'string' ? (
      <Tag color="#f6f6f6" style={{ color: '#cccccc', margin: '0px' }}>
        null
      </Tag>
    ) : (
      <Tag
        color="#f6f6f6"
        style={{ color: '#cccccc', margin: '0px', float: 'right' }}
      >
        null
      </Tag>
    );
  };

  const columns = useMemo(
    _ => {
      if (tableResp) {
        const columns = tableResp?.table?.columns ?? [];
        return columns.map(column => {
          const { name, type, properties } = column;
          return {
            key: name,
            title: renderTitle(name, type, properties),
            dataIndex: name,
            render: renderColumn(type, properties),
          };
        });
      }
      return [];
    },
    [tableResp]
  );

  const handlePagesizeChange = (page, pageSize) => {
    setCurrentPage(page);
  };

  const total = useMemo(
    _ => {
      if (table_records) {
        return Number(
          table_records?.info?.total_number_of_records ||
            table_records?.info?.count
        );
      }
      return 0;
    },
    [table_records]
  );

  return (
    <Modal
      title={
        <>
          {`Table Preview: ${schema}.${name}`}{' '}
          <label
            style={{
              fontWeight: 300,
              float: 'right',
              marginRight: '20px',
              fontSize: '14px',
            }}
          >
            {formatFriendlyNumberWhole(total)} Records
          </label>
        </>
      }
      open={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            height,
            overflow: 'auto',
          }}
        >
          {table_records && (
            <Table
              rowKey="__record_id"
              childrenColumnName="__kinetica_children__"
              columns={columns}
              dataSource={table_records.data}
              pagination={{
                current: currentPage,
                onChange: handlePagesizeChange,
                pageSize,
                pageSizeOptions: [pageSize],
                total,
                showSizeChanger: false,
              }}
              scroll={{
                x: 'max-content',
              }}
              size="small"
            />
          )}
          {error && (
            <pre className="console">Query failed: {error.message}</pre>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TablePreviewModal;
