// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Spin } from 'antd';
import { createEditor } from 'slate';
import { Slate, Editable, withReact } from 'slate-react';
import { withHistory } from 'slate-history';

// App Imports
// import GraphQLServices from '../../graphql/services';
import { BLOCK_TYPES } from '../../constants';
import Spinner from '../common/Spinner';

const withCodeVoids = editor => {
  const { isVoid } = editor;
  editor.isVoid = element =>
    element.type === BLOCK_TYPES.SQL ||
    element.type === BLOCK_TYPES.TEXT ||
    element.type === BLOCK_TYPES.IMAGE ||
    element.type === BLOCK_TYPES.HTML ||
    element.type === BLOCK_TYPES.MAP ||
    element.type === BLOCK_TYPES.GRAPH
      ? true
      : isVoid(element);
  return editor;
};

const BlockFullscreenModal = ({
  block,
  updateContent,
  renderElementFullscreen,
  visible,
  close,
  width,
  height,
  callback,
}) => {
  const editor = useMemo(
    _ => withHistory(withCodeVoids(withReact(createEditor()))),
    []
  );

  const [value, setValue] = useState([block]);

  const handleSlateChange = newValue => {
    newValue.forEach(block => {
      const content = JSON.stringify(block.content);
      updateContent(block.id, content);
    });
    setValue(newValue);
    callback(newValue);
  };

  return (
    <Modal
      title={`Block Edit`}
      open={visible}
      width={width}
      height={height}
      onCancel={close}
      footer={[
        <Button key="submit" onClick={close}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={false}>
        <div
          style={{
            maxHeight: height + 20,
            overflow: 'auto',
          }}
        >
          <Slate editor={editor} value={value} onChange={handleSlateChange}>
            <Editable
              readOnly={true}
              renderElement={renderElementFullscreen}
              style={{ pointerEvents: 'none' }}
            ></Editable>
          </Slate>
        </div>
      </Spin>
    </Modal>
  );
};

export default BlockFullscreenModal;
