// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_PERMISSIONS = gql`
  query {
    permissions {
      id
      name
      label
      description
    }
  }
`;

export const useGetPermissions = () => {
  return useQuery(GET_PERMISSIONS);
};
