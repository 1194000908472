// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Spin } from 'antd';

// App Imports
import VizMap from '../../containers/dataexplore/VizMap';
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import { MAP_VIZ_TYPE } from '../../constants';

const MapPickerModal = ({
  table,
  defaults = {},
  visible,
  setVisible,
  width,
  height,
  initWithConfig = true,
  handleGeoPicker,
}) => {
  const { loading, data } =
    GraphQLServices.VisualizationTypes.useGetVisualizationTypeById({
      variables: {
        id: MAP_VIZ_TYPE,
      },
    });

  const [config, setConfig] = useState([
    {
      table_schema: table?.schema,
      table_name: table?.name,
    },
  ]);

  const [selection, setSelection] = useState([]);

  const handleUpdateMapViz = (update, callback) => {
    setConfig([...update]);
    if (callback) {
      callback();
    }
  };

  const viz = useMemo(
    _ => {
      return {
        ...data,
        config: config.map(item => {
          return {
            table_schema: table?.schema,
            table_name: table?.name,
            ...item,
            ...defaults,
          };
        }),
      };
    },
    [table, config, data, defaults]
  );

  const handleSelectionUpdate = selection => {
    setSelection(selection);
  };

  const handleSelect = _ => {
    handleGeoPicker(selection);
    setVisible(false);
  };

  return (
    <Modal
      title={`Table WMS Picker: ${table.full}`}
      open={visible || loading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="cancel" onClick={_ => setVisible(false)}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" onClick={handleSelect}>
          Select
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            maxHeight: height - 100,
            minHeight: 400,
            overflow: 'hidden',
          }}
        >
          {data && !loading && (
            <VizMap
              viz={viz}
              handleUpdate={handleUpdateMapViz}
              minHeight={Math.max(height - 100, 400)}
              isPreview={true}
              initWithConfig={initWithConfig}
              disabledFields={['table_schema', 'table_name']}
              handleGeoPicker={handleSelectionUpdate}
              multiLayer={false}
            />
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default MapPickerModal;
