// Imports
import React from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb } from 'antd';

// App Imports
import ImportModelForm from './ImportModelForm';

// Component
const ImportModel = () => {
  const { topBarCollapsed } = useSelector(state => state.app);

  return (
    <>
      <Breadcrumb style={{ margin: '9px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Explore</Breadcrumb.Item>
        <Breadcrumb.Item>Import Model</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Import Model</h2>
      <div
        style={{
          padding: '20px 20px 0px',
          backgroundColor: '#ffffff',
          height: topBarCollapsed
            ? 'calc(100vh - 160px)'
            : 'calc(100vh - 210px)',
          overflow: 'auto',
        }}
      >
        <div
          style={{
            top: '0px',
            left: '0px',
            padding: '10px',
          }}
        >
          <ImportModelForm />
        </div>
      </div>
    </>
  );
};

export default ImportModel;
