// Imports
import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTERADMINS = gql`
  query K8sKineticaClusterAdmins {
    k8s_kineticaclusteradmins {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICACLUSTERADMIN = gql`
  mutation CreateK8sKineticaClusterAdmin($name: String!, $body: JSON!) {
    kineticaClusterAdminCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusterAdmins = options => {
  return useQuery(GET_K8S_KINETICACLUSTERADMINS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useCreateK8SKineticaClusterAdmin = () => {
  return useMutation(CREATE_K8S_KINETICACLUSTERADMIN);
};
