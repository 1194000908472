// Imports
import React, { useState, useEffect } from 'react';
import { ResponsiveLine } from '@nivo/line';
import differenceInSeconds from 'date-fns/differenceInSeconds';
import addMilliseconds from 'date-fns/addMilliseconds';

// App Imports

const getId = data => {
  return (
    data.metric.__name__ ??
    '' +
      toLabels({
        ...data.metric,
        __name__: undefined,
      })
  );
};

const COLOR_SCHEMA = [
  '#a6cee3',
  '#589cc8',
  '#3688ad',
  '#8bc495',
  '#8acb6c',
  '#40a736',
  '#929d60',
  '#fa9392',
  '#ec494a',
  '#e83d2d',
  '#f89c5e',
  '#fea746',
  '#ff8206',
  '#e39a71',
  '#c0a6d0',
  '#8862ad',
  '#91709a',
  '#e7e099',
  '#deb969',
  '#b15928',
];

const toLabels = labelObj => {
  const labels = [];
  for (let key in labelObj) {
    if (!['ring', 'cluster'].includes(key)) {
      const value = labelObj[key];
      if (typeof key === 'string') {
        if (typeof value === 'string') {
          // Labels are unquoted keys with quoted values.
          // We escape double quotes because we are using double quotes as the
          // quoting character.
          labels.push(`${key}="${value.replace('"', '\\"')}"`);
        } else if (typeof value === 'object') {
          let operator = '=';
          if (value.operator === 'equal') {
            operator = '=';
          } else if (value.operator === 'not_equal') {
            operator = '!=';
          } else if (value.operator === 'regex') {
            operator = '=~';
          } else if (value.operator === 'not_regex') {
            operator = '!~';
          }

          labels.push(`${key}${operator}"${value.replace('"', '\\"')}"`);
        }
      }
    }
  }

  if (labels.length > 0) {
    return `{${labels.join(',')}}`;
  }

  return '';
};

export default function MetricChart({
  startDate,
  endDate,
  step = 10,
  data: dataProp,
  title,
  height = 250,
  chartProps,
  lineId = getId,
}) {
  const xScaleProp = typeof chartProps === 'object' ? chartProps.xScale : null;
  const yScaleProp = typeof chartProps === 'object' ? chartProps.yScale : null;
  const colorProp = typeof chartProps === 'object' ? chartProps.colors : null;
  const yScaleStacked = yScaleProp && yScaleProp.stacked;
  const [data, setData] = useState(dataProp || []);
  const [xScale, setXScale] = useState({
    type: 'time',
    format: 'native',
    min: startDate,
    max: endDate,
  });
  const [yScale, setYScale] = useState({
    type: 'linear',
    min: 'auto',
    max: 'auto',
  });

  let colorReservationTable = {};
  let nextColorIndex = 0;
  const getColor = id => {
    if (colorReservationTable[id]) {
      return colorReservationTable[id];
    } else {
      const color = COLOR_SCHEMA[nextColorIndex];
      nextColorIndex = (nextColorIndex + 1) % COLOR_SCHEMA.length;
      colorReservationTable[id] = color;
      return color;
    }
  };

  useEffect(
    _ => {
      if (startDate && endDate) {
        setXScale({
          type: 'time',
          format: 'native',
          min: startDate,
          max: endDate,
        });

        let allLines = [];
        let shouldSetYScale = false;
        const yStats = {
          min: Infinity,
          max: -Infinity,
        };
        let lineIdIndex = 0;
        const mapResults = r => {
          const id =
            // If lineId is a string, it will apply to all metrics
            typeof lineId === 'string'
              ? lineId
              : // If lineId is a function, it will be applied to metric
              typeof lineId === 'function'
              ? lineId(r)
              : // If lineId is an array, it will be iterated through
              Array.isArray(lineId)
              ? lineId[lineIdIndex++]
              : getId(r);

          const line = {
            id,
            data: r.values.map(v => ({
              x: new Date(v[0] * 1000),
              y: v[1],
            })),
          };

          // Set the color of the line if necessary.
          // This should only be necessary if the colors.datum property is set.
          // This defines what property of the line data is used to set the
          // color.  This assigns a globally unique color to this line that can
          // be shared across charts.
          if (colorProp && colorProp.datum) {
            line[colorProp.datum] = getColor(id);
          }

          return line;
        };
        for (let result of dataProp.result) {
          // Build lines from metric data.
          const lines = [result].map(mapResults);

          // Check for data points that are greater than the step amount apart.
          // If they are, then this indicates a hole in the graph which is
          // represented using null values.
          for (let i = 0; i < lines.length; i++) {
            const line = lines[i];
            for (let j = 0; j < line.data.length - 1; j++) {
              const curPoint = line.data[j];
              const nextPoint = line.data[j + 1];

              // Insert hole if difference in data points is greater than step
              // amount.
              if (differenceInSeconds(nextPoint.x, curPoint.x) > step) {
                line.data.splice(j + 1, 0, {
                  x: addMilliseconds(curPoint.x, 1),
                  y: null,
                });
                j++;
                shouldSetYScale = true;
              }

              if (yScaleStacked) {
                yStats.min += Number(curPoint.y) || 0;
                yStats.max += Number(curPoint.y) || 0;
              } else {
                yStats.min = Math.min(Number(curPoint.y), yStats.min);
                yStats.max = Math.max(Number(curPoint.y), yStats.max);
              }
            }

            const lastPoint = line.data[line.data.length - 1];
            if (yScaleStacked) {
              yStats.min += Number(lastPoint.y) || 0;
              yStats.max += Number(lastPoint.y) || 0;
            } else {
              yStats.min = Math.min(Number(lastPoint.y), yStats.min);
              yStats.max = Math.max(Number(lastPoint.y), yStats.max);
            }
          }

          allLines = allLines.concat(lines);
        }

        // We only set the y-scale when nulls are inserted because nulls will
        // make the auto-scaling of the data start at 0.
        if (shouldSetYScale) {
          const ySpec = {};
          if (yStats.min !== Infinity) {
            ySpec.min = yStats.min;
          }
          if (yStats.max !== -Infinity) {
            ySpec.max = yStats.max;
          }
          setYScale({
            ...yScale,
            ...ySpec,
          });
        } else {
          setYScale({
            type: 'linear',
            min: 'auto',
            max: 'auto',
          });
        }

        setData(allLines);
      }

      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [startDate, endDate]
  );

  return (
    <div style={{ width: '100%', height, marginBottom: '20px' }}>
      <div className="ki_metric_chart">
        {title && <label>{title}</label>}
        <ResponsiveLine
          {...chartProps}
          xScale={{ ...xScale, ...xScaleProp }}
          yScale={{ ...yScale, ...yScaleProp }}
          data={data}
          enableArea={true}
        ></ResponsiveLine>
      </div>
    </div>
  );
}
