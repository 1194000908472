// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_AUTH_PROVIDERS = gql`
  query {
    auth_providers {
      id
      name
      description
      type
      params
      created_at
      updated_at
    }
  }
`;

export const useGetAuthProviders = () => {
  return useQuery(GET_AUTH_PROVIDERS);
};
