//Imports
import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Tabs, Button, Row, Col } from 'antd';
import {
  TableOutlined,
  AreaChartOutlined,
  BarChartOutlined,
  LineChartOutlined,
  PieChartOutlined,
  DotChartOutlined,
  FieldBinaryOutlined,
  ClusterOutlined,
  GlobalOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import { VIZ_TYPES } from '../../constants';
import VizBarChart from './VizBarChart';
import VizLineChart from './VizLineChart';
import VizPieChart from './VizPieChart';
import VizBigNumber from './VizBigNumber';
import VizGraph from './VizGraph';
import VizMapQuery from './VizMapQuery';
import VizScatterPlotChart from './VizScatterPlotChart';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import ResultTable from './ResultTable';
import ResultInfo from './ResultInfo';
import ResultError from './ResultError';
import { addOrUpdateBlockResult } from '../../store/worksheet/actions';

const MAP_BLOCK_HEIGHT = 535;
const RESULT_PANEL_MIN_HEIGHT = 260;

export default function ResultView({
  element,
  queryResponse,
  queryError,
  readOnly = false,
  run = () => {},
}) {
  const { id: block_id } = element;

  const [createVisualization] =
    GraphQLServices.Visualizations.useCreateVisualization();
  const [updateVisualizationById] =
    GraphQLServices.Visualizations.useUpdateVisualizationById();
  const [removeVisualizationById] =
    GraphQLServices.Visualizations.useRemoveVisualizationById();
  const [executeSql] = GraphQLServices.SqlQueries.useExecuteSql();
  const dispatch = useDispatch();

  const [graphQueryResponse, setGraphQueryResponse] = useState(null);
  const [graphColumns, setGraphColumns] = useState([]);

  const [visualizationsResp, setVisualizationsResp] = useState({
    loading: false,
    data: null,
    errors: null,
  });

  const graphqlClient = useApolloClient();

  const { data: { visualizationsByBlockIds: visualizations } = [] } = useMemo(
    _ => {
      if (!visualizationsResp.data) {
        return {
          ...visualizationsResp,
          data: {
            visualizationsByBlockIds: null,
          },
        };
      }
      return visualizationsResp;
    },
    [visualizationsResp]
  );

  const refetchVisualizations = useCallback(
    async _ => {
      if (queryResponse) {
        try {
          const {
            data = null,
            loading = false,
            errors = null,
          } = await graphqlClient.query({
            query:
              GraphQLServices.Visualizations.GET_VISUALIZATIONS_BY_BLOCK_IDS,
            variables: {
              block_ids: [block_id],
            },
          });
          setVisualizationsResp({ data, loading, errors });
        } catch (err) {
          console.error(err);
        }
      }
    },
    [block_id, graphqlClient, queryResponse]
  );

  useEffect(() => {
    refetchVisualizations();
  }, [refetchVisualizations]);

  const handleAddBarChartViz = useCallback(
    _ => {
      const { BAR_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Bar Chart Visualization',
          description: 'Description for bar chart visualization',
          config: { type: 'bar_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddLineChartViz = useCallback(
    _ => {
      const { LINE_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Line Chart Visualization',
          description: 'Description for line chart visualization',
          config: { type: 'line_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddBigNumberViz = useCallback(
    _ => {
      const { BIG_NUMBER: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Big Number Visualization',
          description: 'Description for big number visualization',
          config: { type: 'big_number' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddGraphViz = useCallback(
    _ => {
      const { GRAPH: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Graph Visualization',
          description: 'Description for graph visualization',
          config: { type: 'graph' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddMap = useCallback(
    _ => {
      const { MAP: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Map Visualization',
          description: 'Description for map visualization',
          config: { type: 'map' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddScatterPlotChartViz = useCallback(
    _ => {
      const { SCATTER_PLOT_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Scatter Plot Chart Visualization',
          description: 'Description for scatter plot chart visualization',
          config: { type: 'scatter_plot_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleAddPieChartViz = useCallback(
    _ => {
      const { PIE_CHART: visualization_type_id } = VIZ_TYPES;
      createVisualization({
        variables: {
          name: 'Pie Chart Visualization',
          description: 'Description for pie chart visualization',
          config: { type: 'pie_chart' },
          block_id,
          visualization_type_id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [block_id, createVisualization, refetchVisualizations]
  );

  const handleUpdateViz = useCallback(
    (config, callback) => {
      updateVisualizationById({
        variables: {
          id: visualizations?.length > 0 && visualizations[0].id,
          config,
        },
      }).then(resp => {
        refetchVisualizations();
        if (callback) {
          callback();
        }
      });
    },
    [updateVisualizationById, refetchVisualizations, visualizations]
  );

  const handleRemoveViz = useCallback(
    _ => {
      removeVisualizationById({
        variables: {
          id: visualizations?.length > 0 && visualizations[0].id,
        },
      }).then(resp => {
        refetchVisualizations();
      });
    },
    [visualizations, removeVisualizationById, refetchVisualizations]
  );

  const clearResult = useCallback(
    _ => {
      dispatch(
        addOrUpdateBlockResult(block_id, {
          queryError: null,
          queryResponse: null,
          queryRunning: false,
          queryID: null,
        })
      );
    },
    [block_id, dispatch]
  );

  const chartData = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        const { data } = queryResponse?.response;
        return data;
      }
      return {};
    },
    [queryResponse]
  );

  const columns = useMemo(
    _ => {
      if (queryResponse?.response?.data) {
        const { data } = queryResponse?.response;
        return data.column_headers;
      }
      return [];
    },
    [queryResponse]
  );

  useEffect(
    _ => {
      const run = async () => {
        if (queryResponse) {
          const { statement = '' } = queryResponse;
          const match = statement.match(/ADJACENCY_TABLE => '(.*)'/im);
          if (match && match.length >= 2) {
            const resp = await executeSql({
              variables: {
                statement: `SELECT * FROM ${match[1]}`,
                limit: EXECUTE_SQL_LIMIT,
              },
            });
            const { executeSql: data } = resp.data;
            setGraphQueryResponse(data);
            setGraphColumns(data.response.data.column_headers);
            if (visualizations && visualizations.length === 0) {
              handleAddGraphViz();
            }
          } else {
            setGraphQueryResponse(null);
            setGraphColumns([]);
          }
        } else {
          setGraphQueryResponse(null);
          setGraphColumns([]);
        }
      };
      run();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [queryResponse, executeSql, handleAddGraphViz]
  );

  const dataTab = useMemo(_ => {
    return (
      <span>
        <TableOutlined />
        Data
      </span>
    );
  }, []);

  const vizTab = useMemo(_ => {
    return (
      <span>
        <AreaChartOutlined />
        Visualization
      </span>
    );
  }, []);

  const resultItems = useMemo(
    _ => {
      const items = [
        {
          key: 'data',
          label: dataTab,
          children: (
            <div style={{ padding: '10px' }}>
              <ResultTable
                blockId={element.id}
                clearResult={clearResult}
                queryResponse={graphQueryResponse ?? queryResponse}
              />
            </div>
          ),
        },
      ];

      if (!readOnly || (visualizations && visualizations.length > 0)) {
        items.push({
          key: 'viz',
          label: vizTab,
          children: (
            <div style={{ padding: '10px' }}>
              {visualizations?.length === 0 && (
                <Row gutter={15}>
                  <Col span={6}>
                    <Button
                      onClick={handleAddBarChartViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <BarChartOutlined /> Bar Chart
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddLineChartViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <LineChartOutlined /> Line Chart
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddPieChartViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <PieChartOutlined />
                      Pie Chart
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddScatterPlotChartViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <DotChartOutlined />
                      Scatter Plot
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddBigNumberViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <FieldBinaryOutlined />
                      Big Number
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddGraphViz}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <ClusterOutlined />
                      Graph
                    </Button>
                  </Col>
                  <Col span={6}>
                    <Button
                      onClick={handleAddMap}
                      size="large"
                      type="dashed"
                      style={{
                        marginBottom: 15,
                        padding: 15,
                        height: 60,
                      }}
                      block
                    >
                      <GlobalOutlined />
                      Map
                    </Button>
                  </Col>
                </Row>
              )}
              {visualizations?.length > 0 &&
                visualizations?.map(visualization => {
                  if (
                    visualization.visualization_type.id === VIZ_TYPES.BAR_CHART
                  ) {
                    return (
                      <VizBarChart
                        key={visualization.id}
                        viz={visualization}
                        data={chartData}
                        columns={columns}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={RESULT_PANEL_MIN_HEIGHT}
                      />
                    );
                  } else if (
                    visualization.visualization_type.id === VIZ_TYPES.LINE_CHART
                  ) {
                    return (
                      <VizLineChart
                        key={visualization.id}
                        viz={visualization}
                        data={chartData}
                        columns={columns}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={RESULT_PANEL_MIN_HEIGHT}
                      />
                    );
                  } else if (
                    visualization.visualization_type.id === VIZ_TYPES.PIE_CHART
                  ) {
                    return (
                      <VizPieChart
                        key={visualization.id}
                        viz={visualization}
                        data={chartData}
                        columns={columns}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={RESULT_PANEL_MIN_HEIGHT}
                      />
                    );
                  } else if (
                    visualization.visualization_type.id ===
                    VIZ_TYPES.SCATTER_PLOT_CHART
                  ) {
                    return (
                      <VizScatterPlotChart
                        key={visualization.id}
                        viz={visualization}
                        data={chartData}
                        columns={columns}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={RESULT_PANEL_MIN_HEIGHT}
                      />
                    );
                  } else if (
                    visualization.visualization_type.id === VIZ_TYPES.MAP
                  ) {
                    let height = MAP_BLOCK_HEIGHT;
                    if (
                      visualization.config &&
                      visualization.config.length > 0
                    ) {
                      height =
                        visualization.config[0].block_height + 50 ||
                        MAP_BLOCK_HEIGHT;
                    }
                    const viz = {
                      config: Array.isArray(visualization.config)
                        ? [...visualization.config]
                        : [
                            {
                              ...visualization.config,
                            },
                          ],
                      visualization_type: {
                        ...visualization.visualization_type,
                      },
                    };
                    return (
                      <VizMapQuery
                        query={queryResponse?.statement}
                        key={visualization.id}
                        viz={viz}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={height + 10}
                        maxWidth={2046}
                        disabledFields={['table_schema', 'table_name']}
                        readOnly={readOnly}
                      />
                    );
                  } else if (
                    visualization.visualization_type.id === VIZ_TYPES.BIG_NUMBER
                  ) {
                    return (
                      <VizBigNumber
                        key={visualization.id}
                        viz={visualization}
                        data={chartData}
                        run={run}
                        columns={columns}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={
                          !readOnly
                            ? RESULT_PANEL_MIN_HEIGHT - 100
                            : RESULT_PANEL_MIN_HEIGHT - 120
                        }
                      />
                    );
                  } else if (
                    visualization.visualization_type.id === VIZ_TYPES.GRAPH
                  ) {
                    let config = {};
                    if (graphQueryResponse) {
                      const { statement = '' } = graphQueryResponse;
                      const match = statement.match(/SELECT \* FROM (.*)/im);

                      let edge_table_schema = '';
                      let edge_table_name = '';

                      if (match && match.length >= 2) {
                        if (match[1].split('.').length > 1) {
                          edge_table_schema = match[1].split('.')[0];
                          edge_table_name = match[1].split('.')[1];
                        } else {
                          edge_table_name = match[1];
                        }
                      }

                      const edge_columns = graphColumns.filter(column =>
                        column.match(/_NODE(.*)_/im)
                      );

                      config = {
                        edge_table_schema,
                        edge_table_name,
                        edge_source_column: edge_columns[0] ?? '',
                        edge_target_column: edge_columns[1] ?? '',
                        edge_label_column: edge_columns[2] ?? '',
                        ...visualization.config,
                      };
                    } else if (queryResponse) {
                      const edge_columns = chartData.column_headers.filter(
                        column => column.match(/_NODE(.*)_/im)
                      );

                      config = {
                        edge_source_column: edge_columns[0] ?? '',
                        edge_target_column: edge_columns[1] ?? '',
                        edge_label_column: edge_columns[2] ?? '',
                        ...visualization.config,
                      };
                    }

                    const viz = {
                      ...visualization,
                      config,
                    };

                    return (
                      <VizGraph
                        key={visualization.id}
                        viz={viz}
                        data={!graphQueryResponse ? chartData : null}
                        handleUpdate={handleUpdateViz}
                        handleRemove={handleRemoveViz}
                        minHeight={500}
                      />
                    );
                  }
                  return '';
                })}
            </div>
          ),
        });
      }

      return items;
    },
    [
      element.id,
      chartData,
      clearResult,
      columns,
      dataTab,
      graphColumns,
      graphQueryResponse,
      handleAddBarChartViz,
      handleAddBigNumberViz,
      handleAddGraphViz,
      handleAddLineChartViz,
      handleAddMap,
      handleAddPieChartViz,
      handleAddScatterPlotChartViz,
      handleRemoveViz,
      handleUpdateViz,
      queryResponse,
      readOnly,
      run,
      visualizations,
      vizTab,
    ]
  );

  const resultViz = useMemo(
    _ => {
      if (
        visualizations &&
        (queryResponse?.response?.total_number_of_records >= 0 ||
          graphQueryResponse?.response?.total_number_of_records >= 0)
      ) {
        return (
          <div
            style={{
              padding: '1px',
              backgroundColor: '#fcfcfc',
              margin: '10px 0px 0px',
            }}
          >
            <Tabs
              defaultActiveKey={
                visualizations?.length > 0 ||
                graphQueryResponse?.response?.total_number_of_records >= 0
                  ? 'viz'
                  : 'data'
              }
              items={resultItems}
              size="small"
              type="card"
            ></Tabs>
          </div>
        );
      }
      return null;
    },
    [
      visualizations,
      queryResponse?.response?.total_number_of_records,
      graphQueryResponse?.response?.total_number_of_records,
      resultItems,
    ]
  );

  const selectViz = useMemo(
    _ => {
      if (
        queryResponse &&
        queryResponse?.response?.total_number_of_records >= 0
      ) {
        return (
          <ResultInfo
            key="select"
            clearResult={clearResult}
            queryResponse={queryResponse}
          />
        );
      } else {
        return null;
      }
    },
    [queryResponse, clearResult]
  );

  const infoViz = useMemo(
    _ => {
      if (
        queryResponse &&
        queryResponse?.response?.total_number_of_records === -1
      ) {
        return (
          <ResultInfo
            key="info"
            clearResult={clearResult}
            queryResponse={queryResponse}
          />
        );
      } else {
        return null;
      }
    },
    [queryResponse, clearResult]
  );

  const errorViz = useMemo(
    _ => {
      if (queryError && queryError?.message) {
        return (
          <ResultError
            key="error"
            clearResult={clearResult}
            queryError={queryError}
          />
        );
      } else {
        return null;
      }
    },
    [queryError, clearResult]
  );

  return (
    <div>
      {selectViz}
      {resultViz}
      {infoViz}
      {errorViz}
    </div>
  );
}
