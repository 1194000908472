// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_TABLE_MONITORS = gql`
  query TableMonitors {
    table_monitors {
      monitor_id
      table_name
      event
      increasing_column
      filter_expression
      refresh_method
      refresh_period
      refresh_start_time
      datasink_name
      additional_info
    }
  }
`;

export const GET_TABLE_MONITOR_BY_NAME = gql`
  query GetTableMonitorByName($monitor_id: String!) {
    table_monitor(monitor_id: $monitor_id) {
      monitor_id
      table_name
      event
      increasing_column
      filter_expression
      refresh_method
      refresh_period
      refresh_start_time
      datasink_name
      additional_info
    }
  }
`;

export const CREATE_TABLE_MONITOR = gql`
  mutation CreateTableMonitor(
    $monitor_id: String!
    $table_name: String!
    $event: String!
    $increasing_column: String
    $filter_expression: String
    $refresh_method: String
    $refresh_period: Int
    $refresh_start_time: String
    $datasink_name: String!
  ) {
    tableMonitorCreate(
      monitor_id: $monitor_id
      table_name: $table_name
      event: $event
      increasing_column: $increasing_column
      filter_expression: $filter_expression
      refresh_method: $refresh_method
      refresh_period: $refresh_period
      refresh_start_time: $refresh_start_time
      datasink_name: $datasink_name
    ) {
      monitor_id
      table_name
      event
      increasing_column
      filter_expression
      refresh_method
      refresh_period
      refresh_start_time
      datasink_name
      additional_info
    }
  }
`;

export const DELETE_TABLE_MONITOR_BY_NAME = gql`
  mutation DeleteTableMonitorByName($monitor_id: String!) {
    tableMonitorDeleteByName(monitor_id: $monitor_id) {
      monitor_id
    }
  }
`;

export const useGetTableMonitors = () => {
  return useQuery(GET_TABLE_MONITORS);
};

export const useGetTableMonitorByName = options => {
  return useQuery(GET_TABLE_MONITOR_BY_NAME, options);
};

export const useCreateTableMonitor = () => {
  return useMutation(CREATE_TABLE_MONITOR);
};

export const useDeleteTableMonitorByName = () => {
  return useMutation(DELETE_TABLE_MONITOR_BY_NAME);
};
