// Imports
import React, { useMemo } from 'react';
import { Select } from 'antd';
import { detailedDiff } from 'deep-object-diff';

// App Imports
import {
  RESOURCE_GROUP_SYSTEM_NAME,
  TABLE_PERMISSIONS,
  FILES_PERMISSIONS,
  DATASOURCE_PERMISSIONS,
  DATASINK_PERMISSIONS,
} from '../../constants';

const { Option } = Select;

export const useRoleOptions = roles => {
  return useMemo(
    _ => {
      if (roles) {
        return roles.map(role => {
          return (
            <Option key={role.name} value={role.name}>
              {role.name}
            </Option>
          );
        });
      } else {
        return [];
      }
    },
    [roles]
  );
};

export const useResourceGroupOptions = resource_groups => {
  return useMemo(
    _ => {
      if (resource_groups) {
        return resource_groups
          .filter(resource_group => {
            return resource_group.name !== RESOURCE_GROUP_SYSTEM_NAME;
          })
          .map(resource_group => {
            return (
              <Option key={resource_group.name} value={resource_group.name}>
                {resource_group.alias}
              </Option>
            );
          });
      } else {
        return [];
      }
    },
    [resource_groups]
  );
};

export const useSchemaOptions = schemas => {
  return useMemo(
    _ => {
      const noneOptions = (
        <Option key="<NONE>" value="">
          &#60;NONE&#62;
        </Option>
      );
      if (schemas) {
        return [
          noneOptions,
          ...schemas.map(schema => {
            return (
              <Option key={schema.name} value={schema.name}>
                {schema.name}
              </Option>
            );
          }),
        ];
      } else {
        return [noneOptions];
      }
    },
    [schemas]
  );
};

export const useTableOptions = (tables, tablePermissions) => {
  return useMemo(
    _ => {
      const noneOptions = <Option key="" value=""></Option>;
      if (tables && tablePermissions) {
        return [
          noneOptions,
          ...tables
            .filter(table => {
              return !tablePermissions.find(perm => perm.table === table.full);
            })
            .map(table => {
              return (
                <Option key={table.full} value={table.full}>
                  {table.full}
                </Option>
              );
            }),
        ];
      } else {
        return [];
      }
    },
    [tables, tablePermissions]
  );
};

export const useFolderFilesOptions = folders => {
  return useMemo(
    _ => {
      const noneOptions = <Option key="" value=""></Option>;
      const rootOptions = (
        <Option key="<ROOT>" value="<ROOT>">
          &lt;ROOT&gt;
        </Option>
      );
      return [
        noneOptions,
        rootOptions,
        ...folders.map(folder => {
          return (
            <Option key={folder.name} value={folder.name}>
              {folder.name}
            </Option>
          );
        }),
      ];
    },
    [folders]
  );
};

export const useSchemaTableOptions = (schemas, tables, tablePermissions) => {
  return useMemo(
    _ => {
      const noneOptions = <Option key="" value=""></Option>;
      if (schemas && tables && tablePermissions) {
        return [
          noneOptions,
          ...[...schemas, ...tables]
            .sort((x, y) => {
              const xName = x.full || x.name;
              const yName = y.full || y.name;
              if (xName.toLowerCase() > yName.toLowerCase()) return 1;
              if (xName.toLowerCase() < yName.toLowerCase()) return -1;
              return 0;
            })
            .filter(item => {
              return !tablePermissions.find(perm =>
                item.full ? perm.table === item.full : perm.table === item.name
              );
            })
            .map(item => {
              if (item.full) {
                return (
                  <Option key={item.full} value={item.full}>
                    {item.full}
                  </Option>
                );
              }
              return (
                <Option key={item.name} value={item.name}>
                  {item.name}
                </Option>
              );
            }),
        ];
      } else {
        return [];
      }
    },
    [schemas, tables, tablePermissions]
  );
};

export const usePermissionOptions = permissions => {
  return useMemo(
    _ => {
      if (permissions) {
        return permissions
          .map(permission => ({
            key: permission.name,
            title: permission.label,
          }))
          .sort((a, b) => {
            if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
            if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
            return 0;
          });
      } else {
        return [];
      }
    },
    [permissions]
  );
};

export const useSqlProcedureOptions = sql_procedures => {
  return useMemo(
    _ => {
      if (sql_procedures) {
        return sql_procedures.map(sql_procedure => ({
          key: sql_procedure.procedure_name,
          title: sql_procedure.procedure_name,
        }));
      } else {
        return [];
      }
    },
    [sql_procedures]
  );
};

export const useDatasourceOptions = datasources => {
  return useMemo(
    _ => {
      const noneOptions = <Option key="" value=""></Option>;
      return [
        noneOptions,
        ...datasources.map(datasource => {
          return (
            <Option
              key={datasource.datasource_name}
              value={datasource.datasource_name}
            >
              {datasource.datasource_name}
            </Option>
          );
        }),
      ];
    },
    [datasources]
  );
};

export const useDatasinkOptions = datasinks => {
  return useMemo(
    _ => {
      const noneOptions = <Option key="" value=""></Option>;
      return [
        noneOptions,
        ...datasinks.map(datasink => {
          return (
            <Option key={datasink.datasink_name} value={datasink.datasink_name}>
              {datasink.datasink_name}
            </Option>
          );
        }),
      ];
    },
    [datasinks]
  );
};

export const diffRoles = (initialValues, updateValues) => {
  const diff = detailedDiff(initialValues, updateValues);
  const { added, deleted, updated } = diff;
  const removeRoles = Object.keys(deleted?.roles ?? {})
    .map(idx => initialValues.roles[idx])
    .concat(
      Object.keys(updated?.roles ?? {}).map(idx => {
        return initialValues.roles[idx];
      })
    );
  const addRoles = Object.values(added?.roles ?? {}).concat(
    Object.keys(updated?.roles ?? {}).map(idx => {
      return updated?.roles[idx];
    })
  );
  return {
    addRoles,
    removeRoles,
  };
};

export const diffPermissions = (initialValues, updateValues) => {
  const diff = detailedDiff(initialValues, updateValues);
  const { added, deleted, updated } = diff;
  const revokePermissions = Object.keys(deleted?.permissions ?? {})
    .map(idx => initialValues.permissions[idx])
    .concat(
      Object.keys(updated?.permissions ?? {}).map(idx => {
        return initialValues.permissions[idx];
      })
    );
  const grantPermissions = Object.values(added?.permissions ?? {}).concat(
    Object.keys(updated?.permissions ?? {}).map(idx => {
      return updated?.permissions[idx];
    })
  );
  return {
    grantPermissions,
    revokePermissions,
  };
};

export const diffSystemPermissions = (initialValues, updateValues) => {
  const diff = detailedDiff(initialValues, updateValues);
  const { added, deleted, updated } = diff;
  const revokeSystemPermissions = Object.keys(deleted?.system_permissions ?? {})
    .map(idx => initialValues.system_permissions[idx])
    .concat(
      Object.keys(updated?.system_permissions ?? {}).map(idx => {
        return initialValues.system_permissions[idx];
      })
    );
  const grantSystemPermissions = Object.values(
    added?.system_permissions ?? {}
  ).concat(
    Object.keys(updated?.system_permissions ?? {}).map(idx => {
      return updated?.system_permissions[idx];
    })
  );
  return {
    grantSystemPermissions,
    revokeSystemPermissions,
  };
};

export const diffProcPermissions = (initialValues, updateValues) => {
  const diff = detailedDiff(initialValues, updateValues);
  const { added, deleted, updated } = diff;
  const revokeProcPermissions = Object.keys(deleted?.proc_permissions ?? {})
    .map(idx => initialValues.proc_permissions[idx])
    .concat(
      Object.keys(updated?.proc_permissions ?? {}).map(idx => {
        return initialValues.proc_permissions[idx];
      })
    );
  const grantProcPermissions = Object.values(
    added?.proc_permissions ?? {}
  ).concat(
    Object.keys(updated?.proc_permissions ?? {}).map(idx => {
      return updated?.proc_permissions[idx];
    })
  );
  return {
    grantProcPermissions,
    revokeProcPermissions,
  };
};

export const diffSqlProcPermissions = (initialValues, updateValues) => {
  const diff = detailedDiff(initialValues, updateValues);
  const { added, deleted, updated } = diff;
  const revokeSqlProcPermissions = Object.keys(
    deleted?.sql_proc_permissions ?? {}
  )
    .map(idx => initialValues.sql_proc_permissions[idx])
    .concat(
      Object.keys(updated?.sql_proc_permissions ?? {}).map(idx => {
        return initialValues.sql_proc_permissions[idx];
      })
    );
  const grantSqlProcPermissions = Object.values(
    added?.sql_proc_permissions ?? {}
  ).concat(
    Object.keys(updated?.sql_proc_permissions ?? {}).map(idx => {
      return updated?.sql_proc_permissions[idx];
    })
  );
  return {
    grantSqlProcPermissions,
    revokeSqlProcPermissions,
  };
};

export const diffDatasourcePermissions = (initialValues, updateValues) => {
  const key = 'datasource_permissions';
  const { [key]: current } = initialValues;
  const { [key]: update } = updateValues;
  const currentObjects = current.reduce((acc, cur) => {
    const { datasource, ...rest } = cur;
    acc[datasource] = rest;
    return acc;
  }, {});
  const updateObjects = update.reduce((acc, cur) => {
    const { datasource, ...rest } = cur;
    acc[datasource] = rest;
    return acc;
  }, {});
  const objectDiff = detailedDiff(currentObjects, updateObjects);
  const deleted = Object.keys(objectDiff.deleted).reduce(
    (acc, cur) => {
      const idx = current.findIndex(obj => obj.datasource === cur);
      acc[key][idx] = undefined;
      return acc;
    },
    { [key]: {} }
  );
  const added = Object.keys(objectDiff.added).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.datasource === cur);
      acc[key][idx] = update[idx];
      return acc;
    },
    { [key]: {} }
  );
  const updated = Object.keys(objectDiff.updated).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.datasource === cur);
      acc[key][idx] = objectDiff.updated[cur];
      return acc;
    },
    { [key]: {} }
  );

  const revokeDatasourcePermissions = Object.keys(
    deleted?.datasource_permissions ?? {}
  )
    .map(idx => initialValues.datasource_permissions[idx])
    .reduce((acc, cur) => {
      DATASOURCE_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          datasource_name: cur.datasource,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.datasource_permissions ?? {}).reduce(
        (acc, cur) => {
          const datasource_name =
            initialValues.datasource_permissions[cur]?.datasource;
          Object.keys(updated?.datasource_permissions[cur])
            .filter(
              permission => !updated?.datasource_permissions[cur][permission]
            )
            .forEach(permission => {
              acc.push([
                {
                  datasource_name,
                  permission,
                },
              ]);
            });
          return acc;
        },
        []
      )
    );
  const grantDatasourcePermissions = Object.keys(
    added?.datasource_permissions ?? {}
  )
    .map(idx => updateValues.datasource_permissions[idx])
    .reduce((acc, cur) => {
      DATASOURCE_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          datasource_name: cur.datasource,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.datasource_permissions ?? {}).reduce(
        (acc, cur) => {
          const datasource_name =
            initialValues.datasource_permissions[cur]?.datasource;
          Object.keys(updated?.datasource_permissions[cur])
            .filter(
              permission => updated?.datasource_permissions[cur][permission]
            )
            .forEach(permission => {
              acc.push([
                {
                  datasource_name,
                  permission,
                },
              ]);
            });
          return acc;
        },
        []
      )
    );

  return {
    grantDatasourcePermissions,
    revokeDatasourcePermissions,
  };
};

export const diffDatasinkPermissions = (initialValues, updateValues) => {
  const key = 'datasink_permissions';
  const { [key]: current } = initialValues;
  const { [key]: update } = updateValues;
  const currentObjects = current.reduce((acc, cur) => {
    const { datasink, ...rest } = cur;
    acc[datasink] = rest;
    return acc;
  }, {});
  const updateObjects = update.reduce((acc, cur) => {
    const { datasink, ...rest } = cur;
    acc[datasink] = rest;
    return acc;
  }, {});
  const objectDiff = detailedDiff(currentObjects, updateObjects);
  const deleted = Object.keys(objectDiff.deleted).reduce(
    (acc, cur) => {
      const idx = current.findIndex(obj => obj.datasink === cur);
      acc[key][idx] = undefined;
      return acc;
    },
    { [key]: {} }
  );
  const added = Object.keys(objectDiff.added).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.datasink === cur);
      acc[key][idx] = update[idx];
      return acc;
    },
    { [key]: {} }
  );
  const updated = Object.keys(objectDiff.updated).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.datasink === cur);
      acc[key][idx] = objectDiff.updated[cur];
      return acc;
    },
    { [key]: {} }
  );

  const revokeDatasinkPermissions = Object.keys(
    deleted?.datasink_permissions ?? {}
  )
    .map(idx => initialValues.datasink_permissions[idx])
    .reduce((acc, cur) => {
      DATASINK_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          datasink_name: cur.datasink,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.datasink_permissions ?? {}).reduce((acc, cur) => {
        const datasink_name = initialValues.datasink_permissions[cur]?.datasink;
        Object.keys(updated?.datasink_permissions[cur])
          .filter(permission => !updated?.datasink_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                datasink_name,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );
  const grantDatasinkPermissions = Object.keys(
    added?.datasink_permissions ?? {}
  )
    .map(idx => updateValues.datasink_permissions[idx])
    .reduce((acc, cur) => {
      DATASINK_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          datasink_name: cur.datasink,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.datasink_permissions ?? {}).reduce((acc, cur) => {
        const datasink_name = initialValues.datasink_permissions[cur]?.datasink;
        Object.keys(updated?.datasink_permissions[cur])
          .filter(permission => updated?.datasink_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                datasink_name,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );

  return {
    grantDatasinkPermissions,
    revokeDatasinkPermissions,
  };
};

export const diffTablePermissions = (initialValues, updateValues) => {
  const key = 'table_permissions';
  const { [key]: current } = initialValues;
  const { [key]: update } = updateValues;
  const currentObjects = current.reduce((acc, cur) => {
    const { table, ...rest } = cur;
    acc[table] = rest;
    return acc;
  }, {});
  const updateObjects = update.reduce((acc, cur) => {
    const { table, ...rest } = cur;
    acc[table] = rest;
    return acc;
  }, {});
  const objectDiff = detailedDiff(currentObjects, updateObjects);
  const deleted = Object.keys(objectDiff.deleted).reduce(
    (acc, cur) => {
      const idx = current.findIndex(obj => obj.table === cur);
      acc[key][idx] = undefined;
      return acc;
    },
    { [key]: {} }
  );
  const added = Object.keys(objectDiff.added).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.table === cur);
      acc[key][idx] = update[idx];
      return acc;
    },
    { [key]: {} }
  );
  const updated = Object.keys(objectDiff.updated).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.table === cur);
      acc[key][idx] = objectDiff.updated[cur];
      return acc;
    },
    { [key]: {} }
  );

  const revokeTablePermissions = Object.keys(deleted?.table_permissions ?? {})
    .map(idx => initialValues.table_permissions[idx])
    .reduce((acc, cur) => {
      TABLE_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          table_name: cur.table,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.table_permissions ?? {}).reduce((acc, cur) => {
        const table_name = initialValues.table_permissions[cur]?.table;
        Object.keys(updated?.table_permissions[cur])
          .filter(permission => !updated?.table_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                table_name,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );
  const grantTablePermissions = Object.keys(added?.table_permissions ?? {})
    .map(idx => updateValues.table_permissions[idx])
    .reduce((acc, cur) => {
      TABLE_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          table_name: cur.table,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.table_permissions ?? {}).reduce((acc, cur) => {
        const table_name = initialValues.table_permissions[cur]?.table;
        Object.keys(updated?.table_permissions[cur])
          .filter(permission => updated?.table_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                table_name,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );

  return {
    grantTablePermissions,
    revokeTablePermissions,
  };
};

export const diffFilesPermissions = (initialValues, updateValues) => {
  const key = 'files_permissions';
  const { [key]: current } = initialValues;
  const { [key]: update } = updateValues;
  const currentObjects = current.reduce((acc, cur) => {
    const { folder, ...rest } = cur;
    acc[folder] = rest;
    return acc;
  }, {});
  const updateObjects = update.reduce((acc, cur) => {
    const { folder, ...rest } = cur;
    acc[folder] = rest;
    return acc;
  }, {});
  const objectDiff = detailedDiff(currentObjects, updateObjects);
  const deleted = Object.keys(objectDiff.deleted).reduce(
    (acc, cur) => {
      const idx = current.findIndex(obj => obj.folder === cur);
      acc[key][idx] = undefined;
      return acc;
    },
    { [key]: {} }
  );
  const added = Object.keys(objectDiff.added).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.folder === cur);
      acc[key][idx] = update[idx];
      return acc;
    },
    { [key]: {} }
  );
  const updated = Object.keys(objectDiff.updated).reduce(
    (acc, cur) => {
      const idx = update.findIndex(obj => obj.folder === cur);
      acc[key][idx] = objectDiff.updated[cur];
      return acc;
    },
    { [key]: {} }
  );

  const revokeFilesPermissions = Object.keys(deleted?.files_permissions ?? {})
    .map(idx => initialValues.files_permissions[idx])
    .reduce((acc, cur) => {
      FILES_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          folder: cur.folder,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.files_permissions ?? {}).reduce((acc, cur) => {
        const folder = initialValues.files_permissions[cur]?.folder;
        Object.keys(updated?.files_permissions[cur])
          .filter(permission => !updated?.files_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                folder,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );
  const grantFilesPermissions = Object.keys(added?.files_permissions ?? {})
    .map(idx => updateValues.files_permissions[idx])
    .reduce((acc, cur) => {
      FILES_PERMISSIONS.filter(permission => {
        return cur[permission.value];
      }).forEach(permission => {
        acc.push({
          folder: cur.folder,
          permission: permission.value,
        });
      });
      return acc;
    }, [])
    .concat(
      ...Object.keys(updated?.files_permissions ?? {}).reduce((acc, cur) => {
        const folder = initialValues.files_permissions[cur]?.folder;
        Object.keys(updated?.files_permissions[cur])
          .filter(permission => updated?.files_permissions[cur][permission])
          .forEach(permission => {
            acc.push([
              {
                folder,
                permission,
              },
            ]);
          });
        return acc;
      }, [])
    );

  return {
    grantFilesPermissions,
    revokeFilesPermissions,
  };
};

export const SYSTEM_USER_ADMIN_PERMISSIONS = ['manage_user'];

export const checkUserAdminRequired = form => {
  const permissions = form.getFieldValue('permissions') || [];
  const system_permissions = form.getFieldValue('system_permissions') || [];
  const hasUserRolePerm = permissions.some(permission => {
    return SYSTEM_USER_ADMIN_PERMISSIONS.includes(permission);
  });
  const hasSystemUserAdminPerm = system_permissions.some(system_permission => {
    return ['system_admin', 'system_user_admin'].includes(system_permission);
  });

  return hasUserRolePerm && !hasSystemUserAdminPerm;
};

export const waitFor = async (condFunc, maxWait, waitInterval) => {
  return new Promise(async resolve => {
    if (await condFunc()) {
      resolve(true);
    } else if (maxWait > 0) {
      setTimeout(async () => {
        const resp = await waitFor(
          condFunc,
          maxWait - waitInterval,
          waitInterval
        );
        resolve(resp);
      }, waitInterval);
    } else {
      resolve(false);
    }
  });
};

const WORKBOOK_REGEX = /\/\* Workbook: (.*) \*\//gi;
const WORKBOOK_DESC_REGEX = /\/\* Workbook Description: (.*) \*\//gi;
const WORKSHEET_REGEX = /\/\* Worksheet: (.*) \*\//gi;
const WORKSHEET_DESC_REGEX = /\/\* Worksheet Description: (.*) \*\//gi;
const BLOCK_TEXT_START = /\/\* TEXT Block Start \*\//gi;
const BLOCK_TEXT_END = /\/\* TEXT Block End \*\//gi;
const BLOCK_SQL_START = /\/\* SQL Block Start \*\//gi;
const BLOCK_SQL_END = /\/\* SQL Block End \*\//gi;

export const parseSqlData = data => {
  const wb = {};
  let currentSheetIdx = -1;
  let currentBlockContent = [];

  data
    .replace(/\r/gm, '')
    .split('\n')
    .filter(line => line !== '' && line !== '/*' && line !== '*/')
    .forEach((line, idx) => {
      const res1 = WORKBOOK_REGEX.exec(line);
      const res2 = WORKBOOK_DESC_REGEX.exec(line);
      const res3 = WORKSHEET_REGEX.exec(line);
      const res4 = WORKSHEET_DESC_REGEX.exec(line);
      const res5 = BLOCK_TEXT_START.exec(line);
      const res6 = BLOCK_TEXT_END.exec(line);
      const res7 = BLOCK_SQL_START.exec(line);
      const res8 = BLOCK_SQL_END.exec(line);

      if (idx === 0 && !res1) {
        throw new Error('Invalid SQL import format');
      }

      if (res1) {
        // NEW WORKBOOK
        const workbook = res1[1];
        wb['name'] = workbook;
        wb['description'] = '';
        wb['worksheets'] = [];
      } else if (res2) {
        // NEW WORKBOOK DESCRIPTION
        const description = res2[1];
        wb['description'] = description;
      } else if (res3) {
        // NEW WORKSHEET
        currentSheetIdx++;
        wb['worksheets'].push({
          name: res3[1],
          description: '',
          blocks: [],
        });
      } else if (res4) {
        // NEW WORKSHEET DESCRIPTION
        const sheet = wb['worksheets'][currentSheetIdx];
        wb['worksheets'][currentSheetIdx] = {
          ...sheet,
          description: res4[1],
        };
      } else if (res5) {
        // NEW TEXT BLOCK START
        currentBlockContent.length = 0;
        currentBlockContent = [];
      } else if (res6) {
        // NEW TEXT BLOCK END
        wb['worksheets'][currentSheetIdx]['blocks'] = [
          ...wb['worksheets'][currentSheetIdx]['blocks'],
          {
            type: 'text',
            content: [...currentBlockContent],
          },
        ];
        currentBlockContent.length = 0;
        currentBlockContent = [];
      } else if (res7) {
        // NEW SQL BLOCK START
        currentBlockContent.length = 0;
        currentBlockContent = [];
      } else if (res8) {
        // NEW SQL BLOCK END
        wb['worksheets'][currentSheetIdx]['blocks'] = [
          ...wb['worksheets'][currentSheetIdx]['blocks'],
          {
            type: 'sql',
            content: [...currentBlockContent],
          },
        ];
        currentBlockContent.length = 0;
        currentBlockContent = [];
      } else {
        currentBlockContent.push(line);
      }
    });

  return wb;
};
