// Imports
import React, { useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Radio,
  Row,
  Col,
  Button,
  InputNumber,
  Checkbox,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  DATASINK_LOCATION_HTTP,
  DATASINK_LOCATION_HTTPS,
  DATASINK_LOCATION_KAFKA,
  DATASINK_LOCATION_JDBC,
  DATASINK_KAFKA_AUTH_TYPES,
  DATASINK_KAFKA_AUTH_NO_AUTH,
  DATASINK_KAFKA_AUTH_SASL_SSL,
  DATASINK_JDBC_AUTH_TYPES,
  DATASINK_JDBC_AUTH_PASSWORD,
  DATASINK_HTTP_PREFIX,
  DATASINK_HTTPS_PREFIX,
  DATASINK_KAFKA_PREFIX,
  DATASINK_LOCATIONS,
} from '../../constants';
import { getTimestampKey, displaySuccess } from '../../helper';

const getLocation = (type, datasink) => {
  const { destination_type, additional_info } = datasink;
  const { destination } = additional_info;
  switch (type) {
    case DATASINK_LOCATION_HTTP:
      return destination_type === type
        ? destination
            .toLowerCase()
            .replaceAll(new RegExp(`${DATASINK_LOCATION_HTTP}://`, 'g'), '')
        : undefined;
    case DATASINK_LOCATION_HTTPS:
      return destination_type === type
        ? destination
            .toLowerCase()
            .replaceAll(new RegExp(`${DATASINK_LOCATION_HTTPS}://`, 'g'), '')
        : undefined;
    case DATASINK_LOCATION_KAFKA:
      return destination_type === type
        ? destination
            .toLowerCase()
            .replaceAll(new RegExp(`${DATASINK_LOCATION_KAFKA}://`, 'g'), '')
        : undefined;
    case DATASINK_LOCATION_JDBC:
      return destination_type.split(':')[0] === type ? destination : undefined;
    default:
      return undefined;
  }
};

const getAuthType = datasink => {
  const { destination_type, additional_info } = datasink;
  const { credential } = additional_info;
  if (destination_type === DATASINK_LOCATION_KAFKA) {
    if (credential) {
      return DATASINK_KAFKA_AUTH_SASL_SSL;
    }
    return DATASINK_KAFKA_AUTH_NO_AUTH;
  } else if (destination_type.split(':')[0] === DATASINK_LOCATION_JDBC) {
    return DATASINK_JDBC_AUTH_PASSWORD;
  }
  return undefined;
};

const getTimeout = value => {
  return value ? parseInt(value) : value;
};

const DatasinkEditModal = ({
  datasink,
  visible,
  close,
  width = 750,
  callback,
}) => {
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [updateDatasinkByName] =
    GraphQLServices.Datasinks.useUpdateDatasinkByName();

  const initialValues = {
    ...datasink.additional_info,
    name: datasink.datasink_name,
    location: datasink.destination_type.split(':')[0],
    location_http: getLocation(DATASINK_LOCATION_HTTP, datasink),
    location_https: getLocation(DATASINK_LOCATION_HTTPS, datasink),
    location_kafka: getLocation(DATASINK_LOCATION_KAFKA, datasink),
    location_jdbc: getLocation(DATASINK_LOCATION_JDBC, datasink),
    kafka_auth_type: getAuthType(datasink),
    jdbc_auth_type: getAuthType(datasink),
    validate: !(datasink.additional_info.skip_validation === 'true'),
    wait_timeout: getTimeout(datasink.additional_info.wait_timeout),
    connection_timeout: getTimeout(datasink.additional_info.connection_timeout),
    destination: undefined,
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  const [form] = Form.useForm();

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASINK_LOCATION_HTTPS)) {
      variables.location = DATASINK_HTTPS_PREFIX + variables.location_https;
    } else if (variables.location?.includes(DATASINK_LOCATION_HTTP)) {
      variables.location = DATASINK_HTTP_PREFIX + variables.location_http;
    } else if (variables.location?.includes(DATASINK_LOCATION_KAFKA)) {
      variables.location = DATASINK_KAFKA_PREFIX + variables.location_kafka;
    } else if (variables.location?.includes(DATASINK_LOCATION_JDBC)) {
      variables.location = variables.location_jdbc;
    }

    setIsUpdating(true);

    // Need to create credential first for kafka stream
    if (
      variables.location?.includes(DATASINK_LOCATION_KAFKA) &&
      variables?.kafka_auth_type === DATASINK_KAFKA_AUTH_SASL_SSL &&
      variables.user_name &&
      variables.password
    ) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASINK_LOCATION_KAFKA,
        identity: '',
        secret: '',
        security_protocol: DATASINK_KAFKA_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.user_name,
        sasl_password: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsUpdating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Need to create credential first for jdbc
    if (
      variables.location?.includes(DATASINK_LOCATION_JDBC) &&
      variables?.jdbc_auth_type === DATASINK_JDBC_AUTH_PASSWORD &&
      variables.user_name &&
      variables.password
    ) {
      const { user_name, password } = variables;
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASINK_LOCATION_JDBC,
        identity: user_name,
        secret: password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsUpdating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    updateDatasinkByName({
      variables: {
        ...variables,
        skip_validation: !variables.validate,
      },
    })
      .then(resp => {
        displaySuccess(`Data sink ${variables.name} updated.`);
        setIsUpdating(false);
        if (callback) {
          if (resp.errors) {
            callback(resp, null);
          } else {
            callback(null, resp);
          }
        }
      })
      .catch(err => {
        setIsUpdating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const update = _ => {
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues({
      ...allValues,
      jdbc_auth_type: allValues.jdbc_auth_type || DATASINK_JDBC_AUTH_PASSWORD,
      kafka_auth_type: allValues.kafka_auth_type || DATASINK_KAFKA_AUTH_NO_AUTH,
    });
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const formSingleLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  return (
    <Modal
      title="Configure Data Sink"
      open={visible}
      width={width}
      bodyStyle={{ minHeight: '400px' }}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        {...formLayout}
        form={form}
        name="datasink"
        layout="horizontal"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Name"
              name="name"
              wrapperCol={{ span: 11 }}
              rules={[
                {
                  required: true,
                  message: 'Please input data source name!',
                  whitespace: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: 'Please select data source location!',
                },
              ]}
            >
              <Radio.Group
                options={DATASINK_LOCATIONS}
                optionType="button"
                buttonStyle="solid"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        {formValues?.location?.includes(DATASINK_LOCATION_JDBC) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_jdbc"
              rules={[
                {
                  required: true,
                  message: 'Please input JDBC connection URL!',
                },
              ]}
            >
              <Input placeholder="<jdbc url connection string>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="jdbc_auth_type"
              initialValue={DATASINK_JDBC_AUTH_PASSWORD}
              hidden
            >
              <Radio.Group
                options={DATASINK_JDBC_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASINK_LOCATION_JDBC) &&
          formValues?.jdbc_auth_type === DATASINK_JDBC_AUTH_PASSWORD && (
            <>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Username" name="user_name">
                    <Input placeholder="<jdbc connection username>" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Password" name="password">
                    <Input
                      type="password"
                      placeholder="<jdbc connection password>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formSingleLayout}
                label="Driver Class"
                name="jdbc_driver_class_name"
              >
                <Input placeholder="<jdbc driver class name>" disabled />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Driver JAR Path"
                name="jdbc_driver_jar_path"
              >
                <Input placeholder="<path to jdbc driver jar>" disabled />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASINK_LOCATION_HTTP) &&
          !formValues?.location?.includes(DATASINK_LOCATION_HTTPS) && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="URL"
                name="location_http"
                rules={[
                  {
                    required: true,
                    message: 'Please input HTTP host:port/path!',
                  },
                ]}
              >
                <Input
                  addonBefore={DATASINK_HTTP_PREFIX}
                  placeholder="<host>:<port>/<path>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASINK_LOCATION_HTTPS) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_https"
              rules={[
                {
                  required: true,
                  message: 'Please input HTTPS host:port/path!',
                },
              ]}
            >
              <Input
                addonBefore={DATASINK_HTTPS_PREFIX}
                placeholder="<host>:<port>/<path>"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASINK_LOCATION_KAFKA) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_kafka"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Stream host:port/path!',
                },
              ]}
            >
              <Input
                addonBefore={DATASINK_KAFKA_PREFIX}
                placeholder="<host>:<port>/<path>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Topic Name"
              name="kafka_topic_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Topic Name!',
                },
              ]}
            >
              <Input placeholder="<kafka stream topic name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="kafka_auth_type"
              initialValue={DATASINK_KAFKA_AUTH_NO_AUTH}
            >
              <Radio.Group
                options={DATASINK_KAFKA_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASINK_LOCATION_KAFKA) &&
          formValues?.kafka_auth_type === DATASINK_KAFKA_AUTH_SASL_SSL && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input placeholder="**********" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Password" name="password">
                  <Input type="password" placeholder="**********" />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location && (
          <>
            <Row gutter={10}>
              <Col span={10}>
                <Form.Item
                  label="Wait Timeout"
                  name="wait_timeout"
                  labelCol={{ span: 10 }}
                  wrapperCol={{ span: 14 }}
                >
                  <InputNumber
                    placeholder="in seconds"
                    style={{ width: '100%' }}
                  />
                </Form.Item>
              </Col>
              <Col span={14}>
                {formValues?.location?.includes(DATASINK_LOCATION_KAFKA) && (
                  <Form.Item
                    label="Connection Timeout"
                    name="connection_timeout"
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 10 }}
                  >
                    <InputNumber
                      placeholder="in seconds"
                      style={{ width: '100%' }}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item
                  label="Validate"
                  name="validate"
                  valuePropName="checked"
                >
                  <Checkbox />
                </Form.Item>
              </Col>
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default DatasinkEditModal;
