// Imports
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Collapse, Descriptions, Form, Typography } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import { GET_IMPORT_JOB_DDL } from '../../graphql/schema/import_jobs';
import { buildImportSQLStatement } from './utils';
import {
  extractFields,
  extractProperties,
  extractColumnFormats,
} from '../createtable/utils';

const { Panel } = Collapse;

export default function SummaryStep({ form, columns, cdata_types = [] }) {
  const graphqlClient = useApolloClient();

  const [importJobDDL, setImportJobDDL] = useState(null);

  const ddl = useMemo(() => {
    return importJobDDL ? importJobDDL.ddl : null;
  }, [importJobDDL]);

  const handleSampleTableDdl = useCallback(() => {
    if (columns.length > 0) {
      form
        .validateFields()
        .then(async ({ schemaName, tableName }) => {
          let createOpts = null;
          if (columns.length > 0) {
            createOpts = {
              fields: extractFields(columns),
              properties: extractProperties(columns),
            };
          }

          const {
            data: { import_job_ddl },
          } = await graphqlClient.query({
            query: GET_IMPORT_JOB_DDL,
            variables: {
              schema: schemaName || '',
              table: tableName || '',
              createOptions: JSON.stringify(createOpts),
            },
          });
          setImportJobDDL(import_job_ddl);
        })
        .catch(
          err => err.errorFields.length && console.log('DDL Error', { err })
        );
    }
  }, [columns, form, graphqlClient]);

  const fields = form.getFieldsValue();

  useEffect(() => {
    if (columns.length > 0) {
      handleSampleTableDdl();
    }
  }, [fields, columns, handleSampleTableDdl]);

  return (
    <div>
      <Form form={form}>
        <Form.Item shouldUpdate={true}>
          {_ => {
            const values = form.getFieldsValue();
            const { type } = values;

            // Check if CDATA type
            const isCDATA = cdata_types.includes(type);
            const isJdbc = type === 'jdbc' || isCDATA;

            const columnFormats = extractColumnFormats(columns);

            const sql = buildImportSQLStatement(
              {
                ...values,
                columnFormats,
              },
              isCDATA
            );

            return (
              <div>
                <Descriptions
                  title="Source"
                  size="small"
                  column={1}
                  labelStyle={{ width: 250 }}
                  bordered
                >
                  <Descriptions.Item label="Data Source">
                    {values.dataSource || 'Kinetica Filesystem'}
                  </Descriptions.Item>
                  {!isJdbc &&
                    values.type !== 'jdbc' &&
                    values.type !== 'kafka' &&
                    values.type !== 'confluent' && (
                      <Descriptions.Item label="File Path">
                        {values.filePath || 'Unspecified'}
                      </Descriptions.Item>
                    )}
                  {!isJdbc && values.fileFormat && (
                    <Descriptions.Item label="File Format">
                      {values.fileFormat}
                    </Descriptions.Item>
                  )}
                  {values.remoteQuery ? (
                    <Descriptions.Item label="Remote Query">
                      {values.remoteQuery}
                    </Descriptions.Item>
                  ) : values.remoteTable ? (
                    <Descriptions.Item label="Remote Table">
                      {values.remoteTable}
                    </Descriptions.Item>
                  ) : null}
                  {(values.type === 'kafka' || values.type === 'confluent') &&
                    values.schemaRegistrySchemaID && (
                      <Descriptions.Item label="Schema Registry Schema ID">
                        {values.schemaRegistrySchemaID}
                      </Descriptions.Item>
                    )}
                  {(values.type === 'kafka' || values.type === 'confluent') &&
                    values.schemaRegistrySchemaName && (
                      <Descriptions.Item label="Schema Registry Schema Name">
                        {values.schemaRegistrySchemaName}
                      </Descriptions.Item>
                    )}
                  {(values.type === 'kafka' || values.type === 'confluent') &&
                    values.schemaRegistrySchemaVersion && (
                      <Descriptions.Item label="Schema Registry Schema Version">
                        {values.schemaRegistrySchemaVersion}
                      </Descriptions.Item>
                    )}
                  {values.fileFormat === 'TEXT' && (
                    <>
                      <Descriptions.Item label="Delimiter">
                        {values?.formatOptions?.DELIMITER || 'Use Default'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Escape Character">
                        {values?.formatOptions?.ESCAPE || 'Use Default'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Comment Identifier">
                        {values?.formatOptions?.COMMENT || 'Use Default'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Quote Character">
                        {values?.formatOptions?.QUOTE || 'Use Default'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Null Identifier">
                        {values?.formatOptions?.NULL || 'Use Default'}
                      </Descriptions.Item>
                      <Descriptions.Item label="Has Header">
                        {values?.formatOptions?.['INCLUDES HEADER']
                          ? 'Yes'
                          : 'No'}
                      </Descriptions.Item>
                    </>
                  )}
                </Descriptions>
                <br />
                <Descriptions
                  title="Destination"
                  size="small"
                  column={1}
                  labelStyle={{ width: 250 }}
                  bordered
                >
                  {values.schemaName && (
                    <Descriptions.Item label="Schema">
                      {values.schemaName}
                    </Descriptions.Item>
                  )}
                  <Descriptions.Item label="Table">
                    {values.tableName}
                  </Descriptions.Item>
                  {values.type !== 'kafka' && values.type !== 'confluent' && (
                    <Descriptions.Item label="Batch Size">
                      {values.batchSize}
                    </Descriptions.Item>
                  )}
                  {/* <Descriptions.Item label="Dimension Table">
                    {values.dimensionTable}
                  </Descriptions.Item> */}
                  {['GDB', 'SHAPEFILE'].includes(values.fileFormat) &&
                    values.layers &&
                    values.layers.trim() !== '' && (
                      <Descriptions.Item label="Layer(s)">
                        {values.layers}
                      </Descriptions.Item>
                    )}
                  {['JSON', 'SHAPEFILE'].includes(values.fileFormat) && (
                    <Descriptions.Item label="Store Points As X/Y">
                      {values.storePointsAsXY === true ? 'Yes' : 'No'}
                    </Descriptions.Item>
                  )}
                  {isJdbc && (
                    <Descriptions.Item label="Subscribe">
                      {values.subscribe === true ? 'Yes' : 'No'}
                    </Descriptions.Item>
                  )}
                  {isJdbc && values.subscribe && (
                    <Descriptions.Item label="Poll Interval">
                      {values.pollInterval}
                    </Descriptions.Item>
                  )}
                  {isJdbc && values.subscribe && (
                    <Descriptions.Item label="Increasing Column">
                      {values.increasingColumn}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                <br />
                <Descriptions
                  title="Error Handling"
                  size="small"
                  column={1}
                  labelStyle={{ width: 250 }}
                  bordered
                >
                  {!values.subscribe && (
                    <Descriptions.Item label="Abort On Error">
                      {values.abortOnError ? 'Yes' : 'No'}
                    </Descriptions.Item>
                  )}
                  {values.badRecordSchema && (
                    <Descriptions.Item label="Bad Records Schema">
                      {values.badRecordSchema}
                    </Descriptions.Item>
                  )}
                  {values.badRecordTable && (
                    <Descriptions.Item label="Bad Records Table">
                      {values.badRecordTable}
                    </Descriptions.Item>
                  )}
                </Descriptions>
                <br />
                {ddl ? (
                  <Collapse bordered={false} activeKey={['ddl']}>
                    <Panel key="ddl" header="DDL">
                      <Typography.Paragraph copyable={{ text: ddl }}>
                        <pre style={{ fontSize: 13, padding: 20 }}>{ddl}</pre>
                      </Typography.Paragraph>
                    </Panel>
                  </Collapse>
                ) : null}
                <Collapse bordered={false} activeKey={['sql']}>
                  <Panel key="sql" header="Generated SQL">
                    <Typography.Paragraph copyable={{ text: sql }}>
                      <pre style={{ fontSize: '13px', padding: '20px' }}>
                        {sql}
                      </pre>
                    </Typography.Paragraph>
                  </Panel>
                </Collapse>
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
}
