// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_CONTAINER_REGISTRIES = gql`
  query ContainerRegistries {
    container_registries {
      registry_name
      registry_uri
      credential_name
      credential {
        credential_name
        credential_type
        credential_identity
        credential
        additional_info
      }
    }
  }
`;

export const GET_CONTAINER_REGISTRY_BY_NAME = gql`
  query GetContainerRegistryByName($registry_name: String!) {
    container_registry(registry_name: $registry_name) {
      registry_name
      registry_uri
      credential_name
      credential {
        credential_name
        credential_type
        credential_identity
        credential
        additional_info
      }
    }
  }
`;

export const CREATE_CONTAINER_REGISTRY = gql`
  mutation CreateContainerRegistry(
    $registry_name: String!
    $registry_uri: String!
    $credential_name: String!
    $replace: Boolean!
  ) {
    containerRegistryCreate(
      registry_name: $registry_name
      registry_uri: $registry_uri
      credential_name: $credential_name
      replace: $replace
    ) {
      registry_name
      registry_uri
      credential_name
      credential {
        credential_name
        credential_type
        credential_identity
        credential
        additional_info
      }
    }
  }
`;

export const DELETE_CONTAINER_REGISTRY_BY_NAME = gql`
  mutation DeleteContainerRegistryByName($registry_name: String!) {
    containerRegistryDeleteByName(registry_name: $registry_name) {
      registry_name
    }
  }
`;

export const useGetContainerRegistries = () => {
  return useQuery(GET_CONTAINER_REGISTRIES);
};

export const useGetContainerRegistryByName = options => {
  return useQuery(GET_CONTAINER_REGISTRY_BY_NAME, options);
};

export const useCreateContainerRegistry = () => {
  return useMutation(CREATE_CONTAINER_REGISTRY);
};

export const useDeleteContainerRegistryByName = () => {
  return useMutation(DELETE_CONTAINER_REGISTRY_BY_NAME);
};
