// Import
import React, {
  useMemo,
  useState,
  useCallback,
  useEffect,
  useRef,
  useContext,
  useReducer,
} from 'react';
import {
  Button,
  Space,
  Tooltip,
  Dropdown,
  Divider,
  Popconfirm,
  Anchor,
  Spin,
  Tag,
} from 'antd';
import {
  CodeOutlined,
  FileTextOutlined,
  EditOutlined,
  DoubleRightOutlined,
  LeftOutlined,
  RightOutlined,
  GlobalOutlined,
  CopyOutlined,
  CloseOutlined,
  ClearOutlined,
  SyncOutlined,
  FileImageOutlined,
  ClockCircleOutlined,
  WarningOutlined,
  PlusOutlined,
  Html5Outlined,
  ClusterOutlined,
  PrinterOutlined,
  MergeCellsOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { createEditor, Transforms } from 'slate';
import { Slate, Editable, withReact, ReactEditor } from 'slate-react';
import { withHistory } from 'slate-history';
import { v4 as uuid } from 'uuid';
import store from 'store2';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import { EXECUTE_SQL } from '../../graphql/schema/sqlqueries';
import CodeBlock from './CodeBlock';
import TextBlock from './TextBlock';
import MapBlock from './MapBlock';
import GraphBlock from './GraphBlock';
import ImageBlock from './ImageBlock';
import HtmlBlock from './HtmlBlock';
import Spinner from '../../components/common/Spinner';
import WorksheetEditModal from '../../components/modal/WorksheetEditModal';
import BlockFullscreenModal from '../../components/modal/BlockFullscreenModal';
import {
  BLOCK_TYPES,
  PRINT_SAMPLE_URL,
  WORKSHEET_BLOCK_COUNT_LIMIT,
  TABLES_UPDATE_SQL_PATTERNS,
  FILES_UPDATE_SQL_PATTERNS,
} from '../../constants';
import { addOrUpdateBlockResult } from '../../store/worksheet/actions';
import ErrorBlock from './ErrorBlock';
import {
  useExportPerfReportWorkbook,
  validateProcessedBlocks,
  validateBlockContent,
  useIsReadOnly,
  useIsPrintOnly,
} from './utils';
import { displaySuccess, displayWarning, sleep } from '../../helper';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import SqlSuggestionContext from './SqlSuggestionContext';
import { UserContext, WorksheetContext } from '../../context';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';

const { Link } = Anchor;

const CHAT_ENABLED_KEY = 'chat_enabled';
const wbStore = store.namespace('workbench');

const TIME_TO_ACCUMULATE_CHANGES = 3000; // ms
const ADD_BLOCK_SLEEP = 250; // ms
const CHAT_WIDTH = 380;

const withCodeVoids = editor => {
  const { isVoid } = editor;
  editor.isVoid = element =>
    element.type === BLOCK_TYPES.SQL ||
    element.type === BLOCK_TYPES.TEXT ||
    element.type === BLOCK_TYPES.IMAGE ||
    element.type === BLOCK_TYPES.HTML ||
    element.type === BLOCK_TYPES.MAP ||
    element.type === BLOCK_TYPES.GRAPH
      ? true
      : isVoid(element);
  return editor;
};

const runIdMapInitialState = {};

function runIdMapReducer(state, action) {
  switch (action.type) {
    case 'update': {
      return {
        ...state,
        [action.id]: action.newRunId,
      };
    }
    default:
      return state;
  }
}

const WorksheetEditor = ({
  worksheetId,
  workbook,
  refetchWorkbook,
  apolloClient,
  moveWorksheetUp,
  moveWorksheetDown,
  copyToThisWorkbook,
  copyToAnotherWorkbook,
  // setAutoRefresh,
  embed = false,
}) => {
  const { id: workbookId } = workbook;

  const userMe = useContext(UserContext);

  const enableSQLAssistant = useMemo(
    _ => {
      if (
        userMe &&
        userMe.settings &&
        userMe.settings.some(setting => setting.key === 'enable_sql_assistant')
      ) {
        const setting = userMe.settings.find(
          setting => setting.key === 'enable_sql_assistant'
        );
        return setting.value === 'true';
      }
      return true;
    },
    [userMe]
  );

  // Flag for tracking when blocks from backend are loaded and initialize intital state of worksheet.
  const uninitialized = useRef(true);
  const worksheetBottomRef = useRef(null);
  const [runIdMap, runIdDispatch] = useReducer(
    runIdMapReducer,
    runIdMapInitialState
  );

  // Get results from store
  const results = useSelector(({ worksheet }) => worksheet.blockResults || {});

  const exportPerfReportWorkbook = useExportPerfReportWorkbook();
  const analytics = useAnalytics();
  const { emit: emitTables } = useEvent(EVENT_TYPES.TABLES_UPDATE);
  const { emit: emitFiles } = useEvent(EVENT_TYPES.FILES_UPDATE);
  const readOnly = useIsReadOnly();
  const printOnly = useIsPrintOnly();
  const dispatch = useDispatch();
  const { data: { worksheet = undefined } = {} } =
    GraphQLServices.Worksheets.useGetWorksheetOnlyById({
      variables: {
        id: worksheetId,
      },
    });
  const {
    data: { blocksByWorksheetId: blocks } = {},
    refetch: refetchBlocks,
    loading: loadingBlocks,
  } = GraphQLServices.Blocks.useGetBlocksByWorksheetId({
    variables: {
      worksheet_id: worksheetId,
    },
  });

  const [createBlock] = GraphQLServices.Blocks.useCreateBlock();
  const [createImageBlock] = GraphQLServices.Blocks.useCreateImageBlock();
  const [createHtmlBlock] = GraphQLServices.Blocks.useCreateHtmlBlock();
  const [updateBlock] = GraphQLServices.Blocks.useUpdateBlockById();
  const [removeUpdateBlock] = GraphQLServices.Blocks.useRemoveUpdateBlockById();
  const [repairWorksheet] = GraphQLServices.Worksheets.useRepairWorksheetById();

  const editor = useMemo(
    _ => withHistory(withCodeVoids(withReact(createEditor()))),
    []
  );
  const [value, setValue] = useState([]);
  const [showWorksheetEditModal, setShowWorksheetEditModal] = useState(false);
  const [clearingAll, setClearingAll] = useState(false);
  const [addingBlock, setAddingBlock] = useState(false);

  const [runAddedBlock, setRunAddedBlock] = useState(false);
  const [addedBlockID, setAddedBlockID] = useState(null);

  const [isChat, setIsChat] = useState(
    wbStore.get(CHAT_ENABLED_KEY) === 'true'
  );

  const { topBarCollapsed } = useSelector(state => state.app);

  useEffect(() => {
    wbStore.set(CHAT_ENABLED_KEY, isChat ? 'true' : 'false');
  }, [isChat]);

  const emitTablesRefreshIfRequired = useCallback(
    content => {
      const required = TABLES_UPDATE_SQL_PATTERNS.some(pattern => {
        return content.toLowerCase().includes(pattern);
      });
      if (required) {
        emitTables();
      }
    },
    [emitTables]
  );

  const emitFilesRefreshIfRequired = useCallback(
    content => {
      const required = FILES_UPDATE_SQL_PATTERNS.some(pattern => {
        return content.toLowerCase().includes(pattern);
      });
      if (required) {
        emitFiles();
      }
    },
    [emitFiles]
  );

  const clearAllResults = useCallback(
    async _ => {
      setClearingAll(true);
      let clearResultPromiseChain = Promise.resolve();
      for (let block of value.filter(b => b.type === BLOCK_TYPES.SQL)) {
        clearResultPromiseChain = clearResultPromiseChain.then(_ =>
          dispatch(
            addOrUpdateBlockResult(block.id, {
              queryError: null,
              queryResponse: null,
              queryRunning: false,
              queryID: null,
            })
          )
        );
      }

      return clearResultPromiseChain
        .then(_ => {
          setClearingAll(false);
        })
        .catch(_ => {
          setClearingAll(false);
        });
    },
    [value, dispatch]
  );

  const clearAllResultsFromBlock = useCallback(
    async id => {
      setClearingAll(true);
      let marker = false;
      let clearResultPromiseChain = Promise.resolve();
      for (let block of value.filter(b => b.type === BLOCK_TYPES.SQL)) {
        // Find start block by ID and then start
        // clearing results from there
        if (block.id === id) {
          marker = true;
        }
        if (marker) {
          clearResultPromiseChain = clearResultPromiseChain.then(_ =>
            dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: false,
                queryID: null,
              })
            )
          );
        }
      }

      return clearResultPromiseChain
        .then(_ => {
          setClearingAll(false);
        })
        .catch(_ => {
          setClearingAll(false);
        });
    },
    [value, dispatch]
  );

  const queryResults = useSelector(({ worksheet }) => worksheet.blockResults);
  const showPerfReport = useCallback(
    _ => {
      exportPerfReportWorkbook(workbookId, worksheetId, queryResults);
    },
    [workbookId, worksheetId, queryResults, exportPerfReportWorkbook]
  );

  const cancelTokenRef = useRef({});
  const [runningAll, setRunningAll] = useState(false);
  const [currentRunningId, setCurrentRunningId] = useState(null);

  const runAllBlocks = useCallback(
    _ => {
      analytics.track(analytics.EVENT_TYPES.RUN_ALL_BLOCKS)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      // Generate unique ID to track query job
      const query_id = uuid();

      setRunningAll(true);
      let runAllPromiseChain = clearAllResults();
      for (let block of value.filter(
        b =>
          b.type === BLOCK_TYPES.SQL ||
          b.type === BLOCK_TYPES.MAP ||
          b.type === BLOCK_TYPES.GRAPH
      )) {
        runAllPromiseChain = runAllPromiseChain.then(_ => {
          if (block.type === BLOCK_TYPES.SQL) {
            return dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: true,
                queryID: query_id,
              })
            )
              .then(_ => {
                setCurrentRunningId(block.id);
                const cancelPromise = new Promise(
                  resolve =>
                    (cancelTokenRef.current = _ => resolve('Cancelled'))
                );
                return Promise.race([
                  cancelPromise,
                  apolloClient.mutate({
                    mutation: EXECUTE_SQL,
                    variables: {
                      statement: block.content,
                      limit: EXECUTE_SQL_LIMIT,
                      query_id,
                    },
                  }),
                ]);
              })
              .then(async res => {
                if (
                  res?.data?.executeSql?.response?.total_number_of_records ===
                  -1
                ) {
                  await apolloClient.query({
                    query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
                  });
                }

                // Check if tables list requires refresh
                emitTablesRefreshIfRequired(block.content);

                // Check if files list requires refresh
                emitFilesRefreshIfRequired(block.content);

                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: res?.errors?.[0],
                    queryResponse: res?.data?.executeSql,
                    queryRunning: false,
                    queryID: query_id,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                if (res?.errors?.[0] || res === 'Cancelled') {
                  throw new Error(
                    res?.errors?.[0]?.message || 'Result Cancelled'
                  );
                }
              })
              .catch(err => {
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: err,
                    queryResponse: null,
                    queryRunning: false,
                    queryID: query_id,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                throw err;
              })
              .finally(_ => {
                setCurrentRunningId(null);
              });
          } else if (block.type === BLOCK_TYPES.MAP) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          } else if (block.type === BLOCK_TYPES.GRAPH) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          }
        });
      }
      runAllPromiseChain
        .then(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displaySuccess('Run All finished!');
        })
        .catch(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          displayWarning('Run All finished with one or more errors!');
        })
        .finally(_ => {
          setCurrentRunningId(null);
        });
    },
    [
      apolloClient,
      value,
      dispatch,
      clearAllResults,
      analytics,
      workbook,
      emitTablesRefreshIfRequired,
      emitFilesRefreshIfRequired,
    ]
  );

  const toggleSqlVisibility = useCallback(
    block => async _ => {
      const { id, config = {} } = block;
      const { isSqlVisible = true, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isSqlVisible: !isSqlVisible,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const toggleBlockCollapsed = useCallback(
    block => async _ => {
      const { id, config = {} } = block;
      const { isBlockCollapsed = false, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isBlockCollapsed: !isBlockCollapsed,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const toggleBlockVisibility = useCallback(
    block => async _ => {
      const { id, config = {} } = block;
      const { isBlockVisible = true, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isBlockVisible: !isBlockVisible,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const toggleSqlGpt = useCallback(
    block => async _ => {
      const { id, config = {} } = block;
      const { isSqlGptEnabled = false, ...rest } = config;
      uninitialized.current = true;
      await updateBlock({
        variables: {
          id,
          config: {
            ...rest,
            isSqlGptEnabled: !isSqlGptEnabled,
          },
        },
      });
      refetchBlocks();
    },
    [updateBlock, refetchBlocks]
  );

  const runAllFromBlock = useCallback(
    (id, customMessages, callback) => _ => {
      analytics.track(analytics.EVENT_TYPES.RUN_ALL_BLOCKS)(
        workbook.is_example ? { title: workbook.name } : {}
      );

      // Generate unique ID to track query job
      const query_id = uuid();

      setRunningAll(true);
      let runAllPromiseChain = clearAllResultsFromBlock(id);
      const idx = value.findIndex(b => b.id === id);
      const restOfBlocks = value.slice(idx);
      for (let block of restOfBlocks.filter(
        b =>
          b.type === BLOCK_TYPES.SQL ||
          b.type === BLOCK_TYPES.MAP ||
          b.type === BLOCK_TYPES.GRAPH
      )) {
        runAllPromiseChain = runAllPromiseChain.then(_ => {
          if (block.type === BLOCK_TYPES.SQL) {
            return dispatch(
              addOrUpdateBlockResult(block.id, {
                queryError: null,
                queryResponse: null,
                queryRunning: true,
                queryID: query_id,
              })
            )
              .then(_ => {
                setCurrentRunningId(block.id);
                const cancelPromise = new Promise(
                  resolve =>
                    (cancelTokenRef.current = _ => resolve('Cancelled'))
                );
                return Promise.race([
                  cancelPromise,
                  apolloClient.mutate({
                    mutation: EXECUTE_SQL,
                    variables: {
                      statement: block.content,
                      limit: EXECUTE_SQL_LIMIT,
                      query_id,
                    },
                  }),
                ]);
              })
              .then(async res => {
                if (
                  res?.data?.executeSql?.response?.total_number_of_records ===
                  -1
                ) {
                  await apolloClient.query({
                    query: GraphQLServices.DataObjects.GET_DATA_OBJECTS,
                  });
                }

                // Check if tables list requires refresh
                emitTablesRefreshIfRequired(block.content);

                // Check if files list requires refresh
                emitFilesRefreshIfRequired(block.content);

                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: res?.errors?.[0],
                    queryResponse: res?.data?.executeSql,
                    queryRunning: false,
                    queryID: query_id,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                if (res?.errors?.[0] || res === 'Cancelled') {
                  throw new Error(
                    res?.errors?.[0]?.message || 'Result Cancelled'
                  );
                }
              })
              .catch(err => {
                dispatch(
                  addOrUpdateBlockResult(block.id, {
                    queryError: err,
                    queryResponse: null,
                    queryRunning: false,
                    queryID: query_id,
                  })
                );

                // Re-throw error to stop promise chain from continuing.
                throw err;
              })
              .finally(_ => {
                setCurrentRunningId(null);
              });
          } else if (block.type === BLOCK_TYPES.MAP) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          } else if (block.type === BLOCK_TYPES.GRAPH) {
            return runIdDispatch({
              type: 'update',
              newRunId: uuid(),
              id: block.id,
            });
          }
        });
      }
      runAllPromiseChain
        .then(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          if (customMessages) {
            if (customMessages.success) {
              displaySuccess(customMessages.success);
            }
          } else {
            displaySuccess('Run All from here finished!');
          }
        })
        .catch(_ => {
          setRunningAll(false);
          cancelTokenRef.current = null;
          if (customMessages) {
            if (customMessages.error) {
              displayWarning(customMessages.error);
            }
          } else {
            displayWarning(
              'Run All from here finished with one or more errors!'
            );
          }
        })
        .finally(_ => {
          setCurrentRunningId(null);
          if (callback) {
            callback();
          }
        });
    },
    [
      apolloClient,
      value,
      dispatch,
      clearAllResultsFromBlock,
      analytics,
      workbook,
      emitTablesRefreshIfRequired,
      emitFilesRefreshIfRequired,
    ]
  );

  const cancelRunAll = useCallback(_ => {
    if (cancelTokenRef.current) {
      cancelTokenRef.current();
      cancelTokenRef.current = null;
    }
  }, []);

  useEffect(() => {
    if (runAddedBlock && addedBlockID) {
      setRunAddedBlock(false);
      runAllFromBlock(addedBlockID, { success: null, error: null }, _ => {
        setTimeout(() => {
          if (worksheetBottomRef.current) {
            worksheetBottomRef.current.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 100);
      })();
      setAddedBlockID(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  // This is for throttling updates for content so that every key press doesn't
  // trigger an update.
  const latestContentUpdateMapRef = useRef({});
  const updateContent = useCallback(
    (id, content) => {
      if (latestContentUpdateMapRef.current[id]) {
        // Update the reference with the latest content update.
        latestContentUpdateMapRef.current[id] = content;
      } else {
        latestContentUpdateMapRef.current[id] = content;
        // After enough time has passed, update the block with the latest content.
        setTimeout(
          async id => {
            await updateBlock({
              variables: {
                id,
                content: latestContentUpdateMapRef.current[id],
              },
            });
            // Clear latest content update so a new setTimeout will be called.
            latestContentUpdateMapRef.current[id] = null;
          },
          TIME_TO_ACCUMULATE_CHANGES,
          id
        );
      }
    },
    [updateBlock]
  );

  // const autoRefreshInterval = useMemo(
  //   _ => {
  //     const { autoRefreshInterval = 0 } = worksheet?.config ?? {};
  //     return autoRefreshInterval;
  //   },
  //   [worksheet]
  // );

  useEffect(
    _ => {
      const processedBlocks = [];
      if (blocks && uninitialized.current) {
        // Start with first block
        let curBlock = blocks.find(b => b.previous_block_id === null);

        // Keep track of visited blocks so there is no possibility of infinite loops.
        const visitedBlocks = [];

        // Visit blocks by following next_block_id and creating Slate objects.
        while (curBlock && !visitedBlocks.includes(curBlock.id)) {
          const type = curBlock.block_type.id;

          let content = '';
          try {
            if (curBlock.content) {
              content = JSON.parse(curBlock.content);
            }
          } catch (err) {
            console.error(curBlock.content);
            console.error(err);
          }

          const config = curBlock.config ?? {};
          const newBlock = {
            id: curBlock.id,
            type: validateBlockContent(type, content) ? type : null,
            content,
            config,
            children: [{ text: '' }],
          };

          // Include what is stored in database for block so it can be accessed by error block.
          if (newBlock.type === null) {
            newBlock.storedBlock = { ...curBlock };
          }

          processedBlocks.push(newBlock);
          visitedBlocks.push(curBlock.id);
          const nextBlockId = curBlock.next_block_id;
          curBlock = blocks.find(b => b.id === nextBlockId);
        }
        uninitialized.current = false;
        validateProcessedBlocks(blocks, processedBlocks);
        setValue(
          processedBlocks.map((processedBlock, idx) => ({
            ...processedBlock,
            seq: idx + 1,
          }))
        );
      }
    },
    [blocks]
  );

  const [blockFullscreen, setBlockFullscreen] = useState(undefined);
  const [showBlockFullscreenModal, setShowBlockFullscreenModal] =
    useState(false);
  const handleFullscreen = block => _ => {
    setBlockFullscreen(block);
    setShowBlockFullscreenModal(true);
  };
  const handleBlockEditCallback = newValue => {
    setValue(
      value.map(item => {
        if (item.id === newValue[0].id) {
          return newValue[0];
        }
        return item;
      })
    );
  };

  const addSQLBlock = useCallback(
    (sql = '', prevBlockId = null, run = false) => {
      setAddingBlock(true);
      if (prevBlockId) {
        const element = value.find(element => element.id === prevBlockId);
        const path = ReactEditor.findPath(editor, element);
        const afterPath = [path[0] + 1];
        Transforms.insertNodes(
          editor,
          {
            type: BLOCK_TYPES.SQL,
            content: sql,
            children: [{ text: '' }],
          },
          { at: afterPath }
        );
      } else {
        if (run) {
          setRunAddedBlock(true);
        }
        Transforms.insertNodes(
          editor,
          {
            type: BLOCK_TYPES.SQL,
            content: sql,
            children: [{ text: '' }],
          },
          { at: [value.length] }
        );
        setTimeout(_ => {
          if (worksheetBottomRef.current) {
            worksheetBottomRef.current.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    },
    [editor, value]
  );

  const renderPendingBlock = props => {
    return (
      <Spin indicator={<Spinner />} spinning={!props.element.id}>
        <div style={{ height: 70, backgroundColor: '#3700b311' }}></div>
      </Spin>
    );
  };

  const renderElement = useCallback(
    (props, fullscreen = false) => {
      switch (props.element.type) {
        case BLOCK_TYPES.SQL:
          return props.element.id ? (
            <CodeBlock
              blocks={value}
              runAllFromBlock={runAllFromBlock}
              toggleSqlVisibility={toggleSqlVisibility}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
              toggleSqlGpt={toggleSqlGpt}
              workbook={{
                name: workbook.name,
                config: workbook.config,
                is_example: workbook.is_example,
              }}
              worksheet={{
                name: worksheet ? worksheet.name : '',
                config: worksheet ? worksheet.config : {},
              }}
              {...props}
            />
          ) : (
            renderPendingBlock(props)
          );
        case BLOCK_TYPES.MAP:
          return props.element.id ? (
            <MapBlock
              blocks={value}
              runId={runIdMap[props.element.id]}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
              handleFullscreen={handleFullscreen(props.element)}
              fullscreen={fullscreen}
              {...props}
            />
          ) : (
            renderPendingBlock(props)
          );
        case BLOCK_TYPES.GRAPH:
          return props.element.id ? (
            <GraphBlock
              blocks={value}
              runId={runIdMap[props.element.id]}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
              {...props}
            />
          ) : (
            renderPendingBlock(props)
          );
        case BLOCK_TYPES.TEXT:
          return props.element.id ? (
            <TextBlock
              blocks={value}
              {...props}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
            />
          ) : (
            renderPendingBlock(props)
          );
        case BLOCK_TYPES.IMAGE:
          return props.element.id ? (
            <ImageBlock
              blocks={value}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
              {...props}
            />
          ) : (
            renderPendingBlock(props)
          );
        case BLOCK_TYPES.HTML:
          return props.element.id ? (
            <HtmlBlock
              blocks={value}
              toggleBlockCollapsed={toggleBlockCollapsed}
              toggleBlockVisibility={toggleBlockVisibility}
              workbook={{
                is_example: workbook.is_example,
              }}
              {...props}
            />
          ) : (
            renderPendingBlock(props)
          );
        default:
          return <ErrorBlock blocks={value} {...props} />;
      }
    },
    [
      value,
      runAllFromBlock,
      runIdMap,
      toggleSqlVisibility,
      toggleBlockCollapsed,
      toggleBlockVisibility,
      toggleSqlGpt,
      workbook,
      worksheet,
    ]
  );

  const renderElementFullscreen = props => renderElement(props, true);

  const handleSlateChange = useCallback(
    async newValue => {
      // Get list of blocks that need to be deleted
      const deleteBlocks = value
        .map((block, idx, blocks) => {
          const previous_block_id = idx > 0 ? blocks[idx - 1].id : null;
          const next_block_id =
            idx < blocks.length - 1 ? blocks[idx + 1].id : null;
          return {
            ...block,
            previous_block_id,
            next_block_id,
          };
        })
        .filter(prevBlock => {
          return (
            prevBlock.id &&
            !newValue.some(newBlock => newBlock.id === prevBlock.id)
          );
        });

      // Delete blocks
      for (const deleteBlock of deleteBlocks) {
        const { id } = deleteBlock;
        await removeUpdateBlock({
          variables: {
            id,
          },
        });
      }

      newValue.forEach((block, idx, blocks) => {
        const prevBlockId = idx > 0 ? blocks[idx - 1].id : null;
        const nextBlockId = idx < blocks.length - 1 ? blocks[idx + 1].id : null;
        const content = JSON.stringify(block.content);
        if (block.id) {
          // Find block in old state
          const oldBlockIndex = value.findIndex(prevBlock => {
            return prevBlock.id === block.id;
          });
          const oldBlock = oldBlockIndex > -1 ? value[oldBlockIndex] : null;
          const oldPrevBlockId =
            oldBlockIndex > 0 ? value[oldBlockIndex - 1].id : null;
          const oldNextBlockId =
            oldBlockIndex < value.length - 1
              ? value[oldBlockIndex + 1].id
              : null;
          const oldContent = oldBlock ? JSON.stringify(oldBlock.content) : null;
          let needsUpdate = false;
          const updatedBlock = {
            id: block.id,
          };

          if (prevBlockId !== undefined && oldPrevBlockId !== prevBlockId) {
            if (prevBlockId) {
              updatedBlock.previous_block_id = prevBlockId;
            } else {
              updatedBlock.previous_block_id = null;
            }
            needsUpdate = true;
          }

          if (nextBlockId !== undefined && oldNextBlockId !== nextBlockId) {
            if (nextBlockId) {
              updatedBlock.next_block_id = nextBlockId;
            } else {
              updatedBlock.next_block_id = null;
            }
            needsUpdate = true;
          }

          if (needsUpdate) {
            updateBlock({
              variables: updatedBlock,
            });
          }

          // Content updates are handled separately.
          if (oldBlock && content !== oldContent) {
            updateContent(block.id, content);
          }
        } else {
          if (block.type === BLOCK_TYPES.IMAGE) {
            createImageBlock({
              variables: {
                name: 'Block',
                description: 'Description for Block',
                content,
                config: {},
                previous_block_id: prevBlockId,
                next_block_id: nextBlockId,
                block_type_id: block.type,
                worksheet_id: worksheetId,
              },
            })
              .then(resp => {
                const createdId = resp.data.blockImageCreate.id;
                const path = ReactEditor.findPath(editor, block);
                Transforms.setNodes(editor, { id: createdId }, { at: path });
                setAddedBlockID(null);
              })
              .finally(async _ => {
                await sleep(ADD_BLOCK_SLEEP);
                setAddingBlock(false);
              });
          } else if (block.type === BLOCK_TYPES.HTML) {
            createHtmlBlock({
              variables: {
                name: 'Block',
                description: 'Description for Block',
                content,
                config: {},
                previous_block_id: prevBlockId,
                next_block_id: nextBlockId,
                block_type_id: block.type,
                worksheet_id: worksheetId,
              },
            })
              .then(resp => {
                const createdId = resp.data.blockHtmlCreate.id;
                const path = ReactEditor.findPath(editor, block);
                Transforms.setNodes(editor, { id: createdId }, { at: path });
                setAddedBlockID(null);
              })
              .finally(async _ => {
                await sleep(ADD_BLOCK_SLEEP);
                setAddingBlock(false);
              });
          } else {
            createBlock({
              variables: {
                name: 'Block',
                description: 'Description for Block',
                content,
                config: {},
                previous_block_id: prevBlockId,
                next_block_id: nextBlockId,
                block_type_id: block.type,
                worksheet_id: worksheetId,
              },
            })
              .then(resp => {
                const createdId = resp.data.blockCreate.id;
                const path = ReactEditor.findPath(editor, block);
                Transforms.setNodes(editor, { id: createdId }, { at: path });

                if (block.type === BLOCK_TYPES.SQL) {
                  setAddedBlockID(createdId);
                } else {
                  setAddedBlockID(null);
                }
              })
              .finally(async _ => {
                await sleep(ADD_BLOCK_SLEEP);
                setAddingBlock(false);
              });
          }
        }

        // Refetch when the last block is updated
        if (value.length !== newValue.length && idx === blocks.length - 1) {
          refetchBlocks();
        }
      });

      setValue(newValue);
    },
    [
      createBlock,
      createHtmlBlock,
      createImageBlock,
      editor,
      refetchBlocks,
      removeUpdateBlock,
      updateBlock,
      updateContent,
      value,
      worksheetId,
    ]
  );

  const handleMoveWorksheetUp = _ => {
    moveWorksheetUp(worksheetId);
  };

  const handleMoveWorksheetDown = _ => {
    moveWorksheetDown(worksheetId);
  };

  const handleEditWorksheet = _ => {
    setShowWorksheetEditModal(true);
  };

  const handleWorksheetEditCallback = (err, resp) => {
    if (resp) {
      refetchWorkbook();
      setShowWorksheetEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleCopyThisWorkbook = useCallback(
    _ => {
      copyToThisWorkbook(worksheet.id);
    },
    [worksheet, copyToThisWorkbook]
  );

  const handleCopyAnotherWorkbook = useCallback(
    _ => {
      copyToAnotherWorkbook(worksheet.id);
    },
    [worksheet, copyToAnotherWorkbook]
  );

  // const handleAutoRefresh = useCallback(
  //   time => _ => {
  //     setAutoRefresh(worksheetId, time);
  //   },
  //   [worksheetId, setAutoRefresh]
  // );

  const copyMenu = useMemo(
    _ => {
      return {
        items: [
          {
            key: 'this',
            label: 'To This Workbook',
            onClick: handleCopyThisWorkbook,
            disabled: readOnly,
          },
          {
            key: 'another',
            label: 'To Another Workbook',
            onClick: handleCopyAnotherWorkbook,
          },
        ],
      };
    },
    [handleCopyAnotherWorkbook, handleCopyThisWorkbook, readOnly]
  );

  // const refreshMenu = useMemo(
  //   _ => (
  //     <Menu selectedKeys={[`${worksheet?.config?.autoRefreshInterval ?? 0}`]}>
  //       <Menu.Item key={0} onClick={handleAutoRefresh(0)}>
  //         Off
  //       </Menu.Item>
  //       <Menu.Item key={5} onClick={handleAutoRefresh(5)}>
  //         5 s
  //       </Menu.Item>
  //       <Menu.Item key={10} onClick={handleAutoRefresh(10)}>
  //         10 s
  //       </Menu.Item>
  //       <Menu.Item key={15} onClick={handleAutoRefresh(15)}>
  //         15 s
  //       </Menu.Item>
  //       <Menu.Item key={30} onClick={handleAutoRefresh(30)}>
  //         30 s
  //       </Menu.Item>
  //       <Menu.Item key={60} onClick={handleAutoRefresh(60)}>
  //         60 s
  //       </Menu.Item>
  //     </Menu>
  //   ),
  //   [worksheet, handleAutoRefresh]
  // );

  const addTextBlock = useCallback(
    async _ => {
      setAddingBlock(true);
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.TEXT,
          content: [{ type: 'paragraph', children: [{ text: '' }] }],
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addImageBlock = useCallback(
    async _ => {
      setAddingBlock(true);
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.IMAGE,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addHtmlBlock = useCallback(
    async _ => {
      setAddingBlock(true);
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.HTML,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addMapBlock = useCallback(
    async _ => {
      setAddingBlock(true);
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.MAP,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const addGraphBlock = useCallback(
    async _ => {
      setAddingBlock(true);
      Transforms.insertNodes(
        editor,
        {
          type: BLOCK_TYPES.GRAPH,
          content: {},
          children: [{ text: '' }],
        },
        { at: [value.length] }
      );
      setTimeout(_ => {
        if (worksheetBottomRef.current) {
          worksheetBottomRef.current.scrollIntoView({
            behavior: 'smooth',
          });
        }
      }, 50);
    },
    [editor, value]
  );

  const canReportPerf = useMemo(
    _ => {
      return blocks
        ? blocks.some(block => {
            return queryResults[block.id]?.queryResponse?.responses.length > 0;
          })
        : false;
    },
    [queryResults, blocks]
  );

  const handlePrint = _ => {
    window.print();
  };

  const showPrintable = useCallback(
    _ => {
      const url = window.location.origin;
      const context = window.location.pathname.split('/')[1];
      const printUrl = PRINT_SAMPLE_URL(url, context, worksheet.id);
      window.open(printUrl);
    },
    [worksheet]
  );

  const confirmRepair = useCallback(
    async _ => {
      try {
        const { id } = worksheet;
        uninitialized.current = true;
        await repairWorksheet({
          variables: {
            id,
          },
        });
        refetchBlocks();
      } catch (err) {
        console.error(err);
      }
    },
    [refetchBlocks, repairWorksheet, worksheet]
  );

  const addMenu = useMemo(
    _ => {
      return {
        items: [
          {
            key: 'sql',
            label: 'SQL',
            icon: <CodeOutlined />,
            onClick: _ => addSQLBlock(''),
          },
          {
            key: 'text',
            label: 'Text',
            icon: <FileTextOutlined />,
            onClick: addTextBlock,
          },
          {
            key: 'image',
            label: 'Image',
            icon: <FileImageOutlined />,
            onClick: addImageBlock,
          },
          {
            key: 'html',
            label: 'HTML',
            icon: <Html5Outlined />,
            onClick: addHtmlBlock,
          },
          {
            key: 'map',
            label: 'Map',
            icon: <GlobalOutlined />,
            onClick: addMapBlock,
          },
          {
            key: 'graph',
            label: 'Graph',
            icon: <ClusterOutlined />,
            onClick: addGraphBlock,
          },
        ],
      };
    },
    [
      addSQLBlock,
      addTextBlock,
      addImageBlock,
      addHtmlBlock,
      addMapBlock,
      addGraphBlock,
    ]
  );

  const handleChatToggle = isChat => {
    setIsChat(isChat);
  };

  const blockLimitReached = useMemo(
    _ => {
      return value && value.length >= WORKSHEET_BLOCK_COUNT_LIMIT;
    },
    [value]
  );

  return printOnly ? (
    <>
      <div
        className="noprint"
        style={{
          marginBottom: '10px',
          marginRight: '10px',
          top: '0px',
          left: '0px',
          padding: '0px',
          zIndex: 2,
          textAlign: 'right',
        }}
      >
        {runningAll && (
          <Tag
            icon={<SyncOutlined spin />}
            style={{
              float: 'left',
              borderWidth: '0px',
              borderRadius: '4px',
              fontSize: '14px',
              backgroundColor: '#eb2f9611',
              color: '#eb2f96',
              padding: '2px 8px',
            }}
          >
            Running All
          </Tag>
        )}
        <Space>
          {(!embed || printOnly) && (
            <Tooltip title={embed ? null : 'Display printable worksheet'}>
              <Button
                onClick={embed ? handlePrint : showPrintable}
                icon={<PrinterOutlined />}
                size="small"
              />
            </Tooltip>
          )}
          <Divider type="vertical" />
          <Tooltip title="Clear All Query Results">
            <Button
              onClick={clearAllResults}
              icon={<ClearOutlined />}
              size="small"
              loading={clearingAll}
              disabled={runningAll}
            >
              Clear
            </Button>
          </Tooltip>
          {runningAll && (
            <Popconfirm
              title="Are you sure you want to cancel all remaining queries?"
              onConfirm={cancelRunAll}
              placement="topRight"
            >
              <Button
                icon={<CloseOutlined />}
                size="small"
                style={{ width: '105px' }}
                danger
              >
                Cancel All
              </Button>
            </Popconfirm>
          )}
          {!runningAll && (
            <Tooltip title="Run All Blocks">
              <Button
                type="primary"
                onClick={runAllBlocks}
                icon={<DoubleRightOutlined />}
                size="small"
                style={{ width: '105px' }}
                ghost
              >
                Run All
              </Button>
            </Tooltip>
          )}
        </Space>
      </div>
      <Spin indicator={<Spinner />} spinning={loadingBlocks}></Spin>
      <Slate editor={editor} value={value} onChange={handleSlateChange}>
        <Editable
          readOnly={true}
          renderElement={renderElement}
          style={{ pointerEvents: 'none' }}
        ></Editable>
        <div ref={worksheetBottomRef}></div>
      </Slate>
    </>
  ) : (
    <div className="worksheet_editor">
      <div
        style={{
          marginBottom: '10px',
          top: '0px',
          left: '0px',
          padding: '0px',
          zIndex: 2,
          textAlign: 'right',
        }}
      >
        {runningAll && (
          <Tag
            icon={<SyncOutlined spin />}
            style={{
              float: 'left',
              borderWidth: '0px',
              borderRadius: '4px',
              fontSize: '14px',
              backgroundColor: '#eb2f9611',
              color: '#eb2f96',
              padding: '2px 8px',
            }}
          >
            Running All
          </Tag>
        )}
        <Space>
          {!readOnly && (
            <>
              {!embed && !readOnly && (
                <Popconfirm
                  title="Are you sure you want to reset and repair your worksheet?"
                  onConfirm={confirmRepair}
                  placement="topRight"
                >
                  <Button
                    type="text"
                    icon={<MergeCellsOutlined />}
                    style={{ color: '#dddddd' }}
                    size="small"
                  />
                </Popconfirm>
              )}
              <Tooltip title="Move Sheet Up">
                <Button
                  icon={<LeftOutlined />}
                  onClick={handleMoveWorksheetUp}
                  disabled={worksheet && !worksheet.previous_worksheet_id}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Move Sheet Down">
                <Button
                  icon={<RightOutlined />}
                  onClick={handleMoveWorksheetDown}
                  disabled={worksheet && !worksheet.next_worksheet_id}
                  size="small"
                />
              </Tooltip>
              <Tooltip title="Edit Worksheet">
                <Button
                  icon={<EditOutlined />}
                  onClick={handleEditWorksheet}
                  size="small"
                />
              </Tooltip>
            </>
          )}
          {!embed && (
            <>
              <Tooltip title="Copy Worksheet">
                <Dropdown menu={copyMenu} trigger={['click']}>
                  <Button icon={<CopyOutlined />} size="small" />
                </Dropdown>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          {!readOnly && (
            <>
              <Tooltip
                title={
                  blockLimitReached
                    ? `Worksheet responsiveness may decrease with more than ${WORKSHEET_BLOCK_COUNT_LIMIT} blocks. Creating a new worksheet is recommended.`
                    : 'Add New Block'
                }
                overlayInnerStyle={{
                  color: '#333333',
                }}
                color={blockLimitReached ? '#ffe58f' : '#ffffff'}
              >
                <Dropdown
                  menu={addMenu}
                  trigger={['click']}
                  disabled={addingBlock}
                >
                  <Button
                    icon={<PlusOutlined />}
                    loading={addingBlock}
                    disabled={addingBlock}
                    size="small"
                  >
                    Add New Block
                  </Button>
                </Dropdown>
              </Tooltip>
              <Divider type="vertical" />
            </>
          )}
          {/* <Tooltip title="Auto-Refresh">
            <Dropdown overlay={refreshMenu} trigger={['click']}>
              <Button
                icon={<ClockCircleOutlined />}
                style={
                  (worksheet?.config?.autoRefreshInterval ?? 0) > 0
                    ? {
                        width: '50px',
                        color: '#eb2f96',
                        border: '1px solid #eb2f96',
                      }
                    : { width: '50px', textAlign: 'center' }
                }
                size="small"
              >
                {(worksheet?.config?.autoRefreshInterval ?? 0) > 0 ? (
                  <label style={{ fontSize: '12px', marginLeft: '5px' }}>
                    {worksheet?.config?.autoRefreshInterval}
                  </label>
                ) : (
                  ''
                )}
              </Button>
            </Dropdown>
          </Tooltip> */}
          <Tooltip
            title={
              canReportPerf
                ? 'Download Performance Report'
                : 'Run at least one query to Download Performance Report'
            }
          >
            <Button
              onClick={showPerfReport}
              icon={<ClockCircleOutlined />}
              size="small"
              disabled={!canReportPerf}
            />
          </Tooltip>
          {!embed && (
            <Tooltip title="Display printable worksheet">
              <Button
                onClick={showPrintable}
                icon={<PrinterOutlined />}
                size="small"
              />
            </Tooltip>
          )}
          <Divider type="vertical" />
          <Tooltip title="Clear All Query Results">
            <Button
              onClick={clearAllResults}
              icon={<ClearOutlined />}
              size="small"
              loading={clearingAll}
              disabled={runningAll}
            >
              Clear
            </Button>
          </Tooltip>
          {runningAll && (
            <Popconfirm
              title="Are you sure you want to cancel all remaining queries?"
              onConfirm={cancelRunAll}
              placement="topRight"
            >
              <Button
                icon={<CloseOutlined />}
                size="small"
                style={{ width: '105px' }}
                danger
              >
                Cancel All
              </Button>
            </Popconfirm>
          )}
          {!runningAll && (
            <Tooltip title="Run All Blocks">
              <Button
                type="primary"
                onClick={runAllBlocks}
                icon={<DoubleRightOutlined />}
                size="small"
                style={{ width: '105px' }}
                ghost
              >
                Run All
              </Button>
            </Tooltip>
          )}
        </Space>
      </div>
      {!embed && enableSQLAssistant && worksheet && !isChat && (
        <SqlSuggestionContext
          workbook={workbook}
          worksheet={worksheet}
          handleResult={addSQLBlock}
          isChat={isChat}
          toggleChat={handleChatToggle}
          disabled={readOnly && !workbook.is_example}
        />
      )}
      {!embed && enableSQLAssistant && worksheet && isChat && (
        <div style={{ width: CHAT_WIDTH, float: 'right' }}>
          <SqlSuggestionContext
            workbook={workbook}
            worksheet={worksheet}
            handleResult={addSQLBlock}
            isChat={isChat}
            toggleChat={handleChatToggle}
            disabled={readOnly && !workbook.is_example}
          />
        </div>
      )}
      <div
        id="worksheet-container"
        style={{
          width: isChat
            ? `calc(100% - ${CHAT_WIDTH + 10}px)`
            : 'calc(100% + 10px)',
          height: topBarCollapsed
            ? embed
              ? 'calc(100vh - 140px)'
              : enableSQLAssistant && !isChat
              ? 'calc(100vh - 340px)'
              : 'calc(100vh - 260px)'
            : embed
            ? 'calc(100vh - 190px)'
            : enableSQLAssistant && !isChat
            ? 'calc(100vh - 390px)'
            : 'calc(100vh - 310px)',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
      >
        {!embed && (
          <Anchor
            getContainer={() => document.querySelector('#worksheet-container')}
            bounds={20}
            targetOffset={5}
            style={{
              marginTop: '15px',
              width: '70px',
              marginRight: '0px',
              float: 'right',
              maxHeight: topBarCollapsed
                ? 'calc(100vh - 365px)'
                : 'calc(100vh - 415px)',
            }}
          >
            {value.map((block, idx) => {
              const result = results[block.id] ?? { queryError: null };
              const hasError =
                result.queryError !== undefined && result.queryError !== null;
              return (
                <Link
                  key={idx}
                  href={`#block-anchor-${idx + 1}`}
                  title={
                    <span
                      style={{
                        color:
                          block.id === currentRunningId ? '#eb2f96' : '#000000',
                        opacity: 0.85,
                        fontWeight:
                          block.id === currentRunningId ? 'bold' : 'normal',
                      }}
                    >
                      {hasError && (
                        <WarningOutlined
                          style={{
                            fontSize: '18px',
                            float: 'left',
                            position: 'absolute',
                            marginLeft: '20px',
                            marginTop: '2px',
                            color: '#ff0000',
                          }}
                        />
                      )}
                      {block.id === currentRunningId && (
                        <SyncOutlined
                          style={{
                            fontSize: '12px',
                            float: 'left',
                            position: 'absolute',
                            marginLeft: '22px',
                            marginTop: '6px',
                            color: '#eb2f96',
                          }}
                          spin
                        />
                      )}
                      {hasError ? (
                        <span style={{ color: '#ff0000', fontWeight: 'bold' }}>
                          {idx + 1}
                        </span>
                      ) : (
                        <>{idx + 1}</>
                      )}
                    </span>
                  }
                />
              );
            })}
          </Anchor>
        )}
        <div id="block-anchor-1" style={{ height: '5px' }}></div>
        <div
          style={{
            width: embed ? 'calc(100% - 10px)' : 'calc(100% - 75px)',
            float: 'left',
          }}
        >
          <WorksheetContext.Provider
            value={{
              addSQLBlock,
            }}
          >
            <Spin indicator={<Spinner />} spinning={loadingBlocks}></Spin>
            <Slate editor={editor} value={value} onChange={handleSlateChange}>
              <Editable
                readOnly={true}
                renderElement={renderElement}
                style={{ pointerEvents: 'none' }}
              ></Editable>
              <div ref={worksheetBottomRef}></div>
            </Slate>
          </WorksheetContext.Provider>
        </div>
      </div>
      {worksheet && (
        <WorksheetEditModal
          worksheet={worksheet}
          visible={showWorksheetEditModal}
          close={_ => {
            setShowWorksheetEditModal(false);
          }}
          callback={handleWorksheetEditCallback}
        />
      )}
      {blockFullscreen && (
        <BlockFullscreenModal
          renderElementFullscreen={renderElementFullscreen}
          block={blockFullscreen}
          updateContent={updateContent}
          visible={showBlockFullscreenModal}
          close={_ => {
            setBlockFullscreen(undefined);
            setShowBlockFullscreenModal(false);
          }}
          width={window.innerWidth - 100}
          height={window.innerHeight - 100}
          callback={handleBlockEditCallback}
        />
      )}
    </div>
  );
};

export default WorksheetEditor;
