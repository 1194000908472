// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_SQL_PROCEDURES = gql`
  query {
    sql_procedures {
      procedure_name
      procedure_definition
      additional_info {
        execute_interval
        execute_start_time
      }
    }
  }
`;

export const useGetSqlProcedures = () => {
  return useQuery(GET_SQL_PROCEDURES);
};
