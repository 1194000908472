// Imports
import React, { useMemo } from 'react';
import { Button, Dropdown, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

// App Imports
import { EXPLORER_PANEL_WIDTH } from '../../../constants';
import { FREE_SAAS } from '../../../setup/config';

const TypeObject = ({ nodeData, handleAdd }) => {
  const type = useMemo(
    _ => {
      return nodeData?.key;
    },
    [nodeData]
  );

  const addMenu = useMemo(
    _ => {
      const items = [];

      if (!FREE_SAAS) {
        items.push({
          key: 'schema',
          label: 'Add New Schema',
        });
      }

      items.push({
        key: 'table',
        label: 'Add New Table',
      });

      items.push({
        key: 'externaltable',
        label: 'Add New External Table',
      });

      return {
        items,
        onClick: handleAdd,
      };
    },
    [handleAdd]
  );

  const getAddButton = (key, disabled = false, tooltip = '') => {
    return (
      <Tooltip title={tooltip}>
        <Button
          icon={<PlusOutlined style={{ fontSize: '12px' }} />}
          onClick={_ => handleAdd({ key })}
          size="small"
          style={{
            float: 'right',
            height: '24px',
            width: '24px',
            verticalAlign: 'top',
          }}
          disabled={disabled}
        />
      </Tooltip>
    );
  };

  return (
    <div
      style={{
        fontWeight: 'bold',
        width: `${EXPLORER_PANEL_WIDTH - 55}px`,
        backgroundColor: '#f6f6f6',
        paddingLeft: 5,
      }}
    >
      {type === 'data.contexts' && getAddButton('context')}
      {type === 'data.func_environments' && getAddButton('func_environment')}
      {type === 'data.datasources' && getAddButton('datasource')}
      {type === 'data.datasinks' && getAddButton('datasink')}
      {type === 'data.graphs' && getAddButton('graph')}
      {type === 'data.streams' && getAddButton('stream')}
      {type === 'data.container_registries' &&
        getAddButton('container_registry')}
      {type === 'data.models' && getAddButton('model')}
      {type === 'data.tables' && (
        <span style={{ float: 'right' }}>
          <Dropdown menu={addMenu}>
            <Button
              icon={<PlusOutlined style={{ fontSize: '12px' }} />}
              size="small"
              style={{ float: 'right', height: '24px', width: '24px' }}
            />
          </Dropdown>
        </span>
      )}
      {nodeData.title}
    </div>
  );
};

export default TypeObject;
