// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_RELEASES = gql`
  query {
    releases {
      version
      operator
      tag
    }
  }
`;

export const useGetReleases = () => {
  return useQuery(GET_RELEASES);
};
