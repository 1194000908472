// Imports
import React from 'react';
import { Dropdown, notification, Tag, Tooltip } from 'antd';
import {
  DeleteOutlined,
  EyeOutlined,
  ApartmentOutlined,
  InfoCircleOutlined,
  CalculatorOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { GET_TABLE_BY_NAME } from '../../../graphql/schema/tables';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const GRAPH_TABLE_REGEX = /(?:graph_table|'graph_table')\s*=\s*'([^']+)'/;
const WKT_NAMES = ['kigeometry', 'wkt', 'geo', 'shape', 'isochrone', 'path'];

const GraphObject = ({
  nodeData,
  handleGraphInfo,
  handleGraphWms,
  handleGraphSolve,
  handleGraphDelete,
  handleMultiSelect,
}) => {
  const graphqlClient = useApolloClient();

  const handleGraphContextPreview = graph => async e => {
    const { is_partitioned, graph_server_id, original_request } = graph?.source;

    let graphTable = null;

    if (original_request?.statement) {
      const graphTableMatch = GRAPH_TABLE_REGEX.exec(
        original_request?.statement
      );
      graphTable =
        graphTableMatch && graphTableMatch.length > 1 && graphTableMatch[1];
    } else {
      graphTable = original_request?.options?.graph_table;
    }

    if (graphTable && graphTable !== '') {
      const split = graphTable.split('.');
      const serverIds = graph_server_id.split(',');
      const table =
        is_partitioned && serverIds.length > 0
          ? {
              full: `${graphTable}_${serverIds[0]}`,
              schema: split.length > 1 ? split[0] : '',
              name:
                split.length > 1
                  ? `${split[1]}_${serverIds[0]}`
                  : `${split[0]}_${serverIds[0]}`,
            }
          : {
              full: graphTable,
              schema: split.length > 1 ? split[0] : '',
              name: split.length > 1 ? split[1] : split[0],
            };

      const tableResp = await graphqlClient.query({
        query: GET_TABLE_BY_NAME,
        variables: {
          ...table,
        },
      });

      if (tableResp.errors) {
        notification.open({
          message: 'Graph Preview Error Occurred',
          description: tableResp.errors.map(e => e.message).join('\n'),
          type: 'error',
        });
        return;
      }

      const { schema, name, columns } = tableResp?.data?.table;

      const isPreviewAvailable = WKT_NAMES.reduce(function (acc, cur) {
        if (!acc) {
          acc = columns.find(function (column) {
            return column.name.toLowerCase().includes(cur.toLowerCase());
          });
        }
        return acc;
      }, null);

      if (!isPreviewAvailable) {
        notification.open({
          message: 'Graph Preview Error Occurred',
          description: 'Preview not available',
          type: 'error',
        });
        return;
      }

      handleGraphWms({
        full: `${schema}.${name}`,
        schema,
        name,
      });
    }
  };

  const handleGraphContextInfo = graph => e => {
    handleGraphInfo(graph.source);
  };

  const handleGraphContextDelete = graph => e => {
    if (graph.children && graph.children.length > 0) {
      handleGraphDelete(
        graph.source,
        graph.children.map(i => i.source.graph_server_id)
      );
    } else {
      handleGraphDelete(graph.source, [graph.source.graph_server_id]);
    }
  };

  const handleGraphContextSolve = graph => e => {
    handleGraphSolve(graph.source);
  };

  const graphContextMenu = (node, excludes = []) => {
    return {
      items: [
        {
          key: 'info',
          label: 'Info',
          icon: <InfoCircleOutlined />,
          onClick: handleGraphContextInfo(node),
        },
        {
          key: 'preview',
          label: 'Preview',
          icon: <EyeOutlined />,
          onClick: handleGraphContextPreview(node),
        },
        {
          key: 'solve',
          label: 'Solve',
          icon: <CalculatorOutlined />,
          onClick: handleGraphContextSolve(node),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: handleGraphContextDelete(node),
          style: { color: '#C62828' },
        },
      ].filter(
        i =>
          !i.key ||
          excludes.length === 0 ||
          (i.key && !excludes.includes(i.key))
      ),
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect =>
        nodeData.children && nodeData.children.length > 0 ? (
          <Dropdown
            menu={graphContextMenu(nodeData, ['info', 'preview'])}
            trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
          >
            <div>
              <ApartmentOutlined />{' '}
              <span
                style={{
                  width: `${
                    EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                  }px`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  lineHeight: '16px',
                  verticalAlign: 'text-top',
                }}
              >
                {nodeData.title}
                {nodeData.source.is_partitioned && (
                  <Tooltip title="Partitioned">
                    <Tag style={{ marginLeft: 5 }}>P</Tag>
                  </Tooltip>
                )}
              </span>
            </div>
          </Dropdown>
        ) : (
          <Dropdown
            menu={graphContextMenu(nodeData)}
            trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
          >
            <div>
              <ApartmentOutlined />{' '}
              <span
                style={{
                  width: `${
                    EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                  }px`,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'inline-block',
                  whiteSpace: 'nowrap',
                  lineHeight: '16px',
                  verticalAlign: 'text-top',
                }}
              >
                {nodeData.title}
                {nodeData.parent && (
                  <Tooltip
                    title={`Graph Server ID: ${nodeData.source.graph_server_id}`}
                  >
                    <Tag style={{ marginLeft: 5 }}>
                      {nodeData.source.graph_server_id}
                    </Tag>
                  </Tooltip>
                )}
              </span>
            </div>
          </Dropdown>
        )
      }
    </MultiSelectContext.Consumer>
  );
};

export default GraphObject;
