import { useMemo } from 'react';
import { Progress } from 'antd';

const formatBytes = (value, decimals = 2) => {
  const bytes = Number(value);
  if (isNaN(bytes)) {
    return value;
  }
  if (bytes === 0) return '0 Bytes';
  const k = 1000;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

const Usage = ({ usage = [] }) => {
  const shouldDisplay = useMemo(() => {
    return usage.some(rank => rank.limit !== 'Unlimited');
  }, [usage]);

  const NORMAL_GRADIENT = {
    from: '#108ee9',
    to: '#87d068',
  };

  const ALERT_GRADIENT = {
    from: '#ff7629',
    to: '#ee0f46',
  };

  return (
    shouldDisplay && (
      <div
        style={{
          padding: '4px 20px 4px 12px',
          borderBottom: '1px solid #dddddd',
          marginBottom: '0px',
          fontSize: '12px',
        }}
      >
        {usage
          .filter(rank => rank.limit !== 'Unlimited')
          .map((rank, idx) => {
            const percent = Math.ceil((rank.usage * 100) / rank.limit);
            return (
              <div
                key={idx}
                style={{
                  padding: '4px',
                }}
              >
                <span style={{ float: 'right' }}>
                  {formatBytes(rank.limit)}
                </span>
                <span>{formatBytes(rank.usage)} Data Used</span>
                <Progress
                  percent={percent}
                  status="normal"
                  strokeLinecap="butt"
                  trailColor="#eeeeee"
                  strokeWidth={10}
                  strokeColor={percent <= 85 ? NORMAL_GRADIENT : ALERT_GRADIENT}
                  style={{ fontSize: '12px' }}
                />
              </div>
            );
          })}
      </div>
    )
  );
};

export default Usage;
