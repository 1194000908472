// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_PROC_PERMISSION = gql`
  mutation GrantProcPermission(
    $name: String!
    $permission: String!
    $proc_name: String!
  ) {
    procPermissionGrant(
      name: $name
      permission: $permission
      proc_name: $proc_name
    ) {
      name
      permission
      proc_name
    }
  }
`;

export const REVOKE_PROC_PERMISSION = gql`
  mutation RevokeProcPermission(
    $name: String!
    $permission: String!
    $proc_name: String!
  ) {
    procPermissionRevoke(
      name: $name
      permission: $permission
      proc_name: $proc_name
    ) {
      name
      permission
      proc_name
    }
  }
`;

export const useGrantProcPermission = () => {
  return useMutation(GRANT_PROC_PERMISSION);
};

export const useRevokeProcPermission = () => {
  return useMutation(REVOKE_PROC_PERMISSION);
};
