// Imports
import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTERBACKUPS = gql`
  query K8sKineticaClusterBackups {
    k8s_kineticaclusterbackups {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICACLUSTERBACKUP = gql`
  mutation CreateK8sKineticaClusterBackup($name: String!, $body: JSON!) {
    kineticaClusterBackupCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const REMOVE_K8S_KINETICACLUSTERBACKUP_BY_NAME = gql`
  mutation RemoveK8sKineticaClusterBackup($name: String!) {
    kineticaClusterBackupRemoveByName(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusterBackups = options => {
  return useQuery(GET_K8S_KINETICACLUSTERBACKUPS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useCreateK8SKineticaClusterBackup = () => {
  return useMutation(CREATE_K8S_KINETICACLUSTERBACKUP);
};

export const useRemoveK8SKineticaClusterBackupByName = () => {
  return useMutation(REMOVE_K8S_KINETICACLUSTERBACKUP_BY_NAME);
};
