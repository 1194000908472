// Imports
import { gql, useQuery, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTERRESTORES = gql`
  query K8sKineticaClusterRestores {
    k8s_kineticaclusterrestores {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICACLUSTERRESTORE = gql`
  mutation CreateK8sKineticaClusterRestore($name: String!, $body: JSON!) {
    kineticaClusterRestoreCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusterRestores = options => {
  return useQuery(GET_K8S_KINETICACLUSTERRESTORES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useCreateK8SKineticaClusterRestore = () => {
  return useMutation(CREATE_K8S_KINETICACLUSTERRESTORE);
};
