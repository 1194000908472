// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_KINETICA_ROLES = gql`
  query KineticaRoles {
    kinetica_roles {
      name
      type
      permissions
      resource_group {
        name
        alias
        max_data
        max_tier_priority
        max_scheduling_priority
        max_cpu_concurrency
        tier_attributes {
          name
          max_memory
        }
        usage
      }
    }
  }
`;

export const useGetKineticaRoles = () => {
  return useQuery(GET_KINETICA_ROLES);
};
