// Imports
import React, { useState, useCallback } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Transfer,
  Table,
  Empty,
  Alert,
  Popover,
  Collapse,
  Badge,
} from 'antd';
import { useApolloClient } from '@apollo/client';
import { detailedDiff } from 'deep-object-diff';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  useRoleOptions,
  // useResourceGroupOptions,
  useSchemaOptions,
  useSchemaTableOptions,
  useSqlProcedureOptions,
  useDatasourceOptions,
  useDatasinkOptions,
  usePermissionOptions,
  useFolderFilesOptions,
  diffRoles,
  diffPermissions,
  diffSystemPermissions,
  diffProcPermissions,
  diffSqlProcPermissions,
  diffDatasourcePermissions,
  diffDatasinkPermissions,
  diffTablePermissions,
  diffFilesPermissions,
  checkUserAdminRequired,
  waitFor,
} from './helper';
import {
  SYSTEM_PERMISSIONS,
  PROC_PERMISSIONS,
  TABLE_PERMISSIONS,
  DATASOURCE_PERMISSIONS,
  DATASINK_PERMISSIONS,
  SYSTEM_ROLES,
  RESOURCE_GROUP_DEFAULT_NAME,
  FILES_PERMISSIONS,
  K8S_USER_CR_CHECK_MAXWAIT,
  K8S_USER_CR_CHECK_WAITINTERVAL,
} from '../../constants';
import { K8S_EXTERNAL_USERNAME_PREFIX } from '../../setup/config';
import { displaySuccess, displayError } from '../../helper';

const { Column } = Table;
const { Panel } = Collapse;

const UserEditModal = ({
  cluster,
  user,
  visible,
  close,
  callback,
  width,
  height,
}) => {
  const [addRoleByName] = GraphQLServices.Users.useAddRoleByName();
  const [removeRoleByName] = GraphQLServices.Users.useRemoveRoleByName();
  const [alterUserByName] = GraphQLServices.Users.useAlterUserByName();
  const [grantPermission] = GraphQLServices.Users.useGrantPermissionByName();
  const [revokePermission] = GraphQLServices.Users.useRevokePermissionByName();
  const [grantFilesPermission] =
    GraphQLServices.Files.useGrantFolderPermission();
  const [revokeFilesPermission] =
    GraphQLServices.Files.useRevokeFolderPermission();
  const [grantTablePermission] =
    GraphQLServices.TablePermissions.useGrantTablePermission();
  const [revokeTablePermission] =
    GraphQLServices.TablePermissions.useRevokeTablePermission();
  const [grantProcPermission] =
    GraphQLServices.ProcPermissions.useGrantProcPermission();
  const [revokeProcPermission] =
    GraphQLServices.ProcPermissions.useRevokeProcPermission();
  const [grantSqlProcPermission] =
    GraphQLServices.SqlProcPermissions.useGrantSqlProcPermission();
  const [revokeSqlProcPermission] =
    GraphQLServices.SqlProcPermissions.useRevokeSqlProcPermission();
  const [grantDatasourcePermission] =
    GraphQLServices.DatasourcePermissions.useGrantDatasourcePermission();
  const [revokeDatasourcePermission] =
    GraphQLServices.DatasourcePermissions.useRevokeDatasourcePermission();
  const [grantDatasinkPermission] =
    GraphQLServices.DatasinkPermissions.useGrantDatasinkPermission();
  const [revokeDatasinkPermission] =
    GraphQLServices.DatasinkPermissions.useRevokeDatasinkPermission();
  const [createK8SKineticaGrant] =
    GraphQLServices.K8sKineticaGrants.useCreateK8SKineticaGrant();
  const [removeK8SKineticaGrantByName] =
    GraphQLServices.K8sKineticaGrants.useRemoveK8SKineticaGrantByName();

  const grantK8sSystemPermission = useCallback(
    (name, permission, ringName) => {
      return createK8SKineticaGrant({
        variables: {
          name: `${name}_${permission}_system_permission`,
          body: {
            spec: {
              addGrantPermissionRequest: {
                name: `${K8S_EXTERNAL_USERNAME_PREFIX}${name}`,
                systemPermission: {
                  name: `${K8S_EXTERNAL_USERNAME_PREFIX}${name}`,
                  permission,
                },
              },
              ringName,
            },
          },
        },
      });
    },
    [createK8SKineticaGrant]
  );

  const revokeK8sSystemPermission = useCallback(
    (name, permission) => {
      return removeK8SKineticaGrantByName({
        variables: {
          name: `${name}_${permission}_system_permission`,
        },
      });
    },
    [removeK8SKineticaGrantByName]
  );

  const { data: { permissions = [] } = {} } =
    GraphQLServices.Permissions.useGetPermissions();
  const {
    data: {
      roles = [],
      // resource_groups = [],
      schemas = [],
      sql_procedures = [],
      datasources = [],
      datasinks = [],
      tables = [],
      folders = [],
    } = {},
  } = GraphQLServices.Helpers.useGetUserRoleOptions();

  const initialValues = {
    username: user?.username,
    first_name: user?.ldap_user?.cn,
    last_name: user?.ldap_user?.sn,
    roles: user?.kinetica_user?.roles
      .filter(role => !SYSTEM_ROLES.includes(role))
      .sort(),
    resource_group: user?.kinetica_user?.resource_group?.name,
    default_schema: user?.kinetica_user?.default_schema,
    permissions: user?.permissions.map(permission => permission.name),
    system_permissions: user?.kinetica_user?.system_permissions ?? [],
    proc_permissions: user?.kinetica_user?.proc_permissions.includes('')
      ? ['proc_execute']
      : [],
    sql_proc_permissions: user?.kinetica_user?.sql_proc_permissions,
    datasource_permissions: user?.kinetica_user?.datasource_permissions,
    datasink_permissions: user?.kinetica_user?.datasink_permissions,
    table_permissions: user?.kinetica_user?.table_permissions,
    files_permissions: user?.kinetica_user?.folder_permissions.map(
      ({ folder, ...rest }) => {
        return {
          ...rest,
          folder: folder === '' ? '<ROOT>' : folder,
        };
      }
    ),
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [targetPermissions, setTargetPermissions] = useState(
    initialValues.permissions ?? []
  );
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [targetSqlProcedures, setTargetSqlProcedures] = useState(
    initialValues.sql_proc_permissions ?? []
  );
  const [selectedSqlProcedures, setSelectedSqlProcedures] = useState([]);
  const [tablePermissions, setTablePermissions] = useState(
    initialValues.table_permissions ?? []
  );
  const [datasourcePermissions, setDatasourcePermissions] = useState(
    initialValues.datasource_permissions ?? []
  );
  const [datasinkPermissions, setDatasinkPermissions] = useState(
    initialValues.datasink_permissions ?? []
  );
  const [filesPermissions, setFilesPermissions] = useState(
    initialValues.files_permissions ?? []
  );

  const graphqlClient = useApolloClient();

  const [form] = Form.useForm();

  const roleOptions = useRoleOptions(roles);
  // const resourceGroupOptions = useResourceGroupOptions(resource_groups);
  const schemaOptions = useSchemaOptions(schemas);
  const schemaTableOptions = useSchemaTableOptions(
    schemas,
    tables,
    tablePermissions
  );
  const sqlProcedureOptions = useSqlProcedureOptions(sql_procedures);
  const datasourceOptions = useDatasourceOptions(datasources);
  const datasinkOptions = useDatasinkOptions(datasinks);
  const permissionOptions = usePermissionOptions(permissions);
  const folderFilesOptions = useFolderFilesOptions(folders);

  const onFieldsChange = async (changedFields, allFields) => {
    // Check if user/role workbench permission selected
    // that required system permission selected
    if (
      changedFields.some(field => {
        return ['permissions', 'system_permissions'].some(f =>
          field.name.includes(f)
        );
      })
    ) {
      const userAdminRequired = checkUserAdminRequired(form);
      form.setFields([
        {
          name: 'system_permissions',
          errors: userAdminRequired
            ? [
                'Admin or User Admin system permission required for user workbench permissions',
              ]
            : [],
        },
      ]);
    }
  };

  const onFinish = async values => {
    const { username } = values;
    setIsUpdating(true);

    const {
      table_permission_table,
      datasource_permission_datasource,
      datasink_permission_datasink,
      files_permission_folder,
      ...rest
    } = values;
    const updateValues = {
      ...rest,
      table_permissions: tablePermissions,
      datasource_permissions: datasourcePermissions,
      datasink_permissions: datasinkPermissions,
      files_permissions: filesPermissions,
    };

    const { updated } = detailedDiff(initialValues, updateValues);

    const { addRoles, removeRoles } = diffRoles(initialValues, updateValues);
    const { grantPermissions, revokePermissions } = diffPermissions(
      initialValues,
      updateValues
    );
    const { grantSystemPermissions, revokeSystemPermissions } =
      diffSystemPermissions(initialValues, updateValues);
    const { grantProcPermissions, revokeProcPermissions } = diffProcPermissions(
      initialValues,
      updateValues
    );
    const { grantSqlProcPermissions, revokeSqlProcPermissions } =
      diffSqlProcPermissions(initialValues, updateValues);
    const { grantDatasourcePermissions, revokeDatasourcePermissions } =
      diffDatasourcePermissions(initialValues, updateValues);
    const { grantDatasinkPermissions, revokeDatasinkPermissions } =
      diffDatasinkPermissions(initialValues, updateValues);
    const { grantTablePermissions, revokeTablePermissions } =
      diffTablePermissions(initialValues, updateValues);
    const { grantFilesPermissions, revokeFilesPermissions } =
      diffFilesPermissions(initialValues, updateValues);

    // Wait until user has been created
    const result = await waitFor(
      async _ => {
        const resp = await graphqlClient.query({
          query: GraphQLServices.K8sKineticaUsers.GET_K8S_KINETICAUSER,
          variables: {
            username,
          },
        });
        const status = resp?.data?.k8s_kineticauser?.status ?? {};
        const { db_response, ldap_response } = status;
        console.table({ username, db_response, ldap_response });
        return status && db_response?.status === 'OK' && ldap_response === 'OK';
      },
      K8S_USER_CR_CHECK_MAXWAIT,
      K8S_USER_CR_CHECK_WAITINTERVAL
    );

    if (!result) {
      const message = `User ${username} provision check failed!`;
      displayError(message);
      if (callback) {
        callback(new Error(message), null);
      }

      setIsUpdating(false);
      return;
    }

    await Promise.all(
      removeRoles.map(role_name => {
        return removeRoleByName({
          variables: {
            username,
            role_name,
          },
        });
      })
    );

    await Promise.all(
      addRoles.map(role_name => {
        return addRoleByName({
          variables: {
            username,
            role_name,
            ring: cluster?.metadata?.name,
          },
        });
      })
    );

    if (updated?.resource_group) {
      const { resource_group } = updated;
      await alterUserByName({
        variables: {
          name: username,
          action: 'set_resource_group',
          value:
            resource_group === RESOURCE_GROUP_DEFAULT_NAME
              ? ''
              : resource_group,
        },
      });
    }

    if (updated?.default_schema) {
      const { default_schema } = updated;
      await alterUserByName({
        variables: {
          name: username,
          action: 'set_default_schema',
          value: default_schema ?? '',
        },
      });
    }

    await Promise.all(
      revokePermissions.map(permission_name => {
        return revokePermission({
          variables: {
            username,
            permission_name,
          },
        });
      })
    );

    await Promise.all(
      grantPermissions.map(permission_name => {
        return grantPermission({
          variables: {
            username,
            permission_name,
          },
        });
      })
    );

    await Promise.all(
      revokeSystemPermissions.map(permission => {
        return revokeK8sSystemPermission(username, permission);
      })
    );

    await Promise.all(
      grantSystemPermissions.map(permission => {
        return grantK8sSystemPermission(
          username,
          permission,
          cluster?.metadata?.name
        );
      })
    );

    await Promise.all(
      revokeProcPermissions.map(permission => {
        return revokeProcPermission({
          variables: {
            name: username,
            permission,
            proc_name: '',
          },
        });
      })
    );

    await Promise.all(
      grantProcPermissions.map(permission => {
        return grantProcPermission({
          variables: {
            name: username,
            permission,
            proc_name: '',
          },
        });
      })
    );

    await Promise.all(
      revokeSqlProcPermissions.map(sql_proc_name => {
        return revokeSqlProcPermission({
          variables: {
            name: username,
            sql_proc_name,
          },
        });
      })
    );

    await Promise.all(
      grantSqlProcPermissions.map(sql_proc_name => {
        return grantSqlProcPermission({
          variables: {
            name: username,
            sql_proc_name,
          },
        });
      })
    );

    await Promise.all(
      revokeDatasourcePermissions.map(datasource_permission => {
        const { datasource_name, permission } = datasource_permission;
        return revokeDatasourcePermission({
          variables: {
            name: username,
            permission: permission.replace(/datasource_/g, ''),
            datasource_name,
          },
        });
      })
    );

    await Promise.all(
      grantDatasourcePermissions.map(datasource_permission => {
        const { datasource_name, permission } = datasource_permission;
        return grantDatasourcePermission({
          variables: {
            name: username,
            permission: permission.replace(/datasource_/g, ''),
            datasource_name,
          },
        });
      })
    );

    await Promise.all(
      revokeDatasinkPermissions.map(datasink_permission => {
        const { datasink_name, permission } = datasink_permission;
        return revokeDatasinkPermission({
          variables: {
            name: username,
            permission: permission.replace(/datasink_/g, ''),
            datasink_name,
          },
        });
      })
    );

    await Promise.all(
      grantDatasinkPermissions.map(datasink_permission => {
        const { datasink_name, permission } = datasink_permission;
        return grantDatasinkPermission({
          variables: {
            name: username,
            permission: permission.replace(/datasink_/g, ''),
            datasink_name,
          },
        });
      })
    );

    await Promise.all(
      revokeTablePermissions.map(table_permission => {
        const { table_name, permission } = table_permission;
        return revokeTablePermission({
          variables: {
            name: username,
            permission,
            table_name,
          },
        });
      })
    );

    await Promise.all(
      grantTablePermissions.map(table_permission => {
        const { table_name, permission } = table_permission;
        return grantTablePermission({
          variables: {
            name: username,
            permission,
            table_name,
            filter_expression: '',
          },
        });
      })
    );

    await Promise.all(
      revokeFilesPermissions.map(file_permission => {
        const { folder: directory_name, permission } = file_permission;
        return revokeFilesPermission({
          variables: {
            name: username,
            permission,
            directory_name: directory_name !== '<ROOT>' ? directory_name : '',
          },
        });
      })
    );

    await Promise.all(
      grantFilesPermissions.map(file_permission => {
        const { folder: directory_name, permission } = file_permission;
        return grantFilesPermission({
          variables: {
            name: username,
            permission,
            directory_name: directory_name !== '<ROOT>' ? directory_name : '',
          },
        });
      })
    );

    setIsUpdating(false);
    displaySuccess(`User ${username} updated successfully!`);

    if (callback) {
      callback(null, updateValues);
    }
  };

  const update = _ => {
    let hasError = false;

    // Check if user/role workbench permission selected
    // that required system permission selected
    const userAdminRequired = checkUserAdminRequired(form);
    if (userAdminRequired) {
      form.setFields([
        {
          name: 'system_permissions',
          errors: [
            'Admin or User Admin system permission required for user workbench permissions',
          ],
        },
      ]);
      hasError = true;
    }

    if (!hasError) {
      form.submit();
    }
  };

  const handlePermissionChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetPermissions(nextTargetKeys);
  };

  const handlePermissionSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedPermissions([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleSqlProcedureChange = (nextTargetKeys, direction, moveKeys) => {
    setTargetSqlProcedures(nextTargetKeys);
  };

  const handleSqlProcedureSelectChange = (
    sourceSelectedKeys,
    targetSelectedKeys
  ) => {
    setSelectedSqlProcedures([...sourceSelectedKeys, ...targetSelectedKeys]);
  };

  const handleAddDatasourcePermission = _ => {
    const datasource = form.getFieldValue('datasource_permission_datasource');
    if (datasource) {
      setDatasourcePermissions([
        ...datasourcePermissions,
        {
          datasource,
          ...DATASOURCE_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ datasource_permission_datasource: '' });
    }
  };

  const handleDeleteDatasourcePermission =
    ({ datasource }) =>
    _ => {
      setDatasourcePermissions([
        ...datasourcePermissions.filter(perm => perm.datasource !== datasource),
      ]);
    };

  const handleDatasourcePermissionChange =
    ({ datasource }, permType) =>
    e => {
      setDatasourcePermissions(
        datasourcePermissions.map(perm => {
          if (perm.datasource === datasource) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddDatasinkPermission = _ => {
    const datasink = form.getFieldValue('datasink_permission_datasink');
    if (datasink) {
      setDatasinkPermissions([
        ...datasinkPermissions,
        {
          datasink,
          ...DATASINK_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ datasink_permission_datasink: '' });
    }
  };

  const handleDeleteDatasinkPermission =
    ({ datasink }) =>
    _ => {
      setDatasinkPermissions([
        ...datasinkPermissions.filter(perm => perm.datasink !== datasink),
      ]);
    };

  const handleDatasinkPermissionChange =
    ({ datasink }, permType) =>
    e => {
      setDatasinkPermissions(
        datasinkPermissions.map(perm => {
          if (perm.datasink === datasink) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddTablePermission = _ => {
    const table = form.getFieldValue('table_permission_table');
    if (table) {
      setTablePermissions([
        ...tablePermissions,
        {
          table,
          ...TABLE_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ table_permission_table: '' });
    }
  };

  const handleDeleteTablePermission =
    ({ table }) =>
    _ => {
      setTablePermissions([
        ...tablePermissions.filter(perm => perm.table !== table),
      ]);
    };

  const handleTablePermissionChange =
    ({ table }, permType) =>
    e => {
      setTablePermissions(
        tablePermissions.map(perm => {
          if (perm.table === table) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const handleAddFilesPermission = _ => {
    const folder = form.getFieldValue('files_permission_folder');
    if (folder) {
      setFilesPermissions([
        ...filesPermissions,
        {
          folder,
          ...FILES_PERMISSIONS.reduce((acc, cur) => {
            acc[cur.value] = false;
            return acc;
          }, {}),
        },
      ]);
      form.setFieldsValue({ files_permission_folder: '' });
    }
  };

  const handleDeleteFilesPermission =
    ({ folder }) =>
    _ => {
      setFilesPermissions([
        ...filesPermissions.filter(perm => perm.folder !== folder),
      ]);
    };

  const handleFilesPermissionChange =
    ({ folder }, permType) =>
    e => {
      setFilesPermissions(
        filesPermissions.map(perm => {
          if (perm.folder === folder) {
            return {
              ...perm,
              [permType]: e.target.checked,
            };
          }
          return perm;
        })
      );
    };

  const formLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 17 },
  };

  const formLayoutSingle = {
    labelCol: { span: 0 },
    wrapperCol: { span: 24 },
  };

  const panelStyle = {
    border: '1px solid #eeeeee',
    backgroundColor: '#f9f9f9',
    marginBottom: '10px',
    overflow: 'hidden',
  };

  const panelActiveStyle = {
    border: '1px solid #dddddd',
    backgroundColor: '#eeeeee',
    marginBottom: '10px',
    overflow: 'hidden',
  };

  const badgeStyle = { backgroundColor: '#108ee9' };

  return (
    <Modal
      title="Edit User"
      open={visible}
      width={width}
      height={height}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="update"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      maskClosable={false}
      destroyOnClose
      centered
    >
      <div
        style={{
          height: height - 180,
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        <Form
          {...formLayout}
          form={form}
          name="user"
          layout="horizontal"
          initialValues={initialValues}
          onFieldsChange={onFieldsChange}
          onFinish={onFinish}
          colon={false}
          preserve={false}
        >
          <Row gutter={0}>
            <Col span={11}>
              <Form.Item
                label="Username"
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input user username!',
                    whitespace: true,
                  },
                ]}
              >
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}></Col>
          </Row>
          <Row gutter={0}>
            <Col span={11}>
              <Form.Item
                label="Roles"
                name="roles"
                extra={
                  <Alert
                    message="Role(s) can only be set at creation."
                    type="warning"
                    style={{ fontSize: '12px', border: '0px' }}
                  />
                }
              >
                <Select
                  mode="multiple"
                  allowClear
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select role(s)"
                  disabled
                >
                  {roleOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Default Schema"
                name="default_schema"
                labelCol={{ span: 7 }}
                wrapperCol={{ span: 17 }}
                rules={[
                  {
                    required: true,
                    message: 'Please select a default schema!',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select default schema"
                >
                  {schemaOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          {/* <Row gutter={0}>
            <Col span={14}>
              <Form.Item
                label="Resource Group"
                name="resource_group"
                rules={[
                  {
                    required: true,
                    message: 'Please select a resource group!',
                  },
                ]}
              >
                <Select
                  showSearch
                  style={{ width: '100%' }}
                  placeholder="Select resource group"
                >
                  {resourceGroupOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={9}>
              
            </Col>
          </Row> */}
          <Row gutter={0}>
            <Col span={20} offset={3}>
              <h3 style={{ marginLeft: '-5px' }}>Permissions</h3>
            </Col>
          </Row>
          <Row gutter={0}>
            <Col span={12}>
              <Form.Item
                label="System"
                name="system_permissions"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                extra={
                  <Alert
                    message="System Permission(s) can only be set at creation."
                    type="warning"
                    style={{ fontSize: '12px', border: '0px' }}
                  />
                }
              >
                <Checkbox.Group options={SYSTEM_PERMISSIONS} disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Proc"
                name="proc_permissions"
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
              >
                <Checkbox.Group options={PROC_PERMISSIONS} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={2}></Col>
            <Col span={21}>
              <div style={{ marginLeft: '30px' }}>
                <Collapse
                  style={{ border: '0px', backgroundColor: 'transparent' }}
                >
                  <Panel
                    header="Workbench"
                    key="workbench"
                    style={
                      targetPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={targetPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Form.Item name="permissions" {...formLayoutSingle}>
                      <Transfer
                        dataSource={permissionOptions}
                        titles={['Available', 'Selected']}
                        targetKeys={targetPermissions}
                        selectedKeys={selectedPermissions}
                        onChange={handlePermissionChange}
                        onSelectChange={handlePermissionSelectChange}
                        render={item => item.title}
                        listStyle={{ width: '100%' }}
                        locale={{
                          notFoundContent: (
                            <Empty description="No Permissions" />
                          ),
                        }}
                      />
                    </Form.Item>
                  </Panel>
                  <Panel
                    header="Table/Schema"
                    key="table"
                    style={
                      tablePermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={tablePermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="table_permission_table"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select table/schema"
                          >
                            {schemaTableOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddTablePermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={tablePermissions}
                      rowKey="table"
                      size="small"
                    >
                      <Column
                        key="table"
                        title="Table/Schema"
                        dataIndex="table"
                      ></Column>
                      {TABLE_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  checked={record[perm.value]}
                                  onChange={handleTablePermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteTablePermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Data Source"
                    key="datasource"
                    style={
                      datasourcePermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={datasourcePermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="datasource_permission_datasource"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select data source"
                          >
                            {datasourceOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddDatasourcePermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={datasourcePermissions}
                      rowKey="datasource"
                      size="small"
                    >
                      <Column
                        key="datasource"
                        title="Data Source"
                        dataIndex="datasource"
                      ></Column>
                      {DATASOURCE_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  checked={record[perm.value]}
                                  onChange={handleDatasourcePermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteDatasourcePermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Data Sink"
                    key="datasink"
                    style={
                      datasinkPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={datasinkPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="datasink_permission_datasink"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select data sink"
                          >
                            {datasinkOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddDatasinkPermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={datasinkPermissions}
                      rowKey="datasink"
                      size="small"
                    >
                      <Column
                        key="datasink"
                        title="Data Sink"
                        dataIndex="datasink"
                      ></Column>
                      {DATASINK_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={70}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  checked={record[perm.value]}
                                  onChange={handleDatasinkPermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteDatasinkPermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="Folder"
                    key="folder"
                    style={
                      filesPermissions.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={filesPermissions.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Row gutter={10}>
                      <Col span={20}>
                        <Form.Item
                          name="files_permission_folder"
                          {...formLayoutSingle}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="Select folder"
                          >
                            {folderFilesOptions}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={4}>
                        <Button
                          type="primary"
                          onClick={handleAddFilesPermission}
                          ghost
                          block
                        >
                          Add
                        </Button>
                      </Col>
                    </Row>
                    <Table
                      dataSource={filesPermissions}
                      rowKey="folder"
                      size="small"
                    >
                      <Column
                        key="folder"
                        title="Folder"
                        dataIndex="folder"
                      ></Column>
                      {FILES_PERMISSIONS.map(perm => {
                        return (
                          <Column
                            key={perm.value}
                            title={
                              perm.content ? (
                                <Popover
                                  title={perm.label}
                                  content={
                                    <ul>
                                      {perm.content.map((line, idx) => (
                                        <li key={idx}>{line}</li>
                                      ))}
                                    </ul>
                                  }
                                >
                                  {perm.label}
                                </Popover>
                              ) : (
                                perm.label
                              )
                            }
                            dataIndex={perm.value}
                            align="center"
                            width={100}
                            render={(text, record, index) => {
                              return (
                                <Checkbox
                                  checked={record[perm.value]}
                                  onChange={handleFilesPermissionChange(
                                    record,
                                    perm.value
                                  )}
                                />
                              );
                            }}
                          ></Column>
                        );
                      })}
                      <Column
                        key="delete"
                        title="Action"
                        dataIndex="delete"
                        width={70}
                        render={(text, record, index) => {
                          return (
                            <Button
                              size="small"
                              onClick={handleDeleteFilesPermission(record)}
                              danger
                              block
                            >
                              Delete
                            </Button>
                          );
                        }}
                      ></Column>
                    </Table>
                  </Panel>
                  <Panel
                    header="SQL Proc"
                    key="sqlproc"
                    style={
                      targetSqlProcedures.length > 0
                        ? panelActiveStyle
                        : panelStyle
                    }
                    extra={
                      <Badge
                        count={targetSqlProcedures.length}
                        style={badgeStyle}
                      />
                    }
                  >
                    <Form.Item
                      name="sql_proc_permissions"
                      {...formLayoutSingle}
                    >
                      <Transfer
                        dataSource={sqlProcedureOptions}
                        titles={['Available', 'Selected']}
                        targetKeys={targetSqlProcedures}
                        selectedKeys={selectedSqlProcedures}
                        onChange={handleSqlProcedureChange}
                        onSelectChange={handleSqlProcedureSelectChange}
                        render={item => item.title}
                        listStyle={{ width: '100%' }}
                        locale={{
                          notFoundContent: (
                            <Empty description="No Permissions" />
                          ),
                        }}
                      />
                    </Form.Item>
                  </Panel>
                </Collapse>
              </div>
            </Col>
            <Col span={1}></Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};

export default UserEditModal;
