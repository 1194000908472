// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_RESOURCE_GROUPS = gql`
  query {
    resource_groups {
      name
      alias
      max_data
      max_tier_priority
      max_scheduling_priority
      max_cpu_concurrency
      tier_attributes {
        name
        max_memory
      }
      usage
    }
  }
`;

export const useGetResourceGroups = () => {
  return useQuery(GET_RESOURCE_GROUPS);
};
