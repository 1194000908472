const VizTitleBar = ({ config }) => {
  const TITLE_STYLE = {
    height: 29,
    float: 'left',
    width: '100%',
    textAlign: 'center',
    color: '#666666',
    fontWeight: 400,
    borderBottom: '1px solid #e9e9e9',
    fontSize: '15px',
    lineHeight: '29px',
    backgroundColor: '#f9f9f9',
  };

  if (
    config &&
    Array.isArray(config) &&
    config.length > 0 &&
    config[0]?.title
  ) {
    return <div style={TITLE_STYLE}>{config[0].title}</div>;
  }
  return config?.title ? <div style={TITLE_STYLE}>{config.title}</div> : <></>;
};

export default VizTitleBar;
