// Imports
import React, { useMemo, useEffect, useState, useContext } from 'react';
import {
  Modal,
  Form,
  Input,
  Button,
  Select,
  Checkbox,
  AutoComplete,
  notification,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { FILES_PERMISSION_WRITE } from '../../constants';
import { FREE_SAAS } from '../../setup/config';
import { UserContext } from '../../context';
import { displayError } from '../../helper';
import useEvent, { EVENT_TYPES } from '../../hooks/useEvent';

const { Option } = Select;

const TableExportModal = ({ table, visible, close, callback }) => {
  const { data: { folders = [] } = {}, refetch: refetchFolders } =
    GraphQLServices.Files.useGetFolders();
  const [executeSql, { loading: queryLoading }] =
    GraphQLServices.SqlQueries.useExecuteSql();

  const [form] = Form.useForm();
  const userMe = useContext(UserContext) ?? {};

  const [folder, setFolder] = useState(null);
  const [file, setFile] = useState(null);

  const { emit } = useEvent(EVENT_TYPES.FILES_UPDATE);

  useEffect(() => {
    if (FREE_SAAS && !folder && folders.length > 0) {
      const folder = folders.find(
        folder => folder.name === `~${userMe.username}`
      );
      setFolder(folder.id);
      form.setFieldsValue({ folder: folder.id });
    }
  }, [folders, folder, form, userMe.username]);

  const folderOptions = useMemo(
    _ => {
      return folders
        ? folders
            .filter(
              folder => folder.permission === FILES_PERMISSION_WRITE.value
            )
            .map(folder => {
              return {
                value: folder.name,
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  const onFinish = async values => {
    const {
      folder,
      fileName,
      fileFormat,
      kineticaHeader = false,
      overwrite = false,
    } = values;
    const { schema, name } = table;

    const quotedTable = `"${schema}"."${name}"`;
    const file_path = fileName
      ? `kifs://${folder}/${fileName}`
      : `kifs://${folder}`;
    const kineticaHeaderStr = kineticaHeader ? 'TRUE' : 'FALSE';
    const singleFileStr = overwrite ? 'overwrite' : 'true';

    const tableExpr = `TABLE ${quotedTable}`;
    const filePathExpr = `FILE PATH '${file_path}'`;
    const formatExpr = ` FORMAT ${fileFormat}`;
    const optionsExpr = ` WITH OPTIONS ( KINETICA_HEADER = ${kineticaHeaderStr}, SINGLE_FILE = '${singleFileStr}' )`;
    const statement = `EXPORT ${tableExpr} INTO ${filePathExpr}${formatExpr}${optionsExpr};`;

    try {
      const resp = await executeSql({
        variables: {
          statement,
        },
      });

      if (resp?.errors) {
        throw new Error(resp?.errors[0]?.message);
      }

      const records = resp?.data?.executeSql?.response?.count_affected;
      notification.info({
        message: 'Table Export',
        description: `${records} records exported from ${schema}.${name} successfully. Go to the Files explorer to access your file.`,
        duration: 0,
      });

      emit();

      await refetchFolders();
      callback(null, resp);
    } catch (error) {
      // Graphql client should display error
      displayError(error);
      callback(error, null);
      setFile(null);
    }
  };

  const exportTable = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Export Table to Files"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="export"
          type="primary"
          onClick={exportTable}
          loading={queryLoading}
        >
          Export
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      {!file ? (
        <Form
          form={form}
          name="table"
          layout="vertical"
          onFinish={onFinish}
          colon={false}
          preserve={false}
        >
          <Form.Item
            label="Folder"
            name="folder"
            rules={[
              {
                required: true,
                message: 'Please select a folder!',
              },
            ]}
          >
            <AutoComplete
              options={folderOptions}
              placeholder="New or existing folder name"
              notFoundContent="No folders found"
              disabled={FREE_SAAS}
            />
          </Form.Item>
          <Form.Item label="File Name" name="fileName">
            <Input placeholder="File to which data will be written" />
          </Form.Item>
          <Form.Item
            name="fileFormat"
            label="Format"
            rules={[
              {
                required: true,
                message: 'Please select a format for the data.',
              },
            ]}
          >
            <Select>
              <Option value="TEXT">CSV/TSV/PSV</Option>
              <Option value="PARQUET">Parquet</Option>
            </Select>
          </Form.Item>
          <Form.Item
            name="overwrite"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Overwrite existing</Checkbox>
          </Form.Item>
          <Form.Item
            name="kineticaHeader"
            valuePropName="checked"
            initialValue={false}
          >
            <Checkbox>Kinetica Header</Checkbox>
          </Form.Item>
        </Form>
      ) : (
        <div>Download?</div>
      )}
    </Modal>
  );
};

export default TableExportModal;
