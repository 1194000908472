// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_METRIC = gql`
  query GetMetric(
    $key: String!
    $endpoint: String!
    $query: String!
    $start: Float
    $end: Float
    $step: Int
  ) {
    metric(
      key: $endpoint
      endpoint: $endpoint
      query: $query
      start: $start
      end: $end
      step: $step
    ) {
      key
      status
      data
    }
  }
`;

export const GET_METRICS = gql`
  query GetMetrics($params: JSON!) {
    metrics(params: $params) {
      key
      status
      data
    }
  }
`;

export const useGetMetric = options => {
  return useQuery(GET_METRIC, options);
};

export const useGetMetrics = options => {
  return useQuery(GET_METRICS, options);
};
