import { gql, useQuery } from '@apollo/client';

export const GET_CHATGPT_COMPLETION = gql`
  query ChatGTPCompletion($question: String!, $context: [JSON]!) {
    chatgpt_completion(question: $question, context: $context) {
      sql_statements
    }
  }
`;

export const useGetChatGPTCompletion = options =>
  useQuery(GET_CHATGPT_COMPLETION, options);
