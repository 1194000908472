// Imports
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Layout,
  Space,
  Button,
  Table,
  Progress,
  Popconfirm,
  Divider,
  notification,
  Tag,
  Tooltip,
} from 'antd';
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DeleteOutlined,
  WarningOutlined,
  SyncOutlined,
  SettingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import parseISO from 'date-fns/parseISO';

// App Imports
import GraphQLServices from '../../graphql/services';
import SnapshotConfigureModal from '../../components/modal/SnapshotConfigureModal';
import { K8S_BACKUPS_POLL_INTERVAL } from '../../setup/config';
import { formatK8sTimestampNoSecond } from '../../formatter';
import { getWarehouseStatus } from '../../helper';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';

const { Content } = Layout;

const PHASE_MAPPING = {
  New: 'New',
  FailedValidation: 'Failed Validation',
  InProgress: 'In Progress',
  Completed: 'Completed',
  PartiallyFailed: 'Partially Failed',
  Failed: 'Failed',
  Deleting: 'Deleting',
};
const ACTIVE_PHASES = ['New', 'InProgress'];
const WARNING_PHASES = ['FailedValidation', 'PartiallyFailed', 'Failed'];
const isActivePhase = phase => ACTIVE_PHASES.includes(phase);

const descSort = (item1, item2) => {
  if (parseISO(item1.startTimestamp) < parseISO(item2.startTimestamp)) return 1;
  if (parseISO(item1.startTimestamp) > parseISO(item2.startTimestamp))
    return -1;
  return 0;
};

const Snapshots = ({ cluster }) => {
  const {
    data: { k8s_kineticaclusterbackups = [] } = {},
    refetch: refetchBackups,
  } = GraphQLServices.K8sKineticaClusterBackups.useGetK8sKineticaClusterBackups(
    {
      pollInterval: K8S_BACKUPS_POLL_INTERVAL,
    }
  );
  const [createK8SKineticaClusterBackup] =
    GraphQLServices.K8sKineticaClusterBackups.useCreateK8SKineticaClusterBackup();
  const [removeK8SKineticaClusterBackup] =
    GraphQLServices.K8sKineticaClusterBackups.useRemoveK8SKineticaClusterBackupByName();
  const [createK8SKineticaClusterRestore] =
    GraphQLServices.K8sKineticaClusterRestores.useCreateK8SKineticaClusterRestore();
  const { data: { k8s_velerorestores = [] } = {}, refetch: refetchRestores } =
    GraphQLServices.K8sVeleroRestores.useGetK8sVeleroRestores({
      pollInterval: K8S_BACKUPS_POLL_INTERVAL,
    });

  const [showSnapshotConfigureModal, setShowSnapshotConfigureModal] =
    useState(false);

  const analytics = useAnalytics();

  const { topBarCollapsed } = useSelector(state => state.app);

  const handleSnapshotConfigure = _ => {
    setShowSnapshotConfigureModal(true);
  };

  const handleSnapshotConfigureUpdateCallback = (err, resp) => {
    if (resp) {
      setShowSnapshotConfigureModal(false);
    } else {
      console.error(err);
    }
  };

  const handleBackupNow = _ => {
    const { clusterName: name } = cluster?.spec?.gpudbCluster;
    const backupPayload = {
      variables: {
        name,
        body: {
          spec: {
            includedNamespaces: ['gpudb'],
          },
        },
      },
    };
    createK8SKineticaClusterBackup(backupPayload).then(response => {
      analytics.track(analytics.EVENT_TYPES.BACKUP_INITIATED)({});

      if (!response.errors) {
        notification.info({
          message: 'Backup initiated.',
        });
        refetchBackups();
        refetchRestores();
      }
    });
  };

  const handleRestore = backup => e => {
    const { clusterName: name } = cluster?.spec?.gpudbCluster;
    const { name: backupName } = backup;
    const restorePayload = {
      variables: {
        name,
        body: {
          spec: {
            backupName,
          },
        },
      },
    };
    createK8SKineticaClusterRestore(restorePayload).then(response => {
      analytics.track(analytics.EVENT_TYPES.RESTORE_INITIATED)({});

      if (!response.errors) {
        notification.info({
          message: 'Backup restore initiated.',
        });
        refetchRestores();
        refetchBackups();
      }
    });
  };

  const handleDelete = backup => e => {
    const { name } = backup;
    const deletePayload = {
      variables: {
        name,
      },
    };
    removeK8SKineticaClusterBackup(deletePayload).then(response => {
      if (!response.errors) {
        notification.info({
          message: 'Backup deleted.',
        });
        refetchRestores();
        refetchBackups();
      }
    });
  };

  const backups = useMemo(
    _ => {
      return k8s_kineticaclusterbackups
        ? k8s_kineticaclusterbackups
            .map(item => {
              const { name } = item?.metadata;
              const { status } = item;
              return {
                type: 'Backup',
                name,
                ...status,
              };
            })
            .filter(backup => backup.phase)
            .sort(descSort)
        : [];
    },
    [k8s_kineticaclusterbackups]
  );

  const activities = useMemo(
    _ => {
      const backups = k8s_kineticaclusterbackups
        ? k8s_kineticaclusterbackups.map(item => {
            const { name } = item?.metadata;
            const { status } = item;
            return {
              type: 'Backup',
              key: `${name}-backup`,
              name,
              ...status,
            };
          })
        : [];
      const restores = k8s_velerorestores
        ? k8s_velerorestores.map(item => {
            const { name } = item?.metadata;
            const { status } = item;
            return {
              type: 'Restore',
              key: `${name}-restore`,
              name,
              ...status,
            };
          })
        : [];
      return []
        .concat(backups)
        .concat(restores)
        .filter(activity => !activity.phase || isActivePhase(activity.phase))
        .sort(descSort);
    },
    [k8s_kineticaclusterbackups, k8s_velerorestores]
  );

  const dateStyle = {
    letterSpacing: '-0.5px',
  };

  const { phase } = getWarehouseStatus(cluster);

  return (
    <Content
      style={{
        backgroundColor: '#ffffff',
        padding: '20px',
        height: topBarCollapsed
          ? DEFAULT_TABPANE_NOHEAD_HEIGHT
          : DEFAULT_TABPANE_HEIGHT,
        overflow: 'auto',
      }}
    >
      <div
        style={{
          marginBottom: '10px',
          top: '0px',
          left: '0px',
          padding: '0px',
          zIndex: 2,
          float: 'right',
        }}
      >
        <Space>
          {false && (
            <Button
              icon={<SettingOutlined />}
              onClick={handleSnapshotConfigure}
              size="small"
            >
              Configure
            </Button>
          )}
          <Popconfirm
            title="Are you sure you want to backup now?"
            onConfirm={handleBackupNow}
          >
            <Button
              type="primary"
              icon={<CloudDownloadOutlined />}
              size="small"
              ghost
            >
              Backup Now
            </Button>
          </Popconfirm>
        </Space>
      </div>
      {activities.length > 0 && (
        <>
          <h3>Activity</h3>
          <Table
            dataSource={activities}
            rowKey="key"
            size="small"
            pagination={false}
          >
            <Table.Column
              title="Type"
              dataIndex="type"
              width={100}
              render={value => {
                return (
                  <div>
                    {value === 'Backup' ? (
                      <Tag color="#87d068">
                        <CloudDownloadOutlined /> {value}
                      </Tag>
                    ) : (
                      <Tag color="#2db7f5">
                        <CloudUploadOutlined /> {value}
                      </Tag>
                    )}
                  </div>
                );
              }}
            />
            <Table.Column
              title="Started"
              dataIndex="startTimestamp"
              width={150}
              render={value => {
                return value ? (
                  <div style={dateStyle}>
                    {formatK8sTimestampNoSecond(value)}
                  </div>
                ) : (
                  <span style={{ color: '#cccccc' }}>Not Available</span>
                );
              }}
            />
            <Table.Column
              title="Status"
              dataIndex="phase"
              width={150}
              render={value => {
                return value ? (
                  <div>
                    {value === 'InProgress' && <SyncOutlined spin />}{' '}
                    {PHASE_MAPPING[value]}
                  </div>
                ) : (
                  <span style={{ color: '#cccccc' }}>Not Available</span>
                );
              }}
            />
            <Table.Column
              title="Progress"
              dataIndex="progress"
              render={(_, record) => {
                const { progress = {} } = record;
                const { itemsBackedUp = 0, totalItems = 0 } = progress;
                const percent = Math.round((itemsBackedUp * 100) / totalItems);
                return totalItems > 0 ? (
                  <div style={{ width: '95%' }}>
                    <Progress
                      percent={percent}
                      size="small"
                      strokeColor={
                        record.phase !== 'PartiallyFailed'
                          ? '#1890ff'
                          : '#ff4d4f'
                      }
                      status={
                        record.phase !== 'PartiallyFailed'
                          ? 'active'
                          : 'exception'
                      }
                    />
                  </div>
                ) : (
                  <span style={{ color: '#cccccc' }}>Not Available</span>
                );
              }}
            />
          </Table>
          <Divider dashed />
        </>
      )}
      <h3>Backups</h3>
      <Table
        dataSource={backups.filter(backup => !isActivePhase(backup.phase))}
        rowKey="name"
        size="small"
        pagination={{
          pageSize:
            activities.length > 0
              ? Math.floor(
                  (window.innerHeight - 530 - 40 * activities.length) / 40
                )
              : Math.floor((window.innerHeight - 420) / 40),
        }}
      >
        <Table.Column
          title=""
          dataIndex="name"
          width={20}
          render={value => {
            return (
              <Tooltip title={value}>
                <SaveOutlined />
              </Tooltip>
            );
          }}
        />
        <Table.Column
          title="Started"
          dataIndex="startTimestamp"
          width={150}
          render={value => {
            return (
              <div style={dateStyle}>{formatK8sTimestampNoSecond(value)}</div>
            );
          }}
        />
        <Table.Column
          title="Completed"
          dataIndex="completionTimestamp"
          width={150}
          render={value => {
            return (
              <div style={dateStyle}>{formatK8sTimestampNoSecond(value)}</div>
            );
          }}
        />
        {/* <Table.Column
          title="Expiration"
          dataIndex="expiration"
          width={150}
          render={value => {
            return (
              <div style={dateStyle}>{formatK8sTimestampNoSecond(value)}</div>
            );
          }}
        /> */}
        <Table.Column
          title="Status"
          dataIndex="phase"
          width={150}
          render={value => {
            return (
              <div
                style={
                  WARNING_PHASES.includes(value) ? { color: '#ff4d4f' } : {}
                }
              >
                {WARNING_PHASES.includes(value) && <WarningOutlined />}{' '}
                {PHASE_MAPPING[value]}
              </div>
            );
          }}
        />
        <Table.Column
          title="Items"
          dataIndex="progress"
          render={(_, record) => {
            return (
              <div>
                {record?.progress?.itemsBackedUp} /{' '}
                {record?.progress?.totalItems}
              </div>
            );
          }}
        />
        <Table.Column
          width={100}
          render={(_, record) => {
            return (
              <Space>
                {phase !== 'Suspended' ? (
                  <Tooltip title="Cluster must be suspended before restoring">
                    <Button
                      icon={<CloudUploadOutlined />}
                      size="small"
                      disabled={true}
                    >
                      Restore
                    </Button>
                  </Tooltip>
                ) : (
                  <Popconfirm
                    title="Are you sure you want to restore this snapshot?"
                    onConfirm={handleRestore(record)}
                  >
                    <Button icon={<CloudUploadOutlined />} size="small">
                      Restore
                    </Button>
                  </Popconfirm>
                )}
                <Popconfirm
                  title="Are you sure you want to delete this snapshot?"
                  onConfirm={handleDelete(record)}
                >
                  <Button icon={<DeleteOutlined />} size="small" danger />
                </Popconfirm>
              </Space>
            );
          }}
        />
      </Table>
      {showSnapshotConfigureModal && (
        <SnapshotConfigureModal
          visible={showSnapshotConfigureModal}
          close={_ => {
            setShowSnapshotConfigureModal(false);
          }}
          callback={handleSnapshotConfigureUpdateCallback}
        />
      )}
    </Content>
  );
};

export default Snapshots;
