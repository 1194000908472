// Imports
import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
  PartitionOutlined,
  DiffOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SettingOutlined,
  FileTextOutlined,
  PlayCircleOutlined,
  ReloadOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const ModelObject = ({
  nodeData,
  handleModelInfo,
  handleModelExecute,
  handleModelConfigure,
  handleModelDelete,
  handleModelDDL,
  handleModelRefresh,
  handleMultiSelect,
}) => {
  const modelContextMenu = node => {
    return (
      <Menu>
        <Menu.Item key="info" onClick={_ => handleModelInfo(node.source)}>
          <InfoCircleOutlined /> Info
        </Menu.Item>
        <Menu.Item key="execute" onClick={_ => handleModelExecute(node.source)}>
          <PlayCircleOutlined /> Execute
        </Menu.Item>
        <Menu.Item key="configure" onClick={_ => handleModelConfigure(node.source)}>
          <SettingOutlined /> Configure
        </Menu.Item>
        <Menu.Item key="ddl" onClick={_ => handleModelDDL(node.source)}>
          <FileTextOutlined /> DDL
        </Menu.Item>
        <Menu.Item key="refresh" onClick={_ => handleModelRefresh(node.source)}>
          <ReloadOutlined /> Refresh
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={_ => handleModelDelete(node.source)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="multiselect"
          onClick={_ => handleMultiSelect(node)}
          style={{ color: '#3700b3' }}
        >
          <DiffOutlined /> Multi-Select
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={modelContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <PartitionOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default ModelObject;
