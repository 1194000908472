// Imports
import React from 'react';
import { Dropdown } from 'antd';
import {
  CodeOutlined,
  DiffOutlined,
  DeleteOutlined,
  SettingOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import {
  EXPLORER_PANEL_WIDTH,
  DEFAULT_ENVIRONMENT_NAME,
} from '../../../constants';

const FuncEnvironmentObject = ({
  nodeData,
  handleFuncEnvironmentInfo,
  handleFuncEnvironmentDelete,
  handleMultiSelect,
}) => {
  const contextContextMenu = node => {
    const isDefault =
      node.source.func_environment_name === DEFAULT_ENVIRONMENT_NAME;
    return {
      items: [
        {
          key: 'info',
          label: 'Configure',
          icon: <SettingOutlined />,
          onClick: _ => handleFuncEnvironmentInfo(node.source),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleFuncEnvironmentDelete(node.source),
          disabled: isDefault,
          style: { color: isDefault ? '#cccccc' : '#C62828' },
        },
        {
          type: 'divider',
        },
        {
          key: 'multiselect',
          label: 'Multi-Select',
          icon: <DiffOutlined />,
          onClick: _ => handleMultiSelect(node),
          disabled: isDefault,
          style: { color: isDefault ? '#cccccc' : '#3700b3' },
        },
      ],
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={contextContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <CodeOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default FuncEnvironmentObject;
