// Imports
import React from 'react';
import { Dropdown } from 'antd';
import {
  FileTextOutlined,
  DiffOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  SettingOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const ContextObject = ({
  nodeData,
  handleContextInfo,
  handleContextConfigure,
  handleContextDelete,
  handleMultiSelect,
}) => {
  const contextContextMenu = node => {
    return {
      items: [
        {
          key: 'info',
          label: 'Info',
          icon: <InfoCircleOutlined />,
          onClick: _ => handleContextInfo(node.source),
        },
        {
          key: 'configure',
          label: 'Configure',
          icon: <SettingOutlined />,
          onClick: _ => handleContextConfigure(node.source),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleContextDelete(node.source),
          style: { color: '#C62828' },
        },
        {
          type: 'divider',
        },
        {
          key: 'multiselect',
          label: 'Multi-Select',
          icon: <DiffOutlined />,
          onClick: _ => handleMultiSelect(node),
          style: { color: '#3700b3' },
        },
      ],
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={contextContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <FileTextOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default ContextObject;
