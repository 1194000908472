// Imports

// App Imports

// Actions Types
export const APP_SET_EXPLORER_CURRENT_TAB = 'APP/SET_EXPLORER_CURRENT_TAB';
export const APP_SET_EXPLORER_PANEL_COLLAPSED =
  'APP/SET_EXPLORER_PANEL_COLLAPSED';
export const APP_SET_EXPLORER_PANEL_DATA_EXPANDED_KEYS =
  'APP/SET_EXPLORER_PANEL_DATA_EXPANDED_KEYS';
export const APP_SET_EXPLORER_PANEL_WORKBOOKS_EXPANDED_KEYS =
  'APP/SET_EXPLORER_PANEL_WORKBOOKS_EXPANDED_KEYS';
export const APP_SET_EXPLORER_PANEL_FILES_EXPANDED_KEYS =
  'APP/SET_EXPLORER_PANEL_FILES_EXPANDED_KEYS';
export const APP_SET_TOP_BAR_COLLAPSED = 'APP/APP_SET_TOP_BAR_COLLAPSED';

// Actions

// Set current explorer menu tab
export function setExplorerCurrentTab(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_EXPLORER_CURRENT_TAB,
      current,
    });
  };
}

// Set explorer panel collapsed state
export function setExplorerPanelCollapsed(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_EXPLORER_PANEL_COLLAPSED,
      current,
    });
  };
}

// Set explorer panel data expanded keys
export function setExplorerPanelDataExpandedKeys(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_EXPLORER_PANEL_DATA_EXPANDED_KEYS,
      current,
    });
  };
}

// Set explorer panel workbooks expanded keys
export function setExplorerPanelWorkbooksExpandedKeys(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_EXPLORER_PANEL_WORKBOOKS_EXPANDED_KEYS,
      current,
    });
  };
}

// Set explorer panel files expanded keys
export function setExplorerPanelFilesExpandedKeys(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_EXPLORER_PANEL_FILES_EXPANDED_KEYS,
      current,
    });
  };
}

// Set top collapsed state
export function setTopBarCollapsed(current) {
  return async dispatch => {
    dispatch({
      type: APP_SET_TOP_BAR_COLLAPSED,
      current,
    });
  };
}
