// Imports
import React from 'react';
import { Dropdown, Menu } from 'antd';
import {
  ImportOutlined,
  DeleteOutlined,
  DiffOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const StreamObject = ({
  nodeData,
  handleStreamInfo,
  handleStreamDelete,
  handleMultiSelect,
}) => {
  const streamContextMenu = node => {
    return (
      <Menu>
        <Menu.Item key="info" onClick={_ => handleStreamInfo(node.source)}>
          <InfoCircleOutlined /> Info
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="delete"
          onClick={_ => handleStreamDelete(node.source)}
          style={{ color: '#C62828' }}
        >
          <DeleteOutlined /> Delete
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          key="multiselect"
          onClick={_ => handleMultiSelect(node)}
          style={{ color: '#3700b3' }}
        >
          <DiffOutlined /> Multi-Select
        </Menu.Item>
      </Menu>
    );
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          overlay={streamContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <ImportOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default StreamObject;
