// Imports
import React from 'react';
import { Dropdown } from 'antd';
import { EditOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';

// App Imports
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const SchemaObject = ({
  nodeData,
  handleSchemaEdit,
  handleSchemaDelete,
  handleAddTableClick,
}) => {
  const tableContextMenu = node => {
    return {
      items: [
        {
          key: 'addtable',
          label: 'Add New Table',
          icon: <PlusOutlined />,
          onClick: _ =>
            handleAddTableClick({ key: 'table', schema: node.source.name }),
        },
        {
          type: 'divider',
        },
        {
          key: 'rename',
          label: 'Rename',
          icon: <EditOutlined />,
          onClick: _ => handleSchemaEdit(node.source),
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleSchemaDelete(node.source),
          style: { color: '#C62828' },
        },
      ],
    };
  };

  return (
    <Dropdown
      menu={tableContextMenu(nodeData)}
      trigger={['click', 'contextMenu']}
    >
      <div>
        <span
          style={{
            width: `${EXPLORER_PANEL_WIDTH - 80}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            verticalAlign: 'text-top',
          }}
        >
          {nodeData.title}{' '}
          {nodeData?.children && (
            <i
              style={{
                display: 'inline-block',
                fontSize: '12px',
                color: '#bbbbbb',
              }}
            >
              {nodeData?.children.length}
            </i>
          )}
        </span>
      </div>
    </Dropdown>
  );
};

export default SchemaObject;
