// Imports
import { useQuery, gql } from '@apollo/client';

export const DEBUG = gql`
  query Debug {
    app {
      app_version
      app_commit_hash
      operator_version
      operator_build_date
      operator_commit_hash
    }
    auth_providers {
      name
      type
      params
    }
    warehouses {
      name
      head_api_url
    }
    ldap_users {
      dn
      cn
      sn
      uid
      objectClass
    }
    ldap_groups {
      dn
      cn
      objectClass
      member
    }
    k8s_kineticaclusters {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaclusteradmins {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaclusterbackups {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaclusterrestores {
      kind
      metadata
      spec
      status
    }
    k8s_velerorestores {
      kind
      metadata
      spec
      status
    }
    k8s_kineticagrants {
      kind
      metadata
      spec
      status
    }
    k8s_kineticausers {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaroles {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaworkbenches {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaclusterupgrades {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaoperatorupgrades {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaworkbenchupgrades {
      kind
      metadata
      spec
      status
    }
    k8s_kineticaworkbenchoperatorupgrades {
      kind
      metadata
      spec
      status
    }
    upgrades {
      clusterName
      currentVersion {
        short
        version
        operator
        tag
      }
      availableVersions {
        short
        version
        operator
        tag
      }
      availableUpgradeVersions {
        short
        version
        operator
        tag
      }
    }
  }
`;

export const useDebug = () => {
  return useQuery(DEBUG);
};
