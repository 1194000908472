// Imports
import React from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  LeftCircleOutlined,
  RightCircleOutlined,
  ArrowsAltOutlined,
  MenuOutlined,
} from '@ant-design/icons';

// App Imports
import KineticaLogo from '../../images/logo.svg';
import Explorer from '../../components/explorer/Explorer';
import ExplorerCollapsed from '../../components/explorer/ExplorerCollapsed';
import {
  setExplorerPanelCollapsed,
  setTopBarCollapsed,
} from '../../store/app/actions';
import { dtId } from '../../helper';
import { EXPLORER_PANEL_WIDTH, TEST_IDS } from '../../constants';

const { Sider } = Layout;

const ExplorerSider = () => {
  const { topBarCollapsed, explorerPanelCollapsed } = useSelector(
    state => state.app
  );

  const dispatch = useDispatch();

  const handleSetCollapsed = current => {
    dispatch(setExplorerPanelCollapsed(current));
  };

  const setCollapsed = collapsed => _ => {
    dispatch(setExplorerPanelCollapsed(collapsed));
  };

  const setTopCollapsed = collapsed => _ => {
    dispatch(setTopBarCollapsed(collapsed));
  };

  return (
    <Sider
      collapsible
      collapsed={explorerPanelCollapsed}
      onCollapse={handleSetCollapsed}
      width={EXPLORER_PANEL_WIDTH}
      theme="light"
      trigger={null}
    >
      {!explorerPanelCollapsed ? (
        <>
          <LeftCircleOutlined
            onClick={setCollapsed(true)}
            style={{
              position: 'absolute',
              right: '-12px',
              top: '65px',
              fontSize: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              color: '#dddddd',
              zIndex: 1000,
            }}
          />
          {topBarCollapsed && (
            <div
              style={{
                backgroundColor: '#3700b3',
                width: EXPLORER_PANEL_WIDTH,
                height: 50,
                display: 'block',
              }}
            >
              <Link to="/" {...dtId(TEST_IDS.NAV_LOGO)}>
                <img
                  src={KineticaLogo}
                  alt="Kinetica Logo"
                  style={{
                    height: '26px',
                    position: 'relative',
                    top: '11px',
                    margin: '0px 20px',
                  }}
                />
              </Link>
              <ArrowsAltOutlined
                onClick={setTopCollapsed(false)}
                style={{
                  fontSize: '20px',
                  margin: '15px 15px 0 0',
                  borderRadius: '12px',
                  color: '#ffffff33',
                  float: 'right',
                  zIndex: 1000,
                }}
              />
            </div>
          )}
          <Explorer />
        </>
      ) : (
        <>
          <RightCircleOutlined
            onClick={setCollapsed(false)}
            style={{
              position: 'absolute',
              right: '-12px',
              top: '65px',
              fontSize: '24px',
              backgroundColor: '#ffffff',
              borderRadius: '12px',
              color: '#dddddd',
            }}
          />
          {topBarCollapsed && (
            <div
              onClick={setTopCollapsed(false)}
              style={{
                marginTop: '20px',
                textAlign: 'center',
                fontSize: '11px',
                color: '#3700b3',
                width: '100%',
                cursor: 'pointer',
              }}
            >
              <MenuOutlined style={{ fontSize: '25px' }} />
              <br />
              Menu
            </div>
          )}
          <ExplorerCollapsed setCollapsed={handleSetCollapsed} />
        </>
      )}
    </Sider>
  );
};

export default ExplorerSider;
