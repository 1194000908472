// Imports
import React, { useMemo, useState } from 'react';
import {
  Drawer,
  Button,
  Form,
  Select,
  Input,
  Row,
  Col,
  Tooltip,
  Slider,
  Radio,
  Divider,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import rgbHex from 'rgb-hex';
import { ChromePicker } from 'react-color';

// App Imports
import { invertColor } from '../../helper';
import {
  NIVO_COLOR_SCHEMES,
  DEFAULT_BORDER_COLOR,
  GRAPH_VIZ_TYPE,
} from '../../constants';

const { Option } = Select;
const { TextArea } = Input;

const FIELD_DEFAULT_COLORS = {
  color: DEFAULT_BORDER_COLOR,
};

const VizConfigDrawer = ({
  title = 'Configuration',
  note = '',
  viz,
  config,
  fields,
  options,
  isOpen,
  handleClose,
  handleUpdate,
  handleOnChange,
  width = 660,
  columns = 3,
  disabledFields = [],
}) => {
  const initialValues = fields.reduce((acc, cur) => {
    acc[cur.name] = config[cur.name] || cur.defaultValue || '';
    return acc;
  }, {});

  const [form] = Form.useForm();

  const [dynamicDisabledFields, setDynamicDisabledFields] = useState(
    fields.reduce((acc, cur) => {
      if (cur.disable) {
        acc[cur.name] =
          disabledFields.includes(cur.name) ||
          Object.keys(cur.disable).every(
            key => initialValues[key] === cur.disable[key]
          );
      }
      return acc;
    }, {})
  );

  const [colorPicker, setColorPicker] = useState(
    fields
      .filter(field => field.type === 'color')
      .reduce((acc, cur) => {
        acc[cur.name] = {
          color:
            config[cur.name] ||
            cur.defaultValue ||
            FIELD_DEFAULT_COLORS[cur.name],
          open: false,
        };
        return acc;
      }, {})
  );

  const onFinish = values => {
    handleUpdate(values);
  };

  const update = _ => {
    form.submit();
  };

  const buildOptions = field => {
    return {
      ...options,
      colorSchemes: NIVO_COLOR_SCHEMES,
    }[field.source]
      ? {
          ...options,
          colorSchemes: NIVO_COLOR_SCHEMES,
        }[field.source].map(option => {
          if (typeof option === 'object') {
            return (
              <Option key={option?.value} value={option?.value}>
                {option?.label}
              </Option>
            );
          }
          return (
            <Option key={option} value={option}>
              {option}
            </Option>
          );
        })
      : [];
  };

  const popover = {
    position: 'absolute',
    zIndex: '2',
  };
  const cover = {
    position: 'fixed',
    top: '0px',
    right: '0px',
    bottom: '0px',
    left: '0px',
  };

  const handleToggleColorPicker = colorField => _ => {
    setColorPicker({
      ...colorPicker,
      [colorField]: {
        ...colorPicker[colorField],
        open: !colorPicker[colorField].open,
      },
    });
  };

  const handleSelectColor = colorField => (color, e) => {
    const { r = 0, g = 0, b = 0, a = 1 } = color?.rgb;
    const hexColor = `${rgbHex(r, g, b, a)}`;
    form.setFieldsValue({ [colorField]: hexColor });
    setColorPicker({
      ...colorPicker,
      [colorField]: {
        ...colorPicker[colorField],
        color: hexColor,
      },
    });
  };

  const renderInput = field => {
    switch (field.type) {
      case 'select':
        return (
          <Select
            showSearch
            placeholder="Select one"
            optionFilterProp="children"
            filterOption={true}
            disabled={disabledFields.includes(field.name)}
          >
            <Option value=""></Option>
            {buildOptions(field)}
          </Select>
        );
      case 'radio':
        return (
          <Radio.Group options={field.options} style={{ margin: '0px 8px' }} />
        );
      case 'color':
        return (
          <>
            <Button
              onClick={handleToggleColorPicker(field.name)}
              style={{
                fontSize: '11px',
                backgroundColor: `#${colorPicker[field.name].color}`,
                borderColor: `#${colorPicker[field.name].color}`,
                color: `#${invertColor(
                  colorPicker[field.name].color.substring(0, 6),
                  true
                )}`,
              }}
              disabled={disabledFields.includes(field.name)}
              block
            >
              {`#${colorPicker[field.name].color}`}
            </Button>
            {colorPicker[field.name].open && (
              <div style={popover}>
                <div
                  style={cover}
                  onClick={handleToggleColorPicker(field.name)}
                />
                <ChromePicker
                  color={`#${colorPicker[field.name].color}`}
                  onChangeComplete={handleSelectColor(field.name)}
                  width={168}
                />
              </div>
            )}
          </>
        );
      case 'range':
        return (
          <Slider
            min={field.start}
            max={field.end}
            marks={{
              [field.start]: {
                label: field.start,
                style: { fontSize: '11px ' },
              },
              [field.end]: {
                label: field.end,
                style: { fontSize: '11px' },
              },
            }}
            step={field.step || 1}
            style={{ margin: '0px 5px' }}
            trackStyle={[
              {
                backgroundColor: '#3700B333',
                height: 12,
                marginTop: '-4px',
              },
            ]}
            handleStyle={[
              {
                backgroundColor: '#ffffff',
                borderColor: '#dddddd',
                borderWidth: '1px',
                height: 16,
                width: 10,
                borderRadius: '2px',
                marginTop: '-6px',
              },
            ]}
            railStyle={{ height: 12, marginTop: '-4px' }}
            dotStyle={{ display: 'none' }}
            disabled={
              disabledFields.includes(field.name) ||
              dynamicDisabledFields[field.name]
            }
          />
        );
      case 'html':
        return (
          <TextArea
            rows={4}
            placeholder={
              field.placeholder != null
                ? form.getFieldValue(field.placeholder)
                : ''
            }
            disabled={disabledFields.includes(field.name)}
            style={{
              fontFamily: 'monospace',
              fontSize: '13px',
            }}
            spellCheck={false}
          />
        );
      default:
        return (
          <Input
            placeholder={
              field.placeholder != null
                ? form.getFieldValue(field.placeholder)
                : ''
            }
            disabled={disabledFields.includes(field.name)}
          />
        );
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    fields.forEach(field => {
      if (
        field.dynamicDefaultValue &&
        Object.keys(changedValues).includes(field.dynamicDefaultValue) &&
        form.getFieldValue(field.name) === ''
      ) {
        form.setFieldsValue({
          [field.name]: form.getFieldValue(field.dynamicDefaultValue),
        });
      }
    });

    // Disable fields based on rules
    const dynamicDisabledFieldsUpdate = {};
    fields.forEach(field => {
      if (
        field.disable &&
        Object.keys(field.disable).every(key => {
          return form.getFieldValue(key) === field.disable[key];
        })
      ) {
        dynamicDisabledFieldsUpdate[field.name] = true;
      } else if (field.disable && !disabledFields.includes(field.name)) {
        dynamicDisabledFieldsUpdate[field.name] = false;
      }
    });
    setDynamicDisabledFields({
      ...dynamicDisabledFields,
      ...dynamicDisabledFieldsUpdate,
    });

    if (handleOnChange) {
      handleOnChange(changedValues, allValues);
    }
  };

  const renderField = field => {
    return (
      <Col key={field.name} span={field.span || 24 / columns}>
        <Form.Item
          label={
            field.description ? (
              <>
                {field.label}
                <Tooltip title={field.description}>
                  <InfoCircleOutlined
                    style={{
                      color: '#bbbbbb',
                      marginLeft: '5px',
                      marginBottom: '-2px',
                    }}
                  />
                </Tooltip>
              </>
            ) : (
              field.label
            )
          }
          name={field.name}
          required={field.required && !disabledFields.includes(field.name)}
          shouldUpdate
        >
          {renderInput(field)}
        </Form.Item>
      </Col>
    );
  };

  const isGraph = useMemo(() => {
    return viz.visualization_type.id === GRAPH_VIZ_TYPE;
  }, [viz]);

  return (
    <Drawer
      title={title}
      extra={
        note ? (
          <i style={{ fontSize: '13px', color: '#999999' }}>{note}</i>
        ) : null
      }
      placement="right"
      onClose={handleClose}
      open={isOpen}
      getContainer={false}
      closable={true}
      width={width}
      style={{ position: 'absolute' }}
      headerStyle={{ height: '43px' }}
      bodyStyle={{ padding: '10px 20px' }}
      maskStyle={{ backgroundColor: '#f1edf8cc' }}
      footer={[
        <Button
          key="update"
          onClick={update}
          type="primary"
          size="small"
          style={{
            float: 'right',
          }}
        >
          Update
        </Button>,
      ]}
    >
      <Form
        form={form}
        name="config"
        layout="vertical"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
        size="small"
      >
        <Row gutter={16}>
          {isGraph && (
            <>
              <p
                style={{
                  padding: '5px 10px',
                  color: '#999999',
                  fontSize: '13px',
                  fontWeight: 200,
                }}
              >
                If node configuration is not provided, nodes can be inferred
                from edge data. If visualizing from query results, node/edge
                table is not required.
              </p>
              {fields
                .filter(field => field.name.includes('node_'))
                .reduce((acc, cur) => {
                  acc.push(cur);
                  if (cur.name.includes('_table_name')) {
                    acc.push({});
                  }
                  return acc;
                }, [])
                .map(field => {
                  if (Object.keys(field).length === 0) {
                    return (
                      <Col key="spacer" span={field.span || 24 / columns}></Col>
                    );
                  }
                  return renderField(field);
                })}
              <Divider
                key="divider1"
                style={{ margin: '0px 10px 15px', padding: '0px' }}
                dashed
              />
              {fields
                .filter(field => field.name.includes('edge_'))
                .reduce((acc, cur) => {
                  acc.push(cur);
                  if (cur.name.includes('_table_name')) {
                    acc.push({});
                  }
                  return acc;
                }, [])
                .map(field => {
                  if (Object.keys(field).length === 0) {
                    return (
                      <Col key="spacer" span={field.span || 24 / columns}></Col>
                    );
                  }
                  return renderField(field);
                })}
              <Divider
                key="divider2"
                style={{ margin: '0px 10px 15px', padding: '0px' }}
                dashed
              />
              {fields
                .filter(field => ['title', 'limit'].includes(field.name))
                .map(field => {
                  if (Object.keys(field).length === 0) {
                    return (
                      <Col key="spacer" span={field.span || 24 / columns}></Col>
                    );
                  }
                  return renderField(field);
                })}
            </>
          )}
          {!isGraph && fields?.map(renderField, columns)}
        </Row>
      </Form>
    </Drawer>
  );
};

export default VizConfigDrawer;
