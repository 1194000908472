// Imports
import React, { useState, useRef, useMemo, useEffect } from 'react';
import { Modal, Button, Spin, Progress, notification } from 'antd';
import axios from 'axios';
import 'filepond/dist/filepond.min.css';

// App Imports
import MonacoEditor from '../../components/editor/MonacoEditor';
import { APP_URL_API } from '../../setup/config';
import Spinner from '../../components/common/Spinner';
import { displaySuccess } from '../../helper';
import { KIFS_UPLOAD_CHUNK_SIZE } from '../../constants';
import GPUdb from '../../lib/GPUdb';

const FileEditModal = ({ file, visible, close, callback }) => {
  const [isUploading, setIsUploading] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [progress, setProgress] = useState(-1);

  const editorRef = useRef();

  useEffect(
    _ => {
      if (file) {
        setIsDownloading(true);
        axios({
          url: `${APP_URL_API}/proxy/dbapi/get/file/${file.path}`,
          method: 'GET',
          responseType: 'blob',
        })
          .then(response => {
            return response.data.text();
          })
          .then(source => {
            editorRef.current.editor.setValue(source);
          })
          .catch(error => {
            if (error.response.data) {
              error.response.data.text().then(msg => {
                notification.error({
                  message: 'Error While Downloading',
                  description: msg,
                  duration: 0,
                });
              });
            } else {
              notification.error({
                message: 'Error While Downloading',
                description: error.toString(),
                duration: 0,
              });
            }
          })
          .finally(_ => {
            setIsDownloading(false);
          });
      }
    },
    [file]
  );

  const handleUpdate = _ => {
    setIsUploading(true);
    const content = editorRef.current.editor.getModel().getValue();

    const newFile = new Blob([content], {
      type: 'text/plain',
    });

    // Create a FileHandler
    const apiUrl = `${APP_URL_API}/proxy/dbapi`;
    const gpudb = new GPUdb(apiUrl, {
      timeout: 0,
    });
    const fileHandler = new GPUdb.FileHandler(gpudb);
    fileHandler.chunkSize = KIFS_UPLOAD_CHUNK_SIZE;

    Object.defineProperty(newFile, 'name', {
      writable: true,
      value: file.name,
    });
    Object.defineProperty(newFile, 'folder', {
      writable: true,
      value: file.folder,
    });

    fileHandler.upload(
      newFile,
      file.folder,
      { file_encoding: 'base64' },
      status => {
        setProgress(status);
      },
      (err3, resp) => {
        if (err3) {
          callback(err3, null);
        } else {
          displaySuccess(`File ${file.name} saved successfully!`);
          callback(null, resp);
        }
        setIsUploading(false);
        setProgress(-1);
      }
    );
  };

  const monacoScrollbar = useMemo(_ => {
    return { alwaysConsumeMouseWheel: false };
  }, []);

  const monacoMinimap = useMemo(_ => {
    return {
      enabled: false,
    };
  }, []);

  return (
    <Modal
      title={`Edit Source: ${file?.name}`}
      open={visible}
      footer={[
        <div key="progress" style={{ width: 200, float: 'left' }}>
          {progress > -1 && <Progress percent={progress} size="small" />}
        </div>,
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="upload"
          type="primary"
          onClick={handleUpdate}
          loading={isUploading}
        >
          Save Changes
        </Button>,
      ]}
      onCancel={close}
      maskClosable={false}
      width={window.innerWidth - 150}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={isUploading || isDownloading}>
        <MonacoEditor
          ref={editorRef}
          language="python"
          contextmenu={false}
          renderLineHighlight={'none'}
          scrollBeyondLastLine={false}
          scrollbar={monacoScrollbar}
          overviewRulerLanes={0}
          automaticLayout={true}
          minimap={monacoMinimap}
          style={{
            width: 'calc(100% - 50px)',
            height: `${window.innerHeight - 300}px`,
            marginTop: '10px',
            marginLeft: '10px',
            pointerEvents: 'all',
          }}
        ></MonacoEditor>
      </Spin>
    </Modal>
  );
};

export default FileEditModal;
