// Imports
import React from 'react';
import { Row, Col } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

// App Imports
import KineticaLogo from '../images/logo.svg';

const Loading = _ => {
  return (
    <Row style={{ top: 'calc(50% - 80px)', position: 'relative' }}>
      <Col xs={4} sm={6} md={7} lg={8} xl={9}></Col>
      <Col xs={16} sm={12} md={10} lg={8} xl={6}>
        <div style={{ textAlign: 'center', marginBottom: '20px' }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: '40px', position: 'relative', top: '-3px' }}
          />
          <h1
            style={{
              color: '#ffffff66',
              fontSize: '34px',
              fontWeight: '200',
              marginBottom: '30px',
            }}
          >
            Workbench
          </h1>
          <LoadingOutlined
            style={{ fontSize: '60px', color: '#ffffff', opacity: '20%' }}
            spin
          />
        </div>
      </Col>
      <Col xs={4} sm={6} md={7} lg={8} xl={9}></Col>
    </Row>
  );
};

export default Loading;
