// Imports
import React, { useCallback, useMemo, useState } from 'react';
import { Spin } from 'antd';
import { ReactEditor, useSlate } from 'slate-react';
import { Transforms } from 'slate';

// App Imports
import VizGraph from './VizGraph';
import BlockToolbar from './BlockToolbar';
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import { GRAPH_VIZ_TYPE } from '../../constants';
import BlockIndex from './BlockIndex';
import { useIsReadOnly } from './utils';
import ReadOnlyDivider from './ReadOnlyDivider';
import CollapsedBlock from './CollapsedBlock';

const BLOCK_HEIGHT = 535;

const GraphBlock = ({
  attributes,
  children,
  element,
  blocks,
  runId,
  toggleBlockCollapsed,
  toggleBlockVisibility,
  handleFullscreen,
  fullscreen = false,
}) => {
  const { loading: vizTypeLoading, data } =
    GraphQLServices.VisualizationTypes.useGetVisualizationTypeById({
      variables: {
        id: GRAPH_VIZ_TYPE,
      },
    });
  const editor = useSlate();
  const readOnly = useIsReadOnly();

  const [loading, setLoading] = useState(false);

  const handleUpdateGraphViz = useCallback(
    (update, callback) => {
      const path = ReactEditor.findPath(editor, element);
      Transforms.setNodes(editor, { content: update }, { at: path });
      if (callback) {
        callback();
      }
    },
    [editor, element]
  );

  const viz = useMemo(
    _ => {
      return {
        ...data,
        config: {
          ...element.content,
        },
      };
    },
    [data, element]
  );

  const index = useMemo(
    _ => {
      const { id } = element;
      return blocks.findIndex(e => e.id === id) + 1;
    },
    [element, blocks]
  );

  const { isBlockVisible, isBlockCollapsed } = useMemo(
    _ => {
      const { isBlockVisible = true, isBlockCollapsed = false } =
        element?.config ?? {};
      return { isBlockVisible, isBlockCollapsed };
    },
    [element]
  );

  const height = useMemo(() => {
    if (fullscreen) {
      return window.innerHeight - 270;
    }
    return BLOCK_HEIGHT;
  }, [fullscreen]);

  if (readOnly && !isBlockVisible) {
    return null;
  }

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px',
              position: 'relative',
              padding: '0px 5px',
              pointerEvents: 'none',
            }
          : {
              margin: fullscreen ? '5px 0px 0px' : '16px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
              pointerEvents: 'none',
            }
      }
    >
      {readOnly && <ReadOnlyDivider index={index} />}
      {!readOnly && <BlockIndex index={index} />}
      {!fullscreen && (
        <BlockToolbar
          element={element}
          blocks={blocks}
          setLoading={setLoading}
          toggleBlockCollapsed={toggleBlockCollapsed}
          toggleBlockVisibility={toggleBlockVisibility}
          handleFullscreen={handleFullscreen}
        />
      )}
      {isBlockCollapsed && (
        <CollapsedBlock
          element={element}
          loading={loading}
        />
      )}
      {!isBlockCollapsed && (
        <Spin indicator={<Spinner />} spinning={loading || vizTypeLoading}>
          <div
            style={{
              padding: '0px 5px',
              overflow: 'hidden',
              height,
              pointerEvents: 'all',
            }}
          >
            {data && !vizTypeLoading && (
              <VizGraph
                key={viz.id}
                viz={viz}
                data={null}
                handleUpdate={handleUpdateGraphViz}
                minHeight={height - 30}
                readOnly={readOnly}
                runId={runId}
                marginTop={15}
              />
            )}
          </div>
        </Spin>
      )}
      <div style={{ userSelect: 'none', display: 'none' }}>{children}</div>
    </div>
  );
};

export default GraphBlock;
