// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';

const { TextArea } = Input;

const WorksheetEditModal = ({ worksheet, visible, close, callback }) => {
  const [updateWorksheetById] =
    GraphQLServices.Worksheets.useUpdateWorksheetById();

  const [isUpdating, setIsUpdating] = useState(false);

  const [form] = Form.useForm();

  const onFinish = values => {
    const { name, description } = values;
    setIsUpdating(true);
    updateWorksheetById({
      variables: {
        id: worksheet.id,
        name,
        description,
      },
    })
      .then(resp => {
        setIsUpdating(false);
        if (callback) {
          callback(null, resp);
        }
      })
      .catch(err => {
        setIsUpdating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const update = _ => {
    form.submit();
  };

  return (
    <Modal
      title="Edit Worksheet"
      open={visible}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="worksheet"
        layout="vertical"
        initialValues={worksheet}
        onFinish={onFinish}
        colon={false}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: 'Please input worksheet name!',
              whitespace: true,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default WorksheetEditModal;
