// Imports
import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
  FileAddOutlined,
  ImportOutlined,
} from '@ant-design/icons';

// App Imports
import {
  EXPLORER_PANEL_WIDTH,
  FILES_PERMISSION_WRITE,
} from '../../../constants';
import { humanFileSize } from '../../../helper';

const FolderObject = ({
  nodeData,
  handleFolderDelete,
  handleOpenFileUploadModal,
  handleFolderImport,
}) => {
  const folderContextMenu = node => {
    const hasWrite = node.source.permission === FILES_PERMISSION_WRITE.value;
    return {
      items: [
        {
          key: 'upload',
          label: 'Upload New File',
          icon: <PlusOutlined />,
          onClick: _ => handleOpenFileUploadModal(node.source),
          disabled: !hasWrite,
        },
        {
          key: 'import',
          label: 'Import',
          icon: <ImportOutlined />,
          onClick: _ => handleFolderImport(node.source),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleFolderDelete(node.source),
          style: { color: hasWrite ? '#C62828' : undefined },
          disabled: !hasWrite,
        },
      ],
    };
  };

  const folderUsage = useMemo(
    _ => {
      return Number(nodeData.source.data_usage);
    },
    [nodeData]
  );

  const folderLimit = useMemo(
    _ => {
      return Number(nodeData.source.data_limit);
    },
    [nodeData]
  );

  return (
    <Dropdown
      menu={folderContextMenu(nodeData)}
      trigger={['click', 'contextMenu']}
    >
      <div>
        <span
          style={{
            width: `${EXPLORER_PANEL_WIDTH - 86}px`,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: 'inline-block',
            whiteSpace: 'nowrap',
            lineHeight: '16px',
            verticalAlign: 'text-top',
          }}
        >
          <i
            style={{
              fontSize: '11px',
              color: folderUsage > 0 ? '#888888' : '#cccccc',
              float: 'right',
            }}
          >
            {humanFileSize(folderUsage, true)}
            {folderLimit >= 0 ? (
              '/' + humanFileSize(folderLimit, true)
            ) : (
              <>/&infin;</>
            )}
          </i>
          {nodeData.title}{' '}
          {nodeData.source.permission === FILES_PERMISSION_WRITE.value && (
            <FileAddOutlined style={{ color: '#cccccc' }} />
          )}
        </span>
      </div>
    </Dropdown>
  );
};

export default FolderObject;
