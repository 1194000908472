// Imports
import React from 'react';
import { Dropdown, notification, Tooltip } from 'antd';
import {
  FileOutlined,
  DownloadOutlined,
  DeleteOutlined,
  EditOutlined,
  SyncOutlined,
  ImportOutlined,
  DiffOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import {
  EXPLORER_PANEL_WIDTH,
  FILES_PERMISSION_WRITE,
} from '../../../constants';
import { KIFS_DOWNLOAD_LIMIT, FREE_SAAS } from '../../../setup/config';
import { humanFileSize } from '../../../helper';

const FileObject = ({
  nodeData,
  handleFileEdit,
  handleFileDownload,
  handleFileImport,
  handleFileDelete,
  handleMultiSelect,
  isDownloadingFile,
}) => {
  const handleDownloadClick = node => () => {
    if (FREE_SAAS && node.size >= KIFS_DOWNLOAD_LIMIT) {
      notification.error({
        message: 'Error has occurred',
        description: `Download exceeds size limit of ${humanFileSize(
          KIFS_DOWNLOAD_LIMIT,
          true
        )}`,
        duration: 0,
      });
      return;
    }
    handleFileDownload(node);
  };

  const fileContextMenu = node => {
    const hasWrite = node.folder.permission === FILES_PERMISSION_WRITE.value;
    const editMenu = node.source.name.toLowerCase().endsWith('.py')
      ? [
          {
            key: 'edit',
            label: 'Edit Source',
            icon: <EditOutlined />,
            onClick: _ => handleFileEdit(node.source),
          },
        ]
      : [];
    return {
      items: [
        ...editMenu,
        {
          key: 'download',
          label: 'Download',
          icon: <DownloadOutlined />,
          onClick: handleDownloadClick(node.source),
        },
        {
          key: 'import',
          label: 'Import',
          icon: <ImportOutlined />,
          onClick: _ => handleFileImport(node.source),
        },
        {
          type: 'divider',
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleFileDelete(node.source),
          style: { color: hasWrite ? '#C62828' : undefined },
          disabled: !hasWrite,
        },
        {
          type: 'divider',
        },
        {
          key: 'multiselect',
          label: 'Multi-Select',
          icon: <DiffOutlined />,
          onClick: _ => handleMultiSelect(node),
          style: { color: hasWrite ? '#3700b3' : undefined },
          disabled: !hasWrite,
        },
      ],
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={fileContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            {isDownloadingFile === nodeData.source.path ? (
              <SyncOutlined spin />
            ) : (
              <FileOutlined />
            )}{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 123 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              <i style={{ fontSize: '11px', color: '#cccccc', float: 'right' }}>
                {humanFileSize(nodeData.source.size, true)}
              </i>
              <Tooltip
                title={
                  isDownloadingFile === nodeData.source.path
                    ? 'Preparing download..'
                    : ''
                }
                open={isDownloadingFile === nodeData.source.path}
                placement="right"
              >
                {nodeData.title}{' '}
              </Tooltip>
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default FileObject;
