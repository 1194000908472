// Imports
import React, { useMemo, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Spin, Tabs, Form, Divider, Row, Col, Cascader } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import KifsImage from '../../images/import/kifs.svg';
import {
  DEFAULT_FORM_ITEM_PROPS,
  getFileFormat,
  getTableFromFile,
} from './utils';
import {
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';

const Setup = ({ form, setupForm, setFormValues }) => {
  const { data: { folders = [] } = {} } = GraphQLServices.Files.useGetFolders();

  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.filepath) {
          const fileRegex = /kifs:\/\/(.*)\/(.*)/gi;
          const matches = fileRegex.exec(location.state.filepath);
          if (matches && matches.length === 3) {
            setupForm.setFieldsValue({
              file: [matches[1], matches[2]],
            });
          } else {
            const folderRegex = /kifs:\/\/(.*)/gi;
            const folderMatches = folderRegex.exec(location.state.filepath);
            if (folderMatches && folderMatches.length === 2) {
              setupForm.setFieldsValue({
                file: [folderMatches[1], ''],
              });
            }
          }
        }
      }
    },
    [location, setupForm]
  );

  const onFinish = async values => {
    console.log(values);
  };

  const handleFileChange = file => {
    if (file.length === 2) {
      const filePath = file[1]
        ? `kifs://${file[0]}/${file[1]}`
        : `kifs://${file[0]}`;
      form.setFieldsValue({
        dataSource: '',
        filePath,
        fileFormat: getFileFormat(file[1]),
        tableName: getTableFromFile(file[1]),
      });
      setupForm.setFieldsValue({
        filePath,
      });
      setFormValues({
        filePath,
      });
      form.validateFields();
    }
  };

  const handleFileFilter = (inputValue, path) => {
    return path.some(option =>
      option.value.toLowerCase().includes(inputValue.toLowerCase())
    );
  };

  const options = useMemo(
    _ => {
      return folders
        ? folders
            .map(folder => {
              return {
                value: folder.name,
                label: folder.name,
                children: [
                  {
                    value: '',
                    label: '<path prefix>',
                  },
                ].concat(
                  folder.files
                    .map(file => {
                      return {
                        value: file.name,
                        label: file.name,
                      };
                    })
                    .sort((file1, file2) => {
                      if (file1.value.toLowerCase() > file2.value.toLowerCase())
                        return 1;
                      if (file1.value.toLowerCase() < file2.value.toLowerCase())
                        return -1;
                      return 0;
                    })
                ),
              };
            })
            .sort((folder1, folder2) => {
              if (folder1.value.toLowerCase() > folder2.value.toLowerCase())
                return 1;
              if (folder1.value.toLowerCase() < folder2.value.toLowerCase())
                return -1;
              return 0;
            })
        : [];
    },
    [folders]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={{}}
      onFinish={onFinish}
      preserve={false}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={KifsImage}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt="KiFS"
          />
        </Col>
        <Col span={16}>
          <p>
            You can easily import files managed by the{' '}
            <strong>Kinetica Filesystem</strong> (KiFS). If you have not already
            done so, you can upload the file you want to import using the Files
            Explorer panel to the left.
          </p>
          <h3>Directions</h3>
          <p>
            Select an existing file from the Kinetica Filesystem and click next
            to continue.
            <br />
            <br />
            <strong>Path Prefix</strong>
            <br />
            To instead load multiple files by prefix matching, please select
            folder then '&lt;path prefix&gt;'. On the next screen, you can
            modify the path.
          </p>
          <Divider dashed />
        </Col>
      </Row>
      <Form.Item
        label="File"
        name="file"
        rules={[
          {
            required: true,
            message: 'Please select a file!',
          },
        ]}
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <Cascader
          options={options}
          onChange={handleFileChange}
          showSearch={{ handleFileFilter }}
          placeholder="Please search and select a file"
          allowClear={false}
        />
      </Form.Item>
    </Form>
  );
};

const SourceKifs = () => {
  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.filepath) {
          const fileRegex = /kifs:\/\/(.*)\/(.*)/gi;
          const matches = fileRegex.exec(location.state.filepath);
          if (matches && matches.length >= 2) {
            setActiveTabKey('import');
          }
        }
      }
    },
    [location]
  );

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportForm setActiveTabKey={setActiveTabKey}>
                  {props => {
                    return <Setup {...props} />;
                  }}
                </ImportForm>
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={Math.floor((window.innerHeight - 450) / 50)}
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [topBarCollapsed]
  );

  return (
    <div>
      <img
        src={KifsImage}
        style={{
          height: '35px',
          display: 'inline-block',
          verticalAlign: 'top',
          marginRight: '10px',
        }}
        alt="Kinetica Filesystem"
      />
      <h2 style={{ display: 'inline-block' }}>Kinetica Filesystem Import</h2>
      <Spin indicator={<Spinner />} spinning={false}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceKifs;
