// Imports

// App Imports
import {
  AUTH_SET_USER,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_RESPONSE,
  AUTH_USER_REQUEST,
  AUTH_USER_RESPONSE,
  AUTH_LOGOUT,
} from './actions';

// Initial State
export const initialState = {
  error: null,
  loginError: null,
  isUserLoading: false,
  isUserLoaded: false,
  isAuthenticating: false,
  isAuthenticated: false,
  user: null,
};

// State
export const auth = (state = initialState, action) => {
  switch (action.type) {
    case AUTH_SET_USER:
      return {
        ...state,
        user: action.user,
      };

    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        loginError: null,
        isAuthenticating: true,
        isAuthenticated: false,
      };

    case AUTH_LOGIN_RESPONSE:
      return {
        ...state,
        loginError: action.error || null,
        isAuthenticating: false,
        isAuthenticated: true,
      };

    case AUTH_USER_REQUEST:
      return {
        ...state,
        error: null,
        isUserLoading: true,
        isUserLoaded: false,
      };

    case AUTH_USER_RESPONSE:
      return {
        ...state,
        error: action.error || null,
        isUserLoading: false,
        isUserLoaded: true,
      };

    case AUTH_LOGOUT:
      return initialState;

    default:
      return state;
  }
};
