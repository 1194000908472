// Imports

// App Imports

// Actions Types
export const BLOCKS_UPDATE_RESULT = 'BLOCKS/UPDATE_RESULT';

// Actions

export function addOrUpdateBlockResult(blockId, results) {
  return async dispatch => {
    dispatch({
      type: BLOCKS_UPDATE_RESULT,
      blockId,
      results,
    });
  };
}
