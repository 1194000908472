// Imports
import React, { useState, useMemo, useCallback } from 'react';
import { Space, Button, Popconfirm, Alert, Empty } from 'antd';
import {
  SettingOutlined,
  CloseOutlined,
  FileImageOutlined,
} from '@ant-design/icons';
import { ResponsivePie } from '@nivo/pie';
import shortid from 'shortid';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';
import { VIZ_X_AXIS_DATA_LIMIT } from '../../constants';
import { downloadPng } from '../../helper';

const VizPieChart = ({
  viz,
  data,
  columns,
  handleUpdate,
  handleRemove,
  minHeight = 180,
}) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const chartData = useMemo(
    _ => {
      return data.column_1
        .map((column_1, colIdx) => {
          return data.column_headers.reduce((acc, cur, headerIdx) => {
            if (cur === config.series_column) {
              acc['id'] = data[`column_${headerIdx + 1}`][colIdx];
              acc['label'] = data[`column_${headerIdx + 1}`][colIdx];
            } else if (cur === config.value_column) {
              acc['value'] = data[`column_${headerIdx + 1}`][colIdx];
            }
            return acc;
          }, {});
        })
        .map(record => {
          return {
            id: record.id || '',
            label: record.lavel || '',
            value: record.value || 0,
          };
        })
        .filter(record => record.id);
    },
    [data, config]
  );

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  const limitExceeded = useMemo(
    _ => {
      return chartData.length > VIZ_X_AXIS_DATA_LIMIT;
    },
    [chartData]
  );

  const imageId = useMemo(_ => {
    return `image_${shortid.generate()}`;
  }, []);

  const handleDownloadPng = useCallback(
    _ => {
      downloadPng(imageId);
    },
    [imageId]
  );

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<FileImageOutlined />}
              onClick={handleDownloadPng}
              size="small"
            >
              Save PNG
            </Button>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
            <Popconfirm
              title="Are you sure you want to delete this visualization?"
              onConfirm={handleRemove}
            >
              <Button icon={<CloseOutlined />} size="small"></Button>
            </Popconfirm>
          </Space>
          {limitExceeded && (
            <Alert
              message={`Data points limit exceeded. Only ${VIZ_X_AXIS_DATA_LIMIT} will be displayed.`}
              style={{
                padding: '3px 10px',
                fontSize: '12px',
                width: 'calc(100% - 240px)',
              }}
              banner
            />
          )}
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <VizTitleBar config={config} />
        <div
          id={imageId}
          className="pie-chart"
          style={{ height: minHeight - 20, textAlign: 'center' }}
        >
          {config?.series_column && config?.value_column ? (
            <>
              {config?.value_label ? (
                <label
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                    width: '250px',
                    textAlign: 'center',
                    backgroundColor: '#f6f6f6',
                    borderRadius: '5px',
                    padding: '3px 8px',
                    right: 'calc(50% - 125px)',
                  }}
                >
                  {config?.value_label}
                </label>
              ) : (
                <label
                  style={{
                    display: 'inline-block',
                    fontSize: '12px',
                    position: 'absolute',
                    width: '250px',
                    textAlign: 'center',
                    borderRadius: '5px',
                    padding: '3px 8px',
                    right: 'calc(50% - 125px)',
                  }}
                ></label>
              )}
              <ResponsivePie
                data={chartData.slice(0, VIZ_X_AXIS_DATA_LIMIT)}
                margin={{
                  top: config?.value_label ? 35 : 30,
                  right: 10,
                  bottom: config?.value_label ? 55 : 30,
                  left: 10,
                }}
                innerRadius={0.2}
                padAngle={2}
                cornerRadius={1}
                sortByValue={true}
                colors={{ scheme: config.color_scheme || 'spectral' }}
                borderWidth={2}
                borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
                radialLabelsSkipAngle={0}
                radialLabelsLinkDiagonalLength={10}
                radialLabelsLinkHorizontalLength={10}
                radialLabelsTextColor="#333333"
                radialLabelsLinkColor={{ from: 'color' }}
                sliceLabelsSkipAngle={10}
                sliceLabelsRadiusOffset={0.5}
                sliceLabelsTextColor="#333333"
              />
            </>
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure Chart"
            />
          )}
          {!readOnly && (
            <VizConfigDrawer
              title="Pie Chart Configuration"
              fields={viz?.visualization_type?.params?.fields}
              viz={viz}
              config={viz?.config}
              options={{
                columns,
              }}
              isOpen={isVizConfigOpen}
              handleClose={handleCloseVizConfig}
              handleUpdate={handleUpdateVizConfig}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VizPieChart;
