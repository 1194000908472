// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_K8S_KINETICACLUSTERS = gql`
  query K8sKineticaClusters($deployment_type: String) {
    k8s_kineticaclusters(deployment_type: $deployment_type) {
      kind
      apiVersion
      metadata
      spec
      status
      license_info {
        key
        type
        status
        expiration
      }
    }
  }
`;

export const UPDATE_K8S_KINETICACLUSTER_BY_NAME = gql`
  mutation UpdateK8sKineticaClusterByName($name: String!, $body: JSON!) {
    kineticaClusterUpdateByName(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaClusters = options => {
  return useQuery(GET_K8S_KINETICACLUSTERS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useUpdateK8SKineticaClusterByName = () => {
  return useMutation(UPDATE_K8S_KINETICACLUSTER_BY_NAME);
};
