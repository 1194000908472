import React, { Component, useEffect, useRef } from 'react';
import * as monaco from 'monaco-editor';
import { v4 as uuid } from 'uuid';

function extractKeys(obj, keys) {
  return Object.entries(obj)
    .filter(entry => keys.includes(entry[0]))
    .reduce((acc, entry) => {
      acc[entry[0]] = entry[1];
      return acc;
    }, {});
}

const MONACO_EDITOR_OPTIONS = [
  'acceptSuggestionOnCommitCharacter',
  'acceptSuggestionOnEnter',
  'accessibilityHelpUrl',
  'accessibilityPageSize',
  'accessibilitySupport',
  'ariaLabel',
  'autoClosingBrackets',
  'autoClosingOvertype',
  'autoClosingQuotes',
  'autoIndent',
  'autoSurround',
  'automaticLayout',
  'codeActionsOnSaveTimeout',
  'codeLens',
  'colorDecorators',
  'comments',
  'contextmenu',
  'copyWithSyntaxHighlighting',
  'cursorBlinking',
  'cursorSmoothCaretAnimation',
  'cursorStyle',
  'cursorSurroundingLines',
  'cursorSurroundingLinesStyle',
  'cursorWidth',
  'detectIndentation',
  'dimension',
  'disableLayerHinting',
  'disableMonospaceOptimizations',
  'dragAndDrop',
  'emptySelectionClipboard',
  'extraEditorClassName',
  'fastScrollSensitivity',
  'find',
  'fixedOverflowWidgets',
  'folding',
  'foldingHighlight',
  'foldingStrategy',
  'fontFamily',
  'fontLigatures',
  'fontSize',
  'fontWeight',
  'formatOnPaste',
  'formatOnType',
  'glyphMargin',
  'gotoLocation',
  'hideCursorInOverviewRuler',
  'highlightActiveIndentGuide',
  'hover',
  'inDiffEditor',
  'insertSpaces',
  'language',
  'largeFileOptimizations',
  'letterSpacing',
  'lightbulb',
  'lineDecorationsWidth',
  'lineHeight',
  'lineNumbers',
  'lineNumbersMinChars',
  'links',
  'matchBrackets',
  'maxTokenizationLineLength',
  'minimap',
  'model',
  'mouseStyle',
  'mouseWheelScrollSensitivity',
  'mouseWheelZoom',
  'multiCursorMergeOverlapping',
  'multiCursorModifier',
  'multiCursorPaste',
  'occurrencesHighlight',
  'overviewRulerBorder',
  'overviewRulerLanes',
  'parameterHints',
  'peekWidgetDefaultFocus',
  'quickSuggestions',
  'quickSuggestionsDelay',
  'readOnly',
  'renderControlCharacters',
  'renderFinalNewline',
  'renderIndentGuides',
  'renderLineHighlight',
  'renderValidationDecorations',
  'renderWhitespace',
  'revealHorizontalRightPadding',
  'roundedSelection',
  'rulers',
  'scrollBeyondLastColumn',
  'scrollBeyondLastLine',
  'scrollbar',
  'selectOnLineNumbers',
  'selectionClipboard',
  'selectionHighlight',
  'showFoldingControls',
  'showUnused',
  'smoothScrolling',
  'snippetSuggestions',
  'stablePeek',
  'stopRenderingLineAfter',
  'suggest',
  'suggestFontSize',
  'suggestLineHeight',
  'suggestOnTriggerCharacters',
  'suggestSelection',
  'tabCompletion',
  'tabSize',
  'theme',
  'trimAutoWhitespace',
  'useTabStops',
  'value',
  'wordBasedSuggestions',
  'wordSeparators',
  'wordWrap',
  'wordWrapBreakAfterCharacters',
  'wordWrapBreakBeforeCharacters',
  'wordWrapColumn',
  'wordWrapMinified',
  'wrappingIndent',
  'wrappingStrategy',
];

const MONACO_VIEWZONE_OPTIONS = [
  'afterColumn',
  'afterLineNumber',
  // 'domNode',
  'heightInLines',
  'heightInPx',
  'marginDomNode',
  'minWidthInPx',
  'onComputedHeight',
  'onDomNodeTop',
  'suppressMouseDown',
];

export function MonacoContentWidget(props) {
  const idRef = useRef(uuid());
  const containerRef = useRef(null);
  const contentWidgetRef = useRef(null);

  // Initialize content widget.
  useEffect(
    _ => {
      contentWidgetRef.current = {
        getId() {
          return idRef.current;
        },
        getDomNode() {
          return containerRef.current;
        },
        getPosition() {
          if (!props.preference && !props.lineNumber && !props.column) {
            return null;
          }

          return {
            position: {
              lineNumber: props.lineNumber,
              column: props.column,
            },
            preference: props.preference,
          };
        },
      };
    },
    [props.preference, props.lineNumber, props.column]
  );

  useEffect(
    _ => {
      if (props.editor) {
        // Add widget to editor and rerender
        props.editor.addContentWidget(contentWidgetRef.current);
      }

      return _ => {
        if (props.editor) {
          // Remove widget from editor
          props.editor.removeContentWidget(contentWidgetRef.current);
        }
      };
    },
    [props.editor]
  );
  return (
    <div ref={containerRef} style={props.style}>
      {props.children}
    </div>
  );
}

export function MonacoOverlayWidget(props) {
  const idRef = useRef(uuid());
  const containerRef = useRef(null);
  const overlayWidgetRef = useRef(null);

  // Initialize overlay widget.
  useEffect(
    _ => {
      overlayWidgetRef.current = {
        getId() {
          return idRef.current;
        },
        getDomNode() {
          return containerRef.current;
        },
        getPosition() {
          if (!props.preference && !props.lineNumber && !props.column) {
            return null;
          }

          return {
            position: {
              lineNumber: props.lineNumber,
              column: props.column,
            },
            preference: props.preference,
          };
        },
      };
    },
    [props.preference, props.lineNumber, props.column]
  );

  useEffect(
    _ => {
      if (props.editor) {
        // Add widget to editor and rerender
        props.editor.addContentWidget(overlayWidgetRef.current);
      }

      return _ => {
        if (props.editor) {
          // Remove widget from editor
          props.editor.removeContentWidget(overlayWidgetRef.current);
        }
      };
    },
    [props.editor]
  );
  return (
    <div ref={containerRef} style={props.style}>
      {props.children}
    </div>
  );
}

export function MonacoViewZone(props) {
  const containerRef = useRef(null);
  const viewRef = useRef(null);
  useEffect(
    _ => {
      if (props.editor) {
        props.editor.changeViewZones(changeAccessor => {
          if (viewRef.current) {
            changeAccessor.removeZone(viewRef.current);
          }

          viewRef.current = changeAccessor.addZone({
            ...extractKeys(props, MONACO_VIEWZONE_OPTIONS),
            domNode: containerRef.current,
          });
        });
      }
    },
    // eslint-disable-next-line
    [props, props.editor, ...MONACO_VIEWZONE_OPTIONS.map(key => props[key])]
  );
  return <div ref={containerRef}>{props.children}</div>;
}

export default class MonacoEditor extends Component {
  constructor(props) {
    super(props);
    this.editor = null;
    this.containerRef = React.createRef();
    this.subscriptionRef = React.createRef();
  }

  componentDidMount() {
    this.editor = monaco.editor.create(
      this.containerRef.current,
      extractKeys(this.props, MONACO_EDITOR_OPTIONS)
    );
    this.subscriptionRef.current = this.editor.onDidChangeModelContent(e => {
      if (this.props.onChange) {
        this.props.onChange(this.editor.getValue(), e);
      }
    });
  }

  componentWillUnmount() {
    if (this.editor) {
      const model = this.editor.getModel();
      if (model) {
        model.dispose();
      }
      this.editor.dispose();
      this.subscriptionRef.current.dispose();
    }
  }

  render() {
    return (
      <>
        <div ref={this.containerRef} style={this.props.style}></div>
        {React.Children.map(this.props.children, child =>
          React.isValidElement(child)
            ? React.cloneElement(child, { editor: this.editor })
            : child
        )}
      </>
    );
  }
}
