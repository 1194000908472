// Imports
import { useMemo, useEffect, useState, createRef } from 'react';
import { useSelector } from 'react-redux';
import { Card, Space, Tabs, Tooltip, Button, Select, message } from 'antd';
import { CopyOutlined, SaveOutlined, ApiOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useResizeDetector } from 'react-resize-detector';

// App Imports
import useCloudProvider from '../../hooks/useCloudProvider';
import MonacoEditor from '../../components/editor/MonacoEditor';
import {
  API_SAMPLE_JAVASCRIPT,
  API_SAMPLE_NODEJS,
  API_SAMPLE_PYTHON,
  API_SAMPLE_CPP,
  API_SAMPLE_CS,
  API_SAMPLE_JAVA,
  API_SAMPLE_JDBC,
  API_SAMPLE_JDBC_JAVA,
  API_SAMPLE_REST,
  API_SAMPLE_ZMQ,
  API_SAMPLE_POSTGRES,
  CONNECTOR_DOWNLOADS,
  KISQL_DOWNLOADS,
  API_DOWNLOADS,
} from '../../constants';
import { DEPLOYMENT_TYPE } from '../../setup/config';

const { Option } = Select;

const Connect = ({ clusters, clustersLoading, warehouses }) => {
  const cloudProvider = useCloudProvider();

  const [apiLanguage, setApiLanguage] = useState('python');

  const javascriptEditorRef = createRef();
  const nodejsEditorRef = createRef();
  const pythonEditorRef = createRef();
  const cppEditorRef = createRef();
  const csEditorRef = createRef();
  const javaEditorRef = createRef();
  const jdbcEditorRef = createRef();
  const jdbcJavaEditorRef = createRef();
  const restEditorRef = createRef();
  const zmqEditorRef = createRef();
  const postgresEditorRef = createRef();

  const { width, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const {
    user: { isInternal },
  } = useSelector(state => state.auth);

  const cluster = useMemo(
    _ => {
      return clusters && clusters.length > 0 && clusters[0];
    },
    [clusters]
  );

  const url = useMemo(
    _ => {
      if (cluster) {
        // If cloud and has CR with rank info
        const ranks = cluster?.status?.ingressUrls?.ranks ?? {};
        if (ranks['0']) {
          return ranks['0'];
        }

        // If not cloud, then use warehouse info
        return warehouses && warehouses.length > 0
          ? isInternal
            ? warehouses[0]?.head_api_url
            : warehouses[0]?.head_api_external_url
          : `${window.location.protocol}//127.0.0.1:9191`;
      }
      return `${window.location.protocol}//127.0.0.1:9191`;
    },
    [cluster, warehouses, isInternal]
  );

  useEffect(
    _ => {
      if (javascriptEditorRef?.current) {
        const { editor: javascriptEditor } = javascriptEditorRef?.current;
        javascriptEditor.layout();
        javascriptEditor.setValue(API_SAMPLE_JAVASCRIPT(url));
      }

      if (nodejsEditorRef?.current) {
        const { editor: nodejsEditor } = nodejsEditorRef?.current;
        nodejsEditor.layout();
        nodejsEditor.setValue(API_SAMPLE_NODEJS(url));
      }

      if (pythonEditorRef?.current) {
        const { editor: pythonEditor } = pythonEditorRef?.current;
        pythonEditor.layout();
        pythonEditor.setValue(API_SAMPLE_PYTHON(url));
      }

      if (cppEditorRef?.current) {
        const { editor: cppEditor } = cppEditorRef?.current;
        cppEditor.layout();
        cppEditor.setValue(API_SAMPLE_CPP(url));
      }

      if (csEditorRef?.current) {
        const { editor: csEditor } = csEditorRef?.current;
        csEditor.layout();
        csEditor.setValue(API_SAMPLE_CS(url));
      }

      if (javaEditorRef?.current) {
        const { editor: javaEditor } = javaEditorRef?.current;
        javaEditor.layout();
        javaEditor.setValue(API_SAMPLE_JAVA(url));
      }

      if (jdbcEditorRef?.current) {
        const { editor: jdbcEditor } = jdbcEditorRef?.current;
        jdbcEditor.layout();
        jdbcEditor.setValue(API_SAMPLE_JDBC(url));
      }

      if (jdbcJavaEditorRef?.current) {
        const { editor: jdbcJavaEditor } = jdbcJavaEditorRef?.current;
        jdbcJavaEditor.layout();
        jdbcJavaEditor.setValue(API_SAMPLE_JDBC_JAVA(url));
      }

      if (restEditorRef?.current) {
        const { editor: restEditor } = restEditorRef?.current;
        restEditor.layout();
        restEditor.setValue(API_SAMPLE_REST(url));
      }

      if (zmqEditorRef?.current) {
        clusters.forEach(cluster => {
          const { editor: zmqEditor } = zmqEditorRef?.current;
          zmqEditor.layout();
          zmqEditor.setValue(
            API_SAMPLE_ZMQ(
              cluster?.status?.ingressUrls?.dbTrigger,
              cluster?.status?.ingressUrls?.dbMonitor
            )
          );
        });
      }

      if (postgresEditorRef?.current) {
        clusters.forEach(cluster => {
          const { editor: postgresEditor } = postgresEditorRef?.current;
          postgresEditor.layout();
          postgresEditor.setValue(
            API_SAMPLE_POSTGRES(cluster?.status?.ingressUrls?.postgresProxy)
          );
        });
      }
    },
    [
      javascriptEditorRef,
      nodejsEditorRef,
      pythonEditorRef,
      cppEditorRef,
      csEditorRef,
      javaEditorRef,
      jdbcEditorRef,
      jdbcJavaEditorRef,
      restEditorRef,
      zmqEditorRef,
      postgresEditorRef,
      clusters,
      url,
    ]
  );

  const isClusterRunning = useMemo(
    _ => {
      return (
        clusters &&
        clusters.length > 0 &&
        clusters[0] &&
        clusters[0]?.status?.phase === 'Running'
      );
    },
    [clusters]
  );

  const handleApiLanguageChange = language => {
    setApiLanguage(language);
  };

  const onChange = () => {
    //
  };

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  const docs = useMemo(() => {
    return !cloudProvider.toLowerCase().includes('onprem')
      ? `${cloudProvider}/`
      : '';
  }, [cloudProvider]);

  const tabItems = [
    {
      key: '1',
      label: 'API',
      children: (
        <Space direction="vertical" size={20}>
          {warehouses && warehouses.length > 0 && (
            <div style={{ padding: '20px 10px 0px' }}>
              <a
                href={`https://docs.kinetica.com/7.1/${docs}api/`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ float: 'right' }}
              >
                Documentation
              </a>
              <h4>API Code Samples</h4>
              <Space direction="vertical" size={20}>
                <div>
                  {(_ => {
                    let text = '';
                    switch (apiLanguage) {
                      case 'javascript':
                        text = API_SAMPLE_JAVASCRIPT(url) ?? '';
                        break;
                      case 'nodejs':
                        text = API_SAMPLE_NODEJS(url) ?? '';
                        break;
                      case 'python':
                        text = API_SAMPLE_PYTHON(url) ?? '';
                        break;
                      case 'cpp':
                        text = API_SAMPLE_CPP(url) ?? '';
                        break;
                      case 'cs':
                        text = API_SAMPLE_CS(url) ?? '';
                        break;
                      case 'java':
                        text = API_SAMPLE_JAVA(url) ?? '';
                        break;
                      case 'jdbc':
                        text = API_SAMPLE_JDBC(url) ?? '';
                        break;
                      case 'jdbcJava':
                        text = API_SAMPLE_JDBC_JAVA(url) ?? '';
                        break;
                      case 'rest':
                        text = API_SAMPLE_REST(url) ?? '';
                        break;
                      case 'zmq':
                        clusters.forEach(cluster => {
                          text =
                            API_SAMPLE_ZMQ(
                              cluster?.status?.ingressUrls?.dbTrigger,
                              cluster?.status?.ingressUrls?.dbMonitor
                            ) ?? '';
                        });
                        break;
                      case 'postgres':
                        clusters.forEach(cluster => {
                          text =
                            API_SAMPLE_POSTGRES(
                              cluster?.status?.ingressUrls?.postgresProxy
                            ) ?? '';
                        });
                        break;
                      default:
                      // Use default
                    }

                    return (
                      <Tooltip title="Copy Code">
                        <CopyToClipboard
                          text={text}
                          onCopy={() => {
                            message.success('Copied to clipboard!');
                          }}
                        >
                          <Button
                            icon={<CopyOutlined />}
                            style={{ float: 'right' }}
                          />
                        </CopyToClipboard>
                      </Tooltip>
                    );
                  })()}
                  {(_ => {
                    const downloadUnavailable =
                      !isClusterRunning && !clustersLoading;
                    const download = API_DOWNLOADS.find(
                      api => api.type === apiLanguage
                    );
                    return isCloud ? (
                      <Tooltip
                        title={
                          !downloadUnavailable
                            ? 'Download'
                            : 'Cluster must be running to enable download links'
                        }
                      >
                        <Button
                          href={
                            download
                              ? `${
                                  cluster?.status?.ingressUrls?.files ?? '/'
                                }/${download.url_path}`
                              : ''
                          }
                          target="_blank"
                          rel="noopener noreferrer"
                          icon={<SaveOutlined />}
                          style={{
                            float: 'right',
                            marginRight: '5px',
                          }}
                          disabled={!download || downloadUnavailable}
                        />
                      </Tooltip>
                    ) : (
                      ''
                    );
                  })()}
                  <Select
                    value={apiLanguage}
                    onChange={handleApiLanguageChange}
                    style={{
                      width: isCloud
                        ? 'calc(100% - 75px)'
                        : 'calc(100% - 35px)',
                    }}
                  >
                    <Option value="python">Python</Option>
                    <Option value="javascript">JavaScript</Option>
                    <Option value="nodejs">Node.js</Option>
                    <Option value="cpp">C++</Option>
                    <Option value="cs">C#</Option>
                    <Option value="java">Java</Option>
                    <Option value="jdbc">JDBC (Connection String)</Option>
                    <Option value="jdbcJava">JDBC (Java)</Option>
                    <Option value="rest">REST (curl)</Option>
                    <Option value="zmq">ZMQ</Option>
                    {cluster?.status?.ingressUrls?.postgresProxy && (
                      <Option value="postgres">Postgres</Option>
                    )}
                  </Select>
                </div>
                <div
                  ref={ref}
                  style={{
                    border: '1px solid #f3f3f3',
                    padding: '5px',
                    backgroundColor: '#f9f9f9',
                  }}
                >
                  {apiLanguage === 'javascript' && (
                    <MonacoEditor
                      ref={javascriptEditorRef}
                      language="html"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'nodejs' && (
                    <MonacoEditor
                      ref={nodejsEditorRef}
                      language="javascript"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'python' && (
                    <MonacoEditor
                      ref={pythonEditorRef}
                      language="python"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'cpp' && (
                    <MonacoEditor
                      ref={cppEditorRef}
                      language="cpp"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'cs' && (
                    <MonacoEditor
                      ref={csEditorRef}
                      language="csharp"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'java' && (
                    <MonacoEditor
                      ref={javaEditorRef}
                      language="java"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'jdbc' && (
                    <MonacoEditor
                      ref={jdbcEditorRef}
                      language="java"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'jdbcJava' && (
                    <MonacoEditor
                      ref={jdbcJavaEditorRef}
                      language="java"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'rest' && (
                    <MonacoEditor
                      ref={restEditorRef}
                      language="shell"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'zmq' && (
                    <MonacoEditor
                      ref={zmqEditorRef}
                      language="python"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                  {apiLanguage === 'postgres' && (
                    <MonacoEditor
                      ref={postgresEditorRef}
                      language="python"
                      lineNumbers="off"
                      readOnly="true"
                      minimap={{ enabled: false }}
                      fontSize={12}
                      folding="false"
                      renderLineHighlight="none"
                      contextmenu={false}
                      links={false}
                      overviewRulerLanes={0}
                      scrollBeyondLastLine={false}
                      style={{
                        width: width ? width - 10 : width,
                        height: '195px',
                      }}
                    ></MonacoEditor>
                  )}
                </div>
              </Space>
            </div>
          )}
          {isCloud && (
            <div style={{ padding: '0px 10px 0px' }}>
              <h4>Connector Downloads</h4>
              {CONNECTOR_DOWNLOADS.map(connector => {
                return (
                  <a
                    key={connector.name}
                    href={`${cluster?.status?.ingressUrls?.files ?? '/'}/${
                      connector.url_path
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'inline-block',
                      margin: '0px 16px 6px 0px',
                    }}
                  >
                    {connector.name}
                  </a>
                );
              })}
            </div>
          )}
        </Space>
      ),
    },
    {
      key: '2',
      label: 'CLI Tools',
      children: (
        <Space direction="vertical" size={20}>
          <div style={{ padding: '20px 10px 0px' }}>
            <h4>Downloads</h4>
            {KISQL_DOWNLOADS.map(download => {
              return (
                <a
                  key={download.name}
                  href={`https://github.com/kineticadb/kisql/raw/release/${download.url_path}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'inline-block',
                    margin: '0px 16px 6px 0px',
                  }}
                >
                  {download.name}
                </a>
              );
            })}
            <ul
              style={{
                listStyle: 'none',
                margin: '0px',
                padding: '0px',
              }}
            >
              <li
                style={{
                  fontSize: '14px',
                  padding: '8px 10px',
                  margin: '5px 0px 0px',
                  backgroundColor: '#f6f6f6',
                  borderRadius: '5px',
                  borderLeft: '5px solid #dddddd',
                }}
              >
                Mac/Linux will require JRE to be installed, while Windows is
                bundled.
              </li>
            </ul>
          </div>
          <div style={{ padding: '0px 10px 0px' }}>
            <h4>Docs & API</h4>
            <a
              href="https://docs.kinetica.com/7.1/tools/kisql/"
              target="_blank"
              rel="noopener noreferrer"
              style={{ display: 'block' }}
            >
              Documentation
            </a>
          </div>
          <div style={{ padding: '0px 10px 0px' }}>
            <h4>Connection String</h4>
            <div
              style={{
                backgroundColor: '#f9f9f9',
                padding: '5px 10px',
                border: '1px solid #f3f3f3',
              }}
            >
              <code
                style={{
                  fontSize: '12px',
                  overflowWrap: 'anywhere',
                }}
              >{`./kisql --url "${url}" --user "myusername"`}</code>
            </div>
          </div>
        </Space>
      ),
    },
  ];

  return (
    <Card
      title={
        <>
          <ApiOutlined style={{ marginRight: 10 }} />
          Connect
        </>
      }
      bordered={false}
      size="small"
      style={{
        height: '530px',
        overflow: 'hidden',
        borderRadius: 5,
      }}
      headStyle={{
        color: '#ffffff',
        fontSize: '20px',
        background:
          'linear-gradient(150deg, #9b45d899 15%, #5c48c199 70%, #5533ff99 94%)',
      }}
    >
      <div style={{ padding: '10px 20px' }}>
        <Tabs onChange={onChange} type="card" items={tabItems}></Tabs>
      </div>
    </Card>
  );
};

export default Connect;
