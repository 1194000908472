// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_USER_ROLE_OPTIONS = gql`
  query {
    roles {
      id
      name
      description
      created_at
      updated_at
    }
    resource_groups {
      name
      alias
      max_data
      max_tier_priority
      max_scheduling_priority
      max_cpu_concurrency
      tier_attributes {
        name
        max_memory
      }
      usage
    }
    sql_procedures {
      procedure_name
      procedure_definition
      additional_info {
        execute_interval
        execute_start_time
      }
    }
    datasources {
      datasource_name
      additional_info
      storage_provider_type
    }
    datasinks {
      datasink_name
      additional_info
      destination_type
    }
    schemas {
      name
    }
    tables {
      schema
      name
      additional_info
      full
      size
    }
    folders {
      id
      name
      user
      permission
      data_usage
      data_limit
      files {
        id
        name
        folder
        path
        size
        user
        creation_time
      }
      creation_time
    }
  }
`;

export const useGetUserRoleOptions = () => {
  return useQuery(GET_USER_ROLE_OPTIONS);
};
