// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_K8S_KINETICAGRANTS = gql`
  query K8sKineticaGrants {
    k8s_kineticagrants {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAGRANT = gql`
  mutation CreateK8sKineticaGrant($name: String!, $body: JSON!) {
    kineticaGrantCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const REMOVE_K8S_KINETICAGRANT_BY_NAME = gql`
  mutation RemoveK8sKineticaGrant($name: String!) {
    kineticaGrantRemoveByName(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaGrants = () => {
  return useQuery(GET_K8S_KINETICAGRANTS);
};

export const useCreateK8SKineticaGrant = () => {
  return useMutation(CREATE_K8S_KINETICAGRANT);
};

export const useRemoveK8SKineticaGrantByName = () => {
  return useMutation(REMOVE_K8S_KINETICAGRANT_BY_NAME);
};
