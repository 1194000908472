// Imports
import React, { useEffect, useMemo, useCallback } from 'react';
import { Modal, Button, Spin, Descriptions, Table, Divider } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import { formatFriendlyNumberWhole, bytesToMegabytes } from '../../formatter';

const TableInfoModal = ({ table, visible, setVisible, width, height }) => {
  const { schema, name } = table;
  const { loading: tableLoading, data } =
    GraphQLServices.Tables.useGetTableByName({
      variables: {
        schema,
        name,
      },
    });

  const graphqlClient = useApolloClient();

  const columns = useMemo(
    _ => {
      if (data?.table?.columns) {
        return data?.table?.columns.map(column => {
          const {
            index,
            name,
            type,
            properties,
            compression,
            memory,
            indexMemory,
          } = column;
          return {
            key: name,
            id: name,
            name,
            type,
            properties,
            compression,
            memory,
            indexMemory,
            index,
          };
        });
      }
      return [];
    },
    [data]
  );

  const waitFor = useCallback(
    async (condFunc, maxWait, waitInterval, size = 0) => {
      console.debug(
        `Refreshing count every ${waitInterval} ms, Current count is ${size}`
      );
      return new Promise(async resolve => {
        const resp = await condFunc(size);
        if (resp.done) {
          resolve(true);
        } else if (maxWait > 0) {
          setTimeout(async () => {
            const promiseResp = await waitFor(
              condFunc,
              maxWait - resp.sizeRate,
              resp.sizeRate,
              resp.size
            );
            resolve(promiseResp);
          }, waitInterval);
        } else {
          resolve(false);
        }
      });
    },
    []
  );

  useEffect(
    _ => {
      let done = false;
      const refreshTableSize = async _ => {
        const condFunc = async (currentSize = 0) => {
          const resp = await graphqlClient.query({
            query: GraphQLServices.Tables.GET_TABLE_BY_NAME,
            variables: {
              schema,
              name,
            },
          });
          return {
            done,
            size: resp?.data?.table?.size,
            sizeRate: currentSize !== resp?.data?.table?.size ? 2000 : 5000,
          };
        };
        await waitFor(condFunc, 21600000, 2000);
      };
      refreshTableSize();

      return _ => {
        done = true;
      };
    },
    [graphqlClient, name, schema, waitFor]
  );

  return (
    <Modal
      title={`Table Info: ${table.full}`}
      open={visible || tableLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={tableLoading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'auto',
          }}
        >
          {data?.table && (
            <>
              <Descriptions title="Summary" size="small" bordered>
                <Descriptions.Item label="Records">
                  {formatFriendlyNumberWhole(data?.table?.size)}
                </Descriptions.Item>
                <Descriptions.Item label="Type">
                  {data?.table?.type}
                </Descriptions.Item>
                <Descriptions.Item label="Distribution">
                  {data?.table?.distribution}
                </Descriptions.Item>
                <Descriptions.Item label="Features">
                  {data?.table?.features.join(',')}
                </Descriptions.Item>
                <Descriptions.Item label="Access">
                  {data?.table?.access}
                </Descriptions.Item>
                <Descriptions.Item label="Keys">
                  {data?.table?.keys || <i style={{ color: '#cccccc' }}>n/a</i>}
                </Descriptions.Item>
                <Descriptions.Item label="TTL">
                  {data?.table?.ttl}
                </Descriptions.Item>
                <Descriptions.Item label="Memory (MB)">
                  {bytesToMegabytes(data?.table?.memory)} MB
                </Descriptions.Item>
                <Descriptions.Item label="Chunk Size">
                  {formatFriendlyNumberWhole(data?.table?.chunk_size) ||
                    'Default (8,000,000)'}
                </Descriptions.Item>
              </Descriptions>
              <Divider dashed />
              <h3>Columns</h3>
              <Table dataSource={columns} size="small" pagination={false}>
                <Table.Column
                  title="#"
                  dataIndex="index"
                  key="index"
                  width={40}
                />
                <Table.Column title="Name" dataIndex="name" />
                <Table.Column title="Type" dataIndex="type" />
                <Table.Column
                  title="Properties"
                  dataIndex="properties"
                  render={(value, record) => {
                    return value.join(', ');
                  }}
                />
                <Table.Column
                  title="Compress"
                  dataIndex="compression"
                  render={(value, record) => {
                    return value || <i style={{ color: '#cccccc' }}>n/a</i>;
                  }}
                />
                <Table.Column
                  title="Memory"
                  dataIndex="memory"
                  render={(value, record) => {
                    return value > 0 ? (
                      <div style={{ textAlign: 'right' }}>
                        {bytesToMegabytes(value)} MB
                      </div>
                    ) : (
                      <div style={{ textAlign: 'right' }}>
                        <i style={{ color: '#cccccc' }}>n/a</i>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title="Index"
                  dataIndex="indexMemory"
                  render={(value, record) => {
                    return value > 0 ? (
                      <div style={{ textAlign: 'right' }}>
                        {bytesToMegabytes(value)} MB
                      </div>
                    ) : (
                      <div style={{ textAlign: 'right' }}>
                        <i style={{ color: '#cccccc' }}>n/a</i>
                      </div>
                    );
                  }}
                />
              </Table>
            </>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default TableInfoModal;
