// Imports
import { useMutation, gql } from '@apollo/client';

export const INSERT_RECORDS_FROM_FILES = gql`
  mutation InsertRecordsFromFiles(
    $table_name: String!
    $filepaths: [String]!
    $modify_columns: JSON!
    $create_table_options: JSON!
    $options: JSON!
  ) {
    insertRecordsFromFiles(
      table_name: $table_name
      filepaths: $filepaths
      modify_columns: $modify_columns
      create_table_options: $create_table_options
      options: $options
    ) {
      columns {
        name
        type
        properties
        field_desc
      }
    }
  }
`;

export const INSERT_RECORDS_FROM_QUERY = gql`
  mutation InsertRecordsFromQuery(
    $table_name: String!
    $remote_query: String!
    $modify_columns: JSON!
    $create_table_options: JSON!
    $options: JSON!
  ) {
    insertRecordsFromQuery(
      table_name: $table_name
      remote_query: $remote_query
      modify_columns: $modify_columns
      create_table_options: $create_table_options
      options: $options
    ) {
      columns {
        name
        type
        properties
        field_desc
      }
    }
  }
`;

export const useInsertRecordsFromFiles = () => {
  return useMutation(INSERT_RECORDS_FROM_FILES);
};

export const useInsertRecordsFromQuery = () => {
  return useMutation(INSERT_RECORDS_FROM_QUERY);
};
