// Imports
import React, {
  useCallback,
  useState,
  useEffect,
  useContext,
  useMemo,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Space,
  Button,
  Tree,
  Tooltip,
  Input,
  Spin,
  Dropdown,
  Popconfirm,
  notification,
} from 'antd';
import {
  DownOutlined,
  RedoOutlined,
  PlusOutlined,
  DeleteOutlined,
  ExportOutlined,
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import { useResizeDetector } from 'react-resize-detector';

// App Imports
import { setExplorerPanelWorkbooksExpandedKeys } from '../../store/app/actions';
import GraphQLServices from '../../graphql/services';
import { GET_WORKSHEET_BY_ID } from '../../graphql/schema/worksheets';
import TypeObject from './objects/TypeObject';
import WorkbookObject from './objects/WorkbookObject';
import WorksheetObject from './objects/WorksheetObject';
import { objHasMatch } from '../../helper';
import Spinner from '../common/Spinner';
import { NAV_ROUTE_DATAEXPLORE_WORKBOOK } from '../../constants';
import WorkbookCreateModal from '../modal/WorkbookCreateModal';
import WorkbookEditModal from '../modal/WorkbookEditModal';
import WorkbookImportModal from '../modal/WorkbookImportModal';
import WorksheetEditModal from '../modal/WorksheetEditModal';
import MultiSelectContext from './MultiSelectContext';
import useAnalytics from '../../hooks/useAnalytics';
import { useExportAllJsonWorkbooks } from '../../containers/dataexplore/utils';
import { EXPLORER_PANEL_WIDTH } from '../../constants';
import { UserContext } from '../../context';

const { Search } = Input;

const WorkbookExplorer = ({ offset = 0, refetchUsage = () => {} }) => {
  const { workbookId } = useParams();

  const userMe = useContext(UserContext);

  const {
    loading: workbooksLoading,
    data: objects,
    refetch: refetchWorkbooks,
  } = GraphQLServices.Workbooks.useGetWorkbooksOnly();
  const [removeWorkbookById, { loading: removingWorkbook }] =
    GraphQLServices.Workbooks.useRemoveWorkbookById();
  const exportAllWorkbooks = useExportAllJsonWorkbooks();

  const [entityFilterStr, setEntityFilterStr] = useState('');
  const [workbooksData, setWorkbooksData] = useState([]);
  const [exampleWorkbooksData, setExampleWorkbooksData] = useState([]);
  const [sharedWorkbooksData, setSharedWorkbooksData] = useState([]);

  const { explorerPanelExpandedKeys } = useSelector(state => state.app);
  const [expandedKeys, setExpandedKeys] = useState([
    'data.workbooks.personal',
    'data.workbooks.examples',
    'data.workbooks.shared',
    ...explorerPanelExpandedKeys.workbooks,
  ]);

  const [showWorkbookCreateModal, setShowWorkbookCreateModal] = useState(false);
  const [showWorkbookEditModal, setShowWorkbookEditModal] = useState(false);
  const [showWorkbookImportModal, setShowWorkbookImportModal] = useState(false);
  const [currentEditWorkbook, setCurrentEditWorkbook] = useState(undefined);
  const [showWorksheetEditModal, setShowWorksheetEditModal] = useState(false);
  const [currentEditWorksheet, setCurrentEditWorksheet] = useState(undefined);

  const [isMultiSelect, setIsMultiSelect] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { height, ref } = useResizeDetector({
    refreshMode: 'debounce',
    refreshRate: 200,
  });

  const analytics = useAnalytics();
  const graphqlClient = useApolloClient();

  const filterWorkbook = (data, filterStr) => {
    if (filterStr === '') {
      return data;
    }
    return data
      .map(item => {
        return Object.keys(item).reduce((acc, cur) => {
          if (Array.isArray(item[cur])) {
            acc[cur] = item[cur].filter(item2 => {
              return objHasMatch(item2, filterStr, ['name', 'description']);
            });
          } else {
            acc[cur] = item[cur];
          }
          return acc;
        }, {});
      })
      .filter(item => {
        return objHasMatch(item, filterStr, ['name', 'description']);
      });
  };

  useEffect(
    _ => {
      const workbooksData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook =>
                  workbook.user.id === userMe.id && !workbook.is_example
              )
              .reduce((acc, cur) => {
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `workbook.${cur.id}`,
                  children: [],
                  source: cur,
                  selectable: false,
                });
                return acc;
              }, [])
          : [];
      setWorkbooksData(workbooksData);

      const exampleWorkbookData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook =>
                  workbook.user.id === userMe.id && workbook.is_example
              )
              .reduce((acc, cur) => {
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `workbook.${cur.id}`,
                  children: [],
                  source: cur,
                  selectable: false,
                  disableCheckbox: true,
                });
                return acc;
              }, [])
          : [];
      setExampleWorkbooksData(exampleWorkbookData);

      const sharedWorkbookData =
        objects && objects.workbooks && userMe
          ? objects.workbooks
              .filter(
                workbook => workbook.user.id !== userMe.id && workbook.is_shared
              )
              .reduce((acc, cur) => {
                acc.push({
                  id: cur.id,
                  title: cur.name || '< root >',
                  key: `workbook.${cur.id}`,
                  children: [],
                  source: cur,
                  sharedSection: true,
                  selectable: false,
                  disableCheckbox: true,
                });
                return acc;
              }, [])
          : [];
      setSharedWorkbooksData(sharedWorkbookData);
    },
    [objects, userMe]
  );

  const treeData = [
    {
      title: 'My Workbooks',
      key: 'data.workbooks.personal',
      children: filterWorkbook(workbooksData, entityFilterStr),
      selectable: false,
      checkable: false,
    },
    {
      title: 'Examples',
      key: 'data.workbooks.examples',
      children: filterWorkbook(exampleWorkbooksData, entityFilterStr),
      selectable: false,
      checkable: false,
    },
    {
      title: 'Shared With Me',
      key: 'data.workbooks.shared',
      children: filterWorkbook(sharedWorkbooksData, entityFilterStr),
      selectable: false,
      checkable: false,
    },
  ];

  const handleTreeCheck = (checkedKeys, e) => {
    setCheckedKeys(checkedKeys);
  };

  const handleMultiSelect = useCallback(
    node => {
      setCheckedKeys([node.key]);
      setIsMultiSelect(!isMultiSelect);
    },
    [isMultiSelect]
  );

  const handleCancelMultiSelect = _ => {
    setCheckedKeys([]);
    setIsMultiSelect(false);
  };

  const handleDeleteSelected = _ => {
    Promise.all(
      checkedKeys.map(key => {
        const type = key.split('.')[0];
        if (type === 'workbook') {
          const id = key.split('.')[1];
          return removeWorkbookById({
            variables: {
              id,
            },
          });
        }
        return Promise.resolve();
      })
    )
      .then(responses => {
        notification.success({
          message: 'Success',
          description: `Selected workbook(s) deleted successfully!`,
        });
        refetchWorkbooks();
        handleCancelMultiSelect();
      })
      .catch(errors => {
        console.error(errors);
      });
  };

  const handleAddMenuClick = type => {
    switch (type.key) {
      case 'newworkbook': {
        setShowWorkbookCreateModal(true);
        return;
      }
      case 'importworkbook': {
        setShowWorkbookImportModal(true);
        return;
      }
      default: {
        return;
      }
    }
  };

  const handleEditMenuClick = useCallback(
    workbook => e => {
      setCurrentEditWorkbook(workbook);
      setShowWorkbookEditModal(true);
    },
    []
  );

  const handleEditWorksheetMenuClick = useCallback(
    worksheet => e => {
      setCurrentEditWorksheet(worksheet);
      setShowWorksheetEditModal(true);
    },
    []
  );

  const handleTreeExpand = useCallback(
    (current, { expanded, node }) => {
      if (expanded) {
        // Add to expandedKeys
        const added = [...expandedKeys, node.key];
        const update = [...new Set(added)];
        setExpandedKeys(update);
        dispatch(setExplorerPanelWorkbooksExpandedKeys(update));
      } else {
        // Remove from expandedKeys
        const copy = [...expandedKeys];
        copy.splice(copy.indexOf(node.key), 1);
        const update = [...copy];
        setExpandedKeys(update);
        dispatch(setExplorerPanelWorkbooksExpandedKeys(update));
      }
    },
    [expandedKeys, dispatch]
  );

  const handleWorkbookCreateCallback = async (err, resp) => {
    if (resp) {
      const block = resp?.data?.blockCreate;
      const { worksheet_id } = block;

      const worksheetResp = await graphqlClient.query({
        query: GET_WORKSHEET_BY_ID,
        variables: {
          id: worksheet_id,
        },
      });

      refetchWorkbooks().then(resp => {
        const currentWorkbook = resp?.data?.workbooks.find(
          workbook => workbook.id === worksheetResp?.data?.worksheet.workbook_id
        );
        setShowWorkbookCreateModal(false);

        analytics.track(analytics.EVENT_TYPES.CREATED_WORKBOOK)({});

        history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${currentWorkbook.id}`);
      });
    } else {
      console.error(err);
    }
  };

  const handleWorkbookEditCallback = (err, resp) => {
    if (resp) {
      setCurrentEditWorkbook(undefined);
      setShowWorkbookEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleWorkbookImportCallback = (err, resp) => {
    if (resp) {
      if (resp?.data?.workbookImport) {
        const { id } = resp?.data?.workbookImport?.[0];
        refetchWorkbooks().then(_ => {
          setShowWorkbookImportModal(false);
          history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${id}`);
        });
      } else if (resp?.data?.workbookCreate) {
        const { id } = resp?.data?.workbookCreate;
        refetchWorkbooks().then(_ => {
          setShowWorkbookImportModal(false);
          history.push(`${NAV_ROUTE_DATAEXPLORE_WORKBOOK}/${id}`);
        });
      }
    } else {
      console.error(err);
    }
  };

  const handleWorksheetEditCallback = (err, resp) => {
    if (resp) {
      setCurrentEditWorksheet(undefined);
      setShowWorksheetEditModal(false);
    } else {
      console.error(err);
    }
  };

  const handleTitleRender = useCallback(
    nodeData => {
      if (nodeData.key.startsWith('data.')) {
        return <TypeObject nodeData={nodeData} refresh={refetchWorkbooks} />;
      } else if (nodeData.key.startsWith('workbook.')) {
        return (
          <WorkbookObject
            nodeData={nodeData}
            refresh={refetchWorkbooks}
            editClick={handleEditMenuClick}
            current={workbookId}
            handleMultiSelect={handleMultiSelect}
          />
        );
      } else if (nodeData.key.startsWith('worksheet.')) {
        return (
          <WorksheetObject
            nodeData={nodeData}
            refresh={refetchWorkbooks}
            editClick={handleEditWorksheetMenuClick}
          />
        );
      }
    },
    [
      refetchWorkbooks,
      handleEditMenuClick,
      handleEditWorksheetMenuClick,
      handleMultiSelect,
      workbookId,
    ]
  );

  const addMenu = useMemo(_ => {
    return {
      items: [
        {
          key: 'newworkbook',
          label: 'Add New Workbook',
        },
        {
          key: 'importworkbook',
          label: 'Import Workbook (JSON/SQL)',
        },
      ],
      onClick: handleAddMenuClick,
    };
  }, []);

  useEffect(
    _ => {
      if (objects) {
        const { workbooks } = objects;

        analytics.track(analytics.EVENT_TYPES.WORKBOOK_COUNT)({
          workbooks: workbooks.length,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [objects]
  );

  return (
    <div
      className="workbooks-explorer"
      style={{
        padding: '15px',
        height: `calc(100vh - ${100 + offset}px)`,
      }}
    >
      <Space direction="vertical" style={{ width: '100%' }}>
        <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
          <Space>
            <Search
              placeholder="Workbook search"
              value={entityFilterStr}
              onChange={e => setEntityFilterStr(e.target.value)}
              allowClear
            />
            <Tooltip title="Refresh">
              <Button
                icon={<RedoOutlined spin={workbooksLoading} />}
                onClick={() => {
                  refetchWorkbooks();
                  refetchUsage();
                }}
              />
            </Tooltip>
            <Tooltip title="Export All Workbooks JSON">
              <Popconfirm
                title="Are you sure you want to export all workbooks?"
                onConfirm={exportAllWorkbooks}
              >
                <Button icon={<ExportOutlined />} />
              </Popconfirm>
            </Tooltip>
            <Dropdown menu={addMenu}>
              <Button icon={<PlusOutlined />} />
            </Dropdown>
          </Space>
        </div>
        {isMultiSelect && (
          <div style={{ width: `${EXPLORER_PANEL_WIDTH - 38}px` }}>
            <Space>
              <Popconfirm
                title="Are you sure you want to delete selected workbook(s)?"
                placement="topRight"
                onConfirm={() => handleDeleteSelected()}
                disabled={checkedKeys.length === 0 || removingWorkbook}
              >
                <Button
                  type="primary"
                  icon={<DeleteOutlined />}
                  style={{ width: `${EXPLORER_PANEL_WIDTH - 160}px` }}
                  loading={false}
                  disabled={checkedKeys.length === 0 || removingWorkbook}
                  danger
                >
                  Delete Selected
                </Button>
              </Popconfirm>
              <Button
                onClick={() => handleCancelMultiSelect()}
                style={{ width: `${EXPLORER_PANEL_WIDTH - 236}px` }}
                disabled={removingWorkbook}
                danger
              >
                Cancel
              </Button>
            </Space>
          </div>
        )}
        <Spin
          indicator={<Spinner />}
          spinning={workbooksLoading}
          style={{ opacity: '20%' }}
        >
          <div
            ref={ref}
            style={{
              height: isMultiSelect
                ? `calc(100vh - ${220 + offset}px)`
                : `calc(100vh - ${180 + offset}px)`,
              overflowY: 'auto',
              overflowX: 'hidden',
            }}
          >
            {treeData.length > 0 && height !== undefined && height > 0 && (
              <MultiSelectContext.Provider value={isMultiSelect}>
                <Tree
                  showLine={{ showLeafIcon: false }}
                  switcherIcon={<DownOutlined />}
                  expandedKeys={expandedKeys}
                  onExpand={handleTreeExpand}
                  onCheck={handleTreeCheck}
                  checkedKeys={checkedKeys}
                  treeData={treeData}
                  height={height}
                  blockNode={true}
                  titleRender={handleTitleRender}
                  checkable={isMultiSelect}
                />
              </MultiSelectContext.Provider>
            )}
          </div>
        </Spin>
      </Space>
      <WorkbookCreateModal
        visible={showWorkbookCreateModal}
        close={_ => {
          setShowWorkbookCreateModal(false);
        }}
        callback={handleWorkbookCreateCallback}
      />
      {showWorkbookImportModal && (
        <WorkbookImportModal
          visible={showWorkbookImportModal}
          close={_ => {
            setShowWorkbookImportModal(false);
          }}
          callback={handleWorkbookImportCallback}
        />
      )}
      {currentEditWorkbook && (
        <WorkbookEditModal
          workbook={currentEditWorkbook}
          visible={showWorkbookEditModal}
          close={_ => {
            setCurrentEditWorkbook(undefined);
            setShowWorkbookEditModal(false);
          }}
          callback={handleWorkbookEditCallback}
        />
      )}
      {currentEditWorksheet && (
        <WorksheetEditModal
          worksheet={currentEditWorksheet}
          visible={showWorksheetEditModal}
          close={_ => {
            setCurrentEditWorksheet(undefined);
            setShowWorksheetEditModal(false);
          }}
          callback={handleWorksheetEditCallback}
        />
      )}
    </div>
  );
};

export default WorkbookExplorer;
