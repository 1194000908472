// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_SYSTEM_PERMISSION = gql`
  mutation GrantSystemPermission($name: String!, $permission: String!) {
    systemPermissionGrant(name: $name, permission: $permission) {
      name
      permission
    }
  }
`;

export const REVOKE_SYSTEM_PERMISSION = gql`
  mutation RevokeSystemPermission($name: String!, $permission: String!) {
    systemPermissionRevoke(name: $name, permission: $permission) {
      name
      permission
    }
  }
`;

export const useGrantSystemPermission = () => {
  return useMutation(GRANT_SYSTEM_PERMISSION);
};

export const useRevokeSystemPermission = () => {
  return useMutation(REVOKE_SYSTEM_PERMISSION);
};
