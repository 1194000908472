// Imports
import React, { useState, useRef } from 'react';
import {
  Form,
  Modal,
  Button,
  Row,
  Col,
  Input,
  Select,
  Divider,
  Switch,
} from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  CREDENTIAL_TYPES,
  CREDENTIAL_FIELDS,
  CREDENTIAL_OPTIONS,
  CREDENTIAL_FIELD_TYPES,
} from '../../constants';
import { displaySuccess } from '../../helper';

const { Option } = Select;

const CredentialCreateModal = ({ visible, close, width = 750, callback }) => {
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();

  const [isCreating, setIsCreating] = useState(false);
  const [formValues, setFormValues] = useState({});

  const [form] = Form.useForm();
  const copyButtonRef = useRef(null);

  const onFinish = async values => {
    setIsCreating(true);

    const variables = Object.keys(values).reduce((acc, cur) => {
      if (
        (values[cur] !== undefined && values[cur] !== '') ||
        ['identity', 'secret'].includes(cur)
      ) {
        acc[cur] = values[cur] ?? '';
      }
      return acc;
    }, {});

    const resp = await createCredential({
      variables,
    });

    setIsCreating(false);

    if (resp?.errors) {
      callback(resp, null);
    } else {
      displaySuccess(`Credential ${variables.name} created.`);
      copyButtonRef.current.click();
      callback(null, resp);
    }
  };

  const create = _ => {
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.type) {
      // Type changed need to reset dynamic fields
      CREDENTIAL_FIELDS[changedValues.type].forEach(field => {
        form.setFieldsValue({
          [field.value]: '',
        });
      });
      const fields = CREDENTIAL_FIELDS[changedValues.type].reduce(
        (acc, cur) => {
          acc[cur.value] = '';
          return acc;
        },
        {}
      );
      setFormValues({
        name: allValues.name,
        type: changedValues.type,
        ...fields,
      });
    } else {
      setFormValues({
        ...allValues,
      });
    }
  };

  return (
    <Modal
      title="Create Credential"
      open={visible}
      width={width}
      bodyStyle={{ minHeight: '400px' }}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={create}
          loading={isCreating}
        >
          Create
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        form={form}
        name="credential"
        layout="vertical"
        initialValues={{}}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <CopyToClipboard
          text={formValues.name}
          onCopy={() => {
            displaySuccess('Credential name copied to clipboard!');
          }}
        >
          <button
            ref={copyButtonRef}
            type="button"
            style={{ display: 'none' }}
          ></button>
        </CopyToClipboard>
        <Row gutter={20}>
          <Col span={12}>
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input credential name!',
                  whitespace: true,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Type"
              name="type"
              rules={[
                {
                  required: true,
                  message: 'Please select credential type!',
                },
              ]}
            >
              <Select>
                {Object.keys(CREDENTIAL_TYPES)
                  .sort()
                  .map(type => {
                    const value = CREDENTIAL_TYPES[type];
                    return (
                      <Option key={value} value={value}>
                        {value}
                      </Option>
                    );
                  })}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        {formValues.type && CREDENTIAL_FIELDS[formValues.type] && (
          <Row gutter={20}>
            {CREDENTIAL_FIELDS[formValues.type].map(field => {
              return (
                <Col span={12} key={field.value}>
                  {field.type === CREDENTIAL_FIELD_TYPES.STRING && (
                    <Form.Item label={field.label} name={field.value}>
                      <Input />
                    </Form.Item>
                  )}
                  {field.type === CREDENTIAL_FIELD_TYPES.PASSWORD && (
                    <Form.Item label={field.label} name={field.value}>
                      <Input.Password />
                    </Form.Item>
                  )}
                  {field.type === CREDENTIAL_FIELD_TYPES.BOOLEAN && (
                    <Form.Item label={field.label} name={field.value}>
                      <Switch checked={formValues[field.value]} />{' '}
                    </Form.Item>
                  )}
                </Col>
              );
            })}
          </Row>
        )}
        {formValues.type && CREDENTIAL_OPTIONS[formValues.type] && (
          <>
            <Divider dashed />
            <Row gutter={20}>
              {CREDENTIAL_OPTIONS[formValues.type].map(field => {
                return (
                  <Col span={12} key={field.value}>
                    {field.type === CREDENTIAL_FIELD_TYPES.STRING && (
                      <Form.Item label={field.label} name={field.value}>
                        <Input />
                      </Form.Item>
                    )}
                    {field.type === CREDENTIAL_FIELD_TYPES.PASSWORD && (
                      <Form.Item label={field.label} name={field.value}>
                        <Input.Password />
                      </Form.Item>
                    )}
                    {field.type === CREDENTIAL_FIELD_TYPES.BOOLEAN && (
                      <Form.Item label={field.label} name={field.value}>
                        <Switch checked={formValues[field.value]} />{' '}
                      </Form.Item>
                    )}
                  </Col>
                );
              })}
            </Row>
          </>
        )}
      </Form>
    </Modal>
  );
};

export default CredentialCreateModal;
