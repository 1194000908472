import mitt from 'mitt';

// Map to maintain all emitters for event types
const map = {};

// Constants for all possible event types
export const EVENT_TYPES = {
  FILES_UPDATE: 'files-update',
  TABLES_UPDATE: 'tables-update',
};

const DEBOUNCE_DELAY = 3000; // in milliseconds

const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

// Create new wrapped emitter object
const getEmitter = _ => {
  const emitter = mitt();
  const on = (event, key, handler) => {
    console.debug('emitter->on', event, key);
    emitter.on(key, handler);
  };
  const emit = debounce((event, key, payload = {}) => {
    console.debug('emitter->emit', event, key, payload);
    emitter.emit(key, payload);
  }, DEBOUNCE_DELAY);
  const off = (event, key) => {
    console.debug('emitter->off', event, key);
    emitter.off(key);
  };
  const all = event => {
    return emitter.all;
  };
  return { on, off, emit, all };
};

// Returns hook object for specific event type
function useEvent(event) {
  // Check if emitter exists for type
  // Create new emitter if needed
  if (!map[event]) {
    map[event] = getEmitter();
  }
  const emitter = map[event];
  return {
    listen: (id, handler) => {
      emitter.on(event, id, handler);
    },
    cancel: id => {
      emitter.off(event, id);
    },
    emit: payload => {
      // Emit signal to all listeners
      Array.from(emitter.all(event).keys()).forEach(id => {
        emitter.emit(event, id, payload);
      });
    },
  };
}

export default useEvent;
