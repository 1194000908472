// Imports
import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

// App Imports
import { APP_URL } from '../setup/config';

function useWebappStatus(onActivate, interval = 15000) {
  const [isActive, setIsActive] = useState(true);

  const check = useCallback(
    async _ => {
      try {
        const resp = await axios.get(`${APP_URL}/favicon.ico`);
        if (resp?.status === 200) {
          if (!isActive) {
            await onActivate(_ => {
              setIsActive(true);
            });
          } else {
            setIsActive(true);
          }
        } else {
          setIsActive(false);
        }
      } catch (err) {
        setIsActive(false);
      }
    },
    [onActivate, isActive, setIsActive]
  );

  useEffect(
    _ => {
      check();
      const intervalId = setInterval(() => {
        check();
      }, interval);
      return _ => clearInterval(intervalId);
    },
    [check, interval]
  );

  return { isActive };
}

export default useWebappStatus;
