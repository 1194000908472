// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_UPGRADES = gql`
  query Upgrades($deployment_type: String) {
    upgrades(deployment_type: $deployment_type) {
      clusterName
      currentVersion {
        short
        version
        operator
        tag
      }
      availableVersions {
        short
        version
        operator
        tag
      }
      availableUpgradeVersions {
        short
        version
        operator
        tag
      }
    }
  }
`;

export const useGetUpgrades = options => {
  return useQuery(GET_UPGRADES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
