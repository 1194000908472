// Imports
import React, { useState, useEffect } from 'react';
import {
  Form,
  Button,
  Popconfirm,
  Input,
  InputNumber,
  AutoComplete,
  Row,
  Col,
  Divider,
  Radio,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 4 },
  wrapperCol: { span: 18 },
};

const DEPLOYMENT_MODES = [
  {
    label: 'Batch',
    value: 'batch',
  },
  {
    label: 'Continuous',
    value: 'continuous',
  },
];

const ExecuteModelForm = ({ name }) => {
  const [modelEvaluateByName] = GraphQLServices.Models.useEvaluateModelByName();

  const { loading, error, data } = GraphQLServices.Schemas.useGetSchemas();
  const {
    loading: tableLoading,
    error: tableError,
    data: tableData,
    refetch: refetchTables,
  } = GraphQLServices.Tables.useGetTables();

  const schemas =
    data && data.schemas ? data.schemas.map(s => ({ value: s.name })) : [];
  let schemaNotFoundText = 'No schemas available';
  if (loading) {
    schemaNotFoundText = 'Retrieving...';
  } else if (error) {
    schemaNotFoundText = 'Error while retrieving';
    console.error(error);
  }

  let tableNotFoundText = 'No tables available for selected schema';
  if (tableLoading) {
    tableNotFoundText = 'Retrieving...';
  } else if (tableError) {
    tableNotFoundText = 'Error while retrieving';
    console.error(tableError);
  }

  const [isExecuting, setIsExecuting] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();

  const { topBarCollapsed } = useSelector(state => state.app);

  useEffect(
    _ => {
      if (name) {
        form.setFieldsValue({
          model_name: name,
        });
      }
    },
    [form, name]
  );

  const resetForm = _ => {
    form.resetFields();
  };

  const onFinish = async values => {
    const {
      model_name,
      deployment_mode,
      number_of_replications,
      sourceSchemaName,
      sourceTableName,
      targetSchemaName,
      targetTableName,
    } = values;

    try {
      setIsExecuting(true);
      const importModelResp = await modelEvaluateByName({
        variables: {
          model_name,
          method: 'execution',
          deployment_mode,
          number_of_replications,
          source_data: `${sourceSchemaName}.${sourceTableName}`,
          destination_table_name: `${targetSchemaName}.${targetTableName}`,
        },
      });

      if (importModelResp?.errors) {
        throw new Error(importModelResp?.errors[0]?.message);
      }

      displaySuccess(`Model ${model_name} executed.`);
      resetForm();
      refetchTables();
    } catch (error) {
      // Graphql client should display error
    } finally {
      setIsExecuting(false);
    }
  };

  const create = _ => {
    form.submit();
  };

  const handleCancel = e => {
    history.push('/');
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={{
        model_name: name,
        deployment_mode: 'batch',
        number_of_replications: 1,
      }}
      onFinish={onFinish}
      colon={false}
    >
      <div
        style={{
          height: topBarCollapsed
            ? 'calc(100vh - 250px)'
            : 'calc(100vh - 300px)',
          overflow: 'auto',
        }}
      >
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="model_name"
          label="Model Name"
        >
          <Input disabled />
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          label="Deployment Mode"
          name="deployment_mode"
          initialValue={DEPLOYMENT_MODES[0].value}
        >
          <Radio.Group
            options={DEPLOYMENT_MODES}
            optionType="button"
            buttonStyle="solid"
          />
        </Form.Item>
        <Form.Item
          {...DEFAULT_FORM_ITEM_PROPS}
          name="number_of_replications"
          label="Replications"
          rules={[
            {
              required: true,
              message: 'Please select number of replications.',
            },
          ]}
        >
          <InputNumber min={1} step={1} precision={0} />
        </Form.Item>
        <Row>
          <Col span={18} offset={4}>
            <Divider dashed />
          </Col>
        </Row>
        <Form.Item
          name="sourceSchemaName"
          label="Source Schema"
          extra="Enter an existing source schema"
          {...DEFAULT_FORM_ITEM_PROPS}
          rules={[
            {
              required: true,
              message: 'Please enter a source schema name.',
            },
            {
              pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
              message:
                'Schema name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
            },
            {
              min: 1,
              message: 'Schema must be between 1-200 characters',
            },
            {
              max: 200,
              message: 'Schema must be between 1-200 characters',
            },
          ]}
        >
          <AutoComplete
            options={schemas}
            notFoundContent={schemaNotFoundText}
            filterOption={true}
          ></AutoComplete>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.sourceSchemaName !== values.sourceSchemaName
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const tables =
              tableData && tableData.tables
                ? tableData.tables
                    .filter(t => t.schema === values?.sourceSchemaName)
                    .map(t => ({ value: t.name }))
                : [];

            return (
              <Form.Item
                name="sourceTableName"
                label="Source Table"
                {...DEFAULT_FORM_ITEM_PROPS}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a source table name.',
                  },
                  {
                    pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                    message:
                      'Table name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                  },
                  {
                    min: 1,
                    message: 'Table must be between 1-200 characters',
                  },
                  {
                    max: 200,
                    message: 'Table must be between 1-200 characters',
                  },
                ]}
                extra="Enter an existing table."
              >
                <AutoComplete
                  options={tables}
                  notFoundContent={tableNotFoundText}
                ></AutoComplete>
              </Form.Item>
            );
          }}
        </Form.Item>
        <Row>
          <Col span={18} offset={4}>
            <Divider dashed />
          </Col>
        </Row>
        <Form.Item
          name="targetSchemaName"
          label="Target Schema"
          extra="Enter an existing target schema"
          {...DEFAULT_FORM_ITEM_PROPS}
          rules={[
            {
              required: true,
              message: 'Please enter a target schema name.',
            },
            {
              pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
              message:
                'Schema name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
            },
            {
              min: 1,
              message: 'Schema must be between 1-200 characters',
            },
            {
              max: 200,
              message: 'Schema must be between 1-200 characters',
            },
          ]}
        >
          <AutoComplete
            options={schemas}
            notFoundContent={schemaNotFoundText}
            filterOption={true}
          ></AutoComplete>
        </Form.Item>
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.targetSchemaName !== values.targetSchemaName
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const tables =
              tableData && tableData.tables
                ? tableData.tables
                    .filter(t => t.schema === values?.targetSchemaName)
                    .map(t => ({ value: t.name }))
                : [];

            return (
              <Form.Item
                name="targetTableName"
                label="Target Table"
                {...DEFAULT_FORM_ITEM_PROPS}
                rules={[
                  {
                    required: true,
                    message: 'Please enter a target table name.',
                  },
                  {
                    pattern: /^[a-zA-Z0-9_][a-zA-Z0-9_{}[\]:\-()#]*$/,
                    message:
                      'Table name must start with alphanumeric or underscore and only contain alphanumerics and the following: _ { } [ ] : -  ( ) #',
                  },
                  {
                    min: 1,
                    message: 'Table must be between 1-200 characters',
                  },
                  {
                    max: 200,
                    message: 'Table must be between 1-200 characters',
                  },
                ]}
                extra="Enter a new or existing table"
              >
                <AutoComplete
                  options={tables}
                  notFoundContent={tableNotFoundText}
                ></AutoComplete>
              </Form.Item>
            );
          }}
        </Form.Item>
      </div>
      <div>
        <Button
          type="primary"
          onClick={create}
          style={{ float: 'right' }}
          loading={isExecuting}
        >
          Execute Model
        </Button>
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default ExecuteModelForm;
