// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_ROLES = gql`
  query Roles {
    roles {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
          permissions
          system_permissions
          proc_permissions
          sql_proc_permissions
          datasource_permissions
          datasink_permissions
          table_permissions
          folder_permissions
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      kinetica_role {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const GET_LOCAL_ROLES = gql`
  query {
    roles {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      created_at
      updated_at
    }
  }
`;

export const CHECK_ROLE_BY_NAME = gql`
  query CheckRoleByName($name: String!) {
    role(name: $name) {
      id
      name
    }
  }
`;

export const GET_ROLE_BY_NAME = gql`
  query GetRoleByName($name: String!) {
    role(name: $name) {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
          permissions
          system_permissions
          proc_permissions
          sql_proc_permissions
          datasource_permissions
          datasink_permissions
          table_permissions
          folder_permissions
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      kinetica_role {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const CREATE_ROLE = gql`
  mutation CreateRole(
    $name: String!
    $description: String
    $ring: String!
    $resource_group: String
  ) {
    roleCreate(
      name: $name
      description: $description
      ring: $ring
      resource_group: $resource_group
    ) {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
          permissions
          system_permissions
          proc_permissions
          sql_proc_permissions
          datasource_permissions
          datasink_permissions
          table_permissions
          folder_permissions
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      kinetica_role {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const ADD_ROLE_BY_NAME = gql`
  mutation AddRoleByName($name: String!, $role_name: String!, $ring: String!) {
    roleAddRoleByName(name: $name, role_name: $role_name, ring: $ring) {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
          permissions
          system_permissions
          proc_permissions
          sql_proc_permissions
          datasource_permissions
          datasink_permissions
          table_permissions
          folder_permissions
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      kinetica_role {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const REMOVE_ROLE_BY_NAME = gql`
  mutation RemoveRoleByName($name: String!, $role_name: String!) {
    roleRemoveRoleByName(name: $name, role_name: $role_name) {
      id
    }
  }
`;

export const REMOVE_ROLE_BY_ID = gql`
  mutation RemoveRoleById($id: ID!) {
    roleRemoveById(id: $id) {
      id
    }
  }
`;

export const ALTER_ROLE_BY_NAME = gql`
  mutation AlterRoleByName($name: String!, $action: String!, $value: String) {
    kineticaRoleAlterByName(name: $name, action: $action, value: $value) {
      name
      type
      roles
      permissions
      resource_group {
        name
        alias
        max_data
        max_tier_priority
        max_scheduling_priority
        max_cpu_concurrency
        tier_attributes {
          name
          max_memory
        }
        usage
      }
    }
  }
`;

export const GRANT_PERMISSION_BY_NAME = gql`
  mutation AddPermissionByName($name: String!, $permission_name: String!) {
    roleAddPermissionByName(name: $name, permission_name: $permission_name) {
      id
      name
      description
      roles {
        id
        name
        description
        permissions {
          id
          name
          label
          description
        }
        kinetica_role {
          name
          type
          resource_group {
            name
            alias
            max_data
            max_tier_priority
            max_scheduling_priority
            max_cpu_concurrency
            tier_attributes {
              name
              max_memory
            }
            usage
          }
          permissions
          system_permissions
          proc_permissions
          sql_proc_permissions
          datasource_permissions
          datasink_permissions
          table_permissions
          folder_permissions
        }
        created_at
        updated_at
      }
      permissions {
        id
        name
        label
        description
      }
      kinetica_role {
        name
        type
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
        permissions
        system_permissions
        proc_permissions
        sql_proc_permissions
        datasource_permissions
        datasink_permissions
        table_permissions
        folder_permissions
      }
      created_at
      updated_at
    }
  }
`;

export const REVOKE_PERMISSION_BY_NAME = gql`
  mutation RemovePermissionByName($name: String!, $permission_name: String!) {
    roleRemovePermissionByName(name: $name, permission_name: $permission_name) {
      id
    }
  }
`;

export const useGetRoles = () => {
  return useQuery(GET_ROLES);
};

export const useGetLocalRoles = () => {
  return useQuery(GET_LOCAL_ROLES);
};

export const useGetRoleByName = () => {
  return useQuery(GET_ROLE_BY_NAME);
};

export const useCreateRole = () => {
  return useMutation(CREATE_ROLE);
};

export const useAddRoleByName = () => {
  return useMutation(ADD_ROLE_BY_NAME);
};

export const useRemoveRoleByName = () => {
  return useMutation(REMOVE_ROLE_BY_NAME);
};

export const useRemoveRoleById = () => {
  return useMutation(REMOVE_ROLE_BY_ID);
};

export const useAlterRoleByName = () => {
  return useMutation(ALTER_ROLE_BY_NAME);
};

export const useGrantPermissionByName = () => {
  return useMutation(GRANT_PERMISSION_BY_NAME);
};

export const useRevokePermissionByName = () => {
  return useMutation(REVOKE_PERMISSION_BY_NAME);
};
