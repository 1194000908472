// Imports
import React, { useMemo, useState } from 'react';
import { Modal, Button, Space, Input, Table, Spin } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';
import { selectQuery } from '../../containers/importexport/utils';

const { Column } = Table;
const { Search } = Input;

const DatasourceTableColumnsModal = ({
  form,
  datasource,
  visible,
  setVisible,
  handleColumnSelection,
  actionLabel = 'Import',
  width,
  height,
  pageSize,
}) => {
  const { datasource_name } = datasource;

  const { data, loading } =
    GraphQLServices.Datasources.useGetDatasourceTableColumnsByName({
      variables: {
        datasource_name,
        remote_query:
          form.getFieldValue('remoteQuery') ||
          selectQuery(form.getFieldValue('remoteTable')),
      },
    });

  const [filter, setFilter] = useState('');

  const columns = useMemo(
    _ => {
      if (data && data.datasourceTableColumns) {
        return data.datasourceTableColumns.columns
          .filter(
            column =>
              filter.trim() === '' ||
              column.toLowerCase().includes(filter.trim().toLowerCase())
          )
          .map(column => {
            return {
              name: column,
            };
          });
      }
      return [];
    },
    [data, filter]
  );

  const onFilterChange = evt => {
    setFilter(evt.target.value);
  };

  const handleColumnSelect = column => e => {
    const { name } = column;
    handleColumnSelection(datasource, { column: name });
    setVisible(false);
  };

  return (
    visible && (
      <Modal
        title={`Data Source Table Columns: ${datasource_name}`}
        open={visible}
        width={width}
        onCancel={_ => setVisible(false)}
        footer={[
          <Button key="submit" onClick={_ => setVisible(false)}>
            Close
          </Button>,
        ]}
        destroyOnClose
        centered
      >
        <Spin indicator={<Spinner />} spinning={loading}>
          <div
            style={{
              height,
              overflow: 'auto',
            }}
          >
            <Space direction="vertical" style={{ width: '100%' }}>
              <Search
                placeholder="Filter tables"
                allowClear
                onChange={onFilterChange}
              />
              {data && (
                <Table
                  rowKey="name"
                  dataSource={columns}
                  pagination={{
                    pageSize: pageSize - 1,
                    pageSizeOptions: [pageSize - 1],
                    showSizeChanger: false,
                  }}
                  scroll={{
                    x: 'max-content',
                  }}
                  size="small"
                >
                  <Column key="name" title="Columns" dataIndex="name" />
                  <Column
                    title=""
                    key="name"
                    width="1%"
                    render={(column, record) => (
                      <Button
                        type="primary"
                        onClick={handleColumnSelect(record)}
                        size="small"
                        ghost
                      >
                        {actionLabel}
                      </Button>
                    )}
                  />
                </Table>
              )}
            </Space>
          </div>
        </Spin>
      </Modal>
    )
  );
};

export default DatasourceTableColumnsModal;
