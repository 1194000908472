// Imports
import { useMemo, useState } from 'react';
import {
  Modal,
  Form,
  Input,
  Spin,
  Select,
  Radio,
  Switch,
  Tooltip,
  Divider,
  Button,
  Popconfirm,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { useUpdateK8SKineticaClusterByName } from '../../graphql/schema/k8s_kineticaclusters';
import { DEPLOYMENT_TYPE } from '../../setup/config';
import { displaySuccess } from '../../helper';
import Spinner from '../common/Spinner';

const convertToGB = byteValue => {
  if (isNaN(byteValue) && byteValue.indexOf('Gi') > -1) {
    const convertedGB = Number(byteValue.split('Gi')[0]) * (1024 * 1024 * 1024);
    return convertedGB / (1000 * 1000 * 1000);
  }
  if (byteValue >= 0) {
    return byteValue / (1000 * 1000 * 1000);
  }
  return byteValue;
};

const convertToByte = gbValue => {
  if (gbValue >= 0) {
    return gbValue * (1000 * 1000 * 1000);
  }
  return gbValue;
};

const FIELD_CATEGORIES = {
  GENERAL: 'General',
  KIFS: 'KiFS',
  TIERING: 'Tiering',
  PERSISTENCE: 'Persistence',
  PROCESSES: 'Processes',
};

const FIELD_TYPES = {
  DATA: 'data',
  SELECT: 'select',
  RADIO: 'radio',
  STRING: 'string',
  NUMBER: 'number',
  BOOLEAN: 'boolean',
};

const DATA_LOADING_OPTIONS = [
  {
    label: 'Always',
    value: 'always',
  },
  {
    label: 'Lazy',
    value: 'lazy',
  },
  {
    label: 'On Demand',
    value: 'on_demand',
  },
];

const CONFIG_PATH_PREFIX = 'spec.gpudbCluster.config.';

const CONFIG_MAP = [
  {
    name: FIELD_CATEGORIES.GENERAL,
    fields: [
      {
        name: 'general.maxGetRecordsSize',
        label: 'Max Get Records Size',
        configPath: 'general.maxGetRecordsSize',
        type: FIELD_TYPES.NUMBER,
      },
      {
        name: 'general.requestTimeout',
        label: 'Request Timeout',
        configPath: 'general.requestTimeout',
        type: FIELD_TYPES.NUMBER,
      },
      {
        name: 'general.concurrentKernelExecution',
        label: 'Concurrent Kernel Execution',
        configPath: 'general.concurrentKernelExecution',
        type: FIELD_TYPES.BOOLEAN,
      },
      {
        name: 'general.maxConcurrentKernels',
        label: 'Max Concurrent Kernels',
        configPath: 'general.maxConcurrentKernels',
        type: FIELD_TYPES.NUMBER,
      },
    ],
  },
  {
    name: FIELD_CATEGORIES.TIERING,
    fields: [
      {
        name: 'tieredStorage.ramTier.default.limit',
        label: 'RAM Default Limit ',
        configPath: 'tieredStorage.ramTier.default.limit',
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'tieredStorage.ramTier.*.limit',
        label: 'RAM Rank Limit',
        configPath: 'tieredStorage.ramTier.*.limit',
        key: 'rank',
        exclude: ['default'],
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'tieredStorage.diskCacheTier.default.limit',
        label: 'Disk Cache Default Limit',
        note: 'Size of RAM tier recommended',
        configPath: 'tieredStorage.diskCacheTier.default.limit',
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'tieredStorage.diskCacheTier.*.limit',
        label: 'Disk Cache Device Limit',
        note: 'Size of RAM tier recommended',
        configPath: 'tieredStorage.diskCacheTier.*.limit',
        key: 'disk',
        exclude: ['default'],
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'tieredStorage.persistTier.default.limit',
        label: 'Persist Tier Default Limit',
        configPath: 'tieredStorage.persistTier.default.limit',
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'tieredStorage.persistTier.*.limit',
        label: 'Persist Tier Rank Limit',
        configPath: 'tieredStorage.persistTier.*.limit',
        key: 'rank',
        exclude: ['default'],
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      // {
      //   name: 'tieredStorage.coldStorageTier.*.limit',
      //   label: 'Cold Storage Tier Limit',
      //   configPath: 'tieredStorage.coldStorageTier.*.limit',
      //   type: FIELD_TYPES.DATA,
      //   encode: convertToGB,
      //   decode: convertToByte,
      // },
    ],
  },
  {
    name: FIELD_CATEGORIES.KIFS,
    fields: [
      {
        name: 'kifs.mountPoint',
        label: 'Mount Point',
        configPath: 'kifs.mountPoint',
        type: FIELD_TYPES.STRING,
      },
    ],
  },
  {
    name: FIELD_CATEGORIES.PERSISTENCE,
    fields: [
      {
        name: 'persistence.loadVectorsOnStart',
        label: 'Load Vectors On Start',
        configPath: 'persistence.loadVectorsOnStart',
        type: FIELD_TYPES.RADIO,
        options: DATA_LOADING_OPTIONS,
      },
      {
        name: 'persistence.buildPKIndexOnStart',
        label: 'Build PK Index On Start',
        configPath: 'persistence.buildPKIndexOnStart',
        type: FIELD_TYPES.RADIO,
        options: DATA_LOADING_OPTIONS,
      },
      {
        name: 'persistence.buildMaterializedViewsOnStart',
        label: 'Build Mat. Views On Start',
        configPath: 'persistence.buildMaterializedViewsOnStart',
        type: FIELD_TYPES.RADIO,
        options: DATA_LOADING_OPTIONS,
      },
      {
        name: 'persistence.tempDirectory',
        label: 'Temp Directory',
        configPath: 'persistence.tempDirectory',
        type: FIELD_TYPES.STRING,
      },
      {
        name: 'persistence.shadowCubeEnabled',
        label: 'Shadow Cube Enabled',
        configPath: 'persistence.shadowCubeEnabled',
        type: FIELD_TYPES.BOOLEAN,
      },
      {
        name: 'persistence.shadowAggSize',
        label: 'Shadow Cube Agg Size',
        configPath: 'persistence.shadowAggSize',
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
      {
        name: 'persistence.shadowFilterSize',
        label: 'Shadow Cube Filter Size',
        configPath: 'persistence.shadowFilterSize',
        type: FIELD_TYPES.DATA,
        encode: convertToGB,
        decode: convertToByte,
      },
    ],
  },
  {
    name: FIELD_CATEGORIES.PROCESSES,
    fields: [
      {
        name: 'processes.tcsPerTom',
        label: 'TCs Per TOM',
        configPath: 'processes.tcsPerTom',
        type: FIELD_TYPES.NUMBER,
      },
      {
        name: 'processes.tpsPerTom',
        label: 'TPs Per TOM',
        configPath: 'processes.tpsPerTom',
        type: FIELD_TYPES.NUMBER,
      },
      {
        name: 'processes.subtaskConcurrentyLimit',
        label: 'Subtask Concurrency Limit',
        configPath: 'processes.subtaskConcurrentyLimit',
        type: FIELD_TYPES.NUMBER,
      },
    ],
  },
];

const ClusterConfigModal = ({ setIsConfigOpen }) => {
  const { data: { k8s_kineticaclusters: clusters } = {}, loading } =
    GraphQLServices.K8sKineticaClusters.useGetK8sKineticaClusters({
      variables: {
        deployment_type: DEPLOYMENT_TYPE,
      },
    });

  const [createK8SKineticaClusterAdmin] =
    GraphQLServices.K8sKineticaClusterAdmins.useCreateK8SKineticaClusterAdmin();

  const [updateKineticaCR] = useUpdateK8SKineticaClusterByName();

  const [configForm] = Form.useForm();

  const [isConfigUpdating, setIsConfigUpdating] = useState(false);

  const handleConfigCancel = evt => {
    setIsConfigOpen(false);
  };

  const cluster = useMemo(() => {
    if (clusters && clusters.length > 0) {
      return clusters[0];
    }
    return null;
  }, [clusters]);

  const clusterName = useMemo(() => {
    if (cluster) {
      const { clusterName: name } = cluster?.spec?.gpudbCluster;
      return name;
    }
    return null;
  }, [cluster]);

  const processWildcardPath = (object, path, field) => {
    const keys = path.split('.*.');
    const parent = keys[0]
      .replace(/\[([^[\]]*)\]/g, '.$1.')
      .split('.')
      .filter(t => t !== '')
      .reduce((prev, cur) => prev && prev[cur], object);
    return Object.keys(parent)
      .filter(key => {
        const { exclude = [] } = field;
        return !exclude.includes(key) && typeof parent[key] === 'object';
      })
      .map(key => {
        return parent[key][keys[1]];
      });
  };

  const getFromPath = (object, path, field) => {
    if (path.indexOf('.*.') > -1) {
      return processWildcardPath(object, path, field);
    } else {
      return path
        .replace(/\[([^[\]]*)\]/g, '.$1.')
        .split('.')
        .filter(t => t !== '')
        .reduce((prev, cur) => prev && prev[cur], object);
    }
  };

  const updateAtPath = (object, path, value) => {
    if (path.indexOf('.*.') > -1) {
      const keys = path.split('.*.');
      const parent = keys[0]
        .replace(/\[([^[\]]*)\]/g, '.$1.')
        .split('.')
        .filter(t => t !== '')
        .reduce((prev, cur) => prev && prev[cur], cluster);
      const wildcard = Object.keys(parent).reduce((acc, cur) => {
        if (typeof parent[cur] === 'object') {
          return cur;
        }
        return acc;
      }, null);
      const newPath = path.replace(/\*/, wildcard);
      updateAtPath(object, newPath, value);
    } else {
      var stack = path.split('.');
      while (stack.length > 1) {
        const key = stack.shift();
        if (!object.hasOwnProperty(key)) {
          object[key] = {};
        }
        object = object[key];
      }
      object[stack.shift()] = value;
    }
  };

  const getWildcardMatches = (field, values) => {
    const replacement = field.key ? `${field.key}(\\d+)` : `(.*)`;
    const template = field.configPath.replace(/\*/, replacement);
    const regex = new RegExp(`^${template}`);
    return Object.keys(values)
      .filter(key => {
        return key.match(regex);
      })
      .map(key => {
        return {
          key,
          value: values[key],
        };
      });
  };

  const getFieldMatch = fieldName => {
    return CONFIG_MAP.reduce((acc, cur) => {
      const field = cur.fields.find(field => field.name === fieldName);
      if (field) {
        return field;
      } else {
        const match = cur.fields.find(field => {
          const replacement = field.key ? `${field.key}(\\d+)` : `(.*)`;
          const template = field.name.replace(/\*/, replacement);
          const regex = new RegExp(`^${template}`);
          return fieldName.match(regex);
        });
        if (match) {
          return match;
        }
        return acc;
      }
    }, null);
  };

  const handleConfigUpdate = async evt => {
    setIsConfigUpdating(true);

    const configBody = {};
    const formValues = configForm.getFieldsValue();
    console.log(formValues);

    Object.keys(formValues).forEach(fieldName => {
      const field = getFieldMatch(fieldName);
      updateAtPath(
        configBody,
        CONFIG_PATH_PREFIX + fieldName,
        decode(formValues[fieldName], field)
      );
    });
    console.log(configBody);

    const configResp = await updateKineticaCR({
      variables: {
        name: clusterName,
        body: configBody,
      },
    });

    if (configResp?.errors?.length > 0) {
      console.error(configResp?.errors);
      setIsConfigUpdating(false);
      return;
    } else {
      displaySuccess('Cluster configuration updated. Restart initiated..');
    }

    const restartBody = {
      spec: {
        kineticaClusterName: clusterName,
        regenerateDBConfig: {
          restart: true,
        },
      },
    };
    console.log(restartBody);

    const restartResp = await createK8SKineticaClusterAdmin({
      variables: {
        name: clusterName,
        body: restartBody,
      },
    });

    if (restartResp?.errors) {
      console.error(restartResp?.errors);
    } else {
      displaySuccess('Cluster restarted.');
      setIsConfigOpen(false);
    }

    setIsConfigUpdating(false);
  };

  const encode = (val, field) => {
    if (field.encode) {
      if (Array.isArray(val)) {
        return val.map(v => field.encode(v));
      }
      return field.encode(val);
    }
    return val;
  };

  const decode = (val, field) => {
    if (field.encode) {
      return field.decode(val);
    }
    return val;
  };

  const initialValues = useMemo(
    _ => {
      const values = cluster
        ? CONFIG_MAP.reduce((acc, cur) => {
            cur.fields.forEach(field => {
              const val = getFromPath(
                cluster,
                CONFIG_PATH_PREFIX + field.configPath,
                field
              );
              if (!Array.isArray(val)) {
                acc[field.configPath] = encode(val, field);
              } else if (val.length > 0) {
                if (field.key) {
                  val.forEach((item, idx) => {
                    const configPath = field.configPath.replace(
                      /\*/,
                      `${field.key}${idx}`
                    );
                    acc[configPath] = encode(item, field);
                  });
                } else {
                  acc[field.configPath] = encode(val[0], field);
                }
              }
            });
            return acc;
          }, {})
        : {};

      if (Object.keys(values).length > 0) {
        console.log(values);
      }

      return values;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cluster]
  );

  const FORM_ITEM_STYLE = {
    marginBottom: 6,
  };
  const FORM_ITEM_RULES = [
    {
      required: true,
    },
  ];

  const renderFormField = (field, key, idx) => {
    switch (field.type) {
      case FIELD_TYPES.STRING:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Input type="text" size="small" />
          </Form.Item>
        );
      case FIELD_TYPES.NUMBER:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Input type="number" size="small" />
          </Form.Item>
        );
      case FIELD_TYPES.DATA:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Input
              type="number"
              addonBefore={
                field.key &&
                idx !== null && (
                  <Tooltip
                    title={field.key && `${field.key.toUpperCase()} ${idx}`}
                  >
                    {idx}
                  </Tooltip>
                )
              }
              addonAfter={
                <Tooltip title="Value in gigabyte (-1 is unlimited)">
                  GB
                </Tooltip>
              }
              size="small"
            />
          </Form.Item>
        );
      case FIELD_TYPES.BOOLEAN:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
            valuePropName="checked"
          >
            <Switch checkedChildren="On" unCheckedChildren="Off" size="small" />
          </Form.Item>
        );
      case FIELD_TYPES.SELECT:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Select options={field.options} size="small" />
          </Form.Item>
        );
      case FIELD_TYPES.RADIO:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Radio.Group
              options={field.options}
              optionType="button"
              buttonStyle="solid"
              size="small"
            />
          </Form.Item>
        );
      default:
        return (
          <Form.Item
            key={key ?? field.name}
            name={key ?? field.configPath}
            label={field.label}
            extra={field.note}
            style={FORM_ITEM_STYLE}
            rules={FORM_ITEM_RULES}
          >
            <Input size="small" />
          </Form.Item>
        );
    }
  };

  return (
    <Modal
      title="Configure Cluster"
      open={true}
      onOk={handleConfigUpdate}
      footer={[
        <Button key="cancel" onClick={handleConfigCancel}>
          Cancel
        </Button>,
        <Popconfirm
          key="update"
          title={
            <>
              Updating cluster configuration will require a database restart.
              <br />
              Would you like to continue?
            </>
          }
          onConfirm={handleConfigUpdate}
        >
          <Button type="primary" loading={isConfigUpdating}>
            Update
          </Button>
        </Popconfirm>,
      ]}
      bodyStyle={{ padding: '0px 24px 24px' }}
      width={800}
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div style={{ height: window.innerHeight - 280, overflowY: 'auto' }}>
          {cluster && (
            <Form
              form={configForm}
              initialValues={initialValues}
              labelCol={{ span: 9 }}
              wrapperCol={{ span: 12 }}
              colon={false}
            >
              {CONFIG_MAP.map(category => {
                return (
                  <div key={category.name}>
                    <Divider orientation="left" style={{ margin: '8px 0' }}>
                      {category.name}
                    </Divider>
                    {category.fields
                      .filter(field => {
                        if (field.configPath.indexOf('*') > -1) {
                          return (
                            getWildcardMatches(field, initialValues).length > 0
                          );
                        }
                        return initialValues[field.configPath] !== undefined;
                      })
                      .map(field => {
                        if (field.configPath.indexOf('*') > -1) {
                          return getWildcardMatches(field, initialValues).map(
                            (item, idx) => {
                              return renderFormField(field, item.key, idx);
                            }
                          );
                        }
                        return renderFormField(field);
                      })}
                  </div>
                );
              })}
            </Form>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default ClusterConfigModal;
