// Imports
import React, { useMemo } from 'react';
import { Form, Input, Select, Alert, Row, Col } from 'antd';

// App Imports
import VizMap from '../dataexplore/VizMap';
import { getFieldInfo } from '../../helper';
import { OSM_SOURCES } from '../../constants';

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

const { Option } = Select;

const CreateOSMGraphConfigureForm = ({ form, docs }) => {
  const viz = useMemo(_ => {
    return {
      config: [
        {
          table_schema: '',
          table_name: '',
        },
      ],
    };
  }, []);

  const handleSelectionUpdate = features => {
    if (features.length > 0) {
      form.setFieldsValue({ wkt_select: features[0] });
    }
  };

  return (
    <div>
      <Form.Item
        key="graph_name"
        label={
          <>
            Graph Name
            {getFieldInfo(docs['graph_name'])}
          </>
        }
        name="graph_name"
        rules={[
          {
            required: true,
            message: 'Please input graph name!',
            whitespace: true,
          },
        ]}
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <Input type="text" />
      </Form.Item>
      <Form.Item
        key="osm_source"
        label="OSM Source"
        name="osm_source"
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <Select>
          {OSM_SOURCES.map((configuration, index) => {
            return (
              <Option key={index} value={configuration.value}>
                {configuration.label}
              </Option>
            );
          })}
        </Select>
      </Form.Item>
      <Row>
        <Col span={5}></Col>
        <Col span={17}>
          <Alert
            message={
              'Larger region selection will require more memory and ' +
              'longer processing time to complete. It is recommended ' +
              'that you select only the area required to minimize both.'
            }
            type="warning"
            style={{ fontSize: '12px', border: '0px', marginBottom: 10 }}
          />
        </Col>
      </Row>
      <Form.Item
        key="wkt_select"
        label="Region Selection"
        name="wkt_select"
        {...DEFAULT_FORM_ITEM_PROPS}
      >
        <VizMap
          viz={viz}
          handleUpdate={_ => {}}
          minHeight={500}
          isPreview={true}
          initWithConfig={false}
          disabledFields={['table_schema', 'table_name']}
          handleWktPicker={handleSelectionUpdate}
          multiLayer={false}
        />
      </Form.Item>
    </div>
  );
};

export default CreateOSMGraphConfigureForm;
