// Improts
import React, { useState, useMemo, useEffect, useRef } from 'react';
import {
  Col,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Checkbox,
  Modal,
  Space,
  Button,
  notification,
} from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import * as monaco from 'monaco-editor';
import { useApolloClient } from '@apollo/client';

// App Imports
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import GraphQLServices from '../../graphql/services';
import MonacoEditor from '../../components/editor/MonacoEditor';
import DatasourceFileBrowserModal from '../../components/modal/DatasourceFileBrowserModal';
import DatasourceTablesModal from '../../components/modal/DatasourceTablesModal';
import DatasourceTableColumnsModal from '../../components/modal/DatasourceTableColumnsModal';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import { VERIFY_DATASOURCE_REMOTE_QUERY_BY_NAME } from '../../graphql/schema/datasources';

const { Option, OptGroup } = Select;

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

export default function SourceStep({ form, showDatasource, cdata_types = [] }) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [hasRemoteQuery, setHasRemoteQuery] = useState(
    form.getFieldValue('remoteQuery') !== undefined &&
      form.getFieldValue('remoteQuery').trim() !== ''
  );
  const [isSubscribed, setIsSubscribed] = useState(
    form.getFieldValue('subscribe') !== undefined &&
      form.getFieldValue('subscribe')
  );
  const [showDatasourceTablesModal, setShowDatasourceTablesModal] =
    useState(false);
  const [showDatasourceTableColumnsModal, setShowDatasourceTableColumnsModal] =
    useState(false);

  const [verifyingQuery, setVerifyingQuery] = useState(false);

  const remoteTable = Form.useWatch('remoteTable', form);
  const increasingColumn = Form.useWatch('increasingColumn', form);

  const toggleModal = () => setIsModalVisible(!isModalVisible);

  const editorRef = useRef();

  const graphqlClient = useApolloClient();

  useEffect(_ => {
    let disposable;
    if (disposable && disposable.dispose) {
      disposable.dispose();
    }

    disposable = monaco.languages.registerCompletionItemProvider(
      'kinetica-sql',
      {
        provideCompletionItems() {
          return {
            suggestions: [...defaultSuggestions],
          };
        },
      }
    );

    return _ => {
      if (disposable && disposable.dispose) {
        disposable.dispose();
      }
    };
  }, []);

  const { loading: objectsLoading, data } =
    GraphQLServices.DataObjects.useGetDataObjects();

  const datasources = useMemo(
    _ => {
      if (objectsLoading || !data?.datasources) {
        return [];
      } else {
        return data.datasources.map(datasource => ({
          label: datasource.datasource_name,
          value: datasource.datasource_name,
          datasource,
        }));
      }
    },
    [objectsLoading, data]
  );

  useEffect(() => {
    setHasRemoteQuery(
      form.getFieldValue('remoteQuery') !== undefined &&
        form.getFieldValue('remoteQuery').trim() !== ''
    );
    setIsSubscribed(
      form.getFieldValue('subscribe') !== undefined &&
        form.getFieldValue('subscribe')
    );
  }, [form]);

  const openDelimiterHelp = _ => {
    Modal.info({
      title: 'Help',
      content: (
        <div>
          <p style={{ marginBottom: '10px' }}>
            The default delimiter is a comma, unless a source file has one of
            these extensions:
          </p>
          <ul style={{ paddingLeft: '20px' }}>
            <li>.psv - will cause | to be the delimiter</li>
            <li>
              .tsv - will cause the tab character to be the delimiter{' '}
              <i>
                (If your file is not .tsv and you want to specify a tab, enter
                \t)
              </i>
            </li>
          </ul>
        </div>
      ),
      onOk() {},
    });
  };

  // Check if CDATA type
  const isCDATA = cdata_types.includes(form.getFieldValue('type'));

  const isJdbc = form.getFieldValue('type') === 'jdbc' || isCDATA;
  const isKafka = form.getFieldValue('type') === 'kafka';
  const isConfluent = form.getFieldValue('type') === 'confluent';
  const isKifs = form.getFieldValue('type') === 'kifs';
  const isKifsCustom =
    form.getFieldValue('filePath') &&
    !form.getFieldValue('filePath').includes('.');
  const isUpload = form.getFieldValue('type') === 'upload';

  const isUploadMulti = form.getFieldValue('filePaths')
    ? form.getFieldValue('filePaths').length > 1
    : false;

  const isBrowsable = !(
    (isKifs && !isKifsCustom) ||
    (isUpload && !isUploadMulti)
  );

  const handleRemoteQueryChange = (remoteQuery, evt) => {
    form.setFieldsValue({
      remoteQuery,
    });
    setHasRemoteQuery(remoteQuery.trim() !== '');
  };

  const handleDatasourceImport = (datasource, options = {}) => {
    form.setFieldsValue({
      remoteTable: options.table,
    });
    setShowDatasourceTablesModal(false);
  };

  const handleColumnSelection = (datasource, options = {}) => {
    form.setFieldsValue({
      increasingColumn: options.column,
    });
    setShowDatasourceTableColumnsModal(false);
  };

  const toggleDatasourceTablesModal = () => {
    setShowDatasourceTablesModal(!showDatasourceTablesModal);
  };

  const toggleDatasourceTableColumnsModal = () => {
    if (
      (form.getFieldValue('remoteTable') &&
        form.getFieldValue('remoteTable').trim() !== '') ||
      (form.getFieldValue('remoteQuery') &&
        form.getFieldValue('remoteQuery').trim() !== '')
    ) {
      setShowDatasourceTableColumnsModal(!showDatasourceTableColumnsModal);
    } else {
      alert('Remote table or query must be specified');
    }
  };

  const datasource = useMemo(
    _ => {
      return showDatasourceTablesModal || showDatasourceTableColumnsModal
        ? datasources.find(ds => {
            return ds.value === form.getFieldValue('dataSource');
          }).datasource
        : null;
    },
    [
      form,
      showDatasourceTablesModal,
      showDatasourceTableColumnsModal,
      datasources,
    ]
  );

  const handleSubscribeChange = evt => {
    setIsSubscribed(evt.target.checked);
  };

  const onValuesChange = (changedValues, allValues) => {
    setHasRemoteQuery(
      allValues.remoteQuery !== undefined && allValues.remoteQuery.trim() !== ''
    );
  };

  const handleVerifyQuery = _ => {
    const verify = async () => {
      try {
        setVerifyingQuery(true);

        const resp = await graphqlClient.query({
          query: VERIFY_DATASOURCE_REMOTE_QUERY_BY_NAME,
          variables: {
            datasource_name: form.getFieldValue('dataSource'),
            remote_query: form.getFieldValue('remoteQuery'),
          },
        });

        if (resp.errors) {
          notification.open({
            message: 'Remote Query Error Occurred',
            description: resp.errors.map(e => e.message).join('\n'),
            type: 'error',
          });
          return;
        }

        notification.open({
          message: 'Verify Remote Query',
          description: 'Remote query executed successfully',
          type: 'success',
        });
      } catch (error) {
        // Error already being caught and displayed by graphql error handler
      } finally {
        setVerifyingQuery(false);
      }
    };

    verify();
  };

  const monacoScrollbar = useMemo(_ => {
    return { alwaysConsumeMouseWheel: false };
  }, []);

  const monacoMinimap = useMemo(_ => {
    return {
      enabled: false,
    };
  }, []);

  return (
    <div>
      <Row>
        <Col span={20} offset={4}>
          <p>Configure the options for the data to be imported.</p>
        </Col>
      </Row>
      <Form
        className="import-source"
        layout="horizontal"
        form={form}
        initialValues={{ pollInterval: 60 }}
        onValuesChange={onValuesChange}
      >
        <Form.Item name="type" hidden={true}>
          <Input />
        </Form.Item>
        <Form.Item
          name="dataSource"
          label="Source"
          initialValue=""
          {...DEFAULT_FORM_ITEM_PROPS}
        >
          <Select loading={objectsLoading} disabled={!showDatasource}>
            <Option value="">Kinetica Filesystem</Option>
            {datasources.length > 0 && (
              <OptGroup label="Data Sources">
                {datasources.map(datasource => (
                  <Option key={datasource.value} value={datasource.value}>
                    {datasource.label}
                  </Option>
                ))}
              </OptGroup>
            )}
          </Select>
        </Form.Item>
        {!isJdbc && !(isKafka || isConfluent) && (
          <Form.Item
            name="filePath"
            label="File Path"
            rules={[
              {
                required: false,
                message:
                  'Please enter a data source file path to the data you want to import.',
              },
            ]}
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Input.Group compact>
              <Input
                value={form.getFieldValue('filePath')}
                style={isBrowsable ? { width: 'calc(100% - 124.95px)' } : {}}
                disabled={!isBrowsable}
              />
              <span style={{ display: isBrowsable ? 'inline-block' : 'none' }}>
                <Button type="primary" onClick={toggleModal}>
                  Browse Files
                </Button>
                <DatasourceFileBrowserModal
                  visible={isModalVisible}
                  toggleFn={toggleModal}
                  form={form}
                />
              </span>
            </Input.Group>
          </Form.Item>
        )}
        {!isJdbc && (
          <Form.Item
            name="fileFormat"
            label="Format"
            rules={[
              {
                required: !(isKafka || isConfluent),
                message: 'Please select a format for the data.',
              },
            ]}
            {...DEFAULT_FORM_ITEM_PROPS}
          >
            <Select>
              <Option value="TEXT" disabled={isKafka || isConfluent}>
                CSV/TSV/PSV
              </Option>
              <Option value="PARQUET" disabled={isKafka || isConfluent}>
                Parquet
              </Option>
              <Option value="SHAPEFILE" disabled={isKafka || isConfluent}>
                Shapefile
              </Option>
              <Option value="JSON">JSON</Option>
              <Option value="AVRO">AVRO</Option>
              <Option value="GDB" disabled={isKafka || isConfluent}>
                GDB
              </Option>
            </Select>
          </Form.Item>
        )}
        {(isKafka || isConfluent) && (
          <>
            <Row>
              <Col span={4}></Col>
              <Col span={20}>
                <h4 style={{ marginBottom: 0 }}>Schema Registry</h4>
                <p style={{ fontSize: '14px', marginBottom: 10 }}>
                  Specify either an ID only or a Name (and Version if latest not
                  desired)
                </p>
              </Col>
            </Row>
            <Form.Item
              label="Schema ID"
              name="schemaRegistrySchemaID"
              rules={[
                {
                  required: false,
                  message: 'Please input schema registry schema ID!',
                },
              ]}
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Schema Name"
              name="schemaRegistrySchemaName"
              rules={[
                {
                  required: false,
                  message: 'Please input schema registry schema name!',
                },
              ]}
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Schema Version"
              name="schemaRegistrySchemaVersion"
              rules={[
                {
                  required: false,
                  message: 'Please input schema registry schema version!',
                },
              ]}
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Input />
            </Form.Item>
            <Row>
              <Col span={4}></Col>
              <Col span={20}>&nbsp;</Col>
            </Row>
            <Form.Item
              name="pollInterval"
              label="Poll Interval"
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <InputNumber placeholder={60} />
            </Form.Item>
          </>
        )}
        {isJdbc && (
          <>
            <Row>
              <Col span={4}></Col>
              <Col span={18}>
                <h4>
                  Specify a Table <strong>OR</strong> Custom SQL
                </h4>
              </Col>
            </Row>
            <Form.Item
              name="remoteTable"
              label="Remote Table"
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Input.Group compact>
                <Input
                  value={remoteTable}
                  placeholder="Enter name or click browse to select from list"
                  style={{ width: 'calc(100% - 125.05px)' }}
                  onChange={evt => {
                    form.setFieldsValue({ remoteTable: evt.target.value });
                  }}
                  disabled={hasRemoteQuery}
                />
                <span
                  style={{ display: isBrowsable ? 'inline-block' : 'none' }}
                >
                  <Button
                    type="primary"
                    onClick={toggleDatasourceTablesModal}
                    disabled={hasRemoteQuery}
                    ghost
                  >
                    Browse Tables
                  </Button>
                  {datasource && showDatasourceTablesModal && (
                    <DatasourceTablesModal
                      datasource={datasource}
                      handleDatasourceImport={handleDatasourceImport}
                      actionLabel="Select"
                      visible={showDatasourceTablesModal}
                      setVisible={visible => {
                        setShowDatasourceTablesModal(visible);
                      }}
                      width={800}
                      height={window.innerHeight - 350}
                      pageSize={Math.floor((window.innerHeight - 510) / 40)}
                    />
                  )}
                </span>
              </Input.Group>
            </Form.Item>
            <Form.Item
              name="remoteQuery"
              label="Remote Query"
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Space direction="vertical" style={{ width: '100%' }}>
                <MonacoEditor
                  ref={editorRef}
                  language="kinetica-sql"
                  contextmenu={false}
                  onChange={handleRemoteQueryChange}
                  renderLineHighlight={'none'}
                  scrollBeyondLastLine={false}
                  scrollbar={monacoScrollbar}
                  overviewRulerLanes={0}
                  automaticLayout={true}
                  minimap={monacoMinimap}
                  style={{
                    width: '100%',
                    height: `180px`,
                    border: '1px solid #D9D9D9',
                    pointerEvents: 'all',
                  }}
                ></MonacoEditor>
                <Button
                  type="primary"
                  onClick={handleVerifyQuery}
                  disabled={!hasRemoteQuery}
                  loading={verifyingQuery}
                  ghost
                >
                  Verify Remote Query
                </Button>
              </Space>
            </Form.Item>
            <Row>
              <Col span={4}></Col>
              <Col span={18}>
                <h4>Subscription Options</h4>
              </Col>
            </Row>
            <Form.Item
              name="subscribe"
              label="Enable"
              valuePropName="checked"
              onChange={handleSubscribeChange}
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Checkbox></Checkbox>
            </Form.Item>
            <Form.Item
              name="pollInterval"
              label="Poll Interval"
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <InputNumber placeholder={60} disabled={!isSubscribed} />
            </Form.Item>
            <Form.Item
              name="increasingColumn"
              label="Increasing Column"
              extra="Column in remote table or query schema"
              shouldUpdate={(prevValues, values) =>
                prevValues.increasingColumn !== values.increasingColumn
              }
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Input.Group compact>
                <Input
                  value={increasingColumn}
                  placeholder="Enter name or click browse to select from list"
                  style={{ width: 'calc(100% - 139.95px)' }}
                  onChange={evt => {
                    form.setFieldsValue({ increasingColumn: evt.target.value });
                  }}
                  disabled={!isSubscribed}
                />
                <span
                  style={{ display: isBrowsable ? 'inline-block' : 'none' }}
                >
                  <Button
                    type="primary"
                    onClick={toggleDatasourceTableColumnsModal}
                    disabled={!isSubscribed}
                    ghost
                  >
                    Browse Columns
                  </Button>
                  {datasource && showDatasourceTableColumnsModal && (
                    <DatasourceTableColumnsModal
                      form={form}
                      datasource={datasource}
                      handleColumnSelection={handleColumnSelection}
                      actionLabel="Select"
                      visible={showDatasourceTableColumnsModal}
                      setVisible={visible => {
                        setShowDatasourceTableColumnsModal(visible);
                      }}
                      width={800}
                      height={window.innerHeight - 350}
                      pageSize={Math.floor((window.innerHeight - 490) / 40)}
                    />
                  )}
                </span>
              </Input.Group>
            </Form.Item>
          </>
        )}
        <Form.Item
          shouldUpdate={(prevValues, values) =>
            prevValues.fileFormat !== values.fileFormat
          }
          style={{ marginBottom: '0px' }}
        >
          {_ => {
            const values = form.getFieldsValue();
            const { fileFormat } = values;
            return (
              <div>
                {fileFormat === 'TEXT' && (
                  <div>
                    <Row gutter={0}>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'DELIMITER']}
                          label="Delimiter"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                        >
                          <Input
                            maxLength={4}
                            addonAfter={
                              <InfoCircleOutlined onClick={openDelimiterHelp} />
                            }
                          ></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'ESCAPE']}
                          label="Escape Character"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'COMMENT']}
                          label="Comment Identifier"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={'#'}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'QUOTE']}
                          label="Quote Character"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          initialValue={'"'}
                        >
                          <Input maxLength={4}></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'NULL']}
                          label="Null Identifier"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 14 }}
                          initialValue={'\\N'}
                        >
                          <Input></Input>
                        </Form.Item>
                      </Col>
                      <Col span={12}>
                        <Form.Item
                          name={['formatOptions', 'INCLUDES HEADER']}
                          label="Has Header"
                          labelCol={{ span: 8 }}
                          wrapperCol={{ span: 12 }}
                          valuePropName="checked"
                          initialValue={true}
                        >
                          <Checkbox></Checkbox>
                        </Form.Item>
                      </Col>
                    </Row>
                  </div>
                )}
                {['GDB', 'SHAPEFILE'].includes(fileFormat) && (
                  <Form.Item
                    name="layers"
                    label="Layer(s)"
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 18 }}
                  >
                    <Input placeholder="Optional comma-delimited list of layer name(s)"></Input>
                  </Form.Item>
                )}
                {['JSON', 'SHAPEFILE'].includes(fileFormat) && (
                  <Row gutter={0}>
                    <Col span={12}>
                      <Form.Item
                        name="storePointsAsXY"
                        label="Store Points As X/Y"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        valuePropName="checked"
                      >
                        <Checkbox></Checkbox>
                      </Form.Item>
                    </Col>
                  </Row>
                )}
              </div>
            );
          }}
        </Form.Item>
      </Form>
    </div>
  );
}
