// Imports
import React from 'react';
import { Divider } from 'antd';

// App Imports

const color = '#3700b322';

const ReadOnlyDivider = ({ index }) => {
  return (
    <Divider
      id={`block-anchor-${index}`}
      style={{
        borderColor: color,
        margin: '0px',
        padding: '0px',
      }}
      dashed
    >
      <span style={{ color, fontWeight: 300, fontSize: '14px' }}>{index}</span>
    </Divider>
  );
};

export default ReadOnlyDivider;
