// Imports
import { gql, useQuery } from '@apollo/client';

export const GET_K8S_KINETICARELEASEVERSIONS = gql`
  query K8sKineticaReleaseVersions($name: String!, $deployment_type: String) {
    k8s_kineticareleaseversions(
      name: $name
      deployment_type: $deployment_type
    ) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaReleaseVersions = options => {
  return useQuery(GET_K8S_KINETICARELEASEVERSIONS, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
