// Imports
import React, { useState } from 'react';
import { Space, Button, Empty } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import * as DOMPurify from 'dompurify';

// App Imports
import VizTitleBar from './VizTitleBar';
import VizConfigDrawer from './VizConfigDrawer';
import { useIsReadOnly } from './utils';

const VizHtml = ({ viz, handleUpdate, isExample = false, minHeight = 180 }) => {
  const { config } = viz;

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(false);
  const readOnly = useIsReadOnly();

  const handleOpenVizConfig = _ => {
    setIsVizConfigOpen(true);
  };

  const handleCloseVizConfig = _ => {
    setIsVizConfigOpen(false);
  };

  const handleUpdateVizConfig = values => {
    handleUpdate(values, _ => {
      setIsVizConfigOpen(false);
    });
  };

  return (
    <div style={{ position: 'relative', minHeight }}>
      {!readOnly && (
        <div style={{ height: 30 }}>
          <Space style={{ float: 'right' }}>
            <Button
              icon={<SettingOutlined />}
              onClick={handleOpenVizConfig}
              size="small"
            >
              Config
            </Button>
          </Space>
        </div>
      )}
      <div
        style={{
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <VizTitleBar config={config} />
        <div className="image" style={{ minHeight: minHeight - 20 }}>
          {config?.html_source ? (
            <div
              dangerouslySetInnerHTML={
                isExample
                  ? {
                      __html: config?.html_source,
                    }
                  : {
                      __html: DOMPurify.sanitize(config?.html_source, {
                        ADD_ATTR: ['target'],
                      }),
                    }
              }
            />
          ) : (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="Please Configure HTML"
            />
          )}
          {!readOnly && (
            <VizConfigDrawer
              title="HTML Configuration"
              note="Source will be sanitized for security"
              fields={viz?.visualization_type?.params?.fields}
              viz={viz}
              config={viz?.config}
              options={{}}
              isOpen={isVizConfigOpen}
              handleClose={handleCloseVizConfig}
              handleUpdate={handleUpdateVizConfig}
              columns={4}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default VizHtml;
