// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_K8S_KINETICAOPERATORUPGRADES = gql`
  query K8sKineticaOperatorUpgrades {
    k8s_kineticaoperatorupgrades {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICAOPERATORUPGRADE_BY_NAME = gql`
  query GetK8sKineticaOperatorUpgradeByName($name: String!) {
    k8s_kineticaoperatorupgrade(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAOPERATORUPGRADE = gql`
  mutation CreateK8sKineticaOperatorUpgrade($name: String!, $body: JSON!) {
    kineticaOperatorUpgradeCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaOperatorUpgrades = () => {
  return useQuery(GET_K8S_KINETICAOPERATORUPGRADES, {
    fetchPolicy: 'no-cache',
  });
};

export const useGetK8sKineticaOperatorUpgradeByName = options => {
  return useQuery(GET_K8S_KINETICAOPERATORUPGRADE_BY_NAME, options);
};

export const useCreateK8SKineticaOperatorUpgrade = () => {
  return useMutation(CREATE_K8S_KINETICAOPERATORUPGRADE);
};
