// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_VISUALIZATIONS_BY_BLOCK_ID = gql`
  query GetVisualizationsByBlockId($block_id: ID!) {
    visualizationsByBlockId(block_id: $block_id) {
      id
      name
      description
      config
      visualization_type {
        id
        name
        description
        params
      }
      block_id
      created_at
      updated_at
    }
  }
`;

export const GET_VISUALIZATIONS_BY_BLOCK_IDS = gql`
  query GetVisualizationsByBlockIds($block_ids: [ID!]!) {
    visualizationsByBlockIds(block_ids: $block_ids) {
      id
      name
      description
      config
      visualization_type {
        id
        name
        description
        params
      }
      block_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_VISUALIZATION = gql`
  mutation CreateVisualization(
    $name: String!
    $description: String
    $config: JSON!
    $block_id: ID!
    $visualization_type_id: ID!
  ) {
    visualizationCreate(
      name: $name
      description: $description
      config: $config
      block_id: $block_id
      visualization_type_id: $visualization_type_id
    ) {
      id
      name
      description
      config
      visualization_type {
        id
        name
        description
        params
      }
      block_id
      created_at
      updated_at
    }
  }
`;

export const UPDATE_VISUALIZATION_BY_ID = gql`
  mutation UpdateVisualizationById($id: ID!, $config: JSON!) {
    visualizationUpdateById(id: $id, config: $config) {
      id
      name
      description
      config
      visualization_type {
        id
        name
        description
        params
      }
      block_id
      created_at
      updated_at
    }
  }
`;

export const REMOVE_VISUALIZATION_BY_ID = gql`
  mutation RemoveVisualizationById($id: ID!) {
    visualizationRemoveById(id: $id) {
      id
    }
  }
`;

export const useGetVisualizationsByBlockId = options => {
  return useQuery(GET_VISUALIZATIONS_BY_BLOCK_ID, options);
};

export const useGetVisualizationsByBlockIds = options => {
  return useQuery(GET_VISUALIZATIONS_BY_BLOCK_IDS, options);
};

export const useCreateVisualization = () => {
  return useMutation(CREATE_VISUALIZATION);
};

export const useUpdateVisualizationById = () => {
  return useMutation(UPDATE_VISUALIZATION_BY_ID);
};

export const useRemoveVisualizationById = () => {
  return useMutation(REMOVE_VISUALIZATION_BY_ID);
};
