// Imports
import React, { useMemo } from 'react';
import {
  Badge,
  Button,
  Modal,
  Popconfirm,
  Table,
  Tag,
  notification,
} from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import { InfoCircleOutlined, SendOutlined } from '@ant-design/icons';
import { useCreateImportJob, getWarnings } from './utils';
import { formatTimestamp } from '../../formatter';

const { Column } = Table;

export default function ImportHistory({ pageSize }) {
  const {
    data: importJobData,
    loading: importJobLoading,
    refetch,
  } = GraphQLServices.ImportJobs.useGetImportJobs();
  const [createImportJob] = useCreateImportJob();
  const [removeAllJobs, { loading: isRemoving }] =
    GraphQLServices.ImportJobs.useDeleteAllJobs();

  const importJobs = useMemo(
    _ => {
      if (importJobLoading || !importJobData.import_jobs) {
        return [];
      } else {
        return importJobData.import_jobs
          .map(job => ({
            ...job,
            params: JSON.parse(job.params),
          }))
          .sort((a, b) => {
            if (Number(a.created_at) > Number(b.created_at)) return -1;
            if (Number(a.created_at) < Number(b.created_at)) return 1;
            return 0;
          });
      }
    },
    [importJobLoading, importJobData]
  );

  const handleClearAllJobs = async _ => {
    await removeAllJobs();
    await refetch();
    notification.open({
      message: 'Import Jobs',
      description: 'All import job history has been cleared',
      type: 'success',
    });
  };

  const dateStyle = {
    letterSpacing: '-0.5px',
    fontSize: '12px',
    lineHeight: '14px',
    width: 75,
  };

  return (
    <div>
      <Popconfirm
        title="Are you sure you want to clear all import job history?"
        onConfirm={handleClearAllJobs}
      >
        <Button
          size="small"
          loading={isRemoving}
          disabled={isRemoving || importJobs.length === 0}
          style={{ float: 'right', marginBottom: '10px' }}
        >
          Clear All
        </Button>
      </Popconfirm>
      <Table
        dataSource={importJobs}
        loading={importJobLoading}
        rowKey="id"
        size="small"
        pagination={
          pageSize && {
            pageSize,
            pageSizeOptions: [pageSize],
            showSizeChanger: false,
          }
        }
      >
        <Column
          title="Created"
          dataIndex="created_at"
          render={text => {
            return <div style={dateStyle}>{formatTimestamp(text / 1000)}</div>;
          }}
        ></Column>
        <Column
          title="Status"
          dataIndex="status"
          render={text => {
            switch (text) {
              case 'CREATED':
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="default" text="Created"></Badge>
                  </div>
                );
              case 'ERROR':
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="error" text="Error"></Badge>
                  </div>
                );
              case 'CANCELLED':
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="warning" text="Cancelled"></Badge>
                  </div>
                );
              case 'RUNNING':
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="processing" text="Running"></Badge>
                  </div>
                );
              case 'DONE':
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="success" text="Done"></Badge>
                  </div>
                );
              default:
                return (
                  <div style={{ width: 70 }}>
                    <Badge status="default" text={text}></Badge>
                  </div>
                );
            }
          }}
          width={85}
        ></Column>
        <Column
          title="Source"
          dataIndex={['params', 'datasource']}
          render={text => {
            return <span>{text || 'KiFS'}</span>;
          }}
        ></Column>
        <Column
          title="File Path/Query"
          dataIndex={['params', 'filePath']}
          render={(_, record) => {
            return (
              <div style={{ fontSize: '13px', lineHeight: '16px' }}>
                {record?.params?.remoteQuery || record?.params?.filePath}
              </div>
            );
          }}
        ></Column>
        <Column
          title="Destination"
          render={(_, record) => {
            return (
              <span>
                {record.params.schema ? `${record.params.schema}.` : ''}
                {record.params.table}
              </span>
            );
          }}
        ></Column>
        <Column
          render={(_, record) => {
            return (
              <div style={{ width: 70 }}>
                <Button
                  icon={<InfoCircleOutlined />}
                  onClick={_ =>
                    Modal.info({
                      title: 'Job Details',
                      content: (
                        <div>
                          {record.job_id && (
                            <div style={{ float: 'right' }}>
                              <Tag color="blue" style={{ marginRight: 0 }}>
                                JOB ID #{record.job_id}
                              </Tag>
                            </div>
                          )}
                          {record.status === 'ERROR' && (
                            <div>
                              <h3>Error Message</h3>
                              <pre className="console error">
                                {record.response ||
                                  'Error message could not be recorded.'}
                              </pre>
                            </div>
                          )}
                          {record.status === 'DONE' &&
                            getWarnings(record).length > 0 && (
                              <pre className="console warning">
                                Warnings:
                                <br />
                                {getWarnings(record).map((warning, idx) => (
                                  <span key={idx}>
                                    {warning}
                                    <br />
                                  </span>
                                ))}
                              </pre>
                            )}
                          {record.sql && (
                            <>
                              <h3>Import SQL</h3>
                              <pre
                                style={{
                                  backgroundColor: '#eee',
                                  padding: '10px 15px',
                                  whiteSpace: 'pre-wrap',
                                }}
                              >
                                {record.sql}
                              </pre>
                            </>
                          )}
                          <h3>Creation Parameters</h3>
                          <pre
                            style={{
                              backgroundColor: '#eee',
                              padding: '10px 15px',
                            }}
                          >
                            {JSON.stringify(record.params, null, 4)}
                          </pre>
                        </div>
                      ),
                      width: 800,
                      centered: true,
                    })
                  }
                  style={{ marginRight: '5px' }}
                ></Button>
                <Popconfirm
                  title="Are you sure you want to retry this job?"
                  onConfirm={_ => {
                    createImportJob({ variables: { ...record.params } });
                  }}
                >
                  <Button icon={<SendOutlined />}></Button>
                </Popconfirm>
              </div>
            );
          }}
          width={80}
        ></Column>
      </Table>
    </div>
  );
}
