// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_DATASINKS = gql`
  query Datasinks {
    datasinks {
      datasink_name
      additional_info
      destination_type
    }
  }
`;

export const GET_DATASINK_BY_NAME = gql`
  query GetDatasinkByName($datasink_name: String!) {
    datasink(datasink_name: $datasink_name) {
      datasink_name
      additional_info
      destination_type
    }
  }
`;

export const CREATE_DATASINK = gql`
  mutation CreateDatasink(
    $name: String!
    $location: String!
    $user_name: String
    $password: String
    $skip_validation: Boolean
    $connection_timeout: Int
    $wait_timeout: Int
    $kafka_topic_name: String
    $jdbc_driver_class_name: String
    $jdbc_driver_jar_path: String
    $credential: String
  ) {
    datasinkCreate(
      name: $name
      location: $location
      user_name: $user_name
      password: $password
      skip_validation: $skip_validation
      connection_timeout: $connection_timeout
      wait_timeout: $wait_timeout
      kafka_topic_name: $kafka_topic_name
      jdbc_driver_class_name: $jdbc_driver_class_name
      jdbc_driver_jar_path: $jdbc_driver_jar_path
      credential: $credential
    ) {
      datasink_name
      additional_info
      destination_type
    }
  }
`;

export const UPDATE_DATASINK_BY_NAME = gql`
  mutation UpdateDatasinkByName(
    $name: String!
    $location: String!
    $user_name: String
    $password: String
    $skip_validation: Boolean
    $connection_timeout: Int
    $wait_timeout: Int
    $kafka_topic_name: String
    $credential: String
  ) {
    datasinkUpdateByName(
      name: $name
      location: $location
      user_name: $user_name
      password: $password
      skip_validation: $skip_validation
      connection_timeout: $connection_timeout
      wait_timeout: $wait_timeout
      kafka_topic_name: $kafka_topic_name
      credential: $credential
    ) {
      datasink_name
      additional_info
      destination_type
    }
  }
`;

export const DELETE_DATASINK_BY_NAME = gql`
  mutation DeleteDatasinkByName($datasink_name: String!) {
    datasinkDeleteByName(datasink_name: $datasink_name) {
      datasink_name
    }
  }
`;

export const useGetDatasinks = () => {
  return useQuery(GET_DATASINKS);
};

export const useGetDatasinkByName = options => {
  return useQuery(GET_DATASINK_BY_NAME, options);
};

export const useCreateDatasink = () => {
  return useMutation(CREATE_DATASINK);
};

export const useUpdateDatasinkByName = () => {
  return useMutation(UPDATE_DATASINK_BY_NAME);
};

export const useDeleteDatasinkByName = () => {
  return useMutation(DELETE_DATASINK_BY_NAME);
};
