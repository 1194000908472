// Imports
import React from 'react';
import { Modal, Button, Spin, Descriptions } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../common/Spinner';

const StreamInfoModal = ({ stream, visible, setVisible, width, height }) => {
  const { monitor_id } = stream;
  const { loading: streamLoading, data } =
    GraphQLServices.TableMonitors.useGetTableMonitorByName({
      variables: {
        monitor_id,
      },
    });

  return (
    <Modal
      title={`Stream Info: ${monitor_id}`}
      open={visible || streamLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={streamLoading}>
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflow: 'auto',
          }}
        >
          {data?.table_monitor && (
            <Descriptions title="Summary" size="small" column={1} bordered>
              <Descriptions.Item label="Table Name">
                {data?.table_monitor?.table_name}
              </Descriptions.Item>
              <Descriptions.Item label="Event">
                {data?.table_monitor?.event}
              </Descriptions.Item>
              {data?.table_monitor?.increasing_column && (
                <Descriptions.Item label="Increasing Column">
                  {data?.table_monitor?.increasing_column}
                </Descriptions.Item>
              )}
              {data?.table_monitor?.filter_expression && (
                <Descriptions.Item label="Filter Expression">
                  {data?.table_monitor?.filter_expression}
                </Descriptions.Item>
              )}
              <Descriptions.Item label="Refresh Method">
                {data?.table_monitor?.refresh_method}
              </Descriptions.Item>
              {data?.table_monitor?.refresh_perio && (
                <Descriptions.Item label="Refresh Period">
                  {data?.table_monitor?.refresh_period}
                </Descriptions.Item>
              )}
              {data?.table_monitor?.refresh_start_time && (
                <Descriptions.Item label="Refresh Start Time">
                  {data?.table_monitor?.refresh_start_time}
                </Descriptions.Item>
              )}
              {data?.table_monitor?.datasink_name && (
                <Descriptions.Item label="Data Sink Name">
                  {data?.table_monitor?.datasink_name}
                </Descriptions.Item>
              )}
            </Descriptions>
          )}
        </div>
      </Spin>
    </Modal>
  );
};

export default StreamInfoModal;
