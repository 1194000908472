// Imports
import { Card, Modal } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';

// App Imports
import useAnalytics from '../../hooks/useAnalytics';
import VideoTutorial from '../../images/workbench_intro_thumb.png';

const Resources = () => {
  const analytics = useAnalytics();

  const handleVideoPlayer = e => {
    analytics.track(analytics.EVENT_TYPES.OPENED_VIDEO_TUTORIAL)({
      title: 'Introduction to Workbench',
      url: 'https://www.youtube.com/watch?v=M2ucktLdpG4',
    });
    Modal.info({
      title: 'Introduction to Workbench',
      content: (
        <ReactPlayer
          url="https://www.youtube.com/watch?v=-wG0FxIUjv4"
          controls={true}
          width={780}
          height={440}
        />
      ),
      centered: true,
      width: 920,
    });
  };

  return (
    <Card
      title={
        <>
          <CopyOutlined style={{ marginRight: 10 }} />
          Resources
        </>
      }
      bordered={false}
      size="small"
      style={{
        height: '250px',
        overflow: 'hidden',
        padding: 0,
        borderRadius: 5,
      }}
      headStyle={{
        color: '#ffffff',
        fontSize: '20px',
        background:
          'linear-gradient(150deg, #9b45d899 15%, #5c48c199 70%, #5533ff99 94%)',
      }}
    >
      <div style={{ padding: '10px 20px' }}>
        <div style={{ float: 'left', marginRight: 40 }}>
          <h4>Video Tutorials</h4>
          {[1].map(item => (
            <div
              key={item}
              onClick={handleVideoPlayer}
              style={{
                display: 'inline-block',
                cursor: 'pointer',
              }}
            >
              <img
                src={VideoTutorial}
                style={{
                  height: '120px',
                  maxWidth: '100%',
                }}
                alt="Tutorial Video"
              />
            </div>
          ))}
        </div>
      </div>
    </Card>
  );
};

export default Resources;
