// Imports
import { gql, useQuery } from '@apollo/client';

export const GET_K8S_VELERORESTORES = gql`
  query K8sVeleroRestores {
    k8s_velerorestores {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sVeleroRestores = options => {
  return useQuery(GET_K8S_VELERORESTORES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
