// Imports
import { useEffect, useMemo, useState } from 'react';
import { Table, Button, Spin, Descriptions, Collapse } from 'antd';
import { RedoOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import Spinner from '../../components/common/Spinner';

const UDFStatus = ({
  proc_name,
  rowSelection,
  actions,
  is_block = false,
  auto_refresh = 0,
  style = {},
}) => {
  const { loading: udfsLoading, data: { udfs } = { udfs: [] } } =
    GraphQLServices.UDFs.useGetUDFs({
      variables: {
        proc_name,
      },
    });
  const {
    loading: udfStatusesLoading,
    data: { udf_statuses } = { udf_statuses: [] },
    refetch: refetchUDFStatuses,
  } = GraphQLServices.UDFs.useGetUDFStatuses({
    pollInterval: auto_refresh,
  });

  const [showDetail, setShowDetail] = useState(!is_block);

  useEffect(_ => {
    if (auto_refresh > 0) {
      setTimeout(() => {
        refetchUDFStatuses();
      }, 200);
    }
  });

  const udf = useMemo(
    _ => {
      if (udfs && udfs.length > 0) {
        return udfs[0];
      }
      return null;
    },
    [udfs]
  );

  const udfColumns = useMemo(_ => {
    return [
      {
        key: 'execution_mode',
        title: 'Execution',
        dataIndex: 'execution_mode',
      },
      {
        key: 'command',
        title: 'Command',
        dataIndex: 'command',
      },
      {
        key: 'args',
        title: 'Args',
        dataIndex: 'args',
        render: args => {
          return args.length > 0 ? (
            args.map(arg => {
              return <div key={arg}>{arg}</div>;
            })
          ) : (
            <i style={{ color: '#cccccc' }}>None</i>
          );
        },
      },
      {
        key: 'options',
        title: 'Options',
        dataIndex: 'options',
        render: options => {
          return Object.keys(options).length > 0 ? (
            Object.keys(options).map(key => {
              return (
                <div key={key}>
                  {key}: {options[key]}
                </div>
              );
            })
          ) : (
            <i style={{ color: '#cccccc' }}>None</i>
          );
        },
      },
    ];
  }, []);

  const segmentColumns = useMemo(
    _ => {
      return is_block
        ? [
            {
              key: 'segment_id',
              title: 'ID',
              dataIndex: 'segment_id',
              render: segment_id => {
                return <div style={{ width: 40 }}>{segment_id}</div>;
              },
            },
            {
              key: 'status',
              title: 'Status',
              dataIndex: 'status',
              render: status => {
                return <div style={{ width: 80 }}>{status}</div>;
              },
            },
            {
              key: 'message',
              title: 'Message',
              dataIndex: 'message',
              render: message => {
                return <div style={{ width: 140 }}>{message}</div>;
              },
            },
            {
              key: 'output',
              title: 'Output',
              dataIndex: 'output',
              width: '96%',
              render: output => {
                return output && Object.keys(output).length > 0
                  ? Object.keys(output).map(key => {
                      return (
                        <Collapse
                          key={key}
                          className="output-collapse"
                          bordered={false}
                          size="small"
                          style={{ minWidth: 500 }}
                        >
                          <Collapse.Panel
                            header={
                              <>
                                <h4 style={{ marginBottom: 0 }}>{key}</h4>
                                {output[key].slice(0, 3).map((line, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      style={{
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        margin: '1px 1px 0px 1px',
                                        fontFamily: 'monospace',
                                      }}
                                    >
                                      {line}
                                    </div>
                                  );
                                })}
                              </>
                            }
                            collapsible={!is_block}
                          >
                            {output[key].slice(3).map((line, idx) => {
                              return (
                                <div
                                  key={idx}
                                  style={{
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    margin: '0px 1px 1px 9px',
                                    fontFamily: 'monospace',
                                  }}
                                >
                                  {line}
                                </div>
                              );
                            })}
                          </Collapse.Panel>
                        </Collapse>
                      );
                    })
                  : '';
              },
            },
            {
              key: 'timings',
              title: 'Timings',
              dataIndex: 'timings',
              render: timings => {
                return (
                  <Descriptions
                    className="timings"
                    size="small"
                    column={1}
                    style={{ width: 160 }}
                    bordered
                  >
                    {Object.keys(timings).map(key => {
                      return (
                        <Descriptions.Item
                          key={key}
                          label={key}
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                            fontWeight: key === 'total' ? 'bold' : 'normal',
                            fontSize: '13px',
                          }}
                        >
                          {timings[key] > 0
                            ? Math.round(
                                (timings[key] / 100 + Number.EPSILON) * 100
                              ) / 100
                            : '0.00'}{' '}
                          s
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                );
              },
            },
            {
              key: 'result',
              title: 'Result',
              dataIndex: 'result',
              render: result => {
                return Object.keys(result).length > 0 ? (
                  <pre
                    style={{
                      width: 360,
                      fontSize: '12px',
                      margin: '2px 1px 0px 1px',
                      fontFamily: 'monospace',
                      overflowX: 'scroll',
                    }}
                  >
                    {JSON.stringify(result, null, 2)}
                  </pre>
                ) : (
                  <div style={{ width: 360 }}></div>
                );
              },
            },
          ]
        : [
            {
              key: 'segment_id',
              title: 'ID',
              dataIndex: 'segment_id',
              render: segment_id => {
                return <div style={{ width: 40 }}>{segment_id}</div>;
              },
            },
            {
              key: 'status',
              title: 'Status',
              dataIndex: 'status',
              render: status => {
                return <div style={{ width: 80 }}>{status}</div>;
              },
            },
            {
              key: 'timings',
              title: 'Timings',
              dataIndex: 'timings',
              render: timings => {
                return (
                  <Descriptions
                    className="timings"
                    size="small"
                    column={1}
                    style={{ width: 160 }}
                    bordered
                  >
                    {Object.keys(timings).map(key => {
                      return (
                        <Descriptions.Item
                          key={key}
                          label={key}
                          style={{
                            whiteSpace: 'nowrap',
                            textAlign: 'right',
                            fontWeight: key === 'total' ? 'bold' : 'normal',
                          }}
                        >
                          {timings[key] > 0
                            ? Math.round(
                                (timings[key] / 100 + Number.EPSILON) * 100
                              ) / 100
                            : '0.00'}{' '}
                          s
                        </Descriptions.Item>
                      );
                    })}
                  </Descriptions>
                );
              },
            },
            {
              key: 'result',
              title: 'Result',
              dataIndex: 'result',
              render: result => {
                return Object.keys(result).length > 0 ? (
                  <pre
                    style={{
                      width: 360,
                      fontSize: '12px',
                      margin: '2px 1px 0px 1px',
                      fontFamily: 'monospace',
                      overflowX: 'scroll',
                    }}
                  >
                    {JSON.stringify(result, null, 2)}
                  </pre>
                ) : (
                  <div style={{ width: 360 }}></div>
                );
              },
            },
            {
              key: 'message',
              title: 'Message',
              dataIndex: 'message',
              render: message => {
                return <div style={{ width: 140 }}>{message}</div>;
              },
            },
            {
              key: 'output',
              title: 'Output',
              dataIndex: 'output',
              width: '96%',
              render: output => {
                return output && Object.keys(output).length > 0
                  ? Object.keys(output).map(key => {
                      return (
                        <Collapse
                          key={key}
                          className="output-collapse"
                          bordered={false}
                          size="small"
                        >
                          <Collapse.Panel
                            header={
                              <>
                                <h4 style={{ marginBottom: 0 }}>{key}</h4>
                                {output[key].slice(0, 3).map((line, idx) => {
                                  return (
                                    <div
                                      key={idx}
                                      style={{
                                        fontSize: '12px',
                                        lineHeight: '14px',
                                        margin: '1px 1px 0px 1px',
                                        fontFamily: 'monospace',
                                      }}
                                    >
                                      {line}
                                    </div>
                                  );
                                })}
                              </>
                            }
                          >
                            {output[key].slice(3).map((line, idx) => {
                              return (
                                <div
                                  key={idx}
                                  style={{
                                    fontSize: '12px',
                                    lineHeight: '14px',
                                    margin: '0px 1px 1px 9px',
                                    fontFamily: 'monospace',
                                  }}
                                >
                                  {line}
                                </div>
                              );
                            })}
                          </Collapse.Panel>
                        </Collapse>
                      );
                    })
                  : '';
              },
            },
          ];
    },
    [is_block]
  );

  const udfStatusColumns = useMemo(
    _ => {
      return [
        {
          key: 'run_id',
          title: 'Run ID',
          dataIndex: 'run_id',
          fixed: 'left',
          render: run_id => {
            return (
              <div style={{ padding: is_block ? '4px 5px' : '8px 5px' }}>
                {run_id}
              </div>
            );
          },
        },
        {
          key: 'overall_status',
          title: 'Status',
          dataIndex: 'overall_status',
          render: overall_status => {
            return (
              <div style={{ padding: is_block ? '4px 5px' : '8px 5px' }}>
                {overall_status}
              </div>
            );
          },
        },
        {
          key: 'segments',
          title: 'Segments',
          dataIndex: 'run_id',
          render: (_, item) => {
            const segments = Object.keys(item.statuses).map(segment_id => {
              return {
                segment_id,
                status: item.statuses[segment_id],
                result: item.results[segment_id],
                message: item.messages[segment_id],
                output: item.output ? item.output[segment_id] : null,
                timings: item.timings[segment_id],
              };
            });
            return (
              <Table
                columns={segmentColumns}
                dataSource={segments}
                rowKey="segment_id"
                pagination={false}
                scroll={{
                  x: 'max-content',
                }}
                size="small"
                style={{ margin: 0 }}
              />
            );
          },
        },
      ];
    },
    [segmentColumns, is_block]
  );

  const udfStatuses = useMemo(
    _ => {
      return udf && udf_statuses
        ? udf_statuses.filter(status => status.proc_name === udf.proc_name)
        : [];
    },
    [udf_statuses, udf]
  );

  const handleToggleDetail = _ => {
    setShowDetail(!showDetail);
  };

  return udf && (!is_block || udfStatuses.length > 0) ? (
    <div style={style}>
      {auto_refresh > 0 && (
        <Button
          key="refresh"
          onClick={_ => refetchUDFStatuses()}
          icon={<RedoOutlined />}
          loading={auto_refresh > 0}
          style={{ float: 'right', marginBottom: '10px', marginLeft: '10px' }}
          size="small"
        ></Button>
      )}
      {actions}
      {is_block ? (
        <h4 style={{ display: 'inline-block' }}>UDF Status</h4>
      ) : (
        <h3 style={{ display: 'inline-block' }}>UDF Status: {udf.proc_name}</h3>
      )}
      {is_block && (
        <Button
          type="link"
          size="small"
          onClick={handleToggleDetail}
          style={{ display: 'inline-block' }}
        >
          {showDetail ? 'Hide Detail' : 'Show Detail'}
        </Button>
      )}
      {showDetail && (
        <Spin indicator={<Spinner />} spinning={udfsLoading}>
          <Table
            columns={udfColumns}
            dataSource={[udf]}
            rowKey="proc_name"
            pagination={false}
            scroll={{
              x: 'max-content',
            }}
            style={{ marginBottom: is_block ? 10 : 30 }}
            size="small"
          />
        </Spin>
      )}
      <Spin indicator={<Spinner />} spinning={udfStatusesLoading}>
        <Table
          columns={udfStatusColumns}
          dataSource={udfStatuses}
          rowKey="run_id"
          rowSelection={rowSelection}
          pagination={false}
          scroll={{
            x: 'max-content',
          }}
          size="small"
        />
      </Spin>
    </div>
  ) : (
    ''
  );
};

export default UDFStatus;
