// Imports
import { useQuery, gql, useMutation, useLazyQuery } from '@apollo/client';

export const GET_IMPORT_JOBS = gql`
  query {
    import_jobs {
      id
      job_id
      user_id
      status
      progress
      params
      sql
      response
      created_at
      updated_at
    }
  }
`;

export const GET_IMPORT_JOB_DDL = gql`
  query GetImportJobDdl(
    $schema: String!
    $table: String!
    $createOptions: JSON!
  ) {
    import_job_ddl(
      schema: $schema
      table: $table
      createOptions: $createOptions
    ) {
      ddl
    }
  }
`;

export const GET_IMPORT_JOB_BY_ID = gql`
  query GetImportJobById($id: ID) {
    import_job(id: $id) {
      id
      job_id
      user_id
      status
      progress
      params
      sql
      response
      created_at
      updated_at
    }
  }
`;

export const CREATE_IMPORT_JOB = gql`
  mutation CreateImportJob(
    $type: String!
    $isCDATA: Boolean
    $table: String
    $schema: String!
    $createOptions: JSON
    $datasource: JSON
    $filePath: String
    $fileFormat: String
    $remoteQuery: String
    $formatOptions: JSON
    $abortOnError: Boolean
    $badRecordSchema: String
    $badRecordTable: String
    $columnFormats: JSON
    $layers: String
    $storePointsAsXY: Boolean
    $subscribe: Boolean
    $pollInterval: Int
    $increasingColumn: String
    $batchSize: String
    $createSchema: Boolean
    $schemaRegistrySchemaID: String
    $schemaRegistrySchemaName: String
    $schemaRegistrySchemaVersion: String
  ) {
    createImportJob(
      type: $type
      isCDATA: $isCDATA
      table: $table
      schema: $schema
      createOptions: $createOptions
      datasource: $datasource
      filePath: $filePath
      fileFormat: $fileFormat
      remoteQuery: $remoteQuery
      formatOptions: $formatOptions
      abortOnError: $abortOnError
      badRecordSchema: $badRecordSchema
      badRecordTable: $badRecordTable
      columnFormats: $columnFormats
      layers: $layers
      storePointsAsXY: $storePointsAsXY
      subscribe: $subscribe
      pollInterval: $pollInterval
      increasingColumn: $increasingColumn
      batchSize: $batchSize
      createSchema: $createSchema
      schemaRegistrySchemaID: $schemaRegistrySchemaID
      schemaRegistrySchemaName: $schemaRegistrySchemaName
      schemaRegistrySchemaVersion: $schemaRegistrySchemaVersion
    ) {
      id
      job_id
      user_id
      status
      progress
      params
      sql
      response
      created_at
      updated_at
    }
  }
`;

export const DELETE_ALL_JOBS = gql`
  mutation DeleteAllJobs {
    jobsDeleteAll
  }
`;

export const useGetImportJobs = () => {
  return useQuery(GET_IMPORT_JOBS);
};

export const useGetImportJobDdl = options =>
  useQuery(GET_IMPORT_JOB_DDL, options);

export const useCreateImportJob = () => {
  return useMutation(CREATE_IMPORT_JOB);
};

export const useGetImportJobById = () => {
  return useLazyQuery(GET_IMPORT_JOB_BY_ID);
};

export const useDeleteAllJobs = () => {
  return useMutation(DELETE_ALL_JOBS);
};
