// Imports
import React from 'react';
import { Dropdown } from 'antd';
import {
  ImportOutlined,
  DeleteOutlined,
  SettingOutlined,
  AuditOutlined,
  DiffOutlined,
  TableOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const DatasourceObject = ({
  nodeData,
  handleDatasourceEdit,
  handleDatasourceDelete,
  handleDatasourceImport,
  handleDatasourceTables,
  handleDatasourceHistory,
  handleMultiSelect,
}) => {
  const datasourceContextMenu = node => {
    const { additional_info, storage_provider_type } = node.source;

    const items = [];

    if (additional_info) {
      items.push({
        key: 'configure',
        label: 'Configure',
        icon: <SettingOutlined />,
        onClick: _ => handleDatasourceEdit(node.source),
      });
    }

    items.push({
      key: 'import',
      label: 'Import',
      icon: <ImportOutlined />,
      onClick: _ => handleDatasourceImport(node.source),
    });

    if (storage_provider_type.toLowerCase().includes('jdbc')) {
      items.push({
        key: 'tables',
        label: 'Tables',
        icon: <TableOutlined />,
        onClick: _ => handleDatasourceTables(node.source),
      });
    }

    const defaults = [
      {
        key: 'history',
        label: 'History',
        icon: <AuditOutlined />,
        onClick: _ => handleDatasourceHistory(node.source),
      },
      {
        type: 'divider',
      },
      {
        key: 'delete',
        label: 'Delete',
        icon: <DeleteOutlined />,
        onClick: _ => handleDatasourceDelete(node.source),
        style: { color: '#C62828' },
      },
      {
        type: 'divider',
      },
      {
        key: 'multiselect',
        label: 'Multi-Select',
        icon: <DiffOutlined />,
        onClick: _ => handleMultiSelect(node),
        style: { color: '#3700b3' },
      },
    ];

    items.push(...defaults);

    return {
      items,
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={datasourceContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <ImportOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 105 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default DatasourceObject;
