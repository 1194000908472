// Imports
import React, { useMemo } from 'react';
import { Tabs } from 'antd';
import {
  DatabaseOutlined,
  BookOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';

// App Imports
import DataExplorer from './DataExplorer';
import WorkbookExplorer from './WorkbookExplorer';
import FileExplorer from './FileExplorer';
import Usage from './Usage';
import useUsage from '../../hooks/useUsage';
import { setExplorerCurrentTab } from '../../store/app/actions';
import { FREE_SAAS } from '../../setup/config';

const noop = () => {};

// Component
const Explorer = () => {
  const { explorerCurrentTab } = useSelector(state => state.app);
  const dispatch = useDispatch();

  const { usage, refetch } = useUsage();

  const callback = current => {
    dispatch(setExplorerCurrentTab(current));
  };

  const tabStyle = useMemo(_ => {
    return {
      padding: '0px 15px',
    };
  }, []);

  const offset = useMemo(() => {
    const filtered = usage.filter(rank => rank.limit !== 'Unlimited');
    return filtered.length > 0 ? filtered.length * 46 + 10 : 0;
  }, [usage]);

  const exploreItems = useMemo(
    _ => {
      return [
        {
          key: 'data',
          label: (
            <div style={tabStyle}>
              <DatabaseOutlined />
              Data
            </div>
          ),
          children: (
            <DataExplorer
              offset={offset}
              refetchUsage={FREE_SAAS ? refetch : noop}
            />
          ),
        },
        {
          key: 'workbooks',
          label: (
            <div style={tabStyle}>
              <BookOutlined />
              Workbooks
            </div>
          ),
          children: (
            <WorkbookExplorer
              offset={offset}
              refetchUsage={FREE_SAAS ? refetch : noop}
            />
          ),
        },
        {
          key: 'files',
          label: (
            <div style={tabStyle}>
              <CopyOutlined />
              Files
            </div>
          ),
          children: (
            <FileExplorer
              offset={offset}
              refetchUsage={FREE_SAAS ? refetch : noop}
            />
          ),
        },
      ];
    },
    [offset, refetch, tabStyle]
  );

  return (
    <div className="explorer">
      {FREE_SAAS && <Usage usage={usage} />}
      <Tabs
        activeKey={explorerCurrentTab}
        items={exploreItems}
        onChange={callback}
        animated={{ inkBar: true, tabPane: false }}
        tabBarStyle={{ width: 350 }}
        centered
      ></Tabs>
    </div>
  );
};

export default Explorer;
