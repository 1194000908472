// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_UDF_EXISTS = gql`
  query UDFExists($proc_name: String) {
    udf_exists(proc_name: $proc_name) {
      proc_name
      proc_exists
    }
  }
`;

export const GET_UDFS = gql`
  query UDFs($proc_name: String, $options: JSON) {
    udfs(proc_name: $proc_name, options: $options) {
      proc_name
      execution_mode
      files
      command
      args
      options
    }
  }
`;

export const GET_UDF_STATUSES = gql`
  query UDFStatuses($run_id: Int, $options: JSON) {
    udf_statuses(run_id: $run_id, options: $options) {
      run_id
      proc_name
      params
      bin_params
      input_table_names
      input_column_names
      output_table_names
      options
      overall_status
      statuses
      messages
      results
      bin_results
      output
      timings
    }
  }
`;

export const KILL_PROC_BY_ID = gql`
  mutation KillProcById($run_id: Int!) {
    killProcById(run_id: $run_id) {
      run_id
    }
  }
`;

export const useGetUDFExists = _ => {
  return useQuery(GET_UDF_EXISTS);
};

export const useGetUDFs = options => {
  return useQuery(GET_UDFS, options);
};

export const useGetUDFStatuses = options => {
  return useQuery(GET_UDF_STATUSES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useKillProcById = () => {
  return useMutation(KILL_PROC_BY_ID);
};
