// Imports
import React, { useState, useCallback } from 'react';
import { Modal, Button, Spin, Upload } from 'antd';
import { ExclamationCircleOutlined, InboxOutlined } from '@ant-design/icons';

// App Imports
import Spinner from '../common/Spinner';
import PlanGraph from '../../components/explain/PlanGraph';
import { download } from '../../containers/dataexplore/utils';

const { confirm } = Modal;
const { Dragger } = Upload;

const ExplainPlanModal = ({
  plan,
  visible,
  setVisible,
  handleExplainPlan,
  loading,
  width,
  height,
  legend = false,
}) => {
  const [canShow, setCanShow] = useState(true);

  const [showImport, setShowImport] = useState(false);
  const [fileToUpload, setFileToUpload] = useState(null);
  const [importPlan, setImportPlan] = useState(null);

  const explain = run => {
    setCanShow(false);
    setShowImport(false);
    setImportPlan(null);
    setFileToUpload(null);
    setTimeout(() => {
      handleExplainPlan(run, _ => {
        setCanShow(true);
      });
    }, 200);
  };

  const exportPlan = plan => {
    confirm({
      title: `Do you want to export this plan?`,
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes',
      onOk() {
        download(
          `explain_plan_${111111 + Math.round(Math.random() * 999999)}.json`,
          JSON.stringify(plan),
          'application/json'
        );
      },
      cancelText: 'No',
      onCancel() {
        // Do nothing
      },
      width: 600,
    });
  };

  const beforeUpload = useCallback(file => {
    setFileToUpload(file);
    return false;
  }, []);

  const onChange = useCallback(
    info => {
      fileToUpload.text().then(data => {
        setCanShow(false);
        setTimeout(() => {
          try {
            setImportPlan(JSON.parse(data));
            setCanShow(true);
          } catch (error) {
            console.error(error);
          }
        }, 200);
      });
    },
    [fileToUpload]
  );

  return (
    <Modal
      title="Explain Plan"
      open={visible}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <Button
          key="explain"
          type="primary"
          onClick={_ => explain(false)}
          ghost
        >
          Explain Plan
        </Button>,
        <Button key="run" type="primary" onClick={_ => explain(true)} ghost>
          Run and Analyze
        </Button>,
        <Button
          key="export"
          onClick={_ => exportPlan(plan)}
          disabled={showImport}
        >
          Export
        </Button>,
        <Button
          key="import"
          onClick={_ => setShowImport(true)}
          disabled={showImport}
        >
          Import
        </Button>,
        <Button key="close" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin indicator={<Spinner />} spinning={loading}>
        <div
          style={{
            height: showImport ? height - 245 : height - 40,
            overflow: 'hidden',
          }}
        >
          {canShow && importPlan
            ? plan?.error || <PlanGraph plan={importPlan} />
            : ''}
          {canShow && plan && !importPlan
            ? plan?.error || <PlanGraph plan={plan} legend={legend} />
            : ''}
        </div>
        {showImport && (
          <div style={{ height: 205 }}>
            <div style={{ height: 185 }}>
              <Dragger
                multiple={false}
                maxCount={1}
                fileList={fileToUpload ? [fileToUpload] : []}
                beforeUpload={beforeUpload}
                onChange={onChange}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Please select an explain plan (JSON) file to upload.
                </p>
              </Dragger>
            </div>
          </div>
        )}
      </Spin>
    </Modal>
  );
};

export default ExplainPlanModal;
