// Imports
import React, { useMemo } from 'react';
import { Form, Row, Col } from 'antd';

// App Imports
import { getGraphField as getField, getFieldInfo } from '../../helper';
import { capitalizeSnakecase } from '../../formatter';

const CreateGraphOptionsForm = ({ grammar, docs }) => {
  const validOptions = useMemo(
    _ => {
      return (
        grammar?.options.filter(option => {
          // Don't display internal options
          return (
            !option.internal || (option.internal && option.internal !== 'true')
          );
        }) ?? []
      );
    },
    [grammar]
  );

  const DEFAULT_FORM_ITEM_PROPS = {
    labelCol: { span: 14 },
    wrapperCol: { span: 10 },
  };

  return (
    <div>
      <Row gutter={0}>
        {validOptions.map(field => {
          return (
            <Col key={field.name} span={11}>
              <Form.Item
                label={
                  <>
                    {capitalizeSnakecase(field.name)}
                    {getFieldInfo(docs[field.name])}
                  </>
                }
                name={field.name}
                {...DEFAULT_FORM_ITEM_PROPS}
              >
                {getField(field)}
              </Form.Item>
            </Col>
          );
        })}
      </Row>
    </div>
  );
};

export default CreateGraphOptionsForm;
