// Imports
import { useEffect, useState } from 'react';

// App Imports
import GraphQLServices from '../graphql/services';
import { capitalizeSnakecase } from '../formatter';
import { FREE_SAAS } from '../setup/config';
import {
  RESOURCE_GROUP_MAX_VALUE,
  RESOURCE_GROUP_MAX_ALIAS,
} from '../constants';

const checkUnlimited = value => {
  if (`${value}` === RESOURCE_GROUP_MAX_VALUE) {
    return capitalizeSnakecase(RESOURCE_GROUP_MAX_ALIAS);
  }
  return value;
};

const useUsage = () => {
  const [usage, setUsage] = useState([]);

  const { data: { userMe: userMeUsage = {} } = {}, refetch } =
    GraphQLServices.Users.useGetUserMeKineticaUsage({
      skip: !FREE_SAAS,
    });

  useEffect(() => {
    if (userMeUsage?.kinetica_user?.resource_group) {
      const data = Object.keys(userMeUsage?.kinetica_user?.resource_group)
        .filter(
          key =>
            !key.includes('__') &&
            !['name', 'alias', 'max_data', 'tier_attributes'].includes(key) &&
            typeof userMeUsage?.kinetica_user?.resource_group[key] === 'object'
        )
        .reduce((acc, key) => {
          const { ranks } = userMeUsage?.kinetica_user?.resource_group[key];
          const data = Object.keys(ranks).map(rank => {
            return {
              rank,
              ...ranks[rank],
            };
          });
          const metrics = Object.keys(ranks)
            .reduce((acc, rank) => {
              const columns = Object.keys(ranks[rank]).filter(
                column => column !== 'thread_running_count'
              );
              return [...new Set([...acc, ...columns])];
            }, [])
            .sort(function (a, b) {
              return a
                .replaceAll('.used', '')
                .toLowerCase()
                .localeCompare(b.replaceAll('.used', '').toLowerCase());
            });

          return metrics
            .filter(column => column.toLowerCase() === 'data')
            .map(column => {
              let max = RESOURCE_GROUP_MAX_VALUE;
              if (column.toLowerCase() === 'data') {
                max = userMeUsage?.kinetica_user?.resource_group.max_data;
              } else if (column.includes('.')) {
                const { tier_attributes } =
                  userMeUsage?.kinetica_user?.resource_group;
                const tier = column.split('.')[0];
                const match = tier_attributes.find(attr => {
                  return attr.name === tier;
                });
                if (match) {
                  max = match['max_memory'];
                }
              }
              return data.map(item => {
                return {
                  usage: item['data'],
                  limit: checkUnlimited(max),
                };
              });
            })[0];
        }, []);
      setUsage(data);
    }
  }, [userMeUsage]);

  return { usage, refetch };
};

export default useUsage;
