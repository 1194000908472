/* eslint-disable react-hooks/exhaustive-deps */
// Imports
import React, { useState, useEffect, useMemo } from 'react';
import { Breadcrumb, Row, Col, Statistic, Card, Table, Spin } from 'antd';
import { useApolloClient } from '@apollo/client';
import { utcFormat } from 'd3-time-format';
import * as d3 from 'd3';

// App Imports
import GraphQLServices from '../../graphql/services';
import useMetricInterval from '../../hooks/useMetricInterval';
import MetricChart from './MetricChart';
import { GET_METRICS } from '../../graphql/schema/metrics';
import TimeRangeSelector, { useTimeRange } from './TimeRangeSelector';
import { DEPLOYMENT_TYPE } from '../../setup/config';
import Spinner from '../../components/common/Spinner';
import {
  formatK8sTimestamp,
  formatCapitalizeFirstLetter,
} from '../../formatter';

const DEFAULT_BASE_CHART_PROPS = {
  margin: { top: 5, right: 20, bottom: 40, left: 40 },
  xScale: { type: 'time', format: 'native' },
  yScale: { type: 'linear', stacked: false },
  axisBottom: {
    format: utcFormat('%H:%M'),
    tickValues: 5,
  },
  axisLeft: {
    format: '.2s',
    tickValues: 5,
  },
  enableSlices: 'x',
  enablePoints: false,
  animate: false,
  colors: { scheme: 'paired' },
};

const DEFAULT_USAGE_CHART_PROPS = {
  ...DEFAULT_BASE_CHART_PROPS,
  margin: { top: 5, right: 20, bottom: 40, left: 50 },
  axisLeft: {
    ...DEFAULT_BASE_CHART_PROPS.axisLeft,
    format: '.1%',
  },
  yScale: {
    ...DEFAULT_BASE_CHART_PROPS.yScale,
    min: 0,
  },
};

const percentageFormat = value => (value * 100).toFixed(2) + '%';
const floatFormat = value => value.toFixed(2);
export const requestPerSecondFormat = value => {
  return value > 1 ? d3.format('.2s')(value) : d3.format('.2f')(value);
};

const COLUMNS = [
  {
    key: 'type',
    title: 'Activity',
    dataIndex: 'type',
    render: text => {
      return formatCapitalizeFirstLetter(text);
    },
  },
  {
    key: 'creationTimestamp',
    title: 'Initiated',
    dataIndex: 'creationTimestamp',
    width: 200,
    render: text => {
      return formatK8sTimestamp(text);
    },
  },
];

const METRIC_PARAMS = [
  {
    key: 'cpu_usage',
    title: 'CPU Usage',
    endpoint: '/api/v1/query_range',
    query:
      'round(sum(ki_host_cpu{dev="",what="user"} / ignoring(what) ki_host_cpu{what="count"}) by (cluster, host) / 100, 0.001)',
    chartProps: {
      yFormat: percentageFormat,
    },
  },
  {
    key: 'memory_usage',
    title: 'Memory Usage',
    endpoint: '/api/v1/query_range',
    query:
      'round(sum(ki_host_mem{what="used"} / ignoring(what) (ki_host_mem{what="used"} + ignoring(what) ki_host_mem{what="free"} + ignoring(what) ki_host_mem{what="buffers"} + ignoring(what) ki_host_mem{what="cached"})) by (cluster, host), 0.001)',
    chartProps: {
      yFormat: percentageFormat,
    },
  },
  {
    key: 'disk_usage',
    title: 'Disk Usage',
    endpoint: '/api/v1/query_range',
    query:
      'round(sum(ki_host_disk{what="used"} / ignoring(what) (ki_host_disk{what="used"} + ignoring(what) ki_host_disk{what="free"})) by (cluster, host, dev), 0.001)',
    chartProps: {
      yFormat: percentageFormat,
    },
  },
  {
    key: 'requests_per_second',
    title: 'Requests Per Second',
    id: 'Requests Per Second',
    endpoint: '/api/v1/query_range',
    query: 'sum(rate(ki_db_requests{what="count",endpoint=~"(.*)"}[5m]))',
    chartProps: {
      yFormat: floatFormat,
      axisLeft: {
        ...DEFAULT_BASE_CHART_PROPS.axisLeft,
        format: requestPerSecondFormat,
      },
    },
  },
  {
    key: 'database_tables',
    title: 'Table Count',
    id: 'Table Count',
    endpoint: '/api/v1/query_range',
    query: 'sum(ki_db_data{what="tables"})',
    chartProps: {
      yFormat: floatFormat,
      axisLeft: {
        ...DEFAULT_BASE_CHART_PROPS.axisLeft,
        format: requestPerSecondFormat,
      },
    },
  },
  {
    key: 'database_records',
    title: 'Record Count',
    id: 'Record Count',
    endpoint: '/api/v1/query_range',
    query: 'sum(ki_db_data{what="records"})',
    chartProps: {
      yFormat: floatFormat,
      axisLeft: {
        ...DEFAULT_BASE_CHART_PROPS.axisLeft,
        format: requestPerSecondFormat,
      },
    },
  },
  {
    key: 'recv_bytes',
    title: 'Received Bytes',
    id: 'Received Bytes',
    endpoint: '/api/v1/query_range',
    query: 'sum(ki_db_net{what="recv_bytes"})',
    chartProps: {
      yFormat: floatFormat,
      axisLeft: {
        ...DEFAULT_BASE_CHART_PROPS.axisLeft,
        format: floatFormat,
      },
    },
  },
  {
    key: 'send_bytes',
    title: 'Sent Bytes',
    id: 'Sent Bytes',
    endpoint: '/api/v1/query_range',
    query: 'sum(ki_db_net{what="send_bytes"})',
    chartProps: {
      yFormat: floatFormat,
      axisLeft: {
        ...DEFAULT_BASE_CHART_PROPS.axisLeft,
        format: floatFormat,
      },
    },
  },
];

const POLL_INTERVAL = 4000;

// Component
const Metrics = () => {
  const {
    loading: jobsLoading,
    data: { k8s_kineticaclusteradmins } = {},
    refetch: refetchJobs,
  } = GraphQLServices.K8sKineticaClusterAdmins.useGetK8sKineticaClusterAdmins({
    variables: {
      deployment_type: DEPLOYMENT_TYPE,
    },
  });

  const graphqlClient = useApolloClient();
  const [metrics, setMetrics] = useState([]);

  const { relative, absolute, getDateRange } = useTimeRange();
  const [{ startDate, endDate, step }, setTimeState] = useState({
    startDate: new Date(),
    endDate: new Date(),
    step: 1,
  });

  const crs = useMemo(
    _ => {
      return k8s_kineticaclusteradmins
        ? new Array(1)
            .fill(
              k8s_kineticaclusteradmins
                .map(cr => {
                  return {
                    name: cr?.metadata?.name,
                    cluster: cr?.spec?.kineticaClusterName,
                    type: cr?.spec?.offline?.offline ? 'suspend' : 'resume',
                    creationTimestamp: cr?.metadata?.creationTimestamp,
                    source: cr,
                  };
                })
                .sort((a, b) => {
                  if (a.creationTimestamp > b.creationTimestamp) return -1;
                  if (a.creationTimestamp < b.creationTimestamp) return 1;
                  return 0;
                })
            )
            .flat()
        : [];
    },
    [k8s_kineticaclusteradmins]
  );

  const fetchData = async _ => {
    try {
      const { startDate: start, endDate: end } = getDateRange();
      const step = Math.ceil((end.getTime() - start.getTime()) / 1000 / 60);

      setTimeState({
        startDate: start,
        endDate: end,
        step,
      });

      const queryStart = Math.floor(start / 1000);
      const queryEnd = Math.floor(end / 1000);

      const {
        data: { metrics },
      } = await graphqlClient.query({
        query: GET_METRICS,
        variables: {
          params: METRIC_PARAMS.map(param => {
            return {
              ...param,
              start: queryStart,
              end: queryEnd,
              step,
            };
          }),
        },
      });
      setMetrics(
        metrics.map(metric => {
          return {
            ...metric,
            data: {
              ...metric.data,
              result: metric.data.result.map(result => {
                return {
                  ...result,
                  last: result.values[result.values.length - 1],
                };
              }),
            },
          };
        })
      );

      refetchJobs();
    } catch (error) {
      console.error(error);
      setMetrics([]);
    }
  };

  useEffect(
    _ => {
      const { startDate: start, endDate: end } = getDateRange();
      setTimeState({
        startDate: start,
        endDate: end,
        step: Math.ceil((end.getTime() - start.getTime()) / 1000 / 60),
      });

      fetchData();
    },
    [
      relative,
      absolute ? absolute.start.format() : null,
      absolute ? absolute.end.format() : null,
    ]
  );

  useMetricInterval(fetchData, absolute ? null : POLL_INTERVAL);

  return (
    <>
      <Breadcrumb style={{ margin: '9px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
        <Breadcrumb.Item>Manage</Breadcrumb.Item>
        <Breadcrumb.Item>Metrics</Breadcrumb.Item>
      </Breadcrumb>
      <div style={{ float: 'right', marginTop: '5px' }}>
        <TimeRangeSelector />
      </div>
      <h2>Metrics</h2>
      <Row gutter={20} style={{ marginBottom: '20px' }}>
        <Col span={8}>
          <Card
            size="small"
            style={{
              borderRadius: '10px',
              border: '1px solid #eeeeee',
              padding: '0px 8px',
            }}
          >
            <Statistic
              title="Records"
              value={
                metrics.find(metric => metric.key === 'database_records')?.data
                  ?.result[0]?.last[1] ?? 0
              }
              precision={0}
              valueStyle={{ color: '#3688ad' }}
              suffix=""
            />
          </Card>
        </Col>
        <Col span={8}>
          <Card
            size="small"
            style={{
              borderRadius: '10px',
              border: '1px solid #eeeeee',
              padding: '0px 8px',
            }}
          >
            <Statistic
              title="Tables"
              value={
                metrics.find(metric => metric.key === 'database_tables')?.data
                  ?.result[0]?.last[1] ?? 0
              }
              precision={0}
              valueStyle={{ color: '#3688ad' }}
              suffix=""
            />
          </Card>
        </Col>
      </Row>
      <Row gutter={20}>
        {metrics.map(metric => {
          const metric_param = METRIC_PARAMS.find(
            param => param.key === metric.key
          );
          return (
            <Col key={metric.key} span={8}>
              <MetricChart
                title={metric_param.title}
                lineId={metric_param.id}
                data={metric.data}
                height={220}
                startDate={startDate}
                endDate={endDate}
                step={step}
                chartProps={{
                  ...DEFAULT_USAGE_CHART_PROPS,
                  colors: { datum: 'color' },
                  ...metric_param.chartProps,
                }}
              />
            </Col>
          );
        })}
        <Col key="admin" span={8}>
          <div style={{ width: '100%', height: 220, marginBottom: '20px' }}>
            <div className="ki_metric_chart">
              <label>Suspend/Resume History</label>
              <Spin indicator={<Spinner />} spinning={jobsLoading}>
                <div style={{ height: 175, overflowY: 'scroll' }}>
                  <Table
                    columns={COLUMNS}
                    dataSource={crs}
                    rowKey="name"
                    pagination={false}
                    scroll={{
                      x: 'max-content',
                    }}
                    size="small"
                  />
                </div>
              </Spin>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default Metrics;
