// Imports
import React from 'react';
import { Descriptions, Form, Table } from 'antd';

// App Imports
import { formatFriendlyNumberWhole } from '../../formatter';

function humanBoolean(bool) {
  return bool ? 'Yes' : 'No';
}

function filterProperties(col, prop) {
  switch (prop) {
    case 'primary_key':
    case 'shard_key':
      if (col?.storage !== 'store_only') {
        return true;
      }
      break;
    case 'disk_optimized':
      if (
        col?.storage === 'data' &&
        col?.type?.[0] === 'string' &&
        col?.type?.[1] === ''
      ) {
        return true;
      }
      break;
    case 'dict':
      if (
        ((col?.type?.[0] === 'int' && col?.type?.[1] === '') ||
          col?.type?.[0] === 'long' ||
          col?.type?.[1] === 'date' ||
          (col?.type?.[1] || '').startsWith('char')) &&
        col?.storage !== 'store_only'
      ) {
        return true;
      }
      break;
    case 'timestamp':
      if (col.type[0] === 'long') {
        return true;
      }
      break;
    case 'init_with_now':
      if (
        col?.type?.[0] === 'string' &&
        (col?.type?.[1] === 'date' ||
          col?.type?.[1] === 'datetime' ||
          col?.type?.[1] === 'time')
      ) {
        return true;
      }
      break;
    case 'text_search':
      if (
        col?.type?.[0] === 'string' &&
        (col?.type?.[1] === '' ||
          (col?.type?.[1] || '').startsWith('char') ||
          col?.type?.[1] === 'date' ||
          col?.type?.[1] === 'datetime' ||
          col?.type?.[1] === 'time' ||
          col?.type?.[1] === 'ipv4' ||
          col?.type?.[1] === 'uuid')
      ) {
        return true;
      }
      break;
    case 'init_with_uuid':
      if (col.type[1] === 'uuid') {
        return true;
      }
      break;
    default:
      return true;
  }

  return false;
}

export default function TableSummary({ form, columns }) {
  return (
    <Form.Item shouldUpdate={true}>
      {_ => {
        const value = form.getFieldsValue();
        return (
          <div>
            <Descriptions
              title="Source Summary"
              size="small"
              column={2}
              bordered
            >
              <Descriptions.Item label="Data Source">
                {value?.options?.datasource_name || 'Kinetica Filesystem'}
              </Descriptions.Item>
              <Descriptions.Item label="File Path">
                {value.filepath || 'Unspecified'}
              </Descriptions.Item>
              <Descriptions.Item label="Table Type">
                {value?.options?.external_table_type || 'Use Default'}
              </Descriptions.Item>
              <Descriptions.Item label="File Format">
                {value?.options?.file_type}
              </Descriptions.Item>
              {value?.options?.file_type === 'delimited_text' && (
                <>
                  <Descriptions.Item label="Delimiter">
                    {value?.options?.text_delimiter || 'Use Default'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Escape Character">
                    {value?.options?.text_escape_character || 'Use Default'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Comment Identifier">
                    {value?.options?.text_comment_string || 'Use Default'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Quote Character">
                    {value?.options?.text_quote_character || 'Use Default'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Null Identifier">
                    {value?.options?.text_null_string || 'Use Default'}
                  </Descriptions.Item>
                  <Descriptions.Item label="Has Header">
                    {humanBoolean(value?.options?.text_has_header)}
                  </Descriptions.Item>
                </>
              )}
            </Descriptions>
            <br />
            <Descriptions
              title="Destination Summary"
              size="small"
              column={2}
              bordered
            >
              <Descriptions.Item label="Schema">
                {value.tableSchema || 'User Default'}
              </Descriptions.Item>
              <Descriptions.Item label="Name">
                {value.tableName || 'Unspecified'}
              </Descriptions.Item>
              <Descriptions.Item label="Chunk Size">
                {formatFriendlyNumberWhole(value?.createOptions?.chunk_size) ||
                  'Default (8,000,000)'}
              </Descriptions.Item>
              <Descriptions.Item label="Dimension Table">
                {humanBoolean(value?.createOptions?.is_replicated)}
              </Descriptions.Item>
              <Descriptions.Item label="Temporary Table">
                {humanBoolean(value?.createOptions?.is_result_table)}
              </Descriptions.Item>
              <Descriptions.Item label="Partition Type">
                {value?.createOptions?.partition_type || 'None'}
              </Descriptions.Item>
              <Descriptions.Item label="Foreign Keys">
                {value?.createOptions?.foreign_keys || 'Unspecified'}
              </Descriptions.Item>
              <Descriptions.Item label="Foreign Shard Key">
                {value?.createOptions?.foreign_shard_key || 'Unspecified'}
              </Descriptions.Item>
            </Descriptions>
            <br />
            <h3>Columns</h3>
            <Table
              dataSource={columns}
              pagination={{ pageSize: 5 }}
              size="small"
              locale={{
                emptyText: 'Infer column definitions from data.',
              }}
            >
              <Table.Column
                title="Name"
                dataIndex="columnName"
                key="columnName"
              ></Table.Column>
              <Table.Column
                title="Type"
                dataIndex="type"
                key="type"
                render={(_, record) => {
                  return (
                    <span>
                      {record.type
                        ? record.type.filter(t => !!t).join(' / ')
                        : ''}
                    </span>
                  );
                }}
              ></Table.Column>
              <Table.Column
                title="Nullable"
                dataIndex="nullable"
                key="nullable"
                render={value => <span>{value ? 'Yes' : 'No'}</span>}
              ></Table.Column>
              <Table.Column
                title="Properties"
                dataIndex="properties"
                key="properties"
                render={(_, record) => {
                  return (
                    <span>
                      {record.properties
                        ? record.properties
                            .filter(prop => filterProperties(record, prop))
                            .join(', ')
                        : ''}
                    </span>
                  );
                }}
              ></Table.Column>
            </Table>
          </div>
        );
      }}
    </Form.Item>
  );
}
