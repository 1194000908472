// Imports
import { Card, Space, Button, Tooltip, Row, Col } from 'antd';
import { CloudUploadOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

// App Imports
import { NAV_ROUTE_IMPORTEXPORT, CDATA_CONFIG } from '../../constants';
import GraphQLServices from '../../graphql/services';
import ImportImg from '../../images/import.png';
import Spinner from '../../components/common/Spinner';
import { IMPORT_CATALOG } from '../../constants';

const LOADING = (
  <Col
    span={24}
    style={{
      height: 300,
      textAlign: 'center',
      paddingTop: 120,
      backgroundColor: '#ffffff33',
    }}
  >
    <Spinner position="static" />
  </Col>
);

const Import = () => {
  const {
    loading: cdataSourcesLoading,
    data: { cdata_drivers: CDATA_CATALOG = [] } = {},
  } = GraphQLServices.CDataDrivers.useGetCDataDriversSummary({
    variables: {
      filter: CDATA_CONFIG.FEATURED,
    },
  });

  const history = useHistory();

  const handleOneTimeImportClick = e => {
    history.push(NAV_ROUTE_IMPORTEXPORT);
  };

  return (
    <>
      <Card
        title={
          <>
            <CloudUploadOutlined style={{ marginRight: 10 }} />
            Import
          </>
        }
        bordered={false}
        size="small"
        style={{
          height: '530px',
          overflow: 'hidden',
          padding: 0,
          borderRadius: 5,
        }}
        headStyle={{
          color: '#ffffff',
          fontSize: '20px',
          background:
            'linear-gradient(150deg, #9b45d899 15%, #5c48c199 70%, #5533ff99 94%)',
        }}
      >
        <div style={{ padding: '10px 10px 10px 20px' }}>
          <Row gutter={20}>
            <Col span={10}>
              <Space direction="vertical">
                <div style={{ textAlign: 'center' }}>
                  <img
                    src={ImportImg}
                    style={{ maxHeight: 170, maxWidth: '100%', margin: 'auto' }}
                    alt="Import"
                    loading="lazy"
                  />
                </div>
                <Button
                  type="primary"
                  icon={<CloudUploadOutlined />}
                  onClick={handleOneTimeImportClick}
                  size="large"
                  shape="round"
                  block
                  ghost
                >
                  Import My Data
                </Button>
                {IMPORT_CATALOG.length > 0 && (
                  <div style={{ marginTop: 10 }}>
                    <h4 style={{ marginBottom: 15 }}>Kinetica Data Sources</h4>
                    {IMPORT_CATALOG.map(({ route, title, img, color }) => (
                      <Tooltip key={route} title={title}>
                        <div
                          onClick={() => history.push(route)}
                          style={{
                            cursor: 'pointer',
                            backgroundColor: '#f6f6f6',
                            backgroundImage: `linear-gradient(#ffffff 40%, ${color}44 90%, ${color}66 100%)`,
                            border: '1px solid #eeeeee',
                            borderRadius: 60,
                            margin: '0px 4px 8px 4px',
                            display: 'block',
                            float: 'left',
                          }}
                        >
                          <img
                            {...{
                              ...img,
                              style: {
                                margin: '9px',
                                height: 30,
                                width: 30,
                              },
                            }}
                            alt={img.alt}
                            loading="lazy"
                          />
                        </div>
                      </Tooltip>
                    ))}
                  </div>
                )}
              </Space>
            </Col>
            <Col span={14}>
              {!cdataSourcesLoading && CDATA_CATALOG.length > 0 ? (
                <>
                  <div style={{ marginTop: 10 }}>
                    <h3 style={{ marginBottom: 15 }}>Featured Data Sources</h3>
                    <div style={{ height: 360, overflowY: 'scroll' }}>
                      {CDATA_CATALOG.map(({ route, title, img }) => (
                        <Tooltip key={route} title={title}>
                          <div
                            onClick={() => history.push(route)}
                            style={{
                              cursor: 'pointer',
                              backgroundColor: '#f6f6f6',
                              border: '1px solid #eeeeee',
                              borderRadius: 60,
                              margin: '0px 6px 12px 6px',
                              display: 'block',
                              float: 'left',
                            }}
                          >
                            <img
                              {...{
                                ...img,
                                style: {
                                  margin: '8px',
                                  height: 40,
                                  width: 40,
                                },
                              }}
                              alt={img.alt}
                              loading="lazy"
                            />
                          </div>
                        </Tooltip>
                      ))}
                    </div>
                  </div>
                  <h4
                    style={{ color: '#999999', cursor: 'pointer' }}
                    onClick={handleOneTimeImportClick}
                  >
                    PLUS 200+ Additional Data Sources
                  </h4>
                </>
              ) : (
                LOADING
              )}
            </Col>
          </Row>
        </div>
      </Card>
    </>
  );
};

export default Import;
