import React from 'react';

const BlockIndex = ({ index }) => {
  return (
    <label
      style={{
        position: 'absolute',
        top: '-11px',
        width: '33px',
        color: '#aaaaaa',
        fontSize: '12px',
        textAlign: 'center',
      }}
    >
      <span
        id={`block-anchor-${index}`}
        style={{ backgroundColor: '#ffffff', padding: '0px 5px' }}
      >
        {index > 0 && index}
      </span>
    </label>
  );
};

export default BlockIndex;
