// Imports
import React, { useMemo } from 'react';
import {
  Form,
  Select,
  Button,
  Row,
  Col,
  Divider,
  Space,
  AutoComplete,
} from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  getGraphComponent as getComponent,
  isGraphComponent as isComponent,
  getGraphField as getField,
  getFieldInfo,
  formatGraphIdentifiers as formatIdentifiers,
} from '../../helper';
import { capitalizeSnakecase } from '../../formatter';

const { Option } = Select;

const CREATE_GRAPH_MAPPINGS = {};

const handleComponentValueFilter = (inputValue, option) => {
  return option.value.includes(inputValue);
};

const DEFAULT_FORM_ITEM_PROPS = {
  labelCol: { span: 5 },
  wrapperCol: { span: 17 },
};

const CreateGraphConfigureForm = ({
  fields,
  grammar,
  componentAdditions,
  setComponentAdditions,
  componentSelections,
  setComponentSelections,
  docs,
}) => {
  const { data: { tableColumnNames = [] } = {} } =
    GraphQLServices.Tables.useGetTableColumnNames();

  const handleComponentChange = field => idx => {
    const { configurations } = getComponent(
      field,
      grammar,
      CREATE_GRAPH_MAPPINGS
    );
    setComponentSelections({
      ...componentSelections,
      [field.name]: configurations[idx]?.identifiers,
    });
  };

  const handleComponentAdd = field => idx => {
    const selection = componentSelections[field.name] ?? [];
    if (selection.length > 0) {
      const optionals = grammar?.components.find(comp => {
        return comp.name.toLowerCase() === field.name;
      })?.addons;
      setComponentAdditions({
        ...componentAdditions,
        [field.name]: [
          ...(componentAdditions[field.name] ?? []),
          {
            set: selection
              .map(item => {
                return {
                  name: item,
                  value: '',
                  optional: false,
                };
              })
              .concat(
                optionals.map(item => {
                  return {
                    name: item,
                    value: '',
                    optional: true,
                  };
                })
              ),
          },
        ],
      });

      const selectionCopy = { ...componentSelections };
      delete selectionCopy[field.name];
      setComponentSelections(selectionCopy);
    }
  };

  const handleComponentDelete = (field, idx) => e => {
    const additionCopy = { ...componentAdditions };
    additionCopy[field.name].splice(idx, 1);
    setComponentAdditions({
      ...componentAdditions,
      [field.name]: [...additionCopy[field.name]],
    });
  };

  const handleComponentValueChange = (field, idx1, idx2, item) => value => {
    const updatedFieldAdditions = [...componentAdditions[field.name]];
    updatedFieldAdditions[idx1].set[idx2].value = value;
    setComponentAdditions({
      ...componentAdditions,
      [field.name]: updatedFieldAdditions,
    });
  };

  const columns = useMemo(
    _ => {
      return tableColumnNames.reduce((acc, cur) => {
        const { schema, name: table, columns } = cur;
        const update = columns.map(column => {
          return {
            value: [schema, table, column].join('.'),
          };
        });
        return [...acc, ...update];
      }, []);
    },
    [tableColumnNames]
  );

  return (
    <div>
      {fields
        .filter(
          field =>
            field.name !== 'options' &&
            !isComponent(field, grammar, CREATE_GRAPH_MAPPINGS)
        )
        .map(field => {
          return (
            <Form.Item
              key={field.name}
              label={
                <>
                  {capitalizeSnakecase(field.name)}
                  {getFieldInfo(docs[field.name])}
                </>
              }
              name={field.name}
              rules={
                field.name === 'graph_name' && [
                  {
                    required: true,
                    message: 'Please input graph name!',
                    whitespace: true,
                  },
                ]
              }
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              {getField(field)}
            </Form.Item>
          );
        })}
      {fields
        .filter(
          field =>
            field.name !== 'options' &&
            isComponent(field, grammar, CREATE_GRAPH_MAPPINGS)
        )
        .map(field => {
          return (
            <Form.Item
              key={field.name}
              label={
                <>
                  {capitalizeSnakecase(field.name)}
                  {getFieldInfo(docs[field.name])}
                </>
              }
              {...DEFAULT_FORM_ITEM_PROPS}
            >
              <Row gutter={10}>
                <Col span={20}>
                  <Select
                    value={
                      componentSelections[field.name]
                        ? formatIdentifiers(componentSelections[field.name])
                        : ''
                    }
                    onChange={handleComponentChange(field)}
                    placeholder={`Select ${capitalizeSnakecase(
                      field.name
                    )} to add`.toUpperCase()}
                    style={{ fontSize: '13px' }}
                    dropdownStyle={{ fontSize: '12px' }}
                  >
                    {getComponent(
                      field,
                      grammar,
                      CREATE_GRAPH_MAPPINGS
                    )?.configurations.map((configuration, index) => {
                      return (
                        <Option key={index} value={index}>
                          {formatIdentifiers(configuration?.identifiers)}
                        </Option>
                      );
                    })}
                  </Select>
                </Col>
                <Col span={4}>
                  <Button
                    onClick={handleComponentAdd(field)}
                    icon={<PlusOutlined />}
                    disabled={!componentSelections[field.name]}
                    block
                  >
                    Add
                  </Button>
                </Col>
              </Row>
              {componentAdditions[field.name]?.map((addition, idx) => {
                return (
                  <div key={idx}>
                    <Divider style={{ margin: '12px 0' }} dashed />
                    <Row key={idx} gutter={10}>
                      <Col span={20} style={{ display: 'flex' }}>
                        <Space direction="vertical" style={{ width: '100%' }}>
                          {addition?.set.map((item, idx2) => {
                            return (
                              <Row key={idx2} gutter={10}>
                                <Col span={12}>
                                  <AutoComplete
                                    value={item.value}
                                    options={columns}
                                    filterOption={handleComponentValueFilter}
                                    onChange={handleComponentValueChange(
                                      field,
                                      idx,
                                      idx2,
                                      item
                                    )}
                                    allowClear={true}
                                    placeholder={item.optional && 'Optional'}
                                    dropdownMatchSelectWidth={false}
                                  />
                                </Col>
                                <Col
                                  span={2}
                                  style={{
                                    textAlign: 'center',
                                    fontSize: '12px',
                                    lineHeight: '28px',
                                    color: '#999999',
                                  }}
                                >
                                  as
                                </Col>
                                <Col
                                  span={10}
                                  style={{
                                    fontSize: '12px',
                                    lineHeight: '28px',
                                  }}
                                >
                                  {item.name}
                                </Col>
                              </Row>
                            );
                          })}
                        </Space>
                      </Col>
                      <Col span={4}>
                        <Button
                          onClick={handleComponentDelete(field, idx)}
                          icon={<DeleteOutlined />}
                          block
                        ></Button>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </Form.Item>
          );
        })}
    </div>
  );
};

export default CreateGraphConfigureForm;
