// Imports
import React, { useContext, useMemo } from 'react';
import { Breadcrumb, Col, Row, Space } from 'antd';

// App Imports
import Workbooks from './Workbooks';
import Import from './Import';
import Connect from './Connect';
import Reveal from './Reveal';
import Resources from './Resources';
import GraphQLServices from '../../graphql/services';
import { DEPLOYMENT_TYPE } from '../../setup/config';
import { FREE_SAAS } from '../../setup/config';
import { ClusterContext } from '../../context';

// Component
const Landing = () => {
  const { data: { warehouses = [] } = {} } =
    GraphQLServices.Warehouses.useGetWarehouses();

  const { clusters, loading } = useContext(ClusterContext);

  const isCloud = DEPLOYMENT_TYPE === 'cloud';

  const revealEnabled = useMemo(() => {
    if (!FREE_SAAS && warehouses && warehouses.length > 0) {
      return isCloud && warehouses[0].reveal_enabled;
    }
    return false;
  }, [isCloud, warehouses]);

  return (
    <>
      <Breadcrumb style={{ margin: '9px 0' }}>
        <Breadcrumb.Item>Home</Breadcrumb.Item>
      </Breadcrumb>
      <h2>Welcome to Workbench</h2>
      <Space direction="vertical" size="middle" style={{ width: '100%' }}>
        <Row gutter={16}>
          <Col span={24}>
            <Workbooks />
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={14}>
            <Import />
          </Col>
          <Col span={10}>
            <Connect
              clusters={clusters}
              clustersLoading={loading}
              warehouses={warehouses}
            />
          </Col>
        </Row>
        {revealEnabled ? (
          <Row gutter={16}>
            <Col span={14}>
              <Reveal />
            </Col>
            <Col span={10}>
              <Resources />
            </Col>
          </Row>
        ) : (
          <Row gutter={16}>
            <Col span={24}>
              <Resources />
            </Col>
          </Row>
        )}
        <div style={{ height: '4px', width: '100%' }}></div>
      </Space>
    </>
  );
};

export default Landing;
