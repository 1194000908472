// Imports
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import * as monaco from 'monaco-editor';
import { ApolloConsumer, useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import WorksheetEditor from './WorksheetEditor';
import { ReadOnlyContext, PrintOnlyContext } from './utils';
import { GET_WORKBOOK_BY_ID } from '../../graphql/schema/workbooks';

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

// Component
const DataExplorePrint = () => {
  const { worksheetId } = useParams();
  const graphqlClient = useApolloClient();

  const [workbook, setWorkbook] = useState(undefined);

  const { data: { worksheet = undefined } = {} } =
    GraphQLServices.Worksheets.useGetWorksheetOnlyById({
      variables: {
        id: worksheetId,
      },
    });

  const { data: { tableTokens } = [] } =
    GraphQLServices.Tables.useGetTableTokens();

  useEffect(
    _ => {
      if (worksheet) {
        graphqlClient
          .query({
            query: GET_WORKBOOK_BY_ID,
            variables: {
              id: worksheet.workbook_id,
            },
          })
          .then(resp => {
            setWorkbook(resp?.data?.workbook);
          });
      }
    },
    [graphqlClient, worksheet]
  );

  useEffect(
    _ => {
      let disposable;
      if (tableTokens) {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }

        disposable = monaco.languages.registerCompletionItemProvider(
          'kinetica-sql',
          {
            provideCompletionItems() {
              const dynamicSuggestions = [];

              tableTokens.tables.forEach(table => {
                // Add fully qualified name
                dynamicSuggestions.push({
                  label: table ?? '',
                  kind: monaco.languages.CompletionItemKind.Value,
                  insertText: table,
                });
              });

              tableTokens.columns.forEach(column => {
                dynamicSuggestions.push({
                  label: column ?? '',
                  kind: monaco.languages.CompletionItemKind.Value,
                  insertText: column,
                });
              });

              return {
                suggestions: [...defaultSuggestions, ...dynamicSuggestions],
              };
            },
          }
        );
      }

      return _ => {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }
      };
    },
    [tableTokens]
  );

  return workbook && worksheet ? (
    <ReadOnlyContext.Provider value={true}>
      <PrintOnlyContext.Provider value={true}>
        <div
          className="printScroll"
          style={{ padding: '30px 40px', backgroundColor: '#ffffff' }}
        >
          <h2
            style={{
              width: '100%',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              marginBottom: 0,
            }}
          >
            {workbook.name}
            {' - '}
            {worksheet.name}
          </h2>
          <ApolloConsumer>
            {client => (
              <WorksheetEditor
                key={worksheetId}
                embed={true}
                print={true}
                apolloClient={client}
                worksheetId={worksheetId}
                workbook={{
                  id: worksheet.workbookId,
                }}
              />
            )}
          </ApolloConsumer>
        </div>
      </PrintOnlyContext.Provider>
    </ReadOnlyContext.Provider>
  ) : (
    <></>
  );
};

export default DataExplorePrint;
