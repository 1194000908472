// Imports
import { useMutation, useQuery, gql } from '@apollo/client';

export const GET_BLOCKS = gql`
  query {
    blocks {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const GET_BLOCKS_BY_WORKSHEET_ID = gql`
  query GetBlocksByWorksheetId($worksheet_id: ID!) {
    blocksByWorksheetId(worksheet_id: $worksheet_id) {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_BLOCK = gql`
  mutation CreateBlock(
    $name: String!
    $description: String
    $content: String
    $config: JSON!
    $previous_block_id: ID
    $next_block_id: ID
    $block_type_id: ID
    $worksheet_id: ID!
  ) {
    blockCreate(
      name: $name
      description: $description
      content: $content
      config: $config
      previous_block_id: $previous_block_id
      next_block_id: $next_block_id
      block_type_id: $block_type_id
      worksheet_id: $worksheet_id
    ) {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_IMAGE_BLOCK = gql`
  mutation CreateImageBlock(
    $name: String!
    $description: String
    $content: String
    $config: JSON!
    $previous_block_id: ID
    $next_block_id: ID
    $block_type_id: ID
    $worksheet_id: ID!
  ) {
    blockImageCreate(
      name: $name
      description: $description
      content: $content
      config: $config
      previous_block_id: $previous_block_id
      next_block_id: $next_block_id
      block_type_id: $block_type_id
      worksheet_id: $worksheet_id
    ) {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const CREATE_HTML_BLOCK = gql`
  mutation CreateHtmlBlock(
    $name: String!
    $description: String
    $content: String
    $config: JSON!
    $previous_block_id: ID
    $next_block_id: ID
    $block_type_id: ID
    $worksheet_id: ID!
  ) {
    blockHtmlCreate(
      name: $name
      description: $description
      content: $content
      config: $config
      previous_block_id: $previous_block_id
      next_block_id: $next_block_id
      block_type_id: $block_type_id
      worksheet_id: $worksheet_id
    ) {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const UPDATE_BLOCK_BY_ID = gql`
  mutation UpdateBlockById(
    $id: ID!
    $name: String
    $description: String
    $content: String
    $config: JSON
    $previous_block_id: ID
    $next_block_id: ID
    $block_type_id: ID
    $worksheet_id: ID
  ) {
    blockUpdateById(
      id: $id
      name: $name
      description: $description
      content: $content
      config: $config
      previous_block_id: $previous_block_id
      next_block_id: $next_block_id
      block_type_id: $block_type_id
      worksheet_id: $worksheet_id
    ) {
      id
      name
      description
      content
      config
      previous_block_id
      next_block_id
      block_type {
        id
        name
        description
      }
      worksheet_id
      created_at
      updated_at
    }
  }
`;

export const REMOVE_BLOCK_BY_ID = gql`
  mutation RemoveBlockById($id: ID!) {
    blockRemoveById(id: $id) {
      id
    }
  }
`;

export const REMOVE_UPDATE_BLOCK_BY_ID = gql`
  mutation RemoveUpdateBlockById($id: ID!) {
    blockRemoveUpdateById(id: $id) {
      id
    }
  }
`;

export const useGetBlocks = () => {
  return useQuery(GET_BLOCKS);
};

export const useGetBlocksByWorksheetId = options => {
  return useQuery(GET_BLOCKS_BY_WORKSHEET_ID, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};

export const useCreateBlock = () => {
  return useMutation(CREATE_BLOCK);
};

export const useCreateImageBlock = () => {
  return useMutation(CREATE_IMAGE_BLOCK);
};

export const useCreateHtmlBlock = () => {
  return useMutation(CREATE_HTML_BLOCK);
};

export const useUpdateBlockById = () => {
  return useMutation(UPDATE_BLOCK_BY_ID);
};

export const useRemoveBlockById = () => {
  return useMutation(REMOVE_BLOCK_BY_ID);
};

export const useRemoveUpdateBlockById = () => {
  return useMutation(REMOVE_UPDATE_BLOCK_BY_ID);
};
