// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_KINETICA_USERS = gql`
  query KineticaUsers {
    kinetica_users {
      name
      type
      roles
      kinetica_roles {
        name
        type
        permissions
        resource_group {
          name
          alias
          max_data
          max_tier_priority
          max_scheduling_priority
          max_cpu_concurrency
          tier_attributes {
            name
            max_memory
          }
          usage
        }
      }
      resource_group {
        name
        alias
        max_data
        max_tier_priority
        max_scheduling_priority
        max_cpu_concurrency
        tier_attributes {
          name
          max_memory
        }
        usage
      }
      default_schema
      permissions
      system_permissions
      proc_permissions
      sql_proc_permissions
      datasource_permissions
      datasink_permissions
      table_permissions
      folder_permissions
    }
  }
`;

export const useGetKineticaUsers = () => {
  return useQuery(GET_KINETICA_USERS);
};
