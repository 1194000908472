// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_SCHEMAS = gql`
  query Schemas {
    schemas {
      name
      tables
    }
  }
`;

export const GET_SCHEMA_BY_NAME = gql`
  query GetSchemaByName($name: String!) {
    schema(name: $name) {
      name
      tables
    }
  }
`;

export const CREATE_SCHEMA = gql`
  mutation CreateSchema($name: String!) {
    schemaCreate(name: $name) {
      name
    }
  }
`;

export const UPDATE_SCHEMA_BY_NAME = gql`
  mutation UpdateSchemaByName($name: String!, $update: String!) {
    schemaUpdateByName(name: $name, update: $update) {
      name
    }
  }
`;

export const DELETE_SCHEMA_BY_NAME = gql`
  mutation DeleteSchemaByName($name: String!) {
    schemaDeleteByName(name: $name) {
      name
    }
  }
`;

export const useGetSchemas = () => {
  return useQuery(GET_SCHEMAS);
};

export const useGetSchemaByName = options => {
  return useQuery(GET_SCHEMA_BY_NAME, options);
};

export const useCreateSchema = () => {
  return useMutation(CREATE_SCHEMA);
};

export const useUpdateSchemaByName = () => {
  return useMutation(UPDATE_SCHEMA_BY_NAME);
};

export const useDeleteSchemaByName = () => {
  return useMutation(DELETE_SCHEMA_BY_NAME);
};
