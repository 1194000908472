// Imports
import React from 'react';
import { Redirect } from 'react-router-dom';

// App Imports
import { UserContext } from '../../context';
import { getPermissionList } from '../../helper';
import { routes } from '../../setup/routes';

const Permission = props => {
  const { everyKeys, someKeys, elseChildren, children } = props;
  return (
    <UserContext.Consumer>
      {userMe => {
        const isAdmin = userMe?.isGlobalAdmin;
        const userPermissions = getPermissionList(userMe).map(
          perm => perm.name
        );
        const hasPermission = everyKeys
          ? everyKeys.every(key => userPermissions.includes(key))
          : someKeys
          ? someKeys.some(key => userPermissions.includes(key))
          : false;
        if (isAdmin || hasPermission) {
          return children;
        } else if (elseChildren) {
          return elseChildren;
        }
        return <Redirect to={routes.root} />;
      }}
    </UserContext.Consumer>
  );
};

export default Permission;
