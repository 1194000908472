// Imports
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { useApolloClient } from '@apollo/client';
import {
  Space,
  Button,
  Popconfirm,
  Alert,
  Modal,
  Drawer,
  Tabs,
  Tooltip,
} from 'antd';
import {
  SettingOutlined,
  CloseOutlined,
  FileImageOutlined,
  ReloadOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import OlMap from 'ol/Map';
import OlView from 'ol/View';
import OlFeature from 'ol/Feature';
import WKT from 'ol/format/WKT';
import {
  defaults as DefaultControls,
  ZoomSlider,
  ScaleLine,
  MousePosition,
} from 'ol/control';
import {
  defaults as DefaultInteractions,
  Draw,
  MouseWheelZoom,
  Select,
  Translate,
} from 'ol/interaction';
import { createStringXY } from 'ol/coordinate';
import SourceOSM from 'ol/source/OSM';
import LayerImage from 'ol/layer/Image';
import SourceImageWms from 'ol/source/ImageWMS';
import LayerVector from 'ol/layer/Vector';
import LayerTile from 'ol/layer/Tile';
import SourceVector from 'ol/source/Vector';
import { LineString, MultiLineString, Point } from 'ol/geom';
import { Style, Stroke, Fill, Circle, Text } from 'ol/style';
import { click, altKeyOnly } from 'ol/events/condition';
import { fromLonLat, toLonLat, transform } from 'ol/proj';
import Colorize from 'ol-ext/filter/Colorize';
import shortid from 'shortid';
import * as ColorHelper from 'colormap';
import 'ol/ol.css';

// App Imports
import GraphQLServices from '../../graphql/services';
import VizTitleBar from './VizTitleBar';
import MapConfigDrawer from './MapConfigDrawer';
import {
  CB_TYPE_UNIQUE_VALUES,
  GEOMETRY_TYPE_LONLAT,
  GEOMETRY_TYPE_WKT,
  WMS_DEFAULT_PARAMS,
  MAP_RENDER_TYPE_HEATMAP,
  MAP_RENDER_TYPES,
} from '../../constants';
import { GET_WMS } from '../../graphql/schema/wms';
import { ConfigToolbarWrapper } from './ConfigToolbar';
import { EXECUTE_SQL_LIMIT } from '../../setup/config';
import ResultTable from './ResultTable';
import { processExecuteSqlResults } from '../../helper';
import { capitalizeSnakecase } from '../../formatter';

const { TabPane } = Tabs;
const { confirm } = Modal;

const LENGTH_LIMIT = 64;

// The icon size is the final size of the legend icon in pixels.
const ICON_SIZE = 16;

// The viewport size is the size of the viewport which only matters when
// drawing an SVG shape.  The viewport is ultimately scaled by the icon size.
const VIEWPORT_SIZE = 50;
const HOLLOW_WIDTH = 4;

// Min/max of WMS size parameter.
const SIZE_MIN = 1;
const SIZE_MAX = 20;

// Min/max of adjusted stroke width.
// This is used to adjust line width.
const ADJUSTED_MIN_WIDTH = 4;
const ADJUSTED_MAX_WIDTH = 8;

// Min/max of adjusted radius.
// This is used to adjust point size.
const ADJUSTED_MIN_RADIUS = 14;
const ADJUSTED_MAX_RADIUS = 25;

// Defaults for legend icons.
const DEFAULT_SIZE = 1;

function debounce(fn, ms) {
  let timer;
  return _ => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

const buildSTXYDWithinExpr = (lon, lat, wktCol, radius) => {
  return `STXY_DWITHIN(${lon}, ${lat}, ${wktCol}, ${radius}, 1) = 1`;
};
const buildGeoDistExpr = (lon, lat, lonCol, latCol, radius) => {
  return `GEODIST(${lon}, ${lat}, ${lonCol}, ${latCol}) <= ${radius}`;
};
const intersects = (coords1, coords2) => {
  var x1 = coords1[0][0];
  var y1 = coords1[0][1];
  var x2 = coords1[1][0];
  var y2 = coords1[1][1];
  var x3 = coords2[0][0];
  var y3 = coords2[0][1];
  var x4 = coords2[1][0];
  var y4 = coords2[1][1];
  var denom = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);
  var numeA = (x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3);
  var numeB = (x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3);
  if (denom === 0) {
    if (numeA === 0 && numeB === 0) {
      return null;
    }
    return null;
  }
  var uA = numeA / denom;
  var uB = numeB / denom;
  if (uA >= 0 && uA <= 1 && uB >= 0 && uB <= 1) {
    var x = x1 + uA * (x2 - x1);
    var y = y1 + uA * (y2 - y1);
    return [x, y];
  }
  return null;
};
const getSplitPoints = (prev, curr) => {
  var initialDiff = prev[0] > curr[0] ? prev[0] - curr[0] : curr[0] - prev[0];
  var altCurr = curr[0] < 0 ? curr[0] + 360 : curr[0] - 360;
  var altDiff = prev[0] > altCurr ? prev[0] - altCurr : altCurr - prev[0];
  if (initialDiff > altDiff) {
    var normVal = 180 - curr[0];
    var prevNorm = [prev[0] + normVal, prev[1]];
    var currNorm = [-180, curr[1]];
    var intersection = intersects(
      [prevNorm, currNorm],
      [
        [-180 + normVal, 90],
        [-180 + normVal, -90],
      ]
    );
    var yRes = intersection[1];
    return {
      endPoint: prev[0] > curr[0] ? [180, yRes] : [-180, yRes],
      startPoint: prev[0] > curr[0] ? [-180, yRes] : [180, yRes],
    };
  }
  return null;
};
const splitRoute180 = route => {
  var newRoutes = [];
  var index = 0;
  var prevPoint;
  route.forEach(point => {
    if (!newRoutes[index]) {
      newRoutes[index] = [];
    }
    if (!prevPoint) {
      prevPoint = point;
    } else {
      var splitPoints = getSplitPoints(prevPoint, point);
      if (splitPoints) {
        newRoutes[index].push(splitPoints.endPoint);
        index++;
        newRoutes[index] = [];
        newRoutes[index].push(splitPoints.startPoint);
        prevPoint = null;
      } else {
        prevPoint = point;
      }
    }
    newRoutes[index].push(point);
  });
  return newRoutes;
};

const LegendIcon = ({ color, shape, size, linecolor, isWkt = false }) => {
  let svgGroup = null;

  // For WKT rendering we show fill color, line color and line width.
  if (isWkt) {
    const adjustedSize =
      ((ADJUSTED_MAX_WIDTH - ADJUSTED_MIN_WIDTH) *
        ((size || DEFAULT_SIZE) - SIZE_MIN)) /
        (SIZE_MAX - SIZE_MIN) +
      ADJUSTED_MIN_WIDTH;

    svgGroup = (
      <g
        transform={`translate(${VIEWPORT_SIZE / 2}, ${VIEWPORT_SIZE / 2})`}
        stroke={linecolor}
        fill={color}
      >
        <circle
          cx="0"
          cy="0"
          r={(VIEWPORT_SIZE - adjustedSize) / 2.5}
          strokeWidth={adjustedSize}
        ></circle>
      </g>
    );
  } else {
    // For point rendering we show point color, point shape and point size.
    let adjustedSize =
      ((ADJUSTED_MAX_RADIUS - ADJUSTED_MIN_RADIUS) *
        ((size || DEFAULT_SIZE) - SIZE_MIN)) /
        (SIZE_MAX - SIZE_MIN) +
      ADJUSTED_MIN_RADIUS;

    const gParams = {};
    if (shape && shape.startsWith('hollow')) {
      gParams.fill = 'transparent';
      gParams.stroke = color;
      adjustedSize -= HOLLOW_WIDTH / 2;
    } else {
      gParams.fill = color;
    }

    let svgShape = null;
    if (!shape || shape.includes('circle')) {
      svgShape = <circle cx="0" cy="0" r={adjustedSize}></circle>;
    } else if (shape.includes('square')) {
      svgShape = (
        <polygon
          points={`-${adjustedSize},-${adjustedSize}, ${adjustedSize},-${adjustedSize} ${adjustedSize},${adjustedSize} -${adjustedSize},${adjustedSize}`}
        ></polygon>
      );
    } else if (shape.includes('diamond')) {
      svgShape = (
        <polygon
          points={`0,-${adjustedSize}, ${adjustedSize},0 0,${adjustedSize} -${adjustedSize},0`}
        ></polygon>
      );
    }

    svgGroup = (
      <g
        transform={`translate(${VIEWPORT_SIZE / 2}, ${VIEWPORT_SIZE / 2})`}
        strokeWidth={HOLLOW_WIDTH}
        {...gParams}
      >
        {svgShape}
      </g>
    );
  }

  return (
    <svg
      width={ICON_SIZE}
      height={ICON_SIZE}
      viewBox={`0 0 ${VIEWPORT_SIZE} ${VIEWPORT_SIZE}`}
      style={{
        pointerEvents: 'all',
      }}
    >
      {svgGroup}
    </svg>
  );
};

const VizMap = ({
  viz,
  handleUpdate,
  handleRemove,
  handleGeoPicker,
  handleWktPicker,
  minHeight = 180,
  maxWidth = undefined,
  modalWidth = undefined,
  isPreview = false,
  initWithConfig = false,
  disabledFields,
  readOnly = false,
  runId,
  fullscreen = false,
  multiLayer = true,
  reload = () => {},
}) => {
  const [executeSql, { loading: queryLoading }] =
    GraphQLServices.SqlQueries.useExecuteSql();
  const { data: { tables: allTables } = [] } =
    GraphQLServices.Tables.useGetTablesOnly();

  const [unmountDrawer, setUnmountDrawer] = useState(
    true && !fullscreen && !initWithConfig
  );

  const vizConfig = useMemo(
    _ => {
      return viz?.config || [];
    },
    [viz]
  );

  const fields = useMemo(
    _ => {
      return viz?.visualization_type?.params?.fields;
    },
    [viz]
  );
  const [loadingMapLayer, setLoadingMapLayer] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const autoIntervalIDs = useRef([]);

  const [activeLayerKey, setActiveLayerKey] = useState('layer_0');

  const graphqlClient = useApolloClient();

  const ref = useRef(null);

  const mapId = useMemo(_ => {
    return `map_${shortid.generate()}`;
  }, []);

  const [tables, setTables] = useState(null);

  const [wmsParams, setWmsParams] = useState({});

  const refetchTables = useCallback(
    async (variables, idx = -1) => {
      const promises = variables.map(variable => {
        return new Promise((resolve, reject) => {
          if (variable?.schema || variable?.name) {
            graphqlClient
              .query({
                query: GraphQLServices.Tables.GET_TABLE_BY_NAME,
                variables: {
                  schema: variable?.schema,
                  name: variable?.name,
                },
              })
              .then(resp => {
                resolve(resp?.data?.table);
              })
              .catch(err => {
                resolve(null);
              });
          } else {
            resolve(null);
          }
        });
      });
      try {
        const results = await Promise.all(promises);
        if (idx >= 0) {
          const updates = [...tables];
          updates[idx] = results[0];
          setTables(updates);
        } else {
          setTables(results);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [graphqlClient, tables]
  );

  useEffect(
    _ => {
      refetchTables(
        vizConfig.map(config => {
          return {
            schema: config.table_schema,
            name: config.table_name,
          };
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [graphqlClient, vizConfig]
  );

  const [isVizConfigOpen, setIsVizConfigOpen] = useState(initWithConfig);
  const [wmsLayers, setWmsLayers] = useState([]);
  const [center, setCenter] = useState(
    fromLonLat([
      vizConfig[0]?.center_longitude || 0.0,
      vizConfig[0]?.center_latitude || 0.0,
    ])
  );
  const [zoom, setZoom] = useState(vizConfig[0]?.zoom_level || 2);
  const [clickResponse, setClickResponse] = useState(null);
  const [currentRecord, setCurrentRecord] = useState(null);

  const [map, setMap] = useState(null);

  const select = useMemo(_ => {
    return new Select({
      condition: evt => {
        return click(evt) && altKeyOnly(evt);
      },
    });
  }, []);

  const imageStyle = new Circle({
    radius: 6,
    stroke: new Stroke({
      color: '#ffffff',
      width: 2,
    }),
    fill: new Fill({
      color: '#ff0000',
    }),
  });

  const textStyle = new Text({
    text: '',
    offsetY: -20,
    font: '12px Courier New',
    fill: new Fill({
      color: '#666',
    }),
    backgroundFill: new Fill({
      color: 'rgba(255, 255, 255, 1)',
      opacity: 0.8,
    }),
    backgroundStroke: new Stroke({
      color: '#ff0000',
      width: 1,
    }),
    padding: [3, 5, 1, 5],
  });

  const vectorSource = useMemo(_ => {
    return new SourceVector();
  }, []);

  const vectorLayer = useMemo(
    _ => {
      return new LayerVector({
        source: vectorSource,
        style: new Style({
          fill: new Fill({
            color: 'rgba(255, 0, 0, 0.2)',
          }),
          stroke: new Stroke({
            color: '#ff0000',
            width: 2,
          }),
        }),
      });
    },
    [vectorSource]
  );

  const draw = useMemo(
    _ => {
      return new Draw({ source: vectorSource, type: 'Polygon' });
    },
    [vectorSource]
  );
  draw.on('drawend', evt => {
    const format = new WKT();
    let features = vectorLayer.getSource().getFeatures();
    features = features.concat(evt.feature).map(feature =>
      format.writeGeometry(feature.getGeometry(), {
        dataProjection: 'EPSG:4326',
        featureProjection: 'EPSG:3857',
      })
    );
    if (handleWktPicker) {
      handleWktPicker(features);
    }
  });
  draw.on('drawstart', function (e) {
    handleClearAllWktSelected();
  });

  const colorMapLayer = useMemo(
    _ => {
      const layer = new LayerTile({
        name: 'OSM',
        source: new SourceOSM({
          crossOrigin: 'anonymous',
          wrapX: true,
          noWrap: false,
        }),
      });

      if (
        vizConfig[0]?.basemap_style &&
        vizConfig[0]?.basemap_style !== 'color'
      ) {
        const enhance = new Colorize({ operation: 'luminosity', value: 0.75 });
        layer.addFilter(enhance);

        const filter = new Colorize();
        filter.setFilter(vizConfig[0]?.basemap_style || 'color');
        layer.addFilter(filter);
      }

      return layer;
    },
    [vizConfig]
  );

  // Initilize everything on load
  useEffect(() => {
    const interactionExtension = [
      new MouseWheelZoom({
        condition: ({ originalEvent }) => {
          return (
            originalEvent.metaKey ||
            originalEvent.ctrlKey ||
            originalEvent.shiftKey ||
            originalEvent.altKey
          );
        },
      }),
    ];

    const controlExtension = [new ZoomSlider(), new ScaleLine()];

    const mousePositionControl = new MousePosition({
      coordinateFormat: createStringXY(4),
      projection: 'EPSG:4326',
      className: 'custom-mouse-position',
      target: document.getElementById('mouse-position'),
    });
    const translate = new Translate({
      features: select.getFeatures(),
    });
    vectorLayer.setZIndex(1001);

    const newMap = new OlMap({
      target: mapId,
      layers: [colorMapLayer, vectorLayer],
      overlays: [],
      interactions: DefaultInteractions({
        mouseWheelZoom: isPreview,
        doubleClickZoom: false,
      }).extend(
        handleGeoPicker
          ? interactionExtension.concat([select, translate])
          : handleWktPicker
          ? interactionExtension.concat([select, translate, draw])
          : interactionExtension
      ),
      controls: DefaultControls().extend(
        handleGeoPicker || handleWktPicker
          ? controlExtension.concat([mousePositionControl])
          : controlExtension
      ),
      pixelRatio: 1.0,
      view: new OlView({
        center: [0, 0],
        zoom: 1,
      }),
    });

    if (handleGeoPicker) {
      newMap.on('singleclick', evt => {
        const pixel = newMap.getEventPixel(evt.originalEvent);
        if (
          !newMap.hasFeatureAtPixel(pixel) &&
          select.getFeatures().getLength() === 0
        ) {
          select.getFeatures().clear();
          const [lon, lat] = toLonLat(evt.coordinate);
          const feature = new OlFeature({
            geometry: new Point(fromLonLat([lon, lat])),
          });

          let coords = transform(
            feature.getGeometry().getCoordinates(),
            'EPSG:3857',
            'EPSG:4326'
          );
          coords = coords.map(function (coord) {
            return coord.toFixed(6);
          });
          const newTextStyle = textStyle.clone();
          newTextStyle.setText(coords.join(', '));
          const featureStyle = new Style({
            image: imageStyle,
            text: newTextStyle,
          });

          feature.setStyle(featureStyle);
          vectorSource.addFeature(feature);
        } else if (!newMap.hasFeatureAtPixel(pixel)) {
          select.getFeatures().clear();
        } else if (newMap.hasFeatureAtPixel(pixel)) {
          const feature = newMap.getFeaturesAtPixel(pixel);
          select.getFeatures().push(feature[0]);
        }
        handleGeoPicker(
          vectorSource
            .getFeatures()
            .map(feature => toLonLat(feature.getGeometry().getCoordinates()))
        );
      });
    } else {
      // Select nearby records and display onclick
      newMap.on('singleclick', async evt => {
        // Reset existing data
        setClickResponse(null);
        setCurrentRecord(null);

        // Get user config values
        const {
          table_schema: schema,
          table_name: table,
          longitude,
          latitude,
          wkt,
        } = vizConfig[0];

        // Get click event coordinates
        const [lon, lat] = toLonLat(evt.coordinate);

        // Calculate the radius of click to filter by
        const { offsetWidth: mapWidth } = ref.current;
        const extent = newMap.getView().calculateExtent(newMap.getSize());
        const extentWidth = extent[2] - extent[0];
        const radius = extentWidth * (3 / mapWidth);

        // Validate values
        if (lat > 90 || lat < -90 || lon > 180 || lon < -180) {
          console.warn('Invalid lon/lat values for click', lon, lat);
          return;
        }

        let filter = '';

        // Filter either points or geometries
        if (wkt && wkt !== '') {
          filter = buildSTXYDWithinExpr(lon, lat, wkt, radius);
        } else if (
          longitude &&
          longitude !== '' &&
          latitude &&
          latitude !== ''
        ) {
          filter = buildGeoDistExpr(lon, lat, longitude, latitude, radius);
        }

        if (filter === '') {
          console.warn('No valid filter for click');
          return;
        }

        // Center map on click location
        // newMap.getView().setCenter(fromLonLat([lon || 0.0, lat || 0.0]));

        // Run filter query
        const layers = schema && schema !== '' ? `${schema}.${table}` : table;
        const resp = await executeSql({
          variables: {
            statement: `SELECT * FROM ${layers} WHERE ${filter};`,
            limit: EXECUTE_SQL_LIMIT,
          },
        });
        const clickResponse = resp?.data?.executeSql;

        // Set the initial record to highlight if response has data
        if (clickResponse?.response?.data) {
          const records = processExecuteSqlResults(clickResponse.response.data);
          if (records.length > 0) {
            setClickResponse(clickResponse);
            setCurrentRecord(records[0]);
          } else {
            setClickResponse(null);
            setCurrentRecord(null);
          }
        } else {
          // If no data then reset
          setClickResponse(null);
          setCurrentRecord(null);
        }
      });
    }

    setMap(newMap);
    return () => {
      newMap.dispose();
    };
    // eslint-disable-next-line
  }, [mapId, isPreview, vizConfig]);

  useEffect(() => {
    let debouncedHandleResize = null;
    if (map) {
      debouncedHandleResize = debounce(() => {
        map.setTarget(mapId);
        map.updateSize();
      }, 1000);
      window.addEventListener('resize', debouncedHandleResize);
    }
    return _ => {
      if (debouncedHandleResize) {
        window.removeEventListener('resize', debouncedHandleResize);
      }
    };
  }, [map, mapId]);

  const setMapView = useCallback(
    (center, zoom) => {
      if (map) {
        map.getView().setCenter(center);
        map.getView().setZoom(zoom);
      }
    },
    [map]
  );

  useEffect(() => {
    setMapView(center, zoom);
  }, [center, zoom, setMapView]);

  const updateWMS = useCallback(
    _ => {
      console.debug('VizMap Run ID', runId);

      const paramsToObject = entries => {
        const result = {};
        for (const [key, value] of entries) {
          result[key] = value;
        }
        return result;
      };

      setWmsLayers(
        vizConfig.map((config, layerIdx) => {
          const {
            table_schema,
            table_name,
            geometry_type,
            renderType,
            longitude,
            latitude,
            wkt,
            colormap,
            fillColor,
            borderColor,
            blurRadius,
            pointSize,
            lineWidth,
            opacity,
            center_longitude,
            center_latitude,
            zoom_level,
            cbAttr,
            cb_type,
            cb_include_other,
            cbVals,
            cbColors,
            cbShapes,
            cb_colormap,
            trackHeadColor,
            trackHeadShape,
            trackHeadSize,
            trackMarkerColor,
            trackMarkerShape,
            trackMarkerSize,
            trackLineColor,
            trackLineWidth,
            symbolRotation,
            valAttr,
            griddingMethod,
            renderOutputGrid,
            color,
            bgColor,
            minLevel,
            maxLevel,
            numLevels,
            smoothingFactor,
            adjustLevel,
            addLabels,
            labelsFontSize,
            textColor,
            overrides,
            auto_refresh_interval,
          } = config;

          const alphaFillColor = fillColor
            ? fillColor.length === 8
              ? `${fillColor.substring(6, 8)}${fillColor.substring(0, 6)}`
              : fillColor.substring(0, 6)
            : fillColor;

          const alphaBorderColor = borderColor
            ? borderColor.length === 8
              ? `${borderColor.substring(6, 8)}${borderColor.substring(0, 6)}`
              : borderColor.substring(0, 6)
            : borderColor;

          const alphaTrackHeadColor = trackHeadColor
            ? trackHeadColor.length === 8
              ? `${trackHeadColor.substring(6, 8)}${trackHeadColor.substring(
                  0,
                  6
                )}`
              : trackHeadColor.substring(0, 6)
            : trackHeadColor;

          const alphaTrackMarkerColor = trackMarkerColor
            ? trackMarkerColor.length === 8
              ? `${trackMarkerColor.substring(
                  6,
                  8
                )}${trackMarkerColor.substring(0, 6)}`
              : trackMarkerColor.substring(0, 6)
            : trackMarkerColor;

          const alphaTrackLineColor = trackLineColor
            ? trackLineColor.length === 8
              ? `${trackLineColor.substring(6, 8)}${trackLineColor.substring(
                  0,
                  6
                )}`
              : trackLineColor.substring(0, 6)
            : trackLineColor;

          const geoParams = {};
          if (wkt && wkt !== '') {
            geoParams.GEO_ATTR = wkt;
          } else if (
            longitude &&
            longitude !== '' &&
            latitude &&
            latitude !== ''
          ) {
            geoParams.X_ATTR = longitude;
            geoParams.Y_ATTR = latitude;
          }

          const layers =
            table_schema && table_schema !== ''
              ? `${table_schema}.${table_name}`
              : table_name;

          const styles =
            MAP_RENDER_TYPES.find(type => type.value === renderType)?.render ??
            MAP_RENDER_TYPE_HEATMAP;

          const styleParams = {
            STYLES: styles,
            LAYERS: layers,
            COLORMAP: colormap,
            POINTCOLORS: alphaFillColor,
            BLUR_RADIUS: blurRadius,
            POINTSIZES: pointSize,
            SHAPELINEWIDTHS: lineWidth,
            SHAPEFILLCOLORS: alphaFillColor,
            SHAPELINECOLORS: alphaBorderColor,
            TRACKHEADCOLORS: alphaTrackHeadColor,
            TRACKHEADSHAPES: trackHeadShape,
            TRACKHEADSIZES: trackHeadSize,
            TRACKMARKERCOLORS: alphaTrackMarkerColor,
            TRACKMARKERSHAPES: trackMarkerShape,
            TRACKMARKERSIZES: trackMarkerSize,
            TRACKLINECOLORS: alphaTrackLineColor,
            TRACKLINEWIDTHS: trackLineWidth,
            SYMBOLROTATIONS: symbolRotation,
            DOTRACKS: renderType ? renderType.includes('track') : 'false',
            VAL_ATTR: valAttr,
            GRIDDING_METHOD: griddingMethod,
            RENDER_OUTPUT_GRID: renderOutputGrid,
            COLOR: color,
            BG_COLOR: bgColor,
            MIN_LEVEL: minLevel,
            MAX_LEVEL: maxLevel,
            NUM_LEVELS: numLevels,
            SMOOTHING_FACTOR: smoothingFactor,
            ADJUST_LEVEL:
              adjustLevel !== undefined ? (adjustLevel ? 1 : 0) : adjustLevel,
            ADD_LABELS: addLabels,
            LABELS_INTRALEVEL_SEPARATION: 4,
            LABELS_INTERLEVEL_SEPARATION: 25,
            LABELS_FONT_SIZE: labelsFontSize,
            TEXT_COLOR: textColor,
          };

          let cbParams = {};
          if (cbAttr && cbVals && cbVals.length > 0) {
            if (cb_type === CB_TYPE_UNIQUE_VALUES) {
              let geoParams = {};
              if (geometry_type === GEOMETRY_TYPE_LONLAT) {
                geoParams = {
                  POINTSIZES: pointSize
                    ? cbVals
                        .map(_ => pointSize)
                        .concat(cb_include_other ? [pointSize] : [])
                        .join(',')
                    : undefined,
                  POINTCOLORS: cbColors
                    .map(color => {
                      const base = color.substring(0, 6);
                      const alpha = color.substring(6, 8) || 'ff';
                      return `${alpha}${base}`;
                    })
                    .concat(cb_include_other ? ['ff666666'] : [])
                    .join(','),
                  POINTSHAPES: cbShapes
                    ? cbShapes
                        .concat(cb_include_other ? ['circle'] : [])
                        .join(',')
                    : undefined,
                };
              } else if (geometry_type === GEOMETRY_TYPE_WKT) {
                geoParams = {
                  POINTSIZES: pointSize
                    ? cbVals
                        .map(_ => pointSize)
                        .concat(cb_include_other ? [pointSize] : [])
                        .join(',')
                    : undefined,
                  POINTCOLORS: cbColors
                    .map(color => {
                      const base = color.substring(0, 6);
                      const alpha = color.substring(6, 8) || 'ff';
                      return `${alpha}${base}`;
                    })
                    .concat(cb_include_other ? ['ff666666'] : [])
                    .join(','),
                  POINTSHAPES: cbShapes
                    ? cbShapes
                        .concat(cb_include_other ? ['circle'] : [])
                        .join(',')
                    : undefined,
                  SHAPELINEWIDTHS: lineWidth
                    ? cbVals
                        .map(_ => lineWidth)
                        .concat(cb_include_other ? [lineWidth] : [])
                        .join(',')
                    : undefined,
                  SHAPELINECOLORS: cbColors
                    .map(color => {
                      const base = color.substring(0, 6);
                      const alpha = color.substring(6, 8) || 'ff';
                      return `${alpha}${base}`;
                    })
                    .concat(cb_include_other ? ['ff666666'] : [])
                    .join(','),
                  SHAPEFILLCOLORS: cbColors
                    .map(color => {
                      const base = color.substring(0, 6);
                      const alpha = '66';
                      return `${alpha}${base}`;
                    })
                    .concat(cb_include_other ? ['66666666'] : [])
                    .join(','),
                };
              }

              cbParams = {
                USE_POINT_RENDERER: true,
                ANTIALIASING: true,
                CB_ATTR: cbAttr,
                CB_VALS: cbVals
                  .concat(cb_include_other ? ['<other>'] : [])
                  .join(','),
                ...geoParams,
              };
            } else {
              const ranges = cbVals.reduce((acc, cur, idx, src) => {
                if (idx < src.length - 1) {
                  acc.push(`${cur}:${src[idx + 1]}`);
                }
                return acc;
              }, []);

              const colors = ColorHelper({
                colormap: cb_colormap,
                format: 'hex',
                alpha: 1,
              })
                .reduce((acc, cur, idx, arr) => {
                  if (arr.length / (cbVals.length - 1) >= 2) {
                    const mod = Math.floor(arr.length / (cbVals.length - 1));
                    if ((idx + Math.floor(mod / 2)) % mod === 0) {
                      acc.push(cur);
                    }
                  } else {
                    acc.push(cur);
                  }
                  return acc;
                }, [])
                .map(color => {
                  return cbAttr ? color : '#f9f9f9';
                });

              let geoParams = {};
              if (geometry_type === GEOMETRY_TYPE_LONLAT) {
                geoParams = {
                  POINTSIZES: pointSize
                    ? ranges.map(_ => pointSize).join(',')
                    : undefined,
                  POINTCOLORS: ranges
                    .map((_, idx) => `ff${colors[idx].replace('#', '')}`)
                    .join(','),
                  POINTSHAPES: ranges.map(_ => 'circle').join(','),
                };
              } else if (geometry_type === GEOMETRY_TYPE_WKT) {
                geoParams = {
                  POINTSIZES: pointSize
                    ? ranges.map(_ => pointSize).join(',')
                    : undefined,
                  POINTCOLORS: ranges
                    .map((_, idx) => `ff${colors[idx].replace('#', '')}`)
                    .join(','),
                  POINTSHAPES: ranges.map(_ => 'circle').join(','),
                  SHAPELINEWIDTHS: lineWidth
                    ? ranges.map(_ => lineWidth).join(',')
                    : undefined,
                  SHAPELINECOLORS: ranges
                    .map((_, idx) => `ff${colors[idx].replace('#', '')}`)
                    .join(','),
                  SHAPEFILLCOLORS: ranges
                    .map((_, idx) => `66${colors[idx].replace('#', '')}`)
                    .join(','),
                };
              }

              cbParams = {
                USE_POINT_RENDERER: true,
                ANTIALIASING: true,
                CB_ATTR: cbAttr,
                CB_VALS: ranges.join(','),
                ...geoParams,
              };
            }
          }

          const overrideParams =
            overrides && Array.isArray(overrides)
              ? overrides.reduce((acc, cur) => {
                  acc[cur.name.split('|')[1]] = cur.value;
                  return acc;
                }, {})
              : {};

          const wmsSource = new SourceImageWms({
            url: '/',
            serverType: 'geoserver',
            crossOrigin: 'anonymous',
            imageLoadFunction: async (image, src) => {
              const search = src.split('?')[1];
              const searchParams = new URLSearchParams(search);
              const entries = searchParams.entries();
              const urlParams = paramsToObject(entries);

              const { X_ATTR, Y_ATTR, GEO_ATTR } = geoParams;
              if ((X_ATTR && Y_ATTR) || GEO_ATTR) {
                const { LAYERS = '' } = styleParams;
                if (LAYERS !== '') {
                  setLoadingMapLayer(true);
                  const params = {
                    ...WMS_DEFAULT_PARAMS,
                    ...urlParams,
                    ...geoParams,
                    ...styleParams,
                    ...cbParams,
                    ...overrideParams,
                  };
                  setWmsParams(params);
                  const resp = await graphqlClient.query({
                    query: GET_WMS,
                    variables: {
                      params,
                    },
                  });
                  if (resp.errors && resp.errors[0]?.message) {
                    setErrorMessage(resp.errors[0]?.message);
                  } else {
                    setErrorMessage(null);
                    image.getImage().src = resp?.data?.wms?.image_data;
                  }
                  setLoadingMapLayer(false);
                }
              }
            },
          });

          if (layerIdx === 0) {
            setCenter(
              fromLonLat([center_longitude || 0.0, center_latitude || 0.0])
            );
            setZoom(zoom_level || 2);
          }

          const oldID = autoIntervalIDs.current[layerIdx];
          if (oldID && oldID > 0) {
            clearInterval(oldID);
            autoIntervalIDs.current[layerIdx] = 0;
            console.debug(
              'LAYER [',
              layerIdx,
              '] REFRESH [',
              oldID,
              '] CLEARED ',
              autoIntervalIDs.current
            );
          }

          if (auto_refresh_interval > 0) {
            const intervalId = setInterval(_ => {
              const REFRESH_ID =
                11111111 + Math.round(Math.random() * 88888888);
              wmsSource.updateParams({
                REFRESH_ID,
              });
            }, auto_refresh_interval * 1000);
            autoIntervalIDs.current[layerIdx] = intervalId;
            autoIntervalIDs.current = [...autoIntervalIDs.current];

            console.debug(
              'LAYER [',
              layerIdx,
              '] REFRESH [',
              intervalId,
              '] ADDED   ',
              autoIntervalIDs.current,
              auto_refresh_interval * 1000,
              layers
            );
          } else {
            autoIntervalIDs.current[layerIdx] = 0;
            autoIntervalIDs.current = [...autoIntervalIDs.current];
          }
          return new LayerImage({
            source: wmsSource,
            opacity: opacity / 100,
          });
        })
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [vizConfig, graphqlClient, runId]
  );

  useEffect(() => {
    return _ => {
      autoIntervalIDs.current.forEach((id, layerIdx) => {
        if (id > 0) {
          clearInterval(id);
          autoIntervalIDs.current[layerIdx] = 0;
          console.debug(
            'LAYER [',
            layerIdx,
            '] REFRESH [',
            id,
            '] CLEARED ',
            autoIntervalIDs.current
          );
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    _ => {
      updateWMS();
    },
    [updateWMS]
  );

  useEffect(
    _ => {
      if (map) {
        if (wmsLayers.length > 0) {
          const length = map.getLayers().getLength();
          if (map.getLayers().getLength() >= 3) {
            wmsLayers.forEach((_, idx) => {
              map.getLayers().removeAt(length - idx - 1);
            });
          }
          wmsLayers.forEach((layer, idx) => {
            map.getLayers().insertAt(2 + idx, layer);
          });
        } else {
          const length = map.getLayers().getLength();
          if (map.getLayers().getLength() >= 3) {
            wmsLayers.forEach((_, idx) => {
              map.getLayers().removeAt(length - idx - 1);
            });
          }
        }
      }
    },
    [map, wmsLayers]
  );

  const handleOpenVizConfig = useCallback(_ => {
    setUnmountDrawer(false);
    setIsVizConfigOpen(true);
  }, []);

  const handleCloseVizConfig = useCallback(
    _ => {
      setIsVizConfigOpen(false);
      if (!fullscreen) {
        setTimeout(_ => {
          setUnmountDrawer(true);
        }, 2000);
      }
    },
    [fullscreen]
  );

  const [commitUpdate, setCommitUpdate] = useState(false);
  const handleCommitUpdate = useCallback(
    _ => {
      setCommitUpdate(true);
      setTimeout(_ => {
        setIsVizConfigOpen(false);
        setCommitUpdate(false);
        if (!fullscreen) {
          setTimeout(_ => {
            setUnmountDrawer(true);
          }, 2000);
        }
      }, vizConfig.length * 200 + 100);
    },
    [fullscreen, vizConfig.length]
  );

  const handleUpdateVizConfig = useCallback(
    idx => values => {
      const update = [...vizConfig];
      update[idx] = values;
      handleUpdate(update);
    },
    [vizConfig, handleUpdate]
  );

  const handleOnChangeVizConfig = useCallback(
    idx => (changedValues, allValues) => {
      if (allValues?.table_name) {
        refetchTables(
          [
            {
              schema: allValues?.table_schema,
              name: allValues?.table_name,
            },
          ],
          idx
        );
      }
    },
    [refetchTables]
  );

  const handleDownloadPng = _ => {
    if (map) {
      map.once('rendercomplete', _ => {
        const mapCanvas = document.createElement('canvas');
        const size = map.getSize();
        mapCanvas.width = size[0];
        mapCanvas.height = size[1];
        const mapContext = mapCanvas.getContext('2d');
        Array.prototype.forEach.call(
          document.querySelectorAll(`#${mapId} .ol-layer canvas`),
          canvas => {
            if (canvas.width > 0) {
              const opacity = canvas.parentNode.style.opacity;
              mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
              const transform = canvas.style.transform;
              const matrix = transform
                // eslint-disable-next-line no-useless-escape
                .match(/^matrix\(([^\(]*)\)$/)[1]
                .split(',')
                .map(Number);
              CanvasRenderingContext2D.prototype.setTransform.apply(
                mapContext,
                matrix
              );
              mapContext.drawImage(canvas, 0, 0);
            }
          }
        );
        if (navigator.msSaveBlob) {
          navigator.msSaveBlob(mapCanvas.msToBlob(), 'map.png');
        } else {
          const link = document.getElementById(`download_${mapId}`);
          link.href = mapCanvas.toDataURL();
          link.click();
        }
      });
      map.renderSync();
    }
  };

  const handleClearAllWktSelected = _ => {
    handleWktPicker([]);
    const features = vectorSource.getFeatures();
    features.forEach(feature => {
      vectorSource.removeFeature(feature);
    });
  };

  const handleClearAllGeoSelected = _ => {
    const features = vectorSource.getFeatures();
    features.forEach(feature => {
      vectorSource.removeFeature(feature);
    });
    select.getFeatures().clear();
    handleGeoPicker(
      vectorSource
        .getFeatures()
        .map(feature => toLonLat(feature.getGeometry().getCoordinates()))
    );
  };

  const handleClearGeoSelected = _ => {
    const features = select.getFeatures();
    features.forEach(feature => {
      vectorSource.removeFeature(feature);
    });
    select.getFeatures().clear();
    handleGeoPicker(
      vectorSource
        .getFeatures()
        .map(feature => toLonLat(feature.getGeometry().getCoordinates()))
    );
  };

  const handleHelpGeo = _ => {
    Modal.info({
      title: 'Selection Help',
      content: (
        <ul style={{ marginTop: '20px' }}>
          <li>Click an empty area on the map to select a point.</li>
          <li>Click an existing point to highlight and move or clear.</li>
          <li>
            When points are highlighted, click an empty area to unhighlight.
          </li>
          <li>
            When points are highlighted, click Clear Highlighted to remove.
          </li>
          <li>Click Clear All to remove all points.</li>
        </ul>
      ),
      onOk() {},
      width: 600,
      centered: true,
    });
  };

  const width = useMemo(() => {
    const calculated = fullscreen
      ? 325
      : window.innerWidth > 1280
      ? window.innerWidth - 560
      : 720;
    return modalWidth
      ? Math.min(modalWidth, Math.min(calculated, 900))
      : Math.min(calculated, 1000);
  }, [fullscreen, modalWidth]);

  const handleLayerChange = useCallback(
    activeKey => {
      setActiveLayerKey(activeKey);
    },
    [setActiveLayerKey]
  );

  const getLayerLabel = value => {
    if (value === 'layer_0') {
      return 'Base Layer';
    }
    return capitalizeSnakecase(value);
  };

  const handleLayerEdit = useCallback(
    (targetKey, action) => {
      if (action === 'add') {
        const update = [
          ...vizConfig,
          {
            ...vizConfig[vizConfig.length - 1],
          },
        ];
        handleUpdate(update, _ => {
          setActiveLayerKey(`layer_${update.length - 1}`);
        });
      } else if (action === 'remove') {
        confirm({
          title: `Do you want to delete ${getLayerLabel(targetKey)}?`,
          icon: <ExclamationCircleOutlined />,
          onOk() {
            const update = [...vizConfig];
            const deleteIdx = update.findIndex(
              (_, idx) => `layer_${idx}` === targetKey
            );
            update.splice(deleteIdx, 1);
            handleUpdate(update, _ => {
              setUnmountDrawer(true);
              setTimeout(() => {
                setUnmountDrawer(false);
                setActiveLayerKey('layer_0');
              }, 100);
            });
          },
          onCancel() {
            // Do nothing
          },
          width: 600,
        });
      }
    },
    [vizConfig, handleUpdate]
  );

  const arrayMove = (arr, old_index, new_index) => {
    while (old_index < 0) {
      old_index += arr.length;
    }
    while (new_index < 0) {
      new_index += arr.length;
    }
    if (new_index >= arr.length) {
      var k = new_index - arr.length;
      while (k-- + 1) {
        arr.push(undefined);
      }
    }
    arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
    return arr;
  };

  const handleLayerDown = useCallback(
    currentIdx => {
      const update = arrayMove([...vizConfig], currentIdx, currentIdx - 1);
      handleUpdate(update, _ => {
        setUnmountDrawer(true);
        setTimeout(() => {
          setUnmountDrawer(false);
          setActiveLayerKey(`layer_${currentIdx - 1}`);
        }, 100);
      });
    },
    [vizConfig, handleUpdate]
  );

  const handleLayerUp = useCallback(
    currentIdx => {
      const update = arrayMove([...vizConfig], currentIdx, currentIdx + 1);
      handleUpdate(update, _ => {
        setUnmountDrawer(true);
        setTimeout(() => {
          setUnmountDrawer(false);
          setActiveLayerKey(`layer_${currentIdx + 1}`);
        }, 100);
      });
    },
    [vizConfig, handleUpdate]
  );

  const configDrawers = useMemo(
    _ => {
      const drawers = vizConfig.map((config, idx) => {
        return {
          label:
            idx === 0 ? (
              <span style={{ lineHeight: '10px' }}>
                Base Layer{' '}
                <i style={{ display: 'block', fontSize: 10 }}>
                  {config.table_name}
                </i>
              </span>
            ) : (
              <span style={{ lineHeight: '10px' }}>
                Layer {idx}{' '}
                <i style={{ display: 'block', fontSize: 10 }}>
                  {config.table_name}
                </i>
              </span>
            ),
          key: `layer_${idx}`,
          closable: idx > 0,
          content: (
            <MapConfigDrawer
              layerIdx={idx}
              vizConfig={vizConfig}
              fields={fields}
              config={config}
              tables={allTables}
              options={{
                columns: tables && tables[idx] ? tables[idx].columns : [],
              }}
              handleUpdate={handleUpdateVizConfig(idx)}
              handleOnChange={handleOnChangeVizConfig(idx)}
              map={map}
              width={width}
              disabledFields={disabledFields || []}
              columns={fullscreen ? 1 : 3}
              mask={!fullscreen}
              commitUpdate={commitUpdate}
              setCommitUpdate={setCommitUpdate}
              moveLayerDown={handleLayerDown}
              moveLayerUp={handleLayerUp}
              multiLayer={multiLayer}
            />
          ),
        };
      });

      return (
        !unmountDrawer && (
          <Drawer
            title="Map Configuration"
            placement="right"
            onClose={handleCloseVizConfig}
            open={fullscreen || isVizConfigOpen}
            getContainer={false}
            closable={!fullscreen}
            mask={!fullscreen}
            width={width}
            style={{ position: 'absolute' }}
            headerStyle={{ height: '43px' }}
            bodyStyle={{ padding: '5px 10px' }}
            maskStyle={{ backgroundColor: '#f1edf8cc' }}
            extra={
              <Button
                key="update"
                onClick={handleCommitUpdate}
                type="primary"
                size={fullscreen ? 'small' : 'small'}
                style={{
                  float: 'right',
                }}
              >
                Update
              </Button>
            }
          >
            <div style={{ padding: 10 }}>
              {multiLayer ? (
                <Tabs
                  type="editable-card"
                  onChange={handleLayerChange}
                  activeKey={activeLayerKey}
                  onEdit={handleLayerEdit}
                  size="small"
                >
                  {drawers.map(drawer => {
                    const { label, key, closable, content } = drawer;
                    return (
                      <TabPane tab={label} key={key} closable={closable}>
                        {content}
                      </TabPane>
                    );
                  })}
                </Tabs>
              ) : (
                drawers[0].content
              )}
            </div>
          </Drawer>
        )
      );
    },
    [
      vizConfig,
      disabledFields,
      tables,
      allTables,
      fields,
      isVizConfigOpen,
      handleCloseVizConfig,
      handleUpdateVizConfig,
      handleOnChangeVizConfig,
      handleCommitUpdate,
      handleLayerChange,
      map,
      fullscreen,
      unmountDrawer,
      width,
      handleLayerEdit,
      activeLayerKey,
      multiLayer,
      commitUpdate,
      handleLayerDown,
      handleLayerUp,
    ]
  );

  const records = useMemo(
    _ => {
      if (clickResponse?.response?.data) {
        return processExecuteSqlResults(clickResponse.response.data);
      }
      return [];
    },
    [clickResponse]
  );

  const handlePaginationChange = useCallback(
    pagination => {
      const { current = 0 } = pagination;
      if (current > 0 && records.length >= current) {
        setCurrentRecord(records[current - 1]);
      } else {
        setCurrentRecord(null);
      }
    },
    [records]
  );

  const handleClearResult = _ => {
    setClickResponse(null);
    setCurrentRecord(null);
  };

  const clearFeatureLayer = useCallback(
    _ => {
      if (map && map.getLayers()) {
        map.getLayers().forEach(layer => {
          if (layer.get('name') === 'features') {
            map.removeLayer(layer);
          }
        });
      }
    },
    [map]
  );

  useEffect(
    _ => {
      clearFeatureLayer();

      if (currentRecord) {
        const { longitude, latitude, wkt } = vizConfig[0];
        const contents = [];

        if (wkt && wkt !== '') {
          contents.push(currentRecord[wkt]);
        } else if (
          longitude &&
          longitude !== '' &&
          latitude &&
          latitude !== ''
        ) {
          contents.push(
            'POINT(' +
              currentRecord[longitude] +
              ' ' +
              currentRecord[latitude] +
              ')'
          );
        }

        if (contents.length > 0) {
          const format = new WKT();
          const features = contents.map(content => {
            var feature = format.readFeature(content, {
              dataProjection: 'EPSG:4326',
              featureProjection: 'EPSG:3857',
            });
            const featureStyle = new Style({
              fill: new Fill({
                color: 'rgba(255, 0, 0, 0.2)',
              }),
              stroke: new Stroke({
                color: '#ff0000',
                width: 2,
              }),
              image: new Circle({
                radius: 5,
                fill: new Fill({
                  color: '#ff0000',
                }),
              }),
            });
            feature.setStyle(featureStyle);

            // Check if we need to split LineString
            // over anti-meridian and adjust feature
            if (feature.getGeometry() instanceof LineString) {
              const feat = format.readFeature(content);
              const coords = feat.getGeometry().getCoordinates();
              const splitCoords = splitRoute180(coords);
              if (JSON.stringify(coords) !== JSON.stringify(splitCoords[0])) {
                const multi = new MultiLineString(splitCoords);
                const multiWktStr = format.writeGeometry(multi);
                const style = feature.getStyle();
                feature = format.readFeature(multiWktStr, {
                  dataProjection: 'EPSG:4326',
                  featureProjection: 'EPSG:3857',
                });
                feature.setStyle(style);
              }
            }

            return feature;
          });
          const featuresLayer = new LayerVector({
            source: new SourceVector({
              features,
            }),
          });
          featuresLayer.set('name', 'features');
          map.addLayer(featuresLayer);
        }
      }
    },
    [map, vizConfig, currentRecord, clearFeatureLayer]
  );

  const getTableNotFoundMessage = message => {
    const match = /could not find the table: '(.*)'/.exec(message);
    if (match.length === 2) {
      return `Layer cannot be displayed: Table '${match[1]}' unavailable`;
    }
    return message;
  };

  const convertColor = (input, override) => {
    if (input.length === 8) {
      if (override) {
        return input.substring(2, 8) + override;
      }
      return input.substring(2, 8) + input.substring(0, 2);
    }
    return input;
  };

  const hasTitle = useMemo(
    _ => {
      return (
        vizConfig &&
        ((vizConfig.title && vizConfig.title !== '') ||
          (Array.isArray(vizConfig) &&
            vizConfig.length > 0 &&
            vizConfig[0].title &&
            vizConfig[0].title !== ''))
      );
    },
    [vizConfig]
  );

  return (
    <div style={{ minHeight }}>
      {!handleWktPicker && (
        <div style={{ height: 45 }}>
          {!readOnly ? (
            <ConfigToolbarWrapper>
              <Space style={{ float: 'right', marginTop: '15px' }}>
                <Button
                  icon={<FileImageOutlined />}
                  onClick={handleDownloadPng}
                  size="small"
                >
                  Save PNG
                </Button>
                <Button
                  icon={<SettingOutlined />}
                  onClick={handleOpenVizConfig}
                  size="small"
                >
                  Config
                </Button>
                {!isPreview && handleRemove && (
                  <Popconfirm
                    title="Are you sure you want to delete this visualization?"
                    onConfirm={handleRemove}
                  >
                    <Button icon={<CloseOutlined />} size="small"></Button>
                  </Popconfirm>
                )}
              </Space>
              <Space style={{ float: 'left', marginTop: '15px' }}>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={updateWMS}
                  size="small"
                  loading={loadingMapLayer || queryLoading}
                ></Button>
                {handleGeoPicker && (
                  <>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={handleClearAllGeoSelected}
                      size="small"
                    >
                      Clear All
                    </Button>
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={handleClearGeoSelected}
                      size="small"
                    >
                      Clear Highlighted
                    </Button>
                    <Button
                      icon={<QuestionCircleOutlined />}
                      onClick={handleHelpGeo}
                      size="small"
                    >
                      Help
                    </Button>
                  </>
                )}
              </Space>
            </ConfigToolbarWrapper>
          ) : (
            <ConfigToolbarWrapper>
              <Space style={{ float: 'left', marginTop: '15px' }}>
                <Button
                  icon={<ReloadOutlined />}
                  onClick={updateWMS}
                  size="small"
                  loading={loadingMapLayer || queryLoading}
                ></Button>
              </Space>
            </ConfigToolbarWrapper>
          )}
        </div>
      )}
      <div
        style={{
          position: 'relative',
          height: minHeight - 60,
          maxWidth,
          overflow: !handleWktPicker && 'hidden',
        }}
      >
        {wmsParams.STYLES &&
          wmsParams.STYLES === 'cb_raster' &&
          wmsParams.CB_ATTR &&
          wmsParams.CB_VALS &&
          wmsParams.CB_VALS.split(',').length > 0 && (
            <Tooltip title={wmsParams.CB_ATTR} placement="leftTop">
              <div
                style={{
                  padding: '5px',
                  margin: '5px',
                  borderRadius: '5px',
                  position: 'absolute',
                  backgroundColor: '#ffffffcc',
                  border: `1px solid #${convertColor(
                    wmsParams.SHAPELINECOLORS
                      ? wmsParams.SHAPELINECOLORS.split(',')[0]
                      : wmsParams.POINTCOLORS.split(',')[0],
                    '33'
                  )}`,
                  zIndex: 1,
                  right: '0px',
                  top: '0px',
                  overflowY: 'auto',
                  maxHeight: minHeight - 95,
                }}
              >
                <ul
                  style={{ listStyle: 'none', margin: 0, padding: '0 5px 0 0' }}
                >
                  {wmsParams.CB_VALS.split(',').map((value, idx) => {
                    const color = wmsParams.SHAPEFILLCOLORS
                      ? wmsParams.SHAPEFILLCOLORS.split(',')[idx]
                      : wmsParams.POINTCOLORS
                      ? wmsParams.POINTCOLORS.split(',')[idx]
                      : 'cccccc';
                    const linecolor = wmsParams.SHAPELINECOLORS
                      ? wmsParams.SHAPELINECOLORS.split(',')[idx]
                      : wmsParams.POINTCOLORS
                      ? wmsParams.POINTCOLORS.split(',')[idx]
                      : 'cccccc';
                    const size = wmsParams.SHAPELINEWIDTHS
                      ? wmsParams.SHAPELINEWIDTHS.split(',')[idx]
                      : wmsParams.POINTSIZES
                      ? wmsParams.POINTSIZES.split(',')[idx]
                      : 0;
                    return (
                      <li
                        key={value}
                        style={{
                          fontSize: '10px',
                          lineHeight: '10px',
                          whiteSpace: 'nowrap',
                        }}
                      >
                        <span style={{ verticalAlign: 'top' }}>
                          <LegendIcon
                            value={value}
                            color={`#${convertColor(color)}`}
                            size={size}
                            linecolor={`#${convertColor(linecolor)}`}
                            isWkt={wmsParams.GEO_ATTR !== undefined}
                          />
                        </span>
                        <span
                          style={{ display: 'inline-block', padding: '3px' }}
                        >
                          {value}
                        </span>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Tooltip>
          )}
        {errorMessage && (
          <div
            style={{
              padding: '5px',
              position: 'absolute',
              zIndex: 1,
              bottom: '0px',
            }}
          >
            {errorMessage.toLowerCase().includes('could not find the table') ? (
              <Alert
                type="info"
                message={getTableNotFoundMessage(errorMessage)}
                bodyStyle={{ color: '#999999' }}
                style={{
                  fontSize: '13px',
                  color: '#cccccc',
                  backgroundColor: '#f3eeff',
                  border: '0px',
                  borderRadius: 5,
                  padding: '3px 8px',
                }}
              />
            ) : (
              <Alert
                type="error"
                message={errorMessage}
                style={{ fontSize: '13px' }}
                showIcon
              />
            )}
          </div>
        )}
        <VizTitleBar config={vizConfig} />
        <div
          id={mapId}
          ref={ref}
          className="map"
          style={{
            height: hasTitle ? minHeight - 90 : minHeight - 60,
            width: fullscreen ? 'calc(100% - 300px)' : '100%',
            border: handleWktPicker && '1px solid #dddddd',
          }}
        ></div>
        {handleWktPicker && (
          <div style={{ float: 'right', height: 45 }}>
            <ConfigToolbarWrapper>
              <Space style={{ marginTop: '15px' }}>
                <Button
                  icon={<DeleteOutlined />}
                  onClick={handleClearAllWktSelected}
                  size="small"
                >
                  Clear Selection
                </Button>
              </Space>
            </ConfigToolbarWrapper>
          </div>
        )}
        {clickResponse &&
          clickResponse?.response?.total_number_of_records > 0 && (
            <div
              style={{
                position: 'absolute',
                bottom: '0px',
                width: 'calc(100% - 10px)',
                backgroundColor: '#ffffff',
                margin: '5px',
                padding: '10px',
                border: '1px solid #cccccc',
              }}
            >
              <ResultTable
                queryResponse={clickResponse}
                onChange={handlePaginationChange}
                clearResult={handleClearResult}
                defaultPageSize={1}
                pageSizeOptions={[
                  '1',
                  '2',
                  '3',
                  '4',
                  '5',
                  '6',
                  '7',
                  '8',
                  '9',
                  '10',
                ]}
                clearLabel={'Close'}
                clearIcon={<CloseOutlined />}
                truncateLength={LENGTH_LIMIT}
              />
            </div>
          )}
        <a id={`download_${mapId}`} download="map.png" href="/">
          &nbsp;
        </a>
        {!readOnly && configDrawers}
      </div>
    </div>
  );
};

export default VizMap;
