// Imports

// App Imports
import { WORKBOOK_SET_CURRENT_WORKSHEET_ID } from './actions';

// Initial State
export const initialState = {
  currentWorksheetId: undefined,
};

// State
export const workbook = (state = initialState, action) => {
  switch (action.type) {
    case WORKBOOK_SET_CURRENT_WORKSHEET_ID:
      return {
        ...state,
        currentWorksheetId: action.id,
      };

    default:
      return state;
  }
};
