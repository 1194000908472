// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_K8S_KINETICAWORKBENCHOPERATORUPGRADES = gql`
  query K8sKineticaWorkbenchOperatorUpgrades {
    k8s_kineticaworkbenchoperatorupgrades {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICAWORKBENCHOPERATORUPGRADE_BY_NAME = gql`
  query GetK8sKineticaWorkbenchOperatorUpgradeByName($name: String!) {
    k8s_kineticaworkbenchoperatorupgrade(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAWORKBENCHOPERATORUPGRADE = gql`
  mutation CreateK8sKineticaWorkbenchOperatorUpgrade(
    $name: String!
    $body: JSON!
  ) {
    kineticaWorkbenchOperatorUpgradeCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaWorkbenchOperatorUpgrades = () => {
  return useQuery(GET_K8S_KINETICAWORKBENCHOPERATORUPGRADES, {
    fetchPolicy: 'no-cache',
  });
};

export const useGetK8sKineticaWorkbenchOperatorUpgradeByName = options => {
  return useQuery(GET_K8S_KINETICAWORKBENCHOPERATORUPGRADE_BY_NAME, options);
};

export const useCreateK8SKineticaWorkbenchOperatorUpgrade = () => {
  return useMutation(CREATE_K8S_KINETICAWORKBENCHOPERATORUPGRADE);
};
