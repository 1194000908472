// Imports
import { useQuery, gql } from '@apollo/client';

export const GET_K8S_KINETICAWORKBENCHES = gql`
  query K8sKineticaWorkbenches($deployment_type: String) {
    k8s_kineticaworkbenches(deployment_type: $deployment_type) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaWorkbenches = options => {
  return useQuery(GET_K8S_KINETICAWORKBENCHES, {
    fetchPolicy: 'no-cache',
    ...options,
  });
};
