import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';

const Spinner = ({
  fontSize = '45px',
  color = '#4a00e0',
  top = 'calc(50% - 22px)',
  left = 'calc(50% - 22px)',
  position = 'absolute',
}) => <LoadingOutlined style={{ fontSize, color, top, left, position }} spin />;

export default Spinner;
