// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_K8S_KINETICAROLES = gql`
  query K8sKineticaRoles {
    k8s_kineticaroles {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const GET_K8S_KINETICAROLE = gql`
  query K8sKineticaRoles($name: String!) {
    k8s_kineticarole(name: $name) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const CREATE_K8S_KINETICAROLE = gql`
  mutation CreateK8sKineticaRole($name: String!, $body: JSON!) {
    kineticaRoleCreate(name: $name, body: $body) {
      kind
      apiVersion
      metadata
      spec
      status
    }
  }
`;

export const useGetK8sKineticaRoles = () => {
  return useQuery(GET_K8S_KINETICAROLES);
};

export const useGetK8sKineticaRole = options => {
  return useQuery(GET_K8S_KINETICAROLE, options);
};

export const useCreateK8SKineticaRole = () => {
  return useMutation(CREATE_K8S_KINETICAROLE);
};
