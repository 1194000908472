// Imports

// App Imports

// Actions Types
export const WORKBOOK_SET_CURRENT_WORKSHEET_ID =
  'WORKBOOK/SET_CURRENT_WORKSHEET_ID';

// Actions

// Set current worksheet ID
export function setCurrentWorksheetId(id) {
  return async dispatch => {
    dispatch({
      type: WORKBOOK_SET_CURRENT_WORKSHEET_ID,
      id,
    });
  };
}
