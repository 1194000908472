// Imports
import React, { useMemo } from 'react';
import { Dropdown } from 'antd';
import {
  InsertRowAboveOutlined,
  EyeOutlined,
  SettingOutlined,
  DeleteOutlined,
  ConsoleSqlOutlined,
  BarChartOutlined,
  FileAddOutlined,
  GlobalOutlined,
  InfoCircleOutlined,
  SwapOutlined,
  DiffOutlined,
  EditOutlined,
  ReloadOutlined,
  ExportOutlined,
} from '@ant-design/icons';

// App Imports
import MultiSelectContext from '../MultiSelectContext';
import { EXPLORER_PANEL_WIDTH } from '../../../constants';

const TableObject = ({
  nodeData,
  handleTableInfo,
  handleTablePreview,
  handleTableCreateSql,
  handleTableRename,
  handleTableDelete,
  handleTableStats,
  handleTableWms,
  handleTableAddRecord,
  handleTableExport,
  handleTableConfigure,
  handleTableMove,
  handleTableRefresh,
  handleMultiSelect,
}) => {
  const disableWmsPreview = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const disableConfigure = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('logical_view') ||
        nodeData?.source?.type.includes('logical_external_table')
      );
    },
    [nodeData]
  );
  const disableAddRecord = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('logical_view') ||
        nodeData?.source?.type.includes('logical_external_table')
      );
    },
    [nodeData]
  );
  const disableStatistics = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const disableRename = useMemo(
    _ => {
      return nodeData?.source?.type.includes('logical_view');
    },
    [nodeData]
  );
  const enableRefresh = useMemo(
    _ => {
      return (
        nodeData?.source?.type.includes('materialized_view') ||
        nodeData?.source?.type.includes('materialized_external_table')
      );
    },
    [nodeData]
  );

  const tableContextMenu = node => {
    return {
      items: [
        {
          key: 'info',
          label: 'Info',
          icon: <InfoCircleOutlined />,
          onClick: _ => handleTableInfo(node.source),
        },
        {
          key: 'preview',
          label: 'Data Preview',
          icon: <EyeOutlined />,
          onClick: _ => handleTablePreview(node.source),
        },
        {
          key: 'wms',
          label: 'WMS Preview',
          icon: <GlobalOutlined />,
          onClick: _ => handleTableWms(node.source),
          disabled: disableWmsPreview,
        },
        {
          key: 'configure',
          label: 'Configure',
          icon: <SettingOutlined />,
          onClick: _ => handleTableConfigure(node.id),
          disabled: disableConfigure,
        },
        {
          key: 'addrecord',
          label: 'Add Record',
          icon: <FileAddOutlined />,
          onClick: _ => handleTableAddRecord(node.source),
          disabled: disableAddRecord,
        },
        {
          key: 'export',
          label: 'Export File',
          icon: <ExportOutlined />,
          onClick: _ => handleTableExport(node.source),
        },
        {
          key: 'sqlddl',
          label: 'SQL DDL',
          icon: <ConsoleSqlOutlined />,
          onClick: _ => handleTableCreateSql(node.id),
        },
        {
          key: 'stats',
          label: 'Statistics',
          icon: <BarChartOutlined />,
          onClick: _ => handleTableStats(node.source),
          disabled: disableStatistics,
        },
        {
          key: 'refresh',
          label: 'Refresh',
          icon: <ReloadOutlined />,
          onClick: _ => handleTableRefresh(node.source),
          disabled: !enableRefresh,
        },
        {
          type: 'divider',
        },
        {
          key: 'rename',
          label: 'Rename',
          icon: <EditOutlined />,
          onClick: _ => handleTableRename(node.source),
          disabled: disableRename,
        },
        {
          key: 'move',
          label: 'Move',
          icon: <SwapOutlined />,
          onClick: _ => handleTableMove(node.source),
        },
        {
          key: 'delete',
          label: 'Delete',
          icon: <DeleteOutlined />,
          onClick: _ => handleTableDelete(node.source),
          style: { color: '#C62828' },
        },
        {
          type: 'divider',
        },
        {
          key: 'multiselect',
          label: 'Multi-Select',
          icon: <DiffOutlined />,
          onClick: _ => handleMultiSelect(node),
          style: { color: '#3700b3' },
        },
      ],
    };
  };

  return (
    <MultiSelectContext.Consumer>
      {isMultiSelect => (
        <Dropdown
          menu={tableContextMenu(nodeData)}
          trigger={isMultiSelect ? ['contextMenu'] : ['click', 'contextMenu']}
        >
          <div>
            <InsertRowAboveOutlined />{' '}
            <span
              style={{
                width: `${
                  EXPLORER_PANEL_WIDTH - 125 - (isMultiSelect ? 24 : 0)
                }px`,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: 'inline-block',
                whiteSpace: 'nowrap',
                lineHeight: '16px',
                verticalAlign: 'text-top',
              }}
            >
              {nodeData.title}
            </span>
          </div>
        </Dropdown>
      )}
    </MultiSelectContext.Consumer>
  );
};

export default TableObject;
