// Imports
import { useQuery, gql } from '@apollo/client';

export const CREATE_GRAPH_GRAMMAR_BY_ENDPOINT = gql`
  query GetGraphGrammarByEndpoint($endpoint: String!) {
    graph_grammar(endpoint: $endpoint) {
      endpoint
      components
      filterparams
      options
    }
  }
`;

export const useGetGraphGrammarByEndpoint = options => {
  return useQuery(CREATE_GRAPH_GRAMMAR_BY_ENDPOINT, options);
};
