// Imports
import React, { useState, useMemo } from 'react';
import { CloseOutlined, CopyOutlined, SyncOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Button, message, Modal, Table } from 'antd';
import { useApolloClient } from '@apollo/client';

// App Imports
import { GET_ENDPOINT_JOBS } from '../../graphql/schema/jobs';
import { formatTimestamp } from '../../formatter';

const JOB_FLAG_UNFLAGGED = {
  label: 'Running',
  value: '',
};

const columns = [
  {
    key: 'job_id',
    title: 'Job ID',
    dataIndex: 'job_id',
    fixed: 'left',
  },
  {
    key: 'endpoint_name',
    title: 'Endpoint',
    dataIndex: 'endpoint_name',
    fixed: 'left',
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
  },
  {
    key: 'time_received',
    title: 'Received',
    dataIndex: 'time_received',
    render: text => {
      return formatTimestamp(text);
    },
  },
  {
    key: 'auth_id',
    title: 'Auth ID',
    dataIndex: 'auth_id',
  },
  {
    key: 'source_ip',
    title: 'Source IP',
    dataIndex: 'source_ip',
  },
  {
    key: 'user_data',
    title: 'User Data',
    dataIndex: 'user_data',
  },
];

const ResultError = ({ clearResult, queryError }) => {
  const [jobs, setJobs] = useState([]);
  const [isJobModalOpen, setIsJobModalOpen] = useState(false);

  const graphqlClient = useApolloClient();

  const runningJobs = useMemo(
    _ => {
      return jobs.filter(
        job => job.flag === null || job.flag === JOB_FLAG_UNFLAGGED.value
      );
    },
    [jobs]
  );

  const handleSeeRunningJobs = async _ => {
    setIsJobModalOpen(true);

    const { data } = await graphqlClient.query({
      query: GET_ENDPOINT_JOBS,
      fetchPolicy: 'no-cache',
    });

    if (data.endpoint_jobs) {
      setJobs(data.endpoint_jobs);
    } else {
      setJobs([]);
    }
  };

  return (
    <div
      className="console error"
      style={{
        margin: '10px 0px 0px',
        borderRadius: '5px',
        padding: '8px 5px 8px 10px',
        color: '#B6818B',
        fontWeight: 200,
        lineHeight: '18px',
      }}
    >
      <Button
        type="text"
        icon={<CloseOutlined />}
        onClick={clearResult}
        style={{
          float: 'right',
          height: '24px',
          width: '24px',
          lineHeight: '20px',
          top: '-3px',
          color: '#B6818B',
        }}
      ></Button>
      {queryError?.message}
      <CopyToClipboard
        text={queryError?.message}
        onCopy={() => {
          message.success('Copied to clipboard!');
        }}
      >
        <span style={{ color: '#888888', cursor: 'pointer' }}>
          <CopyOutlined style={{ marginRight: '3px', marginLeft: '5px' }} />
          Copy
        </span>
      </CopyToClipboard>
      {queryError?.message.includes('The query may still be running') && (
        <>
          <Button
            icon={<SyncOutlined />}
            size="small"
            onClick={handleSeeRunningJobs}
            style={{ marginTop: '5px', fontSize: '12px', marginLeft: '5px' }}
          >
            View Running Jobs
          </Button>
        </>
      )}
      <Modal
        title="Running Jobs"
        open={isJobModalOpen}
        width={window.innerWidth - 200}
        footer={[
          <Button
            key="ok"
            type="primary"
            onClick={_ => setIsJobModalOpen(false)}
          >
            OK
          </Button>,
        ]}
        cancelText={null}
        centered
      >
        <Table
          columns={columns}
          dataSource={runningJobs}
          rowKey="job_id"
          pagination={{
            pageSize: 10,
          }}
          scroll={{
            x: 'max-content',
          }}
          size="small"
        />
      </Modal>
    </div>
  );
};

export default ResultError;
