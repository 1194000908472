import React from 'react';

export default function ConfigToolbar({ top = '-16px', children }) {
  return (
    <div
      style={{
        position: 'absolute',
        left: '35px',
        pointerEvents: 'all',
        top,
      }}
    >
      <div
        style={{
          position: 'relative',
          float: 'left',
          zIndex: 1,
        }}
      >
        {children}
      </div>
    </div>
  );
}

export function ConfigToolbarWrapper({ top, wrap, children }) {
  if (wrap) {
    return <ConfigToolbar top={top}>{children}</ConfigToolbar>;
  }
  return children;
}
