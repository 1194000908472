// Imports
import React, { useState, useMemo, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  Spin,
  Tabs,
  Form,
  Checkbox,
  Row,
  Col,
  Input,
  Radio,
  Select,
  Button,
  Empty,
  Collapse,
} from 'antd';
import { FileTextOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import useAnalytics from '../../hooks/useAnalytics';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_JDBC,
  CDATA_USERNAMES,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
} from '../../constants';
import Spinner from '../../components/common/Spinner';
import ImportForm from './ImportForm';
import ImportHistory from './ImportHistory';
import TextArea from 'antd/lib/input/TextArea';
import { DEFAULT_FORM_ITEM_PROPS } from './utils';
import { getTimestampKey } from '../../helper';

const { Panel } = Collapse;

const URL_MODE_MANUAL = 'manual';
const URL_MODE_ASSIST = 'assist';

const Setup = ({
  form,
  setupForm,
  setIsCreating,
  setFormValues: setParentFormValues,
  setStep,
  driver,
}) => {
  const { data: { datasources = [] } = {}, refetch: refetchDatasources } =
    GraphQLServices.Datasources.useGetDatasources();
  const [createDatasource] = GraphQLServices.Datasources.useCreateDatasource();
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();

  const [urlMode, setUrlMode] = useState(URL_MODE_ASSIST);

  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setupForm.setFieldsValue({
            mode: 'existing',
            datasource: location.state.datasource.datasource_name,
          });
          setParentFormValues({
            datasource: location.state.datasource.datasource_name,
          });
        }
        if (location.state.table) {
          form.setFieldsValue({
            remoteTable: location.state.table,
          });
        }
      }
    },
    [location, form, setupForm, setParentFormValues]
  );

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_JDBC)) {
      variables.location = variables.location_jdbc;
    }

    if (variables.location_username) {
      variables.user_name = variables.location_username;
    }
    if (variables.location_password) {
      variables.password = variables.location_password;
    }

    if (variables.user_name && variables.password) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASOURCE_LOCATION_JDBC,
        identity: variables.user_name,
        secret: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsCreating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    setIsCreating(true);

    createDatasource({
      variables,
    })
      .then(resp => {
        const { datasource_name: datasource } = resp?.data?.datasourceCreate;
        setIsCreating(false);
        refetchDatasources().then(_ => {
          if (datasource) {
            setupForm.resetFields();
            setupForm.setFieldsValue({
              mode: 'existing',
              datasource,
            });
            form.setFieldsValue({
              dataSource: datasource,
              fileFormat: 'JSON',
            });

            analytics.track(analytics.EVENT_TYPES.CREATED_DATA_SOURCE)({});

            setUrlMode(URL_MODE_MANUAL);
            setStep(1);
          }
        });
      })
      .catch(err => {
        setIsCreating(false);
        refetchDatasources();
      });
  };

  const properties = useMemo(() => {
    return driver.properties
      ?.map(property =>
        property.Type === 'boolean'
          ? {
              ...property,
              Default: property.Default === 'true',
              Value: property.Value === 'true',
            }
          : property
      )
      .sort((prop1, prop2) => {
        if (prop1.CatOrdinal > prop2.CatOrdinal) {
          return 1;
        }
        if (prop1.CatOrdinal < prop2.CatOrdinal) {
          return -1;
        }
        return 0;
      });
  }, [driver]);

  const categories = useMemo(() => {
    return properties
      .reduce((acc, cur) => {
        if (!acc.some(item => item.Category === cur.Category)) {
          const { Category, CatOrdinal } = cur;
          acc.push({
            Category,
            CatOrdinal,
          });
        }
        return acc;
      }, [])
      .sort((prop1, prop2) => {
        if (prop1.CatOrdinal > prop2.CatOrdinal) {
          return 1;
        }
        if (prop1.CatOrdinal < prop2.CatOrdinal) {
          return -1;
        }
        return 0;
      });
  }, [properties]);

  const buildWithDefaults = useCallback(
    (prefix, params = []) => {
      const defaults = properties
        .filter(
          property =>
            (property.Required || property.type === 'boolean') &&
            !params.some(param => param.name === property.PropertyName)
        )
        .map(property => ({
          name: property.PropertyName,
          value: property.Default,
        }));
      const update = [...params, ...defaults];
      if (update.length > 0) {
        return `${prefix}:${update
          .filter(param => param.value !== '')
          .map(param => `${param.name}=${param.value}`)
          .join(';')}`;
      }
      return prefix;
    },
    [properties]
  );

  const [tempValue, setTempValue] = useState(null);

  const hierarchyFields = useMemo(
    _ => {
      return properties.reduce((acc, cur) => {
        if (cur.Hierarchy !== '') {
          const hierarchy = cur.Hierarchy.split('=');
          if (hierarchy.length > 1) {
            acc.push(hierarchy[0]);
          }
        }
        return acc;
      }, []);
    },
    [properties]
  );

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues?.mode) {
      setupForm.setFieldsValue({
        datasource: undefined,
      });
    }

    if (changedValues?.urlMode) {
      setUrlMode(changedValues?.urlMode);
      setupForm.setFieldsValue({
        location_jdbc: `${buildWithDefaults(driver.url_prefix)}`,
        location_jdbc_preview: `PREVIEW => ${buildWithDefaults(
          driver.url_prefix
        )}`,
        location_username: '',
        location_password: '',
      });
    }

    const propertyNames = properties.map(property => property.PropertyName);
    if (
      propertyNames.some(property =>
        Object.keys(changedValues).includes(property)
      )
    ) {
      const params = Object.keys(allValues)
        .filter(key => {
          const property = properties.find(
            property => property.PropertyName === key
          );
          return (
            propertyNames.includes(key) &&
            allValues[key] !== undefined &&
            (property.Default !== allValues[key] || property.Required)
          );
        })
        .map(key => ({
          name: key,
          value: allValues[key],
          isUsername: CDATA_USERNAMES.some(
            username => username.toLowerCase() === key.toLowerCase()
          ),
          isSecret: properties.some(property => {
            return (
              key === property.PropertyName &&
              property.Sensitivity === 'PASSWORD'
            );
          }),
        }));
      setupForm.setFieldsValue({
        location_jdbc: `${buildWithDefaults(
          driver.url_prefix,
          params.filter(param => !param.isUsername && !param.isSecret)
        )}`,
        location_jdbc_preview: `PREVIEW => ${buildWithDefaults(
          driver.url_prefix,
          params.filter(param => !param.isUsername && !param.isSecret)
        )}`,
        location_username: params.some(param => param.isUsername)
          ? params.find(param => param.isUsername).value
          : '',
        location_password: params.some(param => param.isSecret)
          ? params.find(param => param.isSecret).value
          : '',
      });
    } else {
      setParentFormValues({
        ...allValues,
      });
    }

    if (
      hierarchyFields.some(field => {
        return Object.keys(changedValues).includes(field);
      })
    ) {
      setTempValue(changedValues);
    }
  };

  const handleDatasourceChange = datasource => {
    form.setFieldsValue({
      dataSource: datasource,
      fileFormat: 'JSON',
    });
  };

  const datasourceOptions = useMemo(
    _ => {
      return datasources
        ? datasources
            .filter(datasource => {
              const provider = datasource.storage_provider_type.toLowerCase();
              return (
                provider.split(':')[0] === DATASOURCE_LOCATION_JDBC &&
                ((provider.split(':')[1] === 'cdata' &&
                  provider.split(':')[2] === driver.type) ||
                  (provider.split(':')[1] === 'kinetica' &&
                    provider.split(':')[1] === driver.type))
              );
            })
            .map(datasource => ({
              label: datasource.datasource_name,
              value: datasource.datasource_name,
            }))
        : [];
    },
    [datasources, driver]
  );

  const buildPanel = useCallback(
    category => (
      <Panel
        header={category.Category || 'Miscellaneous'}
        key={category.Category}
      >
        {properties &&
          properties
            .filter(property => property.Category === category.Category)
            .map(property => {
              const hierarchy = property.Hierarchy.split('=');
              const hasHierarchy = hierarchy.length > 1;
              const cond1 = !hasHierarchy;
              const cond2 =
                hasHierarchy &&
                hierarchy[1]
                  .split(',')
                  .includes(setupForm.getFieldValue(hierarchy[0]));
              const required = property.Required && (cond1 || cond2);
              switch (property.Type) {
                case 'boolean': {
                  return (
                    <Form.Item
                      key={property.PropertyName}
                      label={property.Name}
                      name={property.PropertyName}
                      tooltip={property.ShortDescription}
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      valuePropName="checked"
                      rules={[
                        {
                          required,
                          message: `Please input ${property.Name}!`,
                        },
                      ]}
                    >
                      <Checkbox />
                    </Form.Item>
                  );
                }
                default: {
                  return (
                    <Form.Item
                      key={property.PropertyName}
                      label={property.Name}
                      name={property.PropertyName}
                      tooltip={property.ShortDescription}
                      labelCol={{ span: 10 }}
                      wrapperCol={{ span: 14 }}
                      rules={[
                        {
                          required,
                          message: `Please input ${property.Name}!`,
                          whitespace: true,
                        },
                      ]}
                    >
                      {property.Sensitivity === 'PASSWORD' ? (
                        <Input.Password />
                      ) : property.Values !== '' ? (
                        <Select
                          options={property.Values.split(',').map(value => ({
                            label: value,
                            value,
                          }))}
                          allowClear
                        />
                      ) : (
                        <Input />
                      )}
                    </Form.Item>
                  );
                }
              }
            })}
      </Panel>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [properties, setupForm, tempValue]
  );

  const initialValues = {
    mode: 'new',
    urlMode: URL_MODE_ASSIST,
    location: DATASOURCE_LOCATION_JDBC,
    location_jdbc: `${buildWithDefaults(
      driver.url_prefix,
      properties
        .filter(property => property.Required)
        .map(property => ({
          name: property.PropertyName,
          value: property.Default,
        }))
    )}`,
    location_jdbc_preview: `PREVIEW => ${buildWithDefaults(
      driver.url_prefix,
      properties
        .filter(property => property.Required)
        .map(property => ({
          name: property.PropertyName,
          value: property.Default,
        }))
    )}`,
    location_username: '',
    location_password: '',
    ...properties.reduce((acc, cur) => {
      if (cur.Required || cur.Type === 'boolean') {
        acc[cur.PropertyName] = cur.Default;
      }
      return acc;
    }, {}),
  };

  const hasRequiredField = useCallback(
    categories => {
      return categories
        .filter(category => {
          return properties.some(property => {
            return property.Category === category.Category && property.Required;
          });
        })
        .map(category => category.Category);
    },
    [properties]
  );

  return (
    <Form
      form={setupForm}
      name="file"
      layout="horizontal"
      initialValues={initialValues}
      onValuesChange={onValuesChange}
      onFinish={onFinish}
      preserve={false}
      scrollToFirstError={true}
      {...DEFAULT_FORM_ITEM_PROPS}
    >
      <Row gutter={0}>
        <Col span={4}>
          <img
            src={driver.img.src}
            style={{
              height: '60px',
              float: 'right',
              margin: '10px 40px 0px 0px',
            }}
            alt={driver.title}
          />
        </Col>
        <Col span={12}>
          <h3>Directions</h3>
          <p>
            Optionally create a new <strong>{driver.title}</strong> data source,
            select a data source (new or existing), and click Next (or Create)
            to continue.
          </p>
        </Col>
        <Col span={6}>
          <Button
            type="primary"
            icon={<FileTextOutlined />}
            href={driver.docs}
            target="_blank"
            rel="noreferrer"
            style={{ float: 'right', marginTop: 30 }}
            ghost
          >
            Documentation
          </Button>
        </Col>
      </Row>
      <Row gutter={0}>
        <Col span={4}></Col>
        <Col span={18}>
          <h3>What would you like to do?</h3>
          <Form.Item label="" name="mode">
            <Radio.Group>
              <Radio.Button value="new">Create New Data Source</Radio.Button>
              <Radio.Button value="existing">
                Select Existing Data Source
              </Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      {setupForm.getFieldValue('mode') === 'new' && (
        <>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: 'Please input data source name!',
                whitespace: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Location"
            name="location"
            rules={[
              {
                required: true,
                message: 'Please select data source location!',
              },
            ]}
            hidden
          >
            <Radio.Group options={DATASOURCE_LOCATIONS} optionType="button" />
          </Form.Item>
          <Form.Item
            label="URL"
            name="urlMode"
            rules={[
              {
                required: true,
                message: 'Please select a URL mode!',
              },
            ]}
          >
            <Radio.Group>
              <Radio.Button value={URL_MODE_ASSIST}>Assisted</Radio.Button>
              <Radio.Button value={URL_MODE_MANUAL}>Manual</Radio.Button>
            </Radio.Group>
          </Form.Item>
          {urlMode === URL_MODE_ASSIST ? (
            <Row gutter={0}>
              <Col span={18} offset={4}>
                <p>
                  <strong>Parameters</strong>
                  <br />
                  The connection URL will be constructed based on the parameter
                  configuration below.
                  <br />
                  <a href={driver.docs} target="_blank" rel="noreferrer">
                    Click here to view documentation
                  </a>
                </p>
                <Collapse
                  defaultActiveKey={hasRequiredField(categories)}
                  style={{ marginBottom: '20px' }}
                >
                  {categories
                    .filter(category => category.CatOrdinal)
                    .map(buildPanel)}
                  {categories
                    .filter(category => !category.CatOrdinal)
                    .map(buildPanel)}
                </Collapse>
                <p>
                  <strong>Connection String</strong>
                  <br />
                  Sensitive credentials and parameters already set to their
                  system default value will not be added to the connection
                  string.
                </p>
              </Col>
            </Row>
          ) : null}
          <Form.Item
            name="location_jdbc"
            {...{
              wrapperCol: { span: 18, offset: 4 },
            }}
            style={urlMode === URL_MODE_ASSIST ? { display: 'none' } : {}}
          >
            {urlMode === URL_MODE_ASSIST ? (
              <Input />
            ) : (
              <TextArea
                placeholder="<jdbc url connection string>"
                rows={4}
                autoCorrect="false"
              />
            )}
          </Form.Item>
          <Form.Item name="location_username" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item name="location_password" style={{ display: 'none' }}>
            <Input />
          </Form.Item>
          <Form.Item
            name="location_jdbc_preview"
            {...{
              wrapperCol: { span: 18, offset: 4 },
            }}
            style={urlMode !== URL_MODE_ASSIST ? { display: 'none' } : {}}
          >
            <TextArea
              placeholder="<jdbc url connection string>"
              rows={4}
              autoCorrect="false"
              disabled={true}
            />
          </Form.Item>
        </>
      )}
      {setupForm.getFieldValue('mode') === 'existing' && (
        <>
          <Form.Item label="Data Source" name="datasource">
            <Select
              onChange={handleDatasourceChange}
              placeholder="Select a data source"
              notFoundContent={
                <Empty
                  description="No JDBC data source available"
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              }
            >
              {datasourceOptions.map(option => {
                return (
                  <Select.Option key={option.value} value={option.value}>
                    {option.label}
                  </Select.Option>
                );
              })}
            </Select>
          </Form.Item>
        </>
      )}
    </Form>
  );
};

const SourceCData = ({ driver, drivers }) => {
  const { loading, data: { cdata_drivers: [cdataSource] = [] } = {} } =
    GraphQLServices.CDataDrivers.useGetCDataDrivers({
      variables: {
        driver_name: driver.title,
        with_props: true,
      },
    });

  const [activeTabKey, setActiveTabKey] = useState('import');
  const { topBarCollapsed } = useSelector(state => state.app);
  const location = useLocation();

  useEffect(
    _ => {
      if (location.state) {
        if (location.state.datasource) {
          setActiveTabKey('import');
        }
      }
    },
    [location]
  );

  const handleTabClick = key => {
    setActiveTabKey(key);
  };

  const tabItems = useMemo(
    _ => {
      return [
        {
          key: 'import',
          label: `Import`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: topBarCollapsed
                  ? DEFAULT_TABPANE_NOHEAD_HEIGHT
                  : DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                {cdataSource && (
                  <ImportForm
                    setActiveTabKey={setActiveTabKey}
                    cdata_drivers={drivers}
                  >
                    {props => {
                      return <Setup driver={cdataSource} {...props} />;
                    }}
                  </ImportForm>
                )}
              </div>
            </div>
          ),
        },
        {
          key: 'history',
          label: `History`,
          children: (
            <div
              style={{
                padding: '20px',
                backgroundColor: '#ffffff',
                height: DEFAULT_TABPANE_HEIGHT,
                overflow: 'auto',
              }}
            >
              <div
                style={{
                  top: '0px',
                  left: '0px',
                  padding: '10px',
                }}
              >
                <ImportHistory
                  pageSize={Math.floor((window.innerHeight - 450) / 50)}
                />
              </div>
            </div>
          ),
        },
      ];
    },
    [cdataSource, drivers, topBarCollapsed]
  );

  return (
    <div>
      {cdataSource && (
        <img
          src={cdataSource.img.src}
          style={{
            height: '35px',
            display: 'inline-block',
            verticalAlign: 'top',
            marginRight: '10px',
          }}
          alt={cdataSource.img.alt}
        />
      )}
      <h2 style={{ display: 'inline-block' }}>
        {cdataSource && cdataSource.title} Import
      </h2>
      <Spin indicator={<Spinner />} spinning={loading}>
        <Tabs
          type="card"
          items={tabItems}
          activeKey={activeTabKey}
          onTabClick={handleTabClick}
        ></Tabs>
      </Spin>
    </div>
  );
};

export default SourceCData;
