// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_DATASINK_PERMISSION = gql`
  mutation GrantDatasinkPermission(
    $name: String!
    $permission: String!
    $datasink_name: String!
  ) {
    datasinkPermissionGrant(
      name: $name
      permission: $permission
      datasink_name: $datasink_name
    ) {
      name
      permission
      datasink_name
    }
  }
`;

export const REVOKE_DATASINK_PERMISSION = gql`
  mutation RevokeDatasinkPermission(
    $name: String!
    $permission: String!
    $datasink_name: String!
  ) {
    datasinkPermissionRevoke(
      name: $name
      permission: $permission
      datasink_name: $datasink_name
    ) {
      name
      permission
      datasink_name
    }
  }
`;

export const useGrantDatasinkPermission = () => {
  return useMutation(GRANT_DATASINK_PERMISSION);
};

export const useRevokeDatasinkPermission = () => {
  return useMutation(REVOKE_DATASINK_PERMISSION);
};
