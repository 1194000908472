// Imports
import React, { useEffect, useState, useMemo } from 'react';
import { Form, Input, Button, Row, Col, Alert } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

// App Imports
import GraphQLServices from '../../graphql/services';
import { login, logoutSilent } from '../../store/auth/actions';
import KineticaLogo from '../../images/logo.svg';
import { routes } from '../../setup/routes';
import Loading from '../../containers/Loading';
import { displayError, displayWarning, dtId, sleep } from '../../helper';
import { TEST_IDS } from '../../constants';
import useAnalytics from '../../hooks/useAnalytics';
import {
  INTERCOM_APP_ID,
  CUSTOMER_INFO_JSON,
  FREE_SAAS,
} from '../../setup/config';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

// Component
const Login = props => {
  const { data: { userMe = {} } = {} } =
    GraphQLServices.Users.useGetLocalUserMe();
  const { data: { setting } = {} } = GraphQLServices.Settings.useGetSettingById(
    {
      variables: {
        id: 'e809c64a-b700-47ab-8bf6-6dd83cc741ab',
      },
    }
  );

  const dispatch = useDispatch();
  const formRef = React.createRef();
  const history = useHistory();

  const { boot, trackEvent } = useIntercom();
  const analytics = useAnalytics();
  const query = useQuery();

  const { redirect } = history?.location?.state ?? {};

  const [loggingIn, setLoggingIn] = useState(false);

  useEffect(
    _ => {
      if (userMe?.username) {
        history.push(routes.home);
      }
    },
    [userMe, history]
  );

  const onFinish = async values => {
    setLoggingIn(true);
    await sleep(300);

    dispatch(
      login({
        username: values.username,
        password: values.password,
        free_saas: FREE_SAAS,
      })
    )
      .then(async user => {
        // Check if user exists in db
        if (!user?.isDbStatusOk) {
          dispatch(logoutSilent()).then(success => {
            setLoggingIn(false);
            displayWarning(
              `User '${values.username}' is being provisioned. Please try again in a few minutes.`
            );
          });
        } else if (user) {
          if (INTERCOM_APP_ID) {
            const { cloud_user_id = '', cloud_group_id = '' } = user;

            // Need to identify and group before logged in track event
            if (cloud_user_id && cloud_group_id) {
              analytics.identify(cloud_user_id);
              await sleep(200);
              analytics.group(cloud_group_id);
            } else if (CUSTOMER_INFO_JSON) {
              try {
                var customerInfo = JSON.parse(CUSTOMER_INFO_JSON);
                if (Object.keys(customerInfo).length > 0) {
                  analytics.identify(customerInfo.managed_resource_group_id);
                }
              } catch (err) {
                console.error(err);
              }
            }

            try {
              const customerInfo = JSON.parse(CUSTOMER_INFO_JSON);
              if (Object.keys(customerInfo).length > 0) {
                delete customerInfo.name;
                boot({
                  userId:
                    cloud_user_id || customerInfo.managed_resource_group_id,
                  email_addr: customerInfo.customer_email,
                  userHash: userMe?.user_hash,
                  customAttributes: {
                    ...customerInfo,
                    kinetica_username: values.username,
                    group_id: cloud_group_id,
                  },
                });
              } else {
                throw new Error('No customer info');
              }
            } catch (err) {
              if (cloud_user_id && cloud_group_id) {
                boot({
                  userId: cloud_user_id,
                  userHash: userMe?.user_hash,
                  customAttributes: {
                    kinetica_username: values.username,
                    group_id: cloud_group_id,
                  },
                });
              } else {
                boot({
                  customAttributes: {
                    kinetica_username: values.username,
                  },
                });
              }
            }
          }

          trackEvent(analytics.EVENT_TYPES.LOGGED_IN, {});

          analytics.track(analytics.EVENT_TYPES.LOGGED_IN)(
            { username: values.username },
            _ => {
              if (redirect) {
                if (!redirect.includes(routes.context)) {
                  window.location.href = `${routes.context}${redirect}`;
                } else {
                  window.location.href = redirect;
                }
              } else {
                window.location.href = routes.root;
              }
            }
          );
        } else {
          setLoggingIn(false);
        }
      })
      .catch(error => {
        if (error?.message.includes('401')) {
          displayError(new Error('Invalid login credentials'));
        } else {
          displayError(error);
        }
        setLoggingIn(false);
      });
  };

  const onFinishFailed = errorInfo => {
    console.warn('Login.onFinishFailed:', errorInfo);
  };

  const systemMessage = useMemo(
    _ => {
      if (setting && setting.value) {
        return setting.value;
      }
      return null;
    },
    [setting]
  );

  // Render
  return !userMe || !userMe?.username ? (
    <Row
      style={{
        top: systemMessage ? 'calc(50% - 230px)' : 'calc(50% - 200px)',
        position: 'relative',
      }}
    >
      <Col xs={4} sm={6} md={7} lg={8} xl={9}></Col>
      <Col xs={16} sm={12} md={10} lg={8} xl={6}>
        <div style={{ textAlign: 'center' }}>
          <img
            src={KineticaLogo}
            alt="Kinetica Logo"
            style={{ height: '40px', position: 'relative', top: '-3px' }}
          />
          <h1
            style={{
              color: '#ffffff66',
              fontSize: '34px',
              fontWeight: '200',
            }}
          >
            Workbench
          </h1>
          {systemMessage && (
            <Alert
              type="warning"
              message={systemMessage}
              style={{
                backgroundColor: '#fffbe6cc',
                marginBottom: '20px',
                borderRadius: '10px',
              }}
              banner
            />
          )}
          <div
            className="login"
            style={{
              padding: '50px 50px 20px',
              backgroundColor: '#ffffff09',
              border: '1px solid #ffffff10',
              borderRadius: '10px',
              boxShadow: '20px 20px 60px #00000022, -20px -20px 60px #00000022',
              width: 320,
              marginTop: '20px',
              margin: 'auto',
            }}
          >
            <Form
              name="login"
              ref={formRef}
              layout="vertical"
              initialValues={{ username: query.get('username') ?? '' }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="false"
              colon={false}
            >
              <Form.Item
                label=""
                name="username"
                rules={[
                  {
                    required: true,
                    message: 'Please input your username!',
                  },
                ]}
              >
                <Input
                  placeholder="Username"
                  bordered={false}
                  size="large"
                  autoFocus={true}
                  style={{
                    backgroundColor: '#00000033',
                    color: '#ffffff',
                  }}
                  {...dtId(TEST_IDS.LOGIN_USERNAME)}
                />
              </Form.Item>
              <Form.Item
                label=""
                name="password"
                rules={[
                  {
                    required: true,
                    message: 'Please input your password!',
                  },
                ]}
              >
                <Input.Password
                  placeholder="Password"
                  bordered={false}
                  size="large"
                  style={{
                    backgroundColor: '#00000033',
                    color: '#ffffff',
                  }}
                  {...dtId(TEST_IDS.LOGIN_PASSWORD)}
                />
              </Form.Item>
              <Form.Item>
                <div className="button-login">
                  <Button
                    type="default"
                    htmlType="submit"
                    loading={loggingIn}
                    size="large"
                    style={{
                      border: 0,
                      color: '#ffffff',
                      borderRadius: '5px',
                      overflow: 'hidden',
                      zIndex: '1',
                      backgroundColor: 'transparent',
                    }}
                    block
                    {...dtId(TEST_IDS.LOGIN_SUBMIT)}
                  >
                    Login
                    <div className="button-login-fill"></div>
                  </Button>
                </div>
              </Form.Item>
            </Form>
          </div>
        </div>
      </Col>
      <Col xs={4} sm={6} md={7} lg={8} xl={9}></Col>
    </Row>
  ) : (
    <Loading />
  );
};

export default Login;
