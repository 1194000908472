// Imports
import { useQuery, gql, useMutation } from '@apollo/client';

export const GET_CONTEXTS = gql`
  query Contexts {
    contexts {
      context_name
      definition
    }
  }
`;

export const GET_CONTEXT_BY_NAME = gql`
  query Context($context_name: String!) {
    context(context_name: $context_name) {
      context_name
      object_name
      object_type
      object_description
      object_rules
      object_comments {
        name
        description
      }
      object_samples {
        question
        answer
      }
    }
  }
`;

export const CREATE_CONTEXT = gql`
  mutation CreateContext(
    $context_name: String!
    $definitions: JSON!
    $overwrite: Boolean
  ) {
    contextCreate(
      context_name: $context_name
      definitions: $definitions
      overwrite: $overwrite
    ) {
      context_name
      object_name
      object_type
      object_description
      object_rules
      object_comments {
        name
        description
      }
      object_samples {
        question
        answer
      }
    }
  }
`;

export const DELETE_CONTEXT_BY_NAME = gql`
  mutation DeleteContextByName($context_name: String!) {
    contextDeleteByName(context_name: $context_name) {
      context_name
    }
  }
`;

export const useGetContexts = () => {
  return useQuery(GET_CONTEXTS);
};

export const useGetContextByName = options => {
  return useQuery(GET_CONTEXT_BY_NAME, options);
};

export const useCreateContext = () => {
  return useMutation(CREATE_CONTEXT);
};

export const useDeleteContextByName = () => {
  return useMutation(DELETE_CONTEXT_BY_NAME);
};
