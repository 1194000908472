// Imports
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from 'antd';
import * as monaco from 'monaco-editor';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  language as kineticaSql,
  conf,
  defaultSuggestions,
} from '../../components/editor/kinetica-sql';
import WorkbookEditor from './WorkbookEditor';

const { Content } = Layout;

monaco.languages.register({
  id: 'kinetica-sql',
});
monaco.languages.setMonarchTokensProvider('kinetica-sql', kineticaSql);
monaco.languages.setLanguageConfiguration('kinetica-sql', conf);

// Component
const DataExploreEmbed = () => {
  const { workbookId } = useParams();

  const { data: { tables } = [] } = GraphQLServices.Tables.useGetTables();

  useEffect(
    _ => {
      let disposable;
      if (tables) {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }

        disposable = monaco.languages.registerCompletionItemProvider(
          'kinetica-sql',
          {
            provideCompletionItems() {
              let added = new Set();
              const dynamicSuggestions = [];
              tables.forEach(entry => {
                // Add fully qualified name
                if (!added.has(entry.full)) {
                  dynamicSuggestions.push({
                    label: entry.full,
                    kind: monaco.languages.CompletionItemKind.Value,
                    insertText: entry.full,
                  });
                  added.add(entry.full);
                }

                // Add schema name only
                if (!added.has(entry.schema)) {
                  dynamicSuggestions.push({
                    label: entry.schema,
                    kind: monaco.languages.CompletionItemKind.Value,
                    insertText: entry.schema,
                  });
                  added.add(entry.schema);
                }

                // Add column names
                entry.columns.forEach(column => {
                  if (!added.has(column.name)) {
                    dynamicSuggestions.push({
                      label: column.name,
                      kind: monaco.languages.CompletionItemKind.Value,
                      insertText: column.name,
                    });
                    added.add(column.name);
                  }
                });
              });

              return {
                suggestions: [...defaultSuggestions, ...dynamicSuggestions],
              };
            },
          }
        );
      }

      return _ => {
        if (disposable && disposable.dispose) {
          disposable.dispose();
        }
      };
    },
    [tables]
  );

  return (
    <Layout className="dataexplore" style={{ height: '100vh' }}>
      <Content
        style={{
          padding: '10px 20px 20px',
          height: 'calc(100vh - 40px)',
          overflowY: 'scroll',
        }}
      >
        {workbookId && (
          <WorkbookEditor
            workbookId={workbookId}
            refetchWorkbooks={_ => {}}
            embed={true}
          />
        )}
      </Content>
    </Layout>
  );
};

export default DataExploreEmbed;
