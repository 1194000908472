// Imports
import React, { useState } from 'react';
import { Modal, Form, Input, Radio, Row, Col, Switch, Button } from 'antd';

// App Imports
import GraphQLServices from '../../graphql/services';
import {
  DATASOURCE_LOCATIONS,
  DATASOURCE_LOCATION_AZURE,
  DATASOURCE_LOCATION_HDFS,
  DATASOURCE_LOCATION_S3,
  DATASOURCE_LOCATION_KAFKA,
  DATASOURCE_LOCATION_JDBC,
  DATASOURCE_S3_AUTH_TYPES,
  DATASOURCE_AZURE_AUTH_TYPES,
  DATASOURCE_AZURE_AUTH_NO_AUTH,
  DATASOURCE_AZURE_AUTH_PASSWORD,
  DATASOURCE_AZURE_AUTH_SAS_TOKEN,
  DATASOURCE_AZURE_AUTH_OAUTH_TOKEN,
  DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY,
  DATASOURCE_KAFKA_AUTH_TYPES,
  DATASOURCE_KAFKA_AUTH_NO_AUTH,
  DATASOURCE_KAFKA_AUTH_SASL_SSL,
  DATASOURCE_HDFS_AUTH_TYPES,
  DATASOURCE_HDFS_AUTH_PASSWORD,
  DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN,
  DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB,
  DATASOURCE_DEFAULT_AUTH_TYPE,
  DATASOURCE_HDFS_PREFIX,
  DATASOURCE_KAFKA_PREFIX,
  DATASOURCE_S3_AUTH_ACCESS_KEY,
  DATASOURCE_S3_AUTH_NO_AUTH,
  DATASOURCE_JDBC_AUTH_TYPES,
  DATASOURCE_JDBC_AUTH_PASSWORD,
} from '../../constants';
import { getTimestampKey, displaySuccess } from '../../helper';

const getAzureAuthType = options => {
  if (options?.azure_sas_token) {
    return DATASOURCE_AZURE_AUTH_SAS_TOKEN;
  } else if (options?.azure_oauth_token) {
    return DATASOURCE_AZURE_AUTH_OAUTH_TOKEN;
  } else if (options?.azure_tenant_id) {
    return DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY;
  } else if (options?.password && options?.password !== '') {
    return DATASOURCE_AZURE_AUTH_PASSWORD;
  }
  return DATASOURCE_AZURE_AUTH_NO_AUTH;
};

const getJdbcAuthType = options => {
  return DATASOURCE_JDBC_AUTH_PASSWORD;
};

const getS3AuthType = options => {
  if (options?.password && options?.password !== '') {
    return DATASOURCE_S3_AUTH_ACCESS_KEY;
  }
  return DATASOURCE_S3_AUTH_NO_AUTH;
};

const getHdfsAuthType = options => {
  if (options?.hdfs_use_kerberos) {
    return DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN;
  } else if (options?.hdfs_kerberos_keytab) {
    return DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB;
  }
  return DATASOURCE_DEFAULT_AUTH_TYPE;
};

const getKafkaAuthType = options => {
  if (options?.credential) {
    return DATASOURCE_KAFKA_AUTH_SASL_SSL;
  }
  return DATASOURCE_KAFKA_AUTH_NO_AUTH;
};

const getNormalizedLocation = location => {
  if (location?.toLowerCase().includes(DATASOURCE_LOCATION_AZURE)) {
    return DATASOURCE_LOCATION_AZURE;
  } else if (location?.toLowerCase().includes(DATASOURCE_LOCATION_HDFS)) {
    return DATASOURCE_LOCATION_HDFS;
  } else if (location?.toLowerCase().includes(DATASOURCE_LOCATION_S3)) {
    return DATASOURCE_LOCATION_S3;
  } else if (location?.toLowerCase().includes(DATASOURCE_LOCATION_JDBC)) {
    return DATASOURCE_LOCATION_JDBC;
  }
  return location;
};

const HDFS_REPLACE_REGEX = new RegExp(DATASOURCE_HDFS_PREFIX, 'gi');
const KAFKA_REPLACE_REGEX = new RegExp(DATASOURCE_KAFKA_PREFIX, 'gi');

const DatasourceEditModal = ({
  datasource,
  visible,
  close,
  width = 750,
  callback,
}) => {
  const [createCredential] = GraphQLServices.Credentials.useCreateCredential();
  const [updateDatasourceByName] =
    GraphQLServices.Datasources.useUpdateDatasourceByName();

  const initialValues = {
    ...datasource.additional_info,
    name: datasource.datasource_name,
    password:
      datasource.additional_info.password === '[redacted]'
        ? ''
        : datasource.additional_info.password,
    location: datasource.storage_provider_type.toLowerCase().split(':')[0],
    location_hdfs: datasource.additional_info.location
      ?.toLowerCase()
      .includes(DATASOURCE_HDFS_PREFIX)
      ? datasource.additional_info.location.replace(HDFS_REPLACE_REGEX, '')
      : getNormalizedLocation(datasource.additional_info.location),
    location_kafka: datasource.additional_info.location
      ?.toLowerCase()
      .includes(DATASOURCE_KAFKA_PREFIX)
      ? datasource.additional_info.location.replace(KAFKA_REPLACE_REGEX, '')
      : getNormalizedLocation(datasource.additional_info.location),
    location_jdbc: datasource.additional_info.location,
    jdbc_auth_type: getJdbcAuthType(datasource.additional_info),
    azure_auth_type: getAzureAuthType(datasource.additional_info),
    s3_auth_type: getS3AuthType(datasource.additional_info),
    hdfs_auth_type: getHdfsAuthType(datasource.additional_info),
    kafka_auth_type: getKafkaAuthType(datasource.additional_info),
  };

  const [isUpdating, setIsUpdating] = useState(false);
  const [formValues, setFormValues] = useState({
    ...initialValues,
  });

  const [form] = Form.useForm();

  const onFinish = async values => {
    const variables = Object.keys(values).reduce((acc, cur) => {
      if (values[cur]) {
        acc[cur] = values[cur];
      }
      return acc;
    }, {});

    if (variables.location?.includes(DATASOURCE_LOCATION_HDFS)) {
      variables.location = DATASOURCE_HDFS_PREFIX + variables.location_hdfs;
    }
    if (variables.location?.includes(DATASOURCE_LOCATION_KAFKA)) {
      variables.location = DATASOURCE_KAFKA_PREFIX + variables.location_kafka;
    }
    if (variables.location?.includes(DATASOURCE_LOCATION_JDBC)) {
      variables.location = variables.location_jdbc;
    }

    setIsUpdating(true);

    // Need to create credential first for kafka stream
    if (
      variables.location?.includes(DATASOURCE_LOCATION_KAFKA) &&
      variables?.kafka_auth_type === DATASOURCE_KAFKA_AUTH_SASL_SSL &&
      variables.user_name &&
      variables.password
    ) {
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables2 = {
        name,
        type: DATASOURCE_LOCATION_KAFKA,
        identity: '',
        secret: '',
        security_protocol: DATASOURCE_KAFKA_AUTH_SASL_SSL.toUpperCase(),
        sasl_mechanism: 'PLAIN',
        sasl_username: variables.user_name,
        sasl_password: variables.password,
      };
      const createCredResp = await createCredential({
        variables: variables2,
      });
      if (createCredResp?.errors) {
        setIsUpdating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Need to create credential first for jdbc
    if (
      variables.location?.includes(DATASOURCE_LOCATION_JDBC) &&
      variables?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD &&
      variables.user_name &&
      variables.password
    ) {
      const { user_name, password } = variables;
      const name = `${variables.name}_credential_autogen_${getTimestampKey()}`;
      const variables3 = {
        name,
        type: DATASOURCE_LOCATION_JDBC,
        identity: user_name,
        secret: password,
      };
      const createCredResp = await createCredential({
        variables: variables3,
      });
      if (createCredResp?.errors) {
        setIsUpdating(false);
        return;
      }

      variables.credential = name;
      variables.user_name = undefined;
      variables.password = undefined;
    }

    // Set encryption algo when key set for S3
    if (
      variables.location?.includes(DATASOURCE_LOCATION_S3) &&
      variables?.s3_auth_type === DATASOURCE_S3_AUTH_ACCESS_KEY &&
      variables?.s3_encryption_customer_key !== ''
    ) {
      variables.s3_encryption_customer_algorithm = 'AES256';
    }

    updateDatasourceByName({
      variables,
    })
      .then(resp => {
        displaySuccess(`Data source ${variables.name} updated.`);
        setIsUpdating(false);
        if (callback) {
          if (resp.errors) {
            callback(resp, null);
          } else {
            callback(null, resp);
          }
        }
      })
      .catch(err => {
        setIsUpdating(false);
        if (callback) {
          callback(err, null);
        }
      });
  };

  const update = _ => {
    form.submit();
  };

  const onValuesChange = (changedValues, allValues) => {
    setFormValues({
      ...allValues,
      jdbc_auth_type: allValues.jdbc_auth_type || DATASOURCE_JDBC_AUTH_PASSWORD,
      azure_auth_type:
        allValues.azure_auth_type || DATASOURCE_AZURE_AUTH_NO_AUTH,
      s3_auth_type: allValues.s3_auth_type || DATASOURCE_S3_AUTH_NO_AUTH,
      hdfs_auth_type: allValues.hdfs_auth_type || DATASOURCE_DEFAULT_AUTH_TYPE,
      kafka_auth_type:
        allValues.kafka_auth_type || DATASOURCE_KAFKA_AUTH_NO_AUTH,
    });
  };

  const formLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const formSingleLayout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 },
  };

  return (
    <Modal
      title="Configure Data Source"
      open={visible}
      width={width}
      bodyStyle={{ minHeight: '400px' }}
      footer={[
        <Button key="cancel" onClick={close}>
          Cancel
        </Button>,
        <Button
          key="create"
          type="primary"
          onClick={update}
          loading={isUpdating}
        >
          Update
        </Button>,
      ]}
      onCancel={close}
      destroyOnClose
      centered
    >
      <Form
        {...formLayout}
        form={form}
        name="datasource"
        layout="horizontal"
        initialValues={initialValues}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        colon={false}
        preserve={false}
      >
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Name"
              name="name"
              wrapperCol={{ span: 11 }}
              rules={[
                {
                  required: true,
                  message: 'Please input data source name!',
                  whitespace: true,
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              {...formSingleLayout}
              label="Location"
              name="location"
              rules={[
                {
                  required: true,
                  message: 'Please select data source location!',
                },
              ]}
            >
              <Radio.Group
                options={DATASOURCE_LOCATIONS}
                optionType="button"
                buttonStyle="solid"
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
        {formValues?.location?.includes(DATASOURCE_LOCATION_JDBC) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_jdbc"
              rules={[
                {
                  required: true,
                  message: 'Please input JDBC connection URL!',
                },
              ]}
            >
              <Input placeholder="<jdbc url connection string>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="jdbc_auth_type"
              initialValue={DATASOURCE_JDBC_AUTH_PASSWORD}
              hidden
            >
              <Radio.Group
                options={DATASOURCE_JDBC_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="Container Name"
              name="azure_container_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Azure Container Name!',
                },
              ]}
            >
              <Input placeholder="<azure container name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="azure_auth_type"
            >
              <Radio.Group
                options={DATASOURCE_AZURE_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_hdfs"
              rules={[
                {
                  required: true,
                  message: 'Please input HDFS host:port!',
                },
              ]}
            >
              <Input
                addonBefore={DATASOURCE_HDFS_PREFIX}
                placeholder="<host>:<port>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="hdfs_auth_type"
            >
              <Radio.Group
                options={DATASOURCE_HDFS_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_S3) && (
          <>
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Bucket Name" name="s3_bucket_name">
                  <Input placeholder="<aws s3 bucket name>" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Region" name="s3_region">
                  <Input placeholder="<aws s3 region>" />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="s3_auth_type"
            >
              <Radio.Group
                options={DATASOURCE_S3_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA) && (
          <>
            <Form.Item
              {...formSingleLayout}
              label="URL"
              name="location_kafka"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Stream host:port!',
                },
              ]}
            >
              <Input
                addonBefore={DATASOURCE_KAFKA_PREFIX}
                placeholder="<host>:<port>"
              />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Topic Name"
              name="kafka_topic_name"
              rules={[
                {
                  required: true,
                  message: 'Please input Kafka Topic Name!',
                },
              ]}
            >
              <Input placeholder="<kafka stream topic name>" />
            </Form.Item>
            <Form.Item
              {...formSingleLayout}
              label="Auth Type"
              name="kafka_auth_type"
            >
              <Radio.Group
                options={DATASOURCE_KAFKA_AUTH_TYPES}
                optionType="button"
                buttonStyle="solid"
              />
            </Form.Item>
          </>
        )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_JDBC) &&
          formValues?.jdbc_auth_type === DATASOURCE_JDBC_AUTH_PASSWORD && (
            <>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Username" name="user_name">
                    <Input placeholder="<jdbc connection username>" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Password" name="password">
                    <Input
                      type="password"
                      placeholder="<jdbc connection password>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formSingleLayout}
                label="Driver Class"
                name="jdbc_driver_class_name"
              >
                <Input placeholder="<jdbc driver class name>" disabled />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Driver JAR Path"
                name="jdbc_driver_jar_path"
              >
                <Input placeholder="<path to jdbc driver jar>" disabled />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_KAFKA) &&
          formValues?.kafka_auth_type === DATASOURCE_KAFKA_AUTH_SASL_SSL && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Username"
                name="user_name"
              >
                <Input placeholder="<kafka stream sasl username>" />
              </Form.Item>
              <Form.Item {...formSingleLayout} label="Password" name="password">
                <Input
                  type="password"
                  placeholder="<kafka stream sasl password>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_S3) &&
          formValues?.s3_auth_type === DATASOURCE_S3_AUTH_ACCESS_KEY && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Access ID"
                name="user_name"
              >
                <Input placeholder="<aws s3 access key id>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Secret Key"
                name="password"
              >
                <Input
                  type="password"
                  placeholder="<aws s3 secret access key>"
                />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Encryption Key"
                name="s3_encryption_customer_key"
              >
                <Input
                  type="password"
                  placeholder="<aws s3 customer encryption key>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_NO_AUTH && (
            <Form.Item
              {...formSingleLayout}
              label="Storage Acct"
              name="user_name"
            >
              <Input placeholder="<azure storage account name>" />
            </Form.Item>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_PASSWORD && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Access Key"
                name="password"
              >
                <Input
                  type="password"
                  placeholder="<azure storage account key>"
                />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_SAS_TOKEN && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="SAS Token"
                name="azure_sas_token"
              >
                <Input placeholder="<azure sas token>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type === DATASOURCE_AZURE_AUTH_OAUTH_TOKEN && (
            <>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="user_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="OAuth Token"
                name="azure_oauth_token"
              >
                <Input placeholder="<azure oauth token>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_AZURE) &&
          formValues?.azure_auth_type ===
            DATASOURCE_AZURE_AUTH_ACTIVE_DIRECTORY && (
            <>
              <Row gutter={10}>
                <Col span={12}>
                  <Form.Item label="Client ID" name="user_name">
                    <Input placeholder="<ad client id>" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Secret Key" name="password">
                    <Input
                      type="password"
                      placeholder="<ad client secret key>"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                {...formSingleLayout}
                label="Storage Acct"
                name="azure_storage_account_name"
              >
                <Input placeholder="<azure storage account name>" />
              </Form.Item>
              <Form.Item
                {...formSingleLayout}
                label="Tenant ID"
                name="azure_tenant_id"
              >
                <Input placeholder="<azure tenant id>" />
              </Form.Item>
            </>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type === DATASOURCE_HDFS_AUTH_PASSWORD && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Password" name="password">
                  <Input type="password" />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_TOKEN && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Use Kerberos" name="hdfs_use_kerberos">
                  <Switch checked={formValues?.hdfs_use_kerberos} />
                </Form.Item>
              </Col>
            </Row>
          )}
        {formValues?.location?.includes(DATASOURCE_LOCATION_HDFS) &&
          formValues?.hdfs_auth_type ===
            DATASOURCE_HDFS_AUTH_KERBEROS_KEYTAB && (
            <Row gutter={10}>
              <Col span={12}>
                <Form.Item label="Username" name="user_name">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item label="Keytab File" name="hdfs_kerberos_keytab">
                  <Input placeholder="<keytab file/path>" />
                </Form.Item>
              </Col>
            </Row>
          )}
      </Form>
    </Modal>
  );
};

export default DatasourceEditModal;
