// Imports
import React, { useMemo, useState } from 'react';
import {
  Modal,
  Button,
  Spin,
  Row,
  Col,
  Popconfirm,
  notification,
  Space,
  Input,
  Tag,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

// App Imports
import GraphQLServices from '../../graphql/services';
import { displaySuccess } from '../../helper';
import { DEFAULT_ENVIRONMENT_NAME } from '../../constants';
import Spinner from '../common/Spinner';

const FuncEnvironmentInfoModal = ({
  context,
  visible,
  setVisible,
  width,
  height,
}) => {
  const { func_environment_name } = context;
  const {
    loading: funcEnvironmentLoading,
    data,
    refetch,
  } = GraphQLServices.FuncEnvironments.useGetFuncEnvironmentByName({
    variables: {
      func_environment_name,
    },
  });
  const { loading: funcDefaultEnvironmentLoading, data: defaultData } =
    GraphQLServices.FuncEnvironments.useGetFuncEnvironmentByName({
      variables: {
        func_environment_name: DEFAULT_ENVIRONMENT_NAME,
      },
    });

  const [installPackages, { loading: installingPackages }] =
    GraphQLServices.FuncEnvironments.useInstallFuncEnvironmentPackagesByName();
  const [uninstallPackages, { loading: uninstallingPackages }] =
    GraphQLServices.FuncEnvironments.useUninstallFuncEnvironmentPackagesByName();

  const [newPackages, setNewPackages] = useState('');

  const handlePackageInstall = _ => {
    const install = async _ => {
      try {
        const resp = await installPackages({
          variables: {
            func_environment_name,
            packages: newPackages.split(' ').filter(pkg => pkg.trim() !== ''),
          },
        });

        const oldPackages = data?.func_environment?.packages.map(
          pkg => pkg.package_name
        );
        const updatePackages =
          resp?.data?.funcEnvironmentInstallPackagesByName?.packages.map(
            pkg => pkg.package_name
          );

        if (JSON.stringify(oldPackages) === JSON.stringify(updatePackages)) {
          notification.warn({
            message: `Unable to install package(s).`,
          });
        } else if (updatePackages) {
          displaySuccess(`Function Environment package(s) installed.`);

          setNewPackages('');
          await refetch();
        }
      } catch (error) {
        // Let global error handler display it
      }
    };
    install();
  };

  const handlePackageUninstall = pkg => _ => {
    const uninstall = async _ => {
      try {
        const resp = await uninstallPackages({
          variables: {
            func_environment_name,
            packages: [pkg.package_name],
          },
        });

        const oldPackages = data?.func_environment?.packages.map(
          pkg => pkg.package_name
        );
        const newPackages =
          resp?.data?.funcEnvironmentUninstallPackagesByName?.packages.map(
            pkg => pkg.package_name
          );

        if (JSON.stringify(oldPackages) === JSON.stringify(newPackages)) {
          notification.warn({
            message: `Unable to uninstall ${pkg.package_name} package.`,
          });
        } else if (newPackages) {
          displaySuccess(`Function Environment package(s) uninstalled.`);

          await refetch();
        }
      } catch (error) {
        // Let global error handler display it
      }
    };
    uninstall();
  };

  const handleNewPackagesUpdate = evt => {
    setNewPackages(evt.target.value);
  };

  const defaultPackages = useMemo(() => {
    return defaultData
      ? defaultData?.func_environment?.packages.map(pkg => pkg.package_name)
      : [];
  }, [defaultData]);

  const isDefault = func_environment_name === DEFAULT_ENVIRONMENT_NAME;

  return (
    <Modal
      title={`Configure Function Environment: ${func_environment_name}`}
      open={visible || funcEnvironmentLoading}
      width={width}
      onCancel={_ => setVisible(false)}
      footer={[
        <span key="warning">
          <Tag
            style={{
              color: '#3700b366',
              paddingLeft: 3,
              paddingRight: 3,
              float: 'left',
              top: '4px',
              position: 'relative',
            }}
          >
            Default
          </Tag>
          <i style={{ float: 'left', margin: 3, color: '#cccccc' }}>
            packages are part of the default environment and cannot be
            uninstalled.
          </i>
        </span>,
        <Button key="submit" onClick={_ => setVisible(false)}>
          Close
        </Button>,
      ]}
      destroyOnClose
      centered
    >
      <Spin
        indicator={<Spinner />}
        spinning={
          funcEnvironmentLoading ||
          funcDefaultEnvironmentLoading ||
          installingPackages ||
          uninstallingPackages
        }
      >
        <div
          style={{
            maxHeight: height + 20,
            minHeight: '200px',
            overflowX: 'hidden',
            overflowY: 'auto',
          }}
        >
          {data?.func_environment?.packages && <h3>Installed Packages</h3>}
          <Space direction="vertical" size="large">
            {data?.func_environment?.packages && (
              <Input.Group compact>
                <Input
                  value={newPackages}
                  onChange={handleNewPackagesUpdate}
                  style={{ width: 'calc(100% - 150px)' }}
                  placeholder={
                    isDefault
                      ? 'Default environment is restricted'
                      : 'package_name==version (version is optional, space-delimited for multiple)'
                  }
                  disabled={isDefault}
                />
                <Popconfirm
                  title="Are you sure you want to install package(s)?"
                  placement="topRight"
                  onConfirm={handlePackageInstall}
                  disabled={isDefault}
                >
                  <Button
                    type="primary"
                    disabled={isDefault}
                    style={{ width: 150 }}
                  >
                    Install Package(s)
                  </Button>
                </Popconfirm>
              </Input.Group>
            )}
            <Row gutter={8}>
              {data?.func_environment?.packages.map(pkg => {
                return (
                  <Col key={pkg.package_name} span={6} xxl={4}>
                    <div style={{ marginBottom: 8 }}>
                      {pkg.package_name}{' '}
                      <Tag
                        style={{
                          color: '#bbbbbb',
                          marginLeft: 3,
                          paddingLeft: 3,
                          paddingRight: 3,
                          lineHeight: '17px',
                        }}
                      >
                        {pkg.version}
                      </Tag>
                      {defaultPackages.includes(pkg.package_name) ? (
                        <Tag
                          style={{
                            color: '#3700b366',
                            marginLeft: -5,
                            paddingLeft: 3,
                            paddingRight: 3,
                            lineHeight: '17px',
                          }}
                        >
                          Default
                        </Tag>
                      ) : (
                        <span>
                          <Popconfirm
                            title={`Are you sure you want to delete ${pkg.package_name} package?`}
                            placement="topRight"
                            onConfirm={handlePackageUninstall(pkg)}
                          >
                            <DeleteOutlined />
                          </Popconfirm>
                        </span>
                      )}
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Space>
        </div>
      </Spin>
    </Modal>
  );
};

export default FuncEnvironmentInfoModal;
