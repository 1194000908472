// Imports
import React, { useMemo, useState } from 'react';
import { Spin } from 'antd';

// App Imports
import BlockToolbar from './BlockToolbar';
import RichTextEditor from './RichTextEditor';
import BlockIndex from './BlockIndex';
import Spinner from '../../components/common/Spinner';
import { useIsReadOnly } from './utils';
import ReadOnlyDivider from './ReadOnlyDivider';
import CollapsedBlock from './CollapsedBlock';

const TextBlock = ({
  attributes,
  children,
  element,
  blocks,
  toggleBlockCollapsed,
  toggleBlockVisibility,
}) => {
  const index = useMemo(
    _ => {
      const { id } = element;
      return blocks.findIndex(e => e.id === id) + 1;
    },
    [element, blocks]
  );

  const readOnly = useIsReadOnly();
  const [loading, setLoading] = useState(false);

  const { isBlockVisible, isBlockCollapsed } = useMemo(
    _ => {
      const { isBlockVisible = true, isBlockCollapsed = false } =
        element?.config ?? {};
      return { isBlockVisible, isBlockCollapsed };
    },
    [element]
  );

  if (readOnly && !isBlockVisible) {
    return null;
  }

  return (
    <div
      {...attributes}
      contentEditable={false}
      style={
        readOnly
          ? {
              margin: '0px 0px',
              position: 'relative',
              padding: '0px 5px',
              pointerEvents: 'none',
            }
          : {
              margin: '16px 0px 26px',
              position: 'relative',
              border: '1px dotted #3700b322',
              borderLeft: '4px solid #3700b333',
              padding: '5px',
              pointerEvents: 'none',
            }
      }
    >
      {readOnly && <ReadOnlyDivider index={index} />}
      {!readOnly && <BlockIndex index={index} />}
      <BlockToolbar
        element={element}
        blocks={blocks}
        setLoading={setLoading}
        toggleBlockCollapsed={toggleBlockCollapsed}
        toggleBlockVisibility={toggleBlockVisibility}
      />
      {isBlockCollapsed && (
        <CollapsedBlock element={element} loading={loading} />
      )}
      {!isBlockCollapsed && (
        <Spin indicator={<Spinner />} spinning={loading}>
          <RichTextEditor
            parentElement={element}
            readOnly={readOnly}
          ></RichTextEditor>
        </Spin>
      )}
      <div
        contentEditable={false}
        style={{ userSelect: 'none', display: 'none' }}
      >
        {children}
      </div>
    </div>
  );
};

export default TextBlock;
