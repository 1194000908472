// Imports
import { gql, useMutation } from '@apollo/client';

export const GRANT_TABLE_PERMISSION = gql`
  mutation GrantTablePermission(
    $name: String!
    $permission: String!
    $table_name: String!
    $filter_expression: String
  ) {
    tablePermissionGrant(
      name: $name
      permission: $permission
      table_name: $table_name
      filter_expression: $filter_expression
    ) {
      name
      permission
      table_name
      filter_expression
    }
  }
`;

export const REVOKE_TABLE_PERMISSION = gql`
  mutation RevokeTablePermission(
    $name: String!
    $permission: String!
    $table_name: String!
  ) {
    tablePermissionRevoke(
      name: $name
      permission: $permission
      table_name: $table_name
    ) {
      name
      permission
      table_name
    }
  }
`;

export const useGrantTablePermission = () => {
  return useMutation(GRANT_TABLE_PERMISSION);
};

export const useRevokeTablePermission = () => {
  return useMutation(REVOKE_TABLE_PERMISSION);
};
