// Imports
import { useQuery, useMutation, gql } from '@apollo/client';

export const GET_WAREHOUSES = gql`
  query Warehouses {
    warehouses {
      id
      name
      description
      head_api_url
      head_api_external_url
      graph_enabled
      ml_enabled
      reveal_enabled
    }
  }
`;

export const UPDATE_WAREHOUSE_BY_NAME = gql`
  mutation UpdateWarehouseByName(
    $name: String!
    $description: String
    $head_api_url: String
    $head_api_external_url: String
    $graph_enabled: Boolean
    $ml_enabled: Boolean
    $reveal_enabled: Boolean
  ) {
    warehouseUpdateByName(
      name: $name
      description: $description
      head_api_url: $head_api_url
      head_api_external_url: $head_api_external_url
      graph_enabled: $graph_enabled
      ml_enabled: $ml_enabled
      reveal_enabled: $reveal_enabled
    ) {
      id
      name
      description
      head_api_url
      head_api_external_url
      graph_enabled
      ml_enabled
      reveal_enabled
    }
  }
`;

export const useGetWarehouses = () => {
  return useQuery(GET_WAREHOUSES);
};

export const useUpdateWarehouseByName = () => {
  return useMutation(UPDATE_WAREHOUSE_BY_NAME);
};
