// App Imports
import * as AuthProviders from './schema/auth_providers';
import * as Schemas from './schema/schemas';
import * as Tables from './schema/tables';
import * as Files from './schema/files';
import * as Credentials from './schema/credentials';
import * as Datasources from './schema/datasources';
import * as Datasinks from './schema/datasinks';
import * as TableMonitors from './schema/table_monitors';
import * as ContainerRegistries from './schema/container_registries';
import * as Models from './schema/models';
import * as Contexts from './schema/contexts';
import * as FuncEnvironments from './schema/func_environments';
import * as Jobs from './schema/jobs';
import * as EndpointJobs from './schema/endpoint_jobs';
import * as DataObjects from './schema/data_objects';
import * as Graphs from './schema/graphs';
import * as GraphGrammars from './schema/graph_grammars';
import * as Workbooks from './schema/workbooks';
import * as Worksheets from './schema/worksheets';
import * as Blocks from './schema/blocks';
import * as Visualizations from './schema/visualizations';
import * as VisualizationTypes from './schema/visualization_types';
import * as Users from './schema/users';
import * as KineticaUsers from './schema/kinetica_users';
import * as Roles from './schema/roles';
import * as KineticaRoles from './schema/kinetica_roles';
import * as Permissions from './schema/permissions';
import * as SystemPermissions from './schema/system_permissions';
import * as TablePermissions from './schema/table_permissions';
import * as ProcPermissions from './schema/proc_permissions';
import * as SqlProcPermissions from './schema/sql_proc_permissions';
import * as DatasourcePermissions from './schema/datasource_permissions';
import * as DatasinkPermissions from './schema/datasink_permissions';
import * as GraphPermissions from './schema/graph_permissions';
import * as ResourceGroups from './schema/resource_groups';
import * as SqlProcedures from './schema/sql_procedures';
import * as Warehouses from './schema/warehouses';
import * as SqlQueries from './schema/sqlqueries';
import * as Wms from './schema/wms';
import * as K8sKineticaWorkbenches from './schema/k8s_kineticaworkbenches';
import * as K8sKineticaClusterBackups from './schema/k8s_kineticaclusterbackups';
import * as K8sKineticaClusterRestores from './schema/k8s_kineticaclusterrestores';
import * as K8sKineticaWorkbenchUpgrades from './schema/k8s_kineticaworkbenchupgrades';
import * as K8sKineticaWorkbenchOperatorUpgrades from './schema/k8s_kineticaworkbenchoperatorupgrades';
import * as K8sKineticaClusters from './schema/k8s_kineticaclusters';
import * as K8sKineticaClusterUpgrades from './schema/k8s_kineticaclusterupgrades';
import * as K8sKineticaOperatorUpgrades from './schema/k8s_kineticaoperatorupgrades';
import * as K8sKineticaClusterAdmins from './schema/k8s_kineticaclusteradmins';
import * as K8sKineticaReleaseVersions from './schema/k8s_kineticareleaseversions';
import * as K8sKineticaUsers from './schema/k8s_kineticausers';
import * as K8sKineticaRoles from './schema/k8s_kineticaroles';
import * as K8sKineticaGrants from './schema/k8s_kineticagrants';
import * as K8sStatefulSets from './schema/k8s_statefulsets';
import * as K8sVeleroRestores from './schema/k8s_velerorestores';
import * as Releases from './schema/releases';
import * as Upgrades from './schema/upgrades';
import * as Helpers from './schema/helpers';
import * as ImportJobs from './schema/import_jobs';
import * as Inserts from './schema/inserts';
import * as Metrics from './schema/metrics';
import * as CDataDrivers from './schema/cdata_drivers';
import * as ChatGPT from './schema/chatgpt';
import * as UDFs from './schema/udfs';
import * as FetchURLs from './schema/fetch_urls';
import * as Settings from './schema/settings';
import * as Debug from './schema/debug';

const GraphQLServices = {
  AuthProviders,
  Schemas,
  Tables,
  Files,
  Credentials,
  Datasources,
  Datasinks,
  TableMonitors,
  ContainerRegistries,
  Models,
  Contexts,
  FuncEnvironments,
  Jobs,
  EndpointJobs,
  DataObjects,
  Graphs,
  GraphGrammars,
  Workbooks,
  Worksheets,
  Blocks,
  Visualizations,
  VisualizationTypes,
  Users,
  KineticaUsers,
  Roles,
  KineticaRoles,
  Permissions,
  SystemPermissions,
  TablePermissions,
  ProcPermissions,
  SqlProcPermissions,
  DatasourcePermissions,
  DatasinkPermissions,
  GraphPermissions,
  ResourceGroups,
  SqlProcedures,
  Warehouses,
  SqlQueries,
  Wms,
  K8sKineticaWorkbenches,
  K8sKineticaClusterBackups,
  K8sKineticaClusterRestores,
  K8sKineticaWorkbenchUpgrades,
  K8sKineticaWorkbenchOperatorUpgrades,
  K8sKineticaClusters,
  K8sKineticaClusterUpgrades,
  K8sKineticaClusterAdmins,
  K8sKineticaReleaseVersions,
  K8sKineticaOperatorUpgrades,
  K8sKineticaUsers,
  K8sKineticaRoles,
  K8sKineticaGrants,
  K8sStatefulSets,
  K8sVeleroRestores,
  Releases,
  Upgrades,
  Helpers,
  ImportJobs,
  Inserts,
  Metrics,
  CDataDrivers,
  ChatGPT,
  UDFs,
  FetchURLs,
  Settings,
  Debug,
};

export default GraphQLServices;
