// Imports
import React, { useState, useMemo } from 'react';
import { Form, Steps, Button, Popconfirm, notification, Spin } from 'antd';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';

// App Imports
import GraphQLServices from '../../graphql/services';
import CreateOSMGraphConfigureForm from './CreateOSMGraphConfigureForm';
import { GPUDB_REQUEST_MAP } from '../../endpoints';
import Spinner from '../../components/common/Spinner';
import { OSM_SOURCES, OSM_SOURCE_US_ROAD } from '../../constants';
import { displaySuccess, displayError } from '../../helper';
import { GET_GRAPH_BY_NAME } from '../../graphql/schema/graphs';

const { Step } = Steps;

const STEPS = [
  {
    title: 'Configure',
    StepComponent: CreateOSMGraphConfigureForm,
  },
];

const CREATE_GRAPH_ENDPOINT = '/create/graph';

const CreateOSMGraphForm = ({ resetMode, showGraphPreview }) => {
  const [executeSql] = GraphQLServices.SqlQueries.useExecuteSql();
  const { refetch: refetchGraphs } = GraphQLServices.Graphs.useGetGraphs();

  const [step, setStep] = useState(0);
  const [isCreating, setIsCreating] = useState(false);

  const [form] = Form.useForm();
  const history = useHistory();
  const graphqlClient = useApolloClient();

  const { topBarCollapsed } = useSelector(state => state.app);

  const onFinish = async values => {
    const { graph_name = '', osm_source = '', wkt_select = '' } = values;

    try {
      setIsCreating(true);

      const source = OSM_SOURCES.find(source => source.value === osm_source);
      const statement = `EXECUTE FUNCTION extract_graph ( PARAMS => KV_PAIRS('path'='${source.path}', 'input'='${source.value}', 'name'='${graph_name}', 'wkt'='${wkt_select}') )`;

      const resp = await executeSql({
        variables: {
          statement,
        },
      });

      if (resp?.errors) {
        throw new Error(resp?.errors[0]?.message);
      }

      const graphResp = await graphqlClient.query({
        query: GET_GRAPH_BY_NAME,
        variables: {
          graph_name,
        },
      });

      if (graphResp.errors) {
        notification.open({
          message: 'Graph Preview Error Occurred',
          description: graphResp.errors.map(e => e.message).join('\n'),
          type: 'error',
        });
        return;
      }

      displaySuccess(`Graph ${graph_name} created.`);
      showGraphPreview(graphResp?.data?.graph);
      refetchGraphs();
      resetMode();
    } catch (error) {
      // Graphql client should display error
      displayError(error);
    } finally {
      setIsCreating(false);
    }
  };

  const create = _ => {
    const errors = [];
    const graph_name = form.getFieldValue('graph_name') ?? '';
    const osm_source = form.getFieldValue('osm_source') ?? '';
    const wkt_select = form.getFieldValue('wkt_select') ?? '';

    if (graph_name.trim() === '') {
      errors.push('Graph Name is required.');
    }

    if (osm_source.trim() === '') {
      errors.push('OSM Source is required.');
    }

    if (wkt_select.trim() === '') {
      errors.push('Region Selection is required.');
    }

    if (errors.length > 0) {
      errors.forEach(error => {
        notification.open({
          message: 'Validation Error Occurred',
          description: error,
          type: 'error',
        });
      });
    } else {
      form.submit();
    }
  };

  const { fields } = GPUDB_REQUEST_MAP[CREATE_GRAPH_ENDPOINT];

  const docs = useMemo(
    _ => {
      return fields.reduce((acc, cur) => {
        if (cur.name !== 'options') {
          acc[cur.name] = cur.doc.replace(/@{link.*?}/g, '');
        } else {
          Object.keys(cur.value.valid_keys).forEach(option => {
            acc[option] = cur.value.valid_keys[option].doc.replace(
              /@{link.*?}/g,
              ''
            );
          });
        }
        return acc;
      }, {});
    },
    [fields]
  );

  const handleCancel = e => {
    history.push('/');
  };

  const handleStepClick = step => {
    setStep(step);
  };

  return (
    <Form
      form={form}
      layout="horizontal"
      initialValues={{ osm_source: OSM_SOURCE_US_ROAD.value, wkt_select: '' }}
      onFinish={onFinish}
      colon={false}
    >
      <Steps
        current={step}
        onChange={handleStepClick}
        style={{ marginBottom: '20px' }}
      >
        {STEPS.map(({ title }, idx) => (
          <Step key={idx} title={title}></Step>
        ))}
      </Steps>
      <div
        style={{
          height: topBarCollapsed
            ? 'calc(100vh - 340px)'
            : 'calc(100vh - 390px)',
          margin: '30px 0px',
          overflow: 'auto',
        }}
      >
        {STEPS.map(({ StepComponent }, idx) => (
          <Spin key={idx} indicator={<Spinner />} spinning={isCreating}>
            <div style={{ display: step === idx ? 'block' : 'none' }}>
              <StepComponent form={form} docs={docs}></StepComponent>
            </div>
          </Spin>
        ))}
      </div>
      <div>
        <Button
          onClick={resetMode}
          style={{ float: 'left', marginLeft: '10px' }}
        >
          Change Mode
        </Button>
        <Button
          type="primary"
          onClick={create}
          style={{ float: 'right' }}
          loading={isCreating}
        >
          Create Graph
        </Button>
        <Popconfirm
          title="Are you sure you want to cancel?"
          onConfirm={handleCancel}
          okText="Yes"
          cancelText="No"
        >
          <Button style={{ float: 'right', marginRight: '10px' }} danger>
            Cancel
          </Button>
        </Popconfirm>
      </div>
    </Form>
  );
};

export default CreateOSMGraphForm;
