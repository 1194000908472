// Imports

// App Imports
import GraphQLServices from '../graphql/services';
import { EXECUTE_SQL_LIMIT } from '../setup/config';
import { displayError } from '../helper';

function useSqlGPT() {
  const [executeSql, { loading: querying }] =
    GraphQLServices.SqlQueries.useExecuteSql();

  const query = async (question, contexts = []) => {
    let statement = `GENERATE SQL FOR '${question.replace(/'/gi, "''")}'`;

    if (contexts.length > 0) {
      // Check to make sure context exists
      const existingResp = await executeSql({
        variables: {
          statement: 'DESCRIBE CONTEXT *',
          limit: EXECUTE_SQL_LIMIT,
        },
      });
      const {
        response: {
          data: { column_1 = [] },
        },
      } = existingResp?.data?.executeSql;
      const existingContexts = [...new Set(column_1)];

      const validContexts = contexts.filter(context =>
        existingContexts.includes(context.context_name)
      );
      if (validContexts.length === 0) {
        throw new Error(
          'No SQL-GPT context selected, please click Configure and select at least 1 context'
        );
      }

      statement += ` WITH OPTIONS ( context_names = '${contexts
        .filter(context => existingContexts.includes(context.context_name))
        .map(context => context.context_name)
        .join(',')}');`;
    } else {
      throw new Error(
        'No SQL-GPT context selected, please click Configure and select at least 1 context'
      );
    }

    const resp = await executeSql({
      variables: {
        statement,
        limit: EXECUTE_SQL_LIMIT,
      },
    });

    if (!resp?.data?.executeSql && resp.errors.length > 0) {
      displayError(resp.errors[0].message);
      return null;
    }

    const {
      response: {
        data: { column_1 = [] },
      },
    } = resp?.data?.executeSql;

    if (column_1.length > 0) {
      return column_1[0];
    }

    return null;
  };

  return {
    query,
    querying,
  };
}

export default useSqlGPT;
