// Imports
import React, { useMemo, useState } from 'react';
import {
  Layout,
  Descriptions,
  Divider,
  Tag,
  Button,
  Tooltip,
  Select,
  Modal,
  Form,
} from 'antd';
import {
  ExclamationCircleOutlined,
  LoadingOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import addDays from 'date-fns/addDays';
import parseISO from 'date-fns/parseISO';
import isBefore from 'date-fns/isBefore';

// App Imports
import Permission from '../../components/security/Permission';
import {
  AUTO_PAUSE_DURATIONS,
  WAREHOUSE_SIZES,
  DEFAULT_TABPANE_HEIGHT,
  DEFAULT_TABPANE_NOHEAD_HEIGHT,
  LICENSE_EXPIRATION_WARNING_DAYS,
  // WAREHOUSE_SIZE_TYPES,
  // WAREHOUSE_TYPES,
} from '../../constants';
import { displayError, displaySuccess, getWarehouseStatus } from '../../helper';
import { useUpdateK8SKineticaClusterByName } from '../../graphql/schema/k8s_kineticaclusters';
import { FREE_SAAS, DEDICATED_SAAS } from '../../setup/config';
import ClusterConfigModal from '../../components/modal/ClusterConfigModal';

const { Content } = Layout;

const DEFAULT_AUTO_PAUSE_STATUS = false;
const DEFAULT_AUTO_PAUSE_DURATION = '30m';

const AutoPauseModal = ({
  cluster,
  visible: autoPauseModalVisible,
  toggleModal: setAutoPauseModalVisible,
}) => {
  const [updateKineticaCR] = useUpdateK8SKineticaClusterByName();
  const clusterName = cluster?.spec?.gpudbCluster?.clusterName;
  const currentAutoPause =
    cluster?.spec?.autoSuspend?.enabled || DEFAULT_AUTO_PAUSE_STATUS;
  const currentAutoPauseDuration =
    cluster?.spec?.autoSuspend?.inactivityDuration ||
    DEFAULT_AUTO_PAUSE_DURATION;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedAutoPause, setSelectedAutopause] = useState(currentAutoPause);
  const [selectedAutoPauseDuration, setSelectedAutopauseDuration] = useState(
    currentAutoPauseDuration
  );

  const handleAutoPauseChange = value => {
    if (value === 'disabled') {
      setSelectedAutopause(false);
      setSelectedAutopauseDuration('disabled');
    } else {
      setSelectedAutopause(true);
      setSelectedAutopauseDuration(value);
    }
  };
  const autoPauseModalHandleOk = async () => {
    setIsLoading(true);
    updateKineticaCR({
      variables: {
        name: clusterName,
        body: {
          spec: {
            autoSuspend: {
              enabled: selectedAutoPause,
              inactivityDuration:
                selectedAutoPauseDuration === 'disabled'
                  ? DEFAULT_AUTO_PAUSE_DURATION
                  : selectedAutoPauseDuration,
            },
          },
        },
      },
    })
      .then(resp => {
        if (resp?.errors?.length > 0) {
          displayError(resp.errors[0].message);
        } else {
          displaySuccess('Auto suspend configuration updated.');
          setAutoPauseModalVisible(false);
        }
        setIsLoading(false);
      })
      .catch(err => {
        console.error({ err });
      });
  };
  const autoPauseModalHandleCancel = () => {
    setAutoPauseModalVisible(false);
  };

  const WarningTitle = text => (
    <span>
      {text}
      <Tag
        icon={<ExclamationCircleOutlined />}
        color="warning"
        style={{
          marginLeft: '10px',
        }}
      >
        Warning
      </Tag>
    </span>
  );

  return (
    <Modal
      title={WarningTitle('Confirm Auto Suspend Changes')}
      open={autoPauseModalVisible}
      // onOk={autoPauseModalHandleOk}
      onCancel={autoPauseModalHandleCancel}
      footer={[
        <Button key="back" onClick={autoPauseModalHandleCancel}>
          Cancel
        </Button>,
        <Button
          disabled={
            selectedAutoPauseDuration === currentAutoPauseDuration &&
            !selectedAutoPause
            // (currentAutoPause && )
          }
          key="submit"
          type="primary"
          loading={isLoading}
          onClick={autoPauseModalHandleOk}
        >
          Submit
        </Button>,
      ]}
      centered
    >
      <p>
        Your auto suspend timeout will be{' '}
        {selectedAutoPause ? 'enabled' : 'disabled'}. Changing your auto suspend
        configuration has an impact on the availability and cost of your
        cluster.
      </p>
      <Form>
        <Form.Item label="Timeout">
          <Select
            defaultValue={
              !currentAutoPause ? 'disabled' : currentAutoPauseDuration
            }
            onSelect={handleAutoPauseChange}
          >
            <Select.Option disabled={!currentAutoPause} key="disabled">
              Disabled
            </Select.Option>
            {AUTO_PAUSE_DURATIONS.map(duration => (
              <Select.Option
                disabled={
                  currentAutoPause && duration === currentAutoPauseDuration
                }
                key={duration}
              >
                {duration}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

const Overview = ({ cluster, upgrades = [] }) => {
  const history = useHistory();
  const [autoPauseModalVisible, setAutoPauseModalVisible] = useState(false);
  const [isConfigOpen, setIsConfigOpen] = useState(false);

  const { topBarCollapsed } = useSelector(state => state.app);

  const versionRegex = /(\d+\.\d+\.\d+\.\d+)\.\d+/gi;
  const versionRegexMatches = versionRegex.exec(
    cluster?.status?.hmStatus?.version
  );
  const version =
    versionRegexMatches && versionRegexMatches.length === 2
      ? versionRegexMatches[1]
      : cluster?.status?.hmStatus?.version ?? 'Unavailable';
  const clusterName = cluster?.spec?.gpudbCluster?.clusterName;
  const upgradeAvailable = upgrades.some(upgrade => {
    return (
      upgrade.clusterName === clusterName &&
      upgrade?.availableUpgradeVersions?.length > 0
    );
  });
  // const upgradeAvailable = releaseVersions.length > 0;

  const handleUpgradeLink = _ => {
    const tab = 'upgrade';
    history.push(`/warehouse/${tab}`);
  };

  const { phase, processing, color } = getWarehouseStatus(cluster);

  const license = useMemo(
    _ => {
      return cluster.license_info;
    },
    [cluster]
  );

  const expiring = useMemo(
    _ => {
      if (license && license.expiration) {
        const cutoffDate = addDays(new Date(), LICENSE_EXPIRATION_WARNING_DAYS);
        const expireDate = parseISO(license.expiration);
        return isBefore(expireDate, cutoffDate);
      }
      return false;
    },
    [license]
  );

  const currentAutoPause =
    cluster?.spec?.autoSuspend?.enabled || DEFAULT_AUTO_PAUSE_STATUS;
  const currentAutoPauseDuration =
    cluster?.spec?.autoSuspend?.inactivityDuration ||
    DEFAULT_AUTO_PAUSE_DURATION;

  // const [sizingChanged, setSizingChanged] = useState(false);
  const currentGpu =
    cluster?.spec?.gpudbCluster?.clusterSize?.tshirtType.includes('GPU') ||
    false;
  // const [selectedGpu, setSelectedGpu] = useState(currentGpu);
  const currentSize =
    WAREHOUSE_SIZES[cluster?.spec?.gpudbCluster?.clusterSize?.tshirtSize] ??
    null;
  // const [selectedSize, setSelectedSize] = useState(currentSize);
  // const [sizingModalVisible, setSizingModalVisible] = useState(false);
  // const sizingModalHandleOk = async () => {
  //   await updateKineticaCR({
  //     variables: {
  //       name: clusterName,
  //       body: {
  //         spec: {
  //           gpudbCluster: {
  //             clusterSize: {
  //               ...WAREHOUSE_SIZE_TYPES[selectedSize],
  //               tshirtType: selectedGpu
  //                 ? WAREHOUSE_TYPES.GPU
  //                 : WAREHOUSE_SIZE_TYPES[selectedSize].tshirtType,
  //             }
  //           }
  //         },
  //       },
  //     },
  //   });
  //   setSizingModalVisible(false);
  // };
  // const sizingModalHandleCancel = () => {
  //   setSizingModalVisible(false);
  // };
  // const checkSizingChange = (selected, gpu) =>
  //   setSizingChanged(selected !== currentSize || gpu !== currentGpu);
  // const handleGpuChange = () => {
  //   checkSizingChange(selectedSize, !selectedGpu);
  //   setSelectedGpu(!selectedGpu);
  // };
  // const handleSizeSelect = ({ item, key, keyPath, selectedKeys, domEvent }) => {
  //   checkSizingChange(key, selectedGpu);
  //   setSelectedSize(key);
  // };

  // const sizingModal = (
  //   <Modal
  //     title={WarningTitle('Confirm Sizing Changes')}
  //     visible={sizingModalVisible}
  //     onOk={sizingModalHandleOk}
  //     onCancel={sizingModalHandleCancel}
  //   >
  //     <p>The price of your cluster will change when the update takes place. Please refer to our <a
  //       target="_blank"
  //       rel="noreferrer"
  //       href="https://docs.kinetica.com/7.1/azure/provision/installation/#choosing-an-offer">offering guide</a> for more information about the size you have selected.</p>
  //   </Modal>
  // );
  // const sizeMenu = (
  //   <Menu
  //     selectedKeys={[selectedSize]}
  //     defaultSelectedKeys={[selectedSize]}
  //     onClick={handleSizeSelect}
  //   >
  //     {Object.values(WAREHOUSE_SIZES).map((size, idx) => (
  //       <Menu.Item key={size}>{size}</Menu.Item>
  //     ))}
  //   </Menu>
  // );

  return (
    <Content
      style={{
        backgroundColor: '#ffffff',
        padding: '20px',
        height: topBarCollapsed
          ? DEFAULT_TABPANE_NOHEAD_HEIGHT
          : DEFAULT_TABPANE_HEIGHT,
        overflow: 'auto',
      }}
    >
      <Button
        type="primary"
        icon={<SettingOutlined />}
        onClick={_ => {
          setIsConfigOpen(true);
        }}
        size="small"
        style={{
          width: 100,
          float: 'right',
          backgroundColor: '#ffffff',
          display: 'none',
        }}
        ghost
      >
        Configure
      </Button>

      <Descriptions title="Detail" size="small" column={1} bordered>
        <Descriptions.Item label="Version" labelStyle={{ width: '200px' }}>
          {version}
          {upgradeAvailable && !DEDICATED_SAAS && (
            <Permission everyKeys={['manage_warehouse']}>
              <Tag
                icon={<ExclamationCircleOutlined />}
                color="warning"
                onClick={handleUpgradeLink}
                style={{
                  marginLeft: '10px',
                  cursor: 'pointer',
                }}
              >
                Upgrade Available
              </Tag>
            </Permission>
          )}
        </Descriptions.Item>
        {expiring && license.key !== 'payg' && (
          <Descriptions.Item
            label="License Expiration"
            labelStyle={{ width: '200px', verticalAlign: 'top' }}
            style={{ color: '#ff7875' }}
          >
            {license.expiration ?? ': Not Available'}
          </Descriptions.Item>
        )}
      </Descriptions>
      <Divider dashed />
      <Descriptions title="Health" size="small" column={1} bordered>
        <Descriptions.Item label="Status" labelStyle={{ width: '200px' }}>
          <Tag
            color={color}
            style={{
              width: '100%',
              textAlign: 'center',
            }}
          >
            {processing && (
              <LoadingOutlined
                style={{ color: '#ffffff', marginRight: '10px' }}
              />
            )}
            {phase.replace(/([a-zA-Z])(?=[A-Z])/g, '$1 ').toUpperCase()}
          </Tag>
        </Descriptions.Item>
      </Descriptions>
      <Divider dashed />
      <Descriptions title="Auto Suspend" size="small" column={1} bordered>
        <Descriptions.Item label="Status" labelStyle={{ width: '200px' }}>
          {currentAutoPause ? 'Enabled -' : 'Disabled'}
          {currentAutoPause ? ` ${currentAutoPauseDuration}` : null}
          <Tooltip
            title="Suspend cluster when no activity has been detected for the selected timeout."
            key="leftButton"
            placement="top"
          >
            <ExclamationCircleOutlined
              style={{
                marginLeft: '8px',
              }}
            />
          </Tooltip>
          <Button
            size="small"
            type="ghost"
            style={{
              marginLeft: '8px',
            }}
            onClick={() => setAutoPauseModalVisible(true)}
            icon={<SettingOutlined />}
          >
            Edit
          </Button>
        </Descriptions.Item>
      </Descriptions>
      {autoPauseModalVisible ? (
        <AutoPauseModal
          cluster={cluster}
          visible={autoPauseModalVisible}
          toggleModal={setAutoPauseModalVisible}
        />
      ) : null}
      <Divider dashed />
      <Descriptions title="Sizing" size="small" column={1} bordered={true}>
        <Descriptions.Item label="Size" labelStyle={{ width: '200px' }}>
          {currentSize || ''}
        </Descriptions.Item>
        <Descriptions.Item
          label="GPU Acceleration"
          labelStyle={{ width: '200px' }}
        >
          {currentGpu ? 'Enabled' : 'Disabled'}
        </Descriptions.Item>
      </Descriptions>
      {/* <Divider dashed />
      <Descriptions title="Sizing" size="small" column={3} bordered>
        <Descriptions.Item
          span={1}
          label={
            <span>
              GPU Accelerated
              <span style={{ marginLeft: "1rem" }}>
                <Tooltip
                  title="Add or remove GPU resources to your cluster."
                  key="leftButton"
                  placement="top"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </span>
            </span>
          }
          labelStyle={{ width: "200px" }}
        >
          <Switch checked={selectedGpu} onChange={handleGpuChange} />
        </Descriptions.Item>
        <Descriptions.Item
          span={1}
          label={
            <span>
              Size
              <span style={{ marginLeft: "1rem" }}>
                <Tooltip
                  title="Scale your deployment to meet your performance requirements."
                  key="leftButton"
                  placement="top"
                >
                  <ExclamationCircleOutlined />
                </Tooltip>
              </span>
            </span>
          }
          labelStyle={{ width: "200px" }}
          contentStyle={{ width: "450px" }}
        >
          <Dropdown overlay={sizeMenu} trigger={["click"]}>
            <Button>
              {selectedSize} <DownOutlined />
            </Button>
          </Dropdown>
        </Descriptions.Item>
        <Descriptions.Item span={1} contentStyle={{ width: 200 }}>
          <Button
            type="primary"
            onClick={() => setSizingModalVisible(true)}
            style={{ width: "100%" }}
            disabled={!sizingChanged}
          >
            Update
          </Button>
        </Descriptions.Item>
      </Descriptions>
      {sizingModal} */}
      {cluster?.status?.ingressUrls && (
        <>
          {!FREE_SAAS && (
            <>
              <Divider dashed />
              <Descriptions
                title="Applications"
                size="small"
                column={1}
                bordered
              >
                {cluster?.status?.ingressUrls?.gadmin && (
                  <Descriptions.Item
                    label="GAdmin"
                    labelStyle={{ width: '200px' }}
                  >
                    {cluster?.status?.ingressUrls?.gadmin}
                  </Descriptions.Item>
                )}
              </Descriptions>
            </>
          )}
          <Divider dashed />
          <Descriptions
            title="Table Monitor (ZMQ)"
            size="small"
            column={1}
            bordered
          >
            {cluster?.status?.ingressUrls?.dbTrigger && (
              <Descriptions.Item
                label="Trigger"
                labelStyle={{ width: '200px' }}
              >
                {cluster?.status?.ingressUrls?.dbTrigger}
              </Descriptions.Item>
            )}
            {cluster?.status?.ingressUrls?.dbMonitor && (
              <Descriptions.Item
                label="Table Monitor"
                labelStyle={{ width: '200px' }}
              >
                {cluster?.status?.ingressUrls?.dbMonitor}
              </Descriptions.Item>
            )}
          </Descriptions>
          {cluster?.status?.ingressUrls?.postgresProxy && (
            <>
              <Divider dashed />
              <Descriptions title="Postgres" size="small" column={1} bordered>
                <Descriptions.Item
                  label="Proxy"
                  labelStyle={{ width: '200px' }}
                >
                  {cluster?.status?.ingressUrls?.postgresProxy}
                </Descriptions.Item>
              </Descriptions>
            </>
          )}
        </>
      )}
      {isConfigOpen && <ClusterConfigModal setIsConfigOpen={setIsConfigOpen} />}
      <div style={{ height: '20px' }}></div>
    </Content>
  );
};

export default Overview;
